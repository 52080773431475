import React from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import {
  Button,
  ButtonProps,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  useTab,
  TabProps,
  Box,
  useMultiStyleConfig,
  CSSObject,
} from '@chakra-ui/react';
import { omit, pick } from 'lodash';
import { Link as RouterLinkBase } from 'react-router-dom';
import { motion } from 'framer-motion';

export type ButtonLinkProps = ButtonProps & NextLinkProps & { target?: string; rel?: string };
const linkProps = ['replace', 'scroll', 'shallow', 'prefetch', 'locale'] as const;
const TabUnderline = motion(Box);
export const ButtonLink = React.forwardRef(
  (
    { href, children, isDisabled, ...props }: ButtonLinkProps,
    ref: React.Ref<HTMLButtonElement>
  ) => (
    <NextLink href={isDisabled ? '' : href} {...pick(props, linkProps)} passHref>
      <Button
        as="a"
        variant="link"
        {...omit(props, linkProps)}
        isDisabled={isDisabled}
        ref={ref}
        onClick={(e) => {
          if (isDisabled) e.preventDefault();
          else props.onClick?.(e);
        }}
      >
        {children}
      </Button>
    </NextLink>
  )
);
ButtonLink.displayName = 'ButtonLink';

export function ButtonLinkSolid(props: ButtonLinkProps) {
  return <ButtonLink variant="solid" {...props} />;
}

export type LinkProps = (Omit<NextLinkProps, 'as'> & {
  disabled?: boolean;
  asUrl?: string;
}) &
  Omit<ChakraLinkProps, 'href'>;
export const Link = React.forwardRef(function Link(
  { href, children, asUrl, disabled, ...props }: React.PropsWithChildren<LinkProps>,
  ref: React.ForwardedRef<HTMLAnchorElement>
) {
  return !disabled ? (
    <NextLink href={href} as={asUrl} {...pick(props, linkProps)} passHref>
      <ChakraLink {...omit(props, linkProps)} ref={ref}>
        {children}
      </ChakraLink>
    </NextLink>
  ) : (
    <ChakraLink {...omit(props, linkProps)} disabled={disabled} ref={ref}>
      {children}
    </ChakraLink>
  );
});
export type TabLinkProps = ButtonLinkProps & TabProps & { useUnderline?: boolean };
export const TabLink = React.forwardRef(
  (
    {
      Component = ButtonLink,
      useUnderline,
      ...props
    }: TabLinkProps & { Component?: typeof ButtonLink },
    ref: React.Ref<HTMLElement>
  ) => {
    const tabProps = useTab({ ...props, ref });
    const styles = useMultiStyleConfig('Tabs', { ...tabProps }) as { tab: CSSObject };
    const borderBottomColor = useUnderline ? styles.tab.borderColor + ' !important' : undefined;
    return (
      <Component
        __css={styles.tab}
        {...tabProps}
        variant="tab"
        {...props}
        position="relative"
        borderColor={borderBottomColor}
      >
        {tabProps.children}
        {useUnderline && !!tabProps['aria-selected'] && (
          <TabUnderline
            layoutId="underline"
            as="span"
            position="absolute"
            left="0"
            right="0"
            borderBottom={styles.tab.borderBottom}
            bottom={styles.tab.marginBottom}
          />
        )}
      </Component>
    );
  }
);

TabLink.displayName = 'Tab link';
const NEXT_JS_PROPS = ['scroll', 'shallow', 'prefetch', 'locale'];
export const RouterLink = React.forwardRef(function RouterLink(
  { href, ...props }: LinkProps,
  ref: React.Ref<HTMLAnchorElement>
) {
  return <ChakraLink as={RouterLinkBase} to={href} {...omit(props, NEXT_JS_PROPS)} ref={ref} />;
});

export const RouterButtonLink = React.forwardRef(function RouterButtonLink(
  { href, children, isDisabled, ...props }: ButtonLinkProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <Button
      as={RouterLinkBase}
      to={href}
      variant="link"
      {...omit(props, NEXT_JS_PROPS)}
      isDisabled={isDisabled}
      ref={ref}
      onClick={(e) => {
        if (isDisabled) e.preventDefault();
        else props.onClick?.(e);
      }}
    >
      {children}
    </Button>
  );
});

export const RouterButtonPrimary = React.forwardRef(function RouterButtonPrimary(
  { href, children, isDisabled, ...props }: ButtonLinkProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return (
    <Button
      as={RouterLinkBase}
      to={href}
      {...omit(props, NEXT_JS_PROPS)}
      isDisabled={isDisabled}
      ref={ref}
      onClick={(e) => {
        if (isDisabled) e.preventDefault();
        else props.onClick?.(e);
      }}
    >
      {children}
    </Button>
  );
});

export const RouterTabLink = React.forwardRef(function RouterTabLink(
  props: TabLinkProps,
  ref: React.Ref<HTMLButtonElement>
) {
  return <TabLink Component={RouterButtonLink} ref={ref} {...props} />;
});
