import { BAssessment, useUpdateCompanySettings } from 'models';
import useCompany from './useCompany';
import { CompanyAssessment } from '../models/assessment-model';
import { uniqBy, values } from 'lodash';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BusinessUnit } from '../models/company-model';

export type StructureNode = {
  id: string;
  name?: string;
  parent?: string;
  businessUnitId?: string;
  contactPersonId?: string;
  order: number;
};

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type DepartmentNode = WithRequired<StructureNode, 'id' | 'name' | 'contactPersonId'>;

export type SubdepartmentNode = WithRequired<
  StructureNode,
  'id' | 'name' | 'parent' | 'contactPersonId'
>;

export type BusinessUnitNode = WithRequired<StructureNode, 'id' | 'businessUnitId'> & {
  parent?: string;
  contactPersonId?: string;
};

export type BareCompanyStructure = {
  [assessmentId: string]: {
    nodes: StructureNode[];
  };
};

const setupInitialStructure = (cAssessment: CompanyAssessment): BareCompanyStructure => {
  const initialCompanyStructure: BareCompanyStructure = {
    [cAssessment.id]: {
      nodes: values(cAssessment.bAssessments).map((bAssessment, index) => {
        return {
          id: bAssessment.businessUnit.id,
          businessUnitId: bAssessment.businessUnit.id,
          contactPersonId: bAssessment.businessUnit.contactPerson?.id,
          order: index,
        };
      }),
    },
  };
  return initialCompanyStructure;
};

const getBareBusinessUnitIds = (
  cAssessment: CompanyAssessment,
  bareCompanyStructure: BareCompanyStructure
) => {
  const cAssessmentBUs = values(cAssessment.bAssessments).map(
    (bAssessment) => bAssessment.businessUnit.id
  );
  return bareCompanyStructure[cAssessment.id].nodes
    .filter((node) => !!node.businessUnitId)
    .filter((node) => cAssessmentBUs.includes(node.businessUnitId))
    .map((node) => node.businessUnitId)
    .filter(Boolean) as string[];
};

const useCompanyStructure = (cAssessment?: CompanyAssessment) => {
  const { settings, id } = useCompany();
  const updateCompanySettings = useUpdateCompanySettings();

  if (!cAssessment) return {};

  const { bUnits, nodes } = useMemo(() => {
    let businessUnits: string[] = [];
    let pureNodes: StructureNode[];
    if (settings.companyStructure && settings.companyStructure[cAssessment.id]) {
      businessUnits = getBareBusinessUnitIds(cAssessment, settings.companyStructure);
      const allNodes = settings.companyStructure[cAssessment.id].nodes.filter(
        (node: StructureNode) => {
          // Remove nodes that are NOT in assessment
          if (!node.businessUnitId) return true;
          return !!cAssessment.bAssessments[node.businessUnitId];
        }
      ) as StructureNode[];

      // Handle nodes whose parent DOES NOT exist
      pureNodes = allNodes.map((node) => {
        if (!node.parent) return node;
        const parentNode = allNodes.find((n) => n.id === node.parent);
        if (!parentNode) {
          return {
            ...node,
            parent: undefined,
          };
        }
        return node;
      });
    } else {
      const newBareStructure = setupInitialStructure(cAssessment);
      updateCompanySettings({
        id,
        settings: {
          ...settings,
          companyStructure: { ...(settings.companyStructure ?? {}), ...newBareStructure },
        },
      });
      pureNodes = newBareStructure[cAssessment.id].nodes as StructureNode[];
    }
    return {
      bUnits: businessUnits,
      nodes: uniqBy(pureNodes, 'id'),
    };
  }, [cAssessment, settings]);

  const updateNode = (nodeId: string, newNode: Partial<StructureNode>) => {
    const newBareStructure = {
      ...settings.companyStructure,
      [cAssessment.id]: {
        ...settings.companyStructure[cAssessment.id],
        nodes: settings.companyStructure[cAssessment.id].nodes.map((node: StructureNode) => {
          if (node.id === nodeId) {
            return { ...node, ...newNode };
          }
          return node;
        }),
      },
    };
    updateCompanySettings({
      id,
      settings: {
        ...settings,
        companyStructure: newBareStructure,
      },
    });
  };

  const updateNodes = (newNodes: StructureNode[]) => {
    updateCompanySettings({
      id,
      settings: {
        ...settings,
        companyStructure: {
          ...settings.companyStructure,
          [cAssessment.id]: {
            nodes: newNodes,
          },
        },
      },
    });
  };

  const addNode = (node: StructureNode) => {
    updateNodes([...settings.companyStructure[cAssessment.id].nodes, node]);
  };

  const addReportingUnit = (reportingUnit: {
    name: string;
    contactPersonId: string;
    parent?: string;
  }) => {
    const newDepartment = {
      id: uuidv4(),
      name: reportingUnit.name,
      parent: reportingUnit.parent,
      contactPersonId: reportingUnit.contactPersonId,
      order: nodes.length,
    };
    addNode(newDepartment);
  };

  const addBusinessUnit = (businessUnit: { id: string; name: string; parent?: string }) => {
    const newBusinessUnit: BusinessUnitNode = {
      id: businessUnit.id,
      name: businessUnit.name,
      businessUnitId: businessUnit.id,
      parent: businessUnit.parent,
      order: nodes.length,
    };
    addNode(newBusinessUnit);
  };

  const moveBusinessUnit = (businessUnit: { id: string; newParentId?: string }) => {
    updateNode(businessUnit.id, {
      id: businessUnit.id,
      parent: businessUnit.newParentId,
    });
  };

  const moveSubdepartment = (nodeId: string, newParent?: string) => {
    updateNode(nodeId, {
      id: nodeId,
      parent: newParent ?? undefined,
    });
  };

  const changeNodeParent = (nodeId: string, newParentId?: string) => {
    updateNode(nodeId, {
      id: nodeId,
      parent: newParentId ?? undefined,
    });
  };

  const deleteNode = (nodeId: string) => {
    const currentNodes = settings.companyStructure[cAssessment.id].nodes as StructureNode[];
    updateNodes(
      currentNodes
        .filter((node) => node.id !== nodeId)
        .map((node) => {
          if (node.parent === nodeId) {
            return { ...node, parent: undefined };
          }
          return node;
        })
    );
  };
  return {
    updateNodes,
    nodes,
    bUnits,
    updateNode,
    addReportingUnit,
    addBusinessUnit,
    moveBusinessUnit,
    moveSubdepartment,
    deleteNode,
    changeNodeParent,
  };
};

export default useCompanyStructure;

// 1 BU => Many CAssessment
export const useAddBusinessUnitToAssessments = () => {
  const { settings, id } = useCompany();
  const updateCompanySettings = useUpdateCompanySettings();

  const addBusinessUnitToAssessments = (bu: BusinessUnit, cAssessmentIds: string[]) => {
    const updateObj = {
      ...settings,
      companyStructure: {
        ...settings.companyStructure,
      },
    };
    cAssessmentIds.forEach((cAssessmentId) => {
      const structure = settings.companyStructure[cAssessmentId] ?? {};
      const newNodes = [
        ...(structure.nodes ?? []),
        {
          id: bu.id,
          businessUnitId: bu.id,
          name: bu.name,
          contactPersonId: bu.contactPerson.id,
          order: structure?.nodes?.length ?? 0,
        },
      ];
      updateObj.companyStructure[cAssessmentId] = {
        ...structure,
        nodes: newNodes,
      };
    });
    updateCompanySettings({ id, settings: updateObj });
  };

  return { addBusinessUnitToAssessments };
};

// Many BUs => 1 CAssessment
export const useAddBusinessUnitsToAssessment = () => {
  const { settings, id } = useCompany();
  const updateCompanySettings = useUpdateCompanySettings();

  const addBusinessUnitsToAssessment = (bAssessments: BAssessment[], cAssessmentId: string) => {
    const updateObj = {
      ...settings,
      companyStructure: {
        ...settings.companyStructure,
      },
    };
    const structure = settings.companyStructure[cAssessmentId] ?? {};
    const newNodes = [
      ...(structure?.nodes ?? []),
      ...bAssessments.map((bAssessment) => {
        return {
          id: bAssessment.businessUnit.id,
          businessUnitId: bAssessment.businessUnit.id,
          name: bAssessment.businessUnit.name,
          contactPersonId: bAssessment.businessUnit.contactPerson.id,
          order: structure?.nodes?.length ?? 0,
        };
      }),
    ];

    updateObj.companyStructure[cAssessmentId] = {
      ...structure,
      nodes: newNodes,
    };

    updateCompanySettings({ id, settings: updateObj });
  };

  return { addBusinessUnitsToAssessment };
};
