import {
  FormControl,
  Button,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputProps,
  Box,
  VStack,
  StackProps,
  ButtonProps,
  Textarea,
  TextareaProps,
  Text,
  BoxProps,
} from '@chakra-ui/react';
import React from 'react';
import { merge } from 'lodash';
import { Field, FieldProps, useFormikContext, Form, isFunction } from 'formik';
import HelpTooltip from '../HelpTooltip';

type InputPropsOverride = Omit<InputProps, 'value' | 'form' | 'onSubmit' | 'onCopy'>;

export type FormFieldRenderProps<V> = InputPropsOverride & {
  value: V;
  setValue: (value: V) => void;
};

export type FormFieldProps<V> = {
  label: string;
  helpText?: string;
  helpTextAsTooltip?: boolean;
  isRequired?: boolean;
  isDisabled?: boolean;
  hasValidation?: boolean;
  children?: React.ReactNode | ((props: FormFieldRenderProps<V>) => React.ReactNode);
  form?: FieldProps<V>['form'];
  errorMessageStyle?: BoxProps;
} & Omit<FieldProps<V>, 'form'> &
  InputPropsOverride;

export const FormField = <V,>({
  field,
  meta,
  form,
  label,
  isDisabled,
  isRequired,
  helpText,
  helpTextAsTooltip = true,
  hasValidation = true,
  // @ts-ignore
  children = ({ setValue, value, ...props }) =>
    (<Input {...props} value={String(value)} />) as React.ReactNode,
  errorMessageStyle = {},
  ...inputProps
}: FormFieldProps<V>) => {
  const isInvalid = !!(meta.error && meta.touched);
  const id = `field-${field.name}`;
  return (
    <FormControl isInvalid={isInvalid} isRequired={isRequired}>
      <Box mb="xs" className="label-container">
        {label && (
          <FormLabel htmlFor={id} display="inline">
            {label}
          </FormLabel>
        )}
        {helpText && helpTextAsTooltip && (
          <Box as="span" marginLeft="-0.75em">
            <HelpTooltip label={helpText} placement="right" />
          </Box>
        )}
      </Box>
      {isFunction(children)
        ? children({
            ...field,
            id,
            isInvalid,
            isDisabled,
            setValue: (v) => form?.setFieldValue(field.name, v),
            ...inputProps,
          })
        : children}

      {helpText && !helpTextAsTooltip && (
        <Box marginTop="3px">
          <FormHelperText>{helpText}</FormHelperText>
        </Box>
      )}
      <Box
        height={hasValidation || meta.error ? '21px' : '0'}
        marginTop="3px"
        {...errorMessageStyle}
      >
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      </Box>
    </FormControl>
  );
};

export const VForm = React.forwardRef(
  (
    { children, bigLabels, sx, ...props }: StackProps & { bigLabels?: boolean },
    ref: React.Ref<HTMLDivElement>
  ) => (
    <Form style={{ width: '100%' }} noValidate>
      <VStack
        spacing="12px"
        alignItems="flex-start"
        sx={merge(
          bigLabels ? { '.chakra-form__label': { fontSize: '18px', fontWeight: 'normal' } } : {},
          sx
        )}
        {...props}
        ref={ref}
      >
        {children}
      </VStack>
    </Form>
  )
);
VForm.displayName = 'VForm';
export const FormikFormField = <V,>({
  name,
  label,
  ...inputProps
}: Omit<FormFieldProps<V>, 'form' | 'field' | 'meta'>) => (
  <Field name={name}>
    {({ field, meta, form }: FieldProps<V, any>) => (
      <FormField field={field} meta={meta} form={form} label={label} {...inputProps} />
    )}
  </Field>
);

export const SubmitButton = ({
  children,
  isLoading,
  ...props
}: JSX.ElementChildrenAttribute & { isLoading: boolean } & ButtonProps) => (
  <Button width="full" isLoading={isLoading} type="submit" variant="submit" size="lg" {...props}>
    {children}
  </Button>
);

export const FormikSubmitButton = (props: JSX.ElementChildrenAttribute & ButtonProps) => {
  const form = useFormikContext();
  return <SubmitButton isLoading={form.isSubmitting} {...props} />;
};

export const TextareaWithCharCounter = ({
  maxLength,
  ...props
}: Omit<FormFieldRenderProps<string>, 'setValue'>) => (
  <Box>
    <Textarea {...(props as TextareaProps)} />
    <Text as="span" fontSize="sm" float="right">
      {props.value.length}/{maxLength} chars
    </Text>
  </Box>
);
