import { Tooltip, TooltipProps } from '@chakra-ui/react';
import { QuestionIcon } from 'icons';

export default function HelpTooltip({
  label,
  placement = 'top',
  ...props
}: Omit<TooltipProps, 'children'>) {
  return (
    <Tooltip
      label={label}
      hasArrow
      arrowSize={10}
      arrowShadowColor="grayscale.800"
      placement={placement}
      shouldWrapChildren
      {...props}
    >
      <QuestionIcon
        ml="sm"
        verticalAlign="middle"
        mt="-0.2em"
        color="primary.main"
        fontSize="0.9em"
      />
    </Tooltip>
  );
}
