import { extendTheme, ThemingProps, theme as defaultTheme } from '@chakra-ui/react';
import {
  createBreakpoints,
  getColor,
  randomColor,
  StyleFunctionProps,
} from '@chakra-ui/theme-tools';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/300.css';

import { CardStyle } from 'components/Card';
import { ProgressTheme } from 'components/ui/Progressbar';
import colors from './colors';
import { FormComponents } from './forms';

function supportsSelector(selector: string) {
  if (typeof window === 'undefined') return true;
  const style = document.createElement('style');
  document.head.appendChild(style);
  try {
    style.sheet?.insertRule(selector + '{}', 0);
  } catch (e) {
    return false;
  } finally {
    document.head.removeChild(style);
  }
  return true;
}
if (!supportsSelector(':focus-visible')) {
  import('focus-visible');
}
const fonts = { mono: `'Menlo', monospace`, body: 'Open Sans', heading: 'Open Sans' };
export const DEVICES = {
  // Min width and above
  mobiles: 0,
  // 360 x 740 Samsung S9+
  // Samsung S21 Ultra 5G	384 x 854
  large_mobiles: 414,
  // 414 x 736 Apple iPhone 6s Plus
  // 428 x 926px Apple iPhone 12 Pro Max
  tablets: 768,
  // 768px Apple iPad Mini
  // 834 x 1112, Apple iPad Air
  // Apple iPad Pro 11"	834 x 1194
  laptops: 992,
  desktops: 1280,
  large_desktops: 1536,
};
const breakpoints = createBreakpoints({
  sm: `${DEVICES.large_mobiles / 16}em`,
  md: `${DEVICES.tablets / 16}em`,
  lg: `${DEVICES.laptops / 16}em`,
  xl: `${DEVICES.desktops / 16}em`,
  ['2xl']: `${DEVICES.large_desktops / 16}em`,
});

const theme = extendTheme({
  styles: {
    global: {
      body: {
        backgroundColor: 'background',
      },
      '.chakra-toast:first-of-type': {
        marginTop: '50px',
      },
      '.sb-main-padded': {
        backgroundColor: 'white',
      },
      [['button', 'a']
        .map(
          (tag) =>
            `${tag}:focus:not(:focus-visible), [data-js-focus-visible] ${tag}:focus:not([data-focus-visible-added])`
        )
        .join(', ')]: {
        boxShadow: 'none !important',
      },
      '.isChromatic .chakra-modal__content': {
        transform: 'none !important',
      },
      '.isChromatic .chakra-spinner, .isChromatic .celsia-spinner': {
        animation: 'none !important',
      },
    },
  },
  space: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '32px',
    xl: '64px',
    '2xl': '80px',
  },
  colors,
  borders: {
    base: `1px solid ${colors.secondary.main}`,
  },
  fontSizes: {
    xs: '9px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    base: '16px',
    xl: '18px',
    '2xl': '24px',
    '3xl': '32px',
    h1: '32px',
    h2: '24px',
    h3: '18px',
    h4: '16px',
  },
  fonts,
  breakpoints,
  components: {
    ...FormComponents,
    Avatar: {
      baseStyle: (props: any) => ({
        container: {
          bg: randomColor({
            string: props.name || ' ',
            colors: [
              '#7450DD',
              '#DD50A5',
              '#45B694',
              '#DD7A50',
              '#DD5050',
              '#6450DD',
              '#17AA52',
              '#266B57',
              '#9D3486',
              '#4D0A82',
            ],
          }),
          color: 'white',
        },
        excessLabel: {
          bg: 'black',
          color: 'white',
          fontSize: 'calc(2rem / 2.5)',
        },
      }),
      sizes: {
        md: {
          container: {
            width: '44px',
            height: '44px',
          },
        },
        lg: {
          container: {
            width: '64px',
            height: '64px',
          },
        },
      },
    },
    Card: CardStyle,
    Badge: {
      baseStyle: { textTransform: 'none', fontWeight: '700' },
      variants: {
        subtle: ({ colorScheme }: ThemingProps) => ({
          color: colorScheme === 'error' ? 'error.400' : 'black',
        }),
      },
      sizes: {
        lg: { fontSize: 'md', p: '5px 16px 6px 16px' },
      },
    },
    Drawer: {
      baseStyle: {
        header: {
          fontSize: '32px',
          fontWeight: '700',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        },
        closeButton: {
          marginLeft: '1em',
          position: 'inherit',
          fontSize: '0.6em',
        },
      },
      sizes: {
        large: {
          dialog: {
            maxW: '70vw',
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        header: {
          fontSize: '32px',
          fontWeight: '700',
          display: 'flex',
          justifyContent: 'space-between',
        },
        closeButton: {
          position: 'inherit',
        },
      },
    },
    Heading: {
      sizes: {
        lg: { fontSize: ['xl', null, '2xl'] },
        h1: { fontSize: 'h1', lineHeight: '44px' },
        h2: { fontSize: 'h2', lineHeight: '32px' },
        h3: { fontSize: 'h3', lineHeight: '25px' },
        h4: { fontSize: 'h4', lineHeight: '22px' },
      },
      variants: {
        section: {
          fontSize: 'h2',
          fontWeight: '600',
          marginBottom: 'md',
        },
      },
    },
    Button: {
      baseStyle: {
        textTransform: 'uppercase',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '100%',
        borderRadius: 'base',
        _disabled: {
          background: 'secondary.light',
          color: 'secondary.main',
          opacity: 1,
        },
        _hover: {
          _disabled: {
            background: 'secondary.light',
            color: 'secondary.main',
            opacity: 1,
          },
        },
      },
      variants: {
        secondary: {
          background: 'secondary.400',
          color: 'primary.300',
          _hover: {
            background: `${colors.primary.light.slice(0, 7)}50`,
            _disabled: {
              background: 'primary.light',
            },
          },
        },
        black: {
          color: 'black',
          textTransform: 'none',
          fontWeight: '400',
          _hover: {
            bg: 'grayscale.200',
            _disabled: {
              bg: 'white',
            },
          },
        },
        link: {
          fontSize: 'inherit',
          fontWeight: 'normal',
          textTransform: 'none',
        },
        flex: {
          display: 'flex',
          height: 'auto',
          textTransform: 'none',
        },
        tab: {
          borderRadius: 0,
          height: 'auto',
        },
        submit: defaultTheme.components.Button.variants.solid,
      },
      sizes: {
        sm: {
          fontSize: '12px',
          padding: '6px 8px',
          height: '24px',
        },
        md: {
          fontSize: '16px',
          padding: '8px 16px',
          height: '32px',
        },
        lg: {
          fontSize: '16px',
          padding: '16px',
        },
      },
      defaultProps: {
        colorScheme: 'primary',
      },
    },

    List: {
      variants: {
        menu: {
          container: {
            display: 'flex',
            listStyle: 'none',
          },
          item: {
            borderBottom: '2px solid transparent',
            padding: 0,
          },
        },
      },
    },
    Link: {
      baseStyle: {
        color: 'black',
      },
      variants: {
        menu: {
          padding: '10px 16px 8px',
          textTransform: 'uppercase',
          fontWeight: '600',
          _hover: { textDecoration: 'none', bg: '#EBF3FF', borderRadius: 'lg' },
        },
      },
    },
    Tabs: {
      variants: {
        line: {
          tablist: {
            borderColor: '#E5E5E5',
          },
          tab: {
            color: 'black',
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            _selected: {
              color: 'primary.main',
              borderColor: 'primary.600',
            },
          },
        },
      },
      sizes: {
        md: {
          tab: {
            fontSize: '16px',
            paddingTop: '4px',
            paddingBottom: '12px',
          },
        },
        lg: {
          tab: {
            fontSize: '16px',
            textTransform: 'uppercase',
            paddingTop: '10px',
            paddingBottom: '8px',
          },
        },
      },
    },

    Table: {
      baseStyle: {
        th: {
          fontSize: 'md',
        },
      },
      sizes: {
        md: {
          th: {
            fontSize: 'sm',
          },
        },
      },
      variants: {
        simple: {
          th: {
            color: 'black',
            textTransform: 'normal',
          },
        },
      },
    },
    Text: {
      baseStyle: {
        color: 'black',
      },
    },

    Alert: {
      variants: {
        'top-accent': {
          container: {
            backgroundColor: 'white',
          },
        },
      },
    },
    Progress: ProgressTheme,

    Tooltip: {
      baseStyle: (props: StyleFunctionProps) => ({
        borderRadius: 'base',
        fontSize: '12px',
        padding: '8px',
        background: getColor(props.theme, 'black'),
        '--tooltip-bg': getColor(props.theme, 'black'),
      }),
    },
    Menu: {
      baseStyle: {
        item: {
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '1em',
          color: 'black',
          px: '16px',
          _focus: {
            bg: 'hoverBg',
          },
        },
        list: {
          border: 'none',
          shadow: '0px 1px 5px rgba(0, 0, 0, 0.25)',
          borderRadius: 'base',
          minWidth: '200px',
        },
        divider: { borderBottomColor: '#E6E6E6' },
      },
      variants: {
        select: {
          item: {
            lineHeight: '1.5em',
            px: 'md',
            py: 'sm',
          },
          list: {
            shadow: '0px 0px 16px rgba(0, 0, 0, 0.08);',
          },
        },
      },
      sizes: {
        lg: {
          item: {
            fontSize: '18px',
          },
        },
      },
    },
    SideMenuItem: {
      baseStyle: {
        transition: 'ease-in 0.2s',
        color: 'black',
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'content-box',
      },
      variants: {
        current: {
          fontWeight: '600',
          borderRight: '3px solid',
          borderColor: 'primary.600',
        },
      },
    },
  },
});
export default theme;
