import {
  VStack,
  Box,
  // Link,
  Text,
  Tooltip,
  TextProps,
  Center,
  HStack,
  BoxProps,
} from '@chakra-ui/react';
import { MultiSegmentProgress } from 'components/ui/Progressbar';
import useAllObjectives from 'hooks/useAllObjectives';
import { groupBy, keyBy } from 'lodash';
import React, { useMemo, useRef, useContext, useCallback } from 'react';
import ScrollSpy from 'react-scrollspy-navigation';
import smartMerge from 'utils/smartMerge';
import StickyScroll from 'components/ui/StickyScroll';
import { CompliantIcon, NonCompliantIcon } from 'icons';

import {
  BAssessment,
  BAssessmentProp,
  Question,
  Objective,
  Tuple,
  ActivityReport,
  GENERAL_ACTIVITY_REF,
} from 'models';
import { StickyMenuContext } from 'components/topMenu/TopMenu';
import { useTranslation } from 'utils/i18n';
import { RouterLink as Link } from 'components/ui/Links';
import {
  isQuestionRequired,
  isQuestionAnswered,
  isQuestionNotAligned,
  isQuestionNotSecondary,
} from 'utils/score';
import { getSubquestionProgress } from 'models/multipleCriteriaQuestion';
import { useNavigate } from 'react-router';

export const createId = (activityRef: string, objectiveKey?: string) =>
  `a${activityRef.replace('.', '-')}${objectiveKey ? `-${objectiveKey}` : ''}`;
export const getId = (activity: ActivityReport['activity'], objectiveKey?: string) =>
  createId(activity.reference, objectiveKey);

export const getQuestionsProgress = (questions: Question[]) => {
  const required = questions.filter(isQuestionRequired);
  const subQuestions = getSubquestionProgress(required);
  const total = required.length + subQuestions.total;
  const answered = required.filter(isQuestionAnswered).length + subQuestions.answered;
  const notAligned =
    (required.filter(isQuestionNotAligned).filter(isQuestionNotSecondary).length +
      subQuestions.notAligned) /
    (total > 0 ? total : 1);
  const progress = total > 0 ? answered / total : 1.0;

  return { total, answered, notAligned, progress };
};

export const ProgressLabel = ({ progress, ...props }: { progress: number } & TextProps) => {
  const { t } = useTranslation('assessment');
  return (
    <Text
      borderRadius="base"
      px="8px"
      py="4px"
      backgroundColor={`assessment.${progress < 1 ? 'todo' : 'done'}.background`}
      color={`assessment.${progress < 1 ? 'todo' : 'done'}.color`}
      textTransform="uppercase"
      whiteSpace="nowrap"
      fontWeight="600"
      display="inline-block"
      {...props}
    >
      {progress < 1 ? t('assessment:progress.todo') : t('assessment:progress.done')}
    </Text>
  );
};

export const AssessmentComplianceIcon = ({
  notAligned,
  progress,
  ...boxProps
}: {
  notAligned: number;
  progress: number;
} & BoxProps) => {
  const { t } = useTranslation('assessment');
  if (notAligned && progress === 1)
    return (
      <Tooltip hasArrow label={t('assessment:completionTooltip.notAligned')}>
        <Box fontSize="lg" {...boxProps}>
          <NonCompliantIcon color="error.main" />
        </Box>
      </Tooltip>
    );
  return (
    <Tooltip
      hasArrow
      label={
        progress === 1
          ? t('assessment:completionTooltip.alignedComplete')
          : t('assessment:completionTooltip.alignedNotComplete')
      }
    >
      <Center>
        <CompliantIcon color={progress === 1 ? 'success.main' : 'primary.600'} w="16px" h="20px" />
      </Center>
    </Tooltip>
  );
};

export const AssessmentProgressMarker = ({
  progress,
  notAligned,
  total,
  answered,
  progressOnly,
}: {
  progress: number;
  notAligned: number;
  total: number;
  answered: number;
  progressOnly?: boolean;
}) => {
  const { t } = useTranslation('assessment');
  if (progressOnly || (progress < 1 && progress > 0)) {
    return (
      <Tooltip label={t('assessment:completed', { answered, total })} shouldWrapChildren>
        <MultiSegmentProgress
          max={100}
          values={{ success: (progress - notAligned) * 100, error: notAligned * 100 }}
          width="100%"
          variant="multiSegment"
          backgroundColor="secondary.main"
          size="xs"
        />
      </Tooltip>
    );
  }
  return <ProgressLabel progress={progress} />;
};
const QuestionListNav = React.forwardRef(
  (
    {
      activity,
      objective,
      questions,
      href,
    }: {
      activity: ActivityReport['activity'];
      objective?: Objective;
      questions: Question[];
      href: string;
    },
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    const qProgress = getQuestionsProgress(questions);
    const navigate = useNavigate();
    const disableScroll = useCallback((node: any) => {
      if (node) {
        node.addEventListener('click', (e: MouseEvent) => {
          navigate(href);
          e.preventDefault();
          e.stopPropagation();
          return false;
        });
      }
    }, []);
    return (
      <Box
        ref={ref}
        width="100%"
        sx={{
          '&.active': {
            backgroundColor: '#CAE2F829',
            fontWeight: 'bold',
          },
        }}
      >
        <HStack
          width="100%"
          justifyContent="stretch"
          alignItems="flex-start"
          ref={disableScroll}
          _hover={{ bg: 'hoverBg', cursor: 'pointer' }}
          padding="md"
        >
          <Box padding="xs">
            <AssessmentComplianceIcon {...qProgress} />
          </Box>
          <VStack alignItems="flex-start" flex="1 1 auto">
            <Link href={href} replace>
              {activity.name}
              {objective ? `/${objective.title}` : ''}
            </Link>
            <Box width="100%" maxWidth="180px">
              <AssessmentProgressMarker {...qProgress} />
            </Box>
          </VStack>
        </HStack>
      </Box>
    );
  }
);

QuestionListNav.displayName = 'QuestionListNav';

export const groupQuestionsByObjective = (questions: Question[], objectives: Objective[]) => {
  const objectivesByKey = keyBy(objectives, 'key');
  return Object.entries(groupBy(questions, 'objective.key')).map(([key, qs]) =>
    Tuple(objectivesByKey[key], qs)
  );
};

function getQuestionsByActivity(report: BAssessment, objectives: Objective[]) {
  return Object.values(report.activities)
    .filter((activity) =>
      report.hasGeneralAssessment ? true : activity.activityRef !== GENERAL_ACTIVITY_REF
    )
    .map((ar) => ({
      questionsByObjective: groupQuestionsByObjective(ar.questions, objectives),
      activity: ar.activity,
    }));
}

export const useObjectivesByActivity = (report: BAssessment) => {
  const prev = useRef<ReturnType<typeof getQuestionsByActivity>>();
  const objectives = useAllObjectives();
  const questionsByActivity = useMemo(() => {
    prev.current = smartMerge(getQuestionsByActivity(report, objectives), prev.current);
    return prev.current;
  }, [report, objectives]);
  return questionsByActivity;
};

export type QuestionsByActivity = ReturnType<typeof useObjectivesByActivity>;

export default function QuestionListMenu({
  showObjectives = true,
  bAssessment,
}: {
  showObjectives: boolean;
} & BAssessmentProp) {
  const unfilteredQuestionsByActivity = useObjectivesByActivity(bAssessment);
  const topMenuHeight = useContext(StickyMenuContext).currentContentOffset;
  return (
    <StickyScroll>
      <VStack>
        <ScrollSpy offsetTop={topMenuHeight}>
          {unfilteredQuestionsByActivity
            .map(({ activity, questionsByObjective }) =>
              showObjectives ? (
                questionsByObjective.map(([objective, questions]) => (
                  <QuestionListNav
                    key={activity.reference + objective.key}
                    activity={activity}
                    objective={objective}
                    questions={questions}
                    href={`#${getId(activity, objective.key)}`}
                    ref={React.createRef()}
                  />
                ))
              ) : (
                <QuestionListNav
                  key={activity.reference}
                  activity={activity}
                  questions={questionsByObjective.flatMap(([_objective, questions]) => questions)}
                  href={`#${getId(activity)}`}
                  ref={React.createRef()}
                />
              )
            )
            .flat()}
        </ScrollSpy>
      </VStack>
    </StickyScroll>
  );
}
