const LanguageDetector = require('i18next-browser-languagedetector');
const { getLangNameFromCode } = require('language-name-map');
const plugins = [];
const isStorybook = typeof process === 'undefined';
const isBrowser = isStorybook || process.browser;
const isDebug = isStorybook || process?.env.NODE_ENV !== 'production';
const fetchCache = {};
if (isBrowser) {
  const Fetch = require('i18next-fetch-backend');
  class SkipENFetch extends Fetch {
    read(language, namespace, callback) {
      const key = `${language}:${namespace}`;
      if (fetchCache[key]) return callback(null, fetchCache[key]);
      if (language == 'en' && namespace.startsWith('db/')) return callback(null, {});
      return super.read(language, namespace, (error, result) => {
        if (!error) fetchCache[key] = result;
        return callback(error, result);
      });
    }
  }
  plugins.push(
    new SkipENFetch(null, {
      loadPath: (lng, namespaces) => {
        return `/locales/${lng}/${namespaces}.json`;
      },
    })
  );
}
const PRODUCTION_LANGUAGES = ['en'];
const ALL_LANGUAGES = ['en', 'no', 'de'];
module.exports = {
  i18n: {
    defaultLocale: 'en',
    locales:
      process.env.NEXT_PUBLIC_VERCEL_ENV == 'production' ? PRODUCTION_LANGUAGES : ALL_LANGUAGES,
  },
  reloadOnPrerender: isDebug,
  debug: isBrowser && isDebug,
  detection: {
    lookupCookie: 'NEXT_LOCALE',
    caches: ['cookie'],
  },
  use: [LanguageDetector.default ?? LanguageDetector, ...plugins],
  //   browserLanguageDetection: false,
  //   serverLanguageDetection: false,
  serializeConfig: false,
  partialBundledLanguages: true,
};
if (!isBrowser) {
  const path = require('path');
  module.exports.i18n.localePath = path.resolve('./public/locales');
}
if (!isStorybook && process?.argv.includes('--print'))
  console.log(
    JSON.stringify(
      module.exports.i18n.locales.map((code) => {
        const lang = getLangNameFromCode(code);
        return [code, `${lang.name} (${lang.native})`];
      })
    )
  );
