import { useState, useEffect } from 'react';
import { setTimeoutInHook } from 'utils/setTimeoutInHook';

const useDebounce = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    return setTimeoutInHook(() => setDebouncedValue(value), delay || 500);
  }, [value, delay]);
  return debouncedValue;
};
export default useDebounce;
