export const gtmTrack = (event: string, data?: any) => {
  try {
    (window as any).dataLayer.push({
      event,
      eventProps: data,
    });
  } catch (error) {
    console.warn('Analytics failed (likely due to being turned off).');
  }
};
