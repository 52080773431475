import { TFunction, i18n } from 'utils/i18n';
import { format } from 'date-fns';
import { I18n } from 'next-i18next';
const languages = {} as any;
let language = 'en';
const LOCALE_MAP = {
  no: 'en-US',
  de: 'de',
  en: 'en-US',
} as any;
export function initDateLocale(_i18n: I18n) {
  language = _i18n.language;
  const dateFnsLocale = LOCALE_MAP[language] ?? 'en-US';
  import(`date-fns/locale/${dateFnsLocale}/index.js`).then((locale) => {
    languages[language] = locale;
  });
}

export function formatPercents(val: number, t?: TFunction) {
  if (t == null) t = i18n?.t;
  const p = (val || 0).toFixed(1);
  if (t) return t('common:percents', { p, defaultValue: '{{p}}%' });
  else return p + '%';
}
export const formatCurrency = (value: number, currency: string) =>
  value.toLocaleString(language, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    style: 'currency',
    currency,
  });

export function formatDateTime(date: Date | string | number) {
  return format(new Date(date), 'Pp', { locale: languages[language] });
}

export const formatDate = (date: Date | string) =>
  format(new Date(date), 'P', { locale: languages[language] });
