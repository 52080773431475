import {
  Heading,
  VStack,
  Text,
  Image,
  Tag,
  Button,
  HStack,
  ButtonProps,
  useDisclosure,
  Center,
} from '@chakra-ui/react';
import { useTranslation } from 'utils/i18n';
import React, { useEffect, useState } from 'react';
import { useCreateDemoCompany, useCreateInitialCompany, User } from 'models';
import { useSetCurrentAssessmentId, useUserLocalSetting } from 'models/localState';
import { useBigToast } from 'hooks/useToast';
import { CelsiaSpinner } from './Loader';
import { useRouter } from 'next/router';
import { gtmTrack } from 'utils/gtm';

export type CompanyStepChoices = 'register' | 'demoTour';

const ChoiceWrapperBox = ({
  children,
  imageUrl,
  heading,
  description,
  buttonText,
  onClick,
}: React.PropsWithChildren<
  {
    imageUrl: string;
    heading: string;
    description: string;
    buttonText: string;
  } & Pick<ButtonProps, 'onClick'>
>) => (
  <VStack boxShadow="0px 0px 57px rgba(0, 0, 0, 0.07)" borderRadius="24px" p="lg" width="100%">
    <Image src={imageUrl} height="147px" />
    <VStack spacing="xs" height="58px">
      <Heading fontSize="h2">{heading}</Heading>
      {children}
    </VStack>
    <Text fontSize="md">{description}</Text>
    <Button size="lg" width="100%" marginTop="32px !important" onClick={onClick}>
      {buttonText}
    </Button>
  </VStack>
);

export const WelcomeScreenTrial = ({
  createCompany,
}: {
  createCompany: (isDemo: boolean) => void;
}) => {
  const { t } = useTranslation();
  return (
    <VStack textAlign="center" spacing="md" px="lg" py="md" maxWidth="850px">
      <Heading fontSize="32px">{t('common:welcomeModal.title')}</Heading>
      <Text fontSize="h2">
        {t('common:welcomeModal.subtitle1')}
        <br />
        {t('common:welcomeModal.subtitle2')}
      </Text>
      <HStack justifyContent="space-between" spacing="lg" mt="64px !important" alignItems="stretch">
        <ChoiceWrapperBox
          imageUrl="graphics/demoTour.png"
          heading={t('common:welcomeModal.choices.demoTour.title')}
          description={t('common:welcomeModal.choices.demoTour.description')}
          buttonText={t('common:welcomeModal.choices.demoTour.action')}
          onClick={() => createCompany(true)}
        >
          <Tag
            bg="rgba(8, 216, 41, 0.1)"
            color="#08D829"
            borderRadius="30px"
            textTransform="uppercase"
            fontSize="md"
            fontWeight="bold"
          >
            {t('common:welcomeModal.choices.demoTour.tag')}
          </Tag>
        </ChoiceWrapperBox>
        <ChoiceWrapperBox
          imageUrl="graphics/startAssessment.png"
          heading={t('common:welcomeModal.choices.startAssessment.title')}
          description={t('common:welcomeModal.choices.startAssessment.description')}
          buttonText={t('common:welcomeModal.choices.startAssessment.action')}
          onClick={() => createCompany(false)}
        />
      </HStack>
    </VStack>
  );
};
export const useFirstCompanyData = () =>
  useUserLocalSetting('first-company', {
    companyName: '',
    isPortfolioOwner: false,
  });

export function FirstCompanyModal({ user }: { user: User }) {
  const createDemoCompany = useCreateDemoCompany();
  const createInitialCompany = useCreateInitialCompany();
  const setCurrentAssessmentId = useSetCurrentAssessmentId();

  const initialCompanyData = useFirstCompanyData()[0];
  const { onOpen, onClose } = useDisclosure();
  const [creating, setCreating] = useState(false);
  const toast = useBigToast();
  const { t } = useTranslation();
  const { replace } = useRouter();

  const setupRealCompany = () => {
    createInitialCompany(
      user.id,
      initialCompanyData?.companyName,
      initialCompanyData?.isPortfolioOwner
    )
      .then((res) => {
        gtmTrack('sign_up_company');
        if (initialCompanyData?.isPortfolioOwner) {
          replace(`/portfolios`);
        } else {
          setCurrentAssessmentId(res.firstBU?.bAssessments[0].cAssessmentId, true);
          replace(`/assessments/${res.firstBU?.bAssessments[0].cAssessmentId}`);
        }
      })
      .catch(() =>
        toast({
          title: t('common:toast.companyCreateFailed.title'),
          description: t('common:toast.companyCreateFailed.description'),
          status: 'error',
        })
      )
      .finally(() => {
        setCreating(false);
      });
  };
  const setupDemoCompany = () => {
    createDemoCompany(user, initialCompanyData?.companyName ?? 'Demo')
      .then((company) => {
        if (company) {
          setCurrentAssessmentId(company.cAssessments[0]?.id, true);
          replace(`/assessments/${company.cAssessments[0]?.id}`);
          toast({
            title: t('common:welcomeModal.demoCreated.title', { companyName: company.name }),
            description: t('common:welcomeModal.demoCreated.description'),
            duration: null,
          });
        } else console.error('Demo company create failed!');
      })
      .finally(() => {
        setCreating(false);
      });
  };
  const createCompany = (isDemo: boolean) => {
    setCreating(true);
    if (isDemo) {
      setupDemoCompany();
    } else {
      setupRealCompany();
    }
  };

  useEffect(() => {
    if (user.companies.length == 0 && user.invitations.length == 0) onOpen();
    else if (!creating) onClose();
  }, [user]);

  return (
    <Center height="100%" mt="5em">
      {!creating ? (
        <WelcomeScreenTrial createCompany={createCompany} />
      ) : (
        <VStack spacing="lg">
          <CelsiaSpinner />
          <Heading as="h3" fontSize="h3">
            {t('common:welcomeModal.lessEffort')}
          </Heading>
          <Heading as="h1" fontSize="h1">
            {t('common:welcomeModal.creating')}
          </Heading>
        </VStack>
      )}
    </Center>
  );
}
