import { ModalProps, Box, Button, HStack } from '@chakra-ui/react';
import { FormikFormField, FormikSubmitButton, VForm } from 'components/ui/forms/FormField';
import { Select } from 'components/ui/forms/Select';
import DefaultModal from 'components/ui/Modal';
import { Formik } from 'formik';
import { useBigToast } from 'hooks/useToast';
import { User, useUpdateUser } from 'models';
import React from 'react';
import { getLanguageMap, useTranslation } from 'utils/i18n';

export default function LanguageEditor({
  isOpen,
  onClose,
  user,
}: Pick<ModalProps, 'isOpen' | 'onClose'> & { user: User }) {
  const { t } = useTranslation();
  const langMap = getLanguageMap();
  const updateUser = useUpdateUser();
  const toast = useBigToast();
  const changeUser = ({ language }: any) =>
    updateUser({ language }, user.id).then(() => {
      toast({ title: t('common:changeLanguage.succeeded') });
      onClose();
    });

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      header={t('common:user.changeLanguage.header')}
      size="xl"
    >
      <Formik
        initialValues={{
          language: user.language,
        }}
        onSubmit={(values) => changeUser(values)}
        key="user"
      >
        <VForm>
          <FormikFormField<string>
            name="language"
            label={t('common:fields.language.label')}
            isRequired
          >
            {/* @ts-ignore */}
            {({ value, setValue }) => (
              <Box width="26em">
                <Select
                  value={{ value, label: langMap[value] }}
                  onChange={(v: any) => v && setValue(v.value)}
                  isSearchable={false}
                  isMulti={false}
                  options={Object.entries(langMap).map(([v, l]) => ({ value: v, label: l }))}
                />
              </Box>
            )}
          </FormikFormField>
          <HStack spacing="md">
            <FormikSubmitButton width="fit-content">
              {t('common:button.saveChanges')}
            </FormikSubmitButton>
            <Button variant="secondary" onClick={onClose} size="lg">
              {t('common:button.cancel')}
            </Button>
          </HStack>
        </VForm>
      </Formik>
    </DefaultModal>
  );
}
