import nextI18NextConfig from '../../../next-i18next.config.js';
import { getLangNameFromCode } from 'language-name-map';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useTranslation as useNextTranslation, TFunction } from 'next-i18next';

export function useTranslation(
  namespaces?: Parameters<typeof useNextTranslation>[0],
  options?: Parameters<typeof useNextTranslation>[1]
) {
  return useNextTranslation(namespaces, { useSuspense: true, ...options });
}
export function getLanguageMap() {
  return Object.fromEntries(
    nextI18NextConfig.i18n.locales.map((locale) => {
      const lang = getLangNameFromCode(locale);
      return [locale, `${lang.name} (${lang.native})`];
    })
  );
}
export const escapeKey = (key: string) => key.replace('.', '$');
export function useChangeLanguage() {
  const { i18n } = useTranslation();
  const { replace, basePath, asPath, query } = useRouter();
  const changeLanguage = useCallback(
    (lang: string) => {
      i18n.changeLanguage(lang);
      replace({ pathname: basePath, query }, asPath, { locale: lang, shallow: true });
    },
    [replace]
  );
  return { changeLanguage, language: i18n.language };
}
export function useENBasedTranslation(namespaces: string | string[], options?: any) {
  const { t, i18n, ...other } = useTranslation(namespaces, {
    useSuspense: true,
    ...options,
  });
  const translateMaybe = useCallback(
    (key: string, opts?: any) => {
      return opts?.defaultValue && i18n.language == 'en' ? opts.defaultValue : t(key, opts);
    },
    [t, i18n.language]
  );
  return { t: translateMaybe, i18n, ...other };
}
export const EU_DATA_NAMESPACE = 'db/eu_data';

export function useEUDataTranslation(type: string) {
  return useENBasedTranslation(EU_DATA_NAMESPACE, { keyPrefix: type });
}

export function translateFields(obj: any, fields: string[], t: TFunction, prefix: string) {
  const translated = Object.fromEntries(
    fields.map((f) => [f, obj[f] ? t(prefix + '.' + f, { defaultValue: obj[f] }) : obj[f]])
  );
  return { ...obj, ...translated };
}
export function translateField(obj: any, field: string, t: TFunction, key: string) {
  return { ...obj, [field]: obj[field] ? t(key, { defaultValue: obj[field] }) : obj[field] };
}

export function tnoop<T>(key: T): T {
  return key;
}
type LastSplit<S extends string> = string extends S
  ? string
  : S extends ''
  ? ''
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
  S extends `${infer _T}.${infer U}`
  ? LastSplit<U>
  : S;

export function tenum<T extends string>(key: T): LastSplit<T> {
  return key.split('.').pop() as any;
}
