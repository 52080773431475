import React, { useMemo, useEffect } from 'react';
import { InMemoryCache, InMemoryCacheConfig } from '@apollo/client';

import { ChakraProvider } from '@chakra-ui/react';
import dynamic from 'next/dynamic';

import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import '../global.css';
import { NhostAuthProvider } from '@nhost/react-auth';
import { NhostApolloProvider } from '@nhost/react-apollo';

import { hotjar } from 'react-hotjar';
import mixpanel from 'mixpanel-browser';

import TagManager from 'react-gtm-module';

import { auth } from '../utils/nhost';
import theme from '../theme';
import { setLoggedInUser } from 'models/localState';
import { appWithTranslation, useTranslation } from 'next-i18next';
import nextI18NextConfig from '../../next-i18next.config.js';
import { initDateLocale } from 'utils/i18n/formatters';
import { gtmTrack } from 'utils/gtm';
import { AppNavigation } from 'components/topMenu/Navigation';
import { useCookieConsent } from 'hooks/useCookieConsent';
import CookieConsentModal from 'components/CookieConsentModal';
import { isProduction } from 'utils/env';

const hotjarId = process.env.NEXT_PUBLIC_HOTJAR_ID;
const hotjarSv = process.env.NEXT_PUBLIC_HOTJAR_SV;
const mixpanelId = process.env.NEXT_PUBLIC_MIXPANEL_ID;

const tagManagerArgs = {
  gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ?? '',
};

// ssr:false is needed for React.Suspense
const Suspense = dynamic(() => import('components/Suspense'), { ssr: false });

export const createApolloCache = (config: InMemoryCacheConfig) =>
  new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          loggedInUser: {
            read() {
              return setLoggedInUser();
            },
          },
        },
      },
      Activity: {
        keyFields: ['reference'],
      },
      Objective: {
        keyFields: ['key'],
      },
      Sector: {
        keyFields: ['reference'],
      },
      QuestionSet: {
        keyFields: ['reference'],
      },
      QuestionChoice: {
        keyFields: false,
      },
    },
    ...config,
  });

function MyApp({ Component, pageProps }: AppProps) {
  const { consent } = useCookieConsent();

  const apolloCache = useMemo(() => {
    const cache = createApolloCache({});
    if (pageProps.initialApolloState) cache.restore(pageProps.initialApolloState);
    return cache;
  }, [pageProps.initialApolloState]);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (consent.analytics) gtmTrack('page_view', { url });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (consent.analytics && isProduction()) {
      hotjar.initialize(Number(hotjarId), Number(hotjarSv));
      TagManager.initialize(tagManagerArgs);
    }

    mixpanel.init(mixpanelId ?? '');
    if (consent.analytics) {
      mixpanel.opt_in_tracking();
    } else {
      mixpanel.opt_out_tracking();
    }
  }, []);

  const { i18n } = useTranslation();

  useEffect(() => {
    initDateLocale(i18n);
  }, [i18n.language]);

  return (
    <NhostAuthProvider auth={auth}>
      <NhostApolloProvider
        auth={auth}
        gqlEndpoint={process.env.NEXT_PUBLIC_HASURA_ENDPOINT_URL}
        cache={apolloCache}
      >
        <ChakraProvider resetCSS theme={theme}>
          <Suspense height="100vh" newContext debugName="mainComponent">
            <AppNavigation>
              <Component {...pageProps} />
              <CookieConsentModal />
            </AppNavigation>
          </Suspense>
        </ChakraProvider>
      </NhostApolloProvider>
    </NhostAuthProvider>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);
