import { Button, Text, HStack, RadioGroup, VStack, Radio } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import { auth } from 'utils/nhost';
import * as Yup from 'yup';
import { useBigToast } from 'hooks/useToast';
import { ButtonLink } from 'components/ui/Links';
import { LoginPage } from './login';
import { FormikFormField, FormikSubmitButton, VForm } from '../components/ui/forms/FormField';
import { FormikNewPasswordField } from '../components/ui/forms/NewPasswordField';
import { isBusinessEmail } from 'utils/validation';
import { User, Invitation } from 'models';
import { useTranslation, TFunction, Trans } from 'utils/i18n';
import { getServerSidePropsFunc } from 'utils/i18n/server';
import { useLogout } from 'models/localState';
import { useFirstCompanyData } from 'components/WelcomeScreen';
import { usePageTitle, withNoNavigation } from 'components/topMenu/Navigation';
import LinkedInTag from 'react-linkedin-insight';

import { gtmTrack } from 'utils/gtm';

const UserRegistrationSchema = (t: TFunction, invitation: Invitation | undefined) =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('login:fields.email.validate'))
      .required(t('login:fields.email.required'))
      .label(t('login:fields.email.label'))
      .matches(invitation ? /.*/ : isBusinessEmail, {
        message: t('login:fields.email.businessEmail'),
      }),
    phoneNumber: Yup.string().label(t('common:fields.phoneNumber.label')),
    password: Yup.string().required(t('login:fields.password.required')),
    strength: Yup.number().min(2, t('login:fields.password.strongerRequired')),
    firstName: Yup.string().trim().required(t('login:fields.firstName.required')),
    lastName: Yup.string().trim().required(t('login:fields.lastName.required')),
    language: Yup.string().trim().required().label(t('login:fields.language.label')),
    ...(!invitation
      ? {
          companyName: Yup.string().label(t('common:fields.companyName.label')).required(),
          isPortfolioOwner: Yup.boolean()
            .label(t('common:fields.isPortfolioOwner.label'))
            .required(),
        }
      : {}),
  });
function CompanyTypeRadio({
  value,
  header,
  text,
}: {
  value: string;
  header: string;
  text: string;
}) {
  return (
    <Radio value={value} alignItems="flex-start">
      <VStack alignItems="flex-start" spacing="sm">
        <Text>{header}</Text>
        <Text fontSize="sm" lineHeight="1.5">
          {text}
        </Text>
      </VStack>
    </Radio>
  );
}
function CompanyTypeField() {
  const { t } = useTranslation();
  return (
    <FormikFormField
      name="isPortfolioOwner"
      label={t('common:fields.isPortfolioOwner.label')}
      isRequired
    >
      {/* @ts-ignore */}
      {(props) => (
        <RadioGroup
          value={props.value ? '1' : '0'}
          onChange={(v) => props.setValue(v === '1')}
          name={props.name}
          as={VStack}
          width="100%"
          spacing="md"
          alignItems="stretch"
          mt="md"
        >
          <CompanyTypeRadio
            value="0"
            header={t('common:fields.isPortfolioOwner.falseLabel')}
            text={t('common:fields.isPortfolioOwner.falseDescription')}
          />
          <CompanyTypeRadio
            value="1"
            header={t('common:fields.isPortfolioOwner.trueLabel')}
            text={t('common:fields.isPortfolioOwner.trueDescription')}
          />
        </RadioGroup>
      )}
    </FormikFormField>
  );
}
export type RegistrationDataType = ReturnType<ReturnType<typeof UserRegistrationSchema>['cast']>;
export const RegisterUserForm = ({
  onSuccess,
  invitation,
}: {
  onSuccess: (data: RegistrationDataType) => any;
  invitation?: Invitation;
}) => {
  const toast = useBigToast();
  const { t, i18n } = useTranslation('login');
  const schema = UserRegistrationSchema(t, invitation);
  const logout = useLogout();

  const handleRegisterUser = async (
    values: Pick<User, 'firstName' | 'lastName' | 'email'> & { password: string }
  ) => {
    const data = schema.cast(values);
    const { email, password, firstName, lastName, phoneNumber, language } = data;
    await logout();
    return auth
      .register({
        email,
        password,
        options: {
          userData: {
            display_name: `${firstName} ${lastName}`,
            email,
            firstName,
            lastName,
            language,
            ...(phoneNumber ? { phoneNumber } : {}),
          },
        },
      })
      .then((res) => {
        if (res.user) {
          gtmTrack('sign_up');
          // Needed for google ads conversion optimization
          gtmTrack('conversion', {
            sendTo: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_SIGN_UP_TAG,
          });
          return onSuccess(schema.cast(values));
        }
        return Promise.resolve(true);
      })
      .catch((error) => {
        toast({
          title: t('login:toast.registrationFailed.title'),
          description:
            error.response?.data?.message ?? t('login:toast.registrationFailed.description'),
          status: 'error',
        });
      });
  };
  return (
    <Formik
      initialValues={{
        email: invitation?.userEmail ?? '',
        phoneNumber: '',
        companyName: invitation?.company.name ?? '',
        isPortfolioOwner: false,
        password: '',
        firstName: '',
        lastName: '',
        language: i18n.language,
      }}
      validationSchema={schema}
      onSubmit={(values) => handleRegisterUser(values)}
      key="user"
    >
      {({ values }) => (
        <VForm bigLabels>
          <HStack>
            <FormikFormField
              name="firstName"
              label={t('login:fields.firstName.label')}
              isRequired
              size="xl"
              autoComplete="given-name"
            />
            <FormikFormField
              name="lastName"
              label={t('login:fields.lastName.label')}
              isRequired
              size="xl"
              autoComplete="family-name"
            />
          </HStack>
          <FormikFormField
            name="phoneNumber"
            label={t('common:fields.phoneNumber.label')}
            size="xl"
            autoComplete="tel"
            placeholder={t('common:fields.phoneNumber.placeholder')}
          />
          <FormikFormField
            name="companyName"
            label={t('common:fields.companyName.label')}
            isRequired
            size="xl"
            isDisabled={!!invitation}
            autoComplete="organization"
          />
          <CompanyTypeField />
          <FormikFormField
            name="email"
            label={t('login:fields.email.label')}
            isRequired
            size="xl"
            isDisabled={!!invitation}
            autoComplete="email"
          />
          <FormikNewPasswordField
            name="password"
            label={t('login:fields.password.label')}
            isRequired
            size="xl"
            userInputs={[values.firstName, values.lastName, values.email]}
            autoComplete="new-password"
          />
          <FormikSubmitButton>
            {invitation ? t('login:createAccount') : t('login:startTrial')}
          </FormikSubmitButton>
        </VForm>
      )}
    </Formik>
  );
};

export default withNoNavigation(function Register() {
  const { push } = useRouter();
  const { t } = useTranslation('login');
  usePageTitle(t('login:registerTitle'));
  const setFirstCompanyData = useFirstCompanyData()[1];

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID) {
      LinkedInTag.init(process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID, 'dc', false); // todo: add cookie consent
    }
  }, []);

  return (
    <LoginPage heading={t('login:registerHeader')}>
      <Text mb="55px">
        {t('login:haveAccount')} <ButtonLink href="/login">{t('login:logIn')}</ButtonLink>
      </Text>
      <RegisterUserForm
        onSuccess={async ({ companyName = '', isPortfolioOwner = false }) => {
          await new Promise((r) => setTimeout(r)); // Wait for rerender with new user-id
          setFirstCompanyData({ companyName, isPortfolioOwner });
          return push({ pathname: '/' });
        }}
      />
      <Text textAlign="center" mt="4">
        <Trans i18nKey="login:termsAgreement">
          By creating an account, you agree to the Celsia
          <Button
            variant="link"
            as="a"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.celsia.io/termsofuse"
          >
            Terms of Use
          </Button>{' '}
          and{' '}
          <Button
            variant="link"
            as="a"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.celsia.io/privacypolicy"
          >
            Privacy Policy
          </Button>
        </Trans>
      </Text>
    </LoginPage>
  );
});

export const getServerSideProps = getServerSidePropsFunc(['common', 'login']);
