import { useQuerySuspense } from 'hooks/useQuerySuspense';

import {
  HArticleFieldsFragment,
  HArticlesQuery,
  ArticlesDocument,
  useArticlesQuery,
  useArticleBodySearchQuery,
  useArticleTitleSearchQuery,
  useTagsWithArticlesQuery,
  HTagFieldsFragment,
  HTagsWithArticlesQuery,
} from './knowledgeCenter.graphql';

import { uniqBy } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from '../utils/mixpanel';
import { useEffect } from 'react';
import { useCurrentCompanyId } from './localState';

export type Article = HArticleFieldsFragment;

export type Tag = HTagFieldsFragment;

export type TagsWithArticles = HTagsWithArticlesQuery;

export function getArticles() {
  const articles = useQuerySuspense<HArticlesQuery>(ArticlesDocument).Article;
  return articles;
}

export function useArticles() {
  const { data, loading } = useArticlesQuery();

  return { articles: data && data.Article ? data.Article : [], articlesAreLoading: loading };
}

export function useArticlesWithTags() {
  const { data, loading } = useTagsWithArticlesQuery();
  const result = data?.Tag ? data.Tag : [];
  return { tags: result, tagsAreLoading: loading };
}

export function useArticleSearch(query: string) {
  const companyId = useCurrentCompanyId();
  const bodySearch = useArticleBodySearchQuery({ variables: { q: `%${query}%` } });

  const bodySearchData = bodySearch.data && bodySearch.data.Article ? bodySearch.data.Article : [];

  const titleSearch = useArticleTitleSearchQuery({ variables: { q: `%${query}%` } });
  const isLoading = bodySearch.loading || titleSearch.loading;

  const titleSearchData =
    titleSearch.data && titleSearch.data.Article ? titleSearch.data.Article : [];

  useEffect(() => {
    mixpanel.track(TRACKING_EVENTS.KNOWLEDGE_BASE.search, { companyId, query });
  }, [query]);
  return {
    searched: uniqBy(bodySearchData.concat(titleSearchData), (item) => item.id),
    searchIsLoading: isLoading,
  };
}
