import { useApolloClient } from '@apollo/client';
import { VStack, Center, HStack, Button, Text } from '@chakra-ui/react';
import { fetchPortfolios } from 'hooks/usePortfolios';
import { ToastType, useBigToast } from 'hooks/useToast';
import { omit } from 'lodash';
import {
  Invitation,
  Tuple,
  useAddUserToCompanyMutation,
  useFindInvitation,
  User,
  useUpsertCompanyInvitation,
} from 'models';
import { TFunction } from 'next-i18next';
import { useRouter } from 'next/router';
import { CompanyAvatar } from 'pages/join-company';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'utils/i18n';
import DefaultModal from './ui/Modal';
import { SwitchIcon } from 'icons';
import { useSwitchCompany } from './topMenu/CompanySwitcher';

function useAcceptInvitation(t: TFunction, toast: ToastType) {
  const client = useApolloClient();
  const [addUserToCompany] = useAddUserToCompanyMutation();
  const switchCompany = useSwitchCompany();
  const onSuccess = (companyName: string, manualRedirect: string | null) => {
    toast({
      title: t('common:toast.success') as string,
      description: t('common:toast.invitationSuccess.description', {
        companyName: companyName,
      }) as string,
      ...(manualRedirect
        ? {
            actions: [
              {
                name: t('common:switchCompany'),
                onClick: () => switchCompany({ id: manualRedirect, isPortfolioOwner: false }),
                icon: <SwitchIcon />,
              },
            ],
          }
        : {}),
    });
  };
  return useCallback(
    (invitation: Invitation, user?: User) => {
      return addUserToCompany({ id: invitation.company.id }, user?.id, false, invitation)
        .then(async ({ data }) => {
          if (invitation?.inviteToChildCompanies) {
            const portfolios = await fetchPortfolios(client, invitation.company.id);
            if (portfolios) {
              const companyIds = portfolios
                .map((portfolio) => portfolio.portfolioCompanies.map((c) => c.company?.id))
                .flat()
                .filter((id) => !!id);
              Promise.all(
                companyIds.map((compId) => addUserToCompany({ id: compId }, user?.id, true))
              );
            }
          }
          const manualRedirect =
            (data?.companyUser?.user?.companyUsers.length ?? 0) > 1 ? invitation.company.id : null;
          return onSuccess(invitation.company.name, manualRedirect);
        })
        .catch(() => {
          toast({
            title: t('common:toast.invitationFailed.title') as string,
            description: t('common:toast.invitationFailed.description') as string,
            status: 'error',
          });
        });
    },
    [addUserToCompany, t, toast]
  );
}
function useRemoveInvitationId() {
  const { replace, query, pathname } = useRouter();
  return Tuple(
    query.invitationId,
    useCallback(
      () => replace({ pathname, query: omit(query, 'invitationId') }, undefined, { shallow: true }),
      [replace, query, pathname]
    )
  );
}
export function AcceptInvitationForm({
  user,
  invitationId,
  onClose,
}: {
  user?: User;
  invitationId: Invitation['id'];
  onClose: () => void;
}) {
  const toast = useBigToast();
  const [updateUserInvite] = useUpsertCompanyInvitation();
  const { invitation, loading: invitationLoading } = useFindInvitation(invitationId);
  const loading = invitationLoading || !user;
  const { t } = useTranslation();
  const [queryInvitationId, removeInvitationId] = useRemoveInvitationId();
  const invalidInvitation = () => {
    removeInvitationId().then(() => {
      toast({
        title: t('common:toast.invitationFailed.title'),
        description: t('common:toast.invitationFailed.description'),
        status: 'error',
      });
      onClose();
    });
  };
  useEffect(() => {
    if (invitationId && !invitationLoading && !invitation) invalidInvitation();
  }, [invitationId, invitationLoading, invitation]);
  const onRejectInvite = () => {
    if (invitation)
      updateUserInvite({ status: 'rejected' }, invitation).then(() => {
        toast({
          title: t('common:toast.invitationRejected.title'),
          description: t('common:toast.invitationRejected.description'),
          status: 'success',
        });
        onClose();
      });
  };
  const acceptInvitation = useAcceptInvitation(t, toast);
  const autoAccept = invitationId == queryInvitationId;
  const autoAcceptMode = useMemo(() => autoAccept, [invitationId]);
  const handleJoinCompany = () => {
    if (invitation && user) acceptInvitation(invitation, user).finally(onClose);
  };
  useEffect(() => {
    if (autoAccept && invitation && user)
      if (invitation.userEmail != user.email) return invalidInvitation();
      else removeInvitationId().then(handleJoinCompany);
  }, [invitation, autoAccept, user]);
  return (
    <VStack spacing="md">
      <Center width="100%" flexDir="column" gap="20px">
        <VStack spacing="md">
          <HStack>
            <Text fontSize="lg">{t('common:invitations.acceptFormHeader')}</Text>
          </HStack>
          <HStack>
            <CompanyAvatar company={invitation?.company} />
          </HStack>
        </VStack>
      </Center>

      <HStack width="100%">
        <Button
          onClick={handleJoinCompany}
          size="lg"
          isDisabled={loading}
          width="50%"
          isLoading={autoAcceptMode}
        >
          {t('common:invitations.acceptButton')}
        </Button>
        <Button
          onClick={onRejectInvite}
          variant="outline"
          size="lg"
          width="50%"
          isDisabled={loading || autoAcceptMode}
        >
          {t('common:invitations.rejectButton')}
        </Button>
      </HStack>
    </VStack>
  );
}

export const CompanyInvitationsModal = ({
  invitationId,
  user,
  onClose,
}: {
  user?: User;
  invitationId?: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <DefaultModal
      isOpen={!!invitationId}
      size="lg"
      header={t('common:invitations.header')}
      onClose={onClose}
    >
      <AcceptInvitationForm user={user} invitationId={invitationId} onClose={onClose} />
    </DefaultModal>
  );
};
