import { useMemo } from 'react';
import { useQuerySuspense } from './useQuerySuspense';
import { AllObjectivesDocument, AllObjectivesQuery, AllObjectivesQueryVariables } from 'models';
import { useEUDataTranslation, translateFields, escapeKey } from 'utils/i18n';

export default function useAllObjectives() {
  const { allObjectives } = useQuerySuspense<AllObjectivesQuery, AllObjectivesQueryVariables>(
    AllObjectivesDocument
  );
  const { t } = useEUDataTranslation('objective');
  return useMemo(
    () =>
      (allObjectives ?? []).map((s) =>
        translateFields(s, ['title', 'description'], t, escapeKey(s.key))
      ),
    [allObjectives, t]
  );
}
