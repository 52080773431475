import { PortfoliosQuery, PortfoliosQueryVariables, CompanyByIdDocument } from 'models';
import { useCurrentCompanyId } from 'models/localState';
import {
  PortfoliosDocument,
  useUploadPortfolioCompanyScoreMutation,
} from 'models/portfolio.graphql';

import { Portfolio } from '../models/portfolio-model';
import { useMemo } from 'react';

import { TaxonomyScore } from 'utils/score';
import { useQuerySuspense } from './useQuerySuspense';
import { ApolloClient } from '@apollo/client';

const portfolioOwnerContext = {
  headers: {
    'x-hasura-role': 'portfolio-owner',
  },
};

const transformPortfolioCompany = (portfolioCompany: Portfolio['portfolioCompanies'][0]) => {
  const isEstimate = !portfolioCompany?.company;
  return {
    ...portfolioCompany,
    scores: isEstimate ? portfolioCompany?.estimateCompany?.scores ?? {} : portfolioCompany.scores,
  };
};

const transformPortfolios = (portfolios: Portfolio[]) => {
  return portfolios.map((portfolio) => ({
    ...portfolio,
    portfolioCompanies: portfolio.portfolioCompanies.map(transformPortfolioCompany),
  }));
};

export const fetchPortfolios = async (client: ApolloClient<object>, companyId: string) => {
  const { data } = await client.query<PortfoliosQuery, PortfoliosQueryVariables>({
    query: PortfoliosDocument,
    variables: {
      companyId,
    },
    context: portfolioOwnerContext,
  });
  return data?.portfolios ?? [];
};

export default function usePortfolios() {
  const companyId = useCurrentCompanyId();
  const { portfolios } = useQuerySuspense<PortfoliosQuery, PortfoliosQueryVariables>(
    PortfoliosDocument,
    {
      variables: {
        companyId,
      },
      context: portfolioOwnerContext,
    }
  );

  const [uploadPortfolioCompanyScoreMutation] = useUploadPortfolioCompanyScoreMutation({
    refetchQueries: [CompanyByIdDocument],
  });
  const portfolioList = useMemo(() => transformPortfolios(portfolios ?? []), [portfolios]);

  const getPortfolio = (id: string) => portfolioList.find((p) => p.id === id);

  type SharedTaxonomyScore = Pick<TaxonomyScore, 'total' | 'eligible' | 'aligned'>;

  const updatePortfolioScore = ({
    portfolioId,
    scores,
  }: {
    portfolioId: string;
    scores: {
      completed: boolean;
      revenue: SharedTaxonomyScore;
      capex: SharedTaxonomyScore;
      opex: SharedTaxonomyScore;
    };
  }) =>
    uploadPortfolioCompanyScoreMutation({
      variables: {
        portfolioId,
        companyId,
        scores,
      },
    });

  return { portfolios: portfolioList, getPortfolio, updatePortfolioScore };
}
