/* 56bc63aef3e8be7e077642dd34f21446b5c7f4ec
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HCommentFieldsFragment = { id: any, createdAt: any, updatedAt: any, authorId: any, data: string, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } };

export type HShortUserFragment = { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined };

export type HThreadUpdateFragment = { id: any, comments: Array<{ id: any }>, comments_aggregate: { aggregate?: { count: number } | null | undefined } };

export type HThreadFieldsFragment = { id: any, companyId: any, answerId?: any | null | undefined, comments: Array<{ id: any, createdAt: any, updatedAt: any, authorId: any, data: string, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }>, subscribers: Array<{ userId: any }>, comments_aggregate: { aggregate?: { count: number } | null | undefined } };

export type HNotificationFieldsFragment = { id: any, createdAt: any, isRead: boolean, comment: { id: any, data: string, createdAt: any, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, thread: { answer?: { question: { uniqueId: string }, activityReport?: { activity: { reference: string }, bAssessment: { id: any, cAssessmentId: any, businessUnit?: { id: any, name: string, company: { id: any, name: string } } | null | undefined } } | null | undefined } | null | undefined } } };

export type HGetThreadQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HGetThreadQuery = { thread?: { id: any, companyId: any, answerId?: any | null | undefined, comments: Array<{ id: any, createdAt: any, updatedAt: any, authorId: any, data: string, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }>, subscribers: Array<{ userId: any }>, comments_aggregate: { aggregate?: { count: number } | null | undefined } } | null | undefined };

export type HThreadSubscriptionVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HThreadSubscription = { thread?: { id: any, companyId: any, answerId?: any | null | undefined, comments: Array<{ id: any, createdAt: any, updatedAt: any, authorId: any, data: string, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }>, subscribers: Array<{ userId: any }>, comments_aggregate: { aggregate?: { count: number } | null | undefined } } | null | undefined };

export type HReadUserNotificationMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type HReadUserNotificationMutation = { notifications?: { notifications: Array<{ id: any, isRead: boolean }> } | null | undefined };

export type HUpsertThreadMutationVariables = Types.Exact<{
  thread: Types.HThread_Insert_Input;
}>;


export type HUpsertThreadMutation = { thread?: { id: any, companyId: any, answerId?: any | null | undefined, answer?: { id: any, thread?: { id: any } | null | undefined } | null | undefined, comments: Array<{ id: any, createdAt: any, updatedAt: any, authorId: any, data: string, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }>, subscribers: Array<{ userId: any }>, comments_aggregate: { aggregate?: { count: number } | null | undefined } } | null | undefined };

export type HAddCommentMutationVariables = Types.Exact<{
  input: Types.HComment_Insert_Input;
  subscription: Types.HThreadSubscriber_Insert_Input;
}>;


export type HAddCommentMutation = { createComment?: { id: any, createdAt: any, updatedAt: any, authorId: any, data: string, thread: { id: any, comments: Array<{ id: any }>, comments_aggregate: { aggregate?: { count: number } | null | undefined } }, notificationsSent: { aggregate?: { count: number } | null | undefined }, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | null | undefined, subscription?: { id: any, isSubscribed: boolean, userId: any } | null | undefined };

export type HRemoveCommentMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HRemoveCommentMutation = { deleteComment?: { thread: { id: any, comments: Array<{ id: any }>, comments_aggregate: { aggregate?: { count: number } | null | undefined } } } | null | undefined };

export type HNotificationsSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type HNotificationsSubscription = { notifications: Array<{ id: any, createdAt: any, isRead: boolean, comment: { id: any, data: string, createdAt: any, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, thread: { answer?: { question: { uniqueId: string }, activityReport?: { activity: { reference: string }, bAssessment: { id: any, cAssessmentId: any, businessUnit?: { id: any, name: string, company: { id: any, name: string } } | null | undefined } } | null | undefined } | null | undefined } } }> };

export const ThreadUpdateFragmentDoc = gql`
    fragment ThreadUpdate on Thread {
  id
  comments {
    id
  }
  comments_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const ShortUserFragmentDoc = gql`
    fragment ShortUser on users {
  id
  email
  avatar_url
  firstName
  lastName
}
    `;
export const CommentFieldsFragmentDoc = gql`
    fragment CommentFields on Comment {
  id
  createdAt
  updatedAt
  authorId
  author {
    ...ShortUser
  }
  data
}
    ${ShortUserFragmentDoc}`;
export const ThreadFieldsFragmentDoc = gql`
    fragment ThreadFields on Thread {
  id
  companyId
  answerId
  ...ThreadUpdate
  comments {
    ...CommentFields
  }
  subscribers(where: {isSubscribed: {_eq: true}}) {
    userId
  }
}
    ${ThreadUpdateFragmentDoc}
${CommentFieldsFragmentDoc}`;
export const NotificationFieldsFragmentDoc = gql`
    fragment NotificationFields on UserNotification {
  id
  createdAt
  isRead
  comment {
    id
    data
    createdAt
    author {
      ...ShortUser
    }
    thread {
      answer {
        question {
          uniqueId
        }
        activityReport {
          activity {
            reference
          }
          bAssessment {
            id
            cAssessmentId
            businessUnit {
              id
              name
              company {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    ${ShortUserFragmentDoc}`;
export const GetThreadDocument = gql`
    query getThread($id: uuid!) {
  thread: Thread_by_pk(id: $id) {
    ...ThreadFields
  }
}
    ${ThreadFieldsFragmentDoc}`;

/**
 * __useGetThreadQuery__
 *
 * To run a query within a React component, call `useGetThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetThreadQuery(baseOptions: Apollo.QueryHookOptions<HGetThreadQuery, HGetThreadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HGetThreadQuery, HGetThreadQueryVariables>(GetThreadDocument, options);
      }
export function useGetThreadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HGetThreadQuery, HGetThreadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HGetThreadQuery, HGetThreadQueryVariables>(GetThreadDocument, options);
        }
export type GetThreadQueryHookResult = ReturnType<typeof useGetThreadQuery>;
export type GetThreadLazyQueryHookResult = ReturnType<typeof useGetThreadLazyQuery>;
export type GetThreadQueryResult = Apollo.QueryResult<HGetThreadQuery, HGetThreadQueryVariables>;
export const ThreadDocument = gql`
    subscription Thread($id: uuid!) {
  thread: Thread_by_pk(id: $id) {
    ...ThreadFields
  }
}
    ${ThreadFieldsFragmentDoc}`;

/**
 * __useThreadSubscription__
 *
 * To run a query within a React component, call `useThreadSubscription` and pass it any options that fit your needs.
 * When your component renders, `useThreadSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useThreadSubscription(baseOptions: Apollo.SubscriptionHookOptions<HThreadSubscription, HThreadSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<HThreadSubscription, HThreadSubscriptionVariables>(ThreadDocument, options);
      }
export type ThreadSubscriptionHookResult = ReturnType<typeof useThreadSubscription>;
export type ThreadSubscriptionResult = Apollo.SubscriptionResult<HThreadSubscription>;
export const ReadUserNotificationDocument = gql`
    mutation readUserNotification($ids: [uuid!]!) {
  notifications: update_UserNotification(
    _set: {isRead: true}
    where: {id: {_in: $ids}}
  ) {
    notifications: returning {
      id
      isRead
    }
  }
}
    `;
export type HReadUserNotificationMutationFn = Apollo.MutationFunction<HReadUserNotificationMutation, HReadUserNotificationMutationVariables>;

/**
 * __useReadUserNotificationMutation__
 *
 * To run a mutation, you first call `useReadUserNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadUserNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readUserNotificationMutation, { data, loading, error }] = useReadUserNotificationMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useReadUserNotificationMutation(baseOptions?: Apollo.MutationHookOptions<HReadUserNotificationMutation, HReadUserNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HReadUserNotificationMutation, HReadUserNotificationMutationVariables>(ReadUserNotificationDocument, options);
      }
export type ReadUserNotificationMutationHookResult = ReturnType<typeof useReadUserNotificationMutation>;
export type ReadUserNotificationMutationResult = Apollo.MutationResult<HReadUserNotificationMutation>;
export type ReadUserNotificationMutationOptions = Apollo.BaseMutationOptions<HReadUserNotificationMutation, HReadUserNotificationMutationVariables>;
export const UpsertThreadDocument = gql`
    mutation upsertThread($thread: Thread_insert_input!) {
  thread: insert_Thread_one(
    object: $thread
    on_conflict: {constraint: Thread_answerId_key, update_columns: [answerId]}
  ) {
    ...ThreadFields
    answer {
      id
      thread {
        id
      }
    }
  }
}
    ${ThreadFieldsFragmentDoc}`;
export type HUpsertThreadMutationFn = Apollo.MutationFunction<HUpsertThreadMutation, HUpsertThreadMutationVariables>;

/**
 * __useUpsertThreadMutation__
 *
 * To run a mutation, you first call `useUpsertThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertThreadMutation, { data, loading, error }] = useUpsertThreadMutation({
 *   variables: {
 *      thread: // value for 'thread'
 *   },
 * });
 */
export function useUpsertThreadMutation(baseOptions?: Apollo.MutationHookOptions<HUpsertThreadMutation, HUpsertThreadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpsertThreadMutation, HUpsertThreadMutationVariables>(UpsertThreadDocument, options);
      }
export type UpsertThreadMutationHookResult = ReturnType<typeof useUpsertThreadMutation>;
export type UpsertThreadMutationResult = Apollo.MutationResult<HUpsertThreadMutation>;
export type UpsertThreadMutationOptions = Apollo.BaseMutationOptions<HUpsertThreadMutation, HUpsertThreadMutationVariables>;
export const AddCommentDocument = gql`
    mutation addComment($input: Comment_insert_input!, $subscription: ThreadSubscriber_insert_input!) {
  createComment: insert_Comment_one(object: $input) {
    ...CommentFields
    thread {
      ...ThreadUpdate
    }
    notificationsSent: notifications_aggregate {
      aggregate {
        count
      }
    }
  }
  subscription: insert_ThreadSubscriber_one(
    object: $subscription
    on_conflict: {constraint: ThreadSubscriber_threadId_userId_c1ed612a_uniq, update_columns: [isSubscribed]}
  ) {
    id
    isSubscribed
    userId
  }
}
    ${CommentFieldsFragmentDoc}
${ThreadUpdateFragmentDoc}`;
export type HAddCommentMutationFn = Apollo.MutationFunction<HAddCommentMutation, HAddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<HAddCommentMutation, HAddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HAddCommentMutation, HAddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<HAddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<HAddCommentMutation, HAddCommentMutationVariables>;
export const RemoveCommentDocument = gql`
    mutation removeComment($id: uuid!) {
  deleteComment: delete_Comment_by_pk(id: $id) {
    thread {
      ...ThreadUpdate
    }
  }
}
    ${ThreadUpdateFragmentDoc}`;
export type HRemoveCommentMutationFn = Apollo.MutationFunction<HRemoveCommentMutation, HRemoveCommentMutationVariables>;

/**
 * __useRemoveCommentMutation__
 *
 * To run a mutation, you first call `useRemoveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommentMutation, { data, loading, error }] = useRemoveCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCommentMutation(baseOptions?: Apollo.MutationHookOptions<HRemoveCommentMutation, HRemoveCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HRemoveCommentMutation, HRemoveCommentMutationVariables>(RemoveCommentDocument, options);
      }
export type RemoveCommentMutationHookResult = ReturnType<typeof useRemoveCommentMutation>;
export type RemoveCommentMutationResult = Apollo.MutationResult<HRemoveCommentMutation>;
export type RemoveCommentMutationOptions = Apollo.BaseMutationOptions<HRemoveCommentMutation, HRemoveCommentMutationVariables>;
export const NotificationsDocument = gql`
    subscription notifications {
  notifications: UserNotification(
    where: {comment: {thread: {answer: {activityReport: {bAssessment: {deletedAt: {_is_null: true}}}}}}}
  ) {
    ...NotificationFields
  }
}
    ${NotificationFieldsFragmentDoc}`;

/**
 * __useNotificationsSubscription__
 *
 * To run a query within a React component, call `useNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<HNotificationsSubscription, HNotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<HNotificationsSubscription, HNotificationsSubscriptionVariables>(NotificationsDocument, options);
      }
export type NotificationsSubscriptionHookResult = ReturnType<typeof useNotificationsSubscription>;
export type NotificationsSubscriptionResult = Apollo.SubscriptionResult<HNotificationsSubscription>;