import { Box, BoxProps, useStyleConfig, ThemingProps } from '@chakra-ui/react';

export interface CardProps extends BoxProps, ThemingProps {
  hoverable?: boolean;
}
export const CardStyle = {
  baseStyle: {
    px: 8,
    py: 12,
    background: 'white',
    boxShadow: 'md',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 'base',
  },
  variants: {
    stats: {
      p: '16px',
      border: '1px solid #F1F1F1',
      background: '#FAFBFB',
    },
  },
};

const Card = (props: CardProps) => {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig('Card', { variant });
  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
};

export default Card;
