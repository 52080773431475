/* 245779af8f0a6fef5eeac9c1c849f3abfd73a8b3
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HFileFieldsFragment = { id: any, title: string, description: string, url: string, token: string, source: string, tags: any, creationDate?: any | null | undefined, updatedAt: any, uploadedBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } };

export type HShortUserFragment = { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined };

export type HAddFileMutationVariables = Types.Exact<{
  input: Types.HFile_Insert_Input;
}>;


export type HAddFileMutation = { file?: { id: any, title: string, description: string, url: string, token: string, source: string, tags: any, creationDate?: any | null | undefined, updatedAt: any, uploadedBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | null | undefined };

export type HRemoveFileMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HRemoveFileMutation = { file?: { id: any, title: string, description: string, url: string, token: string, source: string, tags: any, creationDate?: any | null | undefined, updatedAt: any, uploadedBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | null | undefined };

export type HCompanyFilesQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type HCompanyFilesQuery = { files: Array<{ id: any, title: string, description: string, url: string, token: string, source: string, tags: any, creationDate?: any | null | undefined, updatedAt: any, uploadedBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }> };

export const ShortUserFragmentDoc = gql`
    fragment ShortUser on users {
  id
  email
  avatar_url
  firstName
  lastName
}
    `;
export const FileFieldsFragmentDoc = gql`
    fragment FileFields on File {
  id
  title
  description
  url
  token
  source
  tags
  creationDate
  updatedAt
  uploadedBy {
    ...ShortUser
  }
}
    ${ShortUserFragmentDoc}`;
export const AddFileDocument = gql`
    mutation addFile($input: File_insert_input!) {
  file: insert_File_one(
    object: $input
    on_conflict: {constraint: File_pkey, update_columns: [creationDate, description, source, tags, title, token, uploadedById, url]}
  ) {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;
export type HAddFileMutationFn = Apollo.MutationFunction<HAddFileMutation, HAddFileMutationVariables>;

/**
 * __useAddFileMutation__
 *
 * To run a mutation, you first call `useAddFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFileMutation, { data, loading, error }] = useAddFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFileMutation(baseOptions?: Apollo.MutationHookOptions<HAddFileMutation, HAddFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HAddFileMutation, HAddFileMutationVariables>(AddFileDocument, options);
      }
export type AddFileMutationHookResult = ReturnType<typeof useAddFileMutation>;
export type AddFileMutationResult = Apollo.MutationResult<HAddFileMutation>;
export type AddFileMutationOptions = Apollo.BaseMutationOptions<HAddFileMutation, HAddFileMutationVariables>;
export const RemoveFileDocument = gql`
    mutation removeFile($id: uuid!) {
  file: delete_File_by_pk(id: $id) {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;
export type HRemoveFileMutationFn = Apollo.MutationFunction<HRemoveFileMutation, HRemoveFileMutationVariables>;

/**
 * __useRemoveFileMutation__
 *
 * To run a mutation, you first call `useRemoveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFileMutation, { data, loading, error }] = useRemoveFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveFileMutation(baseOptions?: Apollo.MutationHookOptions<HRemoveFileMutation, HRemoveFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HRemoveFileMutation, HRemoveFileMutationVariables>(RemoveFileDocument, options);
      }
export type RemoveFileMutationHookResult = ReturnType<typeof useRemoveFileMutation>;
export type RemoveFileMutationResult = Apollo.MutationResult<HRemoveFileMutation>;
export type RemoveFileMutationOptions = Apollo.BaseMutationOptions<HRemoveFileMutation, HRemoveFileMutationVariables>;
export const CompanyFilesDocument = gql`
    query CompanyFiles($companyId: uuid!) {
  files: File(where: {companyId: {_eq: $companyId}}) {
    ...FileFields
  }
}
    ${FileFieldsFragmentDoc}`;

/**
 * __useCompanyFilesQuery__
 *
 * To run a query within a React component, call `useCompanyFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFilesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyFilesQuery(baseOptions: Apollo.QueryHookOptions<HCompanyFilesQuery, HCompanyFilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HCompanyFilesQuery, HCompanyFilesQueryVariables>(CompanyFilesDocument, options);
      }
export function useCompanyFilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HCompanyFilesQuery, HCompanyFilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HCompanyFilesQuery, HCompanyFilesQueryVariables>(CompanyFilesDocument, options);
        }
export type CompanyFilesQueryHookResult = ReturnType<typeof useCompanyFilesQuery>;
export type CompanyFilesLazyQueryHookResult = ReturnType<typeof useCompanyFilesLazyQuery>;
export type CompanyFilesQueryResult = Apollo.QueryResult<HCompanyFilesQuery, HCompanyFilesQueryVariables>;