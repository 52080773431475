import { useAuth } from '@nhost/react-auth';
import MenuWrapper, { useMenuContext } from 'components/topMenu/MenuWrapper';
import { useRouter } from 'next/router';
import React, {
  useState,
  useMemo,
  useEffect,
  useContext,
  SetStateAction,
  useLayoutEffect,
} from 'react';
import { TopMenuItem } from './TopMenu';
import Loader from 'components/Loader';
import { useTranslation } from 'utils/i18n';
import NextHead from 'next/head';
import { getModuleName, ModulesMenu, ModuleType } from './ModulesMenu';
import { useMountedState } from 'react-use';
import { ErrorBoundary } from 'react-error-boundary';
import { useCurrentCompanyId, useSetCurrentCompanyId } from 'models/localState';
import { COMPANY_ID_NOT_SET, COMPANY_NOT_FOUND } from 'components/Suspense';
import { isCelsiaSupport } from 'utils/support';
import useLoggedInUser from 'hooks/useLoggedInUser';
import DefaultModal from 'components/ui/Modal';

const NavigationContext = React.createContext({
  setCurrentModule: (_module: SetStateAction<ModuleType>) => {},
  setMenuItems: (_items: SetStateAction<TopMenuItem[] | null>) => {},
  setTitle: (_title: SetStateAction<string>) => {},
});

type PageTitleType = {
  title?: string;
};

export function AppNavigation({
  children,
  title: initialTitle,
}: React.PropsWithChildren<PageTitleType>) {
  const [module, setCurrentModule] = useState<ModuleType | null>(null);
  const [menuItems, setMenuItems] = useState<TopMenuItem[] | null>([]);
  const [title, setTitle] = useState(initialTitle ?? '');
  const setters = { setCurrentModule, setMenuItems, setTitle };
  const navigationContext = useMemo(() => setters, Object.values(setters));
  return (
    <NavigationContext.Provider value={navigationContext}>
      <NextHead>
        <title key="title">Celsia {title ? ` - ${title}` : ''}</title>
      </NextHead>
      <ModulesMenu currentModule={module}>
        {menuItems ? <MenuWrapper>{children}</MenuWrapper> : children}
      </ModulesMenu>
    </NavigationContext.Provider>
  );
}
export type ModulePageProps = {
  moduleId?: ModuleType;
  menuItems?: TopMenuItem[];
  loginRequired?: boolean;
  title?: string;
};
export function ModulePage({
  moduleId,
  menuItems = [],
  loginRequired = true,
  children,
  title,
}: React.PropsWithChildren<ModulePageProps>) {
  const navigationContext = useContext(NavigationContext);
  const { t } = useTranslation();
  const menuContext = useMenuContext();
  const { pathname, push } = useRouter();
  const { signedIn } = useAuth();
  const isMounted = useMountedState();
  const { user: currentUser } = useLoggedInUser();

  useLayoutEffect(() => {
    if (moduleId !== undefined) {
      navigationContext.setCurrentModule(moduleId);
      navigationContext.setTitle(getModuleName(t, moduleId));
      menuContext.setTitle('');
      menuContext.setActionBar(null);
      menuContext.setSideMenu(null);
    }
  }, [moduleId, menuContext]);
  useLayoutEffect(() => {
    if (title !== undefined) navigationContext.setTitle(title);
  }, [title, navigationContext]);
  useLayoutEffect(() => {
    if (menuItems !== undefined) navigationContext.setMenuItems(loginRequired ? menuItems : null);
  }, [menuItems, navigationContext, loginRequired]);

  useEffect(() => {
    if (isMounted() && loginRequired && signedIn === false && pathname != '/login') {
      push('/login');
    }
  }, [signedIn, push, loginRequired, isMounted()]);
  const currentCompanyId = useCurrentCompanyId();
  const setCurrentCompanyId = useSetCurrentCompanyId();
  if (loginRequired && signedIn && !isCelsiaSupport(currentUser?.email)) {
    return (
      <DefaultModal isOpen={true} header="Scheduled maintenance">
        We&apos;re doing some planned maintenance right now. <br />
        Please come back after 11pm CET and check out our new features!
      </DefaultModal>
    );
  }
  if (loginRequired && !signedIn) {
    return <Loader height="100vh" />;
  }
  return (
    <ErrorBoundary
      resetKeys={[currentCompanyId]}
      fallbackRender={({ error }) => {
        if (error.name === COMPANY_ID_NOT_SET) {
          return <></>;
        } else if (error.name === COMPANY_NOT_FOUND) {
          console.error(error.name);
          setCurrentCompanyId('');
          push('/');
          return <></>;
        }
        throw error;
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
export function usePageTitle(title?: string | null) {
  const navigationContext = useContext(NavigationContext);
  useEffect(() => {
    if (title != null) navigationContext.setTitle(title);
  }, [title]);
}
export function NoNavigationPage(props: React.PropsWithChildren<ModulePageProps>) {
  return <ModulePage moduleId={null} loginRequired={false} {...props} />;
}
export function TaxonomyModulePage(props: React.PropsWithChildren<ModulePageProps>) {
  return <ModulePage {...props} moduleId={'assessments'} loginRequired={true} />;
}

export function BusinessUnitsPage(props: React.PropsWithChildren<ModulePageProps>) {
  return <ModulePage {...props} moduleId={'businessUnits'} loginRequired={true} />;
}

export function PortfoliosPage(props: React.PropsWithChildren<ModulePageProps>) {
  return <ModulePage {...props} moduleId={'portfolio'} loginRequired={true} />;
}

export function withModulePage<P>(
  Component: React.ComponentType<React.PropsWithChildren<P>>,
  moduleProps?: ModulePageProps,
  PageComponent = ModulePage
) {
  const wrapped = (props: P) => {
    return (
      <PageComponent {...moduleProps}>
        <Component {...props} />
      </PageComponent>
    );
  };
  return wrapped;
}
export function withTaxonomyModule<P>(
  Component: React.ComponentType<React.PropsWithChildren<P>>,
  moduleProps?: ModulePageProps
) {
  return withModulePage(Component, moduleProps, TaxonomyModulePage);
}

export function withBusinessUnitsModule<P>(
  Component: React.ComponentType<React.PropsWithChildren<P>>,
  moduleProps?: ModulePageProps
) {
  return withModulePage(Component, moduleProps, BusinessUnitsPage);
}

export function withPortfoliosModule<P>(
  Component: React.ComponentType<React.PropsWithChildren<P>>,
  moduleProps?: ModulePageProps
) {
  return withModulePage(Component, moduleProps, PortfoliosPage);
}

export function withNoNavigation<P>(
  Component: React.ComponentType<React.PropsWithChildren<P>>,
  moduleProps?: ModulePageProps
) {
  const wrapped = (props: P) => (
    <NoNavigationPage {...moduleProps}>
      <Component {...props} />
    </NoNavigationPage>
  );
  return wrapped;
}
