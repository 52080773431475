import useCookie, { getCookie } from 'react-use-cookie';
import Router from 'next/router';

export const CONSENT_COOKIE_KEY = 'celsia-app-cookie-consent';

export type CookieConsent = {
  isSetup: boolean;
  essential: true;
  marketing: boolean;
  analytics: boolean;
  functional: boolean;
};

export const DEFAULT_COOKIE_CONSENT: CookieConsent = {
  isSetup: false,
  essential: true,
  marketing: false,
  analytics: false,
  functional: false,
};

export const useCookieConsent = () => {
  const [consentCookie, setConsentCookie] = useCookie(
    CONSENT_COOKIE_KEY,
    JSON.stringify(DEFAULT_COOKIE_CONSENT)
  );
  const onUpdate = (newConsent: CookieConsent) => {
    setConsentCookie(JSON.stringify(newConsent));
    Router.reload();
  };
  return { consent: JSON.parse(consentCookie) as CookieConsent, onUpdate };
};

export const getConsentCookie = () => {
  const cookie = getCookie(CONSENT_COOKIE_KEY, JSON.stringify(DEFAULT_COOKIE_CONSENT));
  return JSON.parse(cookie) as CookieConsent;
};
