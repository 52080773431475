/* f683c28b7abb6dd81e1d4920eb66b5095da9e394
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HBusinessUnitDistributionKeyFieldsFragment = { id: any, receivingBusinessUnitId: any, activityRef: string, revenuePercentage: any, opexPercentage: any, capexPercentage: any };

export type HBusinessUnitFieldsFragment = { id: any, name: string, description: string, businessArea: string, sectors: any, createdAt: any, isSupporting: boolean, labels: any, contactPerson: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, company: { id: any, name: string } };

export type HShortUserFragment = { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined };

export type HCAssessmentFieldsFragment = { id: any, startDate: any, endDate: any, createdAt: any, aggregate: { id: any, title: string, interval: string, contactPerson?: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } | null | undefined }, bAssesssments: Array<{ id: any, businessUnitId?: any | null | undefined }>, deletedBAssessments: Array<{ id: any, businessUnitId?: any | null | undefined }> };

export type HCompanyFieldsFragment = { id: any, name: string, currency: string, logoUrl?: string | null | undefined, isOnTrial: boolean, isPortfolioOwner: boolean, trialEndDate: any, settings: any, businessUnits: Array<{ id: any, name: string, description: string, businessArea: string, sectors: any, createdAt: any, isSupporting: boolean, labels: any, contactPerson: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, company: { id: any, name: string } }>, users: Array<{ userId: any, role: Types.HUserRole_Enum, user: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }>, invitations: Array<{ userEmail: string, status: string, id: any }>, portfolioMemberships: Array<{ portfolioId: any, scores: any, portfolio: { name: string, ownerCompany: { id: any, name: string } } }>, portfolios: Array<{ name: string }> };

export type HCompanyByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HCompanyByIdQuery = { company?: { id: any, name: string, currency: string, logoUrl?: string | null | undefined, isOnTrial: boolean, isPortfolioOwner: boolean, trialEndDate: any, settings: any, cAssessments: Array<{ id: any, startDate: any, endDate: any, createdAt: any, aggregate: { id: any, title: string, interval: string, contactPerson?: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } | null | undefined }, bAssesssments: Array<{ id: any, businessUnitId?: any | null | undefined }>, deletedBAssessments: Array<{ id: any, businessUnitId?: any | null | undefined }> }>, businessUnits: Array<{ id: any, name: string, description: string, businessArea: string, sectors: any, createdAt: any, isSupporting: boolean, labels: any, contactPerson: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, company: { id: any, name: string } }>, users: Array<{ userId: any, role: Types.HUserRole_Enum, user: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }>, invitations: Array<{ userEmail: string, status: string, id: any }>, portfolioMemberships: Array<{ portfolioId: any, scores: any, portfolio: { name: string, ownerCompany: { id: any, name: string } } }>, portfolios: Array<{ name: string }> } | null | undefined };

export type HBusinessUnitQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HBusinessUnitQuery = { businessUnit?: { companyId: any, contactPersonId: any, id: any, name: string, description: string, businessArea: string, sectors: any, createdAt: any, isSupporting: boolean, labels: any, bAssessments: Array<{ cAssessmentId: any, activityReports: Array<{ activityRef: string, financials?: { id: any, revenue: any, capex: any, opex: any, adaptationCapex: any, adaptationOpex: any, isEstimate: boolean } | null | undefined, answers: Array<{ data?: any | null | undefined, answeredById: any, questionId: any, flagged?: boolean | null | undefined, attachments: Array<{ fileId: any }>, notes: Array<{ updatedAt: any, body: string, authorId: any }> }> }> }>, contactPerson: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, company: { id: any, name: string } } | null | undefined };

export type HFinancialsFieldsFragment = { id: any, revenue: any, capex: any, opex: any, adaptationCapex: any, adaptationOpex: any, isEstimate: boolean };

export type HUserInvitationsQueryVariables = Types.Exact<{
  status: Types.Scalars['String'];
  userId: Types.Scalars['uuid'];
}>;


export type HUserInvitationsQuery = { invitations: Array<{ id: any, status: string }> };

export type HUpdateCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  company?: Types.Maybe<Types.HCompany_Set_Input>;
}>;


export type HUpdateCompanyMutation = { company?: { id: any, name: string, currency: string, logoUrl?: string | null | undefined, isOnTrial: boolean, isPortfolioOwner: boolean, trialEndDate: any, settings: any, businessUnits: Array<{ id: any, name: string, description: string, businessArea: string, sectors: any, createdAt: any, isSupporting: boolean, labels: any, contactPerson: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, company: { id: any, name: string } }>, users: Array<{ userId: any, role: Types.HUserRole_Enum, user: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }>, invitations: Array<{ userEmail: string, status: string, id: any }>, portfolioMemberships: Array<{ portfolioId: any, scores: any, portfolio: { name: string, ownerCompany: { id: any, name: string } } }>, portfolios: Array<{ name: string }> } | null | undefined };

export type HUpsertCompanyUserMutationVariables = Types.Exact<{
  companyUser: Types.HCompanyUser_Insert_Input;
}>;


export type HUpsertCompanyUserMutation = { companyUser?: { id: any, role: Types.HUserRole_Enum, user: { id: any, companyUsers: Array<{ id: any, companyId: any }> }, company: { id: any, name: string, isPortfolioOwner: boolean, industries: any, logoUrl?: string | null | undefined } } | null | undefined };

export type HShortCompanyFragment = { id: any, name: string, isPortfolioOwner: boolean, industries: any, logoUrl?: string | null | undefined };

export type HUpsertCompanyInvitationMutationVariables = Types.Exact<{
  companyInvitation: Types.HCompanyInvitation_Insert_Input;
}>;


export type HUpsertCompanyInvitationMutation = { insert_CompanyInvitation_one?: { id: any, status: string, userEmail: string, company: { id: any, name: string, invitations: Array<{ id: any }> }, user?: { display_name?: string | null | undefined, id: any } | null | undefined } | null | undefined };

export type HUpsertBusinessUnitMutationVariables = Types.Exact<{
  businessUnit: Types.HBusinessUnit_Insert_Input;
}>;


export type HUpsertBusinessUnitMutation = { businessUnit?: { id: any, name: string, description: string, businessArea: string, sectors: any, createdAt: any, isSupporting: boolean, labels: any, company: { id: any, name: string, businessUnits: Array<{ id: any }> }, bAssessments: Array<{ cAssessmentId: any }>, contactPerson: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | null | undefined };

export type HUpsertBusinessUnitsMutationVariables = Types.Exact<{
  businessUnits?: Array<Types.HBusinessUnit_Insert_Input> | Types.HBusinessUnit_Insert_Input;
}>;


export type HUpsertBusinessUnitsMutation = { businessUnit?: { affected_rows: number } | null | undefined };

export type HDeleteBusinessUnitMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  deletedAt?: Types.Maybe<Types.Scalars['timestamptz']>;
}>;


export type HDeleteBusinessUnitMutation = { businessUnit?: { id: any, name: string, description: string, businessArea: string, sectors: any, createdAt: any, isSupporting: boolean, labels: any, company: { id: any, name: string, businessUnits: Array<{ id: any }> }, contactPerson: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } | null | undefined, update_BusinessUnitAssessment?: { returning: Array<{ cAssessment: { id: any, bAssesssments: Array<{ id: any }> } }> } | null | undefined };

export type HRequestQuoteMutationVariables = Types.Exact<{
  data: Types.HRequestQuoteInput;
}>;


export type HRequestQuoteMutation = { requestQuote?: { message: string } | null | undefined };

export type HGetInvitationQueryVariables = Types.Exact<{
  data: Types.HInvitationQuery;
}>;


export type HGetInvitationQuery = { invitation?: { id: any, userEmail: string, status: string, userExists: boolean, inviteToChildCompanies: boolean, companyId: any, companyName: string, companyLogoUrl?: string | null | undefined } | null | undefined };

export type HInvitationsSubscriptionVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
}>;


export type HInvitationsSubscription = { invitations: Array<{ id: any, status: string }> };

export type HGetBusinessUnitCompanyAssessmentsQueryVariables = Types.Exact<{
  businessUnitId: Types.Scalars['uuid'];
}>;


export type HGetBusinessUnitCompanyAssessmentsQuery = { activeCAssessments: Array<{ id: any }>, deletedCAssessments: Array<{ id: any }> };

export const BusinessUnitDistributionKeyFieldsFragmentDoc = gql`
    fragment BusinessUnitDistributionKeyFields on BusinessUnitDistributionKey {
  id
  receivingBusinessUnitId
  activityRef
  revenuePercentage
  opexPercentage
  capexPercentage
}
    `;
export const ShortUserFragmentDoc = gql`
    fragment ShortUser on users {
  id
  email
  avatar_url
  firstName
  lastName
}
    `;
export const CAssessmentFieldsFragmentDoc = gql`
    fragment CAssessmentFields on CompanyAssessment {
  id
  startDate
  endDate
  createdAt
  aggregate {
    id
    title
    interval
    contactPerson {
      ...ShortUser
    }
  }
  bAssesssments: bAssesssments(where: {deletedAt: {_is_null: true}}) {
    id
    businessUnitId
  }
  deletedBAssessments: bAssesssments(where: {deletedAt: {_is_null: false}}) {
    id
    businessUnitId
  }
}
    ${ShortUserFragmentDoc}`;
export const BusinessUnitFieldsFragmentDoc = gql`
    fragment BusinessUnitFields on BusinessUnit {
  id
  name
  description
  businessArea
  sectors
  createdAt
  contactPerson {
    ...ShortUser
  }
  company {
    id
    name
  }
  isSupporting
  labels
}
    ${ShortUserFragmentDoc}`;
export const CompanyFieldsFragmentDoc = gql`
    fragment CompanyFields on Company {
  id
  name
  currency
  logoUrl
  isOnTrial
  isPortfolioOwner
  trialEndDate
  settings
  businessUnits {
    ...BusinessUnitFields
  }
  users {
    userId
    role
    user {
      ...ShortUser
    }
  }
  invitations {
    userEmail
    status
    id
  }
  portfolioMemberships: portfolioCompanies {
    portfolioId
    portfolio {
      name
      ownerCompany {
        id
        name
      }
    }
    scores
  }
  portfolios {
    name
  }
}
    ${BusinessUnitFieldsFragmentDoc}
${ShortUserFragmentDoc}`;
export const FinancialsFieldsFragmentDoc = gql`
    fragment FinancialsFields on Financials {
  id
  revenue
  capex
  opex
  adaptationCapex
  adaptationOpex
  isEstimate
}
    `;
export const ShortCompanyFragmentDoc = gql`
    fragment ShortCompany on Company {
  id
  name
  isPortfolioOwner
  industries
  logoUrl
}
    `;
export const CompanyByIdDocument = gql`
    query CompanyById($id: uuid!) {
  company: Company_by_pk(id: $id) {
    ...CompanyFields
    cAssessments: cAssessments(where: {deletedAt: {_is_null: true}}) {
      ...CAssessmentFields
    }
  }
}
    ${CompanyFieldsFragmentDoc}
${CAssessmentFieldsFragmentDoc}`;

/**
 * __useCompanyByIdQuery__
 *
 * To run a query within a React component, call `useCompanyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyByIdQuery(baseOptions: Apollo.QueryHookOptions<HCompanyByIdQuery, HCompanyByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HCompanyByIdQuery, HCompanyByIdQueryVariables>(CompanyByIdDocument, options);
      }
export function useCompanyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HCompanyByIdQuery, HCompanyByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HCompanyByIdQuery, HCompanyByIdQueryVariables>(CompanyByIdDocument, options);
        }
export type CompanyByIdQueryHookResult = ReturnType<typeof useCompanyByIdQuery>;
export type CompanyByIdLazyQueryHookResult = ReturnType<typeof useCompanyByIdLazyQuery>;
export type CompanyByIdQueryResult = Apollo.QueryResult<HCompanyByIdQuery, HCompanyByIdQueryVariables>;
export const BusinessUnitDocument = gql`
    query BusinessUnit($id: uuid!) {
  businessUnit: BusinessUnit_by_pk(id: $id) {
    ...BusinessUnitFields
    companyId
    contactPersonId
    bAssessments(where: {deletedAt: {_is_null: true}}) {
      cAssessmentId
      activityReports(where: {deletedAt: {_is_null: true}}) {
        activityRef
        financials {
          ...FinancialsFields
        }
        answers {
          data
          answeredById
          questionId
          flagged
          attachments {
            fileId
          }
          notes {
            updatedAt
            body
            authorId
          }
        }
      }
    }
  }
}
    ${BusinessUnitFieldsFragmentDoc}
${FinancialsFieldsFragmentDoc}`;

/**
 * __useBusinessUnitQuery__
 *
 * To run a query within a React component, call `useBusinessUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useBusinessUnitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBusinessUnitQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBusinessUnitQuery(baseOptions: Apollo.QueryHookOptions<HBusinessUnitQuery, HBusinessUnitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HBusinessUnitQuery, HBusinessUnitQueryVariables>(BusinessUnitDocument, options);
      }
export function useBusinessUnitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HBusinessUnitQuery, HBusinessUnitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HBusinessUnitQuery, HBusinessUnitQueryVariables>(BusinessUnitDocument, options);
        }
export type BusinessUnitQueryHookResult = ReturnType<typeof useBusinessUnitQuery>;
export type BusinessUnitLazyQueryHookResult = ReturnType<typeof useBusinessUnitLazyQuery>;
export type BusinessUnitQueryResult = Apollo.QueryResult<HBusinessUnitQuery, HBusinessUnitQueryVariables>;
export const UserInvitationsDocument = gql`
    query UserInvitations($status: String!, $userId: uuid!) {
  invitations: CompanyInvitation(
    where: {status: {_eq: $status}, user: {id: {_eq: $userId}}}
  ) {
    id
    status
  }
}
    `;

/**
 * __useUserInvitationsQuery__
 *
 * To run a query within a React component, call `useUserInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInvitationsQuery({
 *   variables: {
 *      status: // value for 'status'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserInvitationsQuery(baseOptions: Apollo.QueryHookOptions<HUserInvitationsQuery, HUserInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HUserInvitationsQuery, HUserInvitationsQueryVariables>(UserInvitationsDocument, options);
      }
export function useUserInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HUserInvitationsQuery, HUserInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HUserInvitationsQuery, HUserInvitationsQueryVariables>(UserInvitationsDocument, options);
        }
export type UserInvitationsQueryHookResult = ReturnType<typeof useUserInvitationsQuery>;
export type UserInvitationsLazyQueryHookResult = ReturnType<typeof useUserInvitationsLazyQuery>;
export type UserInvitationsQueryResult = Apollo.QueryResult<HUserInvitationsQuery, HUserInvitationsQueryVariables>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: uuid!, $company: Company_set_input) {
  company: update_Company_by_pk(pk_columns: {id: $id}, _set: $company) {
    ...CompanyFields
  }
}
    ${CompanyFieldsFragmentDoc}`;
export type HUpdateCompanyMutationFn = Apollo.MutationFunction<HUpdateCompanyMutation, HUpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      company: // value for 'company'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<HUpdateCompanyMutation, HUpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpdateCompanyMutation, HUpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<HUpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<HUpdateCompanyMutation, HUpdateCompanyMutationVariables>;
export const UpsertCompanyUserDocument = gql`
    mutation upsertCompanyUser($companyUser: CompanyUser_insert_input!) {
  companyUser: insert_CompanyUser_one(
    object: $companyUser
    on_conflict: {constraint: CompanyUser_companyId_userId_10e185ba_uniq, update_columns: [role]}
  ) {
    id
    role
    user {
      id
      companyUsers {
        id
        companyId
      }
    }
    company {
      ...ShortCompany
    }
  }
}
    ${ShortCompanyFragmentDoc}`;
export type HUpsertCompanyUserMutationFn = Apollo.MutationFunction<HUpsertCompanyUserMutation, HUpsertCompanyUserMutationVariables>;

/**
 * __useUpsertCompanyUserMutation__
 *
 * To run a mutation, you first call `useUpsertCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCompanyUserMutation, { data, loading, error }] = useUpsertCompanyUserMutation({
 *   variables: {
 *      companyUser: // value for 'companyUser'
 *   },
 * });
 */
export function useUpsertCompanyUserMutation(baseOptions?: Apollo.MutationHookOptions<HUpsertCompanyUserMutation, HUpsertCompanyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpsertCompanyUserMutation, HUpsertCompanyUserMutationVariables>(UpsertCompanyUserDocument, options);
      }
export type UpsertCompanyUserMutationHookResult = ReturnType<typeof useUpsertCompanyUserMutation>;
export type UpsertCompanyUserMutationResult = Apollo.MutationResult<HUpsertCompanyUserMutation>;
export type UpsertCompanyUserMutationOptions = Apollo.BaseMutationOptions<HUpsertCompanyUserMutation, HUpsertCompanyUserMutationVariables>;
export const UpsertCompanyInvitationDocument = gql`
    mutation upsertCompanyInvitation($companyInvitation: CompanyInvitation_insert_input!) {
  insert_CompanyInvitation_one(
    object: $companyInvitation
    on_conflict: {constraint: CompanyInvitation_pkey, update_columns: [status]}
  ) {
    id
    company {
      id
      name
      invitations {
        id
      }
    }
    user {
      display_name
      id
    }
    status
    userEmail
  }
}
    `;
export type HUpsertCompanyInvitationMutationFn = Apollo.MutationFunction<HUpsertCompanyInvitationMutation, HUpsertCompanyInvitationMutationVariables>;

/**
 * __useUpsertCompanyInvitationMutation__
 *
 * To run a mutation, you first call `useUpsertCompanyInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCompanyInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCompanyInvitationMutation, { data, loading, error }] = useUpsertCompanyInvitationMutation({
 *   variables: {
 *      companyInvitation: // value for 'companyInvitation'
 *   },
 * });
 */
export function useUpsertCompanyInvitationMutation(baseOptions?: Apollo.MutationHookOptions<HUpsertCompanyInvitationMutation, HUpsertCompanyInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpsertCompanyInvitationMutation, HUpsertCompanyInvitationMutationVariables>(UpsertCompanyInvitationDocument, options);
      }
export type UpsertCompanyInvitationMutationHookResult = ReturnType<typeof useUpsertCompanyInvitationMutation>;
export type UpsertCompanyInvitationMutationResult = Apollo.MutationResult<HUpsertCompanyInvitationMutation>;
export type UpsertCompanyInvitationMutationOptions = Apollo.BaseMutationOptions<HUpsertCompanyInvitationMutation, HUpsertCompanyInvitationMutationVariables>;
export const UpsertBusinessUnitDocument = gql`
    mutation upsertBusinessUnit($businessUnit: BusinessUnit_insert_input!) {
  businessUnit: insert_BusinessUnit_one(
    object: $businessUnit
    on_conflict: {constraint: businessUnit_pkey, update_columns: [businessArea, contactPersonId, description, name, sectors, labels]}
  ) {
    ...BusinessUnitFields
    company {
      id
      businessUnits {
        id
      }
    }
    bAssessments {
      cAssessmentId
    }
  }
}
    ${BusinessUnitFieldsFragmentDoc}`;
export type HUpsertBusinessUnitMutationFn = Apollo.MutationFunction<HUpsertBusinessUnitMutation, HUpsertBusinessUnitMutationVariables>;

/**
 * __useUpsertBusinessUnitMutation__
 *
 * To run a mutation, you first call `useUpsertBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBusinessUnitMutation, { data, loading, error }] = useUpsertBusinessUnitMutation({
 *   variables: {
 *      businessUnit: // value for 'businessUnit'
 *   },
 * });
 */
export function useUpsertBusinessUnitMutation(baseOptions?: Apollo.MutationHookOptions<HUpsertBusinessUnitMutation, HUpsertBusinessUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpsertBusinessUnitMutation, HUpsertBusinessUnitMutationVariables>(UpsertBusinessUnitDocument, options);
      }
export type UpsertBusinessUnitMutationHookResult = ReturnType<typeof useUpsertBusinessUnitMutation>;
export type UpsertBusinessUnitMutationResult = Apollo.MutationResult<HUpsertBusinessUnitMutation>;
export type UpsertBusinessUnitMutationOptions = Apollo.BaseMutationOptions<HUpsertBusinessUnitMutation, HUpsertBusinessUnitMutationVariables>;
export const UpsertBusinessUnitsDocument = gql`
    mutation upsertBusinessUnits($businessUnits: [BusinessUnit_insert_input!]! = []) {
  businessUnit: insert_BusinessUnit(
    objects: $businessUnits
    on_conflict: {constraint: businessUnit_pkey, update_columns: [businessArea, contactPersonId, description, name, sectors, labels]}
  ) {
    affected_rows
  }
}
    `;
export type HUpsertBusinessUnitsMutationFn = Apollo.MutationFunction<HUpsertBusinessUnitsMutation, HUpsertBusinessUnitsMutationVariables>;

/**
 * __useUpsertBusinessUnitsMutation__
 *
 * To run a mutation, you first call `useUpsertBusinessUnitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBusinessUnitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBusinessUnitsMutation, { data, loading, error }] = useUpsertBusinessUnitsMutation({
 *   variables: {
 *      businessUnits: // value for 'businessUnits'
 *   },
 * });
 */
export function useUpsertBusinessUnitsMutation(baseOptions?: Apollo.MutationHookOptions<HUpsertBusinessUnitsMutation, HUpsertBusinessUnitsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpsertBusinessUnitsMutation, HUpsertBusinessUnitsMutationVariables>(UpsertBusinessUnitsDocument, options);
      }
export type UpsertBusinessUnitsMutationHookResult = ReturnType<typeof useUpsertBusinessUnitsMutation>;
export type UpsertBusinessUnitsMutationResult = Apollo.MutationResult<HUpsertBusinessUnitsMutation>;
export type UpsertBusinessUnitsMutationOptions = Apollo.BaseMutationOptions<HUpsertBusinessUnitsMutation, HUpsertBusinessUnitsMutationVariables>;
export const DeleteBusinessUnitDocument = gql`
    mutation deleteBusinessUnit($id: uuid!, $deletedAt: timestamptz) {
  businessUnit: update_BusinessUnit_by_pk(
    pk_columns: {id: $id}
    _set: {deletedAt: $deletedAt}
  ) {
    ...BusinessUnitFields
    company {
      id
      businessUnits {
        id
      }
    }
  }
  update_BusinessUnitAssessment(
    where: {businessUnitId: {_eq: $id}}
    _set: {deletedAt: $deletedAt}
  ) {
    returning {
      cAssessment {
        id
        bAssesssments {
          id
        }
      }
    }
  }
}
    ${BusinessUnitFieldsFragmentDoc}`;
export type HDeleteBusinessUnitMutationFn = Apollo.MutationFunction<HDeleteBusinessUnitMutation, HDeleteBusinessUnitMutationVariables>;

/**
 * __useDeleteBusinessUnitMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessUnitMutation, { data, loading, error }] = useDeleteBusinessUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deletedAt: // value for 'deletedAt'
 *   },
 * });
 */
export function useDeleteBusinessUnitMutation(baseOptions?: Apollo.MutationHookOptions<HDeleteBusinessUnitMutation, HDeleteBusinessUnitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HDeleteBusinessUnitMutation, HDeleteBusinessUnitMutationVariables>(DeleteBusinessUnitDocument, options);
      }
export type DeleteBusinessUnitMutationHookResult = ReturnType<typeof useDeleteBusinessUnitMutation>;
export type DeleteBusinessUnitMutationResult = Apollo.MutationResult<HDeleteBusinessUnitMutation>;
export type DeleteBusinessUnitMutationOptions = Apollo.BaseMutationOptions<HDeleteBusinessUnitMutation, HDeleteBusinessUnitMutationVariables>;
export const RequestQuoteDocument = gql`
    mutation requestQuote($data: RequestQuoteInput!) {
  requestQuote(data: $data) {
    message
  }
}
    `;
export type HRequestQuoteMutationFn = Apollo.MutationFunction<HRequestQuoteMutation, HRequestQuoteMutationVariables>;

/**
 * __useRequestQuoteMutation__
 *
 * To run a mutation, you first call `useRequestQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestQuoteMutation, { data, loading, error }] = useRequestQuoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRequestQuoteMutation(baseOptions?: Apollo.MutationHookOptions<HRequestQuoteMutation, HRequestQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HRequestQuoteMutation, HRequestQuoteMutationVariables>(RequestQuoteDocument, options);
      }
export type RequestQuoteMutationHookResult = ReturnType<typeof useRequestQuoteMutation>;
export type RequestQuoteMutationResult = Apollo.MutationResult<HRequestQuoteMutation>;
export type RequestQuoteMutationOptions = Apollo.BaseMutationOptions<HRequestQuoteMutation, HRequestQuoteMutationVariables>;
export const GetInvitationDocument = gql`
    query getInvitation($data: InvitationQuery!) {
  invitation: getInvitation(query: $data) {
    id
    userEmail
    status
    userExists
    inviteToChildCompanies
    companyId
    companyName
    companyLogoUrl
  }
}
    `;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetInvitationQuery(baseOptions: Apollo.QueryHookOptions<HGetInvitationQuery, HGetInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HGetInvitationQuery, HGetInvitationQueryVariables>(GetInvitationDocument, options);
      }
export function useGetInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HGetInvitationQuery, HGetInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HGetInvitationQuery, HGetInvitationQueryVariables>(GetInvitationDocument, options);
        }
export type GetInvitationQueryHookResult = ReturnType<typeof useGetInvitationQuery>;
export type GetInvitationLazyQueryHookResult = ReturnType<typeof useGetInvitationLazyQuery>;
export type GetInvitationQueryResult = Apollo.QueryResult<HGetInvitationQuery, HGetInvitationQueryVariables>;
export const InvitationsDocument = gql`
    subscription invitations($userId: uuid!) {
  invitations: CompanyInvitation(
    where: {status: {_eq: "pending"}, user: {id: {_eq: $userId}}}
  ) {
    id
    status
  }
}
    `;

/**
 * __useInvitationsSubscription__
 *
 * To run a query within a React component, call `useInvitationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useInvitationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationsSubscription({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useInvitationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<HInvitationsSubscription, HInvitationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<HInvitationsSubscription, HInvitationsSubscriptionVariables>(InvitationsDocument, options);
      }
export type InvitationsSubscriptionHookResult = ReturnType<typeof useInvitationsSubscription>;
export type InvitationsSubscriptionResult = Apollo.SubscriptionResult<HInvitationsSubscription>;
export const GetBusinessUnitCompanyAssessmentsDocument = gql`
    query GetBusinessUnitCompanyAssessments($businessUnitId: uuid!) {
  activeCAssessments: CompanyAssessment(
    where: {bAssesssments: {_and: {businessUnitId: {_eq: $businessUnitId}, deletedAt: {_is_null: true}}}}
  ) {
    id
  }
  deletedCAssessments: CompanyAssessment(
    where: {bAssesssments: {_and: {businessUnitId: {_eq: $businessUnitId}, deletedAt: {_is_null: false}}}}
  ) {
    id
  }
}
    `;

/**
 * __useGetBusinessUnitCompanyAssessmentsQuery__
 *
 * To run a query within a React component, call `useGetBusinessUnitCompanyAssessmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessUnitCompanyAssessmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessUnitCompanyAssessmentsQuery({
 *   variables: {
 *      businessUnitId: // value for 'businessUnitId'
 *   },
 * });
 */
export function useGetBusinessUnitCompanyAssessmentsQuery(baseOptions: Apollo.QueryHookOptions<HGetBusinessUnitCompanyAssessmentsQuery, HGetBusinessUnitCompanyAssessmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HGetBusinessUnitCompanyAssessmentsQuery, HGetBusinessUnitCompanyAssessmentsQueryVariables>(GetBusinessUnitCompanyAssessmentsDocument, options);
      }
export function useGetBusinessUnitCompanyAssessmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HGetBusinessUnitCompanyAssessmentsQuery, HGetBusinessUnitCompanyAssessmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HGetBusinessUnitCompanyAssessmentsQuery, HGetBusinessUnitCompanyAssessmentsQueryVariables>(GetBusinessUnitCompanyAssessmentsDocument, options);
        }
export type GetBusinessUnitCompanyAssessmentsQueryHookResult = ReturnType<typeof useGetBusinessUnitCompanyAssessmentsQuery>;
export type GetBusinessUnitCompanyAssessmentsLazyQueryHookResult = ReturnType<typeof useGetBusinessUnitCompanyAssessmentsLazyQuery>;
export type GetBusinessUnitCompanyAssessmentsQueryResult = Apollo.QueryResult<HGetBusinessUnitCompanyAssessmentsQuery, HGetBusinessUnitCompanyAssessmentsQueryVariables>;