import demoData from 'constants/demoCompany.json';
import { useCallback } from 'react';
import { omitMeta, User, UserRole } from './general';
import {
  HActivityReport_Insert_Input,
  HCompanyAssessment_Insert_Input,
  HCompany_Constraint,
  HCompany_Insert_Input,
  HCompany_Update_Column,
} from 'schema';
import { omit } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { CompanyByIdDocument } from 'models';
import {
  HCompanyByIdQuery,
  HCompanyByIdQueryVariables,
  useUpsertCompanyUserMutation,
} from './company.graphql';
import {
  CompanyFilesDocument,
  HCompanyFilesQuery,
  HCompanyFilesQueryVariables,
} from './file.graphql';

const demoCompany = demoData.company;
export const useCreateDemoCompany = () => {
  const client = useApolloClient();
  const [upsertCompanyUser] = useUpsertCompanyUserMutation();
  return useCallback(async (user: User, companyName: string) => {
    const upsertCompany = (company: HCompany_Insert_Input, refetch = false) =>
      upsertCompanyUser({
        variables: {
          companyUser: {
            userId: user.id,
            role: UserRole.Admin,
            company: {
              data: company,
              on_conflict: {
                constraint: HCompany_Constraint.CompanyPkey,
                update_columns: [HCompany_Update_Column.Id],
              },
            },
          },
        },
        ...(refetch ? { refetchQueries: ['CompanyById'], awaitRefetchQueries: true } : {}),
      });
    const newCompany: HCompany_Insert_Input = {
      ...omit(demoCompany, 'cAssessments', 'businessUnits', 'files'),
      name: companyName + ' [Demo]',
      settings: { isDemo: true },
    };

    const { data } = await upsertCompany(newCompany);
    newCompany.id = data?.companyUser?.company.id;
    await upsertCompany({
      ...newCompany,
      businessUnits: {
        data: demoCompany.businessUnits.map((bu) => ({
          ...omitMeta(bu),
          contactPersonId: user.id,
          isSupporting: false,
        })),
      },
      files: { data: demoCompany.files.map((f) => ({ ...f, uploadedById: user.id })) },
    });
    const { data: companyData } = await client.query<HCompanyByIdQuery, HCompanyByIdQueryVariables>(
      { query: CompanyByIdDocument, variables: { id: newCompany.id }, fetchPolicy: 'network-only' }
    );
    const { data: files } = await client.query<HCompanyFilesQuery, HCompanyFilesQueryVariables>({
      query: CompanyFilesDocument,
      variables: { companyId: newCompany.id },
      fetchPolicy: 'network-only',
    });
    if (companyData) {
      const buByName = Object.fromEntries(
        companyData.company?.businessUnits.map((bu) => [bu.name, bu.id]) ?? []
      );
      const fileByUrl = Object.fromEntries(files.files.map((f) => [f.url, f.id]));
      const getActivityReportInsert = (
        ar: typeof demoCompany['cAssessments'][number]['bAssesssments'][number]['activityReports'][number]
      ) =>
        ({
          ...ar,
          financials: { data: ar.financials },
          answers: {
            data: ar.answers.map((a) => ({
              ...a,
              answeredById: user.id,
              notes: { data: a.notes.map((n) => ({ ...n, authorId: user.id })) },
              attachments: {
                data: a.attachments.map((at) => ({ fileId: fileByUrl[at.file.url] })),
              },
            })),
          },
        } as HActivityReport_Insert_Input);
      const companyAssessments: HCompanyAssessment_Insert_Input[] = demoCompany.cAssessments.map(
        (companyAssessment) => ({
          ...companyAssessment,
          aggregate: { data: { ...companyAssessment.aggregate, companyId: newCompany.id } },
          bAssesssments: {
            data: companyAssessment.bAssesssments.map((bA) => ({
              businessUnitId: buByName[bA.businessUnit?.name ?? ''] ?? null,
              activityReports: {
                data: bA.activityReports.map(getActivityReportInsert),
              },
            })),
          },
        })
      );
      await upsertCompany({ ...newCompany, cAssessments: { data: companyAssessments } }, true);
      const { data: newCompanyData } = await client.query<
        HCompanyByIdQuery,
        HCompanyByIdQueryVariables
      >({
        query: CompanyByIdDocument,
        variables: { id: newCompany.id },
        fetchPolicy: 'network-only',
      });
      return newCompanyData.company;
    }
  }, []);
};
