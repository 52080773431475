import { useCallback, useMemo } from 'react';
import { omit, pick, sortBy } from 'lodash';
import { useQuerySuspense } from 'hooks/useQuerySuspense';
import {
  HQuestionType_Enum,
  HQuestionLevel_Enum,
  HUserRole_Enum,
  HAnswer_Insert_Input,
  HThread_Insert_Input,
  HComment_Insert_Input,
  HUsers_Set_Input,
} from 'schema';
import { ActivityReport } from './assessment-model';

import {
  HActivityFieldsFragment,
  HAllObjectivesQuery,
  HAllObjectivesQueryVariables,
  HObjectiveFieldsFragment,
  HUserFieldsFragment,
  useAddAnswerMutation,
  HSectorFieldsFragment,
  useGetUserQuery,
  useUpdateUserMutation,
  HAllActivitiesQuery,
  AllActivitiesDocument,
  HAllActivitiesQueryVariables,
  HAllSectorsQuery,
  HAllSectorsQueryVariables,
  AllSectorsDocument,
  GetUserDocument,
  HGetUserQueryVariables,
  HGetUserQuery,
  HShortUserFragment,
  useDeleteNoteMutation,
} from './general.graphql';

import { Answer } from './question-model';
import { useCurrentUserId } from './localState';
import { escapeKey, translateField, translateFields, useEUDataTranslation } from 'utils/i18n';
import { AttachmentOnConflict, CompanyFile } from './file-model';
import { getSubscribeCurrentUserInput, AnswerThreadOnConflict } from './thread-model';
import { ApolloCache, DocumentNode } from '@apollo/client';
import * as uuid from 'uuid';
import { FileIds } from 'components/files/FileList';
import { useLatest } from 'react-use';
import omitDeep from 'omit-deep-lodash';
export { useAllActivitiesQuery, AllObjectivesDocument, GetAnswerDocument } from './general.graphql';

export const QuestionLevel = HQuestionLevel_Enum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type QuestionLevel = HQuestionLevel_Enum;

export const QuestionType = HQuestionType_Enum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type QuestionType = HQuestionType_Enum;

export const UserRole = HUserRole_Enum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type UserRole = typeof UserRole;

export type Activity = HActivityFieldsFragment;
export type SimpleActivity = Pick<Activity, 'name' | 'reference'>;

export interface User extends HUserFieldsFragment {
  email: string;
}
export type ShortUser = HShortUserFragment;

export type Objective = HObjectiveFieldsFragment;

export type AllObjectivesQuery = HAllObjectivesQuery;
export type AllObjectivesQueryVariables = HAllObjectivesQueryVariables;

export type Sector = HSectorFieldsFragment;

export const Tuple = <T, K>(a: T, b: K) => [a, b] as [T, K];
export type AnswerUpdate = Omit<
  HAnswer_Insert_Input,
  'attachments' | 'activityReport' | 'thread' | 'notes' | 'report'
> & {
  attachments?: CompanyFile[];
  thread?: Omit<HThread_Insert_Input, 'comments' | 'subscribers'> & {
    comments: HComment_Insert_Input[];
  };
  note?: string;
};
export const omitMeta = (obj: any) => omit(obj, 'id', '__typename');
export const upsertOmitMeta = (obj: any) => omit(obj, '__typename');
export const omitMetaOther = (obj: any, ...other: string[]) => omit(obj, '__typename', ...other);
export const deepOmitTypename = (object: object) => {
  return omitDeep(object, '__typename');
};

const toAnswerAttachment = (f: CompanyFile) => ({ id: null, file: f });

export function useSaveAnswerMutation() {
  const [mutate, result] = useAddAnswerMutation();
  const userId = useCurrentUserId();
  const modifiedMutate = useCallback(
    (
      newAnswer: AnswerUpdate & {
        report?: Pick<ActivityReport, 'businessUnitId' | 'financials'>;
      },
      oldAnswer?: Answer
    ) => {
      const data: HAnswer_Insert_Input = omit(
        {
          ...pick(oldAnswer, 'data', 'flagged', 'threadId'),
          ...newAnswer,
        },
        'attachments',
        'thread',
        'report',
        'note'
      );
      let attachmentsToDelete = [] as FileIds;
      if (newAnswer.attachments) {
        const attachments = newAnswer.attachments;
        const current = oldAnswer?.attachments.map(({ id }) => id) ?? [];
        attachmentsToDelete = current.filter((id) => !attachments.find((f) => f.id == id));
        data.attachments = {
          data: newAnswer.attachments
            .filter(({ id }) => !current.includes(id))
            .map((f) => ({ fileId: f.id })),
          on_conflict: AttachmentOnConflict,
        };
      }
      if (newAnswer.thread) {
        data.thread = {
          data: {
            ...newAnswer.thread,
            comments: { data: newAnswer.thread.comments },
            subscribers: getSubscribeCurrentUserInput(),
          },
          on_conflict: AnswerThreadOnConflict,
        };
      }
      if (newAnswer.note) {
        data.notes = {
          data: [{ body: newAnswer.note, authorId: userId }],
        };
      }
      const note = newAnswer.note
        ? { body: newAnswer.note, updatedAt: new Date().toISOString() }
        : oldAnswer?.note
        ? {
            body: oldAnswer?.note.body,
            updatedAt: oldAnswer?.note?.authors[0].modifiedAt,
          }
        : null;
      return mutate({
        variables: { answer: data, id: oldAnswer?.id ?? uuid.NIL, attachmentsToDelete },
        optimisticResponse: {
          answer: {
            ...(oldAnswer ?? {
              id: -1,
              createdAt: new Date(),
              threadId: null,
              attachments: [],
              answeredBy: { id: null },
              question: { id: null, uniqueId: 'tmp' },
            }),
            questionId: newAnswer.questionId,
            data: newAnswer.data,
            updatedAt: Date.now(),
            flagged: newAnswer.flagged ?? oldAnswer?.flagged,
            attachments: (newAnswer.attachments ?? oldAnswer?.attachments ?? []).map(
              toAnswerAttachment
            ),
            note: note ? [note] : [],
            activityReport: null,
            noteChanges:
              oldAnswer?.note?.authors.map((a) => ({
                updatedAt: a.modifiedAt,
                author: a,
              })) ?? [],
          },
          delete_AnswerAttachment: {
            affected_rows: 0,
          },
        },
      });
    },
    [mutate, userId]
  );
  return Tuple(modifiedMutate, result);
}

export function useCurrentUser() {
  const userId = useCurrentUserId();
  const result = useGetUserQuery({
    fetchPolicy: 'cache-first',
    variables: { id: userId },
    skip: !userId,
  });
  const { data, loading } = result;
  if (!loading && data?.user) return data.user as User;
  return undefined;
}

export function useCurrentUserSuspense() {
  const userId = useCurrentUserId();
  return useQuerySuspense<HGetUserQuery, HGetUserQueryVariables>(GetUserDocument, {
    variables: { id: userId },
    skip: !userId,
  }).user;
}

export function useUpdateUser() {
  const [updateUser] = useUpdateUserMutation();
  return useCallback(
    (userUpdate: HUsers_Set_Input, id: string) =>
      updateUser({
        variables: {
          id,
          user: userUpdate,
        },
      }),
    [updateUser]
  );
}
export function useUserSetting<T>(name: string, def: T) {
  const currentUser = useCurrentUser();
  const [updateUser] = useUpdateUserMutation();
  const userRef = useLatest(currentUser);
  const updateSetting = useCallback(
    (value: T) => {
      const user = userRef.current;
      return (
        user &&
        updateUser({
          variables: {
            id: user.id,
            user: {
              settings: {
                ...user.settings,
                [name]: value,
              },
            },
          },
          optimisticResponse: {
            user: {
              ...user,
              settings: {
                ...user?.settings,
                [name]: value,
              },
            },
          },
        })
      );
    },
    [name]
  );
  return Tuple(currentUser?.settings?.[name] ?? def, updateSetting);
}

export function useAllActivities() {
  const { allActivities } = useQuerySuspense<HAllActivitiesQuery, HAllActivitiesQueryVariables>(
    AllActivitiesDocument
  );
  const { t } = useEUDataTranslation('activity');
  return useMemo(
    () =>
      allActivities.map((a) =>
        translateFields(a, ['name', 'description'], t, escapeKey(a.reference))
      ),
    [allActivities, t]
  );
}
export function useAllSectors() {
  const { sectors } = useQuerySuspense<HAllSectorsQuery, HAllSectorsQueryVariables>(
    AllSectorsDocument
  );
  const { t } = useEUDataTranslation('sector');
  return useMemo(
    () => sortBy(sectors, 'name').map((s) => translateField(s, 'name', t, escapeKey(s.reference))),
    [sectors, t]
  );
}
export function useSectors(filter: string[]) {
  const allSectors = useAllSectors();
  return useMemo(() => allSectors.filter((s) => filter.includes(s.reference)), [
    allSectors,
    filter,
  ]);
}

export function updateQuery<QueryType, VariablesType, DataType>(
  cache: ApolloCache<any>,
  query: DocumentNode,
  variables: VariablesType,
  modifier: (query: QueryType, newData: DataType) => QueryType,
  newData: DataType | undefined | null
) {
  const queryDef = { query, variables };
  const queryResult = cache.readQuery<QueryType, VariablesType>(queryDef);
  if (queryResult && newData) {
    cache.writeQuery({
      ...queryDef,
      data: modifier(queryResult, newData),
    });
  }
}

export function useDeleteNote() {
  const [deleteNote] = useDeleteNoteMutation();
  return useCallback(
    (answerId: Answer['id']) =>
      deleteNote({
        variables: {
          answerId,
        },
      }).then(({ data }) => data?.note?.returning[0]),
    [deleteNote]
  );
}
