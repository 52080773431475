/* 58efc14c28da927e3180a16c75f71a9715a5ff43
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HCompanyShortFragment = { id: any, name: string, industries: any, logoUrl?: string | null | undefined };

export type HEstimateCompanyFieldsFragment = { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any };

export type HPortfolioCompanyFragment = { id: any, scores: any, amount: any, contactPersonEmail: string, contactPersonName: string, company?: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } | null | undefined, estimateCompany?: { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any } | null | undefined };

export type HUserPortfolioCompanyFragment = { id: any, scores: any };

export type HPortfolioFieldsFragment = { id: any, name: string, description: string, totalAmount: any, portfolioCompanies: Array<{ id: any, scores: any, amount: any, contactPersonEmail: string, contactPersonName: string, company?: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } | null | undefined, estimateCompany?: { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any } | null | undefined }> };

export type HUpdateOwnerCompanyFieldsFragment = { ownerCompany: { id: any, portfolios: Array<{ name: string }> } };

export type HNudgePortfolioCompaniesFieldsFragment = { emailsSent: number, error?: string | null | undefined };

export type HEstimateCompaniesQueryVariables = Types.Exact<{
  query: Types.Scalars['String'];
}>;


export type HEstimateCompaniesQuery = { companies: Array<{ industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, id: any }> };

export type HPortfoliosQueryVariables = Types.Exact<{
  companyId: Types.Scalars['uuid'];
}>;


export type HPortfoliosQuery = { portfolios: Array<{ id: any, name: string, description: string, totalAmount: any, portfolioCompanies: Array<{ id: any, scores: any, amount: any, contactPersonEmail: string, contactPersonName: string, company?: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } | null | undefined, estimateCompany?: { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any } | null | undefined }> }> };

export type HFindEstimateCompanyByUniqueIdQueryVariables = Types.Exact<{
  uniqueId: Types.Scalars['String'];
}>;


export type HFindEstimateCompanyByUniqueIdQuery = { EstimateCompany: Array<{ id: any, name: string, uniqueId: string }> };

export type HFindEstimateCompanyByNameQueryVariables = Types.Exact<{
  name: Types.Scalars['String'];
}>;


export type HFindEstimateCompanyByNameQuery = { EstimateCompany: Array<{ id: any, name: string, uniqueId: string }> };

export type HUpsertPortfolioMutationVariables = Types.Exact<{
  portfolio: Types.HPortfolio_Insert_Input;
}>;


export type HUpsertPortfolioMutation = { portfolio?: { id: any, name: string, description: string, totalAmount: any, portfolioCompanies: Array<{ id: any, scores: any, amount: any, contactPersonEmail: string, contactPersonName: string, company?: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } | null | undefined, estimateCompany?: { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any } | null | undefined }>, ownerCompany: { id: any, portfolios: Array<{ name: string }> } } | null | undefined };

export type HAddCompanyToPortfolioMutationVariables = Types.Exact<{
  companyData?: Types.Maybe<Types.HCompany_Obj_Rel_Insert_Input>;
  userId: Types.Scalars['uuid'];
}>;


export type HAddCompanyToPortfolioMutation = { companyUser?: { role: Types.HUserRole_Enum, id: any, company: { id: any, name: string, industries: any, logoUrl?: string | null | undefined, portfolioCompanies: Array<{ id: any, scores: any, amount: any, contactPersonEmail: string, contactPersonName: string, portfolio: { id: any, portfolioCompanies: Array<{ id: any }> }, company?: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } | null | undefined, estimateCompany?: { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any } | null | undefined }> } } | null | undefined };

export type HCreatePortfolioCompaniesMutationVariables = Types.Exact<{
  companies: Array<Types.HCompanyUser_Insert_Input> | Types.HCompanyUser_Insert_Input;
}>;


export type HCreatePortfolioCompaniesMutation = { result?: { returning: Array<{ company: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } }> } | null | undefined };

export type HUpdatePortfolioCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  data?: Types.Maybe<Types.HPortfolioCompany_Set_Input>;
}>;


export type HUpdatePortfolioCompanyMutation = { portfolioCompany?: { id: any, scores: any, amount: any, contactPersonEmail: string, contactPersonName: string, company?: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } | null | undefined, estimateCompany?: { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any } | null | undefined } | null | undefined };

export type HUploadPortfolioCompanyScoreMutationVariables = Types.Exact<{
  portfolioId: Types.Scalars['uuid'];
  companyId: Types.Scalars['uuid'];
  scores?: Types.Maybe<Types.Scalars['jsonb']>;
}>;


export type HUploadPortfolioCompanyScoreMutation = { portfolioCompany?: { returning: Array<{ id: any, scores: any }> } | null | undefined };

export type HDeletePortfolioCompanyMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HDeletePortfolioCompanyMutation = { portfolioCompany?: { id: any, scores: any, amount: any, contactPersonEmail: string, contactPersonName: string, portfolio: { id: any, portfolioCompanies: Array<{ id: any }> }, company?: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } | null | undefined, estimateCompany?: { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any } | null | undefined } | null | undefined };

export type HUpsertPortfolioCompanyMutationVariables = Types.Exact<{
  portfolioCompany: Types.HPortfolioCompany_Insert_Input;
}>;


export type HUpsertPortfolioCompanyMutation = { portfolioCompany?: { id: any, scores: any, amount: any, contactPersonEmail: string, contactPersonName: string, portfolio: { id: any, portfolioCompanies: Array<{ id: any }> }, company?: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } | null | undefined, estimateCompany?: { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any } | null | undefined } | null | undefined };

export type HUpsertPortfolioCompaniesMutationVariables = Types.Exact<{
  portfolioCompanies: Array<Types.HPortfolioCompany_Insert_Input> | Types.HPortfolioCompany_Insert_Input;
}>;


export type HUpsertPortfolioCompaniesMutation = { portfolioCompanies?: { affected_rows: number } | null | undefined };

export type HUpsertPortfolioEstimateCompanyMutationVariables = Types.Exact<{
  portfolioCompany: Types.HPortfolioCompany_Insert_Input;
}>;


export type HUpsertPortfolioEstimateCompanyMutation = { portfolioCompany?: { id: any, scores: any, amount: any, contactPersonEmail: string, contactPersonName: string, portfolio: { id: any, portfolioCompanies: Array<{ id: any }> }, company?: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } | null | undefined, estimateCompany?: { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any } | null | undefined } | null | undefined };

export type HDeletePortfolioMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HDeletePortfolioMutation = { portfolio?: { id: any, name: string, description: string, totalAmount: any, portfolioCompanies: Array<{ id: any, scores: any, amount: any, contactPersonEmail: string, contactPersonName: string, company?: { id: any, name: string, industries: any, logoUrl?: string | null | undefined } | null | undefined, estimateCompany?: { createdAt: any, id: any, industries: any, logoUrl?: string | null | undefined, metadata: any, name: string, scores: any, updatedAt: any } | null | undefined }>, ownerCompany: { id: any, portfolios: Array<{ name: string }> } } | null | undefined };

export type HInsertPortfolioCompaniesMutationVariables = Types.Exact<{
  objects: Array<Types.HPortfolioCompany_Insert_Input> | Types.HPortfolioCompany_Insert_Input;
}>;


export type HInsertPortfolioCompaniesMutation = { insert_PortfolioCompany?: { affected_rows: number } | null | undefined };

export type HNudgePortfolioCompaniesMutationVariables = Types.Exact<{
  recipients: Array<Types.HEmailItem> | Types.HEmailItem;
  data: Types.HNudgePortfolioCompaniesInput;
}>;


export type HNudgePortfolioCompaniesMutation = { nudgePortfolioCompanies?: { emailsSent: number, error?: string | null | undefined } | null | undefined };

export const UserPortfolioCompanyFragmentDoc = gql`
    fragment UserPortfolioCompany on PortfolioCompany {
  id
  scores
}
    `;
export const CompanyShortFragmentDoc = gql`
    fragment CompanyShort on Company {
  id
  name
  industries
  logoUrl
}
    `;
export const EstimateCompanyFieldsFragmentDoc = gql`
    fragment EstimateCompanyFields on EstimateCompany {
  createdAt
  id
  industries
  logoUrl
  metadata
  name
  scores
  updatedAt
}
    `;
export const PortfolioCompanyFragmentDoc = gql`
    fragment PortfolioCompany on PortfolioCompany {
  id
  scores
  amount
  company {
    ...CompanyShort
  }
  estimateCompany {
    ...EstimateCompanyFields
  }
  contactPersonEmail
  contactPersonName
}
    ${CompanyShortFragmentDoc}
${EstimateCompanyFieldsFragmentDoc}`;
export const PortfolioFieldsFragmentDoc = gql`
    fragment PortfolioFields on Portfolio {
  id
  name
  description
  totalAmount
  portfolioCompanies {
    ...PortfolioCompany
  }
}
    ${PortfolioCompanyFragmentDoc}`;
export const UpdateOwnerCompanyFieldsFragmentDoc = gql`
    fragment UpdateOwnerCompanyFields on Portfolio {
  ownerCompany {
    id
    portfolios {
      name
    }
  }
}
    `;
export const NudgePortfolioCompaniesFieldsFragmentDoc = gql`
    fragment NudgePortfolioCompaniesFields on NudgePortfolioCompaniesOutput {
  emailsSent
  error
}
    `;
export const EstimateCompaniesDocument = gql`
    query EstimateCompanies($query: String!) {
  companies: EstimateCompany(where: {name: {_ilike: $query}}, limit: 500) {
    industries
    logoUrl
    metadata
    name
    scores
    id
  }
}
    `;

/**
 * __useEstimateCompaniesQuery__
 *
 * To run a query within a React component, call `useEstimateCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEstimateCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEstimateCompaniesQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useEstimateCompaniesQuery(baseOptions: Apollo.QueryHookOptions<HEstimateCompaniesQuery, HEstimateCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HEstimateCompaniesQuery, HEstimateCompaniesQueryVariables>(EstimateCompaniesDocument, options);
      }
export function useEstimateCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HEstimateCompaniesQuery, HEstimateCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HEstimateCompaniesQuery, HEstimateCompaniesQueryVariables>(EstimateCompaniesDocument, options);
        }
export type EstimateCompaniesQueryHookResult = ReturnType<typeof useEstimateCompaniesQuery>;
export type EstimateCompaniesLazyQueryHookResult = ReturnType<typeof useEstimateCompaniesLazyQuery>;
export type EstimateCompaniesQueryResult = Apollo.QueryResult<HEstimateCompaniesQuery, HEstimateCompaniesQueryVariables>;
export const PortfoliosDocument = gql`
    query Portfolios($companyId: uuid!) {
  portfolios: Portfolio(where: {ownerCompanyId: {_eq: $companyId}}) {
    ...PortfolioFields
  }
}
    ${PortfolioFieldsFragmentDoc}`;

/**
 * __usePortfoliosQuery__
 *
 * To run a query within a React component, call `usePortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortfoliosQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function usePortfoliosQuery(baseOptions: Apollo.QueryHookOptions<HPortfoliosQuery, HPortfoliosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HPortfoliosQuery, HPortfoliosQueryVariables>(PortfoliosDocument, options);
      }
export function usePortfoliosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HPortfoliosQuery, HPortfoliosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HPortfoliosQuery, HPortfoliosQueryVariables>(PortfoliosDocument, options);
        }
export type PortfoliosQueryHookResult = ReturnType<typeof usePortfoliosQuery>;
export type PortfoliosLazyQueryHookResult = ReturnType<typeof usePortfoliosLazyQuery>;
export type PortfoliosQueryResult = Apollo.QueryResult<HPortfoliosQuery, HPortfoliosQueryVariables>;
export const FindEstimateCompanyByUniqueIdDocument = gql`
    query FindEstimateCompanyByUniqueId($uniqueId: String!) {
  EstimateCompany(where: {uniqueId: {_eq: $uniqueId}}, limit: 1) {
    id
    name
    uniqueId
  }
}
    `;

/**
 * __useFindEstimateCompanyByUniqueIdQuery__
 *
 * To run a query within a React component, call `useFindEstimateCompanyByUniqueIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEstimateCompanyByUniqueIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEstimateCompanyByUniqueIdQuery({
 *   variables: {
 *      uniqueId: // value for 'uniqueId'
 *   },
 * });
 */
export function useFindEstimateCompanyByUniqueIdQuery(baseOptions: Apollo.QueryHookOptions<HFindEstimateCompanyByUniqueIdQuery, HFindEstimateCompanyByUniqueIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HFindEstimateCompanyByUniqueIdQuery, HFindEstimateCompanyByUniqueIdQueryVariables>(FindEstimateCompanyByUniqueIdDocument, options);
      }
export function useFindEstimateCompanyByUniqueIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HFindEstimateCompanyByUniqueIdQuery, HFindEstimateCompanyByUniqueIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HFindEstimateCompanyByUniqueIdQuery, HFindEstimateCompanyByUniqueIdQueryVariables>(FindEstimateCompanyByUniqueIdDocument, options);
        }
export type FindEstimateCompanyByUniqueIdQueryHookResult = ReturnType<typeof useFindEstimateCompanyByUniqueIdQuery>;
export type FindEstimateCompanyByUniqueIdLazyQueryHookResult = ReturnType<typeof useFindEstimateCompanyByUniqueIdLazyQuery>;
export type FindEstimateCompanyByUniqueIdQueryResult = Apollo.QueryResult<HFindEstimateCompanyByUniqueIdQuery, HFindEstimateCompanyByUniqueIdQueryVariables>;
export const FindEstimateCompanyByNameDocument = gql`
    query FindEstimateCompanyByName($name: String!) {
  EstimateCompany(where: {name: {_ilike: $name}}, limit: 1) {
    id
    name
    uniqueId
  }
}
    `;

/**
 * __useFindEstimateCompanyByNameQuery__
 *
 * To run a query within a React component, call `useFindEstimateCompanyByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindEstimateCompanyByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindEstimateCompanyByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useFindEstimateCompanyByNameQuery(baseOptions: Apollo.QueryHookOptions<HFindEstimateCompanyByNameQuery, HFindEstimateCompanyByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HFindEstimateCompanyByNameQuery, HFindEstimateCompanyByNameQueryVariables>(FindEstimateCompanyByNameDocument, options);
      }
export function useFindEstimateCompanyByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HFindEstimateCompanyByNameQuery, HFindEstimateCompanyByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HFindEstimateCompanyByNameQuery, HFindEstimateCompanyByNameQueryVariables>(FindEstimateCompanyByNameDocument, options);
        }
export type FindEstimateCompanyByNameQueryHookResult = ReturnType<typeof useFindEstimateCompanyByNameQuery>;
export type FindEstimateCompanyByNameLazyQueryHookResult = ReturnType<typeof useFindEstimateCompanyByNameLazyQuery>;
export type FindEstimateCompanyByNameQueryResult = Apollo.QueryResult<HFindEstimateCompanyByNameQuery, HFindEstimateCompanyByNameQueryVariables>;
export const UpsertPortfolioDocument = gql`
    mutation upsertPortfolio($portfolio: Portfolio_insert_input!) {
  portfolio: insert_Portfolio_one(
    object: $portfolio
    on_conflict: {constraint: Portfolio_pkey, update_columns: [name, totalAmount, description]}
  ) {
    ...PortfolioFields
    ...UpdateOwnerCompanyFields
  }
}
    ${PortfolioFieldsFragmentDoc}
${UpdateOwnerCompanyFieldsFragmentDoc}`;
export type HUpsertPortfolioMutationFn = Apollo.MutationFunction<HUpsertPortfolioMutation, HUpsertPortfolioMutationVariables>;

/**
 * __useUpsertPortfolioMutation__
 *
 * To run a mutation, you first call `useUpsertPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPortfolioMutation, { data, loading, error }] = useUpsertPortfolioMutation({
 *   variables: {
 *      portfolio: // value for 'portfolio'
 *   },
 * });
 */
export function useUpsertPortfolioMutation(baseOptions?: Apollo.MutationHookOptions<HUpsertPortfolioMutation, HUpsertPortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpsertPortfolioMutation, HUpsertPortfolioMutationVariables>(UpsertPortfolioDocument, options);
      }
export type UpsertPortfolioMutationHookResult = ReturnType<typeof useUpsertPortfolioMutation>;
export type UpsertPortfolioMutationResult = Apollo.MutationResult<HUpsertPortfolioMutation>;
export type UpsertPortfolioMutationOptions = Apollo.BaseMutationOptions<HUpsertPortfolioMutation, HUpsertPortfolioMutationVariables>;
export const AddCompanyToPortfolioDocument = gql`
    mutation addCompanyToPortfolio($companyData: Company_obj_rel_insert_input, $userId: uuid!) {
  companyUser: insert_CompanyUser_one(
    object: {role: ADMIN, userId: $userId, company: $companyData}
  ) {
    role
    id
    company {
      ...CompanyShort
      portfolioCompanies {
        ...PortfolioCompany
        portfolio {
          id
          portfolioCompanies {
            id
          }
        }
      }
    }
  }
}
    ${CompanyShortFragmentDoc}
${PortfolioCompanyFragmentDoc}`;
export type HAddCompanyToPortfolioMutationFn = Apollo.MutationFunction<HAddCompanyToPortfolioMutation, HAddCompanyToPortfolioMutationVariables>;

/**
 * __useAddCompanyToPortfolioMutation__
 *
 * To run a mutation, you first call `useAddCompanyToPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyToPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyToPortfolioMutation, { data, loading, error }] = useAddCompanyToPortfolioMutation({
 *   variables: {
 *      companyData: // value for 'companyData'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAddCompanyToPortfolioMutation(baseOptions?: Apollo.MutationHookOptions<HAddCompanyToPortfolioMutation, HAddCompanyToPortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HAddCompanyToPortfolioMutation, HAddCompanyToPortfolioMutationVariables>(AddCompanyToPortfolioDocument, options);
      }
export type AddCompanyToPortfolioMutationHookResult = ReturnType<typeof useAddCompanyToPortfolioMutation>;
export type AddCompanyToPortfolioMutationResult = Apollo.MutationResult<HAddCompanyToPortfolioMutation>;
export type AddCompanyToPortfolioMutationOptions = Apollo.BaseMutationOptions<HAddCompanyToPortfolioMutation, HAddCompanyToPortfolioMutationVariables>;
export const CreatePortfolioCompaniesDocument = gql`
    mutation createPortfolioCompanies($companies: [CompanyUser_insert_input!]!) {
  result: insert_CompanyUser(objects: $companies) {
    returning {
      company {
        ...CompanyShort
      }
    }
  }
}
    ${CompanyShortFragmentDoc}`;
export type HCreatePortfolioCompaniesMutationFn = Apollo.MutationFunction<HCreatePortfolioCompaniesMutation, HCreatePortfolioCompaniesMutationVariables>;

/**
 * __useCreatePortfolioCompaniesMutation__
 *
 * To run a mutation, you first call `useCreatePortfolioCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePortfolioCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPortfolioCompaniesMutation, { data, loading, error }] = useCreatePortfolioCompaniesMutation({
 *   variables: {
 *      companies: // value for 'companies'
 *   },
 * });
 */
export function useCreatePortfolioCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<HCreatePortfolioCompaniesMutation, HCreatePortfolioCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HCreatePortfolioCompaniesMutation, HCreatePortfolioCompaniesMutationVariables>(CreatePortfolioCompaniesDocument, options);
      }
export type CreatePortfolioCompaniesMutationHookResult = ReturnType<typeof useCreatePortfolioCompaniesMutation>;
export type CreatePortfolioCompaniesMutationResult = Apollo.MutationResult<HCreatePortfolioCompaniesMutation>;
export type CreatePortfolioCompaniesMutationOptions = Apollo.BaseMutationOptions<HCreatePortfolioCompaniesMutation, HCreatePortfolioCompaniesMutationVariables>;
export const UpdatePortfolioCompanyDocument = gql`
    mutation updatePortfolioCompany($id: uuid!, $data: PortfolioCompany_set_input) {
  portfolioCompany: update_PortfolioCompany_by_pk(
    pk_columns: {id: $id}
    _set: $data
  ) {
    ...PortfolioCompany
  }
}
    ${PortfolioCompanyFragmentDoc}`;
export type HUpdatePortfolioCompanyMutationFn = Apollo.MutationFunction<HUpdatePortfolioCompanyMutation, HUpdatePortfolioCompanyMutationVariables>;

/**
 * __useUpdatePortfolioCompanyMutation__
 *
 * To run a mutation, you first call `useUpdatePortfolioCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePortfolioCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePortfolioCompanyMutation, { data, loading, error }] = useUpdatePortfolioCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePortfolioCompanyMutation(baseOptions?: Apollo.MutationHookOptions<HUpdatePortfolioCompanyMutation, HUpdatePortfolioCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpdatePortfolioCompanyMutation, HUpdatePortfolioCompanyMutationVariables>(UpdatePortfolioCompanyDocument, options);
      }
export type UpdatePortfolioCompanyMutationHookResult = ReturnType<typeof useUpdatePortfolioCompanyMutation>;
export type UpdatePortfolioCompanyMutationResult = Apollo.MutationResult<HUpdatePortfolioCompanyMutation>;
export type UpdatePortfolioCompanyMutationOptions = Apollo.BaseMutationOptions<HUpdatePortfolioCompanyMutation, HUpdatePortfolioCompanyMutationVariables>;
export const UploadPortfolioCompanyScoreDocument = gql`
    mutation uploadPortfolioCompanyScore($portfolioId: uuid!, $companyId: uuid!, $scores: jsonb) {
  portfolioCompany: update_PortfolioCompany(
    where: {portfolioId: {_eq: $portfolioId}, _and: {companyId: {_eq: $companyId}}}
    _set: {scores: $scores}
  ) {
    returning {
      ...UserPortfolioCompany
    }
  }
}
    ${UserPortfolioCompanyFragmentDoc}`;
export type HUploadPortfolioCompanyScoreMutationFn = Apollo.MutationFunction<HUploadPortfolioCompanyScoreMutation, HUploadPortfolioCompanyScoreMutationVariables>;

/**
 * __useUploadPortfolioCompanyScoreMutation__
 *
 * To run a mutation, you first call `useUploadPortfolioCompanyScoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPortfolioCompanyScoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPortfolioCompanyScoreMutation, { data, loading, error }] = useUploadPortfolioCompanyScoreMutation({
 *   variables: {
 *      portfolioId: // value for 'portfolioId'
 *      companyId: // value for 'companyId'
 *      scores: // value for 'scores'
 *   },
 * });
 */
export function useUploadPortfolioCompanyScoreMutation(baseOptions?: Apollo.MutationHookOptions<HUploadPortfolioCompanyScoreMutation, HUploadPortfolioCompanyScoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUploadPortfolioCompanyScoreMutation, HUploadPortfolioCompanyScoreMutationVariables>(UploadPortfolioCompanyScoreDocument, options);
      }
export type UploadPortfolioCompanyScoreMutationHookResult = ReturnType<typeof useUploadPortfolioCompanyScoreMutation>;
export type UploadPortfolioCompanyScoreMutationResult = Apollo.MutationResult<HUploadPortfolioCompanyScoreMutation>;
export type UploadPortfolioCompanyScoreMutationOptions = Apollo.BaseMutationOptions<HUploadPortfolioCompanyScoreMutation, HUploadPortfolioCompanyScoreMutationVariables>;
export const DeletePortfolioCompanyDocument = gql`
    mutation deletePortfolioCompany($id: uuid!) {
  portfolioCompany: delete_PortfolioCompany_by_pk(id: $id) {
    ...PortfolioCompany
    portfolio {
      id
      portfolioCompanies {
        id
      }
    }
  }
}
    ${PortfolioCompanyFragmentDoc}`;
export type HDeletePortfolioCompanyMutationFn = Apollo.MutationFunction<HDeletePortfolioCompanyMutation, HDeletePortfolioCompanyMutationVariables>;

/**
 * __useDeletePortfolioCompanyMutation__
 *
 * To run a mutation, you first call `useDeletePortfolioCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePortfolioCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePortfolioCompanyMutation, { data, loading, error }] = useDeletePortfolioCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePortfolioCompanyMutation(baseOptions?: Apollo.MutationHookOptions<HDeletePortfolioCompanyMutation, HDeletePortfolioCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HDeletePortfolioCompanyMutation, HDeletePortfolioCompanyMutationVariables>(DeletePortfolioCompanyDocument, options);
      }
export type DeletePortfolioCompanyMutationHookResult = ReturnType<typeof useDeletePortfolioCompanyMutation>;
export type DeletePortfolioCompanyMutationResult = Apollo.MutationResult<HDeletePortfolioCompanyMutation>;
export type DeletePortfolioCompanyMutationOptions = Apollo.BaseMutationOptions<HDeletePortfolioCompanyMutation, HDeletePortfolioCompanyMutationVariables>;
export const UpsertPortfolioCompanyDocument = gql`
    mutation upsertPortfolioCompany($portfolioCompany: PortfolioCompany_insert_input!) {
  portfolioCompany: insert_PortfolioCompany_one(
    object: $portfolioCompany
    on_conflict: {constraint: PortfolioCompany_portfolioId_companyId_fcfe62f4_uniq, update_columns: [amount, contactPersonEmail, contactPersonName]}
  ) {
    ...PortfolioCompany
    portfolio {
      id
      portfolioCompanies {
        id
      }
    }
  }
}
    ${PortfolioCompanyFragmentDoc}`;
export type HUpsertPortfolioCompanyMutationFn = Apollo.MutationFunction<HUpsertPortfolioCompanyMutation, HUpsertPortfolioCompanyMutationVariables>;

/**
 * __useUpsertPortfolioCompanyMutation__
 *
 * To run a mutation, you first call `useUpsertPortfolioCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPortfolioCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPortfolioCompanyMutation, { data, loading, error }] = useUpsertPortfolioCompanyMutation({
 *   variables: {
 *      portfolioCompany: // value for 'portfolioCompany'
 *   },
 * });
 */
export function useUpsertPortfolioCompanyMutation(baseOptions?: Apollo.MutationHookOptions<HUpsertPortfolioCompanyMutation, HUpsertPortfolioCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpsertPortfolioCompanyMutation, HUpsertPortfolioCompanyMutationVariables>(UpsertPortfolioCompanyDocument, options);
      }
export type UpsertPortfolioCompanyMutationHookResult = ReturnType<typeof useUpsertPortfolioCompanyMutation>;
export type UpsertPortfolioCompanyMutationResult = Apollo.MutationResult<HUpsertPortfolioCompanyMutation>;
export type UpsertPortfolioCompanyMutationOptions = Apollo.BaseMutationOptions<HUpsertPortfolioCompanyMutation, HUpsertPortfolioCompanyMutationVariables>;
export const UpsertPortfolioCompaniesDocument = gql`
    mutation upsertPortfolioCompanies($portfolioCompanies: [PortfolioCompany_insert_input!]!) {
  portfolioCompanies: insert_PortfolioCompany(
    objects: $portfolioCompanies
    on_conflict: {constraint: PortfolioCompany_portfolioId_companyId_fcfe62f4_uniq, update_columns: amount}
  ) {
    affected_rows
  }
}
    `;
export type HUpsertPortfolioCompaniesMutationFn = Apollo.MutationFunction<HUpsertPortfolioCompaniesMutation, HUpsertPortfolioCompaniesMutationVariables>;

/**
 * __useUpsertPortfolioCompaniesMutation__
 *
 * To run a mutation, you first call `useUpsertPortfolioCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPortfolioCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPortfolioCompaniesMutation, { data, loading, error }] = useUpsertPortfolioCompaniesMutation({
 *   variables: {
 *      portfolioCompanies: // value for 'portfolioCompanies'
 *   },
 * });
 */
export function useUpsertPortfolioCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<HUpsertPortfolioCompaniesMutation, HUpsertPortfolioCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpsertPortfolioCompaniesMutation, HUpsertPortfolioCompaniesMutationVariables>(UpsertPortfolioCompaniesDocument, options);
      }
export type UpsertPortfolioCompaniesMutationHookResult = ReturnType<typeof useUpsertPortfolioCompaniesMutation>;
export type UpsertPortfolioCompaniesMutationResult = Apollo.MutationResult<HUpsertPortfolioCompaniesMutation>;
export type UpsertPortfolioCompaniesMutationOptions = Apollo.BaseMutationOptions<HUpsertPortfolioCompaniesMutation, HUpsertPortfolioCompaniesMutationVariables>;
export const UpsertPortfolioEstimateCompanyDocument = gql`
    mutation upsertPortfolioEstimateCompany($portfolioCompany: PortfolioCompany_insert_input!) {
  portfolioCompany: insert_PortfolioCompany_one(
    object: $portfolioCompany
    on_conflict: {constraint: PortfolioCompany_portfolioId_estimateCompanyId_fd25d65d_uniq, update_columns: amount}
  ) {
    ...PortfolioCompany
    portfolio {
      id
      portfolioCompanies {
        id
      }
    }
  }
}
    ${PortfolioCompanyFragmentDoc}`;
export type HUpsertPortfolioEstimateCompanyMutationFn = Apollo.MutationFunction<HUpsertPortfolioEstimateCompanyMutation, HUpsertPortfolioEstimateCompanyMutationVariables>;

/**
 * __useUpsertPortfolioEstimateCompanyMutation__
 *
 * To run a mutation, you first call `useUpsertPortfolioEstimateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPortfolioEstimateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPortfolioEstimateCompanyMutation, { data, loading, error }] = useUpsertPortfolioEstimateCompanyMutation({
 *   variables: {
 *      portfolioCompany: // value for 'portfolioCompany'
 *   },
 * });
 */
export function useUpsertPortfolioEstimateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<HUpsertPortfolioEstimateCompanyMutation, HUpsertPortfolioEstimateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpsertPortfolioEstimateCompanyMutation, HUpsertPortfolioEstimateCompanyMutationVariables>(UpsertPortfolioEstimateCompanyDocument, options);
      }
export type UpsertPortfolioEstimateCompanyMutationHookResult = ReturnType<typeof useUpsertPortfolioEstimateCompanyMutation>;
export type UpsertPortfolioEstimateCompanyMutationResult = Apollo.MutationResult<HUpsertPortfolioEstimateCompanyMutation>;
export type UpsertPortfolioEstimateCompanyMutationOptions = Apollo.BaseMutationOptions<HUpsertPortfolioEstimateCompanyMutation, HUpsertPortfolioEstimateCompanyMutationVariables>;
export const DeletePortfolioDocument = gql`
    mutation deletePortfolio($id: uuid!) {
  portfolio: update_Portfolio_by_pk(
    pk_columns: {id: $id}
    _set: {deletedAt: "now()"}
  ) {
    ...PortfolioFields
    ...UpdateOwnerCompanyFields
  }
}
    ${PortfolioFieldsFragmentDoc}
${UpdateOwnerCompanyFieldsFragmentDoc}`;
export type HDeletePortfolioMutationFn = Apollo.MutationFunction<HDeletePortfolioMutation, HDeletePortfolioMutationVariables>;

/**
 * __useDeletePortfolioMutation__
 *
 * To run a mutation, you first call `useDeletePortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePortfolioMutation, { data, loading, error }] = useDeletePortfolioMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePortfolioMutation(baseOptions?: Apollo.MutationHookOptions<HDeletePortfolioMutation, HDeletePortfolioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HDeletePortfolioMutation, HDeletePortfolioMutationVariables>(DeletePortfolioDocument, options);
      }
export type DeletePortfolioMutationHookResult = ReturnType<typeof useDeletePortfolioMutation>;
export type DeletePortfolioMutationResult = Apollo.MutationResult<HDeletePortfolioMutation>;
export type DeletePortfolioMutationOptions = Apollo.BaseMutationOptions<HDeletePortfolioMutation, HDeletePortfolioMutationVariables>;
export const InsertPortfolioCompaniesDocument = gql`
    mutation InsertPortfolioCompanies($objects: [PortfolioCompany_insert_input!]!) {
  insert_PortfolioCompany(
    objects: $objects
    on_conflict: {constraint: PortfolioCompany_portfolioId_estimateCompanyId_fd25d65d_uniq, update_columns: amount}
  ) {
    affected_rows
  }
}
    `;
export type HInsertPortfolioCompaniesMutationFn = Apollo.MutationFunction<HInsertPortfolioCompaniesMutation, HInsertPortfolioCompaniesMutationVariables>;

/**
 * __useInsertPortfolioCompaniesMutation__
 *
 * To run a mutation, you first call `useInsertPortfolioCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPortfolioCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPortfolioCompaniesMutation, { data, loading, error }] = useInsertPortfolioCompaniesMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertPortfolioCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<HInsertPortfolioCompaniesMutation, HInsertPortfolioCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HInsertPortfolioCompaniesMutation, HInsertPortfolioCompaniesMutationVariables>(InsertPortfolioCompaniesDocument, options);
      }
export type InsertPortfolioCompaniesMutationHookResult = ReturnType<typeof useInsertPortfolioCompaniesMutation>;
export type InsertPortfolioCompaniesMutationResult = Apollo.MutationResult<HInsertPortfolioCompaniesMutation>;
export type InsertPortfolioCompaniesMutationOptions = Apollo.BaseMutationOptions<HInsertPortfolioCompaniesMutation, HInsertPortfolioCompaniesMutationVariables>;
export const NudgePortfolioCompaniesDocument = gql`
    mutation nudgePortfolioCompanies($recipients: [EmailItem!]!, $data: NudgePortfolioCompaniesInput!) {
  nudgePortfolioCompanies(recipients: $recipients, data: $data) {
    ...NudgePortfolioCompaniesFields
  }
}
    ${NudgePortfolioCompaniesFieldsFragmentDoc}`;
export type HNudgePortfolioCompaniesMutationFn = Apollo.MutationFunction<HNudgePortfolioCompaniesMutation, HNudgePortfolioCompaniesMutationVariables>;

/**
 * __useNudgePortfolioCompaniesMutation__
 *
 * To run a mutation, you first call `useNudgePortfolioCompaniesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNudgePortfolioCompaniesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [nudgePortfolioCompaniesMutation, { data, loading, error }] = useNudgePortfolioCompaniesMutation({
 *   variables: {
 *      recipients: // value for 'recipients'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useNudgePortfolioCompaniesMutation(baseOptions?: Apollo.MutationHookOptions<HNudgePortfolioCompaniesMutation, HNudgePortfolioCompaniesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HNudgePortfolioCompaniesMutation, HNudgePortfolioCompaniesMutationVariables>(NudgePortfolioCompaniesDocument, options);
      }
export type NudgePortfolioCompaniesMutationHookResult = ReturnType<typeof useNudgePortfolioCompaniesMutation>;
export type NudgePortfolioCompaniesMutationResult = Apollo.MutationResult<HNudgePortfolioCompaniesMutation>;
export type NudgePortfolioCompaniesMutationOptions = Apollo.BaseMutationOptions<HNudgePortfolioCompaniesMutation, HNudgePortfolioCompaniesMutationVariables>;