import { createIcon } from '@chakra-ui/icons';

export const NonCompliantIcon = createIcon({
  displayName: 'NonCompliantIcon',
  viewBox: '0 0 22 22',
  d:
    'M19.8404 21.7305L14.1404 16.0305C12.2604 17.7905 9.92035 19.0005 7.00035 19.0005C6.64035 19.0005 6.14035 18.8705 5.66035 18.7005L4.71035 21.0005L2.82035 20.3405C4.15035 17.0305 5.50035 13.3205 8.66035 10.5505L7.77035 9.66047C5.76035 11.0305 3.86035 13.1005 2.75035 16.2505C2.75035 16.2505 1.00035 14.5005 1.00035 12.5005C1.00035 11.0005 2.12035 8.32047 4.72035 6.61047L0.110352 2.00047L1.39035 0.730469L15.3904 14.7405L21.1104 20.4605L19.8404 21.7305ZM16.0004 7.00047C14.3504 7.37047 12.9304 7.88047 11.7004 8.50047L16.5004 13.2905C19.8703 8.35047 21.0004 2.00047 21.0004 2.00047C20.0304 3.95047 13.3504 4.24047 8.38035 5.18047L11.1504 7.95047C13.8104 7.00047 16.0004 7.00047 16.0004 7.00047Z',
});
export const CompliantIcon = createIcon({
  displayName: 'CompliantIcon',
  viewBox: '0 0 22 22',
  d:
    'M1.82 13.21a6.995 6.995 0 0 1 .594-6.756C3.856 4.317 8.58 2.433 16.584.8c-.268 6.85-1.122 11.345-2.563 13.482a7 7 0 0 1-9.467 2.05 7.562 7.562 0 0 1 2.27-2.482l.507-.352a2 2 0 0 0-2.278-3.288l-.507.352A11.558 11.558 0 0 0 1.82 13.21Zm.746 6.86a1 1 0 1 1-2 0c0-3.465 1.7-6.711 4.55-8.685l.507-.352a1 1 0 1 1 1.14 1.644l-.508.352a8.567 8.567 0 0 0-3.689 7.042Z',
});
