import React, { useState } from 'react';

import { QuestionIcon } from '@chakra-ui/icons';

import {
  NoteIcon,
  ScreeningIcon,
  FinancialsIcon,
  DocumentationIcon,
  TeamMembersCollaborationIcon,
  TaxonomyScoreIcon,
  CompanyStructureIcon,
  DictionaryIcon,
  AboutTaxonomyIcon,
  FaqIcon,
  GettingStartedIcon,
} from 'icons/icons';
import {
  Avatar,
  VStack,
  Text,
  Flex,
  IconButton,
  useDisclosure,
  SimpleGrid,
  Box,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  CloseButton,
  Heading,
} from '@chakra-ui/react';

import DefaultDrawer from 'components/ui/Drawer';
import { SearchInput } from 'components/ui/SearchInput';

import { useTranslation } from 'utils/i18n';

import {
  Article,
  Tag,
  useArticles,
  useArticleSearch,
  useArticlesWithTags,
} from '../../models/knowledgeCenter-model';
import useDebounce from 'hooks/useDebounce';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from '../../utils/mixpanel';
import { useCurrentCompanyId } from '../../models/localState';

const createMarkup = (body: string) => {
  return { __html: body };
};

export const ArticleResultItem = ({ article }: { article: Article }) => {
  return (
    <AccordionItem
      width="100%"
      marginBottom="md"
      borderWidth="1px"
      borderColor="secondary.main"
      outlineColor="secondary.main"
      borderRadius="4px"
      flex="1"
    >
      <h2>
        <AccordionButton justifyContent="space-between">
          <Box noOfLines={1} textColor="blue">
            {article.title}
          </Box>
          <AccordionIcon color="primary.main" />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Box margin={2}>
          <div dangerouslySetInnerHTML={createMarkup(article.body)}></div>
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};

const Loader = () => {
  return (
    <Accordion width="100%">
      {[0, 1, 2, 3].map((i) => (
        <AccordionItem
          width="100%"
          key={i}
          marginBottom="md"
          borderWidth="1px"
          borderColor="secondary.main"
          outlineColor="secondary.main"
          borderRadius="4px"
          flex="1"
        >
          <Box bg="#E0E0E0" w="100%" p={4} color="white"></Box>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

const getAccordion = (articlesToShow: Article[], isLoading: boolean) => {
  if (!isLoading) {
    return (
      <Accordion allowToggle allowMultiple width="100%">
        {articlesToShow.length > 0 &&
          articlesToShow.map((i) => <ArticleResultItem key={i.id} article={i} />)}
        {!isLoading && articlesToShow.length === 0 && <div> No results found.</div>}
      </Accordion>
    );
  }
  return <Loader />;
};

const KNOWLEDGE_BASE_ICONS: { [key: string]: React.ReactNode } = {
  Screening: <ScreeningIcon boxSize={10} />,
  'Financial data': <FinancialsIcon boxSize={10} />,
  Documentation: <DocumentationIcon boxSize={10} />,
  'Team members and collaboration': <TeamMembersCollaborationIcon boxSize={10} />,
  'Taxonomy score': <TaxonomyScoreIcon boxSize={10} />,
  'Company structure': <CompanyStructureIcon boxSize={10} />,
  'Taxonomy dictionary': <DictionaryIcon boxSize={10} />,
  'About the taxonomy': <AboutTaxonomyIcon boxSize={10} />,
  FAQ: <FaqIcon boxSize={10} />,
  'Getting started': <GettingStartedIcon boxSize={10} />,
};

export default function KnowledgeBase() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const companyId = useCurrentCompanyId();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [activeTag, setActiveTag] = useState('');
  const [loading, setLoading] = useState(false);

  const debouncedQuery = useDebounce(searchValue, 1000);

  const { searched } = useArticleSearch(debouncedQuery);

  const { articles } = useArticles();
  const { tags } = useArticlesWithTags();
  const TagItem = ({ tag }: { tag: Tag }) => {
    const tagClick = () => {
      setSearchValue('');
      setActiveTag(tag.id);
    };

    return (
      <VStack alignItems="flex-start">
        {KNOWLEDGE_BASE_ICONS[tag.name] ? (
          KNOWLEDGE_BASE_ICONS[tag.name]
        ) : (
          <NoteIcon boxSize={10} />
        )}
        <Box color="blue" style={{ cursor: 'pointer' }} onClick={tagClick}>
          {tag.name}
        </Box>
        <Box>{tag.description}</Box>
      </VStack>
    );
  };

  const getArticlesToShow = () => {
    if (searchValue !== '') {
      return searched;
    }
    if (activeTag !== '') {
      return tags.find((i) => i.id === activeTag)?.articles.map((i) => i.Article) ?? [];
    }
    return articles ?? [];
  };

  const articlesToShow = getArticlesToShow();

  const getActiveTagName = () => {
    return tags.find((i) => i.id === activeTag)?.name;
  };

  const getTitle = () => {
    if (searchValue !== '') {
      return <Text fontSize="2xl"> {`Showing results for ${searchValue}`} </Text>;
    } else if (activeTag !== '') {
      return (
        <Flex>
          <Text fontSize="2xl">{`Category: ${getActiveTagName()}`} </Text>
          <CloseButton mt="1" onClick={() => setActiveTag('')} />
        </Flex>
      );
    } else return <Text fontSize="2xl">{t('common:knowledgeBase.relatedHelpResources')}</Text>;
  };

  const setSearch = (input: { value: string }) => {
    setLoading(true);
    setSearchValue(input.value);
    setActiveTag('');
    setTimeout(() => setLoading(false), 1200);
  };
  return (
    <>
      <DefaultDrawer
        onClose={onClose}
        isOpen={isOpen}
        size="lg"
        returnFocusOnClose={false}
        header={<Heading fontSize="2xl">{t('common:knowledgeBase.title')}</Heading>}
      >
        <VStack
          alignItems="flex-start"
          spacing="md"
          onClick={(e) => {
            if ((e.target as HTMLElement).tagName == 'A') onClose();
          }}
        >
          <SearchInput
            search={searchValue}
            setSearch={(input) => setSearch({ value: input })}
            placeholder={'Search for help'}
          />
          {getTitle()}
          {getAccordion(articlesToShow, loading)}
        </VStack>
        <Text mt={5} mb={5} fontSize="2xl">
          {t('common:knowledgeBase.subTitle')}
        </Text>
        <SimpleGrid columns={2} spacing={10}>
          {tags.map((i) => {
            return <TagItem key={i.id} tag={i} />;
          })}
        </SimpleGrid>
      </DefaultDrawer>
      <IconButton
        variant="ghost"
        height="40px"
        aria-label={t('common:notifications.notifications')}
        onClick={() => {
          onOpen();
          mixpanel.track(TRACKING_EVENTS.KNOWLEDGE_BASE.OPEN, {
            companyId,
            location: window.location.pathname,
          });
        }}
        icon={
          <Avatar
            cursor="pointer"
            icon={<QuestionIcon boxSize="20px" />}
            bg="primary.light"
            color="primary.main"
            boxSize="32px"
          />
        }
      />
    </>
  );
}
