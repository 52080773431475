import { createContext, useContext, useEffect, useCallback } from 'react';
import { ApolloQueryResult, useApolloClient } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core';
import { DocumentNode } from 'graphql';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { FetchPolicy, QueryOptions } from '@apollo/client/core/watchQueryOptions';
import { useUpdate } from 'react-use';

export const QuerySuspenseContext = createContext({} as any);

const suspend = <TData = any>(promise: Promise<TData>) => {
  let status = 'pending';
  let response: ApolloQueryResult<TData>;
  let data: TData;
  const setData = (d: TData) => {
    status = 'success';
    data = d;
  };

  const suspender = promise.then(
    (d) => setData(d),
    (err) => {
      status = 'error';
      response = err;
    }
  );

  const read = () => {
    switch (status) {
      case 'pending':
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw suspender;
      case 'error':
        // eslint-disable-next-line @typescript-eslint/no-throw-literal
        throw response;
      default:
        return data;
    }
  };
  return { read, setData };
};
export function useClearSuspenseCache() {
  const suspenseCache = useContext(QuerySuspenseContext);
  return useCallback(() => {
    Object.keys(suspenseCache).forEach((k) => {
      delete suspenseCache[k];
    });
  }, [suspenseCache]);
}

export const useQuerySuspense = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: Omit<QueryOptions<TVariables, TData>, 'query'> & { skip?: boolean },
  queryId?: string
): TData => {
  const client = useApolloClient();
  const queryOptions = {
    query,
    ...options,
    fetchPolicy: 'cache-first' as FetchPolicy,
  };
  const key = queryId || JSON.stringify(queryOptions);
  const suspenseCache = useContext(QuerySuspenseContext);
  const update = useUpdate();

  if (!suspenseCache[key] && !options?.skip) {
    const observable = client.watchQuery(queryOptions);
    const { read, setData } = suspend(
      new Promise<TData>((resolve, reject) => {
        const subscription: ReturnType<typeof observable.subscribe> = observable.subscribe(
          (response) => {
            if (!response.loading) {
              resolve(response.data);
              setData(response.data);
            }
          },
          reject,
          () => subscription.unsubscribe()
        );
        const currentResult = client.readQuery(queryOptions);
        if (currentResult) resolve(currentResult);
      })
    );
    suspenseCache[key] = { read, observable, queryOptions };
  }
  const cacheItem = suspenseCache[key];

  useEffect(() => {
    if (cacheItem) {
      const { observable } = cacheItem;
      const subscription = observable.subscribe(update);
      return () => subscription.unsubscribe();
    }
  }, [cacheItem]);

  if (options?.skip) return {} as TData;
  return cacheItem.read();
};
