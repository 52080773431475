/* d572650c02c1b029f4df676acc1687407bee956b
 * This file is automatically generated by graphql-let. */

import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = { [X in Exclude<keyof T, K>]?: T[X] } & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  citext: any;
  date: any;
  float8: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** columns and relationships of "Activity" */
export type HActivity = {
  /** An array relationship */
  BusinessUnitDistributionKeys: Array<HBusinessUnitDistributionKey>;
  /** An array relationship */
  activityNaceCodes: Array<HActivityNaceCode>;
  /** An array relationship */
  activityQuestionSets: Array<HActivityQuestionSet>;
  /** An array relationship */
  activityReports: Array<HActivityReport>;
  description: Scalars['String'];
  name: Scalars['String'];
  reference: Scalars['String'];
  /** An object relationship */
  sector: HSector;
  sectorRef: Scalars['String'];
  tagExpression?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Activity" */
export type HActivityBusinessUnitDistributionKeysArgs = {
  distinct_on?: Maybe<Array<HBusinessUnitDistributionKey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnitDistributionKey_Order_By>>;
  where?: Maybe<HBusinessUnitDistributionKey_Bool_Exp>;
};


/** columns and relationships of "Activity" */
export type HActivityActivityNaceCodesArgs = {
  distinct_on?: Maybe<Array<HActivityNaceCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityNaceCode_Order_By>>;
  where?: Maybe<HActivityNaceCode_Bool_Exp>;
};


/** columns and relationships of "Activity" */
export type HActivityActivityQuestionSetsArgs = {
  distinct_on?: Maybe<Array<HActivityQuestionSet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityQuestionSet_Order_By>>;
  where?: Maybe<HActivityQuestionSet_Bool_Exp>;
};


/** columns and relationships of "Activity" */
export type HActivityActivityReportsArgs = {
  distinct_on?: Maybe<Array<HActivityReport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityReport_Order_By>>;
  where?: Maybe<HActivityReport_Bool_Exp>;
};

/** columns and relationships of "ActivityNaceCode" */
export type HActivityNaceCode = {
  /** An object relationship */
  activity: HActivity;
  activityRef: Scalars['String'];
  code: Scalars['String'];
  id: Scalars['bigint'];
};

/** order by aggregate values of table "ActivityNaceCode" */
export type HActivityNaceCode_Aggregate_Order_By = {
  avg?: Maybe<HActivityNaceCode_Avg_Order_By>;
  count?: Maybe<HOrder_By>;
  max?: Maybe<HActivityNaceCode_Max_Order_By>;
  min?: Maybe<HActivityNaceCode_Min_Order_By>;
  stddev?: Maybe<HActivityNaceCode_Stddev_Order_By>;
  stddev_pop?: Maybe<HActivityNaceCode_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<HActivityNaceCode_Stddev_Samp_Order_By>;
  sum?: Maybe<HActivityNaceCode_Sum_Order_By>;
  var_pop?: Maybe<HActivityNaceCode_Var_Pop_Order_By>;
  var_samp?: Maybe<HActivityNaceCode_Var_Samp_Order_By>;
  variance?: Maybe<HActivityNaceCode_Variance_Order_By>;
};

/** order by avg() on columns of table "ActivityNaceCode" */
export type HActivityNaceCode_Avg_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** Boolean expression to filter rows from the table "ActivityNaceCode". All fields are combined with a logical 'AND'. */
export type HActivityNaceCode_Bool_Exp = {
  _and?: Maybe<Array<HActivityNaceCode_Bool_Exp>>;
  _not?: Maybe<HActivityNaceCode_Bool_Exp>;
  _or?: Maybe<Array<HActivityNaceCode_Bool_Exp>>;
  activity?: Maybe<HActivity_Bool_Exp>;
  activityRef?: Maybe<HString_Comparison_Exp>;
  code?: Maybe<HString_Comparison_Exp>;
  id?: Maybe<HBigint_Comparison_Exp>;
};

/** order by max() on columns of table "ActivityNaceCode" */
export type HActivityNaceCode_Max_Order_By = {
  activityRef?: Maybe<HOrder_By>;
  code?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "ActivityNaceCode" */
export type HActivityNaceCode_Min_Order_By = {
  activityRef?: Maybe<HOrder_By>;
  code?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
};

/** Ordering options when selecting data from "ActivityNaceCode". */
export type HActivityNaceCode_Order_By = {
  activity?: Maybe<HActivity_Order_By>;
  activityRef?: Maybe<HOrder_By>;
  code?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
};

/** select columns of table "ActivityNaceCode" */
export enum HActivityNaceCode_Select_Column {
  /** column name */
  ActivityRef = 'activityRef',
  /** column name */
  Code = 'code',
  /** column name */
  Id = 'id'
}

/** order by stddev() on columns of table "ActivityNaceCode" */
export type HActivityNaceCode_Stddev_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by stddev_pop() on columns of table "ActivityNaceCode" */
export type HActivityNaceCode_Stddev_Pop_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by stddev_samp() on columns of table "ActivityNaceCode" */
export type HActivityNaceCode_Stddev_Samp_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by sum() on columns of table "ActivityNaceCode" */
export type HActivityNaceCode_Sum_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by var_pop() on columns of table "ActivityNaceCode" */
export type HActivityNaceCode_Var_Pop_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by var_samp() on columns of table "ActivityNaceCode" */
export type HActivityNaceCode_Var_Samp_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by variance() on columns of table "ActivityNaceCode" */
export type HActivityNaceCode_Variance_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** columns and relationships of "ActivityQuestionSet" */
export type HActivityQuestionSet = {
  /** An object relationship */
  activity: HActivity;
  activityRef: Scalars['String'];
  id: Scalars['bigint'];
  /** An object relationship */
  questionSet: HQuestionSet;
  questionSetRef: Scalars['String'];
};

/** order by aggregate values of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Aggregate_Order_By = {
  avg?: Maybe<HActivityQuestionSet_Avg_Order_By>;
  count?: Maybe<HOrder_By>;
  max?: Maybe<HActivityQuestionSet_Max_Order_By>;
  min?: Maybe<HActivityQuestionSet_Min_Order_By>;
  stddev?: Maybe<HActivityQuestionSet_Stddev_Order_By>;
  stddev_pop?: Maybe<HActivityQuestionSet_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<HActivityQuestionSet_Stddev_Samp_Order_By>;
  sum?: Maybe<HActivityQuestionSet_Sum_Order_By>;
  var_pop?: Maybe<HActivityQuestionSet_Var_Pop_Order_By>;
  var_samp?: Maybe<HActivityQuestionSet_Var_Samp_Order_By>;
  variance?: Maybe<HActivityQuestionSet_Variance_Order_By>;
};

/** order by avg() on columns of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Avg_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** Boolean expression to filter rows from the table "ActivityQuestionSet". All fields are combined with a logical 'AND'. */
export type HActivityQuestionSet_Bool_Exp = {
  _and?: Maybe<Array<HActivityQuestionSet_Bool_Exp>>;
  _not?: Maybe<HActivityQuestionSet_Bool_Exp>;
  _or?: Maybe<Array<HActivityQuestionSet_Bool_Exp>>;
  activity?: Maybe<HActivity_Bool_Exp>;
  activityRef?: Maybe<HString_Comparison_Exp>;
  id?: Maybe<HBigint_Comparison_Exp>;
  questionSet?: Maybe<HQuestionSet_Bool_Exp>;
  questionSetRef?: Maybe<HString_Comparison_Exp>;
};

/** order by max() on columns of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Max_Order_By = {
  activityRef?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  questionSetRef?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Min_Order_By = {
  activityRef?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  questionSetRef?: Maybe<HOrder_By>;
};

/** Ordering options when selecting data from "ActivityQuestionSet". */
export type HActivityQuestionSet_Order_By = {
  activity?: Maybe<HActivity_Order_By>;
  activityRef?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  questionSet?: Maybe<HQuestionSet_Order_By>;
  questionSetRef?: Maybe<HOrder_By>;
};

/** select columns of table "ActivityQuestionSet" */
export enum HActivityQuestionSet_Select_Column {
  /** column name */
  ActivityRef = 'activityRef',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionSetRef = 'questionSetRef'
}

/** order by stddev() on columns of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Stddev_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by stddev_pop() on columns of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Stddev_Pop_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by stddev_samp() on columns of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Stddev_Samp_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by sum() on columns of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Sum_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by var_pop() on columns of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Var_Pop_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by var_samp() on columns of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Var_Samp_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by variance() on columns of table "ActivityQuestionSet" */
export type HActivityQuestionSet_Variance_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** columns and relationships of "ActivityReport" */
export type HActivityReport = {
  /** An object relationship */
  activity: HActivity;
  activityRef: Scalars['String'];
  /** An array relationship */
  answers: Array<HAnswer>;
  /** An object relationship */
  bAssessment: HBusinessUnitAssessment;
  bAssessmentId: Scalars['uuid'];
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  financials?: Maybe<HFinancials>;
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "ActivityReport" */
export type HActivityReportAnswersArgs = {
  distinct_on?: Maybe<Array<HAnswer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswer_Order_By>>;
  where?: Maybe<HAnswer_Bool_Exp>;
};

/** order by aggregate values of table "ActivityReport" */
export type HActivityReport_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HActivityReport_Max_Order_By>;
  min?: Maybe<HActivityReport_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ActivityReport" */
export type HActivityReport_Arr_Rel_Insert_Input = {
  data: Array<HActivityReport_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HActivityReport_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ActivityReport". All fields are combined with a logical 'AND'. */
export type HActivityReport_Bool_Exp = {
  _and?: Maybe<Array<HActivityReport_Bool_Exp>>;
  _not?: Maybe<HActivityReport_Bool_Exp>;
  _or?: Maybe<Array<HActivityReport_Bool_Exp>>;
  activity?: Maybe<HActivity_Bool_Exp>;
  activityRef?: Maybe<HString_Comparison_Exp>;
  answers?: Maybe<HAnswer_Bool_Exp>;
  bAssessment?: Maybe<HBusinessUnitAssessment_Bool_Exp>;
  bAssessmentId?: Maybe<HUuid_Comparison_Exp>;
  completedAt?: Maybe<HTimestamptz_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  deletedAt?: Maybe<HTimestamptz_Comparison_Exp>;
  financials?: Maybe<HFinancials_Bool_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ActivityReport" */
export enum HActivityReport_Constraint {
  /** unique or primary key constraint */
  ActivityReportBAssessmentIdActivityRef_614b7372Uniq = 'ActivityReport_bAssessmentId_activityRef_614b7372_uniq',
  /** unique or primary key constraint */
  ActivityReportPkey = 'activityReport_pkey'
}

/** input type for inserting data into table "ActivityReport" */
export type HActivityReport_Insert_Input = {
  activityRef?: Maybe<Scalars['String']>;
  answers?: Maybe<HAnswer_Arr_Rel_Insert_Input>;
  bAssessment?: Maybe<HBusinessUnitAssessment_Obj_Rel_Insert_Input>;
  bAssessmentId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  financials?: Maybe<HFinancials_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "ActivityReport" */
export type HActivityReport_Max_Order_By = {
  activityRef?: Maybe<HOrder_By>;
  bAssessmentId?: Maybe<HOrder_By>;
  completedAt?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "ActivityReport" */
export type HActivityReport_Min_Order_By = {
  activityRef?: Maybe<HOrder_By>;
  bAssessmentId?: Maybe<HOrder_By>;
  completedAt?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "ActivityReport" */
export type HActivityReport_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HActivityReport>;
};

/** input type for inserting object relation for remote table "ActivityReport" */
export type HActivityReport_Obj_Rel_Insert_Input = {
  data: HActivityReport_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HActivityReport_On_Conflict>;
};

/** on conflict condition type for table "ActivityReport" */
export type HActivityReport_On_Conflict = {
  constraint: HActivityReport_Constraint;
  update_columns?: Array<HActivityReport_Update_Column>;
  where?: Maybe<HActivityReport_Bool_Exp>;
};

/** Ordering options when selecting data from "ActivityReport". */
export type HActivityReport_Order_By = {
  activity?: Maybe<HActivity_Order_By>;
  activityRef?: Maybe<HOrder_By>;
  answers_aggregate?: Maybe<HAnswer_Aggregate_Order_By>;
  bAssessment?: Maybe<HBusinessUnitAssessment_Order_By>;
  bAssessmentId?: Maybe<HOrder_By>;
  completedAt?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  financials?: Maybe<HFinancials_Order_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** primary key columns input for table: ActivityReport */
export type HActivityReport_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ActivityReport" */
export enum HActivityReport_Select_Column {
  /** column name */
  ActivityRef = 'activityRef',
  /** column name */
  BAssessmentId = 'bAssessmentId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "ActivityReport" */
export type HActivityReport_Set_Input = {
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "ActivityReport" */
export enum HActivityReport_Update_Column {
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt'
}

/** order by aggregate values of table "Activity" */
export type HActivity_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HActivity_Max_Order_By>;
  min?: Maybe<HActivity_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "Activity". All fields are combined with a logical 'AND'. */
export type HActivity_Bool_Exp = {
  BusinessUnitDistributionKeys?: Maybe<HBusinessUnitDistributionKey_Bool_Exp>;
  _and?: Maybe<Array<HActivity_Bool_Exp>>;
  _not?: Maybe<HActivity_Bool_Exp>;
  _or?: Maybe<Array<HActivity_Bool_Exp>>;
  activityNaceCodes?: Maybe<HActivityNaceCode_Bool_Exp>;
  activityQuestionSets?: Maybe<HActivityQuestionSet_Bool_Exp>;
  activityReports?: Maybe<HActivityReport_Bool_Exp>;
  description?: Maybe<HString_Comparison_Exp>;
  name?: Maybe<HString_Comparison_Exp>;
  reference?: Maybe<HString_Comparison_Exp>;
  sector?: Maybe<HSector_Bool_Exp>;
  sectorRef?: Maybe<HString_Comparison_Exp>;
  tagExpression?: Maybe<HString_Comparison_Exp>;
};

/** order by max() on columns of table "Activity" */
export type HActivity_Max_Order_By = {
  description?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  reference?: Maybe<HOrder_By>;
  sectorRef?: Maybe<HOrder_By>;
  tagExpression?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "Activity" */
export type HActivity_Min_Order_By = {
  description?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  reference?: Maybe<HOrder_By>;
  sectorRef?: Maybe<HOrder_By>;
  tagExpression?: Maybe<HOrder_By>;
};

/** Ordering options when selecting data from "Activity". */
export type HActivity_Order_By = {
  BusinessUnitDistributionKeys_aggregate?: Maybe<HBusinessUnitDistributionKey_Aggregate_Order_By>;
  activityNaceCodes_aggregate?: Maybe<HActivityNaceCode_Aggregate_Order_By>;
  activityQuestionSets_aggregate?: Maybe<HActivityQuestionSet_Aggregate_Order_By>;
  activityReports_aggregate?: Maybe<HActivityReport_Aggregate_Order_By>;
  description?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  reference?: Maybe<HOrder_By>;
  sector?: Maybe<HSector_Order_By>;
  sectorRef?: Maybe<HOrder_By>;
  tagExpression?: Maybe<HOrder_By>;
};

/** select columns of table "Activity" */
export enum HActivity_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  Reference = 'reference',
  /** column name */
  SectorRef = 'sectorRef',
  /** column name */
  TagExpression = 'tagExpression'
}

/** columns and relationships of "Answer" */
export type HAnswer = {
  /** An object relationship */
  activityReport?: Maybe<HActivityReport>;
  /** An object relationship */
  answeredBy: HUsers;
  answeredById: Scalars['uuid'];
  /** An array relationship */
  attachments: Array<HAnswerAttachment>;
  createdAt: Scalars['timestamptz'];
  data?: Maybe<Scalars['jsonb']>;
  flagged?: Maybe<Scalars['Boolean']>;
  id: Scalars['uuid'];
  /** An array relationship */
  notes: Array<HAnswerNote>;
  /** An object relationship */
  question: HQuestion;
  questionId: Scalars['uuid'];
  reportId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  thread?: Maybe<HThread>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "Answer" */
export type HAnswerAttachmentsArgs = {
  distinct_on?: Maybe<Array<HAnswerAttachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswerAttachment_Order_By>>;
  where?: Maybe<HAnswerAttachment_Bool_Exp>;
};


/** columns and relationships of "Answer" */
export type HAnswerDataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Answer" */
export type HAnswerNotesArgs = {
  distinct_on?: Maybe<Array<HAnswerNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswerNote_Order_By>>;
  where?: Maybe<HAnswerNote_Bool_Exp>;
};

/** columns and relationships of "AnswerAttachment" */
export type HAnswerAttachment = {
  /** An object relationship */
  answer: HAnswer;
  answerId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  file: HFile;
  fileId: Scalars['uuid'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** order by aggregate values of table "AnswerAttachment" */
export type HAnswerAttachment_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HAnswerAttachment_Max_Order_By>;
  min?: Maybe<HAnswerAttachment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "AnswerAttachment" */
export type HAnswerAttachment_Arr_Rel_Insert_Input = {
  data: Array<HAnswerAttachment_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HAnswerAttachment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "AnswerAttachment". All fields are combined with a logical 'AND'. */
export type HAnswerAttachment_Bool_Exp = {
  _and?: Maybe<Array<HAnswerAttachment_Bool_Exp>>;
  _not?: Maybe<HAnswerAttachment_Bool_Exp>;
  _or?: Maybe<Array<HAnswerAttachment_Bool_Exp>>;
  answer?: Maybe<HAnswer_Bool_Exp>;
  answerId?: Maybe<HUuid_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  file?: Maybe<HFile_Bool_Exp>;
  fileId?: Maybe<HUuid_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "AnswerAttachment" */
export enum HAnswerAttachment_Constraint {
  /** unique or primary key constraint */
  AnswerAttachmentAnswerIdFileIdEe5f7802Uniq = 'AnswerAttachment_answerId_fileId_ee5f7802_uniq',
  /** unique or primary key constraint */
  AnswerAttachmentPkey = 'AnswerAttachment_pkey'
}

/** input type for inserting data into table "AnswerAttachment" */
export type HAnswerAttachment_Insert_Input = {
  answer?: Maybe<HAnswer_Obj_Rel_Insert_Input>;
  answerId?: Maybe<Scalars['uuid']>;
  file?: Maybe<HFile_Obj_Rel_Insert_Input>;
  fileId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "AnswerAttachment" */
export type HAnswerAttachment_Max_Order_By = {
  answerId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  fileId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "AnswerAttachment" */
export type HAnswerAttachment_Min_Order_By = {
  answerId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  fileId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "AnswerAttachment" */
export type HAnswerAttachment_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HAnswerAttachment>;
};

/** on conflict condition type for table "AnswerAttachment" */
export type HAnswerAttachment_On_Conflict = {
  constraint: HAnswerAttachment_Constraint;
  update_columns?: Array<HAnswerAttachment_Update_Column>;
  where?: Maybe<HAnswerAttachment_Bool_Exp>;
};

/** Ordering options when selecting data from "AnswerAttachment". */
export type HAnswerAttachment_Order_By = {
  answer?: Maybe<HAnswer_Order_By>;
  answerId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  file?: Maybe<HFile_Order_By>;
  fileId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** primary key columns input for table: AnswerAttachment */
export type HAnswerAttachment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "AnswerAttachment" */
export enum HAnswerAttachment_Select_Column {
  /** column name */
  AnswerId = 'answerId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  FileId = 'fileId',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "AnswerAttachment" */
export type HAnswerAttachment_Set_Input = {
  answerId?: Maybe<Scalars['uuid']>;
  fileId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "AnswerAttachment" */
export enum HAnswerAttachment_Update_Column {
  /** column name */
  AnswerId = 'answerId',
  /** column name */
  FileId = 'fileId'
}

/** columns and relationships of "AnswerNote" */
export type HAnswerNote = {
  /** An object relationship */
  answer: HAnswer;
  answerId: Scalars['uuid'];
  /** An object relationship */
  author: HUsers;
  authorId: Scalars['uuid'];
  body: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};

/** order by aggregate values of table "AnswerNote" */
export type HAnswerNote_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HAnswerNote_Max_Order_By>;
  min?: Maybe<HAnswerNote_Min_Order_By>;
};

/** input type for inserting array relation for remote table "AnswerNote" */
export type HAnswerNote_Arr_Rel_Insert_Input = {
  data: Array<HAnswerNote_Insert_Input>;
};

/** Boolean expression to filter rows from the table "AnswerNote". All fields are combined with a logical 'AND'. */
export type HAnswerNote_Bool_Exp = {
  _and?: Maybe<Array<HAnswerNote_Bool_Exp>>;
  _not?: Maybe<HAnswerNote_Bool_Exp>;
  _or?: Maybe<Array<HAnswerNote_Bool_Exp>>;
  answer?: Maybe<HAnswer_Bool_Exp>;
  answerId?: Maybe<HUuid_Comparison_Exp>;
  author?: Maybe<HUsers_Bool_Exp>;
  authorId?: Maybe<HUuid_Comparison_Exp>;
  body?: Maybe<HString_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "AnswerNote" */
export type HAnswerNote_Insert_Input = {
  answer?: Maybe<HAnswer_Obj_Rel_Insert_Input>;
  authorId?: Maybe<Scalars['uuid']>;
  body?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "AnswerNote" */
export type HAnswerNote_Max_Order_By = {
  answerId?: Maybe<HOrder_By>;
  authorId?: Maybe<HOrder_By>;
  body?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "AnswerNote" */
export type HAnswerNote_Min_Order_By = {
  answerId?: Maybe<HOrder_By>;
  authorId?: Maybe<HOrder_By>;
  body?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "AnswerNote" */
export type HAnswerNote_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HAnswerNote>;
};

/** Ordering options when selecting data from "AnswerNote". */
export type HAnswerNote_Order_By = {
  answer?: Maybe<HAnswer_Order_By>;
  answerId?: Maybe<HOrder_By>;
  author?: Maybe<HUsers_Order_By>;
  authorId?: Maybe<HOrder_By>;
  body?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** select columns of table "AnswerNote" */
export enum HAnswerNote_Select_Column {
  /** column name */
  AnswerId = 'answerId',
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** order by aggregate values of table "Answer" */
export type HAnswer_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HAnswer_Max_Order_By>;
  min?: Maybe<HAnswer_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HAnswer_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Answer" */
export type HAnswer_Arr_Rel_Insert_Input = {
  data: Array<HAnswer_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HAnswer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Answer". All fields are combined with a logical 'AND'. */
export type HAnswer_Bool_Exp = {
  _and?: Maybe<Array<HAnswer_Bool_Exp>>;
  _not?: Maybe<HAnswer_Bool_Exp>;
  _or?: Maybe<Array<HAnswer_Bool_Exp>>;
  activityReport?: Maybe<HActivityReport_Bool_Exp>;
  answeredBy?: Maybe<HUsers_Bool_Exp>;
  answeredById?: Maybe<HUuid_Comparison_Exp>;
  attachments?: Maybe<HAnswerAttachment_Bool_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  data?: Maybe<HJsonb_Comparison_Exp>;
  flagged?: Maybe<HBoolean_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  notes?: Maybe<HAnswerNote_Bool_Exp>;
  question?: Maybe<HQuestion_Bool_Exp>;
  questionId?: Maybe<HUuid_Comparison_Exp>;
  reportId?: Maybe<HUuid_Comparison_Exp>;
  thread?: Maybe<HThread_Bool_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Answer" */
export enum HAnswer_Constraint {
  /** unique or primary key constraint */
  AnswerQuestionIdReportId_22331f7fUniq = 'Answer_questionId_reportId_22331f7f_uniq',
  /** unique or primary key constraint */
  AnswerPkey = 'answer_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HAnswer_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HAnswer_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HAnswer_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "Answer" */
export type HAnswer_Insert_Input = {
  activityReport?: Maybe<HActivityReport_Obj_Rel_Insert_Input>;
  answeredById?: Maybe<Scalars['uuid']>;
  attachments?: Maybe<HAnswerAttachment_Arr_Rel_Insert_Input>;
  data?: Maybe<Scalars['jsonb']>;
  flagged?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<HAnswerNote_Arr_Rel_Insert_Input>;
  questionId?: Maybe<Scalars['uuid']>;
  reportId?: Maybe<Scalars['uuid']>;
  thread?: Maybe<HThread_Obj_Rel_Insert_Input>;
};

/** order by max() on columns of table "Answer" */
export type HAnswer_Max_Order_By = {
  answeredById?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  questionId?: Maybe<HOrder_By>;
  reportId?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "Answer" */
export type HAnswer_Min_Order_By = {
  answeredById?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  questionId?: Maybe<HOrder_By>;
  reportId?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "Answer" */
export type HAnswer_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HAnswer>;
};

/** input type for inserting object relation for remote table "Answer" */
export type HAnswer_Obj_Rel_Insert_Input = {
  data: HAnswer_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HAnswer_On_Conflict>;
};

/** on conflict condition type for table "Answer" */
export type HAnswer_On_Conflict = {
  constraint: HAnswer_Constraint;
  update_columns?: Array<HAnswer_Update_Column>;
  where?: Maybe<HAnswer_Bool_Exp>;
};

/** Ordering options when selecting data from "Answer". */
export type HAnswer_Order_By = {
  activityReport?: Maybe<HActivityReport_Order_By>;
  answeredBy?: Maybe<HUsers_Order_By>;
  answeredById?: Maybe<HOrder_By>;
  attachments_aggregate?: Maybe<HAnswerAttachment_Aggregate_Order_By>;
  createdAt?: Maybe<HOrder_By>;
  data?: Maybe<HOrder_By>;
  flagged?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  notes_aggregate?: Maybe<HAnswerNote_Aggregate_Order_By>;
  question?: Maybe<HQuestion_Order_By>;
  questionId?: Maybe<HOrder_By>;
  reportId?: Maybe<HOrder_By>;
  thread?: Maybe<HThread_Order_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** primary key columns input for table: Answer */
export type HAnswer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HAnswer_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Answer" */
export enum HAnswer_Select_Column {
  /** column name */
  AnsweredById = 'answeredById',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Flagged = 'flagged',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionId = 'questionId',
  /** column name */
  ReportId = 'reportId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "Answer" */
export type HAnswer_Set_Input = {
  answeredById?: Maybe<Scalars['uuid']>;
  data?: Maybe<Scalars['jsonb']>;
  flagged?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "Answer" */
export enum HAnswer_Update_Column {
  /** column name */
  AnsweredById = 'answeredById',
  /** column name */
  Data = 'data',
  /** column name */
  Flagged = 'flagged'
}

/** columns and relationships of "Article" */
export type HArticle = {
  /** An array relationship */
  Tag_articles: Array<HTag_Articles>;
  body: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  title: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "Article" */
export type HArticleTag_ArticlesArgs = {
  distinct_on?: Maybe<Array<HTag_Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HTag_Articles_Order_By>>;
  where?: Maybe<HTag_Articles_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "Article". All fields are combined with a logical 'AND'. */
export type HArticle_Bool_Exp = {
  Tag_articles?: Maybe<HTag_Articles_Bool_Exp>;
  _and?: Maybe<Array<HArticle_Bool_Exp>>;
  _not?: Maybe<HArticle_Bool_Exp>;
  _or?: Maybe<Array<HArticle_Bool_Exp>>;
  body?: Maybe<HString_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  title?: Maybe<HString_Comparison_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "Article". */
export type HArticle_Order_By = {
  Tag_articles_aggregate?: Maybe<HTag_Articles_Aggregate_Order_By>;
  body?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  title?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** select columns of table "Article" */
export enum HArticle_Select_Column {
  /** column name */
  Body = 'body',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type HBoolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "BusinessUnit" */
export type HBusinessUnit = {
  /** An array relationship */
  bAssessments: Array<HBusinessUnitAssessment>;
  businessArea: Scalars['String'];
  /** An object relationship */
  company: HCompany;
  companyId: Scalars['uuid'];
  /** An object relationship */
  contactPerson: HUsers;
  contactPersonId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  /** An array relationship */
  distributionKeys: Array<HBusinessUnitDistributionKey>;
  id: Scalars['uuid'];
  isSupporting: Scalars['Boolean'];
  labels: Scalars['jsonb'];
  name: Scalars['String'];
  sectors: Scalars['jsonb'];
  /** An array relationship */
  supportedBy: Array<HBusinessUnitDistributionKey>;
  type: Scalars['String'];
};


/** columns and relationships of "BusinessUnit" */
export type HBusinessUnitBAssessmentsArgs = {
  distinct_on?: Maybe<Array<HBusinessUnitAssessment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnitAssessment_Order_By>>;
  where?: Maybe<HBusinessUnitAssessment_Bool_Exp>;
};


/** columns and relationships of "BusinessUnit" */
export type HBusinessUnitDistributionKeysArgs = {
  distinct_on?: Maybe<Array<HBusinessUnitDistributionKey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnitDistributionKey_Order_By>>;
  where?: Maybe<HBusinessUnitDistributionKey_Bool_Exp>;
};


/** columns and relationships of "BusinessUnit" */
export type HBusinessUnitLabelsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "BusinessUnit" */
export type HBusinessUnitSectorsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "BusinessUnit" */
export type HBusinessUnitSupportedByArgs = {
  distinct_on?: Maybe<Array<HBusinessUnitDistributionKey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnitDistributionKey_Order_By>>;
  where?: Maybe<HBusinessUnitDistributionKey_Bool_Exp>;
};

/** columns and relationships of "BusinessUnitAssessment" */
export type HBusinessUnitAssessment = {
  /** An array relationship */
  activityReports: Array<HActivityReport>;
  /** An object relationship */
  businessUnit?: Maybe<HBusinessUnit>;
  businessUnitId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  cAssessment: HCompanyAssessment;
  cAssessmentId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  hasGeneralAssessment: Scalars['Boolean'];
  id: Scalars['uuid'];
};


/** columns and relationships of "BusinessUnitAssessment" */
export type HBusinessUnitAssessmentActivityReportsArgs = {
  distinct_on?: Maybe<Array<HActivityReport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityReport_Order_By>>;
  where?: Maybe<HActivityReport_Bool_Exp>;
};

/** order by aggregate values of table "BusinessUnitAssessment" */
export type HBusinessUnitAssessment_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HBusinessUnitAssessment_Max_Order_By>;
  min?: Maybe<HBusinessUnitAssessment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "BusinessUnitAssessment" */
export type HBusinessUnitAssessment_Arr_Rel_Insert_Input = {
  data: Array<HBusinessUnitAssessment_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HBusinessUnitAssessment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "BusinessUnitAssessment". All fields are combined with a logical 'AND'. */
export type HBusinessUnitAssessment_Bool_Exp = {
  _and?: Maybe<Array<HBusinessUnitAssessment_Bool_Exp>>;
  _not?: Maybe<HBusinessUnitAssessment_Bool_Exp>;
  _or?: Maybe<Array<HBusinessUnitAssessment_Bool_Exp>>;
  activityReports?: Maybe<HActivityReport_Bool_Exp>;
  businessUnit?: Maybe<HBusinessUnit_Bool_Exp>;
  businessUnitId?: Maybe<HUuid_Comparison_Exp>;
  cAssessment?: Maybe<HCompanyAssessment_Bool_Exp>;
  cAssessmentId?: Maybe<HUuid_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  deletedAt?: Maybe<HTimestamptz_Comparison_Exp>;
  hasGeneralAssessment?: Maybe<HBoolean_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "BusinessUnitAssessment" */
export enum HBusinessUnitAssessment_Constraint {
  /** unique or primary key constraint */
  BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U = 'BusinessUnitAssessment_cAssessmentId_businessUnitIdN_8db61b88_u',
  /** unique or primary key constraint */
  BusinessUnitAssessmentPkey = 'BusinessUnitAssessment_pkey'
}

/** input type for inserting data into table "BusinessUnitAssessment" */
export type HBusinessUnitAssessment_Insert_Input = {
  activityReports?: Maybe<HActivityReport_Arr_Rel_Insert_Input>;
  businessUnit?: Maybe<HBusinessUnit_Obj_Rel_Insert_Input>;
  businessUnitId?: Maybe<Scalars['uuid']>;
  cAssessment?: Maybe<HCompanyAssessment_Obj_Rel_Insert_Input>;
  cAssessmentId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  hasGeneralAssessment?: Maybe<Scalars['Boolean']>;
};

/** order by max() on columns of table "BusinessUnitAssessment" */
export type HBusinessUnitAssessment_Max_Order_By = {
  businessUnitId?: Maybe<HOrder_By>;
  cAssessmentId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "BusinessUnitAssessment" */
export type HBusinessUnitAssessment_Min_Order_By = {
  businessUnitId?: Maybe<HOrder_By>;
  cAssessmentId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "BusinessUnitAssessment" */
export type HBusinessUnitAssessment_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HBusinessUnitAssessment>;
};

/** input type for inserting object relation for remote table "BusinessUnitAssessment" */
export type HBusinessUnitAssessment_Obj_Rel_Insert_Input = {
  data: HBusinessUnitAssessment_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HBusinessUnitAssessment_On_Conflict>;
};

/** on conflict condition type for table "BusinessUnitAssessment" */
export type HBusinessUnitAssessment_On_Conflict = {
  constraint: HBusinessUnitAssessment_Constraint;
  update_columns?: Array<HBusinessUnitAssessment_Update_Column>;
  where?: Maybe<HBusinessUnitAssessment_Bool_Exp>;
};

/** Ordering options when selecting data from "BusinessUnitAssessment". */
export type HBusinessUnitAssessment_Order_By = {
  activityReports_aggregate?: Maybe<HActivityReport_Aggregate_Order_By>;
  businessUnit?: Maybe<HBusinessUnit_Order_By>;
  businessUnitId?: Maybe<HOrder_By>;
  cAssessment?: Maybe<HCompanyAssessment_Order_By>;
  cAssessmentId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  hasGeneralAssessment?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
};

/** primary key columns input for table: BusinessUnitAssessment */
export type HBusinessUnitAssessment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "BusinessUnitAssessment" */
export enum HBusinessUnitAssessment_Select_Column {
  /** column name */
  BusinessUnitId = 'businessUnitId',
  /** column name */
  CAssessmentId = 'cAssessmentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  HasGeneralAssessment = 'hasGeneralAssessment',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "BusinessUnitAssessment" */
export type HBusinessUnitAssessment_Set_Input = {
  cAssessmentId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  hasGeneralAssessment?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "BusinessUnitAssessment" */
export enum HBusinessUnitAssessment_Update_Column {
  /** column name */
  CAssessmentId = 'cAssessmentId',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  HasGeneralAssessment = 'hasGeneralAssessment'
}

/** columns and relationships of "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey = {
  /** An object relationship */
  activity: HActivity;
  activityRef: Scalars['String'];
  capexPercentage: Scalars['float8'];
  id: Scalars['uuid'];
  opexPercentage: Scalars['float8'];
  /** An object relationship */
  receivingBusinessUnit: HBusinessUnit;
  receivingBusinessUnitId: Scalars['uuid'];
  revenuePercentage: Scalars['float8'];
  /** An object relationship */
  supportingBusinessUnit: HBusinessUnit;
  supportingBusinessUnitId: Scalars['uuid'];
};

/** order by aggregate values of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Aggregate_Order_By = {
  avg?: Maybe<HBusinessUnitDistributionKey_Avg_Order_By>;
  count?: Maybe<HOrder_By>;
  max?: Maybe<HBusinessUnitDistributionKey_Max_Order_By>;
  min?: Maybe<HBusinessUnitDistributionKey_Min_Order_By>;
  stddev?: Maybe<HBusinessUnitDistributionKey_Stddev_Order_By>;
  stddev_pop?: Maybe<HBusinessUnitDistributionKey_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<HBusinessUnitDistributionKey_Stddev_Samp_Order_By>;
  sum?: Maybe<HBusinessUnitDistributionKey_Sum_Order_By>;
  var_pop?: Maybe<HBusinessUnitDistributionKey_Var_Pop_Order_By>;
  var_samp?: Maybe<HBusinessUnitDistributionKey_Var_Samp_Order_By>;
  variance?: Maybe<HBusinessUnitDistributionKey_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Arr_Rel_Insert_Input = {
  data: Array<HBusinessUnitDistributionKey_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HBusinessUnitDistributionKey_On_Conflict>;
};

/** order by avg() on columns of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Avg_Order_By = {
  capexPercentage?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
};

/** Boolean expression to filter rows from the table "BusinessUnitDistributionKey". All fields are combined with a logical 'AND'. */
export type HBusinessUnitDistributionKey_Bool_Exp = {
  _and?: Maybe<Array<HBusinessUnitDistributionKey_Bool_Exp>>;
  _not?: Maybe<HBusinessUnitDistributionKey_Bool_Exp>;
  _or?: Maybe<Array<HBusinessUnitDistributionKey_Bool_Exp>>;
  activity?: Maybe<HActivity_Bool_Exp>;
  activityRef?: Maybe<HString_Comparison_Exp>;
  capexPercentage?: Maybe<HFloat8_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  opexPercentage?: Maybe<HFloat8_Comparison_Exp>;
  receivingBusinessUnit?: Maybe<HBusinessUnit_Bool_Exp>;
  receivingBusinessUnitId?: Maybe<HUuid_Comparison_Exp>;
  revenuePercentage?: Maybe<HFloat8_Comparison_Exp>;
  supportingBusinessUnit?: Maybe<HBusinessUnit_Bool_Exp>;
  supportingBusinessUnitId?: Maybe<HUuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "BusinessUnitDistributionKey" */
export enum HBusinessUnitDistributionKey_Constraint {
  /** unique or primary key constraint */
  BusinessUnitDistributionSupportingBusinessUnitId_66084b72Uniq = 'BusinessUnitDistribution_supportingBusinessUnitId_66084b72_uniq',
  /** unique or primary key constraint */
  SupportedBusinessUnitsPkey = 'SupportedBusinessUnits_pkey'
}

/** input type for incrementing numeric columns in table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Inc_Input = {
  capexPercentage?: Maybe<Scalars['float8']>;
  opexPercentage?: Maybe<Scalars['float8']>;
  revenuePercentage?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Insert_Input = {
  activityRef?: Maybe<Scalars['String']>;
  capexPercentage?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  opexPercentage?: Maybe<Scalars['float8']>;
  receivingBusinessUnit?: Maybe<HBusinessUnit_Obj_Rel_Insert_Input>;
  receivingBusinessUnitId?: Maybe<Scalars['uuid']>;
  revenuePercentage?: Maybe<Scalars['float8']>;
  supportingBusinessUnit?: Maybe<HBusinessUnit_Obj_Rel_Insert_Input>;
  supportingBusinessUnitId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Max_Order_By = {
  activityRef?: Maybe<HOrder_By>;
  capexPercentage?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  receivingBusinessUnitId?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
  supportingBusinessUnitId?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Min_Order_By = {
  activityRef?: Maybe<HOrder_By>;
  capexPercentage?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  receivingBusinessUnitId?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
  supportingBusinessUnitId?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HBusinessUnitDistributionKey>;
};

/** on conflict condition type for table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_On_Conflict = {
  constraint: HBusinessUnitDistributionKey_Constraint;
  update_columns?: Array<HBusinessUnitDistributionKey_Update_Column>;
  where?: Maybe<HBusinessUnitDistributionKey_Bool_Exp>;
};

/** Ordering options when selecting data from "BusinessUnitDistributionKey". */
export type HBusinessUnitDistributionKey_Order_By = {
  activity?: Maybe<HActivity_Order_By>;
  activityRef?: Maybe<HOrder_By>;
  capexPercentage?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  receivingBusinessUnit?: Maybe<HBusinessUnit_Order_By>;
  receivingBusinessUnitId?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
  supportingBusinessUnit?: Maybe<HBusinessUnit_Order_By>;
  supportingBusinessUnitId?: Maybe<HOrder_By>;
};

/** primary key columns input for table: BusinessUnitDistributionKey */
export type HBusinessUnitDistributionKey_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "BusinessUnitDistributionKey" */
export enum HBusinessUnitDistributionKey_Select_Column {
  /** column name */
  ActivityRef = 'activityRef',
  /** column name */
  CapexPercentage = 'capexPercentage',
  /** column name */
  Id = 'id',
  /** column name */
  OpexPercentage = 'opexPercentage',
  /** column name */
  ReceivingBusinessUnitId = 'receivingBusinessUnitId',
  /** column name */
  RevenuePercentage = 'revenuePercentage',
  /** column name */
  SupportingBusinessUnitId = 'supportingBusinessUnitId'
}

/** input type for updating data in table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Set_Input = {
  activityRef?: Maybe<Scalars['String']>;
  capexPercentage?: Maybe<Scalars['float8']>;
  opexPercentage?: Maybe<Scalars['float8']>;
  receivingBusinessUnitId?: Maybe<Scalars['uuid']>;
  revenuePercentage?: Maybe<Scalars['float8']>;
  supportingBusinessUnitId?: Maybe<Scalars['uuid']>;
};

/** order by stddev() on columns of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Stddev_Order_By = {
  capexPercentage?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
};

/** order by stddev_pop() on columns of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Stddev_Pop_Order_By = {
  capexPercentage?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
};

/** order by stddev_samp() on columns of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Stddev_Samp_Order_By = {
  capexPercentage?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
};

/** order by sum() on columns of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Sum_Order_By = {
  capexPercentage?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
};

/** update columns of table "BusinessUnitDistributionKey" */
export enum HBusinessUnitDistributionKey_Update_Column {
  /** column name */
  ActivityRef = 'activityRef',
  /** column name */
  CapexPercentage = 'capexPercentage',
  /** column name */
  OpexPercentage = 'opexPercentage',
  /** column name */
  ReceivingBusinessUnitId = 'receivingBusinessUnitId',
  /** column name */
  RevenuePercentage = 'revenuePercentage',
  /** column name */
  SupportingBusinessUnitId = 'supportingBusinessUnitId'
}

/** order by var_pop() on columns of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Var_Pop_Order_By = {
  capexPercentage?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
};

/** order by var_samp() on columns of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Var_Samp_Order_By = {
  capexPercentage?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
};

/** order by variance() on columns of table "BusinessUnitDistributionKey" */
export type HBusinessUnitDistributionKey_Variance_Order_By = {
  capexPercentage?: Maybe<HOrder_By>;
  opexPercentage?: Maybe<HOrder_By>;
  revenuePercentage?: Maybe<HOrder_By>;
};

/** order by aggregate values of table "BusinessUnit" */
export type HBusinessUnit_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HBusinessUnit_Max_Order_By>;
  min?: Maybe<HBusinessUnit_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HBusinessUnit_Append_Input = {
  labels?: Maybe<Scalars['jsonb']>;
  sectors?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "BusinessUnit" */
export type HBusinessUnit_Arr_Rel_Insert_Input = {
  data: Array<HBusinessUnit_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HBusinessUnit_On_Conflict>;
};

/** Boolean expression to filter rows from the table "BusinessUnit". All fields are combined with a logical 'AND'. */
export type HBusinessUnit_Bool_Exp = {
  _and?: Maybe<Array<HBusinessUnit_Bool_Exp>>;
  _not?: Maybe<HBusinessUnit_Bool_Exp>;
  _or?: Maybe<Array<HBusinessUnit_Bool_Exp>>;
  bAssessments?: Maybe<HBusinessUnitAssessment_Bool_Exp>;
  businessArea?: Maybe<HString_Comparison_Exp>;
  company?: Maybe<HCompany_Bool_Exp>;
  companyId?: Maybe<HUuid_Comparison_Exp>;
  contactPerson?: Maybe<HUsers_Bool_Exp>;
  contactPersonId?: Maybe<HUuid_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  deletedAt?: Maybe<HTimestamptz_Comparison_Exp>;
  description?: Maybe<HString_Comparison_Exp>;
  distributionKeys?: Maybe<HBusinessUnitDistributionKey_Bool_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  isSupporting?: Maybe<HBoolean_Comparison_Exp>;
  labels?: Maybe<HJsonb_Comparison_Exp>;
  name?: Maybe<HString_Comparison_Exp>;
  sectors?: Maybe<HJsonb_Comparison_Exp>;
  supportedBy?: Maybe<HBusinessUnitDistributionKey_Bool_Exp>;
  type?: Maybe<HString_Comparison_Exp>;
};

/** unique or primary key constraints on table "BusinessUnit" */
export enum HBusinessUnit_Constraint {
  /** unique or primary key constraint */
  BusinessUnitPkey = 'businessUnit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HBusinessUnit_Delete_At_Path_Input = {
  labels?: Maybe<Array<Scalars['String']>>;
  sectors?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HBusinessUnit_Delete_Elem_Input = {
  labels?: Maybe<Scalars['Int']>;
  sectors?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HBusinessUnit_Delete_Key_Input = {
  labels?: Maybe<Scalars['String']>;
  sectors?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "BusinessUnit" */
export type HBusinessUnit_Insert_Input = {
  bAssessments?: Maybe<HBusinessUnitAssessment_Arr_Rel_Insert_Input>;
  businessArea?: Maybe<Scalars['String']>;
  company?: Maybe<HCompany_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  contactPersonId?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  distributionKeys?: Maybe<HBusinessUnitDistributionKey_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  isSupporting?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  sectors?: Maybe<Scalars['jsonb']>;
  supportedBy?: Maybe<HBusinessUnitDistributionKey_Arr_Rel_Insert_Input>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "BusinessUnit" */
export type HBusinessUnit_Max_Order_By = {
  businessArea?: Maybe<HOrder_By>;
  companyId?: Maybe<HOrder_By>;
  contactPersonId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  description?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  type?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "BusinessUnit" */
export type HBusinessUnit_Min_Order_By = {
  businessArea?: Maybe<HOrder_By>;
  companyId?: Maybe<HOrder_By>;
  contactPersonId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  description?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  type?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "BusinessUnit" */
export type HBusinessUnit_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HBusinessUnit>;
};

/** input type for inserting object relation for remote table "BusinessUnit" */
export type HBusinessUnit_Obj_Rel_Insert_Input = {
  data: HBusinessUnit_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HBusinessUnit_On_Conflict>;
};

/** on conflict condition type for table "BusinessUnit" */
export type HBusinessUnit_On_Conflict = {
  constraint: HBusinessUnit_Constraint;
  update_columns?: Array<HBusinessUnit_Update_Column>;
  where?: Maybe<HBusinessUnit_Bool_Exp>;
};

/** Ordering options when selecting data from "BusinessUnit". */
export type HBusinessUnit_Order_By = {
  bAssessments_aggregate?: Maybe<HBusinessUnitAssessment_Aggregate_Order_By>;
  businessArea?: Maybe<HOrder_By>;
  company?: Maybe<HCompany_Order_By>;
  companyId?: Maybe<HOrder_By>;
  contactPerson?: Maybe<HUsers_Order_By>;
  contactPersonId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  description?: Maybe<HOrder_By>;
  distributionKeys_aggregate?: Maybe<HBusinessUnitDistributionKey_Aggregate_Order_By>;
  id?: Maybe<HOrder_By>;
  isSupporting?: Maybe<HOrder_By>;
  labels?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  sectors?: Maybe<HOrder_By>;
  supportedBy_aggregate?: Maybe<HBusinessUnitDistributionKey_Aggregate_Order_By>;
  type?: Maybe<HOrder_By>;
};

/** primary key columns input for table: BusinessUnit */
export type HBusinessUnit_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HBusinessUnit_Prepend_Input = {
  labels?: Maybe<Scalars['jsonb']>;
  sectors?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "BusinessUnit" */
export enum HBusinessUnit_Select_Column {
  /** column name */
  BusinessArea = 'businessArea',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  ContactPersonId = 'contactPersonId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsSupporting = 'isSupporting',
  /** column name */
  Labels = 'labels',
  /** column name */
  Name = 'name',
  /** column name */
  Sectors = 'sectors',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "BusinessUnit" */
export type HBusinessUnit_Set_Input = {
  businessArea?: Maybe<Scalars['String']>;
  contactPersonId?: Maybe<Scalars['uuid']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  isSupporting?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  sectors?: Maybe<Scalars['jsonb']>;
  type?: Maybe<Scalars['String']>;
};

/** update columns of table "BusinessUnit" */
export enum HBusinessUnit_Update_Column {
  /** column name */
  BusinessArea = 'businessArea',
  /** column name */
  ContactPersonId = 'contactPersonId',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  IsSupporting = 'isSupporting',
  /** column name */
  Labels = 'labels',
  /** column name */
  Name = 'name',
  /** column name */
  Sectors = 'sectors',
  /** column name */
  Type = 'type'
}

/** columns and relationships of "Comment" */
export type HComment = {
  /** An object relationship */
  author: HUsers;
  authorId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  data: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  notifications: Array<HUserNotification>;
  /** An aggregate relationship */
  notifications_aggregate: HUserNotification_Aggregate;
  /** An object relationship */
  thread: HThread;
  threadId: Scalars['uuid'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "Comment" */
export type HCommentNotificationsArgs = {
  distinct_on?: Maybe<Array<HUserNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HUserNotification_Order_By>>;
  where?: Maybe<HUserNotification_Bool_Exp>;
};


/** columns and relationships of "Comment" */
export type HCommentNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<HUserNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HUserNotification_Order_By>>;
  where?: Maybe<HUserNotification_Bool_Exp>;
};

/** aggregated selection of "Comment" */
export type HComment_Aggregate = {
  aggregate?: Maybe<HComment_Aggregate_Fields>;
  nodes: Array<HComment>;
};

/** aggregate fields of "Comment" */
export type HComment_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<HComment_Max_Fields>;
  min?: Maybe<HComment_Min_Fields>;
};


/** aggregate fields of "Comment" */
export type HComment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<HComment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Comment" */
export type HComment_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HComment_Max_Order_By>;
  min?: Maybe<HComment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Comment" */
export type HComment_Arr_Rel_Insert_Input = {
  data: Array<HComment_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HComment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Comment". All fields are combined with a logical 'AND'. */
export type HComment_Bool_Exp = {
  _and?: Maybe<Array<HComment_Bool_Exp>>;
  _not?: Maybe<HComment_Bool_Exp>;
  _or?: Maybe<Array<HComment_Bool_Exp>>;
  author?: Maybe<HUsers_Bool_Exp>;
  authorId?: Maybe<HUuid_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  data?: Maybe<HString_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  notifications?: Maybe<HUserNotification_Bool_Exp>;
  thread?: Maybe<HThread_Bool_Exp>;
  threadId?: Maybe<HUuid_Comparison_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Comment" */
export enum HComment_Constraint {
  /** unique or primary key constraint */
  CommentPkey = 'Comment_pkey'
}

/** input type for inserting data into table "Comment" */
export type HComment_Insert_Input = {
  data?: Maybe<Scalars['String']>;
  notifications?: Maybe<HUserNotification_Arr_Rel_Insert_Input>;
  thread?: Maybe<HThread_Obj_Rel_Insert_Input>;
  threadId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type HComment_Max_Fields = {
  authorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  threadId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Comment" */
export type HComment_Max_Order_By = {
  authorId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  data?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  threadId?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** aggregate min on columns */
export type HComment_Min_Fields = {
  authorId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  data?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  threadId?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Comment" */
export type HComment_Min_Order_By = {
  authorId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  data?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  threadId?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "Comment" */
export type HComment_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HComment>;
};

/** input type for inserting object relation for remote table "Comment" */
export type HComment_Obj_Rel_Insert_Input = {
  data: HComment_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HComment_On_Conflict>;
};

/** on conflict condition type for table "Comment" */
export type HComment_On_Conflict = {
  constraint: HComment_Constraint;
  update_columns?: Array<HComment_Update_Column>;
  where?: Maybe<HComment_Bool_Exp>;
};

/** Ordering options when selecting data from "Comment". */
export type HComment_Order_By = {
  author?: Maybe<HUsers_Order_By>;
  authorId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  data?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  notifications_aggregate?: Maybe<HUserNotification_Aggregate_Order_By>;
  thread?: Maybe<HThread_Order_By>;
  threadId?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** primary key columns input for table: Comment */
export type HComment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Comment" */
export enum HComment_Select_Column {
  /** column name */
  AuthorId = 'authorId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  ThreadId = 'threadId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "Comment" */
export type HComment_Set_Input = {
  data?: Maybe<Scalars['String']>;
};

/** update columns of table "Comment" */
export enum HComment_Update_Column {
  /** column name */
  Data = 'data'
}

/** columns and relationships of "Company" */
export type HCompany = {
  /** An array relationship */
  Threads: Array<HThread>;
  /** An array relationship */
  businessUnits: Array<HBusinessUnit>;
  /** An array relationship */
  cAssessmentAggregates: Array<HCompanyAssessmentAggregate>;
  /** An array relationship */
  cAssessments: Array<HCompanyAssessment>;
  currency: Scalars['String'];
  /** An array relationship */
  files: Array<HFile>;
  id: Scalars['uuid'];
  industries: Scalars['jsonb'];
  /** An array relationship */
  invitations: Array<HCompanyInvitation>;
  isOnTrial: Scalars['Boolean'];
  isPortfolioOwner: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  numberOfEmployees?: Maybe<Scalars['Int']>;
  orgId?: Maybe<Scalars['String']>;
  /** An array relationship */
  portfolioCompanies: Array<HPortfolioCompany>;
  /** An array relationship */
  portfolios: Array<HPortfolio>;
  settings: Scalars['jsonb'];
  trialEndDate: Scalars['date'];
  /** An array relationship */
  users: Array<HCompanyUser>;
};


/** columns and relationships of "Company" */
export type HCompanyThreadsArgs = {
  distinct_on?: Maybe<Array<HThread_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HThread_Order_By>>;
  where?: Maybe<HThread_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type HCompanyBusinessUnitsArgs = {
  distinct_on?: Maybe<Array<HBusinessUnit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnit_Order_By>>;
  where?: Maybe<HBusinessUnit_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type HCompanyCAssessmentAggregatesArgs = {
  distinct_on?: Maybe<Array<HCompanyAssessmentAggregate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyAssessmentAggregate_Order_By>>;
  where?: Maybe<HCompanyAssessmentAggregate_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type HCompanyCAssessmentsArgs = {
  distinct_on?: Maybe<Array<HCompanyAssessment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyAssessment_Order_By>>;
  where?: Maybe<HCompanyAssessment_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type HCompanyFilesArgs = {
  distinct_on?: Maybe<Array<HFile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HFile_Order_By>>;
  where?: Maybe<HFile_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type HCompanyIndustriesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Company" */
export type HCompanyInvitationsArgs = {
  distinct_on?: Maybe<Array<HCompanyInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyInvitation_Order_By>>;
  where?: Maybe<HCompanyInvitation_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type HCompanyPortfolioCompaniesArgs = {
  distinct_on?: Maybe<Array<HPortfolioCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HPortfolioCompany_Order_By>>;
  where?: Maybe<HPortfolioCompany_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type HCompanyPortfoliosArgs = {
  distinct_on?: Maybe<Array<HPortfolio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HPortfolio_Order_By>>;
  where?: Maybe<HPortfolio_Bool_Exp>;
};


/** columns and relationships of "Company" */
export type HCompanySettingsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Company" */
export type HCompanyUsersArgs = {
  distinct_on?: Maybe<Array<HCompanyUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyUser_Order_By>>;
  where?: Maybe<HCompanyUser_Bool_Exp>;
};

/** columns and relationships of "CompanyAssessment" */
export type HCompanyAssessment = {
  /** An object relationship */
  aggregate: HCompanyAssessmentAggregate;
  aggregateId: Scalars['uuid'];
  /** An array relationship */
  bAssesssments: Array<HBusinessUnitAssessment>;
  /** An object relationship */
  company: HCompany;
  companyId: Scalars['uuid'];
  completedAt?: Maybe<Scalars['timestamptz']>;
  createdAt: Scalars['timestamptz'];
  deletedAt?: Maybe<Scalars['timestamptz']>;
  endDate: Scalars['date'];
  id: Scalars['uuid'];
  startDate: Scalars['date'];
};


/** columns and relationships of "CompanyAssessment" */
export type HCompanyAssessmentBAssesssmentsArgs = {
  distinct_on?: Maybe<Array<HBusinessUnitAssessment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnitAssessment_Order_By>>;
  where?: Maybe<HBusinessUnitAssessment_Bool_Exp>;
};

/** columns and relationships of "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregate = {
  /** An array relationship */
  cAssessments: Array<HCompanyAssessment>;
  /** An object relationship */
  company: HCompany;
  companyId: Scalars['uuid'];
  /** An object relationship */
  contactPerson?: Maybe<HUsers>;
  contactPersonId?: Maybe<Scalars['uuid']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  interval: Scalars['String'];
  startDate: Scalars['date'];
  title: Scalars['String'];
};


/** columns and relationships of "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregateCAssessmentsArgs = {
  distinct_on?: Maybe<Array<HCompanyAssessment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyAssessment_Order_By>>;
  where?: Maybe<HCompanyAssessment_Bool_Exp>;
};

/** order by aggregate values of table "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregate_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HCompanyAssessmentAggregate_Max_Order_By>;
  min?: Maybe<HCompanyAssessmentAggregate_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregate_Arr_Rel_Insert_Input = {
  data: Array<HCompanyAssessmentAggregate_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HCompanyAssessmentAggregate_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CompanyAssessmentAggregate". All fields are combined with a logical 'AND'. */
export type HCompanyAssessmentAggregate_Bool_Exp = {
  _and?: Maybe<Array<HCompanyAssessmentAggregate_Bool_Exp>>;
  _not?: Maybe<HCompanyAssessmentAggregate_Bool_Exp>;
  _or?: Maybe<Array<HCompanyAssessmentAggregate_Bool_Exp>>;
  cAssessments?: Maybe<HCompanyAssessment_Bool_Exp>;
  company?: Maybe<HCompany_Bool_Exp>;
  companyId?: Maybe<HUuid_Comparison_Exp>;
  contactPerson?: Maybe<HUsers_Bool_Exp>;
  contactPersonId?: Maybe<HUuid_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  interval?: Maybe<HString_Comparison_Exp>;
  startDate?: Maybe<HDate_Comparison_Exp>;
  title?: Maybe<HString_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompanyAssessmentAggregate" */
export enum HCompanyAssessmentAggregate_Constraint {
  /** unique or primary key constraint */
  CompanyAssessmentAggregatePkey = 'CompanyAssessmentAggregate_pkey'
}

/** input type for inserting data into table "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregate_Insert_Input = {
  cAssessments?: Maybe<HCompanyAssessment_Arr_Rel_Insert_Input>;
  company?: Maybe<HCompany_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  contactPersonId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  interval?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregate_Max_Order_By = {
  companyId?: Maybe<HOrder_By>;
  contactPersonId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  interval?: Maybe<HOrder_By>;
  startDate?: Maybe<HOrder_By>;
  title?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregate_Min_Order_By = {
  companyId?: Maybe<HOrder_By>;
  contactPersonId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  interval?: Maybe<HOrder_By>;
  startDate?: Maybe<HOrder_By>;
  title?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregate_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HCompanyAssessmentAggregate>;
};

/** input type for inserting object relation for remote table "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregate_Obj_Rel_Insert_Input = {
  data: HCompanyAssessmentAggregate_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HCompanyAssessmentAggregate_On_Conflict>;
};

/** on conflict condition type for table "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregate_On_Conflict = {
  constraint: HCompanyAssessmentAggregate_Constraint;
  update_columns?: Array<HCompanyAssessmentAggregate_Update_Column>;
  where?: Maybe<HCompanyAssessmentAggregate_Bool_Exp>;
};

/** Ordering options when selecting data from "CompanyAssessmentAggregate". */
export type HCompanyAssessmentAggregate_Order_By = {
  cAssessments_aggregate?: Maybe<HCompanyAssessment_Aggregate_Order_By>;
  company?: Maybe<HCompany_Order_By>;
  companyId?: Maybe<HOrder_By>;
  contactPerson?: Maybe<HUsers_Order_By>;
  contactPersonId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  interval?: Maybe<HOrder_By>;
  startDate?: Maybe<HOrder_By>;
  title?: Maybe<HOrder_By>;
};

/** primary key columns input for table: CompanyAssessmentAggregate */
export type HCompanyAssessmentAggregate_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CompanyAssessmentAggregate" */
export enum HCompanyAssessmentAggregate_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  ContactPersonId = 'contactPersonId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Interval = 'interval',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Title = 'title'
}

/** input type for updating data in table "CompanyAssessmentAggregate" */
export type HCompanyAssessmentAggregate_Set_Input = {
  contactPersonId?: Maybe<Scalars['uuid']>;
  interval?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['date']>;
  title?: Maybe<Scalars['String']>;
};

/** update columns of table "CompanyAssessmentAggregate" */
export enum HCompanyAssessmentAggregate_Update_Column {
  /** column name */
  ContactPersonId = 'contactPersonId',
  /** column name */
  Interval = 'interval',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Title = 'title'
}

/** order by aggregate values of table "CompanyAssessment" */
export type HCompanyAssessment_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HCompanyAssessment_Max_Order_By>;
  min?: Maybe<HCompanyAssessment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CompanyAssessment" */
export type HCompanyAssessment_Arr_Rel_Insert_Input = {
  data: Array<HCompanyAssessment_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HCompanyAssessment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CompanyAssessment". All fields are combined with a logical 'AND'. */
export type HCompanyAssessment_Bool_Exp = {
  _and?: Maybe<Array<HCompanyAssessment_Bool_Exp>>;
  _not?: Maybe<HCompanyAssessment_Bool_Exp>;
  _or?: Maybe<Array<HCompanyAssessment_Bool_Exp>>;
  aggregate?: Maybe<HCompanyAssessmentAggregate_Bool_Exp>;
  aggregateId?: Maybe<HUuid_Comparison_Exp>;
  bAssesssments?: Maybe<HBusinessUnitAssessment_Bool_Exp>;
  company?: Maybe<HCompany_Bool_Exp>;
  companyId?: Maybe<HUuid_Comparison_Exp>;
  completedAt?: Maybe<HTimestamptz_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  deletedAt?: Maybe<HTimestamptz_Comparison_Exp>;
  endDate?: Maybe<HDate_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  startDate?: Maybe<HDate_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompanyAssessment" */
export enum HCompanyAssessment_Constraint {
  /** unique or primary key constraint */
  CompanyAssessmentPkey = 'companyAssessment_pkey'
}

/** input type for inserting data into table "CompanyAssessment" */
export type HCompanyAssessment_Insert_Input = {
  aggregate?: Maybe<HCompanyAssessmentAggregate_Obj_Rel_Insert_Input>;
  aggregateId?: Maybe<Scalars['uuid']>;
  bAssesssments?: Maybe<HBusinessUnitAssessment_Arr_Rel_Insert_Input>;
  company?: Maybe<HCompany_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  completedAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  startDate?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "CompanyAssessment" */
export type HCompanyAssessment_Max_Order_By = {
  aggregateId?: Maybe<HOrder_By>;
  companyId?: Maybe<HOrder_By>;
  completedAt?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  endDate?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  startDate?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "CompanyAssessment" */
export type HCompanyAssessment_Min_Order_By = {
  aggregateId?: Maybe<HOrder_By>;
  companyId?: Maybe<HOrder_By>;
  completedAt?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  endDate?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  startDate?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "CompanyAssessment" */
export type HCompanyAssessment_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HCompanyAssessment>;
};

/** input type for inserting object relation for remote table "CompanyAssessment" */
export type HCompanyAssessment_Obj_Rel_Insert_Input = {
  data: HCompanyAssessment_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HCompanyAssessment_On_Conflict>;
};

/** on conflict condition type for table "CompanyAssessment" */
export type HCompanyAssessment_On_Conflict = {
  constraint: HCompanyAssessment_Constraint;
  update_columns?: Array<HCompanyAssessment_Update_Column>;
  where?: Maybe<HCompanyAssessment_Bool_Exp>;
};

/** Ordering options when selecting data from "CompanyAssessment". */
export type HCompanyAssessment_Order_By = {
  aggregate?: Maybe<HCompanyAssessmentAggregate_Order_By>;
  aggregateId?: Maybe<HOrder_By>;
  bAssesssments_aggregate?: Maybe<HBusinessUnitAssessment_Aggregate_Order_By>;
  company?: Maybe<HCompany_Order_By>;
  companyId?: Maybe<HOrder_By>;
  completedAt?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  deletedAt?: Maybe<HOrder_By>;
  endDate?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  startDate?: Maybe<HOrder_By>;
};

/** primary key columns input for table: CompanyAssessment */
export type HCompanyAssessment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CompanyAssessment" */
export enum HCompanyAssessment_Select_Column {
  /** column name */
  AggregateId = 'aggregateId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'startDate'
}

/** input type for updating data in table "CompanyAssessment" */
export type HCompanyAssessment_Set_Input = {
  completedAt?: Maybe<Scalars['timestamptz']>;
  deletedAt?: Maybe<Scalars['timestamptz']>;
  endDate?: Maybe<Scalars['date']>;
  startDate?: Maybe<Scalars['date']>;
};

/** update columns of table "CompanyAssessment" */
export enum HCompanyAssessment_Update_Column {
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  StartDate = 'startDate'
}

/** columns and relationships of "CompanyInvitation" */
export type HCompanyInvitation = {
  /** An object relationship */
  company: HCompany;
  companyId: Scalars['uuid'];
  id: Scalars['uuid'];
  status: Scalars['String'];
  /** An object relationship */
  user?: Maybe<HUsers>;
  userEmail: Scalars['String'];
};

/** order by aggregate values of table "CompanyInvitation" */
export type HCompanyInvitation_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HCompanyInvitation_Max_Order_By>;
  min?: Maybe<HCompanyInvitation_Min_Order_By>;
};

/** input type for inserting array relation for remote table "CompanyInvitation" */
export type HCompanyInvitation_Arr_Rel_Insert_Input = {
  data: Array<HCompanyInvitation_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HCompanyInvitation_On_Conflict>;
};

/** Boolean expression to filter rows from the table "CompanyInvitation". All fields are combined with a logical 'AND'. */
export type HCompanyInvitation_Bool_Exp = {
  _and?: Maybe<Array<HCompanyInvitation_Bool_Exp>>;
  _not?: Maybe<HCompanyInvitation_Bool_Exp>;
  _or?: Maybe<Array<HCompanyInvitation_Bool_Exp>>;
  company?: Maybe<HCompany_Bool_Exp>;
  companyId?: Maybe<HUuid_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  status?: Maybe<HString_Comparison_Exp>;
  user?: Maybe<HUsers_Bool_Exp>;
  userEmail?: Maybe<HString_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompanyInvitation" */
export enum HCompanyInvitation_Constraint {
  /** unique or primary key constraint */
  CompanyInvitationCompanyIdUserEmail_68f64bffUniq = 'CompanyInvitation_companyId_userEmail_68f64bff_uniq',
  /** unique or primary key constraint */
  CompanyInvitationPkey = 'CompanyInvitation_pkey'
}

/** input type for inserting data into table "CompanyInvitation" */
export type HCompanyInvitation_Insert_Input = {
  company?: Maybe<HCompany_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  inviteToChildCompanies?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "CompanyInvitation" */
export type HCompanyInvitation_Max_Order_By = {
  companyId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  status?: Maybe<HOrder_By>;
  userEmail?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "CompanyInvitation" */
export type HCompanyInvitation_Min_Order_By = {
  companyId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  status?: Maybe<HOrder_By>;
  userEmail?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "CompanyInvitation" */
export type HCompanyInvitation_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HCompanyInvitation>;
};

/** on conflict condition type for table "CompanyInvitation" */
export type HCompanyInvitation_On_Conflict = {
  constraint: HCompanyInvitation_Constraint;
  update_columns?: Array<HCompanyInvitation_Update_Column>;
  where?: Maybe<HCompanyInvitation_Bool_Exp>;
};

/** Ordering options when selecting data from "CompanyInvitation". */
export type HCompanyInvitation_Order_By = {
  company?: Maybe<HCompany_Order_By>;
  companyId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  status?: Maybe<HOrder_By>;
  user?: Maybe<HUsers_Order_By>;
  userEmail?: Maybe<HOrder_By>;
};

/** primary key columns input for table: CompanyInvitation */
export type HCompanyInvitation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CompanyInvitation" */
export enum HCompanyInvitation_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UserEmail = 'userEmail'
}

/** input type for updating data in table "CompanyInvitation" */
export type HCompanyInvitation_Set_Input = {
  companyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  inviteToChildCompanies?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  userEmail?: Maybe<Scalars['String']>;
};

/** update columns of table "CompanyInvitation" */
export enum HCompanyInvitation_Update_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  InviteToChildCompanies = 'inviteToChildCompanies',
  /** column name */
  Status = 'status',
  /** column name */
  UserEmail = 'userEmail'
}

/** columns and relationships of "CompanyUser" */
export type HCompanyUser = {
  /** An object relationship */
  company: HCompany;
  companyId: Scalars['uuid'];
  id: Scalars['bigint'];
  role: HUserRole_Enum;
  /** An object relationship */
  user: HUsers;
  userId: Scalars['uuid'];
};

/** order by aggregate values of table "CompanyUser" */
export type HCompanyUser_Aggregate_Order_By = {
  avg?: Maybe<HCompanyUser_Avg_Order_By>;
  count?: Maybe<HOrder_By>;
  max?: Maybe<HCompanyUser_Max_Order_By>;
  min?: Maybe<HCompanyUser_Min_Order_By>;
  stddev?: Maybe<HCompanyUser_Stddev_Order_By>;
  stddev_pop?: Maybe<HCompanyUser_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<HCompanyUser_Stddev_Samp_Order_By>;
  sum?: Maybe<HCompanyUser_Sum_Order_By>;
  var_pop?: Maybe<HCompanyUser_Var_Pop_Order_By>;
  var_samp?: Maybe<HCompanyUser_Var_Samp_Order_By>;
  variance?: Maybe<HCompanyUser_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "CompanyUser" */
export type HCompanyUser_Arr_Rel_Insert_Input = {
  data: Array<HCompanyUser_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HCompanyUser_On_Conflict>;
};

/** order by avg() on columns of table "CompanyUser" */
export type HCompanyUser_Avg_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** Boolean expression to filter rows from the table "CompanyUser". All fields are combined with a logical 'AND'. */
export type HCompanyUser_Bool_Exp = {
  _and?: Maybe<Array<HCompanyUser_Bool_Exp>>;
  _not?: Maybe<HCompanyUser_Bool_Exp>;
  _or?: Maybe<Array<HCompanyUser_Bool_Exp>>;
  company?: Maybe<HCompany_Bool_Exp>;
  companyId?: Maybe<HUuid_Comparison_Exp>;
  id?: Maybe<HBigint_Comparison_Exp>;
  role?: Maybe<HUserRole_Enum_Comparison_Exp>;
  user?: Maybe<HUsers_Bool_Exp>;
  userId?: Maybe<HUuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "CompanyUser" */
export enum HCompanyUser_Constraint {
  /** unique or primary key constraint */
  CompanyUserCompanyIdUserId_10e185baUniq = 'CompanyUser_companyId_userId_10e185ba_uniq',
  /** unique or primary key constraint */
  CompanyUserPkey = 'companyUser_pkey'
}

/** input type for inserting data into table "CompanyUser" */
export type HCompanyUser_Insert_Input = {
  company?: Maybe<HCompany_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  role?: Maybe<HUserRole_Enum>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "CompanyUser" */
export type HCompanyUser_Max_Order_By = {
  companyId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  userId?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "CompanyUser" */
export type HCompanyUser_Min_Order_By = {
  companyId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  userId?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "CompanyUser" */
export type HCompanyUser_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HCompanyUser>;
};

/** on conflict condition type for table "CompanyUser" */
export type HCompanyUser_On_Conflict = {
  constraint: HCompanyUser_Constraint;
  update_columns?: Array<HCompanyUser_Update_Column>;
  where?: Maybe<HCompanyUser_Bool_Exp>;
};

/** Ordering options when selecting data from "CompanyUser". */
export type HCompanyUser_Order_By = {
  company?: Maybe<HCompany_Order_By>;
  companyId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  role?: Maybe<HOrder_By>;
  user?: Maybe<HUsers_Order_By>;
  userId?: Maybe<HOrder_By>;
};

/** primary key columns input for table: CompanyUser */
export type HCompanyUser_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "CompanyUser" */
export enum HCompanyUser_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "CompanyUser" */
export type HCompanyUser_Set_Input = {
  role?: Maybe<HUserRole_Enum>;
};

/** order by stddev() on columns of table "CompanyUser" */
export type HCompanyUser_Stddev_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by stddev_pop() on columns of table "CompanyUser" */
export type HCompanyUser_Stddev_Pop_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by stddev_samp() on columns of table "CompanyUser" */
export type HCompanyUser_Stddev_Samp_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by sum() on columns of table "CompanyUser" */
export type HCompanyUser_Sum_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** update columns of table "CompanyUser" */
export enum HCompanyUser_Update_Column {
  /** column name */
  Role = 'role'
}

/** order by var_pop() on columns of table "CompanyUser" */
export type HCompanyUser_Var_Pop_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by var_samp() on columns of table "CompanyUser" */
export type HCompanyUser_Var_Samp_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by variance() on columns of table "CompanyUser" */
export type HCompanyUser_Variance_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HCompany_Append_Input = {
  industries?: Maybe<Scalars['jsonb']>;
  settings?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "Company". All fields are combined with a logical 'AND'. */
export type HCompany_Bool_Exp = {
  Threads?: Maybe<HThread_Bool_Exp>;
  _and?: Maybe<Array<HCompany_Bool_Exp>>;
  _not?: Maybe<HCompany_Bool_Exp>;
  _or?: Maybe<Array<HCompany_Bool_Exp>>;
  businessUnits?: Maybe<HBusinessUnit_Bool_Exp>;
  cAssessmentAggregates?: Maybe<HCompanyAssessmentAggregate_Bool_Exp>;
  cAssessments?: Maybe<HCompanyAssessment_Bool_Exp>;
  currency?: Maybe<HString_Comparison_Exp>;
  files?: Maybe<HFile_Bool_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  industries?: Maybe<HJsonb_Comparison_Exp>;
  invitations?: Maybe<HCompanyInvitation_Bool_Exp>;
  isOnTrial?: Maybe<HBoolean_Comparison_Exp>;
  isPortfolioOwner?: Maybe<HBoolean_Comparison_Exp>;
  logoUrl?: Maybe<HString_Comparison_Exp>;
  name?: Maybe<HString_Comparison_Exp>;
  numberOfEmployees?: Maybe<HInt_Comparison_Exp>;
  orgId?: Maybe<HString_Comparison_Exp>;
  portfolioCompanies?: Maybe<HPortfolioCompany_Bool_Exp>;
  portfolios?: Maybe<HPortfolio_Bool_Exp>;
  settings?: Maybe<HJsonb_Comparison_Exp>;
  trialEndDate?: Maybe<HDate_Comparison_Exp>;
  users?: Maybe<HCompanyUser_Bool_Exp>;
};

/** unique or primary key constraints on table "Company" */
export enum HCompany_Constraint {
  /** unique or primary key constraint */
  CompanyPkey = 'company_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HCompany_Delete_At_Path_Input = {
  industries?: Maybe<Array<Scalars['String']>>;
  settings?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HCompany_Delete_Elem_Input = {
  industries?: Maybe<Scalars['Int']>;
  settings?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HCompany_Delete_Key_Input = {
  industries?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Company" */
export type HCompany_Inc_Input = {
  numberOfEmployees?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Company" */
export type HCompany_Insert_Input = {
  Threads?: Maybe<HThread_Arr_Rel_Insert_Input>;
  businessUnits?: Maybe<HBusinessUnit_Arr_Rel_Insert_Input>;
  cAssessmentAggregates?: Maybe<HCompanyAssessmentAggregate_Arr_Rel_Insert_Input>;
  cAssessments?: Maybe<HCompanyAssessment_Arr_Rel_Insert_Input>;
  currency?: Maybe<Scalars['String']>;
  files?: Maybe<HFile_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  industries?: Maybe<Scalars['jsonb']>;
  invitations?: Maybe<HCompanyInvitation_Arr_Rel_Insert_Input>;
  isPortfolioOwner?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  orgId?: Maybe<Scalars['String']>;
  portfolioCompanies?: Maybe<HPortfolioCompany_Arr_Rel_Insert_Input>;
  portfolios?: Maybe<HPortfolio_Arr_Rel_Insert_Input>;
  settings?: Maybe<Scalars['jsonb']>;
  users?: Maybe<HCompanyUser_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "Company" */
export type HCompany_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HCompany>;
};

/** input type for inserting object relation for remote table "Company" */
export type HCompany_Obj_Rel_Insert_Input = {
  data: HCompany_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HCompany_On_Conflict>;
};

/** on conflict condition type for table "Company" */
export type HCompany_On_Conflict = {
  constraint: HCompany_Constraint;
  update_columns?: Array<HCompany_Update_Column>;
  where?: Maybe<HCompany_Bool_Exp>;
};

/** Ordering options when selecting data from "Company". */
export type HCompany_Order_By = {
  Threads_aggregate?: Maybe<HThread_Aggregate_Order_By>;
  businessUnits_aggregate?: Maybe<HBusinessUnit_Aggregate_Order_By>;
  cAssessmentAggregates_aggregate?: Maybe<HCompanyAssessmentAggregate_Aggregate_Order_By>;
  cAssessments_aggregate?: Maybe<HCompanyAssessment_Aggregate_Order_By>;
  currency?: Maybe<HOrder_By>;
  files_aggregate?: Maybe<HFile_Aggregate_Order_By>;
  id?: Maybe<HOrder_By>;
  industries?: Maybe<HOrder_By>;
  invitations_aggregate?: Maybe<HCompanyInvitation_Aggregate_Order_By>;
  isOnTrial?: Maybe<HOrder_By>;
  isPortfolioOwner?: Maybe<HOrder_By>;
  logoUrl?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  numberOfEmployees?: Maybe<HOrder_By>;
  orgId?: Maybe<HOrder_By>;
  portfolioCompanies_aggregate?: Maybe<HPortfolioCompany_Aggregate_Order_By>;
  portfolios_aggregate?: Maybe<HPortfolio_Aggregate_Order_By>;
  settings?: Maybe<HOrder_By>;
  trialEndDate?: Maybe<HOrder_By>;
  users_aggregate?: Maybe<HCompanyUser_Aggregate_Order_By>;
};

/** primary key columns input for table: Company */
export type HCompany_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HCompany_Prepend_Input = {
  industries?: Maybe<Scalars['jsonb']>;
  settings?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "Company" */
export enum HCompany_Select_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Industries = 'industries',
  /** column name */
  IsOnTrial = 'isOnTrial',
  /** column name */
  IsPortfolioOwner = 'isPortfolioOwner',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfEmployees = 'numberOfEmployees',
  /** column name */
  OrgId = 'orgId',
  /** column name */
  Settings = 'settings',
  /** column name */
  TrialEndDate = 'trialEndDate'
}

/** input type for updating data in table "Company" */
export type HCompany_Set_Input = {
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  industries?: Maybe<Scalars['jsonb']>;
  isPortfolioOwner?: Maybe<Scalars['Boolean']>;
  logoUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  orgId?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "Company" */
export enum HCompany_Update_Column {
  /** column name */
  Currency = 'currency',
  /** column name */
  Id = 'id',
  /** column name */
  Industries = 'industries',
  /** column name */
  IsPortfolioOwner = 'isPortfolioOwner',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfEmployees = 'numberOfEmployees',
  /** column name */
  OrgId = 'orgId',
  /** column name */
  Settings = 'settings'
}

export type HEmailItem = {
  companyId: Scalars['String'];
  companyName: Scalars['String'];
  displayName: Scalars['String'];
  email: Scalars['String'];
};

/** columns and relationships of "EstimateCompany" */
export type HEstimateCompany = {
  /** An array relationship */
  PortfolioCompanies: Array<HPortfolioCompany>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  industries: Scalars['jsonb'];
  logoUrl?: Maybe<Scalars['String']>;
  metadata: Scalars['jsonb'];
  name: Scalars['String'];
  scoreType: Scalars['String'];
  scores: Scalars['jsonb'];
  uniqueId: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "EstimateCompany" */
export type HEstimateCompanyPortfolioCompaniesArgs = {
  distinct_on?: Maybe<Array<HPortfolioCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HPortfolioCompany_Order_By>>;
  where?: Maybe<HPortfolioCompany_Bool_Exp>;
};


/** columns and relationships of "EstimateCompany" */
export type HEstimateCompanyIndustriesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "EstimateCompany" */
export type HEstimateCompanyMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "EstimateCompany" */
export type HEstimateCompanyScoresArgs = {
  path?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "EstimateCompany". All fields are combined with a logical 'AND'. */
export type HEstimateCompany_Bool_Exp = {
  PortfolioCompanies?: Maybe<HPortfolioCompany_Bool_Exp>;
  _and?: Maybe<Array<HEstimateCompany_Bool_Exp>>;
  _not?: Maybe<HEstimateCompany_Bool_Exp>;
  _or?: Maybe<Array<HEstimateCompany_Bool_Exp>>;
  country?: Maybe<HString_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  industries?: Maybe<HJsonb_Comparison_Exp>;
  logoUrl?: Maybe<HString_Comparison_Exp>;
  metadata?: Maybe<HJsonb_Comparison_Exp>;
  name?: Maybe<HString_Comparison_Exp>;
  scoreType?: Maybe<HString_Comparison_Exp>;
  scores?: Maybe<HJsonb_Comparison_Exp>;
  uniqueId?: Maybe<HString_Comparison_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "EstimateCompany". */
export type HEstimateCompany_Order_By = {
  PortfolioCompanies_aggregate?: Maybe<HPortfolioCompany_Aggregate_Order_By>;
  country?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  industries?: Maybe<HOrder_By>;
  logoUrl?: Maybe<HOrder_By>;
  metadata?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  scoreType?: Maybe<HOrder_By>;
  scores?: Maybe<HOrder_By>;
  uniqueId?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** select columns of table "EstimateCompany" */
export enum HEstimateCompany_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Industries = 'industries',
  /** column name */
  LogoUrl = 'logoUrl',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  ScoreType = 'scoreType',
  /** column name */
  Scores = 'scores',
  /** column name */
  UniqueId = 'uniqueId',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** columns and relationships of "File" */
export type HFile = {
  /** An array relationship */
  answerAttachments: Array<HAnswerAttachment>;
  /** An object relationship */
  company: HCompany;
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  creationDate?: Maybe<Scalars['date']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  source: Scalars['String'];
  tags: Scalars['jsonb'];
  title: Scalars['String'];
  token: Scalars['String'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  uploadedBy: HUsers;
  uploadedById: Scalars['uuid'];
  url: Scalars['String'];
};


/** columns and relationships of "File" */
export type HFileAnswerAttachmentsArgs = {
  distinct_on?: Maybe<Array<HAnswerAttachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswerAttachment_Order_By>>;
  where?: Maybe<HAnswerAttachment_Bool_Exp>;
};


/** columns and relationships of "File" */
export type HFileTagsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "File" */
export type HFile_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HFile_Max_Order_By>;
  min?: Maybe<HFile_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HFile_Append_Input = {
  tags?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "File" */
export type HFile_Arr_Rel_Insert_Input = {
  data: Array<HFile_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HFile_On_Conflict>;
};

/** Boolean expression to filter rows from the table "File". All fields are combined with a logical 'AND'. */
export type HFile_Bool_Exp = {
  _and?: Maybe<Array<HFile_Bool_Exp>>;
  _not?: Maybe<HFile_Bool_Exp>;
  _or?: Maybe<Array<HFile_Bool_Exp>>;
  answerAttachments?: Maybe<HAnswerAttachment_Bool_Exp>;
  company?: Maybe<HCompany_Bool_Exp>;
  companyId?: Maybe<HUuid_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  creationDate?: Maybe<HDate_Comparison_Exp>;
  description?: Maybe<HString_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  source?: Maybe<HString_Comparison_Exp>;
  tags?: Maybe<HJsonb_Comparison_Exp>;
  title?: Maybe<HString_Comparison_Exp>;
  token?: Maybe<HString_Comparison_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
  uploadedBy?: Maybe<HUsers_Bool_Exp>;
  uploadedById?: Maybe<HUuid_Comparison_Exp>;
  url?: Maybe<HString_Comparison_Exp>;
};

/** unique or primary key constraints on table "File" */
export enum HFile_Constraint {
  /** unique or primary key constraint */
  FilePkey = 'File_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HFile_Delete_At_Path_Input = {
  tags?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HFile_Delete_Elem_Input = {
  tags?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HFile_Delete_Key_Input = {
  tags?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "File" */
export type HFile_Insert_Input = {
  answerAttachments?: Maybe<HAnswerAttachment_Arr_Rel_Insert_Input>;
  company?: Maybe<HCompany_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  creationDate?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  uploadedById?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "File" */
export type HFile_Max_Order_By = {
  companyId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  creationDate?: Maybe<HOrder_By>;
  description?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  source?: Maybe<HOrder_By>;
  title?: Maybe<HOrder_By>;
  token?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
  uploadedById?: Maybe<HOrder_By>;
  url?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "File" */
export type HFile_Min_Order_By = {
  companyId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  creationDate?: Maybe<HOrder_By>;
  description?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  source?: Maybe<HOrder_By>;
  title?: Maybe<HOrder_By>;
  token?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
  uploadedById?: Maybe<HOrder_By>;
  url?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "File" */
export type HFile_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HFile>;
};

/** input type for inserting object relation for remote table "File" */
export type HFile_Obj_Rel_Insert_Input = {
  data: HFile_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HFile_On_Conflict>;
};

/** on conflict condition type for table "File" */
export type HFile_On_Conflict = {
  constraint: HFile_Constraint;
  update_columns?: Array<HFile_Update_Column>;
  where?: Maybe<HFile_Bool_Exp>;
};

/** Ordering options when selecting data from "File". */
export type HFile_Order_By = {
  answerAttachments_aggregate?: Maybe<HAnswerAttachment_Aggregate_Order_By>;
  company?: Maybe<HCompany_Order_By>;
  companyId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  creationDate?: Maybe<HOrder_By>;
  description?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  source?: Maybe<HOrder_By>;
  tags?: Maybe<HOrder_By>;
  title?: Maybe<HOrder_By>;
  token?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
  uploadedBy?: Maybe<HUsers_Order_By>;
  uploadedById?: Maybe<HOrder_By>;
  url?: Maybe<HOrder_By>;
};

/** primary key columns input for table: File */
export type HFile_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HFile_Prepend_Input = {
  tags?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "File" */
export enum HFile_Select_Column {
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  CreationDate = 'creationDate',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Source = 'source',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  Token = 'token',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UploadedById = 'uploadedById',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "File" */
export type HFile_Set_Input = {
  creationDate?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['jsonb']>;
  title?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  uploadedById?: Maybe<Scalars['uuid']>;
  url?: Maybe<Scalars['String']>;
};

/** update columns of table "File" */
export enum HFile_Update_Column {
  /** column name */
  CreationDate = 'creationDate',
  /** column name */
  Description = 'description',
  /** column name */
  Source = 'source',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  Token = 'token',
  /** column name */
  UploadedById = 'uploadedById',
  /** column name */
  Url = 'url'
}

/** columns and relationships of "Financials" */
export type HFinancials = {
  /** An object relationship */
  activityReport: HActivityReport;
  adaptationCapex: Scalars['numeric'];
  adaptationOpex: Scalars['numeric'];
  capex: Scalars['numeric'];
  id: Scalars['uuid'];
  isEstimate: Scalars['Boolean'];
  opex: Scalars['numeric'];
  revenue: Scalars['numeric'];
};

/** Boolean expression to filter rows from the table "Financials". All fields are combined with a logical 'AND'. */
export type HFinancials_Bool_Exp = {
  _and?: Maybe<Array<HFinancials_Bool_Exp>>;
  _not?: Maybe<HFinancials_Bool_Exp>;
  _or?: Maybe<Array<HFinancials_Bool_Exp>>;
  activityReport?: Maybe<HActivityReport_Bool_Exp>;
  adaptationCapex?: Maybe<HNumeric_Comparison_Exp>;
  adaptationOpex?: Maybe<HNumeric_Comparison_Exp>;
  capex?: Maybe<HNumeric_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  isEstimate?: Maybe<HBoolean_Comparison_Exp>;
  opex?: Maybe<HNumeric_Comparison_Exp>;
  revenue?: Maybe<HNumeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "Financials" */
export enum HFinancials_Constraint {
  /** unique or primary key constraint */
  FinancialsReportId_801651ecUniq = 'Financials_reportId_801651ec_uniq',
  /** unique or primary key constraint */
  FinancialsPkey = 'financials_pkey'
}

/** input type for incrementing numeric columns in table "Financials" */
export type HFinancials_Inc_Input = {
  adaptationCapex?: Maybe<Scalars['numeric']>;
  adaptationOpex?: Maybe<Scalars['numeric']>;
  capex?: Maybe<Scalars['numeric']>;
  opex?: Maybe<Scalars['numeric']>;
  revenue?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Financials" */
export type HFinancials_Insert_Input = {
  activityReport?: Maybe<HActivityReport_Obj_Rel_Insert_Input>;
  adaptationCapex?: Maybe<Scalars['numeric']>;
  adaptationOpex?: Maybe<Scalars['numeric']>;
  capex?: Maybe<Scalars['numeric']>;
  isEstimate?: Maybe<Scalars['Boolean']>;
  opex?: Maybe<Scalars['numeric']>;
  revenue?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "Financials" */
export type HFinancials_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HFinancials>;
};

/** input type for inserting object relation for remote table "Financials" */
export type HFinancials_Obj_Rel_Insert_Input = {
  data: HFinancials_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HFinancials_On_Conflict>;
};

/** on conflict condition type for table "Financials" */
export type HFinancials_On_Conflict = {
  constraint: HFinancials_Constraint;
  update_columns?: Array<HFinancials_Update_Column>;
  where?: Maybe<HFinancials_Bool_Exp>;
};

/** Ordering options when selecting data from "Financials". */
export type HFinancials_Order_By = {
  activityReport?: Maybe<HActivityReport_Order_By>;
  adaptationCapex?: Maybe<HOrder_By>;
  adaptationOpex?: Maybe<HOrder_By>;
  capex?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  isEstimate?: Maybe<HOrder_By>;
  opex?: Maybe<HOrder_By>;
  revenue?: Maybe<HOrder_By>;
};

/** primary key columns input for table: Financials */
export type HFinancials_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Financials" */
export enum HFinancials_Select_Column {
  /** column name */
  AdaptationCapex = 'adaptationCapex',
  /** column name */
  AdaptationOpex = 'adaptationOpex',
  /** column name */
  Capex = 'capex',
  /** column name */
  Id = 'id',
  /** column name */
  IsEstimate = 'isEstimate',
  /** column name */
  Opex = 'opex',
  /** column name */
  Revenue = 'revenue'
}

/** input type for updating data in table "Financials" */
export type HFinancials_Set_Input = {
  adaptationCapex?: Maybe<Scalars['numeric']>;
  adaptationOpex?: Maybe<Scalars['numeric']>;
  capex?: Maybe<Scalars['numeric']>;
  isEstimate?: Maybe<Scalars['Boolean']>;
  opex?: Maybe<Scalars['numeric']>;
  revenue?: Maybe<Scalars['numeric']>;
};

/** update columns of table "Financials" */
export enum HFinancials_Update_Column {
  /** column name */
  AdaptationCapex = 'adaptationCapex',
  /** column name */
  AdaptationOpex = 'adaptationOpex',
  /** column name */
  Capex = 'capex',
  /** column name */
  IsEstimate = 'isEstimate',
  /** column name */
  Opex = 'opex',
  /** column name */
  Revenue = 'revenue'
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type HInt_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type HInvitationOutput = {
  companyId: Scalars['uuid'];
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  id: Scalars['uuid'];
  inviteToChildCompanies: Scalars['Boolean'];
  status: Scalars['String'];
  userEmail: Scalars['String'];
  userExists: Scalars['Boolean'];
};

export type HInvitationQuery = {
  id: Scalars['uuid'];
};

export type HNudgePortfolioCompaniesInput = {
  customMessage: Scalars['String'];
  portfolioOwner: Scalars['String'];
};

export type HNudgePortfolioCompaniesOutput = {
  emailsSent: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Objective" */
export type HObjective = {
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  /** An array relationship */
  questions: Array<HQuestion>;
  title: Scalars['String'];
};


/** columns and relationships of "Objective" */
export type HObjectiveQuestionsArgs = {
  distinct_on?: Maybe<Array<HQuestion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HQuestion_Order_By>>;
  where?: Maybe<HQuestion_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "Objective". All fields are combined with a logical 'AND'. */
export type HObjective_Bool_Exp = {
  _and?: Maybe<Array<HObjective_Bool_Exp>>;
  _not?: Maybe<HObjective_Bool_Exp>;
  _or?: Maybe<Array<HObjective_Bool_Exp>>;
  description?: Maybe<HString_Comparison_Exp>;
  key?: Maybe<HString_Comparison_Exp>;
  questions?: Maybe<HQuestion_Bool_Exp>;
  title?: Maybe<HString_Comparison_Exp>;
};

/** Ordering options when selecting data from "Objective". */
export type HObjective_Order_By = {
  description?: Maybe<HOrder_By>;
  key?: Maybe<HOrder_By>;
  questions_aggregate?: Maybe<HQuestion_Aggregate_Order_By>;
  title?: Maybe<HOrder_By>;
};

/** select columns of table "Objective" */
export enum HObjective_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Key = 'key',
  /** column name */
  Title = 'title'
}

/** columns and relationships of "Portfolio" */
export type HPortfolio = {
  description: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  ownerCompany: HCompany;
  ownerCompanyId: Scalars['uuid'];
  /** An array relationship */
  portfolioCompanies: Array<HPortfolioCompany>;
  totalAmount: Scalars['numeric'];
};


/** columns and relationships of "Portfolio" */
export type HPortfolioPortfolioCompaniesArgs = {
  distinct_on?: Maybe<Array<HPortfolioCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HPortfolioCompany_Order_By>>;
  where?: Maybe<HPortfolioCompany_Bool_Exp>;
};

/** columns and relationships of "PortfolioCompany" */
export type HPortfolioCompany = {
  amount: Scalars['numeric'];
  /** An object relationship */
  company?: Maybe<HCompany>;
  companyId?: Maybe<Scalars['uuid']>;
  contactPersonEmail: Scalars['String'];
  contactPersonName: Scalars['String'];
  createdAt: Scalars['timestamptz'];
  /** An object relationship */
  estimateCompany?: Maybe<HEstimateCompany>;
  estimateCompanyId?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  portfolio: HPortfolio;
  portfolioId: Scalars['uuid'];
  scores: Scalars['jsonb'];
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "PortfolioCompany" */
export type HPortfolioCompanyScoresArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "PortfolioCompany" */
export type HPortfolioCompany_Aggregate_Order_By = {
  avg?: Maybe<HPortfolioCompany_Avg_Order_By>;
  count?: Maybe<HOrder_By>;
  max?: Maybe<HPortfolioCompany_Max_Order_By>;
  min?: Maybe<HPortfolioCompany_Min_Order_By>;
  stddev?: Maybe<HPortfolioCompany_Stddev_Order_By>;
  stddev_pop?: Maybe<HPortfolioCompany_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<HPortfolioCompany_Stddev_Samp_Order_By>;
  sum?: Maybe<HPortfolioCompany_Sum_Order_By>;
  var_pop?: Maybe<HPortfolioCompany_Var_Pop_Order_By>;
  var_samp?: Maybe<HPortfolioCompany_Var_Samp_Order_By>;
  variance?: Maybe<HPortfolioCompany_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HPortfolioCompany_Append_Input = {
  scores?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "PortfolioCompany" */
export type HPortfolioCompany_Arr_Rel_Insert_Input = {
  data: Array<HPortfolioCompany_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HPortfolioCompany_On_Conflict>;
};

/** order by avg() on columns of table "PortfolioCompany" */
export type HPortfolioCompany_Avg_Order_By = {
  amount?: Maybe<HOrder_By>;
};

/** Boolean expression to filter rows from the table "PortfolioCompany". All fields are combined with a logical 'AND'. */
export type HPortfolioCompany_Bool_Exp = {
  _and?: Maybe<Array<HPortfolioCompany_Bool_Exp>>;
  _not?: Maybe<HPortfolioCompany_Bool_Exp>;
  _or?: Maybe<Array<HPortfolioCompany_Bool_Exp>>;
  amount?: Maybe<HNumeric_Comparison_Exp>;
  company?: Maybe<HCompany_Bool_Exp>;
  companyId?: Maybe<HUuid_Comparison_Exp>;
  contactPersonEmail?: Maybe<HString_Comparison_Exp>;
  contactPersonName?: Maybe<HString_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  estimateCompany?: Maybe<HEstimateCompany_Bool_Exp>;
  estimateCompanyId?: Maybe<HUuid_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  portfolio?: Maybe<HPortfolio_Bool_Exp>;
  portfolioId?: Maybe<HUuid_Comparison_Exp>;
  scores?: Maybe<HJsonb_Comparison_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "PortfolioCompany" */
export enum HPortfolioCompany_Constraint {
  /** unique or primary key constraint */
  PortfolioCompanyPkey = 'PortfolioCompany_pkey',
  /** unique or primary key constraint */
  PortfolioCompanyPortfolioIdCompanyIdFcfe62f4Uniq = 'PortfolioCompany_portfolioId_companyId_fcfe62f4_uniq',
  /** unique or primary key constraint */
  PortfolioCompanyPortfolioIdEstimateCompanyIdFd25d65dUniq = 'PortfolioCompany_portfolioId_estimateCompanyId_fd25d65d_uniq'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HPortfolioCompany_Delete_At_Path_Input = {
  scores?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HPortfolioCompany_Delete_Elem_Input = {
  scores?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HPortfolioCompany_Delete_Key_Input = {
  scores?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "PortfolioCompany" */
export type HPortfolioCompany_Inc_Input = {
  amount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "PortfolioCompany" */
export type HPortfolioCompany_Insert_Input = {
  amount?: Maybe<Scalars['numeric']>;
  company?: Maybe<HCompany_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  contactPersonName?: Maybe<Scalars['String']>;
  estimateCompanyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  portfolio?: Maybe<HPortfolio_Obj_Rel_Insert_Input>;
  portfolioId?: Maybe<Scalars['uuid']>;
  scores?: Maybe<Scalars['jsonb']>;
};

/** order by max() on columns of table "PortfolioCompany" */
export type HPortfolioCompany_Max_Order_By = {
  amount?: Maybe<HOrder_By>;
  companyId?: Maybe<HOrder_By>;
  contactPersonEmail?: Maybe<HOrder_By>;
  contactPersonName?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  estimateCompanyId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  portfolioId?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "PortfolioCompany" */
export type HPortfolioCompany_Min_Order_By = {
  amount?: Maybe<HOrder_By>;
  companyId?: Maybe<HOrder_By>;
  contactPersonEmail?: Maybe<HOrder_By>;
  contactPersonName?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  estimateCompanyId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  portfolioId?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "PortfolioCompany" */
export type HPortfolioCompany_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HPortfolioCompany>;
};

/** on conflict condition type for table "PortfolioCompany" */
export type HPortfolioCompany_On_Conflict = {
  constraint: HPortfolioCompany_Constraint;
  update_columns?: Array<HPortfolioCompany_Update_Column>;
  where?: Maybe<HPortfolioCompany_Bool_Exp>;
};

/** Ordering options when selecting data from "PortfolioCompany". */
export type HPortfolioCompany_Order_By = {
  amount?: Maybe<HOrder_By>;
  company?: Maybe<HCompany_Order_By>;
  companyId?: Maybe<HOrder_By>;
  contactPersonEmail?: Maybe<HOrder_By>;
  contactPersonName?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  estimateCompany?: Maybe<HEstimateCompany_Order_By>;
  estimateCompanyId?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  portfolio?: Maybe<HPortfolio_Order_By>;
  portfolioId?: Maybe<HOrder_By>;
  scores?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** primary key columns input for table: PortfolioCompany */
export type HPortfolioCompany_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HPortfolioCompany_Prepend_Input = {
  scores?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "PortfolioCompany" */
export enum HPortfolioCompany_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  ContactPersonEmail = 'contactPersonEmail',
  /** column name */
  ContactPersonName = 'contactPersonName',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  EstimateCompanyId = 'estimateCompanyId',
  /** column name */
  Id = 'id',
  /** column name */
  PortfolioId = 'portfolioId',
  /** column name */
  Scores = 'scores',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "PortfolioCompany" */
export type HPortfolioCompany_Set_Input = {
  amount?: Maybe<Scalars['numeric']>;
  companyId?: Maybe<Scalars['uuid']>;
  contactPersonEmail?: Maybe<Scalars['String']>;
  contactPersonName?: Maybe<Scalars['String']>;
  estimateCompanyId?: Maybe<Scalars['uuid']>;
  scores?: Maybe<Scalars['jsonb']>;
};

/** order by stddev() on columns of table "PortfolioCompany" */
export type HPortfolioCompany_Stddev_Order_By = {
  amount?: Maybe<HOrder_By>;
};

/** order by stddev_pop() on columns of table "PortfolioCompany" */
export type HPortfolioCompany_Stddev_Pop_Order_By = {
  amount?: Maybe<HOrder_By>;
};

/** order by stddev_samp() on columns of table "PortfolioCompany" */
export type HPortfolioCompany_Stddev_Samp_Order_By = {
  amount?: Maybe<HOrder_By>;
};

/** order by sum() on columns of table "PortfolioCompany" */
export type HPortfolioCompany_Sum_Order_By = {
  amount?: Maybe<HOrder_By>;
};

/** update columns of table "PortfolioCompany" */
export enum HPortfolioCompany_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  ContactPersonEmail = 'contactPersonEmail',
  /** column name */
  ContactPersonName = 'contactPersonName',
  /** column name */
  EstimateCompanyId = 'estimateCompanyId',
  /** column name */
  Scores = 'scores'
}

/** order by var_pop() on columns of table "PortfolioCompany" */
export type HPortfolioCompany_Var_Pop_Order_By = {
  amount?: Maybe<HOrder_By>;
};

/** order by var_samp() on columns of table "PortfolioCompany" */
export type HPortfolioCompany_Var_Samp_Order_By = {
  amount?: Maybe<HOrder_By>;
};

/** order by variance() on columns of table "PortfolioCompany" */
export type HPortfolioCompany_Variance_Order_By = {
  amount?: Maybe<HOrder_By>;
};

/** order by aggregate values of table "Portfolio" */
export type HPortfolio_Aggregate_Order_By = {
  avg?: Maybe<HPortfolio_Avg_Order_By>;
  count?: Maybe<HOrder_By>;
  max?: Maybe<HPortfolio_Max_Order_By>;
  min?: Maybe<HPortfolio_Min_Order_By>;
  stddev?: Maybe<HPortfolio_Stddev_Order_By>;
  stddev_pop?: Maybe<HPortfolio_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<HPortfolio_Stddev_Samp_Order_By>;
  sum?: Maybe<HPortfolio_Sum_Order_By>;
  var_pop?: Maybe<HPortfolio_Var_Pop_Order_By>;
  var_samp?: Maybe<HPortfolio_Var_Samp_Order_By>;
  variance?: Maybe<HPortfolio_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Portfolio" */
export type HPortfolio_Arr_Rel_Insert_Input = {
  data: Array<HPortfolio_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HPortfolio_On_Conflict>;
};

/** order by avg() on columns of table "Portfolio" */
export type HPortfolio_Avg_Order_By = {
  totalAmount?: Maybe<HOrder_By>;
};

/** Boolean expression to filter rows from the table "Portfolio". All fields are combined with a logical 'AND'. */
export type HPortfolio_Bool_Exp = {
  _and?: Maybe<Array<HPortfolio_Bool_Exp>>;
  _not?: Maybe<HPortfolio_Bool_Exp>;
  _or?: Maybe<Array<HPortfolio_Bool_Exp>>;
  description?: Maybe<HString_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  name?: Maybe<HString_Comparison_Exp>;
  ownerCompany?: Maybe<HCompany_Bool_Exp>;
  ownerCompanyId?: Maybe<HUuid_Comparison_Exp>;
  portfolioCompanies?: Maybe<HPortfolioCompany_Bool_Exp>;
  totalAmount?: Maybe<HNumeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "Portfolio" */
export enum HPortfolio_Constraint {
  /** unique or primary key constraint */
  PortfolioPkey = 'Portfolio_pkey'
}

/** input type for incrementing numeric columns in table "Portfolio" */
export type HPortfolio_Inc_Input = {
  totalAmount?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Portfolio" */
export type HPortfolio_Insert_Input = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  ownerCompany?: Maybe<HCompany_Obj_Rel_Insert_Input>;
  ownerCompanyId?: Maybe<Scalars['uuid']>;
  portfolioCompanies?: Maybe<HPortfolioCompany_Arr_Rel_Insert_Input>;
  totalAmount?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "Portfolio" */
export type HPortfolio_Max_Order_By = {
  description?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  ownerCompanyId?: Maybe<HOrder_By>;
  totalAmount?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "Portfolio" */
export type HPortfolio_Min_Order_By = {
  description?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  ownerCompanyId?: Maybe<HOrder_By>;
  totalAmount?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "Portfolio" */
export type HPortfolio_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HPortfolio>;
};

/** input type for inserting object relation for remote table "Portfolio" */
export type HPortfolio_Obj_Rel_Insert_Input = {
  data: HPortfolio_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HPortfolio_On_Conflict>;
};

/** on conflict condition type for table "Portfolio" */
export type HPortfolio_On_Conflict = {
  constraint: HPortfolio_Constraint;
  update_columns?: Array<HPortfolio_Update_Column>;
  where?: Maybe<HPortfolio_Bool_Exp>;
};

/** Ordering options when selecting data from "Portfolio". */
export type HPortfolio_Order_By = {
  description?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
  ownerCompany?: Maybe<HCompany_Order_By>;
  ownerCompanyId?: Maybe<HOrder_By>;
  portfolioCompanies_aggregate?: Maybe<HPortfolioCompany_Aggregate_Order_By>;
  totalAmount?: Maybe<HOrder_By>;
};

/** primary key columns input for table: Portfolio */
export type HPortfolio_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Portfolio" */
export enum HPortfolio_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerCompanyId = 'ownerCompanyId',
  /** column name */
  TotalAmount = 'totalAmount'
}

/** input type for updating data in table "Portfolio" */
export type HPortfolio_Set_Input = {
  deletedAt?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['numeric']>;
};

/** order by stddev() on columns of table "Portfolio" */
export type HPortfolio_Stddev_Order_By = {
  totalAmount?: Maybe<HOrder_By>;
};

/** order by stddev_pop() on columns of table "Portfolio" */
export type HPortfolio_Stddev_Pop_Order_By = {
  totalAmount?: Maybe<HOrder_By>;
};

/** order by stddev_samp() on columns of table "Portfolio" */
export type HPortfolio_Stddev_Samp_Order_By = {
  totalAmount?: Maybe<HOrder_By>;
};

/** order by sum() on columns of table "Portfolio" */
export type HPortfolio_Sum_Order_By = {
  totalAmount?: Maybe<HOrder_By>;
};

/** update columns of table "Portfolio" */
export enum HPortfolio_Update_Column {
  /** column name */
  DeletedAt = 'deletedAt',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  TotalAmount = 'totalAmount'
}

/** order by var_pop() on columns of table "Portfolio" */
export type HPortfolio_Var_Pop_Order_By = {
  totalAmount?: Maybe<HOrder_By>;
};

/** order by var_samp() on columns of table "Portfolio" */
export type HPortfolio_Var_Samp_Order_By = {
  totalAmount?: Maybe<HOrder_By>;
};

/** order by variance() on columns of table "Portfolio" */
export type HPortfolio_Variance_Order_By = {
  totalAmount?: Maybe<HOrder_By>;
};

/** columns and relationships of "Question" */
export type HQuestion = {
  /** An array relationship */
  Answers: Array<HAnswer>;
  aboutDocumentation?: Maybe<Scalars['String']>;
  alignmentErrorMessage?: Maybe<Scalars['String']>;
  /** An array relationship */
  choices: Array<HQuestionChoice>;
  dependencyDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  documentationRequired: Scalars['Boolean'];
  extendedDescription?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isAligned?: Maybe<Scalars['String']>;
  isEditable?: Maybe<Scalars['String']>;
  isRequired?: Maybe<Scalars['String']>;
  isVisible?: Maybe<Scalars['String']>;
  level: HQuestionLevel_Enum;
  levelExpression?: Maybe<Scalars['String']>;
  /** An object relationship */
  objective: HObjective;
  objectiveKey: Scalars['String'];
  orderIndex: Scalars['String'];
  other: Scalars['jsonb'];
  /** An object relationship */
  questionSet: HQuestionSet;
  questionSetRef: Scalars['String'];
  questionSetVersion: Scalars['String'];
  relevantDirectives?: Maybe<Scalars['String']>;
  resourceLink?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: HQuestionType_Enum;
  uniqueId: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Question" */
export type HQuestionAnswersArgs = {
  distinct_on?: Maybe<Array<HAnswer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswer_Order_By>>;
  where?: Maybe<HAnswer_Bool_Exp>;
};


/** columns and relationships of "Question" */
export type HQuestionChoicesArgs = {
  distinct_on?: Maybe<Array<HQuestionChoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HQuestionChoice_Order_By>>;
  where?: Maybe<HQuestionChoice_Bool_Exp>;
};


/** columns and relationships of "Question" */
export type HQuestionOtherArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "QuestionChoice" */
export type HQuestionChoice = {
  displayName: Scalars['String'];
  id: Scalars['bigint'];
  order: Scalars['String'];
  /** An object relationship */
  question: HQuestion;
  question_id: Scalars['uuid'];
  value: Scalars['String'];
};

/** order by aggregate values of table "QuestionChoice" */
export type HQuestionChoice_Aggregate_Order_By = {
  avg?: Maybe<HQuestionChoice_Avg_Order_By>;
  count?: Maybe<HOrder_By>;
  max?: Maybe<HQuestionChoice_Max_Order_By>;
  min?: Maybe<HQuestionChoice_Min_Order_By>;
  stddev?: Maybe<HQuestionChoice_Stddev_Order_By>;
  stddev_pop?: Maybe<HQuestionChoice_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<HQuestionChoice_Stddev_Samp_Order_By>;
  sum?: Maybe<HQuestionChoice_Sum_Order_By>;
  var_pop?: Maybe<HQuestionChoice_Var_Pop_Order_By>;
  var_samp?: Maybe<HQuestionChoice_Var_Samp_Order_By>;
  variance?: Maybe<HQuestionChoice_Variance_Order_By>;
};

/** order by avg() on columns of table "QuestionChoice" */
export type HQuestionChoice_Avg_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** Boolean expression to filter rows from the table "QuestionChoice". All fields are combined with a logical 'AND'. */
export type HQuestionChoice_Bool_Exp = {
  _and?: Maybe<Array<HQuestionChoice_Bool_Exp>>;
  _not?: Maybe<HQuestionChoice_Bool_Exp>;
  _or?: Maybe<Array<HQuestionChoice_Bool_Exp>>;
  displayName?: Maybe<HString_Comparison_Exp>;
  id?: Maybe<HBigint_Comparison_Exp>;
  order?: Maybe<HString_Comparison_Exp>;
  question?: Maybe<HQuestion_Bool_Exp>;
  question_id?: Maybe<HUuid_Comparison_Exp>;
  value?: Maybe<HString_Comparison_Exp>;
};

/** order by max() on columns of table "QuestionChoice" */
export type HQuestionChoice_Max_Order_By = {
  displayName?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  order?: Maybe<HOrder_By>;
  question_id?: Maybe<HOrder_By>;
  value?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "QuestionChoice" */
export type HQuestionChoice_Min_Order_By = {
  displayName?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  order?: Maybe<HOrder_By>;
  question_id?: Maybe<HOrder_By>;
  value?: Maybe<HOrder_By>;
};

/** Ordering options when selecting data from "QuestionChoice". */
export type HQuestionChoice_Order_By = {
  displayName?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  order?: Maybe<HOrder_By>;
  question?: Maybe<HQuestion_Order_By>;
  question_id?: Maybe<HOrder_By>;
  value?: Maybe<HOrder_By>;
};

/** select columns of table "QuestionChoice" */
export enum HQuestionChoice_Select_Column {
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  QuestionId = 'question_id',
  /** column name */
  Value = 'value'
}

/** order by stddev() on columns of table "QuestionChoice" */
export type HQuestionChoice_Stddev_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by stddev_pop() on columns of table "QuestionChoice" */
export type HQuestionChoice_Stddev_Pop_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by stddev_samp() on columns of table "QuestionChoice" */
export type HQuestionChoice_Stddev_Samp_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by sum() on columns of table "QuestionChoice" */
export type HQuestionChoice_Sum_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by var_pop() on columns of table "QuestionChoice" */
export type HQuestionChoice_Var_Pop_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by var_samp() on columns of table "QuestionChoice" */
export type HQuestionChoice_Var_Samp_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by variance() on columns of table "QuestionChoice" */
export type HQuestionChoice_Variance_Order_By = {
  id?: Maybe<HOrder_By>;
};

export enum HQuestionLevel_Enum {
  DoNoSignificantHarm = 'DO_NO_SIGNIFICANT_HARM',
  General = 'GENERAL',
  SocialSafeguards = 'SOCIAL_SAFEGUARDS',
  SubstantialContribution = 'SUBSTANTIAL_CONTRIBUTION'
}

/** Boolean expression to compare columns of type "QuestionLevel_enum". All fields are combined with logical 'AND'. */
export type HQuestionLevel_Enum_Comparison_Exp = {
  _eq?: Maybe<HQuestionLevel_Enum>;
  _in?: Maybe<Array<HQuestionLevel_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<HQuestionLevel_Enum>;
  _nin?: Maybe<Array<HQuestionLevel_Enum>>;
};

/** columns and relationships of "QuestionSet" */
export type HQuestionSet = {
  /** An array relationship */
  activityQuestionSets: Array<HActivityQuestionSet>;
  newestVersion: Scalars['String'];
  /** An array relationship */
  questions: Array<HQuestion>;
  reference: Scalars['String'];
};


/** columns and relationships of "QuestionSet" */
export type HQuestionSetActivityQuestionSetsArgs = {
  distinct_on?: Maybe<Array<HActivityQuestionSet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityQuestionSet_Order_By>>;
  where?: Maybe<HActivityQuestionSet_Bool_Exp>;
};


/** columns and relationships of "QuestionSet" */
export type HQuestionSetQuestionsArgs = {
  distinct_on?: Maybe<Array<HQuestion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HQuestion_Order_By>>;
  where?: Maybe<HQuestion_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "QuestionSet". All fields are combined with a logical 'AND'. */
export type HQuestionSet_Bool_Exp = {
  _and?: Maybe<Array<HQuestionSet_Bool_Exp>>;
  _not?: Maybe<HQuestionSet_Bool_Exp>;
  _or?: Maybe<Array<HQuestionSet_Bool_Exp>>;
  activityQuestionSets?: Maybe<HActivityQuestionSet_Bool_Exp>;
  newestVersion?: Maybe<HString_Comparison_Exp>;
  questions?: Maybe<HQuestion_Bool_Exp>;
  reference?: Maybe<HString_Comparison_Exp>;
};

/** Ordering options when selecting data from "QuestionSet". */
export type HQuestionSet_Order_By = {
  activityQuestionSets_aggregate?: Maybe<HActivityQuestionSet_Aggregate_Order_By>;
  newestVersion?: Maybe<HOrder_By>;
  questions_aggregate?: Maybe<HQuestion_Aggregate_Order_By>;
  reference?: Maybe<HOrder_By>;
};

/** select columns of table "QuestionSet" */
export enum HQuestionSet_Select_Column {
  /** column name */
  NewestVersion = 'newestVersion',
  /** column name */
  Reference = 'reference'
}

export enum HQuestionType_Enum {
  Decimal = 'DECIMAL',
  Integer = 'INTEGER',
  LongText = 'LONG_TEXT',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleCriteria = 'MULTIPLE_CRITERIA',
  Other = 'OTHER',
  ShortText = 'SHORT_TEXT',
  SingleChoice = 'SINGLE_CHOICE',
  SingleChoiceDropdown = 'SINGLE_CHOICE_DROPDOWN',
  YesNo = 'YES_NO',
  YesNoNa = 'YES_NO_NA',
  YesNoNaUnknown = 'YES_NO_NA_UNKNOWN'
}

/** Boolean expression to compare columns of type "QuestionType_enum". All fields are combined with logical 'AND'. */
export type HQuestionType_Enum_Comparison_Exp = {
  _eq?: Maybe<HQuestionType_Enum>;
  _in?: Maybe<Array<HQuestionType_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<HQuestionType_Enum>;
  _nin?: Maybe<Array<HQuestionType_Enum>>;
};

/** order by aggregate values of table "Question" */
export type HQuestion_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HQuestion_Max_Order_By>;
  min?: Maybe<HQuestion_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "Question". All fields are combined with a logical 'AND'. */
export type HQuestion_Bool_Exp = {
  Answers?: Maybe<HAnswer_Bool_Exp>;
  _and?: Maybe<Array<HQuestion_Bool_Exp>>;
  _not?: Maybe<HQuestion_Bool_Exp>;
  _or?: Maybe<Array<HQuestion_Bool_Exp>>;
  aboutDocumentation?: Maybe<HString_Comparison_Exp>;
  alignmentErrorMessage?: Maybe<HString_Comparison_Exp>;
  choices?: Maybe<HQuestionChoice_Bool_Exp>;
  dependencyDescription?: Maybe<HString_Comparison_Exp>;
  description?: Maybe<HString_Comparison_Exp>;
  documentationRequired?: Maybe<HBoolean_Comparison_Exp>;
  extendedDescription?: Maybe<HString_Comparison_Exp>;
  group?: Maybe<HString_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  isAligned?: Maybe<HString_Comparison_Exp>;
  isEditable?: Maybe<HString_Comparison_Exp>;
  isRequired?: Maybe<HString_Comparison_Exp>;
  isVisible?: Maybe<HString_Comparison_Exp>;
  level?: Maybe<HQuestionLevel_Enum_Comparison_Exp>;
  levelExpression?: Maybe<HString_Comparison_Exp>;
  objective?: Maybe<HObjective_Bool_Exp>;
  objectiveKey?: Maybe<HString_Comparison_Exp>;
  orderIndex?: Maybe<HString_Comparison_Exp>;
  other?: Maybe<HJsonb_Comparison_Exp>;
  questionSet?: Maybe<HQuestionSet_Bool_Exp>;
  questionSetRef?: Maybe<HString_Comparison_Exp>;
  questionSetVersion?: Maybe<HString_Comparison_Exp>;
  relevantDirectives?: Maybe<HString_Comparison_Exp>;
  resourceLink?: Maybe<HString_Comparison_Exp>;
  title?: Maybe<HString_Comparison_Exp>;
  type?: Maybe<HQuestionType_Enum_Comparison_Exp>;
  uniqueId?: Maybe<HString_Comparison_Exp>;
  unit?: Maybe<HString_Comparison_Exp>;
};

/** order by max() on columns of table "Question" */
export type HQuestion_Max_Order_By = {
  aboutDocumentation?: Maybe<HOrder_By>;
  alignmentErrorMessage?: Maybe<HOrder_By>;
  dependencyDescription?: Maybe<HOrder_By>;
  description?: Maybe<HOrder_By>;
  extendedDescription?: Maybe<HOrder_By>;
  group?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  isAligned?: Maybe<HOrder_By>;
  isEditable?: Maybe<HOrder_By>;
  isRequired?: Maybe<HOrder_By>;
  isVisible?: Maybe<HOrder_By>;
  levelExpression?: Maybe<HOrder_By>;
  objectiveKey?: Maybe<HOrder_By>;
  orderIndex?: Maybe<HOrder_By>;
  questionSetRef?: Maybe<HOrder_By>;
  questionSetVersion?: Maybe<HOrder_By>;
  relevantDirectives?: Maybe<HOrder_By>;
  resourceLink?: Maybe<HOrder_By>;
  title?: Maybe<HOrder_By>;
  uniqueId?: Maybe<HOrder_By>;
  unit?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "Question" */
export type HQuestion_Min_Order_By = {
  aboutDocumentation?: Maybe<HOrder_By>;
  alignmentErrorMessage?: Maybe<HOrder_By>;
  dependencyDescription?: Maybe<HOrder_By>;
  description?: Maybe<HOrder_By>;
  extendedDescription?: Maybe<HOrder_By>;
  group?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  isAligned?: Maybe<HOrder_By>;
  isEditable?: Maybe<HOrder_By>;
  isRequired?: Maybe<HOrder_By>;
  isVisible?: Maybe<HOrder_By>;
  levelExpression?: Maybe<HOrder_By>;
  objectiveKey?: Maybe<HOrder_By>;
  orderIndex?: Maybe<HOrder_By>;
  questionSetRef?: Maybe<HOrder_By>;
  questionSetVersion?: Maybe<HOrder_By>;
  relevantDirectives?: Maybe<HOrder_By>;
  resourceLink?: Maybe<HOrder_By>;
  title?: Maybe<HOrder_By>;
  uniqueId?: Maybe<HOrder_By>;
  unit?: Maybe<HOrder_By>;
};

/** Ordering options when selecting data from "Question". */
export type HQuestion_Order_By = {
  Answers_aggregate?: Maybe<HAnswer_Aggregate_Order_By>;
  aboutDocumentation?: Maybe<HOrder_By>;
  alignmentErrorMessage?: Maybe<HOrder_By>;
  choices_aggregate?: Maybe<HQuestionChoice_Aggregate_Order_By>;
  dependencyDescription?: Maybe<HOrder_By>;
  description?: Maybe<HOrder_By>;
  documentationRequired?: Maybe<HOrder_By>;
  extendedDescription?: Maybe<HOrder_By>;
  group?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  isAligned?: Maybe<HOrder_By>;
  isEditable?: Maybe<HOrder_By>;
  isRequired?: Maybe<HOrder_By>;
  isVisible?: Maybe<HOrder_By>;
  level?: Maybe<HOrder_By>;
  levelExpression?: Maybe<HOrder_By>;
  objective?: Maybe<HObjective_Order_By>;
  objectiveKey?: Maybe<HOrder_By>;
  orderIndex?: Maybe<HOrder_By>;
  other?: Maybe<HOrder_By>;
  questionSet?: Maybe<HQuestionSet_Order_By>;
  questionSetRef?: Maybe<HOrder_By>;
  questionSetVersion?: Maybe<HOrder_By>;
  relevantDirectives?: Maybe<HOrder_By>;
  resourceLink?: Maybe<HOrder_By>;
  title?: Maybe<HOrder_By>;
  type?: Maybe<HOrder_By>;
  uniqueId?: Maybe<HOrder_By>;
  unit?: Maybe<HOrder_By>;
};

/** select columns of table "Question" */
export enum HQuestion_Select_Column {
  /** column name */
  AboutDocumentation = 'aboutDocumentation',
  /** column name */
  AlignmentErrorMessage = 'alignmentErrorMessage',
  /** column name */
  DependencyDescription = 'dependencyDescription',
  /** column name */
  Description = 'description',
  /** column name */
  DocumentationRequired = 'documentationRequired',
  /** column name */
  ExtendedDescription = 'extendedDescription',
  /** column name */
  Group = 'group',
  /** column name */
  Id = 'id',
  /** column name */
  IsAligned = 'isAligned',
  /** column name */
  IsEditable = 'isEditable',
  /** column name */
  IsRequired = 'isRequired',
  /** column name */
  IsVisible = 'isVisible',
  /** column name */
  Level = 'level',
  /** column name */
  LevelExpression = 'levelExpression',
  /** column name */
  ObjectiveKey = 'objectiveKey',
  /** column name */
  OrderIndex = 'orderIndex',
  /** column name */
  Other = 'other',
  /** column name */
  QuestionSetRef = 'questionSetRef',
  /** column name */
  QuestionSetVersion = 'questionSetVersion',
  /** column name */
  RelevantDirectives = 'relevantDirectives',
  /** column name */
  ResourceLink = 'resourceLink',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UniqueId = 'uniqueId',
  /** column name */
  Unit = 'unit'
}

export type HRequestQuoteInput = {
  companyName: Scalars['String'];
  email: Scalars['String'];
  message: Scalars['String'];
  phoneNumber: Scalars['String'];
  taxonomyKnowledge: Scalars['String'];
  userEmail: Scalars['String'];
};

export type HRequestQuoteOutput = {
  message: Scalars['String'];
};

/** columns and relationships of "Sector" */
export type HSector = {
  /** An array relationship */
  activities: Array<HActivity>;
  name: Scalars['String'];
  reference: Scalars['String'];
};


/** columns and relationships of "Sector" */
export type HSectorActivitiesArgs = {
  distinct_on?: Maybe<Array<HActivity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivity_Order_By>>;
  where?: Maybe<HActivity_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "Sector". All fields are combined with a logical 'AND'. */
export type HSector_Bool_Exp = {
  _and?: Maybe<Array<HSector_Bool_Exp>>;
  _not?: Maybe<HSector_Bool_Exp>;
  _or?: Maybe<Array<HSector_Bool_Exp>>;
  activities?: Maybe<HActivity_Bool_Exp>;
  name?: Maybe<HString_Comparison_Exp>;
  reference?: Maybe<HString_Comparison_Exp>;
};

/** Ordering options when selecting data from "Sector". */
export type HSector_Order_By = {
  activities_aggregate?: Maybe<HActivity_Aggregate_Order_By>;
  name?: Maybe<HOrder_By>;
  reference?: Maybe<HOrder_By>;
};

/** select columns of table "Sector" */
export enum HSector_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Reference = 'reference'
}

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type HString_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "Tag" */
export type HTag = {
  /** An array relationship */
  Tag_articles: Array<HTag_Articles>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
};


/** columns and relationships of "Tag" */
export type HTagTag_ArticlesArgs = {
  distinct_on?: Maybe<Array<HTag_Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HTag_Articles_Order_By>>;
  where?: Maybe<HTag_Articles_Bool_Exp>;
};

/** columns and relationships of "Tag_articles" */
export type HTag_Articles = {
  /** An object relationship */
  Article: HArticle;
  /** An object relationship */
  Tag: HTag;
  article_id: Scalars['uuid'];
  id: Scalars['bigint'];
  tag_id: Scalars['uuid'];
};

/** order by aggregate values of table "Tag_articles" */
export type HTag_Articles_Aggregate_Order_By = {
  avg?: Maybe<HTag_Articles_Avg_Order_By>;
  count?: Maybe<HOrder_By>;
  max?: Maybe<HTag_Articles_Max_Order_By>;
  min?: Maybe<HTag_Articles_Min_Order_By>;
  stddev?: Maybe<HTag_Articles_Stddev_Order_By>;
  stddev_pop?: Maybe<HTag_Articles_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<HTag_Articles_Stddev_Samp_Order_By>;
  sum?: Maybe<HTag_Articles_Sum_Order_By>;
  var_pop?: Maybe<HTag_Articles_Var_Pop_Order_By>;
  var_samp?: Maybe<HTag_Articles_Var_Samp_Order_By>;
  variance?: Maybe<HTag_Articles_Variance_Order_By>;
};

/** order by avg() on columns of table "Tag_articles" */
export type HTag_Articles_Avg_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** Boolean expression to filter rows from the table "Tag_articles". All fields are combined with a logical 'AND'. */
export type HTag_Articles_Bool_Exp = {
  Article?: Maybe<HArticle_Bool_Exp>;
  Tag?: Maybe<HTag_Bool_Exp>;
  _and?: Maybe<Array<HTag_Articles_Bool_Exp>>;
  _not?: Maybe<HTag_Articles_Bool_Exp>;
  _or?: Maybe<Array<HTag_Articles_Bool_Exp>>;
  article_id?: Maybe<HUuid_Comparison_Exp>;
  id?: Maybe<HBigint_Comparison_Exp>;
  tag_id?: Maybe<HUuid_Comparison_Exp>;
};

/** order by max() on columns of table "Tag_articles" */
export type HTag_Articles_Max_Order_By = {
  article_id?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  tag_id?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "Tag_articles" */
export type HTag_Articles_Min_Order_By = {
  article_id?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  tag_id?: Maybe<HOrder_By>;
};

/** Ordering options when selecting data from "Tag_articles". */
export type HTag_Articles_Order_By = {
  Article?: Maybe<HArticle_Order_By>;
  Tag?: Maybe<HTag_Order_By>;
  article_id?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  tag_id?: Maybe<HOrder_By>;
};

/** select columns of table "Tag_articles" */
export enum HTag_Articles_Select_Column {
  /** column name */
  ArticleId = 'article_id',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id'
}

/** order by stddev() on columns of table "Tag_articles" */
export type HTag_Articles_Stddev_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by stddev_pop() on columns of table "Tag_articles" */
export type HTag_Articles_Stddev_Pop_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by stddev_samp() on columns of table "Tag_articles" */
export type HTag_Articles_Stddev_Samp_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by sum() on columns of table "Tag_articles" */
export type HTag_Articles_Sum_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by var_pop() on columns of table "Tag_articles" */
export type HTag_Articles_Var_Pop_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by var_samp() on columns of table "Tag_articles" */
export type HTag_Articles_Var_Samp_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** order by variance() on columns of table "Tag_articles" */
export type HTag_Articles_Variance_Order_By = {
  id?: Maybe<HOrder_By>;
};

/** Boolean expression to filter rows from the table "Tag". All fields are combined with a logical 'AND'. */
export type HTag_Bool_Exp = {
  Tag_articles?: Maybe<HTag_Articles_Bool_Exp>;
  _and?: Maybe<Array<HTag_Bool_Exp>>;
  _not?: Maybe<HTag_Bool_Exp>;
  _or?: Maybe<Array<HTag_Bool_Exp>>;
  description?: Maybe<HString_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  name?: Maybe<HString_Comparison_Exp>;
};

/** Ordering options when selecting data from "Tag". */
export type HTag_Order_By = {
  Tag_articles_aggregate?: Maybe<HTag_Articles_Aggregate_Order_By>;
  description?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  name?: Maybe<HOrder_By>;
};

/** select columns of table "Tag" */
export enum HTag_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** columns and relationships of "Thread" */
export type HThread = {
  /** An object relationship */
  answer?: Maybe<HAnswer>;
  answerId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  comments: Array<HComment>;
  /** An aggregate relationship */
  comments_aggregate: HComment_Aggregate;
  /** An object relationship */
  company: HCompany;
  companyId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  subscribers: Array<HThreadSubscriber>;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "Thread" */
export type HThreadCommentsArgs = {
  distinct_on?: Maybe<Array<HComment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HComment_Order_By>>;
  where?: Maybe<HComment_Bool_Exp>;
};


/** columns and relationships of "Thread" */
export type HThreadComments_AggregateArgs = {
  distinct_on?: Maybe<Array<HComment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HComment_Order_By>>;
  where?: Maybe<HComment_Bool_Exp>;
};


/** columns and relationships of "Thread" */
export type HThreadSubscribersArgs = {
  distinct_on?: Maybe<Array<HThreadSubscriber_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HThreadSubscriber_Order_By>>;
  where?: Maybe<HThreadSubscriber_Bool_Exp>;
};

/** columns and relationships of "ThreadSubscriber" */
export type HThreadSubscriber = {
  id: Scalars['uuid'];
  isSubscribed: Scalars['Boolean'];
  /** An object relationship */
  thread: HThread;
  threadId: Scalars['uuid'];
  /** An object relationship */
  user: HUsers;
  userId: Scalars['uuid'];
};

/** order by aggregate values of table "ThreadSubscriber" */
export type HThreadSubscriber_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HThreadSubscriber_Max_Order_By>;
  min?: Maybe<HThreadSubscriber_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ThreadSubscriber" */
export type HThreadSubscriber_Arr_Rel_Insert_Input = {
  data: Array<HThreadSubscriber_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HThreadSubscriber_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ThreadSubscriber". All fields are combined with a logical 'AND'. */
export type HThreadSubscriber_Bool_Exp = {
  _and?: Maybe<Array<HThreadSubscriber_Bool_Exp>>;
  _not?: Maybe<HThreadSubscriber_Bool_Exp>;
  _or?: Maybe<Array<HThreadSubscriber_Bool_Exp>>;
  id?: Maybe<HUuid_Comparison_Exp>;
  isSubscribed?: Maybe<HBoolean_Comparison_Exp>;
  thread?: Maybe<HThread_Bool_Exp>;
  threadId?: Maybe<HUuid_Comparison_Exp>;
  user?: Maybe<HUsers_Bool_Exp>;
  userId?: Maybe<HUuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ThreadSubscriber" */
export enum HThreadSubscriber_Constraint {
  /** unique or primary key constraint */
  ThreadSubscriberPkey = 'ThreadSubscriber_pkey',
  /** unique or primary key constraint */
  ThreadSubscriberThreadIdUserIdC1ed612aUniq = 'ThreadSubscriber_threadId_userId_c1ed612a_uniq'
}

/** input type for inserting data into table "ThreadSubscriber" */
export type HThreadSubscriber_Insert_Input = {
  thread?: Maybe<HThread_Obj_Rel_Insert_Input>;
  threadId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ThreadSubscriber" */
export type HThreadSubscriber_Max_Order_By = {
  id?: Maybe<HOrder_By>;
  threadId?: Maybe<HOrder_By>;
  userId?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "ThreadSubscriber" */
export type HThreadSubscriber_Min_Order_By = {
  id?: Maybe<HOrder_By>;
  threadId?: Maybe<HOrder_By>;
  userId?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "ThreadSubscriber" */
export type HThreadSubscriber_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HThreadSubscriber>;
};

/** on conflict condition type for table "ThreadSubscriber" */
export type HThreadSubscriber_On_Conflict = {
  constraint: HThreadSubscriber_Constraint;
  update_columns?: Array<HThreadSubscriber_Update_Column>;
  where?: Maybe<HThreadSubscriber_Bool_Exp>;
};

/** Ordering options when selecting data from "ThreadSubscriber". */
export type HThreadSubscriber_Order_By = {
  id?: Maybe<HOrder_By>;
  isSubscribed?: Maybe<HOrder_By>;
  thread?: Maybe<HThread_Order_By>;
  threadId?: Maybe<HOrder_By>;
  user?: Maybe<HUsers_Order_By>;
  userId?: Maybe<HOrder_By>;
};

/** primary key columns input for table: ThreadSubscriber */
export type HThreadSubscriber_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ThreadSubscriber" */
export enum HThreadSubscriber_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IsSubscribed = 'isSubscribed',
  /** column name */
  ThreadId = 'threadId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "ThreadSubscriber" */
export type HThreadSubscriber_Set_Input = {
  isSubscribed?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "ThreadSubscriber" */
export enum HThreadSubscriber_Update_Column {
  /** column name */
  IsSubscribed = 'isSubscribed'
}

/** order by aggregate values of table "Thread" */
export type HThread_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HThread_Max_Order_By>;
  min?: Maybe<HThread_Min_Order_By>;
};

/** input type for inserting array relation for remote table "Thread" */
export type HThread_Arr_Rel_Insert_Input = {
  data: Array<HThread_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HThread_On_Conflict>;
};

/** Boolean expression to filter rows from the table "Thread". All fields are combined with a logical 'AND'. */
export type HThread_Bool_Exp = {
  _and?: Maybe<Array<HThread_Bool_Exp>>;
  _not?: Maybe<HThread_Bool_Exp>;
  _or?: Maybe<Array<HThread_Bool_Exp>>;
  answer?: Maybe<HAnswer_Bool_Exp>;
  answerId?: Maybe<HUuid_Comparison_Exp>;
  comments?: Maybe<HComment_Bool_Exp>;
  company?: Maybe<HCompany_Bool_Exp>;
  companyId?: Maybe<HUuid_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  subscribers?: Maybe<HThreadSubscriber_Bool_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Thread" */
export enum HThread_Constraint {
  /** unique or primary key constraint */
  ThreadAnswerIdKey = 'Thread_answerId_key',
  /** unique or primary key constraint */
  ThreadPkey = 'Thread_pkey'
}

/** input type for inserting data into table "Thread" */
export type HThread_Insert_Input = {
  answer?: Maybe<HAnswer_Obj_Rel_Insert_Input>;
  answerId?: Maybe<Scalars['uuid']>;
  comments?: Maybe<HComment_Arr_Rel_Insert_Input>;
  company?: Maybe<HCompany_Obj_Rel_Insert_Input>;
  companyId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  subscribers?: Maybe<HThreadSubscriber_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "Thread" */
export type HThread_Max_Order_By = {
  answerId?: Maybe<HOrder_By>;
  companyId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** order by min() on columns of table "Thread" */
export type HThread_Min_Order_By = {
  answerId?: Maybe<HOrder_By>;
  companyId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "Thread" */
export type HThread_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HThread>;
};

/** input type for inserting object relation for remote table "Thread" */
export type HThread_Obj_Rel_Insert_Input = {
  data: HThread_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<HThread_On_Conflict>;
};

/** on conflict condition type for table "Thread" */
export type HThread_On_Conflict = {
  constraint: HThread_Constraint;
  update_columns?: Array<HThread_Update_Column>;
  where?: Maybe<HThread_Bool_Exp>;
};

/** Ordering options when selecting data from "Thread". */
export type HThread_Order_By = {
  answer?: Maybe<HAnswer_Order_By>;
  answerId?: Maybe<HOrder_By>;
  comments_aggregate?: Maybe<HComment_Aggregate_Order_By>;
  company?: Maybe<HCompany_Order_By>;
  companyId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  subscribers_aggregate?: Maybe<HThreadSubscriber_Aggregate_Order_By>;
  updatedAt?: Maybe<HOrder_By>;
};

/** primary key columns input for table: Thread */
export type HThread_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Thread" */
export enum HThread_Select_Column {
  /** column name */
  AnswerId = 'answerId',
  /** column name */
  CompanyId = 'companyId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updatedAt'
}

/** input type for updating data in table "Thread" */
export type HThread_Set_Input = {
  answerId?: Maybe<Scalars['uuid']>;
  companyId?: Maybe<Scalars['uuid']>;
};

/** update columns of table "Thread" */
export enum HThread_Update_Column {
  /** column name */
  AnswerId = 'answerId',
  /** column name */
  CompanyId = 'companyId'
}

/** columns and relationships of "UserNotification" */
export type HUserNotification = {
  /** An object relationship */
  comment: HComment;
  commentId: Scalars['uuid'];
  createdAt: Scalars['timestamptz'];
  id: Scalars['uuid'];
  isRead: Scalars['Boolean'];
  updatedAt: Scalars['timestamptz'];
  /** An object relationship */
  user: HUsers;
  userId: Scalars['uuid'];
};

/** aggregated selection of "UserNotification" */
export type HUserNotification_Aggregate = {
  aggregate?: Maybe<HUserNotification_Aggregate_Fields>;
  nodes: Array<HUserNotification>;
};

/** aggregate fields of "UserNotification" */
export type HUserNotification_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<HUserNotification_Max_Fields>;
  min?: Maybe<HUserNotification_Min_Fields>;
};


/** aggregate fields of "UserNotification" */
export type HUserNotification_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<HUserNotification_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "UserNotification" */
export type HUserNotification_Aggregate_Order_By = {
  count?: Maybe<HOrder_By>;
  max?: Maybe<HUserNotification_Max_Order_By>;
  min?: Maybe<HUserNotification_Min_Order_By>;
};

/** input type for inserting array relation for remote table "UserNotification" */
export type HUserNotification_Arr_Rel_Insert_Input = {
  data: Array<HUserNotification_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<HUserNotification_On_Conflict>;
};

/** Boolean expression to filter rows from the table "UserNotification". All fields are combined with a logical 'AND'. */
export type HUserNotification_Bool_Exp = {
  _and?: Maybe<Array<HUserNotification_Bool_Exp>>;
  _not?: Maybe<HUserNotification_Bool_Exp>;
  _or?: Maybe<Array<HUserNotification_Bool_Exp>>;
  comment?: Maybe<HComment_Bool_Exp>;
  commentId?: Maybe<HUuid_Comparison_Exp>;
  createdAt?: Maybe<HTimestamptz_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  isRead?: Maybe<HBoolean_Comparison_Exp>;
  updatedAt?: Maybe<HTimestamptz_Comparison_Exp>;
  user?: Maybe<HUsers_Bool_Exp>;
  userId?: Maybe<HUuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserNotification" */
export enum HUserNotification_Constraint {
  /** unique or primary key constraint */
  UserNotificationPkey = 'UserNotification_pkey'
}

/** input type for inserting data into table "UserNotification" */
export type HUserNotification_Insert_Input = {
  comment?: Maybe<HComment_Obj_Rel_Insert_Input>;
  commentId?: Maybe<Scalars['uuid']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type HUserNotification_Max_Fields = {
  commentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "UserNotification" */
export type HUserNotification_Max_Order_By = {
  commentId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
  userId?: Maybe<HOrder_By>;
};

/** aggregate min on columns */
export type HUserNotification_Min_Fields = {
  commentId?: Maybe<Scalars['uuid']>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updatedAt?: Maybe<Scalars['timestamptz']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "UserNotification" */
export type HUserNotification_Min_Order_By = {
  commentId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
  userId?: Maybe<HOrder_By>;
};

/** response of any mutation on the table "UserNotification" */
export type HUserNotification_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HUserNotification>;
};

/** on conflict condition type for table "UserNotification" */
export type HUserNotification_On_Conflict = {
  constraint: HUserNotification_Constraint;
  update_columns?: Array<HUserNotification_Update_Column>;
  where?: Maybe<HUserNotification_Bool_Exp>;
};

/** Ordering options when selecting data from "UserNotification". */
export type HUserNotification_Order_By = {
  comment?: Maybe<HComment_Order_By>;
  commentId?: Maybe<HOrder_By>;
  createdAt?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  isRead?: Maybe<HOrder_By>;
  updatedAt?: Maybe<HOrder_By>;
  user?: Maybe<HUsers_Order_By>;
  userId?: Maybe<HOrder_By>;
};

/** primary key columns input for table: UserNotification */
export type HUserNotification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "UserNotification" */
export enum HUserNotification_Select_Column {
  /** column name */
  CommentId = 'commentId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'isRead',
  /** column name */
  UpdatedAt = 'updatedAt',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "UserNotification" */
export type HUserNotification_Set_Input = {
  isRead?: Maybe<Scalars['Boolean']>;
};

/** update columns of table "UserNotification" */
export enum HUserNotification_Update_Column {
  /** column name */
  IsRead = 'isRead'
}

export enum HUserRole_Enum {
  /** Administrator rights to a company. Can manage users and their roles in the company. */
  Admin = 'ADMIN',
  /** Can view reports. Including financials and answers to question, and can edit the audit status. */
  Auditor = 'AUDITOR',
  /** Can create and edit reports, including financials and answers to questions. */
  Editor = 'EDITOR',
  User = 'USER',
  /** Can view reports. Including financials and answers to question, and audit status. */
  Viewer = 'VIEWER'
}

/** Boolean expression to compare columns of type "UserRole_enum". All fields are combined with logical 'AND'. */
export type HUserRole_Enum_Comparison_Exp = {
  _eq?: Maybe<HUserRole_Enum>;
  _in?: Maybe<Array<HUserRole_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<HUserRole_Enum>;
  _nin?: Maybe<Array<HUserRole_Enum>>;
};

/** columns and relationships of "auth.accounts" */
export type HAuth_Accounts = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
  email?: Maybe<Scalars['citext']>;
  /** An object relationship */
  user: HUsers;
};


/** columns and relationships of "auth.accounts" */
export type HAuth_AccountsCustom_Register_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "auth.accounts". All fields are combined with a logical 'AND'. */
export type HAuth_Accounts_Bool_Exp = {
  _and?: Maybe<Array<HAuth_Accounts_Bool_Exp>>;
  _not?: Maybe<HAuth_Accounts_Bool_Exp>;
  _or?: Maybe<Array<HAuth_Accounts_Bool_Exp>>;
  custom_register_data?: Maybe<HJsonb_Comparison_Exp>;
  email?: Maybe<HCitext_Comparison_Exp>;
  user?: Maybe<HUsers_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.accounts". */
export type HAuth_Accounts_Order_By = {
  custom_register_data?: Maybe<HOrder_By>;
  email?: Maybe<HOrder_By>;
  user?: Maybe<HUsers_Order_By>;
};

/** select columns of table "auth.accounts" */
export enum HAuth_Accounts_Select_Column {
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  Email = 'email'
}

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type HBigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type HCitext_Comparison_Exp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['citext']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['citext']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['citext']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['citext']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['citext']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type HDate_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type HFloat8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type HJsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type HMutation_Root = {
  /** delete data from the table: "AnswerAttachment" */
  delete_AnswerAttachment?: Maybe<HAnswerAttachment_Mutation_Response>;
  /** delete single row from the table: "AnswerAttachment" */
  delete_AnswerAttachment_by_pk?: Maybe<HAnswerAttachment>;
  /** delete data from the table: "AnswerNote" */
  delete_AnswerNote?: Maybe<HAnswerNote_Mutation_Response>;
  /** delete single row from the table: "AnswerNote" */
  delete_AnswerNote_by_pk?: Maybe<HAnswerNote>;
  /** delete data from the table: "BusinessUnitDistributionKey" */
  delete_BusinessUnitDistributionKey?: Maybe<HBusinessUnitDistributionKey_Mutation_Response>;
  /** delete single row from the table: "BusinessUnitDistributionKey" */
  delete_BusinessUnitDistributionKey_by_pk?: Maybe<HBusinessUnitDistributionKey>;
  /** delete data from the table: "Comment" */
  delete_Comment?: Maybe<HComment_Mutation_Response>;
  /** delete single row from the table: "Comment" */
  delete_Comment_by_pk?: Maybe<HComment>;
  /** delete data from the table: "File" */
  delete_File?: Maybe<HFile_Mutation_Response>;
  /** delete single row from the table: "File" */
  delete_File_by_pk?: Maybe<HFile>;
  /** delete data from the table: "PortfolioCompany" */
  delete_PortfolioCompany?: Maybe<HPortfolioCompany_Mutation_Response>;
  /** delete single row from the table: "PortfolioCompany" */
  delete_PortfolioCompany_by_pk?: Maybe<HPortfolioCompany>;
  /** delete data from the table: "ThreadSubscriber" */
  delete_ThreadSubscriber?: Maybe<HThreadSubscriber_Mutation_Response>;
  /** delete single row from the table: "ThreadSubscriber" */
  delete_ThreadSubscriber_by_pk?: Maybe<HThreadSubscriber>;
  /** insert data into the table: "ActivityReport" */
  insert_ActivityReport?: Maybe<HActivityReport_Mutation_Response>;
  /** insert a single row into the table: "ActivityReport" */
  insert_ActivityReport_one?: Maybe<HActivityReport>;
  /** insert data into the table: "Answer" */
  insert_Answer?: Maybe<HAnswer_Mutation_Response>;
  /** insert data into the table: "AnswerAttachment" */
  insert_AnswerAttachment?: Maybe<HAnswerAttachment_Mutation_Response>;
  /** insert a single row into the table: "AnswerAttachment" */
  insert_AnswerAttachment_one?: Maybe<HAnswerAttachment>;
  /** insert data into the table: "AnswerNote" */
  insert_AnswerNote?: Maybe<HAnswerNote_Mutation_Response>;
  /** insert a single row into the table: "AnswerNote" */
  insert_AnswerNote_one?: Maybe<HAnswerNote>;
  /** insert a single row into the table: "Answer" */
  insert_Answer_one?: Maybe<HAnswer>;
  /** insert data into the table: "BusinessUnit" */
  insert_BusinessUnit?: Maybe<HBusinessUnit_Mutation_Response>;
  /** insert data into the table: "BusinessUnitAssessment" */
  insert_BusinessUnitAssessment?: Maybe<HBusinessUnitAssessment_Mutation_Response>;
  /** insert a single row into the table: "BusinessUnitAssessment" */
  insert_BusinessUnitAssessment_one?: Maybe<HBusinessUnitAssessment>;
  /** insert data into the table: "BusinessUnitDistributionKey" */
  insert_BusinessUnitDistributionKey?: Maybe<HBusinessUnitDistributionKey_Mutation_Response>;
  /** insert a single row into the table: "BusinessUnitDistributionKey" */
  insert_BusinessUnitDistributionKey_one?: Maybe<HBusinessUnitDistributionKey>;
  /** insert a single row into the table: "BusinessUnit" */
  insert_BusinessUnit_one?: Maybe<HBusinessUnit>;
  /** insert data into the table: "Comment" */
  insert_Comment?: Maybe<HComment_Mutation_Response>;
  /** insert a single row into the table: "Comment" */
  insert_Comment_one?: Maybe<HComment>;
  /** insert data into the table: "Company" */
  insert_Company?: Maybe<HCompany_Mutation_Response>;
  /** insert data into the table: "CompanyAssessment" */
  insert_CompanyAssessment?: Maybe<HCompanyAssessment_Mutation_Response>;
  /** insert data into the table: "CompanyAssessmentAggregate" */
  insert_CompanyAssessmentAggregate?: Maybe<HCompanyAssessmentAggregate_Mutation_Response>;
  /** insert a single row into the table: "CompanyAssessmentAggregate" */
  insert_CompanyAssessmentAggregate_one?: Maybe<HCompanyAssessmentAggregate>;
  /** insert a single row into the table: "CompanyAssessment" */
  insert_CompanyAssessment_one?: Maybe<HCompanyAssessment>;
  /** insert data into the table: "CompanyInvitation" */
  insert_CompanyInvitation?: Maybe<HCompanyInvitation_Mutation_Response>;
  /** insert a single row into the table: "CompanyInvitation" */
  insert_CompanyInvitation_one?: Maybe<HCompanyInvitation>;
  /** insert data into the table: "CompanyUser" */
  insert_CompanyUser?: Maybe<HCompanyUser_Mutation_Response>;
  /** insert a single row into the table: "CompanyUser" */
  insert_CompanyUser_one?: Maybe<HCompanyUser>;
  /** insert a single row into the table: "Company" */
  insert_Company_one?: Maybe<HCompany>;
  /** insert data into the table: "File" */
  insert_File?: Maybe<HFile_Mutation_Response>;
  /** insert a single row into the table: "File" */
  insert_File_one?: Maybe<HFile>;
  /** insert data into the table: "Financials" */
  insert_Financials?: Maybe<HFinancials_Mutation_Response>;
  /** insert a single row into the table: "Financials" */
  insert_Financials_one?: Maybe<HFinancials>;
  /** insert data into the table: "Portfolio" */
  insert_Portfolio?: Maybe<HPortfolio_Mutation_Response>;
  /** insert data into the table: "PortfolioCompany" */
  insert_PortfolioCompany?: Maybe<HPortfolioCompany_Mutation_Response>;
  /** insert a single row into the table: "PortfolioCompany" */
  insert_PortfolioCompany_one?: Maybe<HPortfolioCompany>;
  /** insert a single row into the table: "Portfolio" */
  insert_Portfolio_one?: Maybe<HPortfolio>;
  /** insert data into the table: "Thread" */
  insert_Thread?: Maybe<HThread_Mutation_Response>;
  /** insert data into the table: "ThreadSubscriber" */
  insert_ThreadSubscriber?: Maybe<HThreadSubscriber_Mutation_Response>;
  /** insert a single row into the table: "ThreadSubscriber" */
  insert_ThreadSubscriber_one?: Maybe<HThreadSubscriber>;
  /** insert a single row into the table: "Thread" */
  insert_Thread_one?: Maybe<HThread>;
  /** insert data into the table: "UserNotification" */
  insert_UserNotification?: Maybe<HUserNotification_Mutation_Response>;
  /** insert a single row into the table: "UserNotification" */
  insert_UserNotification_one?: Maybe<HUserNotification>;
  nudgePortfolioCompanies?: Maybe<HNudgePortfolioCompaniesOutput>;
  requestQuote?: Maybe<HRequestQuoteOutput>;
  /** update data of the table: "ActivityReport" */
  update_ActivityReport?: Maybe<HActivityReport_Mutation_Response>;
  /** update single row of the table: "ActivityReport" */
  update_ActivityReport_by_pk?: Maybe<HActivityReport>;
  /** update data of the table: "Answer" */
  update_Answer?: Maybe<HAnswer_Mutation_Response>;
  /** update data of the table: "AnswerAttachment" */
  update_AnswerAttachment?: Maybe<HAnswerAttachment_Mutation_Response>;
  /** update single row of the table: "AnswerAttachment" */
  update_AnswerAttachment_by_pk?: Maybe<HAnswerAttachment>;
  /** update single row of the table: "Answer" */
  update_Answer_by_pk?: Maybe<HAnswer>;
  /** update data of the table: "BusinessUnit" */
  update_BusinessUnit?: Maybe<HBusinessUnit_Mutation_Response>;
  /** update data of the table: "BusinessUnitAssessment" */
  update_BusinessUnitAssessment?: Maybe<HBusinessUnitAssessment_Mutation_Response>;
  /** update single row of the table: "BusinessUnitAssessment" */
  update_BusinessUnitAssessment_by_pk?: Maybe<HBusinessUnitAssessment>;
  /** update data of the table: "BusinessUnitDistributionKey" */
  update_BusinessUnitDistributionKey?: Maybe<HBusinessUnitDistributionKey_Mutation_Response>;
  /** update single row of the table: "BusinessUnitDistributionKey" */
  update_BusinessUnitDistributionKey_by_pk?: Maybe<HBusinessUnitDistributionKey>;
  /** update single row of the table: "BusinessUnit" */
  update_BusinessUnit_by_pk?: Maybe<HBusinessUnit>;
  /** update data of the table: "Comment" */
  update_Comment?: Maybe<HComment_Mutation_Response>;
  /** update single row of the table: "Comment" */
  update_Comment_by_pk?: Maybe<HComment>;
  /** update data of the table: "Company" */
  update_Company?: Maybe<HCompany_Mutation_Response>;
  /** update data of the table: "CompanyAssessment" */
  update_CompanyAssessment?: Maybe<HCompanyAssessment_Mutation_Response>;
  /** update data of the table: "CompanyAssessmentAggregate" */
  update_CompanyAssessmentAggregate?: Maybe<HCompanyAssessmentAggregate_Mutation_Response>;
  /** update single row of the table: "CompanyAssessmentAggregate" */
  update_CompanyAssessmentAggregate_by_pk?: Maybe<HCompanyAssessmentAggregate>;
  /** update single row of the table: "CompanyAssessment" */
  update_CompanyAssessment_by_pk?: Maybe<HCompanyAssessment>;
  /** update data of the table: "CompanyInvitation" */
  update_CompanyInvitation?: Maybe<HCompanyInvitation_Mutation_Response>;
  /** update single row of the table: "CompanyInvitation" */
  update_CompanyInvitation_by_pk?: Maybe<HCompanyInvitation>;
  /** update data of the table: "CompanyUser" */
  update_CompanyUser?: Maybe<HCompanyUser_Mutation_Response>;
  /** update single row of the table: "CompanyUser" */
  update_CompanyUser_by_pk?: Maybe<HCompanyUser>;
  /** update single row of the table: "Company" */
  update_Company_by_pk?: Maybe<HCompany>;
  /** update data of the table: "File" */
  update_File?: Maybe<HFile_Mutation_Response>;
  /** update single row of the table: "File" */
  update_File_by_pk?: Maybe<HFile>;
  /** update data of the table: "Financials" */
  update_Financials?: Maybe<HFinancials_Mutation_Response>;
  /** update single row of the table: "Financials" */
  update_Financials_by_pk?: Maybe<HFinancials>;
  /** update data of the table: "Portfolio" */
  update_Portfolio?: Maybe<HPortfolio_Mutation_Response>;
  /** update data of the table: "PortfolioCompany" */
  update_PortfolioCompany?: Maybe<HPortfolioCompany_Mutation_Response>;
  /** update single row of the table: "PortfolioCompany" */
  update_PortfolioCompany_by_pk?: Maybe<HPortfolioCompany>;
  /** update single row of the table: "Portfolio" */
  update_Portfolio_by_pk?: Maybe<HPortfolio>;
  /** update data of the table: "Thread" */
  update_Thread?: Maybe<HThread_Mutation_Response>;
  /** update data of the table: "ThreadSubscriber" */
  update_ThreadSubscriber?: Maybe<HThreadSubscriber_Mutation_Response>;
  /** update single row of the table: "ThreadSubscriber" */
  update_ThreadSubscriber_by_pk?: Maybe<HThreadSubscriber>;
  /** update single row of the table: "Thread" */
  update_Thread_by_pk?: Maybe<HThread>;
  /** update data of the table: "UserNotification" */
  update_UserNotification?: Maybe<HUserNotification_Mutation_Response>;
  /** update single row of the table: "UserNotification" */
  update_UserNotification_by_pk?: Maybe<HUserNotification>;
  /** update data of the table: "users" */
  update_users?: Maybe<HUsers_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<HUsers>;
};


/** mutation root */
export type HMutation_RootDelete_AnswerAttachmentArgs = {
  where: HAnswerAttachment_Bool_Exp;
};


/** mutation root */
export type HMutation_RootDelete_AnswerAttachment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type HMutation_RootDelete_AnswerNoteArgs = {
  where: HAnswerNote_Bool_Exp;
};


/** mutation root */
export type HMutation_RootDelete_AnswerNote_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type HMutation_RootDelete_BusinessUnitDistributionKeyArgs = {
  where: HBusinessUnitDistributionKey_Bool_Exp;
};


/** mutation root */
export type HMutation_RootDelete_BusinessUnitDistributionKey_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type HMutation_RootDelete_CommentArgs = {
  where: HComment_Bool_Exp;
};


/** mutation root */
export type HMutation_RootDelete_Comment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type HMutation_RootDelete_FileArgs = {
  where: HFile_Bool_Exp;
};


/** mutation root */
export type HMutation_RootDelete_File_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type HMutation_RootDelete_PortfolioCompanyArgs = {
  where: HPortfolioCompany_Bool_Exp;
};


/** mutation root */
export type HMutation_RootDelete_PortfolioCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type HMutation_RootDelete_ThreadSubscriberArgs = {
  where: HThreadSubscriber_Bool_Exp;
};


/** mutation root */
export type HMutation_RootDelete_ThreadSubscriber_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type HMutation_RootInsert_ActivityReportArgs = {
  objects: Array<HActivityReport_Insert_Input>;
  on_conflict?: Maybe<HActivityReport_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_ActivityReport_OneArgs = {
  object: HActivityReport_Insert_Input;
  on_conflict?: Maybe<HActivityReport_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_AnswerArgs = {
  objects: Array<HAnswer_Insert_Input>;
  on_conflict?: Maybe<HAnswer_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_AnswerAttachmentArgs = {
  objects: Array<HAnswerAttachment_Insert_Input>;
  on_conflict?: Maybe<HAnswerAttachment_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_AnswerAttachment_OneArgs = {
  object: HAnswerAttachment_Insert_Input;
  on_conflict?: Maybe<HAnswerAttachment_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_AnswerNoteArgs = {
  objects: Array<HAnswerNote_Insert_Input>;
};


/** mutation root */
export type HMutation_RootInsert_AnswerNote_OneArgs = {
  object: HAnswerNote_Insert_Input;
};


/** mutation root */
export type HMutation_RootInsert_Answer_OneArgs = {
  object: HAnswer_Insert_Input;
  on_conflict?: Maybe<HAnswer_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_BusinessUnitArgs = {
  objects: Array<HBusinessUnit_Insert_Input>;
  on_conflict?: Maybe<HBusinessUnit_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_BusinessUnitAssessmentArgs = {
  objects: Array<HBusinessUnitAssessment_Insert_Input>;
  on_conflict?: Maybe<HBusinessUnitAssessment_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_BusinessUnitAssessment_OneArgs = {
  object: HBusinessUnitAssessment_Insert_Input;
  on_conflict?: Maybe<HBusinessUnitAssessment_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_BusinessUnitDistributionKeyArgs = {
  objects: Array<HBusinessUnitDistributionKey_Insert_Input>;
  on_conflict?: Maybe<HBusinessUnitDistributionKey_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_BusinessUnitDistributionKey_OneArgs = {
  object: HBusinessUnitDistributionKey_Insert_Input;
  on_conflict?: Maybe<HBusinessUnitDistributionKey_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_BusinessUnit_OneArgs = {
  object: HBusinessUnit_Insert_Input;
  on_conflict?: Maybe<HBusinessUnit_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_CommentArgs = {
  objects: Array<HComment_Insert_Input>;
  on_conflict?: Maybe<HComment_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_Comment_OneArgs = {
  object: HComment_Insert_Input;
  on_conflict?: Maybe<HComment_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_CompanyArgs = {
  objects: Array<HCompany_Insert_Input>;
  on_conflict?: Maybe<HCompany_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_CompanyAssessmentArgs = {
  objects: Array<HCompanyAssessment_Insert_Input>;
  on_conflict?: Maybe<HCompanyAssessment_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_CompanyAssessmentAggregateArgs = {
  objects: Array<HCompanyAssessmentAggregate_Insert_Input>;
  on_conflict?: Maybe<HCompanyAssessmentAggregate_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_CompanyAssessmentAggregate_OneArgs = {
  object: HCompanyAssessmentAggregate_Insert_Input;
  on_conflict?: Maybe<HCompanyAssessmentAggregate_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_CompanyAssessment_OneArgs = {
  object: HCompanyAssessment_Insert_Input;
  on_conflict?: Maybe<HCompanyAssessment_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_CompanyInvitationArgs = {
  objects: Array<HCompanyInvitation_Insert_Input>;
  on_conflict?: Maybe<HCompanyInvitation_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_CompanyInvitation_OneArgs = {
  object: HCompanyInvitation_Insert_Input;
  on_conflict?: Maybe<HCompanyInvitation_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_CompanyUserArgs = {
  objects: Array<HCompanyUser_Insert_Input>;
  on_conflict?: Maybe<HCompanyUser_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_CompanyUser_OneArgs = {
  object: HCompanyUser_Insert_Input;
  on_conflict?: Maybe<HCompanyUser_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_Company_OneArgs = {
  object: HCompany_Insert_Input;
  on_conflict?: Maybe<HCompany_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_FileArgs = {
  objects: Array<HFile_Insert_Input>;
  on_conflict?: Maybe<HFile_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_File_OneArgs = {
  object: HFile_Insert_Input;
  on_conflict?: Maybe<HFile_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_FinancialsArgs = {
  objects: Array<HFinancials_Insert_Input>;
  on_conflict?: Maybe<HFinancials_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_Financials_OneArgs = {
  object: HFinancials_Insert_Input;
  on_conflict?: Maybe<HFinancials_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_PortfolioArgs = {
  objects: Array<HPortfolio_Insert_Input>;
  on_conflict?: Maybe<HPortfolio_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_PortfolioCompanyArgs = {
  objects: Array<HPortfolioCompany_Insert_Input>;
  on_conflict?: Maybe<HPortfolioCompany_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_PortfolioCompany_OneArgs = {
  object: HPortfolioCompany_Insert_Input;
  on_conflict?: Maybe<HPortfolioCompany_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_Portfolio_OneArgs = {
  object: HPortfolio_Insert_Input;
  on_conflict?: Maybe<HPortfolio_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_ThreadArgs = {
  objects: Array<HThread_Insert_Input>;
  on_conflict?: Maybe<HThread_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_ThreadSubscriberArgs = {
  objects: Array<HThreadSubscriber_Insert_Input>;
  on_conflict?: Maybe<HThreadSubscriber_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_ThreadSubscriber_OneArgs = {
  object: HThreadSubscriber_Insert_Input;
  on_conflict?: Maybe<HThreadSubscriber_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_Thread_OneArgs = {
  object: HThread_Insert_Input;
  on_conflict?: Maybe<HThread_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_UserNotificationArgs = {
  objects: Array<HUserNotification_Insert_Input>;
  on_conflict?: Maybe<HUserNotification_On_Conflict>;
};


/** mutation root */
export type HMutation_RootInsert_UserNotification_OneArgs = {
  object: HUserNotification_Insert_Input;
  on_conflict?: Maybe<HUserNotification_On_Conflict>;
};


/** mutation root */
export type HMutation_RootNudgePortfolioCompaniesArgs = {
  data: HNudgePortfolioCompaniesInput;
  recipients: Array<HEmailItem>;
};


/** mutation root */
export type HMutation_RootRequestQuoteArgs = {
  data: HRequestQuoteInput;
};


/** mutation root */
export type HMutation_RootUpdate_ActivityReportArgs = {
  _set?: Maybe<HActivityReport_Set_Input>;
  where: HActivityReport_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_ActivityReport_By_PkArgs = {
  _set?: Maybe<HActivityReport_Set_Input>;
  pk_columns: HActivityReport_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_AnswerArgs = {
  _append?: Maybe<HAnswer_Append_Input>;
  _delete_at_path?: Maybe<HAnswer_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HAnswer_Delete_Elem_Input>;
  _delete_key?: Maybe<HAnswer_Delete_Key_Input>;
  _prepend?: Maybe<HAnswer_Prepend_Input>;
  _set?: Maybe<HAnswer_Set_Input>;
  where: HAnswer_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_AnswerAttachmentArgs = {
  _set?: Maybe<HAnswerAttachment_Set_Input>;
  where: HAnswerAttachment_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_AnswerAttachment_By_PkArgs = {
  _set?: Maybe<HAnswerAttachment_Set_Input>;
  pk_columns: HAnswerAttachment_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_Answer_By_PkArgs = {
  _append?: Maybe<HAnswer_Append_Input>;
  _delete_at_path?: Maybe<HAnswer_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HAnswer_Delete_Elem_Input>;
  _delete_key?: Maybe<HAnswer_Delete_Key_Input>;
  _prepend?: Maybe<HAnswer_Prepend_Input>;
  _set?: Maybe<HAnswer_Set_Input>;
  pk_columns: HAnswer_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_BusinessUnitArgs = {
  _append?: Maybe<HBusinessUnit_Append_Input>;
  _delete_at_path?: Maybe<HBusinessUnit_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HBusinessUnit_Delete_Elem_Input>;
  _delete_key?: Maybe<HBusinessUnit_Delete_Key_Input>;
  _prepend?: Maybe<HBusinessUnit_Prepend_Input>;
  _set?: Maybe<HBusinessUnit_Set_Input>;
  where: HBusinessUnit_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_BusinessUnitAssessmentArgs = {
  _set?: Maybe<HBusinessUnitAssessment_Set_Input>;
  where: HBusinessUnitAssessment_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_BusinessUnitAssessment_By_PkArgs = {
  _set?: Maybe<HBusinessUnitAssessment_Set_Input>;
  pk_columns: HBusinessUnitAssessment_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_BusinessUnitDistributionKeyArgs = {
  _inc?: Maybe<HBusinessUnitDistributionKey_Inc_Input>;
  _set?: Maybe<HBusinessUnitDistributionKey_Set_Input>;
  where: HBusinessUnitDistributionKey_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_BusinessUnitDistributionKey_By_PkArgs = {
  _inc?: Maybe<HBusinessUnitDistributionKey_Inc_Input>;
  _set?: Maybe<HBusinessUnitDistributionKey_Set_Input>;
  pk_columns: HBusinessUnitDistributionKey_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_BusinessUnit_By_PkArgs = {
  _append?: Maybe<HBusinessUnit_Append_Input>;
  _delete_at_path?: Maybe<HBusinessUnit_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HBusinessUnit_Delete_Elem_Input>;
  _delete_key?: Maybe<HBusinessUnit_Delete_Key_Input>;
  _prepend?: Maybe<HBusinessUnit_Prepend_Input>;
  _set?: Maybe<HBusinessUnit_Set_Input>;
  pk_columns: HBusinessUnit_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_CommentArgs = {
  _set?: Maybe<HComment_Set_Input>;
  where: HComment_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_Comment_By_PkArgs = {
  _set?: Maybe<HComment_Set_Input>;
  pk_columns: HComment_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_CompanyArgs = {
  _append?: Maybe<HCompany_Append_Input>;
  _delete_at_path?: Maybe<HCompany_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HCompany_Delete_Elem_Input>;
  _delete_key?: Maybe<HCompany_Delete_Key_Input>;
  _inc?: Maybe<HCompany_Inc_Input>;
  _prepend?: Maybe<HCompany_Prepend_Input>;
  _set?: Maybe<HCompany_Set_Input>;
  where: HCompany_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_CompanyAssessmentArgs = {
  _set?: Maybe<HCompanyAssessment_Set_Input>;
  where: HCompanyAssessment_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_CompanyAssessmentAggregateArgs = {
  _set?: Maybe<HCompanyAssessmentAggregate_Set_Input>;
  where: HCompanyAssessmentAggregate_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_CompanyAssessmentAggregate_By_PkArgs = {
  _set?: Maybe<HCompanyAssessmentAggregate_Set_Input>;
  pk_columns: HCompanyAssessmentAggregate_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_CompanyAssessment_By_PkArgs = {
  _set?: Maybe<HCompanyAssessment_Set_Input>;
  pk_columns: HCompanyAssessment_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_CompanyInvitationArgs = {
  _set?: Maybe<HCompanyInvitation_Set_Input>;
  where: HCompanyInvitation_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_CompanyInvitation_By_PkArgs = {
  _set?: Maybe<HCompanyInvitation_Set_Input>;
  pk_columns: HCompanyInvitation_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_CompanyUserArgs = {
  _set?: Maybe<HCompanyUser_Set_Input>;
  where: HCompanyUser_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_CompanyUser_By_PkArgs = {
  _set?: Maybe<HCompanyUser_Set_Input>;
  pk_columns: HCompanyUser_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_Company_By_PkArgs = {
  _append?: Maybe<HCompany_Append_Input>;
  _delete_at_path?: Maybe<HCompany_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HCompany_Delete_Elem_Input>;
  _delete_key?: Maybe<HCompany_Delete_Key_Input>;
  _inc?: Maybe<HCompany_Inc_Input>;
  _prepend?: Maybe<HCompany_Prepend_Input>;
  _set?: Maybe<HCompany_Set_Input>;
  pk_columns: HCompany_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_FileArgs = {
  _append?: Maybe<HFile_Append_Input>;
  _delete_at_path?: Maybe<HFile_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HFile_Delete_Elem_Input>;
  _delete_key?: Maybe<HFile_Delete_Key_Input>;
  _prepend?: Maybe<HFile_Prepend_Input>;
  _set?: Maybe<HFile_Set_Input>;
  where: HFile_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_File_By_PkArgs = {
  _append?: Maybe<HFile_Append_Input>;
  _delete_at_path?: Maybe<HFile_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HFile_Delete_Elem_Input>;
  _delete_key?: Maybe<HFile_Delete_Key_Input>;
  _prepend?: Maybe<HFile_Prepend_Input>;
  _set?: Maybe<HFile_Set_Input>;
  pk_columns: HFile_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_FinancialsArgs = {
  _inc?: Maybe<HFinancials_Inc_Input>;
  _set?: Maybe<HFinancials_Set_Input>;
  where: HFinancials_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_Financials_By_PkArgs = {
  _inc?: Maybe<HFinancials_Inc_Input>;
  _set?: Maybe<HFinancials_Set_Input>;
  pk_columns: HFinancials_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_PortfolioArgs = {
  _inc?: Maybe<HPortfolio_Inc_Input>;
  _set?: Maybe<HPortfolio_Set_Input>;
  where: HPortfolio_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_PortfolioCompanyArgs = {
  _append?: Maybe<HPortfolioCompany_Append_Input>;
  _delete_at_path?: Maybe<HPortfolioCompany_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HPortfolioCompany_Delete_Elem_Input>;
  _delete_key?: Maybe<HPortfolioCompany_Delete_Key_Input>;
  _inc?: Maybe<HPortfolioCompany_Inc_Input>;
  _prepend?: Maybe<HPortfolioCompany_Prepend_Input>;
  _set?: Maybe<HPortfolioCompany_Set_Input>;
  where: HPortfolioCompany_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_PortfolioCompany_By_PkArgs = {
  _append?: Maybe<HPortfolioCompany_Append_Input>;
  _delete_at_path?: Maybe<HPortfolioCompany_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HPortfolioCompany_Delete_Elem_Input>;
  _delete_key?: Maybe<HPortfolioCompany_Delete_Key_Input>;
  _inc?: Maybe<HPortfolioCompany_Inc_Input>;
  _prepend?: Maybe<HPortfolioCompany_Prepend_Input>;
  _set?: Maybe<HPortfolioCompany_Set_Input>;
  pk_columns: HPortfolioCompany_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_Portfolio_By_PkArgs = {
  _inc?: Maybe<HPortfolio_Inc_Input>;
  _set?: Maybe<HPortfolio_Set_Input>;
  pk_columns: HPortfolio_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_ThreadArgs = {
  _set?: Maybe<HThread_Set_Input>;
  where: HThread_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_ThreadSubscriberArgs = {
  _set?: Maybe<HThreadSubscriber_Set_Input>;
  where: HThreadSubscriber_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_ThreadSubscriber_By_PkArgs = {
  _set?: Maybe<HThreadSubscriber_Set_Input>;
  pk_columns: HThreadSubscriber_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_Thread_By_PkArgs = {
  _set?: Maybe<HThread_Set_Input>;
  pk_columns: HThread_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_UserNotificationArgs = {
  _set?: Maybe<HUserNotification_Set_Input>;
  where: HUserNotification_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_UserNotification_By_PkArgs = {
  _set?: Maybe<HUserNotification_Set_Input>;
  pk_columns: HUserNotification_Pk_Columns_Input;
};


/** mutation root */
export type HMutation_RootUpdate_UsersArgs = {
  _append?: Maybe<HUsers_Append_Input>;
  _delete_at_path?: Maybe<HUsers_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HUsers_Delete_Elem_Input>;
  _delete_key?: Maybe<HUsers_Delete_Key_Input>;
  _prepend?: Maybe<HUsers_Prepend_Input>;
  _set?: Maybe<HUsers_Set_Input>;
  where: HUsers_Bool_Exp;
};


/** mutation root */
export type HMutation_RootUpdate_Users_By_PkArgs = {
  _append?: Maybe<HUsers_Append_Input>;
  _delete_at_path?: Maybe<HUsers_Delete_At_Path_Input>;
  _delete_elem?: Maybe<HUsers_Delete_Elem_Input>;
  _delete_key?: Maybe<HUsers_Delete_Key_Input>;
  _prepend?: Maybe<HUsers_Prepend_Input>;
  _set?: Maybe<HUsers_Set_Input>;
  pk_columns: HUsers_Pk_Columns_Input;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type HNumeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum HOrder_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type HQuery_Root = {
  /** fetch data from the table: "Activity" */
  Activity: Array<HActivity>;
  /** fetch data from the table: "ActivityNaceCode" */
  ActivityNaceCode: Array<HActivityNaceCode>;
  /** fetch data from the table: "ActivityNaceCode" using primary key columns */
  ActivityNaceCode_by_pk?: Maybe<HActivityNaceCode>;
  /** fetch data from the table: "ActivityQuestionSet" */
  ActivityQuestionSet: Array<HActivityQuestionSet>;
  /** fetch data from the table: "ActivityQuestionSet" using primary key columns */
  ActivityQuestionSet_by_pk?: Maybe<HActivityQuestionSet>;
  /** fetch data from the table: "ActivityReport" */
  ActivityReport: Array<HActivityReport>;
  /** fetch data from the table: "ActivityReport" using primary key columns */
  ActivityReport_by_pk?: Maybe<HActivityReport>;
  /** fetch data from the table: "Activity" using primary key columns */
  Activity_by_pk?: Maybe<HActivity>;
  /** fetch data from the table: "Answer" */
  Answer: Array<HAnswer>;
  /** fetch data from the table: "AnswerAttachment" */
  AnswerAttachment: Array<HAnswerAttachment>;
  /** fetch data from the table: "AnswerAttachment" using primary key columns */
  AnswerAttachment_by_pk?: Maybe<HAnswerAttachment>;
  /** fetch data from the table: "AnswerNote" */
  AnswerNote: Array<HAnswerNote>;
  /** fetch data from the table: "AnswerNote" using primary key columns */
  AnswerNote_by_pk?: Maybe<HAnswerNote>;
  /** fetch data from the table: "Answer" using primary key columns */
  Answer_by_pk?: Maybe<HAnswer>;
  /** fetch data from the table: "Article" */
  Article: Array<HArticle>;
  /** fetch data from the table: "Article" using primary key columns */
  Article_by_pk?: Maybe<HArticle>;
  /** fetch data from the table: "BusinessUnit" */
  BusinessUnit: Array<HBusinessUnit>;
  /** fetch data from the table: "BusinessUnitAssessment" */
  BusinessUnitAssessment: Array<HBusinessUnitAssessment>;
  /** fetch data from the table: "BusinessUnitAssessment" using primary key columns */
  BusinessUnitAssessment_by_pk?: Maybe<HBusinessUnitAssessment>;
  /** fetch data from the table: "BusinessUnitDistributionKey" */
  BusinessUnitDistributionKey: Array<HBusinessUnitDistributionKey>;
  /** fetch data from the table: "BusinessUnitDistributionKey" using primary key columns */
  BusinessUnitDistributionKey_by_pk?: Maybe<HBusinessUnitDistributionKey>;
  /** fetch data from the table: "BusinessUnit" using primary key columns */
  BusinessUnit_by_pk?: Maybe<HBusinessUnit>;
  /** fetch data from the table: "Comment" */
  Comment: Array<HComment>;
  /** fetch aggregated fields from the table: "Comment" */
  Comment_aggregate: HComment_Aggregate;
  /** fetch data from the table: "Comment" using primary key columns */
  Comment_by_pk?: Maybe<HComment>;
  /** fetch data from the table: "Company" */
  Company: Array<HCompany>;
  /** fetch data from the table: "CompanyAssessment" */
  CompanyAssessment: Array<HCompanyAssessment>;
  /** fetch data from the table: "CompanyAssessmentAggregate" */
  CompanyAssessmentAggregate: Array<HCompanyAssessmentAggregate>;
  /** fetch data from the table: "CompanyAssessmentAggregate" using primary key columns */
  CompanyAssessmentAggregate_by_pk?: Maybe<HCompanyAssessmentAggregate>;
  /** fetch data from the table: "CompanyAssessment" using primary key columns */
  CompanyAssessment_by_pk?: Maybe<HCompanyAssessment>;
  /** fetch data from the table: "CompanyInvitation" */
  CompanyInvitation: Array<HCompanyInvitation>;
  /** fetch data from the table: "CompanyInvitation" using primary key columns */
  CompanyInvitation_by_pk?: Maybe<HCompanyInvitation>;
  /** fetch data from the table: "CompanyUser" */
  CompanyUser: Array<HCompanyUser>;
  /** fetch data from the table: "CompanyUser" using primary key columns */
  CompanyUser_by_pk?: Maybe<HCompanyUser>;
  /** fetch data from the table: "Company" using primary key columns */
  Company_by_pk?: Maybe<HCompany>;
  /** fetch data from the table: "EstimateCompany" */
  EstimateCompany: Array<HEstimateCompany>;
  /** fetch data from the table: "EstimateCompany" using primary key columns */
  EstimateCompany_by_pk?: Maybe<HEstimateCompany>;
  /** fetch data from the table: "File" */
  File: Array<HFile>;
  /** fetch data from the table: "File" using primary key columns */
  File_by_pk?: Maybe<HFile>;
  /** fetch data from the table: "Financials" */
  Financials: Array<HFinancials>;
  /** fetch data from the table: "Financials" using primary key columns */
  Financials_by_pk?: Maybe<HFinancials>;
  /** fetch data from the table: "Objective" */
  Objective: Array<HObjective>;
  /** fetch data from the table: "Objective" using primary key columns */
  Objective_by_pk?: Maybe<HObjective>;
  /** fetch data from the table: "Portfolio" */
  Portfolio: Array<HPortfolio>;
  /** fetch data from the table: "PortfolioCompany" */
  PortfolioCompany: Array<HPortfolioCompany>;
  /** fetch data from the table: "PortfolioCompany" using primary key columns */
  PortfolioCompany_by_pk?: Maybe<HPortfolioCompany>;
  /** fetch data from the table: "Portfolio" using primary key columns */
  Portfolio_by_pk?: Maybe<HPortfolio>;
  /** fetch data from the table: "Question" */
  Question: Array<HQuestion>;
  /** fetch data from the table: "QuestionChoice" */
  QuestionChoice: Array<HQuestionChoice>;
  /** fetch data from the table: "QuestionChoice" using primary key columns */
  QuestionChoice_by_pk?: Maybe<HQuestionChoice>;
  /** fetch data from the table: "QuestionSet" */
  QuestionSet: Array<HQuestionSet>;
  /** fetch data from the table: "QuestionSet" using primary key columns */
  QuestionSet_by_pk?: Maybe<HQuestionSet>;
  /** fetch data from the table: "Question" using primary key columns */
  Question_by_pk?: Maybe<HQuestion>;
  /** fetch data from the table: "Sector" */
  Sector: Array<HSector>;
  /** fetch data from the table: "Sector" using primary key columns */
  Sector_by_pk?: Maybe<HSector>;
  /** fetch data from the table: "Tag" */
  Tag: Array<HTag>;
  /** An array relationship */
  Tag_articles: Array<HTag_Articles>;
  /** fetch data from the table: "Tag_articles" using primary key columns */
  Tag_articles_by_pk?: Maybe<HTag_Articles>;
  /** fetch data from the table: "Tag" using primary key columns */
  Tag_by_pk?: Maybe<HTag>;
  /** fetch data from the table: "Thread" */
  Thread: Array<HThread>;
  /** fetch data from the table: "ThreadSubscriber" */
  ThreadSubscriber: Array<HThreadSubscriber>;
  /** fetch data from the table: "ThreadSubscriber" using primary key columns */
  ThreadSubscriber_by_pk?: Maybe<HThreadSubscriber>;
  /** fetch data from the table: "Thread" using primary key columns */
  Thread_by_pk?: Maybe<HThread>;
  /** fetch data from the table: "UserNotification" */
  UserNotification: Array<HUserNotification>;
  /** fetch aggregated fields from the table: "UserNotification" */
  UserNotification_aggregate: HUserNotification_Aggregate;
  /** fetch data from the table: "UserNotification" using primary key columns */
  UserNotification_by_pk?: Maybe<HUserNotification>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<HAuth_Accounts>;
  getInvitation?: Maybe<HInvitationOutput>;
  /** fetch data from the table: "users" */
  users: Array<HUsers>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<HUsers>;
};


export type HQuery_RootActivityArgs = {
  distinct_on?: Maybe<Array<HActivity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivity_Order_By>>;
  where?: Maybe<HActivity_Bool_Exp>;
};


export type HQuery_RootActivityNaceCodeArgs = {
  distinct_on?: Maybe<Array<HActivityNaceCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityNaceCode_Order_By>>;
  where?: Maybe<HActivityNaceCode_Bool_Exp>;
};


export type HQuery_RootActivityNaceCode_By_PkArgs = {
  id: Scalars['bigint'];
};


export type HQuery_RootActivityQuestionSetArgs = {
  distinct_on?: Maybe<Array<HActivityQuestionSet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityQuestionSet_Order_By>>;
  where?: Maybe<HActivityQuestionSet_Bool_Exp>;
};


export type HQuery_RootActivityQuestionSet_By_PkArgs = {
  id: Scalars['bigint'];
};


export type HQuery_RootActivityReportArgs = {
  distinct_on?: Maybe<Array<HActivityReport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityReport_Order_By>>;
  where?: Maybe<HActivityReport_Bool_Exp>;
};


export type HQuery_RootActivityReport_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootActivity_By_PkArgs = {
  reference: Scalars['String'];
};


export type HQuery_RootAnswerArgs = {
  distinct_on?: Maybe<Array<HAnswer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswer_Order_By>>;
  where?: Maybe<HAnswer_Bool_Exp>;
};


export type HQuery_RootAnswerAttachmentArgs = {
  distinct_on?: Maybe<Array<HAnswerAttachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswerAttachment_Order_By>>;
  where?: Maybe<HAnswerAttachment_Bool_Exp>;
};


export type HQuery_RootAnswerAttachment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootAnswerNoteArgs = {
  distinct_on?: Maybe<Array<HAnswerNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswerNote_Order_By>>;
  where?: Maybe<HAnswerNote_Bool_Exp>;
};


export type HQuery_RootAnswerNote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootAnswer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootArticleArgs = {
  distinct_on?: Maybe<Array<HArticle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HArticle_Order_By>>;
  where?: Maybe<HArticle_Bool_Exp>;
};


export type HQuery_RootArticle_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootBusinessUnitArgs = {
  distinct_on?: Maybe<Array<HBusinessUnit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnit_Order_By>>;
  where?: Maybe<HBusinessUnit_Bool_Exp>;
};


export type HQuery_RootBusinessUnitAssessmentArgs = {
  distinct_on?: Maybe<Array<HBusinessUnitAssessment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnitAssessment_Order_By>>;
  where?: Maybe<HBusinessUnitAssessment_Bool_Exp>;
};


export type HQuery_RootBusinessUnitAssessment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootBusinessUnitDistributionKeyArgs = {
  distinct_on?: Maybe<Array<HBusinessUnitDistributionKey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnitDistributionKey_Order_By>>;
  where?: Maybe<HBusinessUnitDistributionKey_Bool_Exp>;
};


export type HQuery_RootBusinessUnitDistributionKey_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootBusinessUnit_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootCommentArgs = {
  distinct_on?: Maybe<Array<HComment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HComment_Order_By>>;
  where?: Maybe<HComment_Bool_Exp>;
};


export type HQuery_RootComment_AggregateArgs = {
  distinct_on?: Maybe<Array<HComment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HComment_Order_By>>;
  where?: Maybe<HComment_Bool_Exp>;
};


export type HQuery_RootComment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootCompanyArgs = {
  distinct_on?: Maybe<Array<HCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompany_Order_By>>;
  where?: Maybe<HCompany_Bool_Exp>;
};


export type HQuery_RootCompanyAssessmentArgs = {
  distinct_on?: Maybe<Array<HCompanyAssessment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyAssessment_Order_By>>;
  where?: Maybe<HCompanyAssessment_Bool_Exp>;
};


export type HQuery_RootCompanyAssessmentAggregateArgs = {
  distinct_on?: Maybe<Array<HCompanyAssessmentAggregate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyAssessmentAggregate_Order_By>>;
  where?: Maybe<HCompanyAssessmentAggregate_Bool_Exp>;
};


export type HQuery_RootCompanyAssessmentAggregate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootCompanyAssessment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootCompanyInvitationArgs = {
  distinct_on?: Maybe<Array<HCompanyInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyInvitation_Order_By>>;
  where?: Maybe<HCompanyInvitation_Bool_Exp>;
};


export type HQuery_RootCompanyInvitation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootCompanyUserArgs = {
  distinct_on?: Maybe<Array<HCompanyUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyUser_Order_By>>;
  where?: Maybe<HCompanyUser_Bool_Exp>;
};


export type HQuery_RootCompanyUser_By_PkArgs = {
  id: Scalars['bigint'];
};


export type HQuery_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootEstimateCompanyArgs = {
  distinct_on?: Maybe<Array<HEstimateCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HEstimateCompany_Order_By>>;
  where?: Maybe<HEstimateCompany_Bool_Exp>;
};


export type HQuery_RootEstimateCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootFileArgs = {
  distinct_on?: Maybe<Array<HFile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HFile_Order_By>>;
  where?: Maybe<HFile_Bool_Exp>;
};


export type HQuery_RootFile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootFinancialsArgs = {
  distinct_on?: Maybe<Array<HFinancials_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HFinancials_Order_By>>;
  where?: Maybe<HFinancials_Bool_Exp>;
};


export type HQuery_RootFinancials_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootObjectiveArgs = {
  distinct_on?: Maybe<Array<HObjective_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HObjective_Order_By>>;
  where?: Maybe<HObjective_Bool_Exp>;
};


export type HQuery_RootObjective_By_PkArgs = {
  key: Scalars['String'];
};


export type HQuery_RootPortfolioArgs = {
  distinct_on?: Maybe<Array<HPortfolio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HPortfolio_Order_By>>;
  where?: Maybe<HPortfolio_Bool_Exp>;
};


export type HQuery_RootPortfolioCompanyArgs = {
  distinct_on?: Maybe<Array<HPortfolioCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HPortfolioCompany_Order_By>>;
  where?: Maybe<HPortfolioCompany_Bool_Exp>;
};


export type HQuery_RootPortfolioCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootPortfolio_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootQuestionArgs = {
  distinct_on?: Maybe<Array<HQuestion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HQuestion_Order_By>>;
  where?: Maybe<HQuestion_Bool_Exp>;
};


export type HQuery_RootQuestionChoiceArgs = {
  distinct_on?: Maybe<Array<HQuestionChoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HQuestionChoice_Order_By>>;
  where?: Maybe<HQuestionChoice_Bool_Exp>;
};


export type HQuery_RootQuestionChoice_By_PkArgs = {
  id: Scalars['bigint'];
};


export type HQuery_RootQuestionSetArgs = {
  distinct_on?: Maybe<Array<HQuestionSet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HQuestionSet_Order_By>>;
  where?: Maybe<HQuestionSet_Bool_Exp>;
};


export type HQuery_RootQuestionSet_By_PkArgs = {
  reference: Scalars['String'];
};


export type HQuery_RootQuestion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootSectorArgs = {
  distinct_on?: Maybe<Array<HSector_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HSector_Order_By>>;
  where?: Maybe<HSector_Bool_Exp>;
};


export type HQuery_RootSector_By_PkArgs = {
  reference: Scalars['String'];
};


export type HQuery_RootTagArgs = {
  distinct_on?: Maybe<Array<HTag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HTag_Order_By>>;
  where?: Maybe<HTag_Bool_Exp>;
};


export type HQuery_RootTag_ArticlesArgs = {
  distinct_on?: Maybe<Array<HTag_Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HTag_Articles_Order_By>>;
  where?: Maybe<HTag_Articles_Bool_Exp>;
};


export type HQuery_RootTag_Articles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type HQuery_RootTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootThreadArgs = {
  distinct_on?: Maybe<Array<HThread_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HThread_Order_By>>;
  where?: Maybe<HThread_Bool_Exp>;
};


export type HQuery_RootThreadSubscriberArgs = {
  distinct_on?: Maybe<Array<HThreadSubscriber_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HThreadSubscriber_Order_By>>;
  where?: Maybe<HThreadSubscriber_Bool_Exp>;
};


export type HQuery_RootThreadSubscriber_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootThread_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootUserNotificationArgs = {
  distinct_on?: Maybe<Array<HUserNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HUserNotification_Order_By>>;
  where?: Maybe<HUserNotification_Bool_Exp>;
};


export type HQuery_RootUserNotification_AggregateArgs = {
  distinct_on?: Maybe<Array<HUserNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HUserNotification_Order_By>>;
  where?: Maybe<HUserNotification_Bool_Exp>;
};


export type HQuery_RootUserNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HQuery_RootAuth_AccountsArgs = {
  distinct_on?: Maybe<Array<HAuth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAuth_Accounts_Order_By>>;
  where?: Maybe<HAuth_Accounts_Bool_Exp>;
};


export type HQuery_RootGetInvitationArgs = {
  query: HInvitationQuery;
};


export type HQuery_RootUsersArgs = {
  distinct_on?: Maybe<Array<HUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HUsers_Order_By>>;
  where?: Maybe<HUsers_Bool_Exp>;
};


export type HQuery_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type HSubscription_Root = {
  /** fetch data from the table: "Activity" */
  Activity: Array<HActivity>;
  /** fetch data from the table: "ActivityNaceCode" */
  ActivityNaceCode: Array<HActivityNaceCode>;
  /** fetch data from the table: "ActivityNaceCode" using primary key columns */
  ActivityNaceCode_by_pk?: Maybe<HActivityNaceCode>;
  /** fetch data from the table: "ActivityQuestionSet" */
  ActivityQuestionSet: Array<HActivityQuestionSet>;
  /** fetch data from the table: "ActivityQuestionSet" using primary key columns */
  ActivityQuestionSet_by_pk?: Maybe<HActivityQuestionSet>;
  /** fetch data from the table: "ActivityReport" */
  ActivityReport: Array<HActivityReport>;
  /** fetch data from the table: "ActivityReport" using primary key columns */
  ActivityReport_by_pk?: Maybe<HActivityReport>;
  /** fetch data from the table: "Activity" using primary key columns */
  Activity_by_pk?: Maybe<HActivity>;
  /** fetch data from the table: "Answer" */
  Answer: Array<HAnswer>;
  /** fetch data from the table: "AnswerAttachment" */
  AnswerAttachment: Array<HAnswerAttachment>;
  /** fetch data from the table: "AnswerAttachment" using primary key columns */
  AnswerAttachment_by_pk?: Maybe<HAnswerAttachment>;
  /** fetch data from the table: "AnswerNote" */
  AnswerNote: Array<HAnswerNote>;
  /** fetch data from the table: "AnswerNote" using primary key columns */
  AnswerNote_by_pk?: Maybe<HAnswerNote>;
  /** fetch data from the table: "Answer" using primary key columns */
  Answer_by_pk?: Maybe<HAnswer>;
  /** fetch data from the table: "Article" */
  Article: Array<HArticle>;
  /** fetch data from the table: "Article" using primary key columns */
  Article_by_pk?: Maybe<HArticle>;
  /** fetch data from the table: "BusinessUnit" */
  BusinessUnit: Array<HBusinessUnit>;
  /** fetch data from the table: "BusinessUnitAssessment" */
  BusinessUnitAssessment: Array<HBusinessUnitAssessment>;
  /** fetch data from the table: "BusinessUnitAssessment" using primary key columns */
  BusinessUnitAssessment_by_pk?: Maybe<HBusinessUnitAssessment>;
  /** fetch data from the table: "BusinessUnitDistributionKey" */
  BusinessUnitDistributionKey: Array<HBusinessUnitDistributionKey>;
  /** fetch data from the table: "BusinessUnitDistributionKey" using primary key columns */
  BusinessUnitDistributionKey_by_pk?: Maybe<HBusinessUnitDistributionKey>;
  /** fetch data from the table: "BusinessUnit" using primary key columns */
  BusinessUnit_by_pk?: Maybe<HBusinessUnit>;
  /** fetch data from the table: "Comment" */
  Comment: Array<HComment>;
  /** fetch aggregated fields from the table: "Comment" */
  Comment_aggregate: HComment_Aggregate;
  /** fetch data from the table: "Comment" using primary key columns */
  Comment_by_pk?: Maybe<HComment>;
  /** fetch data from the table: "Company" */
  Company: Array<HCompany>;
  /** fetch data from the table: "CompanyAssessment" */
  CompanyAssessment: Array<HCompanyAssessment>;
  /** fetch data from the table: "CompanyAssessmentAggregate" */
  CompanyAssessmentAggregate: Array<HCompanyAssessmentAggregate>;
  /** fetch data from the table: "CompanyAssessmentAggregate" using primary key columns */
  CompanyAssessmentAggregate_by_pk?: Maybe<HCompanyAssessmentAggregate>;
  /** fetch data from the table: "CompanyAssessment" using primary key columns */
  CompanyAssessment_by_pk?: Maybe<HCompanyAssessment>;
  /** fetch data from the table: "CompanyInvitation" */
  CompanyInvitation: Array<HCompanyInvitation>;
  /** fetch data from the table: "CompanyInvitation" using primary key columns */
  CompanyInvitation_by_pk?: Maybe<HCompanyInvitation>;
  /** fetch data from the table: "CompanyUser" */
  CompanyUser: Array<HCompanyUser>;
  /** fetch data from the table: "CompanyUser" using primary key columns */
  CompanyUser_by_pk?: Maybe<HCompanyUser>;
  /** fetch data from the table: "Company" using primary key columns */
  Company_by_pk?: Maybe<HCompany>;
  /** fetch data from the table: "EstimateCompany" */
  EstimateCompany: Array<HEstimateCompany>;
  /** fetch data from the table: "EstimateCompany" using primary key columns */
  EstimateCompany_by_pk?: Maybe<HEstimateCompany>;
  /** fetch data from the table: "File" */
  File: Array<HFile>;
  /** fetch data from the table: "File" using primary key columns */
  File_by_pk?: Maybe<HFile>;
  /** fetch data from the table: "Financials" */
  Financials: Array<HFinancials>;
  /** fetch data from the table: "Financials" using primary key columns */
  Financials_by_pk?: Maybe<HFinancials>;
  /** fetch data from the table: "Objective" */
  Objective: Array<HObjective>;
  /** fetch data from the table: "Objective" using primary key columns */
  Objective_by_pk?: Maybe<HObjective>;
  /** fetch data from the table: "Portfolio" */
  Portfolio: Array<HPortfolio>;
  /** fetch data from the table: "PortfolioCompany" */
  PortfolioCompany: Array<HPortfolioCompany>;
  /** fetch data from the table: "PortfolioCompany" using primary key columns */
  PortfolioCompany_by_pk?: Maybe<HPortfolioCompany>;
  /** fetch data from the table: "Portfolio" using primary key columns */
  Portfolio_by_pk?: Maybe<HPortfolio>;
  /** fetch data from the table: "Question" */
  Question: Array<HQuestion>;
  /** fetch data from the table: "QuestionChoice" */
  QuestionChoice: Array<HQuestionChoice>;
  /** fetch data from the table: "QuestionChoice" using primary key columns */
  QuestionChoice_by_pk?: Maybe<HQuestionChoice>;
  /** fetch data from the table: "QuestionSet" */
  QuestionSet: Array<HQuestionSet>;
  /** fetch data from the table: "QuestionSet" using primary key columns */
  QuestionSet_by_pk?: Maybe<HQuestionSet>;
  /** fetch data from the table: "Question" using primary key columns */
  Question_by_pk?: Maybe<HQuestion>;
  /** fetch data from the table: "Sector" */
  Sector: Array<HSector>;
  /** fetch data from the table: "Sector" using primary key columns */
  Sector_by_pk?: Maybe<HSector>;
  /** fetch data from the table: "Tag" */
  Tag: Array<HTag>;
  /** An array relationship */
  Tag_articles: Array<HTag_Articles>;
  /** fetch data from the table: "Tag_articles" using primary key columns */
  Tag_articles_by_pk?: Maybe<HTag_Articles>;
  /** fetch data from the table: "Tag" using primary key columns */
  Tag_by_pk?: Maybe<HTag>;
  /** fetch data from the table: "Thread" */
  Thread: Array<HThread>;
  /** fetch data from the table: "ThreadSubscriber" */
  ThreadSubscriber: Array<HThreadSubscriber>;
  /** fetch data from the table: "ThreadSubscriber" using primary key columns */
  ThreadSubscriber_by_pk?: Maybe<HThreadSubscriber>;
  /** fetch data from the table: "Thread" using primary key columns */
  Thread_by_pk?: Maybe<HThread>;
  /** fetch data from the table: "UserNotification" */
  UserNotification: Array<HUserNotification>;
  /** fetch aggregated fields from the table: "UserNotification" */
  UserNotification_aggregate: HUserNotification_Aggregate;
  /** fetch data from the table: "UserNotification" using primary key columns */
  UserNotification_by_pk?: Maybe<HUserNotification>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<HAuth_Accounts>;
  /** fetch data from the table: "users" */
  users: Array<HUsers>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<HUsers>;
};


export type HSubscription_RootActivityArgs = {
  distinct_on?: Maybe<Array<HActivity_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivity_Order_By>>;
  where?: Maybe<HActivity_Bool_Exp>;
};


export type HSubscription_RootActivityNaceCodeArgs = {
  distinct_on?: Maybe<Array<HActivityNaceCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityNaceCode_Order_By>>;
  where?: Maybe<HActivityNaceCode_Bool_Exp>;
};


export type HSubscription_RootActivityNaceCode_By_PkArgs = {
  id: Scalars['bigint'];
};


export type HSubscription_RootActivityQuestionSetArgs = {
  distinct_on?: Maybe<Array<HActivityQuestionSet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityQuestionSet_Order_By>>;
  where?: Maybe<HActivityQuestionSet_Bool_Exp>;
};


export type HSubscription_RootActivityQuestionSet_By_PkArgs = {
  id: Scalars['bigint'];
};


export type HSubscription_RootActivityReportArgs = {
  distinct_on?: Maybe<Array<HActivityReport_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HActivityReport_Order_By>>;
  where?: Maybe<HActivityReport_Bool_Exp>;
};


export type HSubscription_RootActivityReport_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootActivity_By_PkArgs = {
  reference: Scalars['String'];
};


export type HSubscription_RootAnswerArgs = {
  distinct_on?: Maybe<Array<HAnswer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswer_Order_By>>;
  where?: Maybe<HAnswer_Bool_Exp>;
};


export type HSubscription_RootAnswerAttachmentArgs = {
  distinct_on?: Maybe<Array<HAnswerAttachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswerAttachment_Order_By>>;
  where?: Maybe<HAnswerAttachment_Bool_Exp>;
};


export type HSubscription_RootAnswerAttachment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootAnswerNoteArgs = {
  distinct_on?: Maybe<Array<HAnswerNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswerNote_Order_By>>;
  where?: Maybe<HAnswerNote_Bool_Exp>;
};


export type HSubscription_RootAnswerNote_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootAnswer_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootArticleArgs = {
  distinct_on?: Maybe<Array<HArticle_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HArticle_Order_By>>;
  where?: Maybe<HArticle_Bool_Exp>;
};


export type HSubscription_RootArticle_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootBusinessUnitArgs = {
  distinct_on?: Maybe<Array<HBusinessUnit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnit_Order_By>>;
  where?: Maybe<HBusinessUnit_Bool_Exp>;
};


export type HSubscription_RootBusinessUnitAssessmentArgs = {
  distinct_on?: Maybe<Array<HBusinessUnitAssessment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnitAssessment_Order_By>>;
  where?: Maybe<HBusinessUnitAssessment_Bool_Exp>;
};


export type HSubscription_RootBusinessUnitAssessment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootBusinessUnitDistributionKeyArgs = {
  distinct_on?: Maybe<Array<HBusinessUnitDistributionKey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnitDistributionKey_Order_By>>;
  where?: Maybe<HBusinessUnitDistributionKey_Bool_Exp>;
};


export type HSubscription_RootBusinessUnitDistributionKey_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootBusinessUnit_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootCommentArgs = {
  distinct_on?: Maybe<Array<HComment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HComment_Order_By>>;
  where?: Maybe<HComment_Bool_Exp>;
};


export type HSubscription_RootComment_AggregateArgs = {
  distinct_on?: Maybe<Array<HComment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HComment_Order_By>>;
  where?: Maybe<HComment_Bool_Exp>;
};


export type HSubscription_RootComment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootCompanyArgs = {
  distinct_on?: Maybe<Array<HCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompany_Order_By>>;
  where?: Maybe<HCompany_Bool_Exp>;
};


export type HSubscription_RootCompanyAssessmentArgs = {
  distinct_on?: Maybe<Array<HCompanyAssessment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyAssessment_Order_By>>;
  where?: Maybe<HCompanyAssessment_Bool_Exp>;
};


export type HSubscription_RootCompanyAssessmentAggregateArgs = {
  distinct_on?: Maybe<Array<HCompanyAssessmentAggregate_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyAssessmentAggregate_Order_By>>;
  where?: Maybe<HCompanyAssessmentAggregate_Bool_Exp>;
};


export type HSubscription_RootCompanyAssessmentAggregate_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootCompanyAssessment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootCompanyInvitationArgs = {
  distinct_on?: Maybe<Array<HCompanyInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyInvitation_Order_By>>;
  where?: Maybe<HCompanyInvitation_Bool_Exp>;
};


export type HSubscription_RootCompanyInvitation_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootCompanyUserArgs = {
  distinct_on?: Maybe<Array<HCompanyUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyUser_Order_By>>;
  where?: Maybe<HCompanyUser_Bool_Exp>;
};


export type HSubscription_RootCompanyUser_By_PkArgs = {
  id: Scalars['bigint'];
};


export type HSubscription_RootCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootEstimateCompanyArgs = {
  distinct_on?: Maybe<Array<HEstimateCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HEstimateCompany_Order_By>>;
  where?: Maybe<HEstimateCompany_Bool_Exp>;
};


export type HSubscription_RootEstimateCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootFileArgs = {
  distinct_on?: Maybe<Array<HFile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HFile_Order_By>>;
  where?: Maybe<HFile_Bool_Exp>;
};


export type HSubscription_RootFile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootFinancialsArgs = {
  distinct_on?: Maybe<Array<HFinancials_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HFinancials_Order_By>>;
  where?: Maybe<HFinancials_Bool_Exp>;
};


export type HSubscription_RootFinancials_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootObjectiveArgs = {
  distinct_on?: Maybe<Array<HObjective_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HObjective_Order_By>>;
  where?: Maybe<HObjective_Bool_Exp>;
};


export type HSubscription_RootObjective_By_PkArgs = {
  key: Scalars['String'];
};


export type HSubscription_RootPortfolioArgs = {
  distinct_on?: Maybe<Array<HPortfolio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HPortfolio_Order_By>>;
  where?: Maybe<HPortfolio_Bool_Exp>;
};


export type HSubscription_RootPortfolioCompanyArgs = {
  distinct_on?: Maybe<Array<HPortfolioCompany_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HPortfolioCompany_Order_By>>;
  where?: Maybe<HPortfolioCompany_Bool_Exp>;
};


export type HSubscription_RootPortfolioCompany_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootPortfolio_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootQuestionArgs = {
  distinct_on?: Maybe<Array<HQuestion_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HQuestion_Order_By>>;
  where?: Maybe<HQuestion_Bool_Exp>;
};


export type HSubscription_RootQuestionChoiceArgs = {
  distinct_on?: Maybe<Array<HQuestionChoice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HQuestionChoice_Order_By>>;
  where?: Maybe<HQuestionChoice_Bool_Exp>;
};


export type HSubscription_RootQuestionChoice_By_PkArgs = {
  id: Scalars['bigint'];
};


export type HSubscription_RootQuestionSetArgs = {
  distinct_on?: Maybe<Array<HQuestionSet_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HQuestionSet_Order_By>>;
  where?: Maybe<HQuestionSet_Bool_Exp>;
};


export type HSubscription_RootQuestionSet_By_PkArgs = {
  reference: Scalars['String'];
};


export type HSubscription_RootQuestion_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootSectorArgs = {
  distinct_on?: Maybe<Array<HSector_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HSector_Order_By>>;
  where?: Maybe<HSector_Bool_Exp>;
};


export type HSubscription_RootSector_By_PkArgs = {
  reference: Scalars['String'];
};


export type HSubscription_RootTagArgs = {
  distinct_on?: Maybe<Array<HTag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HTag_Order_By>>;
  where?: Maybe<HTag_Bool_Exp>;
};


export type HSubscription_RootTag_ArticlesArgs = {
  distinct_on?: Maybe<Array<HTag_Articles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HTag_Articles_Order_By>>;
  where?: Maybe<HTag_Articles_Bool_Exp>;
};


export type HSubscription_RootTag_Articles_By_PkArgs = {
  id: Scalars['bigint'];
};


export type HSubscription_RootTag_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootThreadArgs = {
  distinct_on?: Maybe<Array<HThread_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HThread_Order_By>>;
  where?: Maybe<HThread_Bool_Exp>;
};


export type HSubscription_RootThreadSubscriberArgs = {
  distinct_on?: Maybe<Array<HThreadSubscriber_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HThreadSubscriber_Order_By>>;
  where?: Maybe<HThreadSubscriber_Bool_Exp>;
};


export type HSubscription_RootThreadSubscriber_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootThread_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootUserNotificationArgs = {
  distinct_on?: Maybe<Array<HUserNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HUserNotification_Order_By>>;
  where?: Maybe<HUserNotification_Bool_Exp>;
};


export type HSubscription_RootUserNotification_AggregateArgs = {
  distinct_on?: Maybe<Array<HUserNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HUserNotification_Order_By>>;
  where?: Maybe<HUserNotification_Bool_Exp>;
};


export type HSubscription_RootUserNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type HSubscription_RootAuth_AccountsArgs = {
  distinct_on?: Maybe<Array<HAuth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAuth_Accounts_Order_By>>;
  where?: Maybe<HAuth_Accounts_Bool_Exp>;
};


export type HSubscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<HUsers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HUsers_Order_By>>;
  where?: Maybe<HUsers_Bool_Exp>;
};


export type HSubscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type HTimestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type HUsers = {
  /** An array relationship */
  AnswerNotes: Array<HAnswerNote>;
  /** An array relationship */
  Answers: Array<HAnswer>;
  /** An array relationship */
  BusinessUnits: Array<HBusinessUnit>;
  /** An array relationship */
  Comments: Array<HComment>;
  /** An aggregate relationship */
  Comments_aggregate: HComment_Aggregate;
  /** An array relationship */
  Files: Array<HFile>;
  /** An object relationship */
  account?: Maybe<HAuth_Accounts>;
  avatar_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  companyUsers: Array<HCompanyUser>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  invitations: Array<HCompanyInvitation>;
  language: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  /** An array relationship */
  notifications: Array<HUserNotification>;
  /** An aggregate relationship */
  notifications_aggregate: HUserNotification_Aggregate;
  settings: Scalars['jsonb'];
  /** An array relationship */
  threadSubscriptions: Array<HThreadSubscriber>;
};


/** columns and relationships of "users" */
export type HUsersAnswerNotesArgs = {
  distinct_on?: Maybe<Array<HAnswerNote_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswerNote_Order_By>>;
  where?: Maybe<HAnswerNote_Bool_Exp>;
};


/** columns and relationships of "users" */
export type HUsersAnswersArgs = {
  distinct_on?: Maybe<Array<HAnswer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HAnswer_Order_By>>;
  where?: Maybe<HAnswer_Bool_Exp>;
};


/** columns and relationships of "users" */
export type HUsersBusinessUnitsArgs = {
  distinct_on?: Maybe<Array<HBusinessUnit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HBusinessUnit_Order_By>>;
  where?: Maybe<HBusinessUnit_Bool_Exp>;
};


/** columns and relationships of "users" */
export type HUsersCommentsArgs = {
  distinct_on?: Maybe<Array<HComment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HComment_Order_By>>;
  where?: Maybe<HComment_Bool_Exp>;
};


/** columns and relationships of "users" */
export type HUsersComments_AggregateArgs = {
  distinct_on?: Maybe<Array<HComment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HComment_Order_By>>;
  where?: Maybe<HComment_Bool_Exp>;
};


/** columns and relationships of "users" */
export type HUsersFilesArgs = {
  distinct_on?: Maybe<Array<HFile_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HFile_Order_By>>;
  where?: Maybe<HFile_Bool_Exp>;
};


/** columns and relationships of "users" */
export type HUsersCompanyUsersArgs = {
  distinct_on?: Maybe<Array<HCompanyUser_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyUser_Order_By>>;
  where?: Maybe<HCompanyUser_Bool_Exp>;
};


/** columns and relationships of "users" */
export type HUsersInvitationsArgs = {
  distinct_on?: Maybe<Array<HCompanyInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HCompanyInvitation_Order_By>>;
  where?: Maybe<HCompanyInvitation_Bool_Exp>;
};


/** columns and relationships of "users" */
export type HUsersNotificationsArgs = {
  distinct_on?: Maybe<Array<HUserNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HUserNotification_Order_By>>;
  where?: Maybe<HUserNotification_Bool_Exp>;
};


/** columns and relationships of "users" */
export type HUsersNotifications_AggregateArgs = {
  distinct_on?: Maybe<Array<HUserNotification_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HUserNotification_Order_By>>;
  where?: Maybe<HUserNotification_Bool_Exp>;
};


/** columns and relationships of "users" */
export type HUsersSettingsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type HUsersThreadSubscriptionsArgs = {
  distinct_on?: Maybe<Array<HThreadSubscriber_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<HThreadSubscriber_Order_By>>;
  where?: Maybe<HThreadSubscriber_Bool_Exp>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type HUsers_Append_Input = {
  settings?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type HUsers_Bool_Exp = {
  AnswerNotes?: Maybe<HAnswerNote_Bool_Exp>;
  Answers?: Maybe<HAnswer_Bool_Exp>;
  BusinessUnits?: Maybe<HBusinessUnit_Bool_Exp>;
  Comments?: Maybe<HComment_Bool_Exp>;
  Files?: Maybe<HFile_Bool_Exp>;
  _and?: Maybe<Array<HUsers_Bool_Exp>>;
  _not?: Maybe<HUsers_Bool_Exp>;
  _or?: Maybe<Array<HUsers_Bool_Exp>>;
  account?: Maybe<HAuth_Accounts_Bool_Exp>;
  avatar_url?: Maybe<HString_Comparison_Exp>;
  companyUsers?: Maybe<HCompanyUser_Bool_Exp>;
  display_name?: Maybe<HString_Comparison_Exp>;
  email?: Maybe<HString_Comparison_Exp>;
  firstName?: Maybe<HString_Comparison_Exp>;
  id?: Maybe<HUuid_Comparison_Exp>;
  invitations?: Maybe<HCompanyInvitation_Bool_Exp>;
  language?: Maybe<HString_Comparison_Exp>;
  lastName?: Maybe<HString_Comparison_Exp>;
  notifications?: Maybe<HUserNotification_Bool_Exp>;
  settings?: Maybe<HJsonb_Comparison_Exp>;
  threadSubscriptions?: Maybe<HThreadSubscriber_Bool_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type HUsers_Delete_At_Path_Input = {
  settings?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type HUsers_Delete_Elem_Input = {
  settings?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type HUsers_Delete_Key_Input = {
  settings?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type HUsers_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<HUsers>;
};

/** Ordering options when selecting data from "users". */
export type HUsers_Order_By = {
  AnswerNotes_aggregate?: Maybe<HAnswerNote_Aggregate_Order_By>;
  Answers_aggregate?: Maybe<HAnswer_Aggregate_Order_By>;
  BusinessUnits_aggregate?: Maybe<HBusinessUnit_Aggregate_Order_By>;
  Comments_aggregate?: Maybe<HComment_Aggregate_Order_By>;
  Files_aggregate?: Maybe<HFile_Aggregate_Order_By>;
  account?: Maybe<HAuth_Accounts_Order_By>;
  avatar_url?: Maybe<HOrder_By>;
  companyUsers_aggregate?: Maybe<HCompanyUser_Aggregate_Order_By>;
  display_name?: Maybe<HOrder_By>;
  email?: Maybe<HOrder_By>;
  firstName?: Maybe<HOrder_By>;
  id?: Maybe<HOrder_By>;
  invitations_aggregate?: Maybe<HCompanyInvitation_Aggregate_Order_By>;
  language?: Maybe<HOrder_By>;
  lastName?: Maybe<HOrder_By>;
  notifications_aggregate?: Maybe<HUserNotification_Aggregate_Order_By>;
  settings?: Maybe<HOrder_By>;
  threadSubscriptions_aggregate?: Maybe<HThreadSubscriber_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type HUsers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type HUsers_Prepend_Input = {
  settings?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "users" */
export enum HUsers_Select_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'firstName',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  LastName = 'lastName',
  /** column name */
  Settings = 'settings'
}

/** input type for updating data in table "users" */
export type HUsers_Set_Input = {
  avatar_url?: Maybe<Scalars['String']>;
  display_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type HUuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterator<TResult> | Promise<AsyncIterator<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type HResolversTypes = {
  Activity: ResolverTypeWrapper<HActivity>;
  ActivityNaceCode: ResolverTypeWrapper<HActivityNaceCode>;
  ActivityNaceCode_aggregate_order_by: HActivityNaceCode_Aggregate_Order_By;
  ActivityNaceCode_avg_order_by: HActivityNaceCode_Avg_Order_By;
  ActivityNaceCode_bool_exp: HActivityNaceCode_Bool_Exp;
  ActivityNaceCode_max_order_by: HActivityNaceCode_Max_Order_By;
  ActivityNaceCode_min_order_by: HActivityNaceCode_Min_Order_By;
  ActivityNaceCode_order_by: HActivityNaceCode_Order_By;
  ActivityNaceCode_select_column: HActivityNaceCode_Select_Column;
  ActivityNaceCode_stddev_order_by: HActivityNaceCode_Stddev_Order_By;
  ActivityNaceCode_stddev_pop_order_by: HActivityNaceCode_Stddev_Pop_Order_By;
  ActivityNaceCode_stddev_samp_order_by: HActivityNaceCode_Stddev_Samp_Order_By;
  ActivityNaceCode_sum_order_by: HActivityNaceCode_Sum_Order_By;
  ActivityNaceCode_var_pop_order_by: HActivityNaceCode_Var_Pop_Order_By;
  ActivityNaceCode_var_samp_order_by: HActivityNaceCode_Var_Samp_Order_By;
  ActivityNaceCode_variance_order_by: HActivityNaceCode_Variance_Order_By;
  ActivityQuestionSet: ResolverTypeWrapper<HActivityQuestionSet>;
  ActivityQuestionSet_aggregate_order_by: HActivityQuestionSet_Aggregate_Order_By;
  ActivityQuestionSet_avg_order_by: HActivityQuestionSet_Avg_Order_By;
  ActivityQuestionSet_bool_exp: HActivityQuestionSet_Bool_Exp;
  ActivityQuestionSet_max_order_by: HActivityQuestionSet_Max_Order_By;
  ActivityQuestionSet_min_order_by: HActivityQuestionSet_Min_Order_By;
  ActivityQuestionSet_order_by: HActivityQuestionSet_Order_By;
  ActivityQuestionSet_select_column: HActivityQuestionSet_Select_Column;
  ActivityQuestionSet_stddev_order_by: HActivityQuestionSet_Stddev_Order_By;
  ActivityQuestionSet_stddev_pop_order_by: HActivityQuestionSet_Stddev_Pop_Order_By;
  ActivityQuestionSet_stddev_samp_order_by: HActivityQuestionSet_Stddev_Samp_Order_By;
  ActivityQuestionSet_sum_order_by: HActivityQuestionSet_Sum_Order_By;
  ActivityQuestionSet_var_pop_order_by: HActivityQuestionSet_Var_Pop_Order_By;
  ActivityQuestionSet_var_samp_order_by: HActivityQuestionSet_Var_Samp_Order_By;
  ActivityQuestionSet_variance_order_by: HActivityQuestionSet_Variance_Order_By;
  ActivityReport: ResolverTypeWrapper<HActivityReport>;
  ActivityReport_aggregate_order_by: HActivityReport_Aggregate_Order_By;
  ActivityReport_arr_rel_insert_input: HActivityReport_Arr_Rel_Insert_Input;
  ActivityReport_bool_exp: HActivityReport_Bool_Exp;
  ActivityReport_constraint: HActivityReport_Constraint;
  ActivityReport_insert_input: HActivityReport_Insert_Input;
  ActivityReport_max_order_by: HActivityReport_Max_Order_By;
  ActivityReport_min_order_by: HActivityReport_Min_Order_By;
  ActivityReport_mutation_response: ResolverTypeWrapper<HActivityReport_Mutation_Response>;
  ActivityReport_obj_rel_insert_input: HActivityReport_Obj_Rel_Insert_Input;
  ActivityReport_on_conflict: HActivityReport_On_Conflict;
  ActivityReport_order_by: HActivityReport_Order_By;
  ActivityReport_pk_columns_input: HActivityReport_Pk_Columns_Input;
  ActivityReport_select_column: HActivityReport_Select_Column;
  ActivityReport_set_input: HActivityReport_Set_Input;
  ActivityReport_update_column: HActivityReport_Update_Column;
  Activity_aggregate_order_by: HActivity_Aggregate_Order_By;
  Activity_bool_exp: HActivity_Bool_Exp;
  Activity_max_order_by: HActivity_Max_Order_By;
  Activity_min_order_by: HActivity_Min_Order_By;
  Activity_order_by: HActivity_Order_By;
  Activity_select_column: HActivity_Select_Column;
  Answer: ResolverTypeWrapper<HAnswer>;
  AnswerAttachment: ResolverTypeWrapper<HAnswerAttachment>;
  AnswerAttachment_aggregate_order_by: HAnswerAttachment_Aggregate_Order_By;
  AnswerAttachment_arr_rel_insert_input: HAnswerAttachment_Arr_Rel_Insert_Input;
  AnswerAttachment_bool_exp: HAnswerAttachment_Bool_Exp;
  AnswerAttachment_constraint: HAnswerAttachment_Constraint;
  AnswerAttachment_insert_input: HAnswerAttachment_Insert_Input;
  AnswerAttachment_max_order_by: HAnswerAttachment_Max_Order_By;
  AnswerAttachment_min_order_by: HAnswerAttachment_Min_Order_By;
  AnswerAttachment_mutation_response: ResolverTypeWrapper<HAnswerAttachment_Mutation_Response>;
  AnswerAttachment_on_conflict: HAnswerAttachment_On_Conflict;
  AnswerAttachment_order_by: HAnswerAttachment_Order_By;
  AnswerAttachment_pk_columns_input: HAnswerAttachment_Pk_Columns_Input;
  AnswerAttachment_select_column: HAnswerAttachment_Select_Column;
  AnswerAttachment_set_input: HAnswerAttachment_Set_Input;
  AnswerAttachment_update_column: HAnswerAttachment_Update_Column;
  AnswerNote: ResolverTypeWrapper<HAnswerNote>;
  AnswerNote_aggregate_order_by: HAnswerNote_Aggregate_Order_By;
  AnswerNote_arr_rel_insert_input: HAnswerNote_Arr_Rel_Insert_Input;
  AnswerNote_bool_exp: HAnswerNote_Bool_Exp;
  AnswerNote_insert_input: HAnswerNote_Insert_Input;
  AnswerNote_max_order_by: HAnswerNote_Max_Order_By;
  AnswerNote_min_order_by: HAnswerNote_Min_Order_By;
  AnswerNote_mutation_response: ResolverTypeWrapper<HAnswerNote_Mutation_Response>;
  AnswerNote_order_by: HAnswerNote_Order_By;
  AnswerNote_select_column: HAnswerNote_Select_Column;
  Answer_aggregate_order_by: HAnswer_Aggregate_Order_By;
  Answer_append_input: HAnswer_Append_Input;
  Answer_arr_rel_insert_input: HAnswer_Arr_Rel_Insert_Input;
  Answer_bool_exp: HAnswer_Bool_Exp;
  Answer_constraint: HAnswer_Constraint;
  Answer_delete_at_path_input: HAnswer_Delete_At_Path_Input;
  Answer_delete_elem_input: HAnswer_Delete_Elem_Input;
  Answer_delete_key_input: HAnswer_Delete_Key_Input;
  Answer_insert_input: HAnswer_Insert_Input;
  Answer_max_order_by: HAnswer_Max_Order_By;
  Answer_min_order_by: HAnswer_Min_Order_By;
  Answer_mutation_response: ResolverTypeWrapper<HAnswer_Mutation_Response>;
  Answer_obj_rel_insert_input: HAnswer_Obj_Rel_Insert_Input;
  Answer_on_conflict: HAnswer_On_Conflict;
  Answer_order_by: HAnswer_Order_By;
  Answer_pk_columns_input: HAnswer_Pk_Columns_Input;
  Answer_prepend_input: HAnswer_Prepend_Input;
  Answer_select_column: HAnswer_Select_Column;
  Answer_set_input: HAnswer_Set_Input;
  Answer_update_column: HAnswer_Update_Column;
  Article: ResolverTypeWrapper<HArticle>;
  Article_bool_exp: HArticle_Bool_Exp;
  Article_order_by: HArticle_Order_By;
  Article_select_column: HArticle_Select_Column;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  Boolean_comparison_exp: HBoolean_Comparison_Exp;
  BusinessUnit: ResolverTypeWrapper<HBusinessUnit>;
  BusinessUnitAssessment: ResolverTypeWrapper<HBusinessUnitAssessment>;
  BusinessUnitAssessment_aggregate_order_by: HBusinessUnitAssessment_Aggregate_Order_By;
  BusinessUnitAssessment_arr_rel_insert_input: HBusinessUnitAssessment_Arr_Rel_Insert_Input;
  BusinessUnitAssessment_bool_exp: HBusinessUnitAssessment_Bool_Exp;
  BusinessUnitAssessment_constraint: HBusinessUnitAssessment_Constraint;
  BusinessUnitAssessment_insert_input: HBusinessUnitAssessment_Insert_Input;
  BusinessUnitAssessment_max_order_by: HBusinessUnitAssessment_Max_Order_By;
  BusinessUnitAssessment_min_order_by: HBusinessUnitAssessment_Min_Order_By;
  BusinessUnitAssessment_mutation_response: ResolverTypeWrapper<HBusinessUnitAssessment_Mutation_Response>;
  BusinessUnitAssessment_obj_rel_insert_input: HBusinessUnitAssessment_Obj_Rel_Insert_Input;
  BusinessUnitAssessment_on_conflict: HBusinessUnitAssessment_On_Conflict;
  BusinessUnitAssessment_order_by: HBusinessUnitAssessment_Order_By;
  BusinessUnitAssessment_pk_columns_input: HBusinessUnitAssessment_Pk_Columns_Input;
  BusinessUnitAssessment_select_column: HBusinessUnitAssessment_Select_Column;
  BusinessUnitAssessment_set_input: HBusinessUnitAssessment_Set_Input;
  BusinessUnitAssessment_update_column: HBusinessUnitAssessment_Update_Column;
  BusinessUnitDistributionKey: ResolverTypeWrapper<HBusinessUnitDistributionKey>;
  BusinessUnitDistributionKey_aggregate_order_by: HBusinessUnitDistributionKey_Aggregate_Order_By;
  BusinessUnitDistributionKey_arr_rel_insert_input: HBusinessUnitDistributionKey_Arr_Rel_Insert_Input;
  BusinessUnitDistributionKey_avg_order_by: HBusinessUnitDistributionKey_Avg_Order_By;
  BusinessUnitDistributionKey_bool_exp: HBusinessUnitDistributionKey_Bool_Exp;
  BusinessUnitDistributionKey_constraint: HBusinessUnitDistributionKey_Constraint;
  BusinessUnitDistributionKey_inc_input: HBusinessUnitDistributionKey_Inc_Input;
  BusinessUnitDistributionKey_insert_input: HBusinessUnitDistributionKey_Insert_Input;
  BusinessUnitDistributionKey_max_order_by: HBusinessUnitDistributionKey_Max_Order_By;
  BusinessUnitDistributionKey_min_order_by: HBusinessUnitDistributionKey_Min_Order_By;
  BusinessUnitDistributionKey_mutation_response: ResolverTypeWrapper<HBusinessUnitDistributionKey_Mutation_Response>;
  BusinessUnitDistributionKey_on_conflict: HBusinessUnitDistributionKey_On_Conflict;
  BusinessUnitDistributionKey_order_by: HBusinessUnitDistributionKey_Order_By;
  BusinessUnitDistributionKey_pk_columns_input: HBusinessUnitDistributionKey_Pk_Columns_Input;
  BusinessUnitDistributionKey_select_column: HBusinessUnitDistributionKey_Select_Column;
  BusinessUnitDistributionKey_set_input: HBusinessUnitDistributionKey_Set_Input;
  BusinessUnitDistributionKey_stddev_order_by: HBusinessUnitDistributionKey_Stddev_Order_By;
  BusinessUnitDistributionKey_stddev_pop_order_by: HBusinessUnitDistributionKey_Stddev_Pop_Order_By;
  BusinessUnitDistributionKey_stddev_samp_order_by: HBusinessUnitDistributionKey_Stddev_Samp_Order_By;
  BusinessUnitDistributionKey_sum_order_by: HBusinessUnitDistributionKey_Sum_Order_By;
  BusinessUnitDistributionKey_update_column: HBusinessUnitDistributionKey_Update_Column;
  BusinessUnitDistributionKey_var_pop_order_by: HBusinessUnitDistributionKey_Var_Pop_Order_By;
  BusinessUnitDistributionKey_var_samp_order_by: HBusinessUnitDistributionKey_Var_Samp_Order_By;
  BusinessUnitDistributionKey_variance_order_by: HBusinessUnitDistributionKey_Variance_Order_By;
  BusinessUnit_aggregate_order_by: HBusinessUnit_Aggregate_Order_By;
  BusinessUnit_append_input: HBusinessUnit_Append_Input;
  BusinessUnit_arr_rel_insert_input: HBusinessUnit_Arr_Rel_Insert_Input;
  BusinessUnit_bool_exp: HBusinessUnit_Bool_Exp;
  BusinessUnit_constraint: HBusinessUnit_Constraint;
  BusinessUnit_delete_at_path_input: HBusinessUnit_Delete_At_Path_Input;
  BusinessUnit_delete_elem_input: HBusinessUnit_Delete_Elem_Input;
  BusinessUnit_delete_key_input: HBusinessUnit_Delete_Key_Input;
  BusinessUnit_insert_input: HBusinessUnit_Insert_Input;
  BusinessUnit_max_order_by: HBusinessUnit_Max_Order_By;
  BusinessUnit_min_order_by: HBusinessUnit_Min_Order_By;
  BusinessUnit_mutation_response: ResolverTypeWrapper<HBusinessUnit_Mutation_Response>;
  BusinessUnit_obj_rel_insert_input: HBusinessUnit_Obj_Rel_Insert_Input;
  BusinessUnit_on_conflict: HBusinessUnit_On_Conflict;
  BusinessUnit_order_by: HBusinessUnit_Order_By;
  BusinessUnit_pk_columns_input: HBusinessUnit_Pk_Columns_Input;
  BusinessUnit_prepend_input: HBusinessUnit_Prepend_Input;
  BusinessUnit_select_column: HBusinessUnit_Select_Column;
  BusinessUnit_set_input: HBusinessUnit_Set_Input;
  BusinessUnit_update_column: HBusinessUnit_Update_Column;
  Comment: ResolverTypeWrapper<HComment>;
  Comment_aggregate: ResolverTypeWrapper<HComment_Aggregate>;
  Comment_aggregate_fields: ResolverTypeWrapper<HComment_Aggregate_Fields>;
  Comment_aggregate_order_by: HComment_Aggregate_Order_By;
  Comment_arr_rel_insert_input: HComment_Arr_Rel_Insert_Input;
  Comment_bool_exp: HComment_Bool_Exp;
  Comment_constraint: HComment_Constraint;
  Comment_insert_input: HComment_Insert_Input;
  Comment_max_fields: ResolverTypeWrapper<HComment_Max_Fields>;
  Comment_max_order_by: HComment_Max_Order_By;
  Comment_min_fields: ResolverTypeWrapper<HComment_Min_Fields>;
  Comment_min_order_by: HComment_Min_Order_By;
  Comment_mutation_response: ResolverTypeWrapper<HComment_Mutation_Response>;
  Comment_obj_rel_insert_input: HComment_Obj_Rel_Insert_Input;
  Comment_on_conflict: HComment_On_Conflict;
  Comment_order_by: HComment_Order_By;
  Comment_pk_columns_input: HComment_Pk_Columns_Input;
  Comment_select_column: HComment_Select_Column;
  Comment_set_input: HComment_Set_Input;
  Comment_update_column: HComment_Update_Column;
  Company: ResolverTypeWrapper<HCompany>;
  CompanyAssessment: ResolverTypeWrapper<HCompanyAssessment>;
  CompanyAssessmentAggregate: ResolverTypeWrapper<HCompanyAssessmentAggregate>;
  CompanyAssessmentAggregate_aggregate_order_by: HCompanyAssessmentAggregate_Aggregate_Order_By;
  CompanyAssessmentAggregate_arr_rel_insert_input: HCompanyAssessmentAggregate_Arr_Rel_Insert_Input;
  CompanyAssessmentAggregate_bool_exp: HCompanyAssessmentAggregate_Bool_Exp;
  CompanyAssessmentAggregate_constraint: HCompanyAssessmentAggregate_Constraint;
  CompanyAssessmentAggregate_insert_input: HCompanyAssessmentAggregate_Insert_Input;
  CompanyAssessmentAggregate_max_order_by: HCompanyAssessmentAggregate_Max_Order_By;
  CompanyAssessmentAggregate_min_order_by: HCompanyAssessmentAggregate_Min_Order_By;
  CompanyAssessmentAggregate_mutation_response: ResolverTypeWrapper<HCompanyAssessmentAggregate_Mutation_Response>;
  CompanyAssessmentAggregate_obj_rel_insert_input: HCompanyAssessmentAggregate_Obj_Rel_Insert_Input;
  CompanyAssessmentAggregate_on_conflict: HCompanyAssessmentAggregate_On_Conflict;
  CompanyAssessmentAggregate_order_by: HCompanyAssessmentAggregate_Order_By;
  CompanyAssessmentAggregate_pk_columns_input: HCompanyAssessmentAggregate_Pk_Columns_Input;
  CompanyAssessmentAggregate_select_column: HCompanyAssessmentAggregate_Select_Column;
  CompanyAssessmentAggregate_set_input: HCompanyAssessmentAggregate_Set_Input;
  CompanyAssessmentAggregate_update_column: HCompanyAssessmentAggregate_Update_Column;
  CompanyAssessment_aggregate_order_by: HCompanyAssessment_Aggregate_Order_By;
  CompanyAssessment_arr_rel_insert_input: HCompanyAssessment_Arr_Rel_Insert_Input;
  CompanyAssessment_bool_exp: HCompanyAssessment_Bool_Exp;
  CompanyAssessment_constraint: HCompanyAssessment_Constraint;
  CompanyAssessment_insert_input: HCompanyAssessment_Insert_Input;
  CompanyAssessment_max_order_by: HCompanyAssessment_Max_Order_By;
  CompanyAssessment_min_order_by: HCompanyAssessment_Min_Order_By;
  CompanyAssessment_mutation_response: ResolverTypeWrapper<HCompanyAssessment_Mutation_Response>;
  CompanyAssessment_obj_rel_insert_input: HCompanyAssessment_Obj_Rel_Insert_Input;
  CompanyAssessment_on_conflict: HCompanyAssessment_On_Conflict;
  CompanyAssessment_order_by: HCompanyAssessment_Order_By;
  CompanyAssessment_pk_columns_input: HCompanyAssessment_Pk_Columns_Input;
  CompanyAssessment_select_column: HCompanyAssessment_Select_Column;
  CompanyAssessment_set_input: HCompanyAssessment_Set_Input;
  CompanyAssessment_update_column: HCompanyAssessment_Update_Column;
  CompanyInvitation: ResolverTypeWrapper<HCompanyInvitation>;
  CompanyInvitation_aggregate_order_by: HCompanyInvitation_Aggregate_Order_By;
  CompanyInvitation_arr_rel_insert_input: HCompanyInvitation_Arr_Rel_Insert_Input;
  CompanyInvitation_bool_exp: HCompanyInvitation_Bool_Exp;
  CompanyInvitation_constraint: HCompanyInvitation_Constraint;
  CompanyInvitation_insert_input: HCompanyInvitation_Insert_Input;
  CompanyInvitation_max_order_by: HCompanyInvitation_Max_Order_By;
  CompanyInvitation_min_order_by: HCompanyInvitation_Min_Order_By;
  CompanyInvitation_mutation_response: ResolverTypeWrapper<HCompanyInvitation_Mutation_Response>;
  CompanyInvitation_on_conflict: HCompanyInvitation_On_Conflict;
  CompanyInvitation_order_by: HCompanyInvitation_Order_By;
  CompanyInvitation_pk_columns_input: HCompanyInvitation_Pk_Columns_Input;
  CompanyInvitation_select_column: HCompanyInvitation_Select_Column;
  CompanyInvitation_set_input: HCompanyInvitation_Set_Input;
  CompanyInvitation_update_column: HCompanyInvitation_Update_Column;
  CompanyUser: ResolverTypeWrapper<HCompanyUser>;
  CompanyUser_aggregate_order_by: HCompanyUser_Aggregate_Order_By;
  CompanyUser_arr_rel_insert_input: HCompanyUser_Arr_Rel_Insert_Input;
  CompanyUser_avg_order_by: HCompanyUser_Avg_Order_By;
  CompanyUser_bool_exp: HCompanyUser_Bool_Exp;
  CompanyUser_constraint: HCompanyUser_Constraint;
  CompanyUser_insert_input: HCompanyUser_Insert_Input;
  CompanyUser_max_order_by: HCompanyUser_Max_Order_By;
  CompanyUser_min_order_by: HCompanyUser_Min_Order_By;
  CompanyUser_mutation_response: ResolverTypeWrapper<HCompanyUser_Mutation_Response>;
  CompanyUser_on_conflict: HCompanyUser_On_Conflict;
  CompanyUser_order_by: HCompanyUser_Order_By;
  CompanyUser_pk_columns_input: HCompanyUser_Pk_Columns_Input;
  CompanyUser_select_column: HCompanyUser_Select_Column;
  CompanyUser_set_input: HCompanyUser_Set_Input;
  CompanyUser_stddev_order_by: HCompanyUser_Stddev_Order_By;
  CompanyUser_stddev_pop_order_by: HCompanyUser_Stddev_Pop_Order_By;
  CompanyUser_stddev_samp_order_by: HCompanyUser_Stddev_Samp_Order_By;
  CompanyUser_sum_order_by: HCompanyUser_Sum_Order_By;
  CompanyUser_update_column: HCompanyUser_Update_Column;
  CompanyUser_var_pop_order_by: HCompanyUser_Var_Pop_Order_By;
  CompanyUser_var_samp_order_by: HCompanyUser_Var_Samp_Order_By;
  CompanyUser_variance_order_by: HCompanyUser_Variance_Order_By;
  Company_append_input: HCompany_Append_Input;
  Company_bool_exp: HCompany_Bool_Exp;
  Company_constraint: HCompany_Constraint;
  Company_delete_at_path_input: HCompany_Delete_At_Path_Input;
  Company_delete_elem_input: HCompany_Delete_Elem_Input;
  Company_delete_key_input: HCompany_Delete_Key_Input;
  Company_inc_input: HCompany_Inc_Input;
  Company_insert_input: HCompany_Insert_Input;
  Company_mutation_response: ResolverTypeWrapper<HCompany_Mutation_Response>;
  Company_obj_rel_insert_input: HCompany_Obj_Rel_Insert_Input;
  Company_on_conflict: HCompany_On_Conflict;
  Company_order_by: HCompany_Order_By;
  Company_pk_columns_input: HCompany_Pk_Columns_Input;
  Company_prepend_input: HCompany_Prepend_Input;
  Company_select_column: HCompany_Select_Column;
  Company_set_input: HCompany_Set_Input;
  Company_update_column: HCompany_Update_Column;
  EmailItem: HEmailItem;
  EstimateCompany: ResolverTypeWrapper<HEstimateCompany>;
  EstimateCompany_bool_exp: HEstimateCompany_Bool_Exp;
  EstimateCompany_order_by: HEstimateCompany_Order_By;
  EstimateCompany_select_column: HEstimateCompany_Select_Column;
  File: ResolverTypeWrapper<HFile>;
  File_aggregate_order_by: HFile_Aggregate_Order_By;
  File_append_input: HFile_Append_Input;
  File_arr_rel_insert_input: HFile_Arr_Rel_Insert_Input;
  File_bool_exp: HFile_Bool_Exp;
  File_constraint: HFile_Constraint;
  File_delete_at_path_input: HFile_Delete_At_Path_Input;
  File_delete_elem_input: HFile_Delete_Elem_Input;
  File_delete_key_input: HFile_Delete_Key_Input;
  File_insert_input: HFile_Insert_Input;
  File_max_order_by: HFile_Max_Order_By;
  File_min_order_by: HFile_Min_Order_By;
  File_mutation_response: ResolverTypeWrapper<HFile_Mutation_Response>;
  File_obj_rel_insert_input: HFile_Obj_Rel_Insert_Input;
  File_on_conflict: HFile_On_Conflict;
  File_order_by: HFile_Order_By;
  File_pk_columns_input: HFile_Pk_Columns_Input;
  File_prepend_input: HFile_Prepend_Input;
  File_select_column: HFile_Select_Column;
  File_set_input: HFile_Set_Input;
  File_update_column: HFile_Update_Column;
  Financials: ResolverTypeWrapper<HFinancials>;
  Financials_bool_exp: HFinancials_Bool_Exp;
  Financials_constraint: HFinancials_Constraint;
  Financials_inc_input: HFinancials_Inc_Input;
  Financials_insert_input: HFinancials_Insert_Input;
  Financials_mutation_response: ResolverTypeWrapper<HFinancials_Mutation_Response>;
  Financials_obj_rel_insert_input: HFinancials_Obj_Rel_Insert_Input;
  Financials_on_conflict: HFinancials_On_Conflict;
  Financials_order_by: HFinancials_Order_By;
  Financials_pk_columns_input: HFinancials_Pk_Columns_Input;
  Financials_select_column: HFinancials_Select_Column;
  Financials_set_input: HFinancials_Set_Input;
  Financials_update_column: HFinancials_Update_Column;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  Int_comparison_exp: HInt_Comparison_Exp;
  InvitationOutput: ResolverTypeWrapper<HInvitationOutput>;
  InvitationQuery: HInvitationQuery;
  NudgePortfolioCompaniesInput: HNudgePortfolioCompaniesInput;
  NudgePortfolioCompaniesOutput: ResolverTypeWrapper<HNudgePortfolioCompaniesOutput>;
  Objective: ResolverTypeWrapper<HObjective>;
  Objective_bool_exp: HObjective_Bool_Exp;
  Objective_order_by: HObjective_Order_By;
  Objective_select_column: HObjective_Select_Column;
  Portfolio: ResolverTypeWrapper<HPortfolio>;
  PortfolioCompany: ResolverTypeWrapper<HPortfolioCompany>;
  PortfolioCompany_aggregate_order_by: HPortfolioCompany_Aggregate_Order_By;
  PortfolioCompany_append_input: HPortfolioCompany_Append_Input;
  PortfolioCompany_arr_rel_insert_input: HPortfolioCompany_Arr_Rel_Insert_Input;
  PortfolioCompany_avg_order_by: HPortfolioCompany_Avg_Order_By;
  PortfolioCompany_bool_exp: HPortfolioCompany_Bool_Exp;
  PortfolioCompany_constraint: HPortfolioCompany_Constraint;
  PortfolioCompany_delete_at_path_input: HPortfolioCompany_Delete_At_Path_Input;
  PortfolioCompany_delete_elem_input: HPortfolioCompany_Delete_Elem_Input;
  PortfolioCompany_delete_key_input: HPortfolioCompany_Delete_Key_Input;
  PortfolioCompany_inc_input: HPortfolioCompany_Inc_Input;
  PortfolioCompany_insert_input: HPortfolioCompany_Insert_Input;
  PortfolioCompany_max_order_by: HPortfolioCompany_Max_Order_By;
  PortfolioCompany_min_order_by: HPortfolioCompany_Min_Order_By;
  PortfolioCompany_mutation_response: ResolverTypeWrapper<HPortfolioCompany_Mutation_Response>;
  PortfolioCompany_on_conflict: HPortfolioCompany_On_Conflict;
  PortfolioCompany_order_by: HPortfolioCompany_Order_By;
  PortfolioCompany_pk_columns_input: HPortfolioCompany_Pk_Columns_Input;
  PortfolioCompany_prepend_input: HPortfolioCompany_Prepend_Input;
  PortfolioCompany_select_column: HPortfolioCompany_Select_Column;
  PortfolioCompany_set_input: HPortfolioCompany_Set_Input;
  PortfolioCompany_stddev_order_by: HPortfolioCompany_Stddev_Order_By;
  PortfolioCompany_stddev_pop_order_by: HPortfolioCompany_Stddev_Pop_Order_By;
  PortfolioCompany_stddev_samp_order_by: HPortfolioCompany_Stddev_Samp_Order_By;
  PortfolioCompany_sum_order_by: HPortfolioCompany_Sum_Order_By;
  PortfolioCompany_update_column: HPortfolioCompany_Update_Column;
  PortfolioCompany_var_pop_order_by: HPortfolioCompany_Var_Pop_Order_By;
  PortfolioCompany_var_samp_order_by: HPortfolioCompany_Var_Samp_Order_By;
  PortfolioCompany_variance_order_by: HPortfolioCompany_Variance_Order_By;
  Portfolio_aggregate_order_by: HPortfolio_Aggregate_Order_By;
  Portfolio_arr_rel_insert_input: HPortfolio_Arr_Rel_Insert_Input;
  Portfolio_avg_order_by: HPortfolio_Avg_Order_By;
  Portfolio_bool_exp: HPortfolio_Bool_Exp;
  Portfolio_constraint: HPortfolio_Constraint;
  Portfolio_inc_input: HPortfolio_Inc_Input;
  Portfolio_insert_input: HPortfolio_Insert_Input;
  Portfolio_max_order_by: HPortfolio_Max_Order_By;
  Portfolio_min_order_by: HPortfolio_Min_Order_By;
  Portfolio_mutation_response: ResolverTypeWrapper<HPortfolio_Mutation_Response>;
  Portfolio_obj_rel_insert_input: HPortfolio_Obj_Rel_Insert_Input;
  Portfolio_on_conflict: HPortfolio_On_Conflict;
  Portfolio_order_by: HPortfolio_Order_By;
  Portfolio_pk_columns_input: HPortfolio_Pk_Columns_Input;
  Portfolio_select_column: HPortfolio_Select_Column;
  Portfolio_set_input: HPortfolio_Set_Input;
  Portfolio_stddev_order_by: HPortfolio_Stddev_Order_By;
  Portfolio_stddev_pop_order_by: HPortfolio_Stddev_Pop_Order_By;
  Portfolio_stddev_samp_order_by: HPortfolio_Stddev_Samp_Order_By;
  Portfolio_sum_order_by: HPortfolio_Sum_Order_By;
  Portfolio_update_column: HPortfolio_Update_Column;
  Portfolio_var_pop_order_by: HPortfolio_Var_Pop_Order_By;
  Portfolio_var_samp_order_by: HPortfolio_Var_Samp_Order_By;
  Portfolio_variance_order_by: HPortfolio_Variance_Order_By;
  Question: ResolverTypeWrapper<HQuestion>;
  QuestionChoice: ResolverTypeWrapper<HQuestionChoice>;
  QuestionChoice_aggregate_order_by: HQuestionChoice_Aggregate_Order_By;
  QuestionChoice_avg_order_by: HQuestionChoice_Avg_Order_By;
  QuestionChoice_bool_exp: HQuestionChoice_Bool_Exp;
  QuestionChoice_max_order_by: HQuestionChoice_Max_Order_By;
  QuestionChoice_min_order_by: HQuestionChoice_Min_Order_By;
  QuestionChoice_order_by: HQuestionChoice_Order_By;
  QuestionChoice_select_column: HQuestionChoice_Select_Column;
  QuestionChoice_stddev_order_by: HQuestionChoice_Stddev_Order_By;
  QuestionChoice_stddev_pop_order_by: HQuestionChoice_Stddev_Pop_Order_By;
  QuestionChoice_stddev_samp_order_by: HQuestionChoice_Stddev_Samp_Order_By;
  QuestionChoice_sum_order_by: HQuestionChoice_Sum_Order_By;
  QuestionChoice_var_pop_order_by: HQuestionChoice_Var_Pop_Order_By;
  QuestionChoice_var_samp_order_by: HQuestionChoice_Var_Samp_Order_By;
  QuestionChoice_variance_order_by: HQuestionChoice_Variance_Order_By;
  QuestionLevel_enum: HQuestionLevel_Enum;
  QuestionLevel_enum_comparison_exp: HQuestionLevel_Enum_Comparison_Exp;
  QuestionSet: ResolverTypeWrapper<HQuestionSet>;
  QuestionSet_bool_exp: HQuestionSet_Bool_Exp;
  QuestionSet_order_by: HQuestionSet_Order_By;
  QuestionSet_select_column: HQuestionSet_Select_Column;
  QuestionType_enum: HQuestionType_Enum;
  QuestionType_enum_comparison_exp: HQuestionType_Enum_Comparison_Exp;
  Question_aggregate_order_by: HQuestion_Aggregate_Order_By;
  Question_bool_exp: HQuestion_Bool_Exp;
  Question_max_order_by: HQuestion_Max_Order_By;
  Question_min_order_by: HQuestion_Min_Order_By;
  Question_order_by: HQuestion_Order_By;
  Question_select_column: HQuestion_Select_Column;
  RequestQuoteInput: HRequestQuoteInput;
  RequestQuoteOutput: ResolverTypeWrapper<HRequestQuoteOutput>;
  Sector: ResolverTypeWrapper<HSector>;
  Sector_bool_exp: HSector_Bool_Exp;
  Sector_order_by: HSector_Order_By;
  Sector_select_column: HSector_Select_Column;
  String: ResolverTypeWrapper<Scalars['String']>;
  String_comparison_exp: HString_Comparison_Exp;
  Tag: ResolverTypeWrapper<HTag>;
  Tag_articles: ResolverTypeWrapper<HTag_Articles>;
  Tag_articles_aggregate_order_by: HTag_Articles_Aggregate_Order_By;
  Tag_articles_avg_order_by: HTag_Articles_Avg_Order_By;
  Tag_articles_bool_exp: HTag_Articles_Bool_Exp;
  Tag_articles_max_order_by: HTag_Articles_Max_Order_By;
  Tag_articles_min_order_by: HTag_Articles_Min_Order_By;
  Tag_articles_order_by: HTag_Articles_Order_By;
  Tag_articles_select_column: HTag_Articles_Select_Column;
  Tag_articles_stddev_order_by: HTag_Articles_Stddev_Order_By;
  Tag_articles_stddev_pop_order_by: HTag_Articles_Stddev_Pop_Order_By;
  Tag_articles_stddev_samp_order_by: HTag_Articles_Stddev_Samp_Order_By;
  Tag_articles_sum_order_by: HTag_Articles_Sum_Order_By;
  Tag_articles_var_pop_order_by: HTag_Articles_Var_Pop_Order_By;
  Tag_articles_var_samp_order_by: HTag_Articles_Var_Samp_Order_By;
  Tag_articles_variance_order_by: HTag_Articles_Variance_Order_By;
  Tag_bool_exp: HTag_Bool_Exp;
  Tag_order_by: HTag_Order_By;
  Tag_select_column: HTag_Select_Column;
  Thread: ResolverTypeWrapper<HThread>;
  ThreadSubscriber: ResolverTypeWrapper<HThreadSubscriber>;
  ThreadSubscriber_aggregate_order_by: HThreadSubscriber_Aggregate_Order_By;
  ThreadSubscriber_arr_rel_insert_input: HThreadSubscriber_Arr_Rel_Insert_Input;
  ThreadSubscriber_bool_exp: HThreadSubscriber_Bool_Exp;
  ThreadSubscriber_constraint: HThreadSubscriber_Constraint;
  ThreadSubscriber_insert_input: HThreadSubscriber_Insert_Input;
  ThreadSubscriber_max_order_by: HThreadSubscriber_Max_Order_By;
  ThreadSubscriber_min_order_by: HThreadSubscriber_Min_Order_By;
  ThreadSubscriber_mutation_response: ResolverTypeWrapper<HThreadSubscriber_Mutation_Response>;
  ThreadSubscriber_on_conflict: HThreadSubscriber_On_Conflict;
  ThreadSubscriber_order_by: HThreadSubscriber_Order_By;
  ThreadSubscriber_pk_columns_input: HThreadSubscriber_Pk_Columns_Input;
  ThreadSubscriber_select_column: HThreadSubscriber_Select_Column;
  ThreadSubscriber_set_input: HThreadSubscriber_Set_Input;
  ThreadSubscriber_update_column: HThreadSubscriber_Update_Column;
  Thread_aggregate_order_by: HThread_Aggregate_Order_By;
  Thread_arr_rel_insert_input: HThread_Arr_Rel_Insert_Input;
  Thread_bool_exp: HThread_Bool_Exp;
  Thread_constraint: HThread_Constraint;
  Thread_insert_input: HThread_Insert_Input;
  Thread_max_order_by: HThread_Max_Order_By;
  Thread_min_order_by: HThread_Min_Order_By;
  Thread_mutation_response: ResolverTypeWrapper<HThread_Mutation_Response>;
  Thread_obj_rel_insert_input: HThread_Obj_Rel_Insert_Input;
  Thread_on_conflict: HThread_On_Conflict;
  Thread_order_by: HThread_Order_By;
  Thread_pk_columns_input: HThread_Pk_Columns_Input;
  Thread_select_column: HThread_Select_Column;
  Thread_set_input: HThread_Set_Input;
  Thread_update_column: HThread_Update_Column;
  UserNotification: ResolverTypeWrapper<HUserNotification>;
  UserNotification_aggregate: ResolverTypeWrapper<HUserNotification_Aggregate>;
  UserNotification_aggregate_fields: ResolverTypeWrapper<HUserNotification_Aggregate_Fields>;
  UserNotification_aggregate_order_by: HUserNotification_Aggregate_Order_By;
  UserNotification_arr_rel_insert_input: HUserNotification_Arr_Rel_Insert_Input;
  UserNotification_bool_exp: HUserNotification_Bool_Exp;
  UserNotification_constraint: HUserNotification_Constraint;
  UserNotification_insert_input: HUserNotification_Insert_Input;
  UserNotification_max_fields: ResolverTypeWrapper<HUserNotification_Max_Fields>;
  UserNotification_max_order_by: HUserNotification_Max_Order_By;
  UserNotification_min_fields: ResolverTypeWrapper<HUserNotification_Min_Fields>;
  UserNotification_min_order_by: HUserNotification_Min_Order_By;
  UserNotification_mutation_response: ResolverTypeWrapper<HUserNotification_Mutation_Response>;
  UserNotification_on_conflict: HUserNotification_On_Conflict;
  UserNotification_order_by: HUserNotification_Order_By;
  UserNotification_pk_columns_input: HUserNotification_Pk_Columns_Input;
  UserNotification_select_column: HUserNotification_Select_Column;
  UserNotification_set_input: HUserNotification_Set_Input;
  UserNotification_update_column: HUserNotification_Update_Column;
  UserRole_enum: HUserRole_Enum;
  UserRole_enum_comparison_exp: HUserRole_Enum_Comparison_Exp;
  auth_accounts: ResolverTypeWrapper<HAuth_Accounts>;
  auth_accounts_bool_exp: HAuth_Accounts_Bool_Exp;
  auth_accounts_order_by: HAuth_Accounts_Order_By;
  auth_accounts_select_column: HAuth_Accounts_Select_Column;
  bigint: ResolverTypeWrapper<Scalars['bigint']>;
  bigint_comparison_exp: HBigint_Comparison_Exp;
  citext: ResolverTypeWrapper<Scalars['citext']>;
  citext_comparison_exp: HCitext_Comparison_Exp;
  date: ResolverTypeWrapper<Scalars['date']>;
  date_comparison_exp: HDate_Comparison_Exp;
  float8: ResolverTypeWrapper<Scalars['float8']>;
  float8_comparison_exp: HFloat8_Comparison_Exp;
  jsonb: ResolverTypeWrapper<Scalars['jsonb']>;
  jsonb_comparison_exp: HJsonb_Comparison_Exp;
  mutation_root: ResolverTypeWrapper<{}>;
  numeric: ResolverTypeWrapper<Scalars['numeric']>;
  numeric_comparison_exp: HNumeric_Comparison_Exp;
  order_by: HOrder_By;
  query_root: ResolverTypeWrapper<{}>;
  subscription_root: ResolverTypeWrapper<{}>;
  timestamptz: ResolverTypeWrapper<Scalars['timestamptz']>;
  timestamptz_comparison_exp: HTimestamptz_Comparison_Exp;
  users: ResolverTypeWrapper<HUsers>;
  users_append_input: HUsers_Append_Input;
  users_bool_exp: HUsers_Bool_Exp;
  users_delete_at_path_input: HUsers_Delete_At_Path_Input;
  users_delete_elem_input: HUsers_Delete_Elem_Input;
  users_delete_key_input: HUsers_Delete_Key_Input;
  users_mutation_response: ResolverTypeWrapper<HUsers_Mutation_Response>;
  users_order_by: HUsers_Order_By;
  users_pk_columns_input: HUsers_Pk_Columns_Input;
  users_prepend_input: HUsers_Prepend_Input;
  users_select_column: HUsers_Select_Column;
  users_set_input: HUsers_Set_Input;
  uuid: ResolverTypeWrapper<Scalars['uuid']>;
  uuid_comparison_exp: HUuid_Comparison_Exp;
};

/** Mapping between all available schema types and the resolvers parents */
export type HResolversParentTypes = {
  Activity: HActivity;
  ActivityNaceCode: HActivityNaceCode;
  ActivityNaceCode_aggregate_order_by: HActivityNaceCode_Aggregate_Order_By;
  ActivityNaceCode_avg_order_by: HActivityNaceCode_Avg_Order_By;
  ActivityNaceCode_bool_exp: HActivityNaceCode_Bool_Exp;
  ActivityNaceCode_max_order_by: HActivityNaceCode_Max_Order_By;
  ActivityNaceCode_min_order_by: HActivityNaceCode_Min_Order_By;
  ActivityNaceCode_order_by: HActivityNaceCode_Order_By;
  ActivityNaceCode_stddev_order_by: HActivityNaceCode_Stddev_Order_By;
  ActivityNaceCode_stddev_pop_order_by: HActivityNaceCode_Stddev_Pop_Order_By;
  ActivityNaceCode_stddev_samp_order_by: HActivityNaceCode_Stddev_Samp_Order_By;
  ActivityNaceCode_sum_order_by: HActivityNaceCode_Sum_Order_By;
  ActivityNaceCode_var_pop_order_by: HActivityNaceCode_Var_Pop_Order_By;
  ActivityNaceCode_var_samp_order_by: HActivityNaceCode_Var_Samp_Order_By;
  ActivityNaceCode_variance_order_by: HActivityNaceCode_Variance_Order_By;
  ActivityQuestionSet: HActivityQuestionSet;
  ActivityQuestionSet_aggregate_order_by: HActivityQuestionSet_Aggregate_Order_By;
  ActivityQuestionSet_avg_order_by: HActivityQuestionSet_Avg_Order_By;
  ActivityQuestionSet_bool_exp: HActivityQuestionSet_Bool_Exp;
  ActivityQuestionSet_max_order_by: HActivityQuestionSet_Max_Order_By;
  ActivityQuestionSet_min_order_by: HActivityQuestionSet_Min_Order_By;
  ActivityQuestionSet_order_by: HActivityQuestionSet_Order_By;
  ActivityQuestionSet_stddev_order_by: HActivityQuestionSet_Stddev_Order_By;
  ActivityQuestionSet_stddev_pop_order_by: HActivityQuestionSet_Stddev_Pop_Order_By;
  ActivityQuestionSet_stddev_samp_order_by: HActivityQuestionSet_Stddev_Samp_Order_By;
  ActivityQuestionSet_sum_order_by: HActivityQuestionSet_Sum_Order_By;
  ActivityQuestionSet_var_pop_order_by: HActivityQuestionSet_Var_Pop_Order_By;
  ActivityQuestionSet_var_samp_order_by: HActivityQuestionSet_Var_Samp_Order_By;
  ActivityQuestionSet_variance_order_by: HActivityQuestionSet_Variance_Order_By;
  ActivityReport: HActivityReport;
  ActivityReport_aggregate_order_by: HActivityReport_Aggregate_Order_By;
  ActivityReport_arr_rel_insert_input: HActivityReport_Arr_Rel_Insert_Input;
  ActivityReport_bool_exp: HActivityReport_Bool_Exp;
  ActivityReport_insert_input: HActivityReport_Insert_Input;
  ActivityReport_max_order_by: HActivityReport_Max_Order_By;
  ActivityReport_min_order_by: HActivityReport_Min_Order_By;
  ActivityReport_mutation_response: HActivityReport_Mutation_Response;
  ActivityReport_obj_rel_insert_input: HActivityReport_Obj_Rel_Insert_Input;
  ActivityReport_on_conflict: HActivityReport_On_Conflict;
  ActivityReport_order_by: HActivityReport_Order_By;
  ActivityReport_pk_columns_input: HActivityReport_Pk_Columns_Input;
  ActivityReport_set_input: HActivityReport_Set_Input;
  Activity_aggregate_order_by: HActivity_Aggregate_Order_By;
  Activity_bool_exp: HActivity_Bool_Exp;
  Activity_max_order_by: HActivity_Max_Order_By;
  Activity_min_order_by: HActivity_Min_Order_By;
  Activity_order_by: HActivity_Order_By;
  Answer: HAnswer;
  AnswerAttachment: HAnswerAttachment;
  AnswerAttachment_aggregate_order_by: HAnswerAttachment_Aggregate_Order_By;
  AnswerAttachment_arr_rel_insert_input: HAnswerAttachment_Arr_Rel_Insert_Input;
  AnswerAttachment_bool_exp: HAnswerAttachment_Bool_Exp;
  AnswerAttachment_insert_input: HAnswerAttachment_Insert_Input;
  AnswerAttachment_max_order_by: HAnswerAttachment_Max_Order_By;
  AnswerAttachment_min_order_by: HAnswerAttachment_Min_Order_By;
  AnswerAttachment_mutation_response: HAnswerAttachment_Mutation_Response;
  AnswerAttachment_on_conflict: HAnswerAttachment_On_Conflict;
  AnswerAttachment_order_by: HAnswerAttachment_Order_By;
  AnswerAttachment_pk_columns_input: HAnswerAttachment_Pk_Columns_Input;
  AnswerAttachment_set_input: HAnswerAttachment_Set_Input;
  AnswerNote: HAnswerNote;
  AnswerNote_aggregate_order_by: HAnswerNote_Aggregate_Order_By;
  AnswerNote_arr_rel_insert_input: HAnswerNote_Arr_Rel_Insert_Input;
  AnswerNote_bool_exp: HAnswerNote_Bool_Exp;
  AnswerNote_insert_input: HAnswerNote_Insert_Input;
  AnswerNote_max_order_by: HAnswerNote_Max_Order_By;
  AnswerNote_min_order_by: HAnswerNote_Min_Order_By;
  AnswerNote_mutation_response: HAnswerNote_Mutation_Response;
  AnswerNote_order_by: HAnswerNote_Order_By;
  Answer_aggregate_order_by: HAnswer_Aggregate_Order_By;
  Answer_append_input: HAnswer_Append_Input;
  Answer_arr_rel_insert_input: HAnswer_Arr_Rel_Insert_Input;
  Answer_bool_exp: HAnswer_Bool_Exp;
  Answer_delete_at_path_input: HAnswer_Delete_At_Path_Input;
  Answer_delete_elem_input: HAnswer_Delete_Elem_Input;
  Answer_delete_key_input: HAnswer_Delete_Key_Input;
  Answer_insert_input: HAnswer_Insert_Input;
  Answer_max_order_by: HAnswer_Max_Order_By;
  Answer_min_order_by: HAnswer_Min_Order_By;
  Answer_mutation_response: HAnswer_Mutation_Response;
  Answer_obj_rel_insert_input: HAnswer_Obj_Rel_Insert_Input;
  Answer_on_conflict: HAnswer_On_Conflict;
  Answer_order_by: HAnswer_Order_By;
  Answer_pk_columns_input: HAnswer_Pk_Columns_Input;
  Answer_prepend_input: HAnswer_Prepend_Input;
  Answer_set_input: HAnswer_Set_Input;
  Article: HArticle;
  Article_bool_exp: HArticle_Bool_Exp;
  Article_order_by: HArticle_Order_By;
  Boolean: Scalars['Boolean'];
  Boolean_comparison_exp: HBoolean_Comparison_Exp;
  BusinessUnit: HBusinessUnit;
  BusinessUnitAssessment: HBusinessUnitAssessment;
  BusinessUnitAssessment_aggregate_order_by: HBusinessUnitAssessment_Aggregate_Order_By;
  BusinessUnitAssessment_arr_rel_insert_input: HBusinessUnitAssessment_Arr_Rel_Insert_Input;
  BusinessUnitAssessment_bool_exp: HBusinessUnitAssessment_Bool_Exp;
  BusinessUnitAssessment_insert_input: HBusinessUnitAssessment_Insert_Input;
  BusinessUnitAssessment_max_order_by: HBusinessUnitAssessment_Max_Order_By;
  BusinessUnitAssessment_min_order_by: HBusinessUnitAssessment_Min_Order_By;
  BusinessUnitAssessment_mutation_response: HBusinessUnitAssessment_Mutation_Response;
  BusinessUnitAssessment_obj_rel_insert_input: HBusinessUnitAssessment_Obj_Rel_Insert_Input;
  BusinessUnitAssessment_on_conflict: HBusinessUnitAssessment_On_Conflict;
  BusinessUnitAssessment_order_by: HBusinessUnitAssessment_Order_By;
  BusinessUnitAssessment_pk_columns_input: HBusinessUnitAssessment_Pk_Columns_Input;
  BusinessUnitAssessment_set_input: HBusinessUnitAssessment_Set_Input;
  BusinessUnitDistributionKey: HBusinessUnitDistributionKey;
  BusinessUnitDistributionKey_aggregate_order_by: HBusinessUnitDistributionKey_Aggregate_Order_By;
  BusinessUnitDistributionKey_arr_rel_insert_input: HBusinessUnitDistributionKey_Arr_Rel_Insert_Input;
  BusinessUnitDistributionKey_avg_order_by: HBusinessUnitDistributionKey_Avg_Order_By;
  BusinessUnitDistributionKey_bool_exp: HBusinessUnitDistributionKey_Bool_Exp;
  BusinessUnitDistributionKey_inc_input: HBusinessUnitDistributionKey_Inc_Input;
  BusinessUnitDistributionKey_insert_input: HBusinessUnitDistributionKey_Insert_Input;
  BusinessUnitDistributionKey_max_order_by: HBusinessUnitDistributionKey_Max_Order_By;
  BusinessUnitDistributionKey_min_order_by: HBusinessUnitDistributionKey_Min_Order_By;
  BusinessUnitDistributionKey_mutation_response: HBusinessUnitDistributionKey_Mutation_Response;
  BusinessUnitDistributionKey_on_conflict: HBusinessUnitDistributionKey_On_Conflict;
  BusinessUnitDistributionKey_order_by: HBusinessUnitDistributionKey_Order_By;
  BusinessUnitDistributionKey_pk_columns_input: HBusinessUnitDistributionKey_Pk_Columns_Input;
  BusinessUnitDistributionKey_set_input: HBusinessUnitDistributionKey_Set_Input;
  BusinessUnitDistributionKey_stddev_order_by: HBusinessUnitDistributionKey_Stddev_Order_By;
  BusinessUnitDistributionKey_stddev_pop_order_by: HBusinessUnitDistributionKey_Stddev_Pop_Order_By;
  BusinessUnitDistributionKey_stddev_samp_order_by: HBusinessUnitDistributionKey_Stddev_Samp_Order_By;
  BusinessUnitDistributionKey_sum_order_by: HBusinessUnitDistributionKey_Sum_Order_By;
  BusinessUnitDistributionKey_var_pop_order_by: HBusinessUnitDistributionKey_Var_Pop_Order_By;
  BusinessUnitDistributionKey_var_samp_order_by: HBusinessUnitDistributionKey_Var_Samp_Order_By;
  BusinessUnitDistributionKey_variance_order_by: HBusinessUnitDistributionKey_Variance_Order_By;
  BusinessUnit_aggregate_order_by: HBusinessUnit_Aggregate_Order_By;
  BusinessUnit_append_input: HBusinessUnit_Append_Input;
  BusinessUnit_arr_rel_insert_input: HBusinessUnit_Arr_Rel_Insert_Input;
  BusinessUnit_bool_exp: HBusinessUnit_Bool_Exp;
  BusinessUnit_delete_at_path_input: HBusinessUnit_Delete_At_Path_Input;
  BusinessUnit_delete_elem_input: HBusinessUnit_Delete_Elem_Input;
  BusinessUnit_delete_key_input: HBusinessUnit_Delete_Key_Input;
  BusinessUnit_insert_input: HBusinessUnit_Insert_Input;
  BusinessUnit_max_order_by: HBusinessUnit_Max_Order_By;
  BusinessUnit_min_order_by: HBusinessUnit_Min_Order_By;
  BusinessUnit_mutation_response: HBusinessUnit_Mutation_Response;
  BusinessUnit_obj_rel_insert_input: HBusinessUnit_Obj_Rel_Insert_Input;
  BusinessUnit_on_conflict: HBusinessUnit_On_Conflict;
  BusinessUnit_order_by: HBusinessUnit_Order_By;
  BusinessUnit_pk_columns_input: HBusinessUnit_Pk_Columns_Input;
  BusinessUnit_prepend_input: HBusinessUnit_Prepend_Input;
  BusinessUnit_set_input: HBusinessUnit_Set_Input;
  Comment: HComment;
  Comment_aggregate: HComment_Aggregate;
  Comment_aggregate_fields: HComment_Aggregate_Fields;
  Comment_aggregate_order_by: HComment_Aggregate_Order_By;
  Comment_arr_rel_insert_input: HComment_Arr_Rel_Insert_Input;
  Comment_bool_exp: HComment_Bool_Exp;
  Comment_insert_input: HComment_Insert_Input;
  Comment_max_fields: HComment_Max_Fields;
  Comment_max_order_by: HComment_Max_Order_By;
  Comment_min_fields: HComment_Min_Fields;
  Comment_min_order_by: HComment_Min_Order_By;
  Comment_mutation_response: HComment_Mutation_Response;
  Comment_obj_rel_insert_input: HComment_Obj_Rel_Insert_Input;
  Comment_on_conflict: HComment_On_Conflict;
  Comment_order_by: HComment_Order_By;
  Comment_pk_columns_input: HComment_Pk_Columns_Input;
  Comment_set_input: HComment_Set_Input;
  Company: HCompany;
  CompanyAssessment: HCompanyAssessment;
  CompanyAssessmentAggregate: HCompanyAssessmentAggregate;
  CompanyAssessmentAggregate_aggregate_order_by: HCompanyAssessmentAggregate_Aggregate_Order_By;
  CompanyAssessmentAggregate_arr_rel_insert_input: HCompanyAssessmentAggregate_Arr_Rel_Insert_Input;
  CompanyAssessmentAggregate_bool_exp: HCompanyAssessmentAggregate_Bool_Exp;
  CompanyAssessmentAggregate_insert_input: HCompanyAssessmentAggregate_Insert_Input;
  CompanyAssessmentAggregate_max_order_by: HCompanyAssessmentAggregate_Max_Order_By;
  CompanyAssessmentAggregate_min_order_by: HCompanyAssessmentAggregate_Min_Order_By;
  CompanyAssessmentAggregate_mutation_response: HCompanyAssessmentAggregate_Mutation_Response;
  CompanyAssessmentAggregate_obj_rel_insert_input: HCompanyAssessmentAggregate_Obj_Rel_Insert_Input;
  CompanyAssessmentAggregate_on_conflict: HCompanyAssessmentAggregate_On_Conflict;
  CompanyAssessmentAggregate_order_by: HCompanyAssessmentAggregate_Order_By;
  CompanyAssessmentAggregate_pk_columns_input: HCompanyAssessmentAggregate_Pk_Columns_Input;
  CompanyAssessmentAggregate_set_input: HCompanyAssessmentAggregate_Set_Input;
  CompanyAssessment_aggregate_order_by: HCompanyAssessment_Aggregate_Order_By;
  CompanyAssessment_arr_rel_insert_input: HCompanyAssessment_Arr_Rel_Insert_Input;
  CompanyAssessment_bool_exp: HCompanyAssessment_Bool_Exp;
  CompanyAssessment_insert_input: HCompanyAssessment_Insert_Input;
  CompanyAssessment_max_order_by: HCompanyAssessment_Max_Order_By;
  CompanyAssessment_min_order_by: HCompanyAssessment_Min_Order_By;
  CompanyAssessment_mutation_response: HCompanyAssessment_Mutation_Response;
  CompanyAssessment_obj_rel_insert_input: HCompanyAssessment_Obj_Rel_Insert_Input;
  CompanyAssessment_on_conflict: HCompanyAssessment_On_Conflict;
  CompanyAssessment_order_by: HCompanyAssessment_Order_By;
  CompanyAssessment_pk_columns_input: HCompanyAssessment_Pk_Columns_Input;
  CompanyAssessment_set_input: HCompanyAssessment_Set_Input;
  CompanyInvitation: HCompanyInvitation;
  CompanyInvitation_aggregate_order_by: HCompanyInvitation_Aggregate_Order_By;
  CompanyInvitation_arr_rel_insert_input: HCompanyInvitation_Arr_Rel_Insert_Input;
  CompanyInvitation_bool_exp: HCompanyInvitation_Bool_Exp;
  CompanyInvitation_insert_input: HCompanyInvitation_Insert_Input;
  CompanyInvitation_max_order_by: HCompanyInvitation_Max_Order_By;
  CompanyInvitation_min_order_by: HCompanyInvitation_Min_Order_By;
  CompanyInvitation_mutation_response: HCompanyInvitation_Mutation_Response;
  CompanyInvitation_on_conflict: HCompanyInvitation_On_Conflict;
  CompanyInvitation_order_by: HCompanyInvitation_Order_By;
  CompanyInvitation_pk_columns_input: HCompanyInvitation_Pk_Columns_Input;
  CompanyInvitation_set_input: HCompanyInvitation_Set_Input;
  CompanyUser: HCompanyUser;
  CompanyUser_aggregate_order_by: HCompanyUser_Aggregate_Order_By;
  CompanyUser_arr_rel_insert_input: HCompanyUser_Arr_Rel_Insert_Input;
  CompanyUser_avg_order_by: HCompanyUser_Avg_Order_By;
  CompanyUser_bool_exp: HCompanyUser_Bool_Exp;
  CompanyUser_insert_input: HCompanyUser_Insert_Input;
  CompanyUser_max_order_by: HCompanyUser_Max_Order_By;
  CompanyUser_min_order_by: HCompanyUser_Min_Order_By;
  CompanyUser_mutation_response: HCompanyUser_Mutation_Response;
  CompanyUser_on_conflict: HCompanyUser_On_Conflict;
  CompanyUser_order_by: HCompanyUser_Order_By;
  CompanyUser_pk_columns_input: HCompanyUser_Pk_Columns_Input;
  CompanyUser_set_input: HCompanyUser_Set_Input;
  CompanyUser_stddev_order_by: HCompanyUser_Stddev_Order_By;
  CompanyUser_stddev_pop_order_by: HCompanyUser_Stddev_Pop_Order_By;
  CompanyUser_stddev_samp_order_by: HCompanyUser_Stddev_Samp_Order_By;
  CompanyUser_sum_order_by: HCompanyUser_Sum_Order_By;
  CompanyUser_var_pop_order_by: HCompanyUser_Var_Pop_Order_By;
  CompanyUser_var_samp_order_by: HCompanyUser_Var_Samp_Order_By;
  CompanyUser_variance_order_by: HCompanyUser_Variance_Order_By;
  Company_append_input: HCompany_Append_Input;
  Company_bool_exp: HCompany_Bool_Exp;
  Company_delete_at_path_input: HCompany_Delete_At_Path_Input;
  Company_delete_elem_input: HCompany_Delete_Elem_Input;
  Company_delete_key_input: HCompany_Delete_Key_Input;
  Company_inc_input: HCompany_Inc_Input;
  Company_insert_input: HCompany_Insert_Input;
  Company_mutation_response: HCompany_Mutation_Response;
  Company_obj_rel_insert_input: HCompany_Obj_Rel_Insert_Input;
  Company_on_conflict: HCompany_On_Conflict;
  Company_order_by: HCompany_Order_By;
  Company_pk_columns_input: HCompany_Pk_Columns_Input;
  Company_prepend_input: HCompany_Prepend_Input;
  Company_set_input: HCompany_Set_Input;
  EmailItem: HEmailItem;
  EstimateCompany: HEstimateCompany;
  EstimateCompany_bool_exp: HEstimateCompany_Bool_Exp;
  EstimateCompany_order_by: HEstimateCompany_Order_By;
  File: HFile;
  File_aggregate_order_by: HFile_Aggregate_Order_By;
  File_append_input: HFile_Append_Input;
  File_arr_rel_insert_input: HFile_Arr_Rel_Insert_Input;
  File_bool_exp: HFile_Bool_Exp;
  File_delete_at_path_input: HFile_Delete_At_Path_Input;
  File_delete_elem_input: HFile_Delete_Elem_Input;
  File_delete_key_input: HFile_Delete_Key_Input;
  File_insert_input: HFile_Insert_Input;
  File_max_order_by: HFile_Max_Order_By;
  File_min_order_by: HFile_Min_Order_By;
  File_mutation_response: HFile_Mutation_Response;
  File_obj_rel_insert_input: HFile_Obj_Rel_Insert_Input;
  File_on_conflict: HFile_On_Conflict;
  File_order_by: HFile_Order_By;
  File_pk_columns_input: HFile_Pk_Columns_Input;
  File_prepend_input: HFile_Prepend_Input;
  File_set_input: HFile_Set_Input;
  Financials: HFinancials;
  Financials_bool_exp: HFinancials_Bool_Exp;
  Financials_inc_input: HFinancials_Inc_Input;
  Financials_insert_input: HFinancials_Insert_Input;
  Financials_mutation_response: HFinancials_Mutation_Response;
  Financials_obj_rel_insert_input: HFinancials_Obj_Rel_Insert_Input;
  Financials_on_conflict: HFinancials_On_Conflict;
  Financials_order_by: HFinancials_Order_By;
  Financials_pk_columns_input: HFinancials_Pk_Columns_Input;
  Financials_set_input: HFinancials_Set_Input;
  Int: Scalars['Int'];
  Int_comparison_exp: HInt_Comparison_Exp;
  InvitationOutput: HInvitationOutput;
  InvitationQuery: HInvitationQuery;
  NudgePortfolioCompaniesInput: HNudgePortfolioCompaniesInput;
  NudgePortfolioCompaniesOutput: HNudgePortfolioCompaniesOutput;
  Objective: HObjective;
  Objective_bool_exp: HObjective_Bool_Exp;
  Objective_order_by: HObjective_Order_By;
  Portfolio: HPortfolio;
  PortfolioCompany: HPortfolioCompany;
  PortfolioCompany_aggregate_order_by: HPortfolioCompany_Aggregate_Order_By;
  PortfolioCompany_append_input: HPortfolioCompany_Append_Input;
  PortfolioCompany_arr_rel_insert_input: HPortfolioCompany_Arr_Rel_Insert_Input;
  PortfolioCompany_avg_order_by: HPortfolioCompany_Avg_Order_By;
  PortfolioCompany_bool_exp: HPortfolioCompany_Bool_Exp;
  PortfolioCompany_delete_at_path_input: HPortfolioCompany_Delete_At_Path_Input;
  PortfolioCompany_delete_elem_input: HPortfolioCompany_Delete_Elem_Input;
  PortfolioCompany_delete_key_input: HPortfolioCompany_Delete_Key_Input;
  PortfolioCompany_inc_input: HPortfolioCompany_Inc_Input;
  PortfolioCompany_insert_input: HPortfolioCompany_Insert_Input;
  PortfolioCompany_max_order_by: HPortfolioCompany_Max_Order_By;
  PortfolioCompany_min_order_by: HPortfolioCompany_Min_Order_By;
  PortfolioCompany_mutation_response: HPortfolioCompany_Mutation_Response;
  PortfolioCompany_on_conflict: HPortfolioCompany_On_Conflict;
  PortfolioCompany_order_by: HPortfolioCompany_Order_By;
  PortfolioCompany_pk_columns_input: HPortfolioCompany_Pk_Columns_Input;
  PortfolioCompany_prepend_input: HPortfolioCompany_Prepend_Input;
  PortfolioCompany_set_input: HPortfolioCompany_Set_Input;
  PortfolioCompany_stddev_order_by: HPortfolioCompany_Stddev_Order_By;
  PortfolioCompany_stddev_pop_order_by: HPortfolioCompany_Stddev_Pop_Order_By;
  PortfolioCompany_stddev_samp_order_by: HPortfolioCompany_Stddev_Samp_Order_By;
  PortfolioCompany_sum_order_by: HPortfolioCompany_Sum_Order_By;
  PortfolioCompany_var_pop_order_by: HPortfolioCompany_Var_Pop_Order_By;
  PortfolioCompany_var_samp_order_by: HPortfolioCompany_Var_Samp_Order_By;
  PortfolioCompany_variance_order_by: HPortfolioCompany_Variance_Order_By;
  Portfolio_aggregate_order_by: HPortfolio_Aggregate_Order_By;
  Portfolio_arr_rel_insert_input: HPortfolio_Arr_Rel_Insert_Input;
  Portfolio_avg_order_by: HPortfolio_Avg_Order_By;
  Portfolio_bool_exp: HPortfolio_Bool_Exp;
  Portfolio_inc_input: HPortfolio_Inc_Input;
  Portfolio_insert_input: HPortfolio_Insert_Input;
  Portfolio_max_order_by: HPortfolio_Max_Order_By;
  Portfolio_min_order_by: HPortfolio_Min_Order_By;
  Portfolio_mutation_response: HPortfolio_Mutation_Response;
  Portfolio_obj_rel_insert_input: HPortfolio_Obj_Rel_Insert_Input;
  Portfolio_on_conflict: HPortfolio_On_Conflict;
  Portfolio_order_by: HPortfolio_Order_By;
  Portfolio_pk_columns_input: HPortfolio_Pk_Columns_Input;
  Portfolio_set_input: HPortfolio_Set_Input;
  Portfolio_stddev_order_by: HPortfolio_Stddev_Order_By;
  Portfolio_stddev_pop_order_by: HPortfolio_Stddev_Pop_Order_By;
  Portfolio_stddev_samp_order_by: HPortfolio_Stddev_Samp_Order_By;
  Portfolio_sum_order_by: HPortfolio_Sum_Order_By;
  Portfolio_var_pop_order_by: HPortfolio_Var_Pop_Order_By;
  Portfolio_var_samp_order_by: HPortfolio_Var_Samp_Order_By;
  Portfolio_variance_order_by: HPortfolio_Variance_Order_By;
  Question: HQuestion;
  QuestionChoice: HQuestionChoice;
  QuestionChoice_aggregate_order_by: HQuestionChoice_Aggregate_Order_By;
  QuestionChoice_avg_order_by: HQuestionChoice_Avg_Order_By;
  QuestionChoice_bool_exp: HQuestionChoice_Bool_Exp;
  QuestionChoice_max_order_by: HQuestionChoice_Max_Order_By;
  QuestionChoice_min_order_by: HQuestionChoice_Min_Order_By;
  QuestionChoice_order_by: HQuestionChoice_Order_By;
  QuestionChoice_stddev_order_by: HQuestionChoice_Stddev_Order_By;
  QuestionChoice_stddev_pop_order_by: HQuestionChoice_Stddev_Pop_Order_By;
  QuestionChoice_stddev_samp_order_by: HQuestionChoice_Stddev_Samp_Order_By;
  QuestionChoice_sum_order_by: HQuestionChoice_Sum_Order_By;
  QuestionChoice_var_pop_order_by: HQuestionChoice_Var_Pop_Order_By;
  QuestionChoice_var_samp_order_by: HQuestionChoice_Var_Samp_Order_By;
  QuestionChoice_variance_order_by: HQuestionChoice_Variance_Order_By;
  QuestionLevel_enum_comparison_exp: HQuestionLevel_Enum_Comparison_Exp;
  QuestionSet: HQuestionSet;
  QuestionSet_bool_exp: HQuestionSet_Bool_Exp;
  QuestionSet_order_by: HQuestionSet_Order_By;
  QuestionType_enum_comparison_exp: HQuestionType_Enum_Comparison_Exp;
  Question_aggregate_order_by: HQuestion_Aggregate_Order_By;
  Question_bool_exp: HQuestion_Bool_Exp;
  Question_max_order_by: HQuestion_Max_Order_By;
  Question_min_order_by: HQuestion_Min_Order_By;
  Question_order_by: HQuestion_Order_By;
  RequestQuoteInput: HRequestQuoteInput;
  RequestQuoteOutput: HRequestQuoteOutput;
  Sector: HSector;
  Sector_bool_exp: HSector_Bool_Exp;
  Sector_order_by: HSector_Order_By;
  String: Scalars['String'];
  String_comparison_exp: HString_Comparison_Exp;
  Tag: HTag;
  Tag_articles: HTag_Articles;
  Tag_articles_aggregate_order_by: HTag_Articles_Aggregate_Order_By;
  Tag_articles_avg_order_by: HTag_Articles_Avg_Order_By;
  Tag_articles_bool_exp: HTag_Articles_Bool_Exp;
  Tag_articles_max_order_by: HTag_Articles_Max_Order_By;
  Tag_articles_min_order_by: HTag_Articles_Min_Order_By;
  Tag_articles_order_by: HTag_Articles_Order_By;
  Tag_articles_stddev_order_by: HTag_Articles_Stddev_Order_By;
  Tag_articles_stddev_pop_order_by: HTag_Articles_Stddev_Pop_Order_By;
  Tag_articles_stddev_samp_order_by: HTag_Articles_Stddev_Samp_Order_By;
  Tag_articles_sum_order_by: HTag_Articles_Sum_Order_By;
  Tag_articles_var_pop_order_by: HTag_Articles_Var_Pop_Order_By;
  Tag_articles_var_samp_order_by: HTag_Articles_Var_Samp_Order_By;
  Tag_articles_variance_order_by: HTag_Articles_Variance_Order_By;
  Tag_bool_exp: HTag_Bool_Exp;
  Tag_order_by: HTag_Order_By;
  Thread: HThread;
  ThreadSubscriber: HThreadSubscriber;
  ThreadSubscriber_aggregate_order_by: HThreadSubscriber_Aggregate_Order_By;
  ThreadSubscriber_arr_rel_insert_input: HThreadSubscriber_Arr_Rel_Insert_Input;
  ThreadSubscriber_bool_exp: HThreadSubscriber_Bool_Exp;
  ThreadSubscriber_insert_input: HThreadSubscriber_Insert_Input;
  ThreadSubscriber_max_order_by: HThreadSubscriber_Max_Order_By;
  ThreadSubscriber_min_order_by: HThreadSubscriber_Min_Order_By;
  ThreadSubscriber_mutation_response: HThreadSubscriber_Mutation_Response;
  ThreadSubscriber_on_conflict: HThreadSubscriber_On_Conflict;
  ThreadSubscriber_order_by: HThreadSubscriber_Order_By;
  ThreadSubscriber_pk_columns_input: HThreadSubscriber_Pk_Columns_Input;
  ThreadSubscriber_set_input: HThreadSubscriber_Set_Input;
  Thread_aggregate_order_by: HThread_Aggregate_Order_By;
  Thread_arr_rel_insert_input: HThread_Arr_Rel_Insert_Input;
  Thread_bool_exp: HThread_Bool_Exp;
  Thread_insert_input: HThread_Insert_Input;
  Thread_max_order_by: HThread_Max_Order_By;
  Thread_min_order_by: HThread_Min_Order_By;
  Thread_mutation_response: HThread_Mutation_Response;
  Thread_obj_rel_insert_input: HThread_Obj_Rel_Insert_Input;
  Thread_on_conflict: HThread_On_Conflict;
  Thread_order_by: HThread_Order_By;
  Thread_pk_columns_input: HThread_Pk_Columns_Input;
  Thread_set_input: HThread_Set_Input;
  UserNotification: HUserNotification;
  UserNotification_aggregate: HUserNotification_Aggregate;
  UserNotification_aggregate_fields: HUserNotification_Aggregate_Fields;
  UserNotification_aggregate_order_by: HUserNotification_Aggregate_Order_By;
  UserNotification_arr_rel_insert_input: HUserNotification_Arr_Rel_Insert_Input;
  UserNotification_bool_exp: HUserNotification_Bool_Exp;
  UserNotification_insert_input: HUserNotification_Insert_Input;
  UserNotification_max_fields: HUserNotification_Max_Fields;
  UserNotification_max_order_by: HUserNotification_Max_Order_By;
  UserNotification_min_fields: HUserNotification_Min_Fields;
  UserNotification_min_order_by: HUserNotification_Min_Order_By;
  UserNotification_mutation_response: HUserNotification_Mutation_Response;
  UserNotification_on_conflict: HUserNotification_On_Conflict;
  UserNotification_order_by: HUserNotification_Order_By;
  UserNotification_pk_columns_input: HUserNotification_Pk_Columns_Input;
  UserNotification_set_input: HUserNotification_Set_Input;
  UserRole_enum_comparison_exp: HUserRole_Enum_Comparison_Exp;
  auth_accounts: HAuth_Accounts;
  auth_accounts_bool_exp: HAuth_Accounts_Bool_Exp;
  auth_accounts_order_by: HAuth_Accounts_Order_By;
  bigint: Scalars['bigint'];
  bigint_comparison_exp: HBigint_Comparison_Exp;
  citext: Scalars['citext'];
  citext_comparison_exp: HCitext_Comparison_Exp;
  date: Scalars['date'];
  date_comparison_exp: HDate_Comparison_Exp;
  float8: Scalars['float8'];
  float8_comparison_exp: HFloat8_Comparison_Exp;
  jsonb: Scalars['jsonb'];
  jsonb_comparison_exp: HJsonb_Comparison_Exp;
  mutation_root: {};
  numeric: Scalars['numeric'];
  numeric_comparison_exp: HNumeric_Comparison_Exp;
  query_root: {};
  subscription_root: {};
  timestamptz: Scalars['timestamptz'];
  timestamptz_comparison_exp: HTimestamptz_Comparison_Exp;
  users: HUsers;
  users_append_input: HUsers_Append_Input;
  users_bool_exp: HUsers_Bool_Exp;
  users_delete_at_path_input: HUsers_Delete_At_Path_Input;
  users_delete_elem_input: HUsers_Delete_Elem_Input;
  users_delete_key_input: HUsers_Delete_Key_Input;
  users_mutation_response: HUsers_Mutation_Response;
  users_order_by: HUsers_Order_By;
  users_pk_columns_input: HUsers_Pk_Columns_Input;
  users_prepend_input: HUsers_Prepend_Input;
  users_set_input: HUsers_Set_Input;
  uuid: Scalars['uuid'];
  uuid_comparison_exp: HUuid_Comparison_Exp;
};

export type HCachedDirectiveArgs = {
  refresh?: Scalars['Boolean'];
  ttl?: Scalars['Int'];
};

export type HCachedDirectiveResolver<Result, Parent, ContextType = any, Args = HCachedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type HActivityResolvers<ContextType = any, ParentType extends HResolversParentTypes['Activity'] = HResolversParentTypes['Activity']> = {
  BusinessUnitDistributionKeys?: Resolver<Array<HResolversTypes['BusinessUnitDistributionKey']>, ParentType, ContextType, RequireFields<HActivityBusinessUnitDistributionKeysArgs, never>>;
  activityNaceCodes?: Resolver<Array<HResolversTypes['ActivityNaceCode']>, ParentType, ContextType, RequireFields<HActivityActivityNaceCodesArgs, never>>;
  activityQuestionSets?: Resolver<Array<HResolversTypes['ActivityQuestionSet']>, ParentType, ContextType, RequireFields<HActivityActivityQuestionSetsArgs, never>>;
  activityReports?: Resolver<Array<HResolversTypes['ActivityReport']>, ParentType, ContextType, RequireFields<HActivityActivityReportsArgs, never>>;
  description?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  sector?: Resolver<HResolversTypes['Sector'], ParentType, ContextType>;
  sectorRef?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  tagExpression?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HActivityNaceCodeResolvers<ContextType = any, ParentType extends HResolversParentTypes['ActivityNaceCode'] = HResolversParentTypes['ActivityNaceCode']> = {
  activity?: Resolver<HResolversTypes['Activity'], ParentType, ContextType>;
  activityRef?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  code?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['bigint'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HActivityQuestionSetResolvers<ContextType = any, ParentType extends HResolversParentTypes['ActivityQuestionSet'] = HResolversParentTypes['ActivityQuestionSet']> = {
  activity?: Resolver<HResolversTypes['Activity'], ParentType, ContextType>;
  activityRef?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['bigint'], ParentType, ContextType>;
  questionSet?: Resolver<HResolversTypes['QuestionSet'], ParentType, ContextType>;
  questionSetRef?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HActivityReportResolvers<ContextType = any, ParentType extends HResolversParentTypes['ActivityReport'] = HResolversParentTypes['ActivityReport']> = {
  activity?: Resolver<HResolversTypes['Activity'], ParentType, ContextType>;
  activityRef?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  answers?: Resolver<Array<HResolversTypes['Answer']>, ParentType, ContextType, RequireFields<HActivityReportAnswersArgs, never>>;
  bAssessment?: Resolver<HResolversTypes['BusinessUnitAssessment'], ParentType, ContextType>;
  bAssessmentId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  completedAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  financials?: Resolver<Maybe<HResolversTypes['Financials']>, ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HActivityReport_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['ActivityReport_mutation_response'] = HResolversParentTypes['ActivityReport_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['ActivityReport']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HAnswerResolvers<ContextType = any, ParentType extends HResolversParentTypes['Answer'] = HResolversParentTypes['Answer']> = {
  activityReport?: Resolver<Maybe<HResolversTypes['ActivityReport']>, ParentType, ContextType>;
  answeredBy?: Resolver<HResolversTypes['users'], ParentType, ContextType>;
  answeredById?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  attachments?: Resolver<Array<HResolversTypes['AnswerAttachment']>, ParentType, ContextType, RequireFields<HAnswerAttachmentsArgs, never>>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  data?: Resolver<Maybe<HResolversTypes['jsonb']>, ParentType, ContextType, RequireFields<HAnswerDataArgs, never>>;
  flagged?: Resolver<Maybe<HResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  notes?: Resolver<Array<HResolversTypes['AnswerNote']>, ParentType, ContextType, RequireFields<HAnswerNotesArgs, never>>;
  question?: Resolver<HResolversTypes['Question'], ParentType, ContextType>;
  questionId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  reportId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  thread?: Resolver<Maybe<HResolversTypes['Thread']>, ParentType, ContextType>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HAnswerAttachmentResolvers<ContextType = any, ParentType extends HResolversParentTypes['AnswerAttachment'] = HResolversParentTypes['AnswerAttachment']> = {
  answer?: Resolver<HResolversTypes['Answer'], ParentType, ContextType>;
  answerId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  file?: Resolver<HResolversTypes['File'], ParentType, ContextType>;
  fileId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HAnswerAttachment_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['AnswerAttachment_mutation_response'] = HResolversParentTypes['AnswerAttachment_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['AnswerAttachment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HAnswerNoteResolvers<ContextType = any, ParentType extends HResolversParentTypes['AnswerNote'] = HResolversParentTypes['AnswerNote']> = {
  answer?: Resolver<HResolversTypes['Answer'], ParentType, ContextType>;
  answerId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  author?: Resolver<HResolversTypes['users'], ParentType, ContextType>;
  authorId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  body?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HAnswerNote_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['AnswerNote_mutation_response'] = HResolversParentTypes['AnswerNote_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['AnswerNote']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HAnswer_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['Answer_mutation_response'] = HResolversParentTypes['Answer_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['Answer']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HArticleResolvers<ContextType = any, ParentType extends HResolversParentTypes['Article'] = HResolversParentTypes['Article']> = {
  Tag_articles?: Resolver<Array<HResolversTypes['Tag_articles']>, ParentType, ContextType, RequireFields<HArticleTag_ArticlesArgs, never>>;
  body?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  title?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HBusinessUnitResolvers<ContextType = any, ParentType extends HResolversParentTypes['BusinessUnit'] = HResolversParentTypes['BusinessUnit']> = {
  bAssessments?: Resolver<Array<HResolversTypes['BusinessUnitAssessment']>, ParentType, ContextType, RequireFields<HBusinessUnitBAssessmentsArgs, never>>;
  businessArea?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<HResolversTypes['Company'], ParentType, ContextType>;
  companyId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  contactPerson?: Resolver<HResolversTypes['users'], ParentType, ContextType>;
  contactPersonId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  description?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  distributionKeys?: Resolver<Array<HResolversTypes['BusinessUnitDistributionKey']>, ParentType, ContextType, RequireFields<HBusinessUnitDistributionKeysArgs, never>>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  isSupporting?: Resolver<HResolversTypes['Boolean'], ParentType, ContextType>;
  labels?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HBusinessUnitLabelsArgs, never>>;
  name?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  sectors?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HBusinessUnitSectorsArgs, never>>;
  supportedBy?: Resolver<Array<HResolversTypes['BusinessUnitDistributionKey']>, ParentType, ContextType, RequireFields<HBusinessUnitSupportedByArgs, never>>;
  type?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HBusinessUnitAssessmentResolvers<ContextType = any, ParentType extends HResolversParentTypes['BusinessUnitAssessment'] = HResolversParentTypes['BusinessUnitAssessment']> = {
  activityReports?: Resolver<Array<HResolversTypes['ActivityReport']>, ParentType, ContextType, RequireFields<HBusinessUnitAssessmentActivityReportsArgs, never>>;
  businessUnit?: Resolver<Maybe<HResolversTypes['BusinessUnit']>, ParentType, ContextType>;
  businessUnitId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  cAssessment?: Resolver<HResolversTypes['CompanyAssessment'], ParentType, ContextType>;
  cAssessmentId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  hasGeneralAssessment?: Resolver<HResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HBusinessUnitAssessment_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['BusinessUnitAssessment_mutation_response'] = HResolversParentTypes['BusinessUnitAssessment_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['BusinessUnitAssessment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HBusinessUnitDistributionKeyResolvers<ContextType = any, ParentType extends HResolversParentTypes['BusinessUnitDistributionKey'] = HResolversParentTypes['BusinessUnitDistributionKey']> = {
  activity?: Resolver<HResolversTypes['Activity'], ParentType, ContextType>;
  activityRef?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  capexPercentage?: Resolver<HResolversTypes['float8'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  opexPercentage?: Resolver<HResolversTypes['float8'], ParentType, ContextType>;
  receivingBusinessUnit?: Resolver<HResolversTypes['BusinessUnit'], ParentType, ContextType>;
  receivingBusinessUnitId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  revenuePercentage?: Resolver<HResolversTypes['float8'], ParentType, ContextType>;
  supportingBusinessUnit?: Resolver<HResolversTypes['BusinessUnit'], ParentType, ContextType>;
  supportingBusinessUnitId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HBusinessUnitDistributionKey_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['BusinessUnitDistributionKey_mutation_response'] = HResolversParentTypes['BusinessUnitDistributionKey_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['BusinessUnitDistributionKey']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HBusinessUnit_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['BusinessUnit_mutation_response'] = HResolversParentTypes['BusinessUnit_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['BusinessUnit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCommentResolvers<ContextType = any, ParentType extends HResolversParentTypes['Comment'] = HResolversParentTypes['Comment']> = {
  author?: Resolver<HResolversTypes['users'], ParentType, ContextType>;
  authorId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  data?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  notifications?: Resolver<Array<HResolversTypes['UserNotification']>, ParentType, ContextType, RequireFields<HCommentNotificationsArgs, never>>;
  notifications_aggregate?: Resolver<HResolversTypes['UserNotification_aggregate'], ParentType, ContextType, RequireFields<HCommentNotifications_AggregateArgs, never>>;
  thread?: Resolver<HResolversTypes['Thread'], ParentType, ContextType>;
  threadId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HComment_AggregateResolvers<ContextType = any, ParentType extends HResolversParentTypes['Comment_aggregate'] = HResolversParentTypes['Comment_aggregate']> = {
  aggregate?: Resolver<Maybe<HResolversTypes['Comment_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<HResolversTypes['Comment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HComment_Aggregate_FieldsResolvers<ContextType = any, ParentType extends HResolversParentTypes['Comment_aggregate_fields'] = HResolversParentTypes['Comment_aggregate_fields']> = {
  count?: Resolver<HResolversTypes['Int'], ParentType, ContextType, RequireFields<HComment_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<HResolversTypes['Comment_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<HResolversTypes['Comment_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HComment_Max_FieldsResolvers<ContextType = any, ParentType extends HResolversParentTypes['Comment_max_fields'] = HResolversParentTypes['Comment_max_fields']> = {
  authorId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  data?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  threadId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HComment_Min_FieldsResolvers<ContextType = any, ParentType extends HResolversParentTypes['Comment_min_fields'] = HResolversParentTypes['Comment_min_fields']> = {
  authorId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  data?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  threadId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HComment_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['Comment_mutation_response'] = HResolversParentTypes['Comment_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['Comment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCompanyResolvers<ContextType = any, ParentType extends HResolversParentTypes['Company'] = HResolversParentTypes['Company']> = {
  Threads?: Resolver<Array<HResolversTypes['Thread']>, ParentType, ContextType, RequireFields<HCompanyThreadsArgs, never>>;
  businessUnits?: Resolver<Array<HResolversTypes['BusinessUnit']>, ParentType, ContextType, RequireFields<HCompanyBusinessUnitsArgs, never>>;
  cAssessmentAggregates?: Resolver<Array<HResolversTypes['CompanyAssessmentAggregate']>, ParentType, ContextType, RequireFields<HCompanyCAssessmentAggregatesArgs, never>>;
  cAssessments?: Resolver<Array<HResolversTypes['CompanyAssessment']>, ParentType, ContextType, RequireFields<HCompanyCAssessmentsArgs, never>>;
  currency?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  files?: Resolver<Array<HResolversTypes['File']>, ParentType, ContextType, RequireFields<HCompanyFilesArgs, never>>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  industries?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HCompanyIndustriesArgs, never>>;
  invitations?: Resolver<Array<HResolversTypes['CompanyInvitation']>, ParentType, ContextType, RequireFields<HCompanyInvitationsArgs, never>>;
  isOnTrial?: Resolver<HResolversTypes['Boolean'], ParentType, ContextType>;
  isPortfolioOwner?: Resolver<HResolversTypes['Boolean'], ParentType, ContextType>;
  logoUrl?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  numberOfEmployees?: Resolver<Maybe<HResolversTypes['Int']>, ParentType, ContextType>;
  orgId?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  portfolioCompanies?: Resolver<Array<HResolversTypes['PortfolioCompany']>, ParentType, ContextType, RequireFields<HCompanyPortfolioCompaniesArgs, never>>;
  portfolios?: Resolver<Array<HResolversTypes['Portfolio']>, ParentType, ContextType, RequireFields<HCompanyPortfoliosArgs, never>>;
  settings?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HCompanySettingsArgs, never>>;
  trialEndDate?: Resolver<HResolversTypes['date'], ParentType, ContextType>;
  users?: Resolver<Array<HResolversTypes['CompanyUser']>, ParentType, ContextType, RequireFields<HCompanyUsersArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCompanyAssessmentResolvers<ContextType = any, ParentType extends HResolversParentTypes['CompanyAssessment'] = HResolversParentTypes['CompanyAssessment']> = {
  aggregate?: Resolver<HResolversTypes['CompanyAssessmentAggregate'], ParentType, ContextType>;
  aggregateId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  bAssesssments?: Resolver<Array<HResolversTypes['BusinessUnitAssessment']>, ParentType, ContextType, RequireFields<HCompanyAssessmentBAssesssmentsArgs, never>>;
  company?: Resolver<HResolversTypes['Company'], ParentType, ContextType>;
  companyId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  completedAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  endDate?: Resolver<HResolversTypes['date'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  startDate?: Resolver<HResolversTypes['date'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCompanyAssessmentAggregateResolvers<ContextType = any, ParentType extends HResolversParentTypes['CompanyAssessmentAggregate'] = HResolversParentTypes['CompanyAssessmentAggregate']> = {
  cAssessments?: Resolver<Array<HResolversTypes['CompanyAssessment']>, ParentType, ContextType, RequireFields<HCompanyAssessmentAggregateCAssessmentsArgs, never>>;
  company?: Resolver<HResolversTypes['Company'], ParentType, ContextType>;
  companyId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  contactPerson?: Resolver<Maybe<HResolversTypes['users']>, ParentType, ContextType>;
  contactPersonId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  interval?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<HResolversTypes['date'], ParentType, ContextType>;
  title?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCompanyAssessmentAggregate_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['CompanyAssessmentAggregate_mutation_response'] = HResolversParentTypes['CompanyAssessmentAggregate_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['CompanyAssessmentAggregate']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCompanyAssessment_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['CompanyAssessment_mutation_response'] = HResolversParentTypes['CompanyAssessment_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['CompanyAssessment']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCompanyInvitationResolvers<ContextType = any, ParentType extends HResolversParentTypes['CompanyInvitation'] = HResolversParentTypes['CompanyInvitation']> = {
  company?: Resolver<HResolversTypes['Company'], ParentType, ContextType>;
  companyId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  status?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  user?: Resolver<Maybe<HResolversTypes['users']>, ParentType, ContextType>;
  userEmail?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCompanyInvitation_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['CompanyInvitation_mutation_response'] = HResolversParentTypes['CompanyInvitation_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['CompanyInvitation']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCompanyUserResolvers<ContextType = any, ParentType extends HResolversParentTypes['CompanyUser'] = HResolversParentTypes['CompanyUser']> = {
  company?: Resolver<HResolversTypes['Company'], ParentType, ContextType>;
  companyId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['bigint'], ParentType, ContextType>;
  role?: Resolver<HResolversTypes['UserRole_enum'], ParentType, ContextType>;
  user?: Resolver<HResolversTypes['users'], ParentType, ContextType>;
  userId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCompanyUser_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['CompanyUser_mutation_response'] = HResolversParentTypes['CompanyUser_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['CompanyUser']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HCompany_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['Company_mutation_response'] = HResolversParentTypes['Company_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['Company']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HEstimateCompanyResolvers<ContextType = any, ParentType extends HResolversParentTypes['EstimateCompany'] = HResolversParentTypes['EstimateCompany']> = {
  PortfolioCompanies?: Resolver<Array<HResolversTypes['PortfolioCompany']>, ParentType, ContextType, RequireFields<HEstimateCompanyPortfolioCompaniesArgs, never>>;
  country?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  industries?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HEstimateCompanyIndustriesArgs, never>>;
  logoUrl?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HEstimateCompanyMetadataArgs, never>>;
  name?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  scoreType?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  scores?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HEstimateCompanyScoresArgs, never>>;
  uniqueId?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HFileResolvers<ContextType = any, ParentType extends HResolversParentTypes['File'] = HResolversParentTypes['File']> = {
  answerAttachments?: Resolver<Array<HResolversTypes['AnswerAttachment']>, ParentType, ContextType, RequireFields<HFileAnswerAttachmentsArgs, never>>;
  company?: Resolver<HResolversTypes['Company'], ParentType, ContextType>;
  companyId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  creationDate?: Resolver<Maybe<HResolversTypes['date']>, ParentType, ContextType>;
  description?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  source?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HFileTagsArgs, never>>;
  title?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  uploadedBy?: Resolver<HResolversTypes['users'], ParentType, ContextType>;
  uploadedById?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  url?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HFile_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['File_mutation_response'] = HResolversParentTypes['File_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['File']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HFinancialsResolvers<ContextType = any, ParentType extends HResolversParentTypes['Financials'] = HResolversParentTypes['Financials']> = {
  activityReport?: Resolver<HResolversTypes['ActivityReport'], ParentType, ContextType>;
  adaptationCapex?: Resolver<HResolversTypes['numeric'], ParentType, ContextType>;
  adaptationOpex?: Resolver<HResolversTypes['numeric'], ParentType, ContextType>;
  capex?: Resolver<HResolversTypes['numeric'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  isEstimate?: Resolver<HResolversTypes['Boolean'], ParentType, ContextType>;
  opex?: Resolver<HResolversTypes['numeric'], ParentType, ContextType>;
  revenue?: Resolver<HResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HFinancials_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['Financials_mutation_response'] = HResolversParentTypes['Financials_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['Financials']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HInvitationOutputResolvers<ContextType = any, ParentType extends HResolversParentTypes['InvitationOutput'] = HResolversParentTypes['InvitationOutput']> = {
  companyId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  companyLogoUrl?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  inviteToChildCompanies?: Resolver<HResolversTypes['Boolean'], ParentType, ContextType>;
  status?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  userEmail?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  userExists?: Resolver<HResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HNudgePortfolioCompaniesOutputResolvers<ContextType = any, ParentType extends HResolversParentTypes['NudgePortfolioCompaniesOutput'] = HResolversParentTypes['NudgePortfolioCompaniesOutput']> = {
  emailsSent?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  error?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HObjectiveResolvers<ContextType = any, ParentType extends HResolversParentTypes['Objective'] = HResolversParentTypes['Objective']> = {
  description?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  questions?: Resolver<Array<HResolversTypes['Question']>, ParentType, ContextType, RequireFields<HObjectiveQuestionsArgs, never>>;
  title?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HPortfolioResolvers<ContextType = any, ParentType extends HResolversParentTypes['Portfolio'] = HResolversParentTypes['Portfolio']> = {
  description?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  ownerCompany?: Resolver<HResolversTypes['Company'], ParentType, ContextType>;
  ownerCompanyId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  portfolioCompanies?: Resolver<Array<HResolversTypes['PortfolioCompany']>, ParentType, ContextType, RequireFields<HPortfolioPortfolioCompaniesArgs, never>>;
  totalAmount?: Resolver<HResolversTypes['numeric'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HPortfolioCompanyResolvers<ContextType = any, ParentType extends HResolversParentTypes['PortfolioCompany'] = HResolversParentTypes['PortfolioCompany']> = {
  amount?: Resolver<HResolversTypes['numeric'], ParentType, ContextType>;
  company?: Resolver<Maybe<HResolversTypes['Company']>, ParentType, ContextType>;
  companyId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  contactPersonEmail?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  contactPersonName?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  estimateCompany?: Resolver<Maybe<HResolversTypes['EstimateCompany']>, ParentType, ContextType>;
  estimateCompanyId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  portfolio?: Resolver<HResolversTypes['Portfolio'], ParentType, ContextType>;
  portfolioId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  scores?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HPortfolioCompanyScoresArgs, never>>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HPortfolioCompany_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['PortfolioCompany_mutation_response'] = HResolversParentTypes['PortfolioCompany_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['PortfolioCompany']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HPortfolio_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['Portfolio_mutation_response'] = HResolversParentTypes['Portfolio_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['Portfolio']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HQuestionResolvers<ContextType = any, ParentType extends HResolversParentTypes['Question'] = HResolversParentTypes['Question']> = {
  Answers?: Resolver<Array<HResolversTypes['Answer']>, ParentType, ContextType, RequireFields<HQuestionAnswersArgs, never>>;
  aboutDocumentation?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  alignmentErrorMessage?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  choices?: Resolver<Array<HResolversTypes['QuestionChoice']>, ParentType, ContextType, RequireFields<HQuestionChoicesArgs, never>>;
  dependencyDescription?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  documentationRequired?: Resolver<HResolversTypes['Boolean'], ParentType, ContextType>;
  extendedDescription?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  group?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  isAligned?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  isEditable?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  isRequired?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  isVisible?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  level?: Resolver<HResolversTypes['QuestionLevel_enum'], ParentType, ContextType>;
  levelExpression?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  objective?: Resolver<HResolversTypes['Objective'], ParentType, ContextType>;
  objectiveKey?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  orderIndex?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  other?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HQuestionOtherArgs, never>>;
  questionSet?: Resolver<HResolversTypes['QuestionSet'], ParentType, ContextType>;
  questionSetRef?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  questionSetVersion?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  relevantDirectives?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  resourceLink?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<HResolversTypes['QuestionType_enum'], ParentType, ContextType>;
  uniqueId?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  unit?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HQuestionChoiceResolvers<ContextType = any, ParentType extends HResolversParentTypes['QuestionChoice'] = HResolversParentTypes['QuestionChoice']> = {
  displayName?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['bigint'], ParentType, ContextType>;
  order?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  question?: Resolver<HResolversTypes['Question'], ParentType, ContextType>;
  question_id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  value?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HQuestionSetResolvers<ContextType = any, ParentType extends HResolversParentTypes['QuestionSet'] = HResolversParentTypes['QuestionSet']> = {
  activityQuestionSets?: Resolver<Array<HResolversTypes['ActivityQuestionSet']>, ParentType, ContextType, RequireFields<HQuestionSetActivityQuestionSetsArgs, never>>;
  newestVersion?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  questions?: Resolver<Array<HResolversTypes['Question']>, ParentType, ContextType, RequireFields<HQuestionSetQuestionsArgs, never>>;
  reference?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HRequestQuoteOutputResolvers<ContextType = any, ParentType extends HResolversParentTypes['RequestQuoteOutput'] = HResolversParentTypes['RequestQuoteOutput']> = {
  message?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HSectorResolvers<ContextType = any, ParentType extends HResolversParentTypes['Sector'] = HResolversParentTypes['Sector']> = {
  activities?: Resolver<Array<HResolversTypes['Activity']>, ParentType, ContextType, RequireFields<HSectorActivitiesArgs, never>>;
  name?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HTagResolvers<ContextType = any, ParentType extends HResolversParentTypes['Tag'] = HResolversParentTypes['Tag']> = {
  Tag_articles?: Resolver<Array<HResolversTypes['Tag_articles']>, ParentType, ContextType, RequireFields<HTagTag_ArticlesArgs, never>>;
  description?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  name?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HTag_ArticlesResolvers<ContextType = any, ParentType extends HResolversParentTypes['Tag_articles'] = HResolversParentTypes['Tag_articles']> = {
  Article?: Resolver<HResolversTypes['Article'], ParentType, ContextType>;
  Tag?: Resolver<HResolversTypes['Tag'], ParentType, ContextType>;
  article_id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['bigint'], ParentType, ContextType>;
  tag_id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HThreadResolvers<ContextType = any, ParentType extends HResolversParentTypes['Thread'] = HResolversParentTypes['Thread']> = {
  answer?: Resolver<Maybe<HResolversTypes['Answer']>, ParentType, ContextType>;
  answerId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  comments?: Resolver<Array<HResolversTypes['Comment']>, ParentType, ContextType, RequireFields<HThreadCommentsArgs, never>>;
  comments_aggregate?: Resolver<HResolversTypes['Comment_aggregate'], ParentType, ContextType, RequireFields<HThreadComments_AggregateArgs, never>>;
  company?: Resolver<HResolversTypes['Company'], ParentType, ContextType>;
  companyId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  subscribers?: Resolver<Array<HResolversTypes['ThreadSubscriber']>, ParentType, ContextType, RequireFields<HThreadSubscribersArgs, never>>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HThreadSubscriberResolvers<ContextType = any, ParentType extends HResolversParentTypes['ThreadSubscriber'] = HResolversParentTypes['ThreadSubscriber']> = {
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  isSubscribed?: Resolver<HResolversTypes['Boolean'], ParentType, ContextType>;
  thread?: Resolver<HResolversTypes['Thread'], ParentType, ContextType>;
  threadId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  user?: Resolver<HResolversTypes['users'], ParentType, ContextType>;
  userId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HThreadSubscriber_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['ThreadSubscriber_mutation_response'] = HResolversParentTypes['ThreadSubscriber_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['ThreadSubscriber']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HThread_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['Thread_mutation_response'] = HResolversParentTypes['Thread_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['Thread']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HUserNotificationResolvers<ContextType = any, ParentType extends HResolversParentTypes['UserNotification'] = HResolversParentTypes['UserNotification']> = {
  comment?: Resolver<HResolversTypes['Comment'], ParentType, ContextType>;
  commentId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  createdAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  isRead?: Resolver<HResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<HResolversTypes['timestamptz'], ParentType, ContextType>;
  user?: Resolver<HResolversTypes['users'], ParentType, ContextType>;
  userId?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HUserNotification_AggregateResolvers<ContextType = any, ParentType extends HResolversParentTypes['UserNotification_aggregate'] = HResolversParentTypes['UserNotification_aggregate']> = {
  aggregate?: Resolver<Maybe<HResolversTypes['UserNotification_aggregate_fields']>, ParentType, ContextType>;
  nodes?: Resolver<Array<HResolversTypes['UserNotification']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HUserNotification_Aggregate_FieldsResolvers<ContextType = any, ParentType extends HResolversParentTypes['UserNotification_aggregate_fields'] = HResolversParentTypes['UserNotification_aggregate_fields']> = {
  count?: Resolver<HResolversTypes['Int'], ParentType, ContextType, RequireFields<HUserNotification_Aggregate_FieldsCountArgs, never>>;
  max?: Resolver<Maybe<HResolversTypes['UserNotification_max_fields']>, ParentType, ContextType>;
  min?: Resolver<Maybe<HResolversTypes['UserNotification_min_fields']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HUserNotification_Max_FieldsResolvers<ContextType = any, ParentType extends HResolversParentTypes['UserNotification_max_fields'] = HResolversParentTypes['UserNotification_max_fields']> = {
  commentId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HUserNotification_Min_FieldsResolvers<ContextType = any, ParentType extends HResolversParentTypes['UserNotification_min_fields'] = HResolversParentTypes['UserNotification_min_fields']> = {
  commentId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  id?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<HResolversTypes['timestamptz']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<HResolversTypes['uuid']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HUserNotification_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['UserNotification_mutation_response'] = HResolversParentTypes['UserNotification_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['UserNotification']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HAuth_AccountsResolvers<ContextType = any, ParentType extends HResolversParentTypes['auth_accounts'] = HResolversParentTypes['auth_accounts']> = {
  custom_register_data?: Resolver<Maybe<HResolversTypes['jsonb']>, ParentType, ContextType, RequireFields<HAuth_AccountsCustom_Register_DataArgs, never>>;
  email?: Resolver<Maybe<HResolversTypes['citext']>, ParentType, ContextType>;
  user?: Resolver<HResolversTypes['users'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface HBigintScalarConfig extends GraphQLScalarTypeConfig<HResolversTypes['bigint'], any> {
  name: 'bigint';
}

export interface HCitextScalarConfig extends GraphQLScalarTypeConfig<HResolversTypes['citext'], any> {
  name: 'citext';
}

export interface HDateScalarConfig extends GraphQLScalarTypeConfig<HResolversTypes['date'], any> {
  name: 'date';
}

export interface HFloat8ScalarConfig extends GraphQLScalarTypeConfig<HResolversTypes['float8'], any> {
  name: 'float8';
}

export interface HJsonbScalarConfig extends GraphQLScalarTypeConfig<HResolversTypes['jsonb'], any> {
  name: 'jsonb';
}

export type HMutation_RootResolvers<ContextType = any, ParentType extends HResolversParentTypes['mutation_root'] = HResolversParentTypes['mutation_root']> = {
  delete_AnswerAttachment?: Resolver<Maybe<HResolversTypes['AnswerAttachment_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_AnswerAttachmentArgs, 'where'>>;
  delete_AnswerAttachment_by_pk?: Resolver<Maybe<HResolversTypes['AnswerAttachment']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_AnswerAttachment_By_PkArgs, 'id'>>;
  delete_AnswerNote?: Resolver<Maybe<HResolversTypes['AnswerNote_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_AnswerNoteArgs, 'where'>>;
  delete_AnswerNote_by_pk?: Resolver<Maybe<HResolversTypes['AnswerNote']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_AnswerNote_By_PkArgs, 'id'>>;
  delete_BusinessUnitDistributionKey?: Resolver<Maybe<HResolversTypes['BusinessUnitDistributionKey_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_BusinessUnitDistributionKeyArgs, 'where'>>;
  delete_BusinessUnitDistributionKey_by_pk?: Resolver<Maybe<HResolversTypes['BusinessUnitDistributionKey']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_BusinessUnitDistributionKey_By_PkArgs, 'id'>>;
  delete_Comment?: Resolver<Maybe<HResolversTypes['Comment_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_CommentArgs, 'where'>>;
  delete_Comment_by_pk?: Resolver<Maybe<HResolversTypes['Comment']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_Comment_By_PkArgs, 'id'>>;
  delete_File?: Resolver<Maybe<HResolversTypes['File_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_FileArgs, 'where'>>;
  delete_File_by_pk?: Resolver<Maybe<HResolversTypes['File']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_File_By_PkArgs, 'id'>>;
  delete_PortfolioCompany?: Resolver<Maybe<HResolversTypes['PortfolioCompany_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_PortfolioCompanyArgs, 'where'>>;
  delete_PortfolioCompany_by_pk?: Resolver<Maybe<HResolversTypes['PortfolioCompany']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_PortfolioCompany_By_PkArgs, 'id'>>;
  delete_ThreadSubscriber?: Resolver<Maybe<HResolversTypes['ThreadSubscriber_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_ThreadSubscriberArgs, 'where'>>;
  delete_ThreadSubscriber_by_pk?: Resolver<Maybe<HResolversTypes['ThreadSubscriber']>, ParentType, ContextType, RequireFields<HMutation_RootDelete_ThreadSubscriber_By_PkArgs, 'id'>>;
  insert_ActivityReport?: Resolver<Maybe<HResolversTypes['ActivityReport_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_ActivityReportArgs, 'objects'>>;
  insert_ActivityReport_one?: Resolver<Maybe<HResolversTypes['ActivityReport']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_ActivityReport_OneArgs, 'object'>>;
  insert_Answer?: Resolver<Maybe<HResolversTypes['Answer_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_AnswerArgs, 'objects'>>;
  insert_AnswerAttachment?: Resolver<Maybe<HResolversTypes['AnswerAttachment_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_AnswerAttachmentArgs, 'objects'>>;
  insert_AnswerAttachment_one?: Resolver<Maybe<HResolversTypes['AnswerAttachment']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_AnswerAttachment_OneArgs, 'object'>>;
  insert_AnswerNote?: Resolver<Maybe<HResolversTypes['AnswerNote_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_AnswerNoteArgs, 'objects'>>;
  insert_AnswerNote_one?: Resolver<Maybe<HResolversTypes['AnswerNote']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_AnswerNote_OneArgs, 'object'>>;
  insert_Answer_one?: Resolver<Maybe<HResolversTypes['Answer']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_Answer_OneArgs, 'object'>>;
  insert_BusinessUnit?: Resolver<Maybe<HResolversTypes['BusinessUnit_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_BusinessUnitArgs, 'objects'>>;
  insert_BusinessUnitAssessment?: Resolver<Maybe<HResolversTypes['BusinessUnitAssessment_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_BusinessUnitAssessmentArgs, 'objects'>>;
  insert_BusinessUnitAssessment_one?: Resolver<Maybe<HResolversTypes['BusinessUnitAssessment']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_BusinessUnitAssessment_OneArgs, 'object'>>;
  insert_BusinessUnitDistributionKey?: Resolver<Maybe<HResolversTypes['BusinessUnitDistributionKey_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_BusinessUnitDistributionKeyArgs, 'objects'>>;
  insert_BusinessUnitDistributionKey_one?: Resolver<Maybe<HResolversTypes['BusinessUnitDistributionKey']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_BusinessUnitDistributionKey_OneArgs, 'object'>>;
  insert_BusinessUnit_one?: Resolver<Maybe<HResolversTypes['BusinessUnit']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_BusinessUnit_OneArgs, 'object'>>;
  insert_Comment?: Resolver<Maybe<HResolversTypes['Comment_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_CommentArgs, 'objects'>>;
  insert_Comment_one?: Resolver<Maybe<HResolversTypes['Comment']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_Comment_OneArgs, 'object'>>;
  insert_Company?: Resolver<Maybe<HResolversTypes['Company_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_CompanyArgs, 'objects'>>;
  insert_CompanyAssessment?: Resolver<Maybe<HResolversTypes['CompanyAssessment_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_CompanyAssessmentArgs, 'objects'>>;
  insert_CompanyAssessmentAggregate?: Resolver<Maybe<HResolversTypes['CompanyAssessmentAggregate_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_CompanyAssessmentAggregateArgs, 'objects'>>;
  insert_CompanyAssessmentAggregate_one?: Resolver<Maybe<HResolversTypes['CompanyAssessmentAggregate']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_CompanyAssessmentAggregate_OneArgs, 'object'>>;
  insert_CompanyAssessment_one?: Resolver<Maybe<HResolversTypes['CompanyAssessment']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_CompanyAssessment_OneArgs, 'object'>>;
  insert_CompanyInvitation?: Resolver<Maybe<HResolversTypes['CompanyInvitation_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_CompanyInvitationArgs, 'objects'>>;
  insert_CompanyInvitation_one?: Resolver<Maybe<HResolversTypes['CompanyInvitation']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_CompanyInvitation_OneArgs, 'object'>>;
  insert_CompanyUser?: Resolver<Maybe<HResolversTypes['CompanyUser_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_CompanyUserArgs, 'objects'>>;
  insert_CompanyUser_one?: Resolver<Maybe<HResolversTypes['CompanyUser']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_CompanyUser_OneArgs, 'object'>>;
  insert_Company_one?: Resolver<Maybe<HResolversTypes['Company']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_Company_OneArgs, 'object'>>;
  insert_File?: Resolver<Maybe<HResolversTypes['File_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_FileArgs, 'objects'>>;
  insert_File_one?: Resolver<Maybe<HResolversTypes['File']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_File_OneArgs, 'object'>>;
  insert_Financials?: Resolver<Maybe<HResolversTypes['Financials_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_FinancialsArgs, 'objects'>>;
  insert_Financials_one?: Resolver<Maybe<HResolversTypes['Financials']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_Financials_OneArgs, 'object'>>;
  insert_Portfolio?: Resolver<Maybe<HResolversTypes['Portfolio_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_PortfolioArgs, 'objects'>>;
  insert_PortfolioCompany?: Resolver<Maybe<HResolversTypes['PortfolioCompany_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_PortfolioCompanyArgs, 'objects'>>;
  insert_PortfolioCompany_one?: Resolver<Maybe<HResolversTypes['PortfolioCompany']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_PortfolioCompany_OneArgs, 'object'>>;
  insert_Portfolio_one?: Resolver<Maybe<HResolversTypes['Portfolio']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_Portfolio_OneArgs, 'object'>>;
  insert_Thread?: Resolver<Maybe<HResolversTypes['Thread_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_ThreadArgs, 'objects'>>;
  insert_ThreadSubscriber?: Resolver<Maybe<HResolversTypes['ThreadSubscriber_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_ThreadSubscriberArgs, 'objects'>>;
  insert_ThreadSubscriber_one?: Resolver<Maybe<HResolversTypes['ThreadSubscriber']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_ThreadSubscriber_OneArgs, 'object'>>;
  insert_Thread_one?: Resolver<Maybe<HResolversTypes['Thread']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_Thread_OneArgs, 'object'>>;
  insert_UserNotification?: Resolver<Maybe<HResolversTypes['UserNotification_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_UserNotificationArgs, 'objects'>>;
  insert_UserNotification_one?: Resolver<Maybe<HResolversTypes['UserNotification']>, ParentType, ContextType, RequireFields<HMutation_RootInsert_UserNotification_OneArgs, 'object'>>;
  nudgePortfolioCompanies?: Resolver<Maybe<HResolversTypes['NudgePortfolioCompaniesOutput']>, ParentType, ContextType, RequireFields<HMutation_RootNudgePortfolioCompaniesArgs, 'data' | 'recipients'>>;
  requestQuote?: Resolver<Maybe<HResolversTypes['RequestQuoteOutput']>, ParentType, ContextType, RequireFields<HMutation_RootRequestQuoteArgs, 'data'>>;
  update_ActivityReport?: Resolver<Maybe<HResolversTypes['ActivityReport_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_ActivityReportArgs, 'where'>>;
  update_ActivityReport_by_pk?: Resolver<Maybe<HResolversTypes['ActivityReport']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_ActivityReport_By_PkArgs, 'pk_columns'>>;
  update_Answer?: Resolver<Maybe<HResolversTypes['Answer_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_AnswerArgs, 'where'>>;
  update_AnswerAttachment?: Resolver<Maybe<HResolversTypes['AnswerAttachment_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_AnswerAttachmentArgs, 'where'>>;
  update_AnswerAttachment_by_pk?: Resolver<Maybe<HResolversTypes['AnswerAttachment']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_AnswerAttachment_By_PkArgs, 'pk_columns'>>;
  update_Answer_by_pk?: Resolver<Maybe<HResolversTypes['Answer']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_Answer_By_PkArgs, 'pk_columns'>>;
  update_BusinessUnit?: Resolver<Maybe<HResolversTypes['BusinessUnit_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_BusinessUnitArgs, 'where'>>;
  update_BusinessUnitAssessment?: Resolver<Maybe<HResolversTypes['BusinessUnitAssessment_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_BusinessUnitAssessmentArgs, 'where'>>;
  update_BusinessUnitAssessment_by_pk?: Resolver<Maybe<HResolversTypes['BusinessUnitAssessment']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_BusinessUnitAssessment_By_PkArgs, 'pk_columns'>>;
  update_BusinessUnitDistributionKey?: Resolver<Maybe<HResolversTypes['BusinessUnitDistributionKey_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_BusinessUnitDistributionKeyArgs, 'where'>>;
  update_BusinessUnitDistributionKey_by_pk?: Resolver<Maybe<HResolversTypes['BusinessUnitDistributionKey']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_BusinessUnitDistributionKey_By_PkArgs, 'pk_columns'>>;
  update_BusinessUnit_by_pk?: Resolver<Maybe<HResolversTypes['BusinessUnit']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_BusinessUnit_By_PkArgs, 'pk_columns'>>;
  update_Comment?: Resolver<Maybe<HResolversTypes['Comment_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_CommentArgs, 'where'>>;
  update_Comment_by_pk?: Resolver<Maybe<HResolversTypes['Comment']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_Comment_By_PkArgs, 'pk_columns'>>;
  update_Company?: Resolver<Maybe<HResolversTypes['Company_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_CompanyArgs, 'where'>>;
  update_CompanyAssessment?: Resolver<Maybe<HResolversTypes['CompanyAssessment_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_CompanyAssessmentArgs, 'where'>>;
  update_CompanyAssessmentAggregate?: Resolver<Maybe<HResolversTypes['CompanyAssessmentAggregate_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_CompanyAssessmentAggregateArgs, 'where'>>;
  update_CompanyAssessmentAggregate_by_pk?: Resolver<Maybe<HResolversTypes['CompanyAssessmentAggregate']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_CompanyAssessmentAggregate_By_PkArgs, 'pk_columns'>>;
  update_CompanyAssessment_by_pk?: Resolver<Maybe<HResolversTypes['CompanyAssessment']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_CompanyAssessment_By_PkArgs, 'pk_columns'>>;
  update_CompanyInvitation?: Resolver<Maybe<HResolversTypes['CompanyInvitation_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_CompanyInvitationArgs, 'where'>>;
  update_CompanyInvitation_by_pk?: Resolver<Maybe<HResolversTypes['CompanyInvitation']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_CompanyInvitation_By_PkArgs, 'pk_columns'>>;
  update_CompanyUser?: Resolver<Maybe<HResolversTypes['CompanyUser_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_CompanyUserArgs, 'where'>>;
  update_CompanyUser_by_pk?: Resolver<Maybe<HResolversTypes['CompanyUser']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_CompanyUser_By_PkArgs, 'pk_columns'>>;
  update_Company_by_pk?: Resolver<Maybe<HResolversTypes['Company']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_Company_By_PkArgs, 'pk_columns'>>;
  update_File?: Resolver<Maybe<HResolversTypes['File_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_FileArgs, 'where'>>;
  update_File_by_pk?: Resolver<Maybe<HResolversTypes['File']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_File_By_PkArgs, 'pk_columns'>>;
  update_Financials?: Resolver<Maybe<HResolversTypes['Financials_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_FinancialsArgs, 'where'>>;
  update_Financials_by_pk?: Resolver<Maybe<HResolversTypes['Financials']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_Financials_By_PkArgs, 'pk_columns'>>;
  update_Portfolio?: Resolver<Maybe<HResolversTypes['Portfolio_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_PortfolioArgs, 'where'>>;
  update_PortfolioCompany?: Resolver<Maybe<HResolversTypes['PortfolioCompany_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_PortfolioCompanyArgs, 'where'>>;
  update_PortfolioCompany_by_pk?: Resolver<Maybe<HResolversTypes['PortfolioCompany']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_PortfolioCompany_By_PkArgs, 'pk_columns'>>;
  update_Portfolio_by_pk?: Resolver<Maybe<HResolversTypes['Portfolio']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_Portfolio_By_PkArgs, 'pk_columns'>>;
  update_Thread?: Resolver<Maybe<HResolversTypes['Thread_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_ThreadArgs, 'where'>>;
  update_ThreadSubscriber?: Resolver<Maybe<HResolversTypes['ThreadSubscriber_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_ThreadSubscriberArgs, 'where'>>;
  update_ThreadSubscriber_by_pk?: Resolver<Maybe<HResolversTypes['ThreadSubscriber']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_ThreadSubscriber_By_PkArgs, 'pk_columns'>>;
  update_Thread_by_pk?: Resolver<Maybe<HResolversTypes['Thread']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_Thread_By_PkArgs, 'pk_columns'>>;
  update_UserNotification?: Resolver<Maybe<HResolversTypes['UserNotification_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_UserNotificationArgs, 'where'>>;
  update_UserNotification_by_pk?: Resolver<Maybe<HResolversTypes['UserNotification']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_UserNotification_By_PkArgs, 'pk_columns'>>;
  update_users?: Resolver<Maybe<HResolversTypes['users_mutation_response']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_UsersArgs, 'where'>>;
  update_users_by_pk?: Resolver<Maybe<HResolversTypes['users']>, ParentType, ContextType, RequireFields<HMutation_RootUpdate_Users_By_PkArgs, 'pk_columns'>>;
};

export interface HNumericScalarConfig extends GraphQLScalarTypeConfig<HResolversTypes['numeric'], any> {
  name: 'numeric';
}

export type HQuery_RootResolvers<ContextType = any, ParentType extends HResolversParentTypes['query_root'] = HResolversParentTypes['query_root']> = {
  Activity?: Resolver<Array<HResolversTypes['Activity']>, ParentType, ContextType, RequireFields<HQuery_RootActivityArgs, never>>;
  ActivityNaceCode?: Resolver<Array<HResolversTypes['ActivityNaceCode']>, ParentType, ContextType, RequireFields<HQuery_RootActivityNaceCodeArgs, never>>;
  ActivityNaceCode_by_pk?: Resolver<Maybe<HResolversTypes['ActivityNaceCode']>, ParentType, ContextType, RequireFields<HQuery_RootActivityNaceCode_By_PkArgs, 'id'>>;
  ActivityQuestionSet?: Resolver<Array<HResolversTypes['ActivityQuestionSet']>, ParentType, ContextType, RequireFields<HQuery_RootActivityQuestionSetArgs, never>>;
  ActivityQuestionSet_by_pk?: Resolver<Maybe<HResolversTypes['ActivityQuestionSet']>, ParentType, ContextType, RequireFields<HQuery_RootActivityQuestionSet_By_PkArgs, 'id'>>;
  ActivityReport?: Resolver<Array<HResolversTypes['ActivityReport']>, ParentType, ContextType, RequireFields<HQuery_RootActivityReportArgs, never>>;
  ActivityReport_by_pk?: Resolver<Maybe<HResolversTypes['ActivityReport']>, ParentType, ContextType, RequireFields<HQuery_RootActivityReport_By_PkArgs, 'id'>>;
  Activity_by_pk?: Resolver<Maybe<HResolversTypes['Activity']>, ParentType, ContextType, RequireFields<HQuery_RootActivity_By_PkArgs, 'reference'>>;
  Answer?: Resolver<Array<HResolversTypes['Answer']>, ParentType, ContextType, RequireFields<HQuery_RootAnswerArgs, never>>;
  AnswerAttachment?: Resolver<Array<HResolversTypes['AnswerAttachment']>, ParentType, ContextType, RequireFields<HQuery_RootAnswerAttachmentArgs, never>>;
  AnswerAttachment_by_pk?: Resolver<Maybe<HResolversTypes['AnswerAttachment']>, ParentType, ContextType, RequireFields<HQuery_RootAnswerAttachment_By_PkArgs, 'id'>>;
  AnswerNote?: Resolver<Array<HResolversTypes['AnswerNote']>, ParentType, ContextType, RequireFields<HQuery_RootAnswerNoteArgs, never>>;
  AnswerNote_by_pk?: Resolver<Maybe<HResolversTypes['AnswerNote']>, ParentType, ContextType, RequireFields<HQuery_RootAnswerNote_By_PkArgs, 'id'>>;
  Answer_by_pk?: Resolver<Maybe<HResolversTypes['Answer']>, ParentType, ContextType, RequireFields<HQuery_RootAnswer_By_PkArgs, 'id'>>;
  Article?: Resolver<Array<HResolversTypes['Article']>, ParentType, ContextType, RequireFields<HQuery_RootArticleArgs, never>>;
  Article_by_pk?: Resolver<Maybe<HResolversTypes['Article']>, ParentType, ContextType, RequireFields<HQuery_RootArticle_By_PkArgs, 'id'>>;
  BusinessUnit?: Resolver<Array<HResolversTypes['BusinessUnit']>, ParentType, ContextType, RequireFields<HQuery_RootBusinessUnitArgs, never>>;
  BusinessUnitAssessment?: Resolver<Array<HResolversTypes['BusinessUnitAssessment']>, ParentType, ContextType, RequireFields<HQuery_RootBusinessUnitAssessmentArgs, never>>;
  BusinessUnitAssessment_by_pk?: Resolver<Maybe<HResolversTypes['BusinessUnitAssessment']>, ParentType, ContextType, RequireFields<HQuery_RootBusinessUnitAssessment_By_PkArgs, 'id'>>;
  BusinessUnitDistributionKey?: Resolver<Array<HResolversTypes['BusinessUnitDistributionKey']>, ParentType, ContextType, RequireFields<HQuery_RootBusinessUnitDistributionKeyArgs, never>>;
  BusinessUnitDistributionKey_by_pk?: Resolver<Maybe<HResolversTypes['BusinessUnitDistributionKey']>, ParentType, ContextType, RequireFields<HQuery_RootBusinessUnitDistributionKey_By_PkArgs, 'id'>>;
  BusinessUnit_by_pk?: Resolver<Maybe<HResolversTypes['BusinessUnit']>, ParentType, ContextType, RequireFields<HQuery_RootBusinessUnit_By_PkArgs, 'id'>>;
  Comment?: Resolver<Array<HResolversTypes['Comment']>, ParentType, ContextType, RequireFields<HQuery_RootCommentArgs, never>>;
  Comment_aggregate?: Resolver<HResolversTypes['Comment_aggregate'], ParentType, ContextType, RequireFields<HQuery_RootComment_AggregateArgs, never>>;
  Comment_by_pk?: Resolver<Maybe<HResolversTypes['Comment']>, ParentType, ContextType, RequireFields<HQuery_RootComment_By_PkArgs, 'id'>>;
  Company?: Resolver<Array<HResolversTypes['Company']>, ParentType, ContextType, RequireFields<HQuery_RootCompanyArgs, never>>;
  CompanyAssessment?: Resolver<Array<HResolversTypes['CompanyAssessment']>, ParentType, ContextType, RequireFields<HQuery_RootCompanyAssessmentArgs, never>>;
  CompanyAssessmentAggregate?: Resolver<Array<HResolversTypes['CompanyAssessmentAggregate']>, ParentType, ContextType, RequireFields<HQuery_RootCompanyAssessmentAggregateArgs, never>>;
  CompanyAssessmentAggregate_by_pk?: Resolver<Maybe<HResolversTypes['CompanyAssessmentAggregate']>, ParentType, ContextType, RequireFields<HQuery_RootCompanyAssessmentAggregate_By_PkArgs, 'id'>>;
  CompanyAssessment_by_pk?: Resolver<Maybe<HResolversTypes['CompanyAssessment']>, ParentType, ContextType, RequireFields<HQuery_RootCompanyAssessment_By_PkArgs, 'id'>>;
  CompanyInvitation?: Resolver<Array<HResolversTypes['CompanyInvitation']>, ParentType, ContextType, RequireFields<HQuery_RootCompanyInvitationArgs, never>>;
  CompanyInvitation_by_pk?: Resolver<Maybe<HResolversTypes['CompanyInvitation']>, ParentType, ContextType, RequireFields<HQuery_RootCompanyInvitation_By_PkArgs, 'id'>>;
  CompanyUser?: Resolver<Array<HResolversTypes['CompanyUser']>, ParentType, ContextType, RequireFields<HQuery_RootCompanyUserArgs, never>>;
  CompanyUser_by_pk?: Resolver<Maybe<HResolversTypes['CompanyUser']>, ParentType, ContextType, RequireFields<HQuery_RootCompanyUser_By_PkArgs, 'id'>>;
  Company_by_pk?: Resolver<Maybe<HResolversTypes['Company']>, ParentType, ContextType, RequireFields<HQuery_RootCompany_By_PkArgs, 'id'>>;
  EstimateCompany?: Resolver<Array<HResolversTypes['EstimateCompany']>, ParentType, ContextType, RequireFields<HQuery_RootEstimateCompanyArgs, never>>;
  EstimateCompany_by_pk?: Resolver<Maybe<HResolversTypes['EstimateCompany']>, ParentType, ContextType, RequireFields<HQuery_RootEstimateCompany_By_PkArgs, 'id'>>;
  File?: Resolver<Array<HResolversTypes['File']>, ParentType, ContextType, RequireFields<HQuery_RootFileArgs, never>>;
  File_by_pk?: Resolver<Maybe<HResolversTypes['File']>, ParentType, ContextType, RequireFields<HQuery_RootFile_By_PkArgs, 'id'>>;
  Financials?: Resolver<Array<HResolversTypes['Financials']>, ParentType, ContextType, RequireFields<HQuery_RootFinancialsArgs, never>>;
  Financials_by_pk?: Resolver<Maybe<HResolversTypes['Financials']>, ParentType, ContextType, RequireFields<HQuery_RootFinancials_By_PkArgs, 'id'>>;
  Objective?: Resolver<Array<HResolversTypes['Objective']>, ParentType, ContextType, RequireFields<HQuery_RootObjectiveArgs, never>>;
  Objective_by_pk?: Resolver<Maybe<HResolversTypes['Objective']>, ParentType, ContextType, RequireFields<HQuery_RootObjective_By_PkArgs, 'key'>>;
  Portfolio?: Resolver<Array<HResolversTypes['Portfolio']>, ParentType, ContextType, RequireFields<HQuery_RootPortfolioArgs, never>>;
  PortfolioCompany?: Resolver<Array<HResolversTypes['PortfolioCompany']>, ParentType, ContextType, RequireFields<HQuery_RootPortfolioCompanyArgs, never>>;
  PortfolioCompany_by_pk?: Resolver<Maybe<HResolversTypes['PortfolioCompany']>, ParentType, ContextType, RequireFields<HQuery_RootPortfolioCompany_By_PkArgs, 'id'>>;
  Portfolio_by_pk?: Resolver<Maybe<HResolversTypes['Portfolio']>, ParentType, ContextType, RequireFields<HQuery_RootPortfolio_By_PkArgs, 'id'>>;
  Question?: Resolver<Array<HResolversTypes['Question']>, ParentType, ContextType, RequireFields<HQuery_RootQuestionArgs, never>>;
  QuestionChoice?: Resolver<Array<HResolversTypes['QuestionChoice']>, ParentType, ContextType, RequireFields<HQuery_RootQuestionChoiceArgs, never>>;
  QuestionChoice_by_pk?: Resolver<Maybe<HResolversTypes['QuestionChoice']>, ParentType, ContextType, RequireFields<HQuery_RootQuestionChoice_By_PkArgs, 'id'>>;
  QuestionSet?: Resolver<Array<HResolversTypes['QuestionSet']>, ParentType, ContextType, RequireFields<HQuery_RootQuestionSetArgs, never>>;
  QuestionSet_by_pk?: Resolver<Maybe<HResolversTypes['QuestionSet']>, ParentType, ContextType, RequireFields<HQuery_RootQuestionSet_By_PkArgs, 'reference'>>;
  Question_by_pk?: Resolver<Maybe<HResolversTypes['Question']>, ParentType, ContextType, RequireFields<HQuery_RootQuestion_By_PkArgs, 'id'>>;
  Sector?: Resolver<Array<HResolversTypes['Sector']>, ParentType, ContextType, RequireFields<HQuery_RootSectorArgs, never>>;
  Sector_by_pk?: Resolver<Maybe<HResolversTypes['Sector']>, ParentType, ContextType, RequireFields<HQuery_RootSector_By_PkArgs, 'reference'>>;
  Tag?: Resolver<Array<HResolversTypes['Tag']>, ParentType, ContextType, RequireFields<HQuery_RootTagArgs, never>>;
  Tag_articles?: Resolver<Array<HResolversTypes['Tag_articles']>, ParentType, ContextType, RequireFields<HQuery_RootTag_ArticlesArgs, never>>;
  Tag_articles_by_pk?: Resolver<Maybe<HResolversTypes['Tag_articles']>, ParentType, ContextType, RequireFields<HQuery_RootTag_Articles_By_PkArgs, 'id'>>;
  Tag_by_pk?: Resolver<Maybe<HResolversTypes['Tag']>, ParentType, ContextType, RequireFields<HQuery_RootTag_By_PkArgs, 'id'>>;
  Thread?: Resolver<Array<HResolversTypes['Thread']>, ParentType, ContextType, RequireFields<HQuery_RootThreadArgs, never>>;
  ThreadSubscriber?: Resolver<Array<HResolversTypes['ThreadSubscriber']>, ParentType, ContextType, RequireFields<HQuery_RootThreadSubscriberArgs, never>>;
  ThreadSubscriber_by_pk?: Resolver<Maybe<HResolversTypes['ThreadSubscriber']>, ParentType, ContextType, RequireFields<HQuery_RootThreadSubscriber_By_PkArgs, 'id'>>;
  Thread_by_pk?: Resolver<Maybe<HResolversTypes['Thread']>, ParentType, ContextType, RequireFields<HQuery_RootThread_By_PkArgs, 'id'>>;
  UserNotification?: Resolver<Array<HResolversTypes['UserNotification']>, ParentType, ContextType, RequireFields<HQuery_RootUserNotificationArgs, never>>;
  UserNotification_aggregate?: Resolver<HResolversTypes['UserNotification_aggregate'], ParentType, ContextType, RequireFields<HQuery_RootUserNotification_AggregateArgs, never>>;
  UserNotification_by_pk?: Resolver<Maybe<HResolversTypes['UserNotification']>, ParentType, ContextType, RequireFields<HQuery_RootUserNotification_By_PkArgs, 'id'>>;
  auth_accounts?: Resolver<Array<HResolversTypes['auth_accounts']>, ParentType, ContextType, RequireFields<HQuery_RootAuth_AccountsArgs, never>>;
  getInvitation?: Resolver<Maybe<HResolversTypes['InvitationOutput']>, ParentType, ContextType, RequireFields<HQuery_RootGetInvitationArgs, 'query'>>;
  users?: Resolver<Array<HResolversTypes['users']>, ParentType, ContextType, RequireFields<HQuery_RootUsersArgs, never>>;
  users_by_pk?: Resolver<Maybe<HResolversTypes['users']>, ParentType, ContextType, RequireFields<HQuery_RootUsers_By_PkArgs, 'id'>>;
};

export type HSubscription_RootResolvers<ContextType = any, ParentType extends HResolversParentTypes['subscription_root'] = HResolversParentTypes['subscription_root']> = {
  Activity?: SubscriptionResolver<Array<HResolversTypes['Activity']>, "Activity", ParentType, ContextType, RequireFields<HSubscription_RootActivityArgs, never>>;
  ActivityNaceCode?: SubscriptionResolver<Array<HResolversTypes['ActivityNaceCode']>, "ActivityNaceCode", ParentType, ContextType, RequireFields<HSubscription_RootActivityNaceCodeArgs, never>>;
  ActivityNaceCode_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['ActivityNaceCode']>, "ActivityNaceCode_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootActivityNaceCode_By_PkArgs, 'id'>>;
  ActivityQuestionSet?: SubscriptionResolver<Array<HResolversTypes['ActivityQuestionSet']>, "ActivityQuestionSet", ParentType, ContextType, RequireFields<HSubscription_RootActivityQuestionSetArgs, never>>;
  ActivityQuestionSet_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['ActivityQuestionSet']>, "ActivityQuestionSet_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootActivityQuestionSet_By_PkArgs, 'id'>>;
  ActivityReport?: SubscriptionResolver<Array<HResolversTypes['ActivityReport']>, "ActivityReport", ParentType, ContextType, RequireFields<HSubscription_RootActivityReportArgs, never>>;
  ActivityReport_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['ActivityReport']>, "ActivityReport_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootActivityReport_By_PkArgs, 'id'>>;
  Activity_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Activity']>, "Activity_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootActivity_By_PkArgs, 'reference'>>;
  Answer?: SubscriptionResolver<Array<HResolversTypes['Answer']>, "Answer", ParentType, ContextType, RequireFields<HSubscription_RootAnswerArgs, never>>;
  AnswerAttachment?: SubscriptionResolver<Array<HResolversTypes['AnswerAttachment']>, "AnswerAttachment", ParentType, ContextType, RequireFields<HSubscription_RootAnswerAttachmentArgs, never>>;
  AnswerAttachment_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['AnswerAttachment']>, "AnswerAttachment_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootAnswerAttachment_By_PkArgs, 'id'>>;
  AnswerNote?: SubscriptionResolver<Array<HResolversTypes['AnswerNote']>, "AnswerNote", ParentType, ContextType, RequireFields<HSubscription_RootAnswerNoteArgs, never>>;
  AnswerNote_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['AnswerNote']>, "AnswerNote_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootAnswerNote_By_PkArgs, 'id'>>;
  Answer_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Answer']>, "Answer_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootAnswer_By_PkArgs, 'id'>>;
  Article?: SubscriptionResolver<Array<HResolversTypes['Article']>, "Article", ParentType, ContextType, RequireFields<HSubscription_RootArticleArgs, never>>;
  Article_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Article']>, "Article_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootArticle_By_PkArgs, 'id'>>;
  BusinessUnit?: SubscriptionResolver<Array<HResolversTypes['BusinessUnit']>, "BusinessUnit", ParentType, ContextType, RequireFields<HSubscription_RootBusinessUnitArgs, never>>;
  BusinessUnitAssessment?: SubscriptionResolver<Array<HResolversTypes['BusinessUnitAssessment']>, "BusinessUnitAssessment", ParentType, ContextType, RequireFields<HSubscription_RootBusinessUnitAssessmentArgs, never>>;
  BusinessUnitAssessment_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['BusinessUnitAssessment']>, "BusinessUnitAssessment_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootBusinessUnitAssessment_By_PkArgs, 'id'>>;
  BusinessUnitDistributionKey?: SubscriptionResolver<Array<HResolversTypes['BusinessUnitDistributionKey']>, "BusinessUnitDistributionKey", ParentType, ContextType, RequireFields<HSubscription_RootBusinessUnitDistributionKeyArgs, never>>;
  BusinessUnitDistributionKey_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['BusinessUnitDistributionKey']>, "BusinessUnitDistributionKey_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootBusinessUnitDistributionKey_By_PkArgs, 'id'>>;
  BusinessUnit_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['BusinessUnit']>, "BusinessUnit_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootBusinessUnit_By_PkArgs, 'id'>>;
  Comment?: SubscriptionResolver<Array<HResolversTypes['Comment']>, "Comment", ParentType, ContextType, RequireFields<HSubscription_RootCommentArgs, never>>;
  Comment_aggregate?: SubscriptionResolver<HResolversTypes['Comment_aggregate'], "Comment_aggregate", ParentType, ContextType, RequireFields<HSubscription_RootComment_AggregateArgs, never>>;
  Comment_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Comment']>, "Comment_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootComment_By_PkArgs, 'id'>>;
  Company?: SubscriptionResolver<Array<HResolversTypes['Company']>, "Company", ParentType, ContextType, RequireFields<HSubscription_RootCompanyArgs, never>>;
  CompanyAssessment?: SubscriptionResolver<Array<HResolversTypes['CompanyAssessment']>, "CompanyAssessment", ParentType, ContextType, RequireFields<HSubscription_RootCompanyAssessmentArgs, never>>;
  CompanyAssessmentAggregate?: SubscriptionResolver<Array<HResolversTypes['CompanyAssessmentAggregate']>, "CompanyAssessmentAggregate", ParentType, ContextType, RequireFields<HSubscription_RootCompanyAssessmentAggregateArgs, never>>;
  CompanyAssessmentAggregate_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['CompanyAssessmentAggregate']>, "CompanyAssessmentAggregate_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootCompanyAssessmentAggregate_By_PkArgs, 'id'>>;
  CompanyAssessment_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['CompanyAssessment']>, "CompanyAssessment_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootCompanyAssessment_By_PkArgs, 'id'>>;
  CompanyInvitation?: SubscriptionResolver<Array<HResolversTypes['CompanyInvitation']>, "CompanyInvitation", ParentType, ContextType, RequireFields<HSubscription_RootCompanyInvitationArgs, never>>;
  CompanyInvitation_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['CompanyInvitation']>, "CompanyInvitation_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootCompanyInvitation_By_PkArgs, 'id'>>;
  CompanyUser?: SubscriptionResolver<Array<HResolversTypes['CompanyUser']>, "CompanyUser", ParentType, ContextType, RequireFields<HSubscription_RootCompanyUserArgs, never>>;
  CompanyUser_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['CompanyUser']>, "CompanyUser_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootCompanyUser_By_PkArgs, 'id'>>;
  Company_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Company']>, "Company_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootCompany_By_PkArgs, 'id'>>;
  EstimateCompany?: SubscriptionResolver<Array<HResolversTypes['EstimateCompany']>, "EstimateCompany", ParentType, ContextType, RequireFields<HSubscription_RootEstimateCompanyArgs, never>>;
  EstimateCompany_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['EstimateCompany']>, "EstimateCompany_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootEstimateCompany_By_PkArgs, 'id'>>;
  File?: SubscriptionResolver<Array<HResolversTypes['File']>, "File", ParentType, ContextType, RequireFields<HSubscription_RootFileArgs, never>>;
  File_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['File']>, "File_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootFile_By_PkArgs, 'id'>>;
  Financials?: SubscriptionResolver<Array<HResolversTypes['Financials']>, "Financials", ParentType, ContextType, RequireFields<HSubscription_RootFinancialsArgs, never>>;
  Financials_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Financials']>, "Financials_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootFinancials_By_PkArgs, 'id'>>;
  Objective?: SubscriptionResolver<Array<HResolversTypes['Objective']>, "Objective", ParentType, ContextType, RequireFields<HSubscription_RootObjectiveArgs, never>>;
  Objective_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Objective']>, "Objective_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootObjective_By_PkArgs, 'key'>>;
  Portfolio?: SubscriptionResolver<Array<HResolversTypes['Portfolio']>, "Portfolio", ParentType, ContextType, RequireFields<HSubscription_RootPortfolioArgs, never>>;
  PortfolioCompany?: SubscriptionResolver<Array<HResolversTypes['PortfolioCompany']>, "PortfolioCompany", ParentType, ContextType, RequireFields<HSubscription_RootPortfolioCompanyArgs, never>>;
  PortfolioCompany_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['PortfolioCompany']>, "PortfolioCompany_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootPortfolioCompany_By_PkArgs, 'id'>>;
  Portfolio_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Portfolio']>, "Portfolio_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootPortfolio_By_PkArgs, 'id'>>;
  Question?: SubscriptionResolver<Array<HResolversTypes['Question']>, "Question", ParentType, ContextType, RequireFields<HSubscription_RootQuestionArgs, never>>;
  QuestionChoice?: SubscriptionResolver<Array<HResolversTypes['QuestionChoice']>, "QuestionChoice", ParentType, ContextType, RequireFields<HSubscription_RootQuestionChoiceArgs, never>>;
  QuestionChoice_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['QuestionChoice']>, "QuestionChoice_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootQuestionChoice_By_PkArgs, 'id'>>;
  QuestionSet?: SubscriptionResolver<Array<HResolversTypes['QuestionSet']>, "QuestionSet", ParentType, ContextType, RequireFields<HSubscription_RootQuestionSetArgs, never>>;
  QuestionSet_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['QuestionSet']>, "QuestionSet_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootQuestionSet_By_PkArgs, 'reference'>>;
  Question_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Question']>, "Question_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootQuestion_By_PkArgs, 'id'>>;
  Sector?: SubscriptionResolver<Array<HResolversTypes['Sector']>, "Sector", ParentType, ContextType, RequireFields<HSubscription_RootSectorArgs, never>>;
  Sector_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Sector']>, "Sector_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootSector_By_PkArgs, 'reference'>>;
  Tag?: SubscriptionResolver<Array<HResolversTypes['Tag']>, "Tag", ParentType, ContextType, RequireFields<HSubscription_RootTagArgs, never>>;
  Tag_articles?: SubscriptionResolver<Array<HResolversTypes['Tag_articles']>, "Tag_articles", ParentType, ContextType, RequireFields<HSubscription_RootTag_ArticlesArgs, never>>;
  Tag_articles_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Tag_articles']>, "Tag_articles_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootTag_Articles_By_PkArgs, 'id'>>;
  Tag_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Tag']>, "Tag_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootTag_By_PkArgs, 'id'>>;
  Thread?: SubscriptionResolver<Array<HResolversTypes['Thread']>, "Thread", ParentType, ContextType, RequireFields<HSubscription_RootThreadArgs, never>>;
  ThreadSubscriber?: SubscriptionResolver<Array<HResolversTypes['ThreadSubscriber']>, "ThreadSubscriber", ParentType, ContextType, RequireFields<HSubscription_RootThreadSubscriberArgs, never>>;
  ThreadSubscriber_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['ThreadSubscriber']>, "ThreadSubscriber_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootThreadSubscriber_By_PkArgs, 'id'>>;
  Thread_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['Thread']>, "Thread_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootThread_By_PkArgs, 'id'>>;
  UserNotification?: SubscriptionResolver<Array<HResolversTypes['UserNotification']>, "UserNotification", ParentType, ContextType, RequireFields<HSubscription_RootUserNotificationArgs, never>>;
  UserNotification_aggregate?: SubscriptionResolver<HResolversTypes['UserNotification_aggregate'], "UserNotification_aggregate", ParentType, ContextType, RequireFields<HSubscription_RootUserNotification_AggregateArgs, never>>;
  UserNotification_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['UserNotification']>, "UserNotification_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootUserNotification_By_PkArgs, 'id'>>;
  auth_accounts?: SubscriptionResolver<Array<HResolversTypes['auth_accounts']>, "auth_accounts", ParentType, ContextType, RequireFields<HSubscription_RootAuth_AccountsArgs, never>>;
  users?: SubscriptionResolver<Array<HResolversTypes['users']>, "users", ParentType, ContextType, RequireFields<HSubscription_RootUsersArgs, never>>;
  users_by_pk?: SubscriptionResolver<Maybe<HResolversTypes['users']>, "users_by_pk", ParentType, ContextType, RequireFields<HSubscription_RootUsers_By_PkArgs, 'id'>>;
};

export interface HTimestamptzScalarConfig extends GraphQLScalarTypeConfig<HResolversTypes['timestamptz'], any> {
  name: 'timestamptz';
}

export type HUsersResolvers<ContextType = any, ParentType extends HResolversParentTypes['users'] = HResolversParentTypes['users']> = {
  AnswerNotes?: Resolver<Array<HResolversTypes['AnswerNote']>, ParentType, ContextType, RequireFields<HUsersAnswerNotesArgs, never>>;
  Answers?: Resolver<Array<HResolversTypes['Answer']>, ParentType, ContextType, RequireFields<HUsersAnswersArgs, never>>;
  BusinessUnits?: Resolver<Array<HResolversTypes['BusinessUnit']>, ParentType, ContextType, RequireFields<HUsersBusinessUnitsArgs, never>>;
  Comments?: Resolver<Array<HResolversTypes['Comment']>, ParentType, ContextType, RequireFields<HUsersCommentsArgs, never>>;
  Comments_aggregate?: Resolver<HResolversTypes['Comment_aggregate'], ParentType, ContextType, RequireFields<HUsersComments_AggregateArgs, never>>;
  Files?: Resolver<Array<HResolversTypes['File']>, ParentType, ContextType, RequireFields<HUsersFilesArgs, never>>;
  account?: Resolver<Maybe<HResolversTypes['auth_accounts']>, ParentType, ContextType>;
  avatar_url?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  companyUsers?: Resolver<Array<HResolversTypes['CompanyUser']>, ParentType, ContextType, RequireFields<HUsersCompanyUsersArgs, never>>;
  display_name?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<HResolversTypes['uuid'], ParentType, ContextType>;
  invitations?: Resolver<Array<HResolversTypes['CompanyInvitation']>, ParentType, ContextType, RequireFields<HUsersInvitationsArgs, never>>;
  language?: Resolver<HResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<HResolversTypes['String']>, ParentType, ContextType>;
  notifications?: Resolver<Array<HResolversTypes['UserNotification']>, ParentType, ContextType, RequireFields<HUsersNotificationsArgs, never>>;
  notifications_aggregate?: Resolver<HResolversTypes['UserNotification_aggregate'], ParentType, ContextType, RequireFields<HUsersNotifications_AggregateArgs, never>>;
  settings?: Resolver<HResolversTypes['jsonb'], ParentType, ContextType, RequireFields<HUsersSettingsArgs, never>>;
  threadSubscriptions?: Resolver<Array<HResolversTypes['ThreadSubscriber']>, ParentType, ContextType, RequireFields<HUsersThreadSubscriptionsArgs, never>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HUsers_Mutation_ResponseResolvers<ContextType = any, ParentType extends HResolversParentTypes['users_mutation_response'] = HResolversParentTypes['users_mutation_response']> = {
  affected_rows?: Resolver<HResolversTypes['Int'], ParentType, ContextType>;
  returning?: Resolver<Array<HResolversTypes['users']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface HUuidScalarConfig extends GraphQLScalarTypeConfig<HResolversTypes['uuid'], any> {
  name: 'uuid';
}

export type HResolvers<ContextType = any> = {
  Activity?: HActivityResolvers<ContextType>;
  ActivityNaceCode?: HActivityNaceCodeResolvers<ContextType>;
  ActivityQuestionSet?: HActivityQuestionSetResolvers<ContextType>;
  ActivityReport?: HActivityReportResolvers<ContextType>;
  ActivityReport_mutation_response?: HActivityReport_Mutation_ResponseResolvers<ContextType>;
  Answer?: HAnswerResolvers<ContextType>;
  AnswerAttachment?: HAnswerAttachmentResolvers<ContextType>;
  AnswerAttachment_mutation_response?: HAnswerAttachment_Mutation_ResponseResolvers<ContextType>;
  AnswerNote?: HAnswerNoteResolvers<ContextType>;
  AnswerNote_mutation_response?: HAnswerNote_Mutation_ResponseResolvers<ContextType>;
  Answer_mutation_response?: HAnswer_Mutation_ResponseResolvers<ContextType>;
  Article?: HArticleResolvers<ContextType>;
  BusinessUnit?: HBusinessUnitResolvers<ContextType>;
  BusinessUnitAssessment?: HBusinessUnitAssessmentResolvers<ContextType>;
  BusinessUnitAssessment_mutation_response?: HBusinessUnitAssessment_Mutation_ResponseResolvers<ContextType>;
  BusinessUnitDistributionKey?: HBusinessUnitDistributionKeyResolvers<ContextType>;
  BusinessUnitDistributionKey_mutation_response?: HBusinessUnitDistributionKey_Mutation_ResponseResolvers<ContextType>;
  BusinessUnit_mutation_response?: HBusinessUnit_Mutation_ResponseResolvers<ContextType>;
  Comment?: HCommentResolvers<ContextType>;
  Comment_aggregate?: HComment_AggregateResolvers<ContextType>;
  Comment_aggregate_fields?: HComment_Aggregate_FieldsResolvers<ContextType>;
  Comment_max_fields?: HComment_Max_FieldsResolvers<ContextType>;
  Comment_min_fields?: HComment_Min_FieldsResolvers<ContextType>;
  Comment_mutation_response?: HComment_Mutation_ResponseResolvers<ContextType>;
  Company?: HCompanyResolvers<ContextType>;
  CompanyAssessment?: HCompanyAssessmentResolvers<ContextType>;
  CompanyAssessmentAggregate?: HCompanyAssessmentAggregateResolvers<ContextType>;
  CompanyAssessmentAggregate_mutation_response?: HCompanyAssessmentAggregate_Mutation_ResponseResolvers<ContextType>;
  CompanyAssessment_mutation_response?: HCompanyAssessment_Mutation_ResponseResolvers<ContextType>;
  CompanyInvitation?: HCompanyInvitationResolvers<ContextType>;
  CompanyInvitation_mutation_response?: HCompanyInvitation_Mutation_ResponseResolvers<ContextType>;
  CompanyUser?: HCompanyUserResolvers<ContextType>;
  CompanyUser_mutation_response?: HCompanyUser_Mutation_ResponseResolvers<ContextType>;
  Company_mutation_response?: HCompany_Mutation_ResponseResolvers<ContextType>;
  EstimateCompany?: HEstimateCompanyResolvers<ContextType>;
  File?: HFileResolvers<ContextType>;
  File_mutation_response?: HFile_Mutation_ResponseResolvers<ContextType>;
  Financials?: HFinancialsResolvers<ContextType>;
  Financials_mutation_response?: HFinancials_Mutation_ResponseResolvers<ContextType>;
  InvitationOutput?: HInvitationOutputResolvers<ContextType>;
  NudgePortfolioCompaniesOutput?: HNudgePortfolioCompaniesOutputResolvers<ContextType>;
  Objective?: HObjectiveResolvers<ContextType>;
  Portfolio?: HPortfolioResolvers<ContextType>;
  PortfolioCompany?: HPortfolioCompanyResolvers<ContextType>;
  PortfolioCompany_mutation_response?: HPortfolioCompany_Mutation_ResponseResolvers<ContextType>;
  Portfolio_mutation_response?: HPortfolio_Mutation_ResponseResolvers<ContextType>;
  Question?: HQuestionResolvers<ContextType>;
  QuestionChoice?: HQuestionChoiceResolvers<ContextType>;
  QuestionSet?: HQuestionSetResolvers<ContextType>;
  RequestQuoteOutput?: HRequestQuoteOutputResolvers<ContextType>;
  Sector?: HSectorResolvers<ContextType>;
  Tag?: HTagResolvers<ContextType>;
  Tag_articles?: HTag_ArticlesResolvers<ContextType>;
  Thread?: HThreadResolvers<ContextType>;
  ThreadSubscriber?: HThreadSubscriberResolvers<ContextType>;
  ThreadSubscriber_mutation_response?: HThreadSubscriber_Mutation_ResponseResolvers<ContextType>;
  Thread_mutation_response?: HThread_Mutation_ResponseResolvers<ContextType>;
  UserNotification?: HUserNotificationResolvers<ContextType>;
  UserNotification_aggregate?: HUserNotification_AggregateResolvers<ContextType>;
  UserNotification_aggregate_fields?: HUserNotification_Aggregate_FieldsResolvers<ContextType>;
  UserNotification_max_fields?: HUserNotification_Max_FieldsResolvers<ContextType>;
  UserNotification_min_fields?: HUserNotification_Min_FieldsResolvers<ContextType>;
  UserNotification_mutation_response?: HUserNotification_Mutation_ResponseResolvers<ContextType>;
  auth_accounts?: HAuth_AccountsResolvers<ContextType>;
  bigint?: GraphQLScalarType;
  citext?: GraphQLScalarType;
  date?: GraphQLScalarType;
  float8?: GraphQLScalarType;
  jsonb?: GraphQLScalarType;
  mutation_root?: HMutation_RootResolvers<ContextType>;
  numeric?: GraphQLScalarType;
  query_root?: HQuery_RootResolvers<ContextType>;
  subscription_root?: HSubscription_RootResolvers<ContextType>;
  timestamptz?: GraphQLScalarType;
  users?: HUsersResolvers<ContextType>;
  users_mutation_response?: HUsers_Mutation_ResponseResolvers<ContextType>;
  uuid?: GraphQLScalarType;
};

export type HDirectiveResolvers<ContextType = any> = {
  cached?: HCachedDirectiveResolver<any, any, ContextType>;
};
