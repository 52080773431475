import { Heading, Box, Text, HStack, VStack, Radio, RadioGroup } from '@chakra-ui/react';
import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useRouter } from 'next/router';
import { FormikFormField, FormikSubmitButton, VForm } from './ui/forms/FormField';
import { useCreateInitialCompany, User, UserCompany } from 'models';
import { useBigToast } from '../hooks/useToast';
import DefaultModal from './ui/Modal';
import { Select } from './ui/forms/Select';
import { CompanyIcon, PortfolioIcon, CircleIconWrapper, IconType } from 'icons';
import { useEffectOnce } from 'react-use';
import { TFunction, useTranslation, tenum } from 'utils/i18n';
import { gtmTrack } from 'utils/gtm';

export const COMPANY_INDUSTRIES_SCHEMA = (t: TFunction) =>
  Yup.array().min(1, t('common:fields.companyIndustries.validateMin'));

export const CompanyRegistrationSchema = (t: TFunction) =>
  Yup.object().shape({
    companyName: Yup.string()
      .trim()
      .label(t('common:fields.companyName.label'))
      .required(t('common:fields.companyName.required')),
    isPortfolioOwner: Yup.boolean(),
    currency: Yup.string().trim().required(),
  });

// change here
export const CURRENCIES = [
  tenum('common:fields.currency.NOK'),
  tenum('common:fields.currency.EUR'),
  tenum('common:fields.currency.USD'),
  tenum('common:fields.currency.GBP'),
  tenum('common:fields.currency.SEK'),
  tenum('common:fields.currency.DKK'),
  tenum('common:fields.currency.CHF'),
  tenum('common:fields.currency.PLN'),
  tenum('common:fields.currency.CZK'),
  tenum('common:fields.currency.HUF'),
  tenum('common:fields.currency.TRY'),
  tenum('common:fields.currency.BGN'),
  tenum('common:fields.currency.HRK'),
  tenum('common:fields.currency.RON'),
];

export function CurrencyField() {
  const { t } = useTranslation();
  return (
    <FormikFormField<string>
      name="currency"
      label={t('common:fields.currency.label_long')}
      isRequired
    >
      {/* @ts-ignore */}
      {({ value, setValue }) => (
        <Box width="20em">
          <Select
            value={{
              value,
              label: CURRENCIES.includes(value as any)
                ? t('common:fields.currency.' + value)
                : value,
            }}
            onChange={(v: any) => v && setValue(v.value)}
            isSearchable={true}
            isMulti={false}
            options={
              CURRENCIES.map((v) => ({
                value: v as string,
                label: t('common:fields.currency.' + v) as string,
              })) as [{ value: string; label: string }]
            }
            size="lg"
          />
        </Box>
      )}
    </FormikFormField>
  );
}
export function CompanyTypeRadio({
  icon,
  header,
  text,
  value,
}: {
  icon: IconType;
  header: string;
  text: string;
  value: string;
}) {
  return (
    <Radio value={value}>
      <VStack textAlign="center" spacing="sm">
        <CircleIconWrapper icon={icon} size="56px" scale={24 / 56} />
        <Heading fontSize="24px">{header}</Heading>
        <Text fontSize="md">{text}</Text>
      </VStack>
    </Radio>
  );
}
export function IsPortfolioOwnerField() {
  const { t } = useTranslation();
  return (
    <FormikFormField
      name="isPortfolioOwner"
      label={t('common:fields.isPortfolioOwner.label')}
      isRequired
    >
      {/* @ts-ignore */}
      {(props) => (
        <RadioGroup
          value={props.value ? '1' : '0'}
          onChange={(v) => props.setValue(v === '1')}
          name={props.name}
          as={HStack}
          width="100%"
          spacing="lg"
          alignItems="stretch"
          sx={{
            '.chakra-radio': {
              border: '1px solid #8F8F8F',
              padding: 'lg',
              borderRadius: '16px',
              width: '20em',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              position: 'relative',
              flexGrow: 1,
              cursor: 'pointer',
            },
            '.chakra-radio:hover': {
              bg: '#F7FAFD',
            },
            '.chakra-radio[data-checked]': {
              borderColor: 'primary.main',
            },
          }}
        >
          <CompanyTypeRadio
            value="0"
            icon={CompanyIcon}
            header={t('common:fields.isPortfolioOwner.falseLabel')}
            text={t('common:fields.isPortfolioOwner.falseDescription')}
          />
          <CompanyTypeRadio
            value="1"
            icon={PortfolioIcon}
            header={t('common:fields.isPortfolioOwner.trueLabel')}
            text={t('common:fields.isPortfolioOwner.trueDescription')}
          />
        </RadioGroup>
      )}
    </FormikFormField>
  );
}
export function RegisterCompanyForm({
  user,
  onSave,
  initialData,
}: {
  user: User;
  onSave?: (c: UserCompany) => void;
  initialData?: {
    companyName: string;
    isPortfolioOwner: boolean;
  };
}) {
  const { replace } = useRouter();
  const toast = useBigToast();
  const { t } = useTranslation();
  const schema = CompanyRegistrationSchema(t);
  const createInitialCompany = useCreateInitialCompany();

  const handleRegisterCompany = (values: any) => {
    const { companyName, ...companyData } = schema.cast(values);
    const company = { name: companyName, ...companyData };
    return createInitialCompany(user.id, companyName, company.isPortfolioOwner, company.currency)
      .then((data) => {
        gtmTrack('sign_up_company');
        if (data.company) onSave?.(data.company);
      })
      .catch(() =>
        toast({
          title: t('common:toast.companyCreateFailed.title'),
          description: t('common:toast.companyCreateFailed.description'),
          status: 'error',
        })
      )
      .finally(() => replace('/'));
  };

  useEffectOnce(() => document.getElementById('field-companyName')?.focus());
  return (
    <Formik
      initialValues={{
        companyName: initialData?.companyName ?? '',
        isPortfolioOwner: initialData?.isPortfolioOwner ?? false,
        currency: 'NOK',
      }}
      validationSchema={schema}
      onSubmit={handleRegisterCompany}
      key="company"
    >
      <VForm>
        <Heading fontSize="xl" mt="-10px" mb="lg">
          {t('common:companyModal.formHeader')}
        </Heading>
        <FormikFormField
          name="companyName"
          label={t('common:fields.companyName.label')}
          isRequired
        />
        <IsPortfolioOwnerField />
        <CurrencyField />
        <FormikSubmitButton>{t('common:companyModal.createButton')}</FormikSubmitButton>
      </VForm>
    </Formik>
  );
}

export function CompanyRegisterModal({
  user,
  isOpen,
  onClose,
  header,
  onSave,
}: {
  user: User;
  isOpen: boolean;
  onClose?: () => void;
  header?: string;
  onSave?: (c: UserCompany) => void;
}) {
  const { t } = useTranslation();

  return (
    <DefaultModal
      isOpen={isOpen}
      size="4xl"
      header={header ?? t('common:companyModal.defaultHeader')}
      onClose={onClose}
    >
      <RegisterCompanyForm user={user} onSave={onSave} />
    </DefaultModal>
  );
}
