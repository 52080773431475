import { Heading, VStack, MenuButton, Button, Menu, Box } from '@chakra-ui/react';
import { ChangePasswordIcon, ChevronDownIcon } from 'icons';
import React from 'react';

import { useRouter } from 'next/router';

import { auth } from 'utils/nhost';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container } from '../components/Container';

import Card from '../components/Card';
import Logo from '../components/topMenu/Logo';
import { setLoggedInUser, useLogout } from 'models/localState';
import { ButtonLink } from '../components/ui/Links';
import { useBigToast } from '../hooks/useToast';
import { FormikFormField, FormikSubmitButton, VForm } from '../components/ui/forms/FormField';
import { getServerSidePropsFunc } from 'utils/i18n/server';
import { getLanguageMap, useChangeLanguage, useTranslation, TFunction } from 'utils/i18n';
import { ActionList } from 'components/ui/ActionMenu';
import { usePageTitle, withNoNavigation } from 'components/topMenu/Navigation';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from 'utils/mixpanel';

const LoginSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(t('login:fields.email.validate'))
      .required(t('login:fields.email.required')),
    password: Yup.string().required(t('login:fields.password.required')),
  });
export const LanguageSwitcher = () => {
  const map = getLanguageMap();
  const { changeLanguage, language } = useChangeLanguage();
  return (
    <Menu placement="bottom-end" computePositionOnMount>
      <MenuButton as={Button} variant="link" rightIcon={<ChevronDownIcon />}>
        {map[language]}
      </MenuButton>
      <ActionList
        actions={Object.entries(map).map(([code, name]) => ({
          name,
          onClick: () => changeLanguage(code),
        }))}
      />
    </Menu>
  );
};

export const LoginPage = ({
  children,
  heading,
}: {
  heading: string;
  children: React.ReactNode;
}) => (
  <Container height="100%" minHeight="100vh" maxW="xl" m="auto">
    <Card
      alignItems="center"
      pt="8"
      mt="32"
      position="relative"
      width="100%"
      shadow="0px 0px 57px rgba(0, 0, 0, 0.07)"
    >
      <Logo size="150px" />
      {heading.split('\n\n').map((h) => (
        <Heading fontSize="2xl" key={h}>
          {h}
        </Heading>
      ))}
      <VStack width="100%" mt={4}>
        {children}
      </VStack>
    </Card>
    <Box position="absolute" top="lg" right="lg">
      <LanguageSwitcher />
    </Box>
  </Container>
);

export const LoginForm = ({ onSuccess, email }: { onSuccess: () => any; email?: string }) => {
  const toast = useBigToast();
  const { t } = useTranslation('login');
  const logout = useLogout();
  const handleLogin = async (values: { email: string; password: string }) => {
    const fields = { ...values, email: values.email.trim() };
    await logout();
    return auth.login(fields).then((res) => {
      if (res.user) {
        setLoggedInUser(res.user.email ?? '');
        mixpanel.track(TRACKING_EVENTS.LOGIN);
      }
    });
  };

  return (
    <Formik
      initialValues={{ email: email ?? '', password: '' }}
      validationSchema={LoginSchema(t)}
      onSubmit={(values) =>
        handleLogin(values)
          .then(() => onSuccess())
          .catch(() =>
            toast({
              title: t('login:logInFailed'),
              description: t('common:tryAgain'),
              status: 'error',
            })
          )
      }
    >
      <VForm bigLabels>
        <FormikFormField
          name="email"
          label={t('login:fields.email.label')}
          autoComplete="username"
          size="xl"
          isDisabled={!!email}
        />
        <FormikFormField
          name="password"
          label={t('login:fields.password.label')}
          type="password"
          autoComplete="current-password"
          size="xl"
        />

        <FormikSubmitButton>{t('logIn')}</FormikSubmitButton>
      </VForm>
    </Formik>
  );
};
export default withNoNavigation(function Login() {
  const { push } = useRouter();
  const { t } = useTranslation('login');
  usePageTitle(t('login:logIn'));
  return (
    <LoginPage heading={t('login:loginHeader')}>
      <LoginForm onSuccess={() => push('/')} />
      <ButtonLink href="/changePassword" leftIcon={<ChangePasswordIcon />}>
        {t('login:forgotPassword')}
      </ButtonLink>
    </LoginPage>
  );
});

export const getServerSideProps = getServerSidePropsFunc(['common', 'login']);
