import React from 'react';
import { useCookieConsent, CookieConsent, DEFAULT_COOKIE_CONSENT } from 'hooks/useCookieConsent';
import { Button, Heading, HStack, VStack, Text, Slide, Checkbox } from '@chakra-ui/react';
import { useTranslation } from 'utils/i18n';

const PopupWrapper = ({
  consent,
  children,
}: {
  consent: CookieConsent;
  children: React.ReactNode;
}) => (
  <Slide direction="right" in={!consent.isSetup} style={{ zIndex: 10 }}>
    <VStack
      width="550px"
      mr="32px"
      mb="32px"
      position="absolute"
      bottom="0"
      padding="16px"
      justifyContent="flex-start"
      alignItems="flex-start"
      right="0"
      background="black"
      color="white"
      spacing="32px"
    >
      {children}
    </VStack>
  </Slide>
);

const OPTIONS: { [key: string]: { disabled: boolean } } = {
  essential: { disabled: true },
  analytics: { disabled: false },
  marketing: { disabled: false },
  functional: { disabled: false },
};

const CookieConsentModal = () => {
  const { t } = useTranslation('common');
  const { consent, onUpdate } = useCookieConsent();
  const [customize, setCustomize] = React.useState(false);
  const [selections, setSelections] = React.useState<CookieConsent>(DEFAULT_COOKIE_CONSENT);

  const onAcceptSelections = () => {
    onUpdate({ ...selections, isSetup: true });
  };

  if (consent.isSetup) return null;

  if (customize) {
    return (
      <PopupWrapper consent={consent}>
        <Heading mb="-16px" fontSize="16px">
          {t('common:cookies.title')}
        </Heading>
        <Text color="white" fontSize="16px" maxW="500px">
          {t('common:cookies.description')}
        </Text>

        <VStack spacing="16px" alignItems="flex-start">
          {Object.keys(OPTIONS).map((key) => (
            <HStack key={key}>
              <Checkbox
                alignSelf="baseline"
                key={key}
                isChecked={selections[key as keyof CookieConsent] ?? false}
                isDisabled={OPTIONS[key].disabled}
                disabled={OPTIONS[key].disabled}
                onChange={(e) =>
                  setSelections((currentSelection) => ({
                    ...currentSelection,
                    [key]: e.target.checked,
                  }))
                }
                variant="darkTheme"
              />
              <Text color="white" fontSize="16px">
                {t(`common:cookies.${key}`)}
              </Text>
            </HStack>
          ))}
        </VStack>

        <HStack>
          <Button onClick={() => onAcceptSelections()}>Accept</Button>
          <Button
            variant="ghost"
            onClick={() =>
              onUpdate({
                isSetup: true,
                essential: true,
                analytics: false,
                marketing: false,
                functional: false,
              })
            }
            color="white"
          >
            {t('common:cookies.decline')}
          </Button>
        </HStack>
      </PopupWrapper>
    );
  }

  return (
    <PopupWrapper consent={consent}>
      <Heading mb="-16px" fontSize="16px">
        {t('common:cookies.title')}
      </Heading>
      <Text color="white" fontSize="16px" maxW="500px">
        {t('common:cookies.description')}
      </Text>
      <HStack textTransform="uppercase">
        <Button
          onClick={() =>
            onUpdate({
              isSetup: true,
              analytics: true,
              marketing: true,
              essential: true,
              functional: true,
            })
          }
        >
          {t('common:cookies.accept')}
        </Button>
        <Button
          onClick={() => {
            setCustomize(true);
            setSelections(consent);
          }}
          background="rgba(255, 255, 255, 0.16)"
        >
          {t('common:cookies.customize')}
        </Button>
        <Button
          variant="ghost"
          color="white"
          onClick={() =>
            onUpdate({
              isSetup: true,
              essential: true,
              analytics: false,
              marketing: false,
              functional: false,
            })
          }
        >
          {t('common:cookies.decline')}
        </Button>
      </HStack>
    </PopupWrapper>
  );
};

export default CookieConsentModal;
