export const dateToString = (d: Date) => {
  return d.toISOString().slice(0, 10);
};

export const stringToDate = (s: string) => {
  try {
    return new Date(s);
  } catch (e) {
    return new Date();
  }
};
