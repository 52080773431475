import { BellIcon } from '@chakra-ui/icons';
import {
  Avatar,
  AvatarBadge,
  HStack,
  VStack,
  StackProps,
  Text,
  IconButton,
  Button,
  Divider,
  useDisclosure,
} from '@chakra-ui/react';
import { createId } from 'components/question/QuestionListMenu';
import DefaultDrawer from 'components/ui/Drawer';
import { TextWithMentions } from 'components/ui/forms/InputWithMentions/InputWithMentions';
import { ButtonLink } from 'components/ui/Links';
import { Notification, useCurrentUser, useNotifications, usePendingInvitations } from 'models';
import React, { useEffect, useState } from 'react';
import UserAvatar from './UserAvatar';
import { Trans, useTranslation } from 'utils/i18n';
import { formatDateTime } from 'utils/i18n/formatters';
import { CompanyInvitationsModal } from 'components/CompanyInvitations';
import { useRouter } from 'next/router';
import { setTimeoutInHook } from 'utils/setTimeoutInHook';
import { useUserSetting } from '../../models/general';
import { LightBulbIcon } from 'icons';

const LATEST_RELEASE_NOTIFICATION = 'new_release_message_july';

export const NotificationItem = ({
  notification,
  ...rest
}: {
  notification: Notification;
} & StackProps) => {
  const { comment } = notification;
  const {
    thread: { answer },
  } = comment;
  const businessUnit = answer?.activityReport?.bAssessment?.businessUnit;
  const cAssessmentId = answer?.activityReport?.bAssessment?.cAssessmentId;
  const activityReference = answer?.activityReport?.activity.reference;
  const answerHash = activityReference
    ? createId(activityReference, notification.comment.thread.answer?.question.uniqueId)
    : '';
  return (
    <HStack align="start" width="100%" {...rest}>
      <UserAvatar user={comment.author} size="sm" />
      <VStack align="start" width="100%">
        <Text>
          <Trans i18nKey="common:notifications.commented">
            {{ user: `${comment.author.firstName} ${comment.author.lastName}` }} commented on a
            thread you&apos;re following in{' '}
            <ButtonLink
              href={`/assessments/${cAssessmentId}/screening?businessUnit=${businessUnit?.id}#${answerHash}.comment`}
              shallow
            >
              <>{{ businessUnitName: businessUnit?.name }}</>
            </ButtonLink>
          </Trans>
        </Text>
        <VStack bg="#F5F5F5" align="start" width="100%" p="md" borderRadius="md">
          <Text fontSize="sm" color="secondary.dark">
            {formatDateTime(comment.createdAt)}
          </Text>
          <TextWithMentions value={comment.data} />
        </VStack>
      </VStack>
    </HStack>
  );
};

export const ReleaseAlertNotification = () => {
  const [, isClosed] = useUserSetting(LATEST_RELEASE_NOTIFICATION, false);
  return (
    <HStack align="start" width="100%">
      <VStack align="start" width="100%">
        <Text>
          <LightBulbIcon color="primary.main" fontSize="24px" />
          <b>NEW Celsia release!</b> Read more about what&apos;s new{' '}
          <ButtonLink
            onClick={() => isClosed(true)}
            target="_blank"
            href="https://celsia.notion.site/July-1st-Celsia-Release-c4b5ccc4aa784acaaae53eb13d3aaf13"
          >
            {' '}
            here
          </ButtonLink>
        </Text>
      </VStack>
      <VStack>
        <Button onClick={() => isClosed(true)}>Dismiss</Button>
      </VStack>
    </HStack>
  );
};

export default function NotificationsMenu() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    notifications,
    readNotifications,
    notificationCount: commentNotificationsCount,
  } = useNotifications();
  const { query } = useRouter();
  const { t } = useTranslation();
  const user = useCurrentUser();
  const pending = usePendingInvitations();
  const [isClosed] = useUserSetting(LATEST_RELEASE_NOTIFICATION, false);
  const invitations = pending ?? user?.invitations ?? [];
  const notificationCount = commentNotificationsCount + invitations.length + (isClosed ? 0 : 1);
  useEffect(() => {
    const unreadNotificationIds =
      notifications?.filter((n) => !n.isRead).map((n) => n.id) || ([] as string[]);

    if (isOpen && unreadNotificationIds.length) {
      return setTimeoutInHook(() => readNotifications(unreadNotificationIds), 2000);
    }
  }, [isOpen, notifications]);
  const [showInvitation, setShowInvitation] = useState<string>(
    (query.invitationId as string) ?? ''
  );
  return (
    <>
      <DefaultDrawer
        onClose={onClose}
        isOpen={isOpen}
        size="lg"
        header={t('common:notifications.notifications_count', { count: notificationCount })}
        returnFocusOnClose={false}
      >
        <VStack
          spacing="md"
          onClick={(e) => {
            if (['A', 'BUTTON'].includes((e.target as HTMLElement).tagName)) onClose();
          }}
        >
          {!isClosed && (
            <>
              <ReleaseAlertNotification />
              <Divider />
            </>
          )}
          {invitations.map((invitation) => (
            <HStack key={invitation.id} width="100%" justifyContent="space-between">
              <Text>{t('common:invitations.invitationNotification')}</Text>
              <Button onClick={() => setShowInvitation(invitation.id)}>
                {t('common:button.show')}
              </Button>
            </HStack>
          ))}
          {!!invitations.length && <Divider />}
          {notifications.map((notification) => (
            <NotificationItem key={notification.id} notification={notification} />
          ))}
        </VStack>
      </DefaultDrawer>
      <CompanyInvitationsModal
        invitationId={showInvitation}
        onClose={() => setShowInvitation('')}
        user={user}
      />
      <IconButton
        variant="ghost"
        height="40px"
        aria-label={t('common:notifications.notifications')}
        onClick={onOpen}
        icon={
          <Avatar
            cursor="pointer"
            icon={<BellIcon transform="rotate(45deg)" boxSize="20px" />}
            bg="primary.light"
            color="primary.main"
            boxSize="32px"
          >
            {notificationCount ? (
              <AvatarBadge
                boxSize="20px"
                bg="notification"
                color="white"
                fontSize="10px"
                borderWidth="1px"
                animation={notificationCount > 0 ? 'sonar 2s 10' : undefined}
                // animation={notificationCount > 0 ? 'pulse 3s infinite' : undefined}
              >
                {notificationCount > 99 ? '+99' : notificationCount.toString()}
              </AvatarBadge>
            ) : undefined}
          </Avatar>
        }
      />
    </>
  );
}
