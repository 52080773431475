import { ComponentWithAs, IconProps } from '@chakra-ui/react';
import {
  AdaptationIcon,
  EcosystemIcon,
  FactoryIcon,
  FishIcon,
  RecycleIcon,
  SafeguardsIcon,
  PlanetEarthIcon,
  IconType,
} from 'icons';
import { mapValues } from 'lodash';
import { tenum, tnoop } from 'utils/i18n';
const OBJECTIVE_ICONS = {
  [tenum('assessment:objectives.mitigation')]: PlanetEarthIcon,
  [tenum('assessment:objectives.adaptation')]: AdaptationIcon,
  [tenum('assessment:objectives.water')]: FishIcon,
  [tenum('assessment:objectives.circular')]: RecycleIcon,
  [tenum('assessment:objectives.pollution')]: FactoryIcon,
  [tenum('assessment:objectives.biodiversity')]: EcosystemIcon,
  [tenum('assessment:objectives.social')]: SafeguardsIcon,
} as { [x in ObjectiveId]: IconType };

export type ObjectiveType = string;
export type ObjectiveId =
  | 'adaptation'
  | 'biodiversity'
  | 'circular'
  | 'general'
  | 'mitigation'
  | 'pollution'
  | 'social'
  | 'water';

export type ObjectiveItem = {
  name: ObjectiveType;
  icon: ComponentWithAs<'svg', IconProps>;
  id: ObjectiveId;
};
export const OBJECTIVES_IDS_LIST: Array<ObjectiveId> = Object.keys(
  OBJECTIVE_ICONS
) as ObjectiveId[];
export const CONTRIBUTING_OBJECTIVES_LIST: Array<ObjectiveId> = OBJECTIVES_IDS_LIST.filter(
  (id) => id != 'social'
);
export const OBJECTIVE_ITEMS = mapValues(OBJECTIVE_ICONS, (icon, id) => ({
  name: 'assessment:objectives.' + id,
  id: id as ObjectiveId,
  icon,
}));

export const OBJECTIVE_ITEMS_LIST: Array<ObjectiveItem> = Object.values(OBJECTIVE_ITEMS);

export const ObjectiveStatus = {
  SUBSTANTIAL_CONTRIBUTION: tnoop('assessment:objectiveStatus.SC'),
  DO_NO_SIGNIFICANT_HARM: tnoop('assessment:objectiveStatus.DNSH'),
  DOES_HARM: tnoop('assessment:objectiveStatus.DH'),
};

export type EmptyMessageObject = { [key: typeof PERFORMANCE_SECITON_TYPES[number]]: string };

export const PERFORMANCE_SECITON_TYPES = ['contributes', 'doesHarm', 'neutral'];

export const EMPTY_COMPANY_OBJECTIVE_MESSGAE: EmptyMessageObject = {
  contributes: tnoop('assessment:companyObjective.empty.contributes'),
  doesHarm: tnoop('assessment:companyObjective.empty.doesHarm'),
  neutral: tnoop('assessment:companyObjective.empty.neutral'),
};

export const EMPTY_ACTIVITY_OBJECTIVE_MESSGAE: EmptyMessageObject = {
  contributes: tnoop('assessment:activityObjective.empty.contributes'),
  doesHarm: tnoop('assessment:activityObjective.empty.doesHarm'),
  neutral: tnoop('assessment:activityObjective.empty.neutral'),
};

export const EMPTY_BUSINESS_UNIT_OBJECTIVE_MESSGAE: EmptyMessageObject = {
  contributes: tnoop('assessment:businessUnitObjective.empty.contributes'),
  doesHarm: tnoop('assessment:businessUnitObjective.empty.doesHarm'),
  neutral: tnoop('assessment:businessUnitObjective.empty.neutral'),
};

export type PerformanceSection = typeof PERFORMANCE_SECITON_TYPES[number];

export const OBJECTIVE_INDEX_MAP: Record<ObjectiveId | ObjectiveType, number> = {
  mitigation: 0,
  adaptation: 1,
  water: 2,
  circular: 3,
  pollution: 4,
  biodiversity: 5,
  general: 6,
  social: 7,
};

export const getObjectiveIndex = (key: ObjectiveType) => OBJECTIVE_INDEX_MAP[key] ?? 100;
type ObjectiveWithKey = { key: ObjectiveId };
export const sortObjectives = (a: ObjectiveWithKey, b: ObjectiveWithKey) =>
  getObjectiveIndex(a.key) - getObjectiveIndex(b.key);
