import { useRouter } from 'next/router';
import { useCurrentUser } from 'models';
import { useLogout } from 'models/localState';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from '../utils/mixpanel';

const useLoggedInUser = () => {
  const { push } = useRouter();
  const user = useCurrentUser();
  const logout = useLogout();
  const handleLogOut = () => {
    push('/login').then(() => {
      mixpanel.track(TRACKING_EVENTS.LOGOUT);
      logout();
    });
  };

  return { user, handleLogOut };
};

export default useLoggedInUser;
