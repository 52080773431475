import { Box } from '@chakra-ui/react';
import Image from 'next/image';

interface LogoProps {
  size?: string;
  textColor?: 'white' | 'black';
}

const Logo = ({ size = '48px' }: LogoProps) => (
  <>
    <Box height={`${Number(size.split('px')[0]) / 1.5}px`} width={size} position="relative">
      <Image src={`/logo.svg`} layout="fill" objectFit="contain" />
    </Box>
  </>
);

export default Logo;
