import { useCallback, useRef } from 'react';
import { isArray } from 'lodash';
import { useRouter } from 'next/router';
import { Tuple } from 'models';
import { useLatest } from 'react-use';
import { useSearchParams } from 'react-router-dom';

export function useUrlQueryVar(varName: string) {
  const router = useRouter();
  const queryRef = useRef(router);
  queryRef.current = router;
  const {
    query: { [varName]: value },
  } = router || { query: {} };
  const setVar = useCallback(
    (newVal: string | null | undefined) => {
      if (queryRef.current == null) return Promise.resolve();
      const {
        query: { [varName]: oldVal, ...query },
        replace,
      } = queryRef.current;
      if (oldVal != newVal)
        return replace(
          {
            query: newVal ? { ...query, [varName]: newVal } : query,
          },
          undefined,
          {
            scroll: false,
          }
        );
    },
    [queryRef]
  );
  return Tuple(isArray(value) ? value[0] : value, setVar);
}
export function useRouterUrlQueryVar(varName: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsRef = useLatest(searchParams);
  const value = searchParams.get(varName);
  const setVar = useCallback((newVal: string | null | undefined) => {
    if (newVal == null) paramsRef.current.delete(varName);
    else paramsRef.current.set(varName, newVal);
    setSearchParams(paramsRef.current);
  }, []);
  return Tuple(isArray(value) ? value[0] : value, setVar);
}
