import { CloseIcon, HamburgerIcon, IconProps, SettingsIcon } from 'icons';
import {
  Box,
  Center,
  Menu,
  Flex,
  SkeletonCircle,
  MenuButton,
  IconButton,
  Stack,
  Divider,
  useTheme,
  useBreakpointValue,
  Spacer,
} from '@chakra-ui/react';
import LogoSvg from '../../../public/celsia_logo_short.svg';

import { Action, ActionList } from 'components/ui/ActionMenu';
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { User } from 'models';
import { Link } from '../ui/Links';
import UserAvatar from './UserAvatar';
import CompanySwitcher from './CompanySwitcher';
import KnowledgeBase from './KnowledgeBase';
import TrialIndicator from './TrialIndicator';
import { useMeasure } from 'react-use';

import Sticky from 'react-stickynode';
import NotificationsMenu from './NotificationsMenu';
import { useTranslation } from 'utils/i18n';
import { useRouter } from 'next/router';
export type TopMenuItem = {
  title: string;
  path: string;
  disabled?: boolean | string;
  icon?: React.ReactElement;
  count?: number;
  className?: string;
};
export type TopMenuProps = React.PropsWithChildren<{
  hideUser?: boolean;
  showLogo?: boolean;
  user: User | undefined;
  actions: Action[];
}>;
const BREAKPOINT = 'lg';
const mq = (small: any, large: any) => ({ base: small, [BREAKPOINT]: large });

const AvatarMenu = React.memo(({ user, actions }: Pick<TopMenuProps, 'user' | 'actions'>) => (
  <Box>
    <Menu>
      <MenuButton
        as={IconButton}
        variant="ghost"
        height="40px"
        icon={
          <SkeletonCircle isLoaded={user != null} boxSize="32px">
            <UserAvatar size="sm" user={user ?? {}} />
          </SkeletonCircle>
        }
      />
      <ActionList actions={actions} />
    </Menu>
  </Box>
));
AvatarMenu.displayName = 'AvatarMenu';

export const SettingsCircleIcon = React.forwardRef<SVGSVGElement>(
  ({ css, ...props }: IconProps, ref) => (
    <SettingsIcon
      bg="primary.light"
      color="primary.main"
      borderRadius="full"
      boxSize="32px"
      p="6px"
      ref={ref}
      {...props}
    />
  )
);
SettingsCircleIcon.displayName = 'SettingsCircleIcon';
export const TOP_MENU_MARGIN = 16;
const stickyMenuContext = {
  currentContentOffset: 0,
  initialContentOffset: 0,
  hideOnNextLevel: 0,
  anchorOffset: 0,
  topOffset: 0,
  setOffset: (_topOffset: number, _stickyOffset: number) => {},
};
export type StickyMenuContextType = typeof stickyMenuContext;
export const StickyMenuContext = React.createContext<StickyMenuContextType>(stickyMenuContext);

export function TopMenu({
  user,
  actions,
  children,
  hideUser = false,
  showLogo = false,
}: TopMenuProps) {
  const [displayMenu, setDisplayMenu] = useState('none');
  const { route } = useRouter();
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const stickyRef = useRef<Sticky>(null);
  const theme = useTheme();
  const [offsets, setOffsets] = useState({ anchorOffset: 0, topOffset: 0 });
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const context = useMemo<StickyMenuContextType>(
    () => ({
      ...offsets,
      initialContentOffset: height + TOP_MENU_MARGIN,
      currentContentOffset: height + offsets.topOffset + TOP_MENU_MARGIN + offsets.anchorOffset,
      hideOnNextLevel: 0,
      setOffset: (topOffset: number, stickyOffset: number) => {
        if (stickyOffset != 0) stickyOffset -= (stickyRef.current?.state as any).y ?? 0;
        topOffset -= stickyOffset;
        if (topOffset != offsets.topOffset || stickyOffset != offsets.anchorOffset)
          setOffsets({ topOffset, anchorOffset: stickyOffset });
      },
    }),
    [offsets, height]
  );

  useEffect(() => {
    // Reset top menu on route changes
    setOffsets({ anchorOffset: 0, topOffset: 0 });
  }, [route]);

  const enabled = context.topOffset !== 0 || true;
  return (
    <StickyMenuContext.Provider value={context}>
      <Box width="100%" id="top-menu" mb={TOP_MENU_MARGIN + 'px'} flexGrow={1}>
        {/* @ts-ignore */}
        <Sticky
          top={context.topOffset}
          innerZ={theme.zIndices.sticky}
          enabled={enabled}
          ref={stickyRef}
        >
          <Box
            width="100%"
            className="top_menu"
            zIndex="sticky"
            backgroundColor="white"
            shadow="0px 0px 16px rgba(0, 0, 0, 0.08)"
            transform={`translateY(${context.anchorOffset}px)`}
            ref={ref}
          >
            <Stack
              direction={mq('column', 'row')}
              px={mq('sm', 'lg')}
              py="md"
              borderBottom="1px solid rgba(176, 176, 176, 0.16)"
            >
              <Flex
                flexShrink={1}
                alignItems="center"
                justifyContent="space-between"
                minWidth="128px"
                minH="40px"
                flexGrow={mq(1, 0)}
              >
                {showLogo && (
                  <Link
                    href="/"
                    color="black"
                    textAlign="center"
                    _hover={{ color: '#3BDBFF29' }}
                    marginRight="md"
                  >
                    <Center as="span" width="100%" height="100%">
                      <LogoSvg />
                    </Center>
                  </Link>
                )}
                <IconButton
                  key={displayMenu}
                  aria-label={displayMenu === 'none' ? t('common:openMenu') : t('common:closeMenu')}
                  size="md"
                  icon={displayMenu === 'none' ? <HamburgerIcon /> : <CloseIcon />}
                  variant="ghost"
                  display={mq('block', 'none')}
                  onClick={() => setDisplayMenu(displayMenu === 'none' ? 'flex' : 'none')}
                />
                {user ? <CompanySwitcher user={user} /> : undefined}
              </Flex>
              <Spacer />
              <Flex
                display={mq(displayMenu, 'flex')}
                justifyContent={mq('center', 'end')}
                alignItems="stretch"
                wrap={mq('wrap', 'nowrap')}
                gridGap="md"
              >
                {!!user && <TrialIndicator />}
                <Divider
                  orientation={isMobile ? 'horizontal' : 'vertical'}
                  width={mq('100%', 0)}
                  mx={mq('auto', '16px')}
                />
                {user ? <NotificationsMenu /> : undefined}
                {user ? <KnowledgeBase /> : undefined}
                {!hideUser && <AvatarMenu user={user} actions={actions} />}
              </Flex>
            </Stack>
          </Box>
        </Sticky>
      </Box>
      {children}
    </StickyMenuContext.Provider>
  );
}
