import { Avatar, AvatarProps, Text, HStack, Tooltip } from '@chakra-ui/react';
import { User } from 'models';

export type UserAvatarProps = {
  user: Pick<User, 'firstName' | 'lastName' | 'avatar_url'>;
} & AvatarProps;
export function getDisplayName(user: UserAvatarProps['user']) {
  return `${user.firstName} ${user.lastName}`;
}
export default function UserAvatar({ user, ...props }: UserAvatarProps) {
  const name = getDisplayName(user);
  return (
    <Tooltip label={name}>
      <Avatar name={name} src={user.avatar_url || undefined} {...props} />
    </Tooltip>
  );
}
export function AvatarWithName({ user, ...props }: UserAvatarProps) {
  return (
    <HStack>
      <UserAvatar user={user} {...props} />
      <Text>{`${user?.firstName} ${user?.lastName}`}</Text>
    </HStack>
  );
}
