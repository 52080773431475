/* 090dedc08fd4932db642b53376ce2437f0b1cd0b
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HArticleFieldsFragment = { id: any, body: string, title: string };

export type HTagFieldsFragment = { id: any, description: string, name: string };

export type HArticleByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HArticleByIdQuery = { article?: { id: any, body: string, title: string } | null | undefined };

export type HTagByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HTagByIdQuery = { tag?: { id: any, description: string, name: string } | null | undefined };

export type HArticlesByTagIdQueryVariables = Types.Exact<{
  tagId: Types.Scalars['uuid'];
}>;


export type HArticlesByTagIdQuery = { articles: Array<{ article_id: any }> };

export type HTagsByArticleIdQueryVariables = Types.Exact<{
  articleId: Types.Scalars['uuid'];
}>;


export type HTagsByArticleIdQuery = { tags: Array<{ tag_id: any }> };

export type HArticlesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HArticlesQuery = { Article: Array<{ id: any, body: string, title: string }> };

export type HTagsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HTagsQuery = { Tag: Array<{ id: any, description: string, name: string }> };

export type HArticleBodySearchQueryVariables = Types.Exact<{
  q: Types.Scalars['String'];
}>;


export type HArticleBodySearchQuery = { Article: Array<{ id: any, body: string, title: string }> };

export type HArticleTitleSearchQueryVariables = Types.Exact<{
  q: Types.Scalars['String'];
}>;


export type HArticleTitleSearchQuery = { Article: Array<{ id: any, body: string, title: string }> };

export type HTagsWithArticlesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HTagsWithArticlesQuery = { Tag: Array<{ id: any, name: string, description: string, articles: Array<{ Article: { id: any, body: string, title: string } }> }> };

export const ArticleFieldsFragmentDoc = gql`
    fragment ArticleFields on Article {
  id
  body
  title
}
    `;
export const TagFieldsFragmentDoc = gql`
    fragment TagFields on Tag {
  id
  description
  name
}
    `;
export const ArticleByIdDocument = gql`
    query ArticleById($id: uuid!) {
  article: Article_by_pk(id: $id) {
    ...ArticleFields
  }
}
    ${ArticleFieldsFragmentDoc}`;

/**
 * __useArticleByIdQuery__
 *
 * To run a query within a React component, call `useArticleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArticleByIdQuery(baseOptions: Apollo.QueryHookOptions<HArticleByIdQuery, HArticleByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HArticleByIdQuery, HArticleByIdQueryVariables>(ArticleByIdDocument, options);
      }
export function useArticleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HArticleByIdQuery, HArticleByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HArticleByIdQuery, HArticleByIdQueryVariables>(ArticleByIdDocument, options);
        }
export type ArticleByIdQueryHookResult = ReturnType<typeof useArticleByIdQuery>;
export type ArticleByIdLazyQueryHookResult = ReturnType<typeof useArticleByIdLazyQuery>;
export type ArticleByIdQueryResult = Apollo.QueryResult<HArticleByIdQuery, HArticleByIdQueryVariables>;
export const TagByIdDocument = gql`
    query TagById($id: uuid!) {
  tag: Tag_by_pk(id: $id) {
    ...TagFields
  }
}
    ${TagFieldsFragmentDoc}`;

/**
 * __useTagByIdQuery__
 *
 * To run a query within a React component, call `useTagByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagByIdQuery(baseOptions: Apollo.QueryHookOptions<HTagByIdQuery, HTagByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HTagByIdQuery, HTagByIdQueryVariables>(TagByIdDocument, options);
      }
export function useTagByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HTagByIdQuery, HTagByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HTagByIdQuery, HTagByIdQueryVariables>(TagByIdDocument, options);
        }
export type TagByIdQueryHookResult = ReturnType<typeof useTagByIdQuery>;
export type TagByIdLazyQueryHookResult = ReturnType<typeof useTagByIdLazyQuery>;
export type TagByIdQueryResult = Apollo.QueryResult<HTagByIdQuery, HTagByIdQueryVariables>;
export const ArticlesByTagIdDocument = gql`
    query ArticlesByTagId($tagId: uuid!) {
  articles: Tag_articles(where: {tag_id: {_eq: $tagId}}) {
    article_id
  }
}
    `;

/**
 * __useArticlesByTagIdQuery__
 *
 * To run a query within a React component, call `useArticlesByTagIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesByTagIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesByTagIdQuery({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useArticlesByTagIdQuery(baseOptions: Apollo.QueryHookOptions<HArticlesByTagIdQuery, HArticlesByTagIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HArticlesByTagIdQuery, HArticlesByTagIdQueryVariables>(ArticlesByTagIdDocument, options);
      }
export function useArticlesByTagIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HArticlesByTagIdQuery, HArticlesByTagIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HArticlesByTagIdQuery, HArticlesByTagIdQueryVariables>(ArticlesByTagIdDocument, options);
        }
export type ArticlesByTagIdQueryHookResult = ReturnType<typeof useArticlesByTagIdQuery>;
export type ArticlesByTagIdLazyQueryHookResult = ReturnType<typeof useArticlesByTagIdLazyQuery>;
export type ArticlesByTagIdQueryResult = Apollo.QueryResult<HArticlesByTagIdQuery, HArticlesByTagIdQueryVariables>;
export const TagsByArticleIdDocument = gql`
    query TagsByArticleId($articleId: uuid!) {
  tags: Tag_articles(where: {article_id: {_eq: $articleId}}) {
    tag_id
  }
}
    `;

/**
 * __useTagsByArticleIdQuery__
 *
 * To run a query within a React component, call `useTagsByArticleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsByArticleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsByArticleIdQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useTagsByArticleIdQuery(baseOptions: Apollo.QueryHookOptions<HTagsByArticleIdQuery, HTagsByArticleIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HTagsByArticleIdQuery, HTagsByArticleIdQueryVariables>(TagsByArticleIdDocument, options);
      }
export function useTagsByArticleIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HTagsByArticleIdQuery, HTagsByArticleIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HTagsByArticleIdQuery, HTagsByArticleIdQueryVariables>(TagsByArticleIdDocument, options);
        }
export type TagsByArticleIdQueryHookResult = ReturnType<typeof useTagsByArticleIdQuery>;
export type TagsByArticleIdLazyQueryHookResult = ReturnType<typeof useTagsByArticleIdLazyQuery>;
export type TagsByArticleIdQueryResult = Apollo.QueryResult<HTagsByArticleIdQuery, HTagsByArticleIdQueryVariables>;
export const ArticlesDocument = gql`
    query Articles {
  Article {
    ...ArticleFields
  }
}
    ${ArticleFieldsFragmentDoc}`;

/**
 * __useArticlesQuery__
 *
 * To run a query within a React component, call `useArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useArticlesQuery(baseOptions?: Apollo.QueryHookOptions<HArticlesQuery, HArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HArticlesQuery, HArticlesQueryVariables>(ArticlesDocument, options);
      }
export function useArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HArticlesQuery, HArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HArticlesQuery, HArticlesQueryVariables>(ArticlesDocument, options);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<HArticlesQuery, HArticlesQueryVariables>;
export const TagsDocument = gql`
    query Tags {
  Tag {
    ...TagFields
  }
}
    ${TagFieldsFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<HTagsQuery, HTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HTagsQuery, HTagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HTagsQuery, HTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HTagsQuery, HTagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<HTagsQuery, HTagsQueryVariables>;
export const ArticleBodySearchDocument = gql`
    query ArticleBodySearch($q: String!) {
  Article(where: {body: {_ilike: $q}}) {
    ...ArticleFields
  }
}
    ${ArticleFieldsFragmentDoc}`;

/**
 * __useArticleBodySearchQuery__
 *
 * To run a query within a React component, call `useArticleBodySearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleBodySearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleBodySearchQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useArticleBodySearchQuery(baseOptions: Apollo.QueryHookOptions<HArticleBodySearchQuery, HArticleBodySearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HArticleBodySearchQuery, HArticleBodySearchQueryVariables>(ArticleBodySearchDocument, options);
      }
export function useArticleBodySearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HArticleBodySearchQuery, HArticleBodySearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HArticleBodySearchQuery, HArticleBodySearchQueryVariables>(ArticleBodySearchDocument, options);
        }
export type ArticleBodySearchQueryHookResult = ReturnType<typeof useArticleBodySearchQuery>;
export type ArticleBodySearchLazyQueryHookResult = ReturnType<typeof useArticleBodySearchLazyQuery>;
export type ArticleBodySearchQueryResult = Apollo.QueryResult<HArticleBodySearchQuery, HArticleBodySearchQueryVariables>;
export const ArticleTitleSearchDocument = gql`
    query ArticleTitleSearch($q: String!) {
  Article(where: {title: {_ilike: $q}}) {
    ...ArticleFields
  }
}
    ${ArticleFieldsFragmentDoc}`;

/**
 * __useArticleTitleSearchQuery__
 *
 * To run a query within a React component, call `useArticleTitleSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleTitleSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleTitleSearchQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useArticleTitleSearchQuery(baseOptions: Apollo.QueryHookOptions<HArticleTitleSearchQuery, HArticleTitleSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HArticleTitleSearchQuery, HArticleTitleSearchQueryVariables>(ArticleTitleSearchDocument, options);
      }
export function useArticleTitleSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HArticleTitleSearchQuery, HArticleTitleSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HArticleTitleSearchQuery, HArticleTitleSearchQueryVariables>(ArticleTitleSearchDocument, options);
        }
export type ArticleTitleSearchQueryHookResult = ReturnType<typeof useArticleTitleSearchQuery>;
export type ArticleTitleSearchLazyQueryHookResult = ReturnType<typeof useArticleTitleSearchLazyQuery>;
export type ArticleTitleSearchQueryResult = Apollo.QueryResult<HArticleTitleSearchQuery, HArticleTitleSearchQueryVariables>;
export const TagsWithArticlesDocument = gql`
    query TagsWithArticles {
  Tag {
    id
    name
    description
    articles: Tag_articles {
      Article {
        id
        body
        title
      }
    }
  }
}
    `;

/**
 * __useTagsWithArticlesQuery__
 *
 * To run a query within a React component, call `useTagsWithArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsWithArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsWithArticlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsWithArticlesQuery(baseOptions?: Apollo.QueryHookOptions<HTagsWithArticlesQuery, HTagsWithArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HTagsWithArticlesQuery, HTagsWithArticlesQueryVariables>(TagsWithArticlesDocument, options);
      }
export function useTagsWithArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HTagsWithArticlesQuery, HTagsWithArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HTagsWithArticlesQuery, HTagsWithArticlesQueryVariables>(TagsWithArticlesDocument, options);
        }
export type TagsWithArticlesQueryHookResult = ReturnType<typeof useTagsWithArticlesQuery>;
export type TagsWithArticlesLazyQueryHookResult = ReturnType<typeof useTagsWithArticlesLazyQuery>;
export type TagsWithArticlesQueryResult = Apollo.QueryResult<HTagsWithArticlesQuery, HTagsWithArticlesQueryVariables>;