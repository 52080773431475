import {
  Box,
  Tooltip,
  VStack,
  Text,
  Center,
  Flex,
  Divider,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import LogoSvg from '../../../public/celsia_logo_short.svg';
import { tenum, TFunction, useTranslation } from 'utils/i18n';
import {
  AssessmentsModuleIcon,
  DriveIcon,
  IconType,
  BusinessUnitsModuleIcon,
  TeamMembersIcon,
} from 'icons';
import { SettingsIcon } from '@chakra-ui/icons';
import { Link } from 'components/ui/Links';
import StickyScroll from 'components/ui/StickyScroll';
import { useSimpleCompany } from 'hooks/useCompany';

type ModuleConfig = {
  Icon: IconType;
  path: string;
  disabledReason?: string;
  hideInPortfolio?: boolean;
  hideInCompany?: boolean;
};
const MODULES_TMP = {
  [tenum('common:module.assessments')]: {
    Icon: AssessmentsModuleIcon,
    path: '/assessments',
    hideInPortfolio: true,
  },
  [tenum('common:module.businessUnits')]: {
    Icon: BusinessUnitsModuleIcon,
    path: '/business-units',
    hideInPortfolio: true,
  },
  [tenum('common:module.portfolio')]: {
    Icon: AssessmentsModuleIcon,
    path: '/portfolios',
    hideInCompany: true,
  },
  // TODO: DEV-313 - Hiding modules from menu awaiting business deciscion
  // [tenum('common:module.SocialModule')]: {
  //   Icon: SocialModuleIcon,
  //   path: '/social',
  //   disabledReason: tnoop('common:comingSoon'),
  // },
  // [tenum('common:module.climateRiscAssessment')]: {
  //   Icon: ClimatRiscAssessmentIcon,
  //   path: '/climateRisk',
  //   disabledReason: tnoop('common:comingSoon'),
  // },
  [tenum('common:module.drive')]: { Icon: DriveIcon, path: '/drive' },
  [tenum('common:module.teamMembers')]: {
    Icon: TeamMembersIcon,
    path: '/team-members',
  },
  [tenum('common:module.settings')]: {
    Icon: SettingsIcon,
    path: '/settings/company-details',
  },
};

export type ModuleKeys = keyof typeof MODULES_TMP;
export type ModuleType = ModuleKeys | null;
const MODULES = MODULES_TMP as Record<ModuleKeys, ModuleConfig>;
export const getModuleName = (t: TFunction, name: ModuleType) =>
  name ? t(`common:module.${name}`) : '';

const mq = (small: any, large: any) => ({ base: small, lg: large });

const ACTIVE_ITEM_COLOR = 'rgba(255, 255, 255, 0.1)';
const SIDE_MENU_COLOR = '#1C1C57';

export function ModulesMenu({
  currentModule,
  children,
}: React.PropsWithChildren<{ currentModule: ModuleType | null }>) {
  const { t } = useTranslation();
  const { isPortfolioOwner } = useSimpleCompany() ?? { isPortfolioOwner: false };
  const isCurrent = (item: ModuleConfig) => currentModule && MODULES[currentModule] == item;
  const isMobile = useBreakpointValue(mq(true, false));
  const isTablet = useBreakpointValue({ base: false, md: true, lg: false });
  return (
    <Flex
      alignItems={mq('auto', 'stretch')}
      minHeight={mq('auto', '100vh')}
      direction={mq('column', 'row')}
    >
      <Box
        width={mq('100%', '126px')}
        display={!currentModule ? 'none' : undefined}
        bg={SIDE_MENU_COLOR}
        overflow="hidden"
        flexShrink={0}
      >
        <StickyScroll isEnabled={!isMobile} key={currentModule}>
          <Flex
            bg={SIDE_MENU_COLOR}
            top="0"
            color="white"
            minHeight="auto"
            alignItems="stretch"
            flexDirection={mq('row', 'column')}
          >
            <Link
              href="/"
              color="white"
              textAlign="center"
              p="25px"
              _hover={{ bg: ACTIVE_ITEM_COLOR }}
            >
              <Center as="span" width="100%" height="100%">
                <LogoSvg />
              </Center>
            </Link>
            <Box height="64px" width="16px"></Box>
            <Flex gridGap={mq(0, '8px')} direction={mq('row', 'column')} wrap="wrap" flexGrow={2}>
              {Object.entries(MODULES)
                .filter(([_name, item]) => {
                  if (isPortfolioOwner) {
                    return !item.hideInPortfolio;
                  }
                  return !item.hideInCompany;
                })
                .map(([name, item]) => (
                  <React.Fragment key={name}>
                    {name == 'drive' &&
                      (isTablet || isMobile ? (
                        <Center py="25px">
                          <Divider borderColor="#4D4D4D" orientation="vertical" mx="16px" />
                        </Center>
                      ) : (
                        <Center px="25px" width="100%">
                          <Divider borderColor="#4D4D4D" orientation="horizontal" my="64px" />
                        </Center>
                      ))}
                    <Tooltip
                      key={name}
                      label={
                        [
                          isMobile ? getModuleName(t, name as ModuleType) : null,
                          item.disabledReason ? t(item.disabledReason) : null,
                        ]
                          .filter(Boolean)
                          .join(' - ') || undefined
                      }
                      placement={isMobile ? 'bottom' : 'right'}
                    >
                      <Link
                        href={item.path}
                        disabled={!!item.disabledReason}
                        textAlign="center"
                        color="white"
                        bg={isCurrent(item) ? ACTIVE_ITEM_COLOR : 'none'}
                        width={mq('auto', '100%')}
                        wordBreak="break-word"
                        _hover={
                          !item.disabledReason
                            ? {
                                textDecoration: 'none',
                                bg: ACTIVE_ITEM_COLOR,
                              }
                            : { cursor: 'default' }
                        }
                        _focus={{ bg: ACTIVE_ITEM_COLOR }}
                      >
                        <VStack
                          as="span"
                          justifyContent="center"
                          height="100%"
                          minHeight={isMobile ? '' : '98px'}
                          spacing="6px"
                          p="md"
                        >
                          <item.Icon boxSize="24px" />
                          <Text
                            as="span"
                            color="inherit"
                            textTransform="uppercase"
                            fontSize="sm"
                            display={mq('none', 'block')}
                            fontWeight="semibold"
                          >
                            {getModuleName(t, name as ModuleType)}
                          </Text>
                        </VStack>
                      </Link>
                    </Tooltip>
                  </React.Fragment>
                ))}
            </Flex>
          </Flex>
        </StickyScroll>
      </Box>
      <Box flexGrow={mq(0, 1)} flexShrink={1} minW="50vw">
        {children}
      </Box>
    </Flex>
  );
}
