const newColors = {
  // As defined in figma
  primary: '#304FFE',
  primaryLight: '#1F8EF329',
  primaryDark: '#1A237E',
  secondary: '#E0E0E0',
  secondaryLight: '#FAFAFA',
  secondaryDark: '#8F8F8F',
  black: '#000000',
  error: '#F44336',
  errorLight: '#FFEBEE',
  warning: '#FF9800',
  warningLight: '#FFF3E0',
  notice: '#607D8B',
  noticeLight: '#ECEFF1',
  success: '#3BD16F',
  successLight: '#E8F5E9',
  background: '#F6F8FA',
  white: '#FFFFFF',
  hoverBg: '#FCC40014',
  aligned: '#3BD16F',
  detailsBg: 'rgba(5, 104, 253, 0.08)',
  selectedBg: '#F2F7FE',
  detailsColor: '#0568FD',
  paleGreen: '#EFFFF5',
  paleRed: '#FFF5F7',
};

const structureColors = {
  structure: {
    department: '#1C1C57',
    subdepartment: '#4C4C89',
  },
};

const activityStatuses = {
  todo: {
    color: '#EDEAFF',
    fontColor: '#7B61FF',
  },
  soFarCompliant: {
    color: '#CFD8DC',
    fontColor: 'black',
  },
  compliant: {
    color: '#00C853',
    fontColor: 'white',
  },
  notCompliant: {
    color: '#FF3D00',
    fontColor: 'white',
  },
};
const buAvatarColors = {
  bUAvatars: {
    purple: '#7450DD',
    red: '#DD5050',
    teal: '#45B694',
    green: '#17AA52',
    orange: '#DD7A50',
    pink: '#DD50A5',
    violet: '#9D3486',
  },
};

const assessment = {
  assessment: {
    done: {
      background: '#E6FAED',
      color: '#00C853',
    },
    todo: {
      background: '#FFF5DC',
      color: '#ECAA00',
    },
  },
  questions: {
    aligned: '#00C853',
    notAligned: '#F44336',
    inProgress: '#CFD8DC',
    all: 'black',
  },
};

const badges = {
  countBadge: '#F1174B',
  countBadgeLight: 'rgba(176, 176, 176, 0.16)',
};

const onboardingSteps = {
  onboardingSteps: {
    count: {
      color: '#3FCB70',
    },
  },
};
const feedbackIcons = {
  feedbackIcons: {
    warning: {
      background: '#FFECD0',
      color: '#E98C00',
    },
    success: {
      background: newColors.paleGreen,
      color: newColors.aligned,
    },
  },
};

const activityScore = {
  activityScore: {
    aligned: newColors.paleGreen,
    notAligned: newColors.paleRed,
  },
  activityTag: {
    GREEN: {
      color: newColors.success,
      background: newColors.successLight,
    },
    TRANSITIONAL: {
      color: newColors.error,
      background: newColors.errorLight,
    },
    ENABLING: {
      color: newColors.warning,
      background: newColors.warningLight,
    },
  },
};
const incompleteWarning = {
  warningAlert: {
    border: '#F3DE9F',
    background: '#FFFAEB',
  },
  warningTag: {
    background: '#FFE193',
    fontColor: '#6F5209',
  },
};

const objectivePerformanceColors = {
  objectivePerformance: {
    doesHarm: {
      primary: '#FF4874',
      secondary: newColors.paleRed,
      border: '#FF4874',
      statusText: 'white',
      background: '#FFF1F0',
    },
    contributes: {
      primary: newColors.aligned,
      secondary: newColors.paleGreen,
      border: newColors.aligned,
      statusText: 'white',
      background: '#EAFCF0',
    },
    neutral: {
      primary: '#F5F5F5',
      secondary: 'white',
      border: '#8F8F8F',
      statusText: newColors.black,
      background: '#FAFAFA',
    },
  },
};

const completionChartColors = {
  completion: {
    cardBorder: '#F1F1F1',
    cardBackground: '#FAFBFB',
    aligned: {
      background: newColors.aligned,
      border: '#28AD48',
    },
    inProgress: { background: '#96F9B8', border: '#47CC75' },
    notAligned: { background: '#F94874', border: '#F1184B' },
    notEligible: { background: '#F3F5F5', border: '#E1E1E1' },
  },
};

const primaryColorScheme = {
  main: newColors.primary, // Base color
  dark: newColors.primaryDark,
  light: newColors.primaryLight,
  50: `${newColors.primary}20`, // Ghost button light-hover bg
  100: newColors.primaryLight, // Ghost button light-active bg
  300: '#0568FD', // Secondary button font color
  500: newColors.primary, // Solid button light bg
  600: '#2424FF', // Solid button light hover bg
  700: newColors.primaryDark, // Solid button light active bg
};
export default {
  ...activityStatuses,
  ...buAvatarColors,
  ...badges,
  ...assessment,
  ...onboardingSteps,
  ...feedbackIcons,
  ...activityScore,
  ...incompleteWarning,
  ...objectivePerformanceColors,
  ...completionChartColors,
  ...newColors,
  ...structureColors,
  grayscale: {
    900: '#252733',
    800: '#4B506D',
    700: '#9FA2B4',
    600: '#C5C7CD',
    500: '#DFE0EB',
    400: '#EBEDF0',
    300: '#F0F1F7',
    200: '#F7F8FC',
    100: '#FCFDFE',
    50: '#FFFFFF',
  },
  purple: {
    main: '#3F21DB',
    light: 'rgba(63, 33, 219, 0.06)',
    lightest: '#E8EFFF',
  },
  success: {
    main: newColors.success,
    light: newColors.successLight,
    800: newColors.success,
    500: newColors.success,
    200: newColors.successLight,
    100: newColors.successLight,
  },
  warning: {
    main: newColors.warning,
    800: newColors.warning,
    200: newColors.warningLight,
    100: newColors.warningLight,
  },
  error: {
    main: newColors.error,
    light: newColors.errorLight,
    800: newColors.error,
    500: newColors.error,
    400: 'rgba(241, 21, 74, 0.92);',
    200: newColors.errorLight,
    100: newColors.errorLight,
  },
  info: {
    main: newColors.notice,
    800: newColors.notice,
    200: newColors.noticeLight,
  },
  primary: primaryColorScheme,
  blue: primaryColorScheme,
  secondary: {
    main: newColors.secondary,
    dark: newColors.secondaryDark,
    light: newColors.secondaryLight,
    50: '#000000',
    100: newColors.secondaryLight,
    400: '#0568FD14',
    500: newColors.secondary,
    600: '#BFBFBF',
    700: newColors.secondaryDark,
  },
  border: '#DFE0EB',
  disabledBorder: '#E1E1E1',
  brand: newColors.primary,
  notification: '#F1174B',
  danger: '#F43641',
};
