import { cssVar } from '@chakra-ui/theme-tools';
import { mapValues, merge } from 'lodash';
const Input = {
  variants: {
    outline: {
      field: {
        borderColor: 'secondary.dark',
        borderRadius: 'base',
        background: 'white',
        outlineColor: 'transparent',
        color: 'black',
        _hover: {
          borderColor: 'primary.dark',
          shadow: '0 0 0 1px ' + cssVar('chakra-colors-primary-dark').reference,
        },
        _focus: {
          borderColor: 'primary.main',
          shadow: '0 0 0 1px ' + cssVar('chakra-colors-primary-main').reference,
        },
        _invalid: {
          borderColor: 'error.main',
          shadow: 'none',
          _focus: { borderColor: 'error.main' },
        },
        _disabled: {
          background: 'secondary.light',
          borderColor: 'secondary.dark',
        },
      },
      addon: {
        bg: 'transparent',
        color: 'primary.main',
        px: 2,
        borderColor: 'secondary.dark',
      },
    },
  },
  sizes: {
    xl: { field: { fontSize: 22, px: '16px', py: '8px', height: '52px' } }, // Login screen
    lg: { field: { fontSize: 18, px: '16px', py: '8px', height: '46px' } }, // Questions
    md: { field: { fontSize: 16, px: '16px', py: '8px' } }, // Default
    sm: { field: { fontSize: 14, px: '8px', py: '4px', borderRadius: 'sm' } }, // FinancialsRow
  },
  defaultProps: { size: 'lg' },
};
Object.values(Input.sizes).forEach((v) => {
  (v as any).addon = v.field;
});
const Checkbox = {
  variants: {
    darkTheme: {
      control: {
        _checked: {
          _disabled: {
            borderColor: '#373434',
            background: '#373434',
            color: '#8F8F8F',
            _hover: {
              background: '#373434',
            },
          },
        },
      },
    },
  },
  baseStyle: {
    control: {
      borderWidth: '1px',
      borderRadius: 'sm',
      borderColor: 'primary.main',
      _invalid: {
        borderWidth: '2px',
      },
      _hover: {
        backgroundColor: 'primary.50',
      },
      _checked: {
        _hover: {
          backgroundColor: 'primary.dark',
        },
        _disabled: {
          opacity: 1,
          borderColor: 'primary.main',
          background: 'primary.main',
          color: 'white',
          _hover: {
            background: 'primary.main',
          },
        },
        background: 'primary.main',
        borderColor: 'primary.main',
      },
      _disabled: {
        borderColor: 'disabledBorder',
        backgroundColor: 'secondary.light',
        cursor: 'not-allowed',
        _hover: {
          backgroundColor: 'secondary.light',
        },
      },
    },
    label: {
      _disabled: {
        opacity: 1,
      },
    },
  },
  sizes: {
    md: {
      control: {
        w: '24px',
        h: '24px',
      },
      label: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
  },
};

export const FormComponents = {
  Checkbox,
  Radio: merge({}, Checkbox, {
    baseStyle: {
      control: {
        borderRadius: '50%',
        _checked: {
          _hover: { backgroundColor: 'primary.50' },
          backgroundColor: 'transparent',
          _before: { backgroundColor: 'primary.main' },
          _disabled: {
            _hover: { backgroundColor: 'white' },
            backgroundColor: 'transparent',
            _before: { backgroundColor: 'primary.main' },
          },
        },
      },
    },
    sizes: { md: { control: { _checked: { _before: { w: '12px', h: '12px' } } } } },
  }),
  Input,
  NumberInput: Input,
  Textarea: {
    variants: {
      outline: Input.variants.outline.field,
    },
    defaultProps: Input.defaultProps,
    sizes: mapValues(Input.sizes, 'field'),
  },
  FormLabel: {
    baseStyle: {
      color: 'black',
      fontSize: '16px',
      fontWeight: '600',
    },
    variants: {
      select: {
        fontSize: '14px',
        fontWeight: '400',
        m: '0',
      },
    },
    _disabled: {
      color: 'blue',
    },
  },
  FormError: {
    baseStyle: {
      text: {
        fontSize: '14px',
        lineHeight: '150%',
        marginTop: '3px',
      },
    },
  },
  Form: {
    baseStyle: {
      helperText: {
        fontSize: '12px',
        color: 'black',
      },
    },
  },
  Switch: {
    baseStyle: {
      container: {
        display: 'flex',
        alignItems: 'center',
      },
      track: {
        bg: 'error.light',
        _checked: {
          bg: 'success.light',
        },
      },
      thumb: {
        shadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
        _before: {
          content: '" "',
          bg: 'error.main',
          borderRadius: '100%',
          boxSize: '66.66666666666666%',
          display: 'block',
          margin: '16.6666666666666%',
          transitionProperty: cssVar('chakra-transition-property-colors').reference,
          transitionDuration: cssVar('chakra-transition-duration-fast').reference,
        },
        _checked: { _before: { bg: 'success.main' } },
      },
    },
    sizes: {
      md: {
        container: {
          [cssVar('switch-track-width').variable]: '48px',
          [cssVar('switch-track-height').variable]: '24px',
        },
      },
    },
  },
};
