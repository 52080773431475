import { useCallback } from 'react';
import { HPortfolioCompany_Constraint, HPortfolioCompany_Update_Column } from 'schema';
import update from 'immutability-helper';
import { omitMeta, useCurrentUser, UserRole, Tuple } from './general';
import { GetUserDocument, HGetUserQuery, HGetUserQueryVariables } from './general.graphql';
import { useCurrentCompanyId, useCurrentUserId } from './localState';
import {
  HPortfolioCompanyFragment,
  HPortfolioFieldsFragment,
  HPortfoliosQuery,
  HPortfoliosQueryVariables,
  PortfoliosDocument,
  useAddCompanyToPortfolioMutation,
  useDeletePortfolioCompanyMutation,
  useUpsertPortfolioCompanyMutation,
  useUpsertPortfolioMutation,
  useDeletePortfolioMutation,
  useUpsertPortfolioEstimateCompanyMutation,
  useInsertPortfolioCompaniesMutation,
  HFindEstimateCompanyByNameQuery,
  HFindEstimateCompanyByNameQueryVariables,
  FindEstimateCompanyByNameDocument,
  HFindEstimateCompanyByUniqueIdQuery,
  HFindEstimateCompanyByUniqueIdQueryVariables,
  FindEstimateCompanyByUniqueIdDocument,
  useEstimateCompaniesLazyQuery,
  HCompanyShortFragment,
  useUpsertPortfolioCompaniesMutation,
  useCreatePortfolioCompaniesMutation,
} from './portfolio.graphql';

import { useApolloClient } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';
import { useUpsertCompanyInvitation } from './company-model';
import { auth } from 'utils/nhost';
import { useUpsertBusinessUnitsMutation } from 'models/company.graphql';
import { GENERAL_ACTIVITY_REF, getDefaultReportData } from './assessment-model';
import { useTranslation } from '../utils/i18n/i18n';

export const useEstimateCompaniesQuery = useEstimateCompaniesLazyQuery;
export type Portfolio = HPortfolioFieldsFragment;
export type PortfolioCompany = HPortfolioCompanyFragment;
export type PortfoliosQuery = HPortfoliosQuery;
export type PortfoliosQueryVariables = HPortfoliosQueryVariables;
export type CompanyShort = HCompanyShortFragment;

export const portfolioOwnerContext = {
  context: {
    headers: {
      'x-hasura-role': 'portfolio-owner',
    },
  },
};

export const useDeletePortfolioCompany = () => {
  const [deletePortfolioCompany] = useDeletePortfolioCompanyMutation(portfolioOwnerContext);
  return useCallback(
    ({ id }: PortfolioCompany) => {
      return deletePortfolioCompany({ variables: { id } }).then(
        ({ data }) => data?.portfolioCompany
      );
    },
    [deletePortfolioCompany]
  );
};

export const useUpsertPortfolioCompany = () => {
  const [addCompanyToPortfolioMutation] = useAddCompanyToPortfolioMutation(portfolioOwnerContext);
  const [upsertPortfolioCompany] = useUpsertPortfolioCompanyMutation(portfolioOwnerContext);
  const [upsertPortfolioCompanyEstimate] = useUpsertPortfolioEstimateCompanyMutation(
    portfolioOwnerContext
  );
  const userId = useCurrentUserId();

  return useCallback(
    ({
      estimateCompany,
      company,
      portfolioId,
      amount,
      contactPersonName,
      contactPersonEmail,
    }: {
      estimateCompany?: { id: string | undefined };
      company?: {
        id: string | undefined;
        name: string;
        industries?: string[];
        currency: string;
      };
      portfolioId: string;
      amount: number;
      contactPersonName: string;
      contactPersonEmail: string;
    }) => {
      if (!company?.id && !estimateCompany?.id) {
        return addCompanyToPortfolioMutation({
          variables: {
            userId,
            companyData: {
              data: {
                ...company,
                portfolioCompanies: {
                  data: [
                    {
                      portfolioId,
                      amount,
                      scores: {},
                      contactPersonName,
                      contactPersonEmail,
                    },
                  ],
                },
              },
            },
          },
          update: (cache, response) => {
            const query = { query: GetUserDocument, variables: { id: userId } };
            const user = cache.readQuery<HGetUserQuery, HGetUserQueryVariables>(query);
            if (user && response.data?.companyUser)
              cache.writeQuery({
                ...query,
                data: update(user, {
                  user: {
                    companies: {
                      $push: [
                        {
                          ...response.data.companyUser,
                          company: {
                            ...response.data.companyUser.company,
                            // As portfolio-owner we shouldn't have access to this information about company,
                            // but new company will not be a portfolioOwner
                            isPortfolioOwner: false,
                          },
                        },
                      ],
                    },
                  },
                }),
              });
          },
        }).then(
          ({ data }) =>
            data?.companyUser?.company.portfolioCompanies.find(
              (p) => p.portfolio.id == portfolioId
            ) ?? null
        );
      }
      if (estimateCompany?.id && !company?.id) {
        return upsertPortfolioCompanyEstimate({
          variables: {
            portfolioCompany: {
              portfolioId,
              amount,
              estimateCompanyId: estimateCompany.id,
              scores: {},
              contactPersonName,
              contactPersonEmail,
            },
          },
        }).then(({ data }) => data?.portfolioCompany ?? null);
      }
      return upsertPortfolioCompany({
        variables: {
          portfolioCompany: {
            portfolioId,
            amount,
            companyId: company?.id ?? undefined,
            scores: {},
            contactPersonName,
            contactPersonEmail,
          },
        },
      }).then(({ data }) => data?.portfolioCompany ?? null);
    },
    [upsertPortfolioCompany, addCompanyToPortfolioMutation, userId]
  );
};

const getDefaultFirstBusinessUnit = (
  t: (key: string) => string,
  userId: string,
  company: {
    id: any;
    name: string;
    isPortfolioOwner: boolean;
    industries: any;
    logoUrl?: string | null | undefined;
  }
) => {
  return {
    companyId: company.id,
    name: t('common:welcomeModal.defaultBusinessUnit.name'),
    description: '',
    businessArea: '',
    contactPersonId: userId,
    isSupporting: false,
    sectors: [],
    labels: ['My Label'],
    id: undefined,
    bAssessments: {
      data: [
        {
          activityReports: {
            data: [{ activityRef: GENERAL_ACTIVITY_REF, answers: { data: [] } }],
          },
          cAssessment: {
            data: getDefaultReportData(company),
          },
          cAssessmentId: undefined,
        },
      ],
    },
  };
};

type CreatePortfolioCompany = {
  name: string;
  contactPerson?: string;
  amount: number;
};
export function useCreatePortfolioCompanies() {
  const { t } = useTranslation('common');
  const [createNewPortfolioCompanies, result] = useCreatePortfolioCompaniesMutation(
    portfolioOwnerContext
  );
  const [upsertPortfolioCompanies] = useUpsertPortfolioCompaniesMutation({
    ...portfolioOwnerContext,
    refetchQueries: [PortfoliosDocument],
    awaitRefetchQueries: true,
  });

  const [inviteUserToCompany] = useUpsertCompanyInvitation();
  const [upsertBusinessUnits] = useUpsertBusinessUnitsMutation();
  const user = useCurrentUser();

  const wrapped = useCallback(
    async (
      companies: CreatePortfolioCompany[],
      userId: string,
      portfolioId: string,
      skipRefreshSession?: boolean
    ) => {
      const withExistingCompanies = companies.map((comp) => {
        const doesExist = user?.companies.find((c) => c.company.name === comp.name);
        if (doesExist) {
          return {
            ...comp,
            id: doesExist.company.id,
          };
        }
        return comp;
      });
      // Create new companies
      const newCompanies = await createNewPortfolioCompanies({
        variables: {
          companies: withExistingCompanies
            .filter((comp: CreatePortfolioCompany & { id?: string }) => !comp?.id)
            .map((comp) => ({
              company: {
                data: {
                  name: comp.name,
                  portfolioCompanies: {
                    data: [
                      {
                        portfolioId,
                        amount: comp.amount,
                        scores: {},
                        contactPersonName: 'N/A',
                        contactPersonEmail: comp.contactPerson ?? 'noreply@celsia.io',
                      },
                    ],
                  },
                },
              },
              userId: userId,
              role: UserRole.Admin,
            })),
        },
      }).then(async ({ data }) => {
        // Create first business unit for each company
        await upsertBusinessUnits({
          variables: {
            businessUnits: data?.result?.returning.map((comp) =>
              getDefaultFirstBusinessUnit(t, userId, { ...comp.company, isPortfolioOwner: false })
            ),
          },
        });
        return data?.result?.returning;
      });
      // Handle adding existing companies to portfolio
      await upsertPortfolioCompanies({
        variables: {
          portfolioCompanies: withExistingCompanies
            .filter((comp: CreatePortfolioCompany & { id?: string }) => !!comp.id)
            .map((comp: CreatePortfolioCompany & { id?: string }) => ({
              portfolioId,
              companyId: comp?.id,
              amount: comp.amount,
              scores: {},
            })),
        },
      });

      if (!skipRefreshSession) await auth.refreshSession(); // Refresh companies claims in JWTtoken

      // Invite respective contact persons to the new companies
      await Promise.allSettled(
        (newCompanies ?? []).map(async (createdCompany) => {
          const contactPerson = companies.find((comp) => comp.name === createdCompany.company.name)
            ?.contactPerson;

          if (contactPerson) {
            const invitation = await inviteUserToCompany({
              userEmail: contactPerson,
              companyId: createdCompany.company.id,
              status: 'pending',
              inviteToChildCompanies: false,
            });
            return invitation;
          }
          return null;
        })
      );
      return result;
    },
    [upsertPortfolioCompanies]
  );
  return Tuple(wrapped, result);
}

export type PortfolioUpsert = {
  id?: Portfolio['id'];
  name: string;
  description: string;
  portfolioCompanies?: PortfolioCompany[];
};

export function useUpsertPortfolio() {
  const [upsertPortfolioMutation] = useUpsertPortfolioMutation({
    ...portfolioOwnerContext,
    refetchQueries: [PortfoliosDocument],
    awaitRefetchQueries: true,
  });
  const companyId = useCurrentCompanyId();
  return useCallback(
    (data: PortfolioUpsert) =>
      upsertPortfolioMutation({
        variables: {
          portfolio: {
            ...omitMeta(data),
            id: data.id,
            ownerCompanyId: companyId,
            totalAmount: 0, // Unused. Use sum of portfolioCompany.amount instead for now
            portfolioCompanies: {
              data:
                data.portfolioCompanies?.map((pc) => ({
                  companyId: pc?.company?.id,
                  amount: pc.amount,
                  scores: pc.scores,
                  contactPersonName: pc.contactPersonName,
                  contactPersonEmail: pc.contactPersonEmail,
                })) ?? [],
              on_conflict: {
                constraint:
                  HPortfolioCompany_Constraint.PortfolioCompanyPortfolioIdCompanyIdFcfe62f4Uniq,
                update_columns: [
                  HPortfolioCompany_Update_Column.CompanyId,
                  HPortfolioCompany_Update_Column.Amount,
                  HPortfolioCompany_Update_Column.Scores,
                  HPortfolioCompany_Update_Column.ContactPersonName,
                  HPortfolioCompany_Update_Column.ContactPersonEmail,
                ],
              },
            },
          },
        },
      }).then((res) => res.data?.portfolio),
    [upsertPortfolioMutation, companyId]
  );
}
export function useDeletePortfolio() {
  const [deletePortfolio] = useDeletePortfolioMutation({
    ...portfolioOwnerContext,
    refetchQueries: [PortfoliosDocument],
    awaitRefetchQueries: true,
  });
  return useCallback(
    ({ id }: Portfolio) => {
      return deletePortfolio({ variables: { id } }).then(({ data }) => data?.portfolio);
    },
    [deletePortfolio]
  );
}

export function useAddPortfolioCompanies() {
  const [insertPortfolioCompanies] = useInsertPortfolioCompaniesMutation({
    ...portfolioOwnerContext,
    refetchQueries: [PortfoliosDocument],
    awaitRefetchQueries: true,
  });
  const client = useApolloClient();
  return useCallback(
    async (portfolioId: string, companies: { name?: string; amount: number; orgId?: string }[]) => {
      const companiesToAdd = await Promise.all(
        companies.map(async (eC) => {
          if (eC.orgId) {
            const { data } = await client.query<
              HFindEstimateCompanyByUniqueIdQuery,
              HFindEstimateCompanyByUniqueIdQueryVariables
            >({
              query: FindEstimateCompanyByUniqueIdDocument,
              variables: {
                uniqueId: eC?.orgId ?? '',
              },
            });
            if (data?.EstimateCompany[0]?.id) {
              return { id: data?.EstimateCompany[0].id, amount: eC.amount };
            }
          }
          if (eC?.name) {
            const { data } = await client.query<
              HFindEstimateCompanyByNameQuery,
              HFindEstimateCompanyByNameQueryVariables
            >({
              query: FindEstimateCompanyByNameDocument,
              variables: {
                name: `%${eC?.name}%` ?? '%%',
              },
            });
            if (data?.EstimateCompany[0]) {
              return { id: data?.EstimateCompany[0].id, amount: eC.amount };
            }
            return null;
          }
        })
      );
      const objects = uniqBy(
        companiesToAdd
          .filter((e) => !!e)
          .map((eC) => ({
            portfolioId,
            amount: eC?.amount,
            estimateCompanyId: eC?.id,
            scores: {},
          })),
        'estimateCompanyId'
      );

      await insertPortfolioCompanies({
        variables: {
          objects,
        },
      });
    },
    [client]
  );
}
