import { Input } from '@chakra-ui/react';
import React from 'react';
import { Field, FieldProps } from 'formik';
import dynamic from 'next/dynamic';
import { FormFieldProps, FormField } from './FormField';
import { useTranslation } from 'utils/i18n';

export const PasswordStrengthBar = dynamic(() => import('react-password-strength-bar'));

export const NewPasswordField = ({
  setPasswordStrength,
  userInputs,
  ...props
}: FormFieldProps<string> & {
  setPasswordStrength: (score: number) => void;
  userInputs?: string[];
}) => {
  const { t } = useTranslation();
  return (
    <FormField {...props}>
      {/* @ts-ignore */}
      {({ setValue, ...inputProps }) => (
        <>
          <PasswordStrengthBar
            password={inputProps.value as string}
            onChangeScore={(score) => setPasswordStrength(score)}
            scoreWords={[
              t('fields.passwordStrength.weak'),
              t('fields.passwordStrength.weak'),
              t('fields.passwordStrength.okay'),
              t('fields.passwordStrength.good'),
              t('fields.passwordStrength.strong'),
            ]}
            shortScoreWord={t('fields.passwordStrength.tooShort')}
            style={{
              display: 'flex',
              flexDirection: 'column-reverse',
              height: '2px',
              overflow: 'visible',
              marginBottom: '4px',
            }}
            userInputs={userInputs}
          />
          <Input {...inputProps} />
        </>
      )}
    </FormField>
  );
};
export const FormikNewPasswordField = ({
  name,
  isRequired,
  userInputs,
  ...rest
}: {
  userInputs?: string[];
} & Omit<FormFieldProps<string>, 'field' | 'meta'>) => (
  <Field name={name}>
    {({ field, meta, form }: FieldProps) => (
      <NewPasswordField
        field={field}
        form={form}
        meta={{ ...meta, error: meta.error || (form.errors.strength as string) }}
        setPasswordStrength={(score) => form.setFieldValue('strength', score)}
        type="password"
        autoComplete="new-password"
        userInputs={userInputs}
        {...rest}
      />
    )}
  </Field>
);
