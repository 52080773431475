import { useMemo, useEffect } from 'react';
import {
  useCurrentAssessmentId,
  useCurrentCompanyId,
  useSetCurrentAssessmentId,
} from 'models/localState';
import { useQuerySuspense } from './useQuerySuspense';
import {
  CompanyByIdDocument,
  CompanyByIdQuery,
  CompanyByIdQueryVariables,
  transformCompany,
  Company,
} from 'models';
import { AppError, COMPANY_ID_NOT_SET, COMPANY_NOT_FOUND } from '../components/Suspense';
import { useEUDataTranslation } from 'utils/i18n';
import { useCompanyByIdQuery } from 'models/company.graphql';
import { validate } from 'uuid';

export function useSimpleCompany() {
  const companyId = useCurrentCompanyId();
  const { data } = useCompanyByIdQuery({
    variables: { id: companyId },
    skip: !validate(companyId ?? ''),
    fetchPolicy: 'cache-first',
  });
  return data?.company;
}

export default function useCompany(): Company {
  const companyId = useCurrentCompanyId();
  const cAssessmentId = useCurrentAssessmentId();
  const setCurrentAssessmentId = useSetCurrentAssessmentId();
  if (companyId === '') {
    throw new AppError(COMPANY_ID_NOT_SET);
  }

  const { company: rawCompany } = useQuerySuspense<CompanyByIdQuery, CompanyByIdQueryVariables>(
    CompanyByIdDocument,
    {
      variables: {
        id: companyId,
      },
    },
    `Company:${companyId}`
  );
  if (rawCompany == null) throw new AppError(COMPANY_NOT_FOUND, `No such company: ${companyId}`);

  const { t } = useEUDataTranslation('activity');
  const company = useMemo(() => transformCompany(rawCompany, t), [rawCompany, t]);
  useEffect(() => {
    if (cAssessmentId == null && !!company.cAssessments[0]?.id)
      setCurrentAssessmentId(company.cAssessments[0]?.id, true);
  }, [company, cAssessmentId, setCurrentAssessmentId]);
  return company;
}
