import {
  Menu,
  MenuButton,
  IconButton,
  MenuItem,
  MenuItemProps,
  MenuGroup,
  Portal,
  MenuProps,
  MenuList,
  MenuDivider,
  Box,
  Button,
} from '@chakra-ui/react';
import { EllipsisVerticalIcon, EditIcon, DeleteIcon, DuplicateIcon } from 'icons';
import React from 'react';
import { useTranslation, tenum } from 'utils/i18n';

const DIVIDER_TITLE = '__divider';
export const DIVIDER = {
  name: DIVIDER_TITLE,
};
const KNOWN_ICONS = {
  [tenum('common:actions.duplicate')]: <DuplicateIcon />,
  [tenum('common:actions.edit')]: <EditIcon />,
  [tenum('common:actions.delete')]: <DeleteIcon />,
};
type KnownActions = keyof typeof KNOWN_ICONS;
export type Action = {
  name: KnownActions | string;
  element?: JSX.Element;
  icon?: KnownActions | MenuItemProps['icon'];
  actions?: Action[];
} & Omit<MenuItemProps, 'icon'>;

const getIcon = (icon: Action['icon'], name?: Action['name']) =>
  typeof icon === 'string' || icon === undefined
    ? KNOWN_ICONS[icon ?? (name as KnownActions)]
    : icon;

export function ActionList({ actions }: { actions: Action[] }) {
  const { t, i18n } = useTranslation();
  const getActions = (actionList: Action[]) =>
    actionList.map(({ name, icon, element, actions: group, ...props }, i) => {
      if (element) {
        return element;
      }
      if (name === DIVIDER_TITLE)
        // eslint-disable-next-line react/no-array-index-key
        return <MenuDivider key={name + i} />;
      else if (group)
        return (
          <MenuGroup title={name} key={name}>
            {getActions(group)}
          </MenuGroup>
        );
      else
        return (
          <MenuItem key={name} icon={getIcon(icon, name)} {...props}>
            {i18n.exists(['common:actions.' + name, name])
              ? t(['common:actions.' + name, name])
              : name}
          </MenuItem>
        );
    });
  return (
    <MenuList
      sx={{ '.chakra-menu__icon-wrapper': { fontSize: '24px' } }}
      onClick={(e) => e.stopPropagation()}
    >
      {getActions(actions)}
    </MenuList>
  );
}

export const ActionMenuButton = ({
  text,
  icon,
  isDisabled,
  color,
}: {
  text?: string;
  icon?: keyof typeof KNOWN_ICONS | MenuItemProps['icon'];
  isDisabled?: boolean;
  color: string;
}) => {
  const { t } = useTranslation();
  const stopPropagation = (e: any) => {
    e.stopPropagation();
  };
  if (text) {
    return (
      <MenuButton
        as={Button}
        leftIcon={getIcon(icon)}
        onClick={stopPropagation}
        isDisabled={isDisabled}
      >
        {text}
      </MenuButton>
    );
  }
  return (
    <MenuButton
      as={IconButton}
      aria-label={t('common:button.options')}
      icon={<EllipsisVerticalIcon />}
      variant="ghost"
      minWidth="24px"
      color={color}
      onClick={stopPropagation}
      isDisabled={isDisabled}
    />
  );
};
export default function ActionMenu({
  actions,
  placement,
  usePortal,
  triggerText,
  triggerIcon,
  triggerButtonColor = 'primary.main',
}: {
  actions: Action[];
  placement?: MenuProps['placement'];
  usePortal?: boolean;
  triggerText?: string;
  triggerIcon?: keyof typeof KNOWN_ICONS | MenuItemProps['icon'];
  triggerButtonColor?: string;
}) {
  const menu = <ActionList actions={actions} />;
  return (
    <Box>
      <Menu placement={placement}>
        <ActionMenuButton
          text={triggerText}
          icon={triggerIcon}
          isDisabled={actions.length == 0}
          color={triggerButtonColor}
        />
        {usePortal ? (
          <Portal>
            <Box sx={{ '& > div': { zIndex: 'var(--chakra-zIndices-tooltip) !important' } }}>
              {menu}
            </Box>
          </Portal>
        ) : (
          menu
        )}
      </Menu>
    </Box>
  );
}
