import useCompany from 'hooks/useCompany';
import { Flex, Text, useDisclosure, Button, Badge } from '@chakra-ui/react';
import DefaultModal from 'components/ui/Modal';
import { endOfDay, formatDistanceToNow } from 'date-fns';
import React from 'react';
import Suspense, { COMPANY_ID_NOT_SET, COMPANY_NOT_FOUND } from 'components/Suspense';
import { useCurrentCompanyId } from 'models/localState';

import GetQuoteForm from './GetQuoteForm';
import { useTranslation } from 'utils/i18n';

export function TrialIndicatorInner() {
  const company = useCompany();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();
  if (!company.isOnTrial) return null;
  const trialEndDate = endOfDay(new Date(company.trialEndDate));
  const hasExpired = trialEndDate < new Date();
  return (
    <Flex wrap={['wrap', 'nowrap']} gridGap="sm">
      <Badge
        bg={hasExpired ? '#F1164B17' : 'warningAlert.background'}
        height="32px"
        borderRadius="base"
        fontWeight="400"
        fontSize="md"
        paddingX="16px"
        paddingY="5px"
      >
        <Text
          color={hasExpired ? '#F1154AEB' : 'black'}
          fontWeight={hasExpired ? 'bold' : 'normal'}
        >
          {hasExpired ? (
            t('common:trial.expired')
          ) : (
            <>
              {t('common:trial.expiresIn')} <b>{formatDistanceToNow(trialEndDate)}</b>
            </>
          )}
        </Text>
      </Badge>
      <Button onClick={onOpen}>{t('common:trial.getQuote')}</Button>
      <DefaultModal
        isOpen={isOpen}
        onClose={onClose}
        header={t('common:trial.contactForm')}
        size="xl"
      >
        <GetQuoteForm onClose={onClose} />
      </DefaultModal>
    </Flex>
  );
}
const EXPECTED_ERRORS = [COMPANY_ID_NOT_SET, COMPANY_NOT_FOUND];
export default function TrialIndicator() {
  const currentCompanyId = useCurrentCompanyId();
  return (
    <Suspense fallback={null} expectedErrors={EXPECTED_ERRORS} debugName="TrialIndicator">
      {currentCompanyId && <TrialIndicatorInner />}
    </Suspense>
  );
}
