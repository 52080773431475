import {
  Box,
  BoxProps,
  Container,
  Heading,
  Stack,
  Wrap,
  HStack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { LogOutIcon, LanguagesIcon } from 'icons';
import useLoggedInUser from 'hooks/useLoggedInUser';
import { TopMenu } from './TopMenu';
import Suspense from '../Suspense';
import { tnoop, useChangeLanguage } from 'utils/i18n';
import { DIVIDER } from '../ui/ActionMenu';
import { getDisplayName } from './UserAvatar';
import LanguageEditor from 'components/user/LanguageEditor';

export const MenuContext = createContext({
  setTitle: (_t: string) => {},
  setActionBar: (_t: React.ReactNode) => {},
  setSideMenu: (_t: React.ReactNode) => {},
  setContainerWidth: (_t: string) => {},
});
export function useMenuContext() {
  return useContext(MenuContext);
}

export function useMenuTitle(title: string | null | undefined) {
  const { setTitle } = useMenuContext();
  useEffect(() => {
    setTitle(title ?? '');
  }, [title]);
}
export interface MenuWrapperProps extends BoxProps {
  title?: string;
  actionBar?: React.ReactNode;
  containerWidth?: string;
}
export function useUserActions() {
  const { user, handleLogOut } = useLoggedInUser();
  const { changeLanguage, language } = useChangeLanguage();
  useEffect(() => {
    if (user?.language && language != user?.language && language) {
      changeLanguage(user.language);
    }
  }, [language, user?.language]);
  const { isOpen, onClose, onOpen } = useDisclosure();
  return useMemo(
    () => ({
      userActions: [
        {
          name: user ? getDisplayName(user) : '',
          actions: [
            { name: tnoop('common:actions.language'), icon: <LanguagesIcon />, onClick: onOpen },
            DIVIDER,
            { name: tnoop('common:actions.logOut'), icon: <LogOutIcon />, onClick: handleLogOut },
          ],
        },
      ],
      user,
      languageEditor: user && <LanguageEditor isOpen={isOpen} onClose={onClose} user={user} />,
    }),
    [onOpen, handleLogOut, user]
  );
}
const MenuWrapper = ({
  children,
  title: initialTitle,
  actionBar: initialActionBar = false,
  containerWidth: initialContainerWidth = 'container.xl',
}: MenuWrapperProps) => {
  const [title, setTitle] = useState(initialTitle);
  const [actionBar, setActionBar] = useState<React.ReactNode>(initialActionBar);
  const [sideMenu, setSideMenu] = useState<React.ReactNode>();
  const [containerWidth, setContainerWidth] = useState<string>(initialContainerWidth);

  const menuContext = useMemo(
    () => ({
      setTitle,
      setActionBar,
      setSideMenu,
      setContainerWidth,
    }),
    [setTitle, setActionBar]
  );
  const { userActions, languageEditor, user } = useUserActions();
  return (
    <MenuContext.Provider value={menuContext}>
      {languageEditor}
      <TopMenu user={user} actions={userActions}>
        <HStack
          alignItems="flex-start"
          justifyContent="center"
          spacing="0"
          padding={['16px 16px 0 16px', null, '16px 32px 0 32px']}
        >
          {sideMenu && <Box width="305px">{sideMenu}</Box>}
          <Container maxW={containerWidth} padding="0">
            {(title || actionBar) && (
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb="lg">
                <Wrap direction="column">
                  {title && (
                    <Heading fontSize="h1" fontWeight="700">
                      {title}
                    </Heading>
                  )}
                </Wrap>
                <Box marginLeft="auto">{actionBar}</Box>
              </Stack>
            )}
            <Suspense debugName="Main Component" fullScreen>
              {children}
            </Suspense>
          </Container>
        </HStack>
      </TopMenu>
    </MenuContext.Provider>
  );
};

export default MenuWrapper;
