import React, { useMemo } from 'react';
import { pick } from 'lodash';
import {
  useToast as useChakraToast,
  UseToastOptions as ChakraUseToastOptions,
  ToastId,
} from '@chakra-ui/react';
import { useLatest } from 'react-use';
import { AlertWithActions, AlertWithActionsProps } from 'components/ui/AlertWithActions';

const DEFAULTS = {
  status: 'success' as UseToastOptions['status'],
  position: 'bottom-right' as UseToastOptions['position'],
  variant: 'top-accent',
  isClosable: true,
};
type UseToastOptions = ChakraUseToastOptions & { actions?: AlertWithActionsProps['actions'] };
export function useToast(props?: UseToastOptions) {
  const toast = useChakraToast();
  const propsRef = useLatest(props);
  return useMemo(() => {
    const getProps = (newProps?: UseToastOptions) => {
      const allProps = { ...DEFAULTS, ...propsRef.current, ...newProps };
      if (!allProps.render)
        // eslint-disable-next-line react/display-name
        allProps.render = (options) => (
          <AlertWithActions
            {...options}
            {...pick(allProps, 'title', 'description', 'variant', 'status', 'actions')}
          />
        );
      return allProps;
    };
    return Object.assign((newProps?: UseToastOptions) => toast(getProps(newProps)), {
      ...pick(toast, 'closeAll', 'close', 'isActive'),
      update: (id: ToastId, newProps: UseToastOptions) => toast.update(id, getProps(newProps)),
    });
  }, [toast]);
}

export function useBigToast(props?: UseToastOptions) {
  return useToast({
    position: 'top',
    ...props,
  });
}
export type ToastType = ReturnType<typeof useBigToast>;
export default useToast;
