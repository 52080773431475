import React, { useEffect, useCallback, useState } from 'react';
import {
  HStack,
  StackProps,
  Text,
  VStack,
  Button,
  Heading,
  Circle,
  useDisclosure,
} from '@chakra-ui/react';
import Image from 'next/image';
import {
  useCurrentCompanyId,
  useGetLastAssessmentIdForCompany,
  useSetCurrentAssessmentId,
  useSetCurrentCompanyId,
} from 'models/localState';
import { User, UserCompany, useSectors, PortfolioCompany } from 'models';
import { useRouter } from 'next/router';
import DefaultDrawer from 'components/ui/Drawer';
import { CompanyRegisterModal } from 'components/RegisterCompany';
import { CompanyIcon, CircleIconWrapper } from 'icons';
import { useTranslation } from 'utils/i18n';
import TruncatedTagList from 'components/TruncatedTagList';
import { SearchInput } from 'components/ui/SearchInput';

interface CompanySwitcherProps extends StackProps {
  user: User;
}
export const CompanyLogo = ({
  url,
  size,
  shadow = '0px 0px 8px 0px #00000029',
}: {
  url: string;
  size: string;
  shadow?: string;
}) => (
  <Circle overflow="hidden" bg="white" size={size} shadow={shadow}>
    <Image src={url} width={size} height={size} />
  </Circle>
);

export const EstimateCompanyPresentation = ({
  company,
}: {
  company: PortfolioCompany['estimateCompany'];
}) => {
  const sectors = useSectors(company?.industries ?? []);
  return (
    <HStack alignItems="flex-start" width="100%">
      {company?.logoUrl && <CompanyLogo url={company.logoUrl} size="32px" />}
      <VStack alignItems="flex-start">
        <Heading fontSize="h3" color="black">
          {company?.name}
        </Heading>
        <TruncatedTagList tags={sectors.map((s) => s.name)} lines={1} />
      </VStack>
    </HStack>
  );
};

export const CompanyPresentation = React.forwardRef(
  (
    {
      company,
      onClick,
    }: {
      company: Pick<UserCompany, 'logoUrl' | 'name' | 'industries'>;
      onClick?: () => void;
    },
    ref: React.LegacyRef<HTMLDivElement>
  ) => {
    return (
      <HStack
        ref={ref}
        alignItems="flex-start"
        width="100%"
        onClick={onClick}
        cursor={!!onClick ? 'pointer' : 'default'}
      >
        {company.logoUrl && <CompanyLogo url={company.logoUrl} size="32px" />}
        <VStack alignItems="flex-start">
          <Heading fontSize="h3" color="black">
            {company.name}
          </Heading>
        </VStack>
      </HStack>
    );
  }
);

CompanyPresentation.displayName = 'CompanyPresentation';
const CompanyButton = ({
  company,
  select,
}: {
  company: UserCompany;
  select: (c: UserCompany) => void;
}) => {
  return (
    <Button
      _hover={{ bg: 'hoverBg' }}
      _focus={{ bg: 'hoverBg' }}
      onClick={() => select(company)}
      tabIndex={0}
      textTransform="none"
      justifyContent="flex-start"
      variant="ghost"
      borderRadius="lg"
      width="100%"
      height="auto"
      p="md"
    >
      <CompanyPresentation company={company} />
    </Button>
  );
};
export function useSwitchCompany() {
  const setCurrentCompanyId = useSetCurrentCompanyId();
  const setCurrentAssessmentId = useSetCurrentAssessmentId();
  const getLastAssessmentIdForCompany = useGetLastAssessmentIdForCompany();

  const { push } = useRouter();
  return useCallback(async (company: Pick<UserCompany, 'id' | 'isPortfolioOwner'>) => {
    const nextAssessmentId = await getLastAssessmentIdForCompany(company.id);
    return push({
      pathname: company.isPortfolioOwner ? '/portfolios' : `/`,
      query: {},
    }).then(() => {
      setCurrentAssessmentId(nextAssessmentId);
      setCurrentCompanyId(company.id);
    });
  }, []);
}
const CompanySwitcher = ({ user, ...rest }: CompanySwitcherProps) => {
  const companyId = useCurrentCompanyId();
  const setCurrentCompanyId = useSetCurrentCompanyId();
  const currentCompany = user?.companies.find((c) => c.company.id === companyId)?.company;
  const [query, setQuery] = useState('');
  const switchCompany = useSwitchCompany();
  useEffect(() => {
    if (user.companies.length && !currentCompany) setCurrentCompanyId(user.companies[0].company.id);
  }, [user.companies, currentCompany]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();

  const handleChange = (company: UserCompany) => {
    switchCompany(company).then(() => onClose());
  };

  const { t } = useTranslation();

  const filterCompanies = ({ name }: { name: string }) => {
    if (query === '' || !query) return true;
    return name?.toLowerCase().search(query.toLowerCase()) !== -1;
  };

  if (!currentCompany) return null;
  return (
    <HStack {...rest} spacing="md" maxW="100%" flexShrink={1}>
      {currentCompany.logoUrl ? (
        <CompanyLogo url={currentCompany.logoUrl} size="22px" />
      ) : (
        <CircleIconWrapper icon={CompanyIcon} size="22px" />
      )}
      <Text
        maxW={['12em', null, null, '100%']}
        noOfLines={1}
        title={currentCompany.name}
        flexShrink={1}
        whiteSpace="nowrap"
      >
        {currentCompany.name}
      </Text>
      <Button variant="secondary" onClick={onOpen} size="sm" flexShrink={0}>
        {t('common:switchCompany')}
      </Button>

      <DefaultDrawer
        header={t('common:companyDrawer.header')}
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      >
        <VStack width="100%">
          <SearchInput
            search={query}
            setSearch={setQuery}
            placeholder={t('common:companyDrawer:filterCompanies')}
          />
          {user.companies
            .filter((c) => filterCompanies(c.company))
            .map(({ company }) => (
              <CompanyButton key={company.id} company={company} select={handleChange} />
            ))}
        </VStack>
        <Button size="lg" width="full" mt="lg" onClick={onCreateOpen}>
          {t('common:companyDrawer.createCompany')}
        </Button>
      </DefaultDrawer>
      <CompanyRegisterModal
        isOpen={isCreateOpen}
        user={user}
        onClose={onCreateClose}
        header={t('common:companyModal.header')}
        onSave={(c) => {
          onCreateClose();
          handleChange(c);
        }}
      />
    </HStack>
  );
};

export default CompanySwitcher;
