import { Formik } from 'formik';
import useCompany from 'hooks/useCompany';
import { useCurrentUser, useRequestQuote } from 'models';
import * as Yup from 'yup';
import { FormikFormField, FormikSubmitButton, VForm } from '../ui/forms/FormField';
import { Select } from '../ui/forms/Select';
import { useBigToast } from 'hooks/useToast';
import { tenum, useTranslation } from 'utils/i18n';
import { TFunction } from 'next-i18next';

const ContactFormSchema = (t: TFunction) =>
  Yup.object().shape({
    companyName: Yup.string()
      .trim()
      .label(t('common:fields.companyName.label'))
      .required() as Yup.StringSchema<string>,
    email: Yup.string()
      .trim()
      .label(t('common:fields.email.workLabel'))
      .required() as Yup.StringSchema<string>,
    userEmail: Yup.string()
      .trim()
      .label(t('common:fields.email.userLabel'))
      .required() as Yup.StringSchema<string>,
    phoneNumber: Yup.string()
      .trim()
      .label(t('common:fields.phoneNumber.label')) as Yup.StringSchema<string>,
    taxonomyKnowledge: Yup.string()
      .trim()
      .label(t('common:fields.taxonomyKnowledge.label')) as Yup.StringSchema<string>,
    message: Yup.string()
      .trim()
      .label(t('common:fields.message.label')) as Yup.StringSchema<string>,
  });

const KNOWLEDGE_OPTIONS = [
  tenum('common:fields.taxonomyKnowledge.very'),
  tenum('common:fields.taxonomyKnowledge.somewhat'),
  tenum('common:fields.taxonomyKnowledge.little'),
  tenum('common:fields.taxonomyKnowledge.none'),
];

const GetQuoteForm = ({ onClose }: { onClose: () => void }) => {
  const toast = useBigToast();
  const requestQuote = useRequestQuote();
  const { t } = useTranslation();
  const submitContactInfo = async (values: any) =>
    requestQuote(values).then(() => {
      onClose();
      toast({
        title: t('common:trial.toast.requestedQuote.title'),
        description: t('common:trial.toast.requestedQuote.description'),
      });
    });
  const company = useCompany();
  const user = useCurrentUser();
  const schema = ContactFormSchema(t);
  return (
    <Formik
      initialValues={{
        companyName: company.name ?? '',
        email: user?.email ?? '',
        phoneNumber: '',
        message: '',
        userEmail: user?.email,
        taxonomyKnowledge: '',
      }}
      validationSchema={schema}
      onSubmit={(values) => submitContactInfo(schema.cast(values))}
    >
      <VForm>
        <FormikFormField
          name="companyName"
          label={t('common:fields.companyName.label')}
          isRequired
        />
        <FormikFormField name="email" label={t('common:fields.email.workLabel')} isRequired />
        <FormikFormField name="phoneNumber" label={t('common:fields.phoneNumber.label_long')} />
        <FormikFormField
          name="taxonomyKnowledge"
          label={t('common:fields.taxonomyKnowledge.label')}
        >
          {/* @ts-ignore */}
          {({ value, setValue }) => (
            <Select
              value={{ value, label: value ? t(`common:fields.taxonomyKnowledge.${value}`) : '' }}
              onChange={(v: any) => v && setValue(v.value)}
              isSearchable={false}
              isMulti={false}
              options={KNOWLEDGE_OPTIONS.map((v) => ({
                value: v,
                label: t(`common:fields.taxonomyKnowledge.${v}`) as string,
              }))}
            />
          )}
        </FormikFormField>
        <FormikFormField
          name="message"
          label={t('common:fields.message.label')}
          placeholder={t('common:fields.message.placeholder')}
        />
        <FormikSubmitButton width="full">{t('common:trial.getQuote')}</FormikSubmitButton>
      </VForm>
    </Formik>
  );
};

export default GetQuoteForm;
