import { Circle } from '@chakra-ui/react';
import { IconProps, createIcon } from '@chakra-ui/icons';
export type IconComponentType = ReturnType<typeof createIcon>;
export function CircleIconWrapper({
  icon: IconComponent,
  size,
  color = 'white',
  bg = 'black',
  scale = 14 / 22,
}: {
  icon: IconComponentType;
  size: string;
  color?: IconProps['color'];
  bg?: IconProps['bg'];
  scale?: number;
}) {
  return (
    <Circle size={size} bg={bg} color={color}>
      <IconComponent boxSize={`${scale * 100}%`} />
    </Circle>
  );
}
