import { useRef, useContext } from 'react';
import { Box } from '@chakra-ui/react';
import Sticky from 'react-stickynode';
import SimpleBar from 'simplebar-react';
import { useWindowScroll, useWindowSize } from 'react-use';
import 'simplebar/dist/simplebar.min.css';
import { StickyMenuContext } from 'components/topMenu/TopMenu';

export default function StickyScroll({
  top,
  header,
  maxHeight = '100vh',
  children,
  zIndex,
  isEnabled = true,
}: {
  top?: number;
  header?: React.ReactElement;
  maxHeight?: string;
  zIndex?: string;
  isEnabled?: boolean;
  children: React.ReactNode;
}) {
  const ref = useRef<SimpleBar | null>(null);
  const { y } = useWindowScroll();
  const rect = ref.current?.getContentElement().getBoundingClientRect();
  const { height } = useWindowSize(); // React on document.body.scrollHeight so we can enable the scroll after whole document is loaded
  const enabled =
    isEnabled &&
    (rect == null || rect.height === 0 || y + rect.top + rect.height < document.body.scrollHeight);
  const topMenuContext = useContext(StickyMenuContext);
  const topMenuHeight = topMenuContext.currentContentOffset;
  if (top == null) top = topMenuHeight;
  return (
    // @ts-ignore
    <Sticky top={top} innerZ={zIndex} enabled={isEnabled}>
      {header}
      <Box
        sx={{
          '.simplebar-content-wrapper': {
            overflowX: 'hidden',
          },
          '.simplebar-horizontal': {
            display: 'none',
          },
          '.simplebar-vertical': {
            right: 'auto',
            left: '0',
          },
          '[data-simplebar=true] .simplebar-mask': {
            position: 'relative',
          },
          '[data-simplebar=true] .simplebar-offset': {
            position: 'relative',
          },
        }}
      >
        {/* @ts-ignore */}
        {isEnabled ? (
          <SimpleBar
            style={{
              maxHeight: enabled
                ? `calc(${maxHeight} - ${top}px)`
                : `${document.body.scrollHeight - (y + (rect?.top ?? 0))}px`,
            }}
            ref={ref}
            key={height + ''} // SimpleBar sometimes does not render correctly after window height change
          >
            {children}
          </SimpleBar>
        ) : (
          children
        )}
      </Box>
    </Sticky>
  );
}
