import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import {
  HCommentFieldsFragment,
  HThreadFieldsFragment,
  HNotificationFieldsFragment,
  useReadUserNotificationMutation,
  useRemoveCommentMutation,
  useNotificationsSubscription,
  useUpsertThreadMutation,
  useThreadSubscription,
} from './thread.graphql';
import { pick } from 'lodash';
import { validate } from 'uuid';
import {
  HThreadSubscriber_Arr_Rel_Insert_Input,
  HThreadSubscriber_Constraint,
  HThreadSubscriber_Update_Column,
  HThread_Constraint,
  HThread_Update_Column,
} from 'schema';
import { useLatest } from 'react-use';
import { useCurrentUserId } from './localState';

export type Thread = HThreadFieldsFragment;
export type Comment = HCommentFieldsFragment;
export type Notification = HNotificationFieldsFragment;
export const SubscriberOnConflict = {
  constraint: HThreadSubscriber_Constraint.ThreadSubscriberThreadIdUserIdC1ed612aUniq,
  update_columns: [],
};
export const AnswerThreadOnConflict = {
  constraint: HThread_Constraint.ThreadAnswerIdKey,
  update_columns: [HThread_Update_Column.AnswerId],
};
export function getSubscribeCurrentUserInput() {
  return {
    // Add current user to thread subscribers
    data: [
      {
        // userId and isSubscribed=True is set by backend automatically
      },
    ],
    on_conflict: {
      constraint: HThreadSubscriber_Constraint.ThreadSubscriberThreadIdUserIdC1ed612aUniq,
      update_columns: [HThreadSubscriber_Update_Column.IsSubscribed],
    },
  } as HThreadSubscriber_Arr_Rel_Insert_Input;
}
export function useCommentThread({
  id,
  ...props
}: Partial<Pick<Thread, 'id' | 'companyId' | 'answerId'>>) {
  const [upsertThread] = useUpsertThreadMutation();
  const [deleteCommentMutation] = useRemoveCommentMutation();
  const { data: threadResult } = useThreadSubscription({
    skip: !validate(id),
    variables: { id },
  });
  const thread = threadResult?.thread;
  const propsRef = useLatest(props);
  const addComment = useCallback(
    (data: Comment['data']) => {
      return upsertThread({
        variables: {
          thread: {
            id,
            ...propsRef.current,
            ...pick(thread, 'companyId', 'answerId'),
            comments: {
              data: [{ data }],
            },
            subscribers: undefined,
          },
        },
      });
    },
    [upsertThread, thread]
  );

  const deleteComment = useCallback(
    (commentId: Comment['id']) => deleteCommentMutation({ variables: { id: commentId } }),
    [deleteCommentMutation]
  );

  return { addComment, deleteComment, thread: threadResult?.thread };
}
const cachedNotifications = {
  default: { notifications: [] },
} as { [userId: string]: { notifications: Notification[] } };

export function useNotifications(): {
  notifications: Notification[];
  notificationCount: number;
  readNotifications: (notificationIds: string[]) => void;
} {
  const userId = useCurrentUserId();
  const { data, loading } = useNotificationsSubscription();
  const [readNotificationsMutation] = useReadUserNotificationMutation();
  // In case we already have notifications for that user (ie from previous mount of that component)
  // use them until new data arrives
  const notificationsResult =
    data ?? cachedNotifications[userId ?? ''] ?? cachedNotifications.default;
  useEffect(() => {
    if (!loading && userId && data) cachedNotifications[userId] = data;
  }, [loading, userId]);
  const readNotifications = useCallback(
    (notificationIds: string[]) =>
      readNotificationsMutation({
        variables: {
          ids: notificationIds,
        },
      }),
    [readNotificationsMutation]
  );

  return {
    notifications: notificationsResult.notifications
      .slice()
      .sort((a, b) => dayjs(b.createdAt).diff(a.createdAt)),
    notificationCount: notificationsResult.notifications.filter(({ isRead }) => !isRead).length,
    readNotifications,
  };
}
