import { SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { makeVar, useReactiveVar, useApolloClient } from '@apollo/client';
import { auth } from 'utils/nhost';
import { useRouterUrlQueryVar, useUrlQueryVar } from 'hooks/useUrlQueryVar';
import { useClearSuspenseCache } from 'hooks/useQuerySuspense';
import { useLatest, useLocalStorage, useUpdate } from 'react-use';
import { Tuple, useUserSetting } from 'models';
import { CompanyAssessment } from './assessment-model';
import { CompanyByIdDocument, useCompanyByIdQuery } from './company.graphql';
import { useCurrentUser } from './general';
import { validate } from 'uuid';

export function getUserId() {
  return auth.getClaim('x-hasura-user-id') as string | null;
}
export function useCurrentUserId() {
  const update = useUpdate();
  useEffect(() => {
    return (auth.onAuthStateChanged(update) as unknown) as () => void;
  }, []);
  return getUserId();
}
export function useUserLocalSetting<T>(name: string, def: T, raw = false) {
  const userId = useCurrentUserId();
  const setting = useLocalStorage(`${name}-${userId}`, def, raw ? { raw } : undefined);
  const setValueRef = useLatest(setting[1]);
  setting[1] = useCallback((val: SetStateAction<T | undefined>) => {
    setValueRef.current(val);
  }, []);
  return setting;
}

const loggedInUserVar = makeVar<string | null>(
  (typeof window !== 'undefined' && localStorage?.getItem('esgx-logged-in-email')) || null
);
export const useLoggedInUser = () => useReactiveVar(loggedInUserVar);

const currentCompanyId = makeVar<string | null>(null);

export const useSetCurrentCompanyId = () => {
  const setCompanyId = useUserLocalSetting(`company-id-for-user`, '', true)[1];
  return useCallback((companyId: any) => {
    setCompanyId(companyId);
    currentCompanyId(companyId);
  }, []);
};
export function useCurrentCompanyId() {
  const lastUsedCompanyId = useUserLocalSetting(`company-id-for-user`, '', true)[0];
  return useReactiveVar(currentCompanyId) || lastUsedCompanyId;
}

export const useGetLastAssessmentIdForCompany = () => {
  const user = useCurrentUser();
  const client = useApolloClient();

  const getLastAssessmentIdForCompany = async (companyId: string | undefined) => {
    if (!companyId) return undefined;
    const companyResult = await client.query({
      query: CompanyByIdDocument,
      variables: { id: companyId },
    });
    const storedId = user?.settings[`last-assessment-id-${companyId}`];
    return storedId || companyResult?.data.company?.cAssessments?.[0]?.id;
  };

  return getLastAssessmentIdForCompany;
};

export const useLastAssessmentIdForCompany = (companyId: string | undefined) => {
  const [storedLastUsedAssessmentId, setStoredLastUsedAssessmentId] = useUserSetting<
    CompanyAssessment['id']
  >(`last-assessment-id-${companyId}`, '');

  const { data } = useCompanyByIdQuery({
    variables: { id: companyId },
    fetchPolicy: 'cache-first',
    skip: !validate(companyId ?? ''),
  });

  return useMemo(() => {
    const lastUsedAssessmentId = storedLastUsedAssessmentId || data?.company?.cAssessments?.[0]?.id;
    if (companyId && lastUsedAssessmentId && lastUsedAssessmentId !== storedLastUsedAssessmentId) {
      setStoredLastUsedAssessmentId(lastUsedAssessmentId);
    }
    return Tuple(lastUsedAssessmentId, setStoredLastUsedAssessmentId);
  }, [companyId, data, storedLastUsedAssessmentId, setStoredLastUsedAssessmentId]);
};

export const useLastUsedAssessmentId = () => {
  const companyId = useCurrentCompanyId();
  const [storedLastUsedAssessmentId, setStoredLastUsedAssessmentId] = useLastAssessmentIdForCompany(
    companyId
  );

  return Tuple(storedLastUsedAssessmentId, setStoredLastUsedAssessmentId);
};

const currentAssessmentId = makeVar<string | undefined>(undefined);
export function useCurrentAssessmentId() {
  const [storedLastUsedAssessmentId] = useLastUsedAssessmentId();

  const localReportId = useReactiveVar(currentAssessmentId);
  const reportId = localReportId || storedLastUsedAssessmentId;
  return reportId;
}

export const useSetCurrentAssessmentId = (router = false) => {
  const [, setStoredLastUsedAssessmentId] = useLastUsedAssessmentId();
  const setQueryReportId = (router ? useRouterUrlQueryVar : useUrlQueryVar)('reportId')[1];
  return useCallback(
    (newAssessmentId: any, hideQuery = false) => {
      currentAssessmentId(newAssessmentId);
      setQueryReportId(hideQuery ? undefined : newAssessmentId);
      if (newAssessmentId) setStoredLastUsedAssessmentId(newAssessmentId);
    },
    [setQueryReportId]
  );
};

export const setLoggedInUser = (loggedInEmail?: string | null) => {
  if (loggedInEmail !== undefined)
    localStorage?.setItem('esgx-logged-in-email', loggedInEmail ?? '');

  return loggedInUserVar(loggedInEmail);
};

export const useLogout = () => {
  const client = useApolloClient();
  const setCurrentReportId = useSetCurrentAssessmentId();
  const clearSuspenseCache = useClearSuspenseCache();
  return useCallback(() => {
    setLoggedInUser(null);
    currentCompanyId(null);
    setCurrentReportId(undefined);
    localStorage?.removeItem('esgx-logged-in-email');
    sessionStorage.clear();
    client.stop();
    const resetStore = () => {
      client.resetStore();
      clearSuspenseCache();
    };
    if (auth.isAuthenticated()) return auth.logout().finally(resetStore);
    else resetStore();
  }, [client, clearSuspenseCache, setCurrentReportId]);
};
