/* eslint-disable @typescript-eslint/no-use-before-define */
import { sumBy, groupBy } from 'lodash';
import {
  BusinessUnit,
  Activity,
  ActivityReport,
  BAssessment,
  CompanyAssessment,
  Financials,
  scoreSections,
  allFinancialSections,
  GENERAL_ACTIVITY_REF,
  PortfolioCompany,
  Question,
  Objective,
  QuestionLevel,
  COMPANY_LEVEL_GENERAL_ASSESSMENT_ID,
  ActivityTagEnum,
} from 'models';
import { tnoop } from './i18n';

type BareFinancials = Omit<Financials, 'id'>;
const defaultFinancials: BareFinancials = {
  revenue: 0,
  capex: 0,
  opex: 0,
  adaptationCapex: 0,
  adaptationOpex: 0,
  isEstimate: false,
};

export type TaxonomyScore = {
  total: number;
  eligible: number;
  aligned: number;
  inProgress?: number;
};
export type TaxonomyScores = {
  revenue: TaxonomyScore;
  capex: TaxonomyScore;
  opex: TaxonomyScore;
  balance?: TaxonomyScore;
  isEstimate?: boolean;
};
export type BasicTaxonomyScores = Omit<TaxonomyScores, 'isEstimate'>;

export const taxonomyScoreNames = ['revenue', 'capex', 'opex'] as const;
export type TaxonomyScoreSection = typeof taxonomyScoreNames[number];
const getScores = <T>(
  f: (attr: typeof allFinancialSections[number]) => T,
  includeAdaptation = false
) =>
  Object.fromEntries(
    (includeAdaptation ? allFinancialSections : scoreSections).map((attr) => [attr, f(attr)])
  );

interface WithActivityReference {
  activityRef: Activity['reference'];
}
export const ALL_ACTIVITIES = 'ALL';

export type ActivityFilterDef = Activity['reference'] | typeof ALL_ACTIVITIES;

const activityFilter = <T extends WithActivityReference>(activityReference: ActivityFilterDef) => (
  ar: T
): boolean =>
  ar.activityRef !== GENERAL_ACTIVITY_REF &&
  (activityReference === ALL_ACTIVITIES || activityReference === ar.activityRef);

export const numberOrZero = (n: number | null) => (Number.isNaN(n) || n == null ? 0 : n);

const sumWithUndefined = (...addends: any[]) =>
  addends.reduce((sum, addend) => sum + numberOrZero(addend), 0);

const aggregateFinancialsReducer = (
  aggregatedFinancials: BareFinancials,
  currentFinancials: Partial<Financials>
): BareFinancials => ({
  ...getScores(
    (attr) => sumWithUndefined(aggregatedFinancials?.[attr], currentFinancials?.[attr]),
    true
  ),
  isEstimate: !!aggregatedFinancials?.isEstimate || !!currentFinancials?.isEstimate,
});
export const aggregateFinancials = (financials: Partial<Financials>[]) =>
  financials.reduce(aggregateFinancialsReducer, defaultFinancials);

const businessUnitActivities = (bAssessment: BAssessment, activityReference: ActivityFilterDef) =>
  Object.values(bAssessment.activities).filter(activityFilter(activityReference));

const getApplicableFinancials = (activityReport: ActivityReport) => {
  const alignedSCObjectives = getAlignedSCObjectives(activityReport);
  const isOnlyAdaptationAligned = isActivityOnlyAdaptationAligned(alignedSCObjectives);
  const isAligned = isActivityDoingAtLeastOneContribution(alignedSCObjectives);

  if (!isAligned) return defaultFinancials;

  const applicableFinancials = activityReport?.supportedFinancials ?? defaultFinancials;

  // If the activity is doing SC to adaptation, and is NOT and enabling activity, only adaptation capex and opex should count
  if (isOnlyAdaptationAligned && activityReport.tag !== ActivityTagEnum.enabling) {
    return {
      ...applicableFinancials,
      capex: applicableFinancials.adaptationCapex,
      opex: applicableFinancials.adaptationOpex,
      revenue: 0,
    };
  }

  return applicableFinancials;
};

export const getInProgressSCObjectives = (activityReport: ActivityReport) =>
  Object.entries(groupBy(activityReport.questions, 'objective.key'))
    .filter(([_, objectiveQuestions]) => {
      const SCQuestions = objectiveQuestions.filter(isQuestionSC);
      return (
        SCQuestions.length > 0 &&
        SCQuestions.filter(isQuestionAnswered).filter(isQuestionRequired).every(isSCQuestionAligned)
      );
    })
    .map(([objectiveName]) => objectiveName);

export const getAllSCObjectives = (activityReport: ActivityReport) =>
  Object.entries(groupBy(activityReport.questions, 'objective.key'))
    .filter(([_, objectiveQuestions]) => objectiveQuestions.filter(isQuestionSC).length)
    .map(([objectiveName]) => objectiveName);

const getApplicableInProgressFinancials = (activityReport: ActivityReport) => {
  const applicable = activityReport.questions.filter(isQuestionAnswered).filter(isQuestionRequired);
  const dnshAligned = applicable.every(isDNSHQuestionAligned);
  if (!dnshAligned) return defaultFinancials;

  const potentiallyAlignedSCObjectives = getInProgressSCObjectives(activityReport);

  const isOnlyAdaptationPotentiallyAligned = isActivityOnlyAdaptationAligned(
    potentiallyAlignedSCObjectives
  );
  const isPotentiallyAligned = isActivityDoingAtLeastOneContribution(
    potentiallyAlignedSCObjectives
  );

  if (!isPotentiallyAligned) return defaultFinancials;

  const applicableFinancials = activityReport?.supportedFinancials ?? defaultFinancials;
  // If the activity is doing SC to adaptation, and is NOT and enabling activity, only adaptation capex and opex should count
  if (isOnlyAdaptationPotentiallyAligned && activityReport.tag !== ActivityTagEnum.enabling) {
    return {
      ...applicableFinancials,
      capex: applicableFinancials.adaptationCapex,
      opex: applicableFinancials.adaptationOpex,
      revenue: 0,
    };
  }

  return applicableFinancials;
};

const getAlignedBusinessUnitFinancials = (
  bAssessment: BAssessment,
  activityReference: ActivityFilterDef,
  isCompanyLevelGeneralAssessmentAligned = false
) => {
  const isGeneralAligned = bAssessment.hasGeneralAssessment
    ? bAssessment.generalQuestions.every(isQuestionAligned)
    : isCompanyLevelGeneralAssessmentAligned;

  const alignedFinancials = aggregateFinancials(
    businessUnitActivities(bAssessment, activityReference).map(getApplicableFinancials)
  );

  return isGeneralAligned ? alignedFinancials : defaultFinancials;
};

export const isQuestionVisible = (q: Question) => !!q.isVisible;
export const isQuestionApplicable = (q: Question) => !!q.isVisible && !!q.isEditable;
export const isQuestionRequired = (q: Question) =>
  isQuestionApplicable(q) && (q.isRequired || q.isAnswered);
export const isQuestionAnswered = (q: Question) => q.isAnswered;
export const isQuestionAligned = (q: Question) => !!q.isAligned;
export const isQuestionNotAligned = (q: Question) => q.isAligned === false;
export const isQuestionSC = (q: Question) =>
  q.level == QuestionLevel.SubstantialContribution || !!q.levelExpression?.includes('SC');
export const isSCQuestionAligned = (q: Question) =>
  !isQuestionRequired(q) ||
  (isQuestionAligned(q) && q.level == QuestionLevel.SubstantialContribution);
export const isQuestionSecondary = (q: Question) => q.isSecondarySubstantialContributionObjective;
export const isQuestionNotSecondary = (q: Question) =>
  !q.isSecondarySubstantialContributionObjective;
export const isActivityInProgress = (act: ActivityReport) =>
  !act.questions.filter(isQuestionRequired).every(isQuestionAnswered);

export const isActivityCompleted = (act: ActivityReport) => {
  return !isActivityInProgress(act);
};
export const isDNSHQuestionAligned = (q: Question) =>
  q.level == QuestionLevel.SubstantialContribution || isQuestionAligned(q);

export const isObjectiveSCAligned = (questions: Question[]) => {
  const SCQuestions = questions.filter(isQuestionSC);
  return SCQuestions.length > 0 && SCQuestions.every(isSCQuestionAligned);
};

export const getAlignedSCObjectives = (activityReport: ActivityReport) => {
  const applicable = activityReport.questions.filter(isQuestionRequired);
  const dnshAligned = applicable.every(isDNSHQuestionAligned);
  if (!dnshAligned) return [];
  const alignedSCObjectives = Object.entries(groupBy(activityReport.questions, 'objective.key'))
    .filter(([_, objectiveQuestions]) => isObjectiveSCAligned(objectiveQuestions))
    .map(([objectiveKey]) => objectiveKey);
  return alignedSCObjectives;
};

const isActivityDoingAtLeastOneContribution = (alignedSCObjectives: string[]) =>
  alignedSCObjectives.length > 0;
export const isActivityAligned = (activityReport: ActivityReport) =>
  isActivityDoingAtLeastOneContribution(getAlignedSCObjectives(activityReport));
const isActivityOnlyAdaptationAligned = (alignedObjectives: string[]) =>
  alignedObjectives.includes('adaptation') && !alignedObjectives.find((o) => o !== 'adaptation');

const calculateScores = (
  eligible: Partial<BareFinancials>,
  aligned: BareFinancials,
  total: BareFinancials,
  inProgress: Partial<BareFinancials>
): TaxonomyScores =>
  // TODO: Should this reflect how big part of the company revenue the BU is? How to deal with the other ones then?
  ({
    ...getScores((attr) => ({
      total: 100,
      eligible: (eligible?.[attr] / total[attr]) * 100,
      aligned: (aligned?.[attr] / total[attr]) * 100,
      inProgress: (inProgress?.[attr] / total[attr]) * 100,
    })),
    isEstimate: aligned?.isEstimate || eligible.isEstimate || total.isEstimate || false,
  });

export const getBusinessUnitInProgressFinancials = (
  bAssessment: BAssessment,
  activityReference: ActivityFilterDef,
  isCompanyLevelGeneralAssessmentInProgress = false
) => {
  const generalInProgress = bAssessment.hasGeneralAssessment
    ? !bAssessment.generalQuestions.some(isQuestionNotAligned) &&
      bAssessment.generalQuestions.some((q) => !isQuestionAnswered(q))
    : isCompanyLevelGeneralAssessmentInProgress;
  return aggregateFinancials(
    businessUnitActivities(bAssessment, activityReference)
      .filter((a) => isActivityInProgress(a) || (generalInProgress && isActivityAligned(a)))
      .map(getApplicableInProgressFinancials)
  );
};

export const getBusinessUnitEligibleFinancials = (
  bAssessment: BAssessment,
  activityReference: ActivityFilterDef
) =>
  aggregateFinancials(
    businessUnitActivities(bAssessment, activityReference).map(
      (activityReport) => activityReport.supportedFinancials
    )
  );

export const getBusinessUnitSupportedFinancials = (
  bAssessment: BAssessment,
  activityReference: ActivityFilterDef
) =>
  aggregateFinancials(
    businessUnitActivities(bAssessment, activityReference).map(
      (activityReport): BareFinancials => ({
        revenue: activityReport.supportedFinancials.revenue - activityReport.financials.revenue,
        capex: activityReport.supportedFinancials.capex - activityReport.financials.capex,
        opex: activityReport.supportedFinancials.opex - activityReport.financials.opex,
        adaptationCapex: 0,
        adaptationOpex: 0,
        isEstimate:
          activityReport.supportedFinancials.isEstimate || activityReport.financials.isEstimate,
      })
    )
  );

export const getBusinessUnitScores = (
  bAssessment: BAssessment,
  activityReference: ActivityFilterDef,
  cAssessment: CompanyAssessment
) => {
  const {
    isCompanyGeneralAssessmentAligned,
    isCompanyGeneralAssessmentInProgress,
  } = getCompanyGeneralAssessmentStatus(cAssessment);

  const businessUnitSupportedFinancials = getBusinessUnitSupportedFinancials(
    bAssessment,
    activityReference
  );
  const businessUnitFinancials = aggregateFinancialsReducer(
    bAssessment.financials,
    businessUnitSupportedFinancials
  );
  const eligibleFinancials = getBusinessUnitEligibleFinancials(bAssessment, activityReference);
  const inProgressFinancials = getBusinessUnitInProgressFinancials(
    bAssessment,
    activityReference,
    isCompanyGeneralAssessmentInProgress
  );
  const alignedFinancials = getAlignedBusinessUnitFinancials(
    bAssessment,
    activityReference,
    isCompanyGeneralAssessmentAligned
  );

  const scores = calculateScores(
    eligibleFinancials,
    alignedFinancials,
    businessUnitFinancials,
    inProgressFinancials
  );
  return scores;
};

const getCompanyGeneralAssessmentStatus = (
  cAssessment: CompanyAssessment
): {
  isCompanyGeneralAssessmentAligned: boolean;
  isCompanyGeneralAssessmentInProgress: boolean;
} => {
  const companyLevelGeneralAssessment =
    cAssessment.bAssessments[COMPANY_LEVEL_GENERAL_ASSESSMENT_ID];
  const isCompanyGeneralAssessmentAligned = companyLevelGeneralAssessment.generalQuestions.every(
    isQuestionAligned
  );
  const isCompanyGeneralAssessmentInProgress =
    !companyLevelGeneralAssessment.generalQuestions.some(isQuestionNotAligned) &&
    companyLevelGeneralAssessment.generalQuestions.some((q) => !isQuestionAnswered(q));

  return { isCompanyGeneralAssessmentAligned, isCompanyGeneralAssessmentInProgress };
};

export const getCompanyScores = (
  cAssessment: CompanyAssessment,
  activityRef: ActivityFilterDef = ALL_ACTIVITIES
) => {
  const companyFinancials = cAssessment.financials || defaultFinancials;
  const {
    isCompanyGeneralAssessmentAligned,
    isCompanyGeneralAssessmentInProgress,
  } = getCompanyGeneralAssessmentStatus(cAssessment);

  const aggregateReports = (getFinancials: (reports: BAssessment) => BareFinancials) =>
    aggregateFinancials(Object.values(cAssessment.bAssessments).map(getFinancials));
  const eligibleFinancials = aggregateReports((bAssessment) =>
    getBusinessUnitEligibleFinancials(bAssessment, activityRef)
  );
  const alignedFinancials = aggregateReports((bAssessment) =>
    getAlignedBusinessUnitFinancials(bAssessment, activityRef, isCompanyGeneralAssessmentAligned)
  );
  const inProgressFinancials = aggregateReports((bAssessment) =>
    getBusinessUnitInProgressFinancials(
      bAssessment,
      activityRef,
      isCompanyGeneralAssessmentInProgress
    )
  );

  return calculateScores(
    eligibleFinancials,
    alignedFinancials,
    companyFinancials,
    inProgressFinancials
  );
};

const isMissingRequiredDocumentation = (q: Question) =>
  q.documentationRequired && q.answer?.attachments.length === 0;

export function calculateActivityCompletion(
  activityReport: ActivityReport,
  objectiveKey: Objective['key'] | null
) {
  const questions =
    activityReport?.questions
      ?.filter((q) => !objectiveKey || q?.objective?.key === objectiveKey)
      .filter(isQuestionRequired) ?? [];
  const completed = questions.filter(isQuestionAnswered);
  const missingDocumentation = questions.filter((q) => isMissingRequiredDocumentation(q));

  return {
    total: questions,
    aligned: completed.filter((q) => isQuestionAligned(q) || isQuestionSecondary(q)),
    completed,
    missingDocumentation,
  };
}

export function calculateBusinessUnitCompletion(
  bAssessment: BAssessment,
  objectiveKey: string,
  activityReferences: Activity['reference'][]
) {
  const activityCompletions = activityReferences
    .filter((ref) => (bAssessment.hasGeneralAssessment ? true : ref !== GENERAL_ACTIVITY_REF))
    .map((ref) =>
      bAssessment.activities[ref]
        ? calculateActivityCompletion(bAssessment.activities[ref], objectiveKey)
        : { total: [], aligned: [], completed: [] }
    );
  return {
    total: sumBy(activityCompletions, 'total.length'),
    completed: sumBy(activityCompletions, 'completed.length'),
    aligned: sumBy(activityCompletions, 'aligned.length'),
    missingDocumentation: sumBy(activityCompletions, 'missingDocumentation.length'),
    firstNotCompletedQuestion: activityCompletions
      .flatMap((a) => a.total)
      .find((q) => !q.isAnswered),
  };
}

export const CompletionStatus = {
  COMPLETED: tnoop('assessment:completion.completed'),
  NOT_COMPLETED: tnoop('assessment:businessUnitObjective.notCompleted'),
  ESTIMATED_FINANCIALS: tnoop('assessment:completion.estimatedFinancials'),
};

export function checkReportCompletion(cAssessment: CompanyAssessment) {
  const { isEstimate } = getCompanyScores(cAssessment);

  const completions = Object.values(cAssessment.bAssessments).map((bAssessment) => {
    const activityRefs = Object.keys(bAssessment.activities);
    return calculateBusinessUnitCompletion(bAssessment, '', activityRefs);
  });

  const isCompleted = completions.every(({ total, completed }) => total === completed);
  const completionStatus = getCompletionStatus(isCompleted, isEstimate);

  return { isCompleted, isEstimate, completionStatus };
}

export const getPortfolioTotal = (portfolioCompanies: PortfolioCompany[]) => {
  const total = portfolioCompanies.reduce((sum, { amount }) => sum + amount, 0);
  return total;
};

export const getAggregatePortfolioScores = (portfolioCompanies: PortfolioCompany[]) => {
  const total = getPortfolioTotal(portfolioCompanies);
  const sectionScores = scoreSections.reduce(
    (
      scoresMap: { [key: string]: { eligible: number; aligned: number; inProgress: number } },
      section
    ) => {
      const aggregateEligible = portfolioCompanies.reduce(
        (agg, { amount, scores, estimateCompany, company }) => {
          const useableScore = !company ? estimateCompany?.scores : scores;
          return agg + (amount / total) * (useableScore[section]?.eligible ?? 0);
        },
        0
      );

      const aggregateAligned = portfolioCompanies.reduce(
        (agg, { amount, scores, estimateCompany, company }) => {
          const useableScore = !company ? estimateCompany?.scores : scores;
          return agg + (amount / total) * (useableScore[section]?.aligned ?? 0);
        },
        0
      );

      const aggregateInprogress = portfolioCompanies.reduce(
        (agg, { amount, scores, estimateCompany, company }) => {
          const useableScore = !company ? estimateCompany?.scores : scores;
          return agg + (amount / total) * (useableScore[section]?.inProgress ?? 0);
        },
        0
      );

      scoresMap[section] = {
        aligned: aggregateAligned,
        eligible: aggregateEligible,
        inProgress: aggregateInprogress,
      };
      return scoresMap;
    },
    {}
  );

  return sectionScores;
};

export const getNotAlignedScore = (eligible: number, aligned: number, inProgress: number) => {
  return eligible - (aligned + inProgress);
};

export const getNotEligibleScore = (eligible: number) => {
  return 100 - eligible;
};

export const expandTaxonomyScore = (score: TaxonomyScore) => {
  const { eligible, aligned, inProgress = 0 } = score;
  const notAligned = getNotAlignedScore(eligible, aligned, inProgress);
  const notEligible = getNotEligibleScore(eligible);
  return { ...score, notAligned, notEligible };
};

export const getCompletionStatus = (isCompleted: boolean, isEstimate?: boolean) => {
  let completionStatus = CompletionStatus.COMPLETED;
  if (isEstimate) completionStatus = CompletionStatus.ESTIMATED_FINANCIALS;
  if (!isCompleted) completionStatus = CompletionStatus.NOT_COMPLETED;
  return completionStatus;
};

export const getRelatedBAssessments = (
  bAssessments: BAssessment[],
  activityReference: ActivityFilterDef
) => {
  return bAssessments.filter((bu) => !!bu.activities[activityReference]);
};

export const calculateCompanyActivityCompletion = (
  cAssessment: CompanyAssessment,
  relatedBusinessUnitsIds: BusinessUnit['id'][],
  activityReference: ActivityFilterDef
) => {
  const { isEstimate } = getCompanyScores(cAssessment);
  const completions = Object.values(cAssessment.bAssessments)
    .filter((bAssessment) => relatedBusinessUnitsIds.includes(bAssessment.businessUnit.id))
    .map((bAssessment) => {
      return calculateBusinessUnitCompletion(bAssessment, '', [activityReference]);
    });
  const isCompleted = completions.every(({ total, completed }) => total === completed);
  const completionStatus = getCompletionStatus(isCompleted, isEstimate);
  return { isCompleted, isEstimate, completionStatus };
};

export const calculateTotalActivityFinancials = (
  relatedBAssessments: BAssessment[],
  activityRef: ActivityFilterDef
) => {
  return aggregateFinancials(
    relatedBAssessments.map((bAssessment) => bAssessment.activities[activityRef].financials)
  );
};

export const getActivityScore = (
  cAssessment: CompanyAssessment,
  relatedBusinessUnitsIds: BusinessUnit['id'][],
  activityRef: ActivityFilterDef
) => {
  const {
    isCompanyGeneralAssessmentAligned,
    isCompanyGeneralAssessmentInProgress,
  } = getCompanyGeneralAssessmentStatus(cAssessment);

  const relatedBAssessments = Object.values(cAssessment.bAssessments)
    .filter((bAssessment) => relatedBusinessUnitsIds.includes(bAssessment.businessUnit.id))
    .filter((relatedBAssessment) => relatedBAssessment.activities[activityRef]);
  const activityTotalFinancials = calculateTotalActivityFinancials(
    relatedBAssessments,
    activityRef
  );
  const eligibleFinancials = aggregateFinancials(
    relatedBAssessments.map((bAssessment) =>
      getBusinessUnitEligibleFinancials(bAssessment, activityRef)
    )
  );

  const alignedFinancials = aggregateFinancials(
    relatedBAssessments.map((bAssessment) =>
      getAlignedBusinessUnitFinancials(bAssessment, activityRef, isCompanyGeneralAssessmentAligned)
    )
  );

  const inProgressFinancials = aggregateFinancials(
    relatedBAssessments.map((bAssessment) =>
      getBusinessUnitInProgressFinancials(
        bAssessment,
        activityRef,
        isCompanyGeneralAssessmentInProgress
      )
    )
  );

  return calculateScores(
    eligibleFinancials,
    alignedFinancials,
    activityTotalFinancials as BareFinancials,
    inProgressFinancials
  );
};

export const getIncompleteBusinessUnitsByActivity = (
  cAssessment: CompanyAssessment,
  relatedBusinessUnitsIds: BusinessUnit['id'][],
  activityRef: ActivityFilterDef
) => {
  const relatedBAssessments = Object.values(cAssessment.bAssessments).filter((bAssessment) =>
    relatedBusinessUnitsIds.includes(bAssessment.businessUnit.id)
  );

  return relatedBAssessments
    .filter((bu) => {
      const { completed, total } = calculateBusinessUnitCompletion(bu, '', [activityRef]);
      return completed < total;
    })
    .map((bAssessment) => bAssessment.businessUnit.id);
};

export const isFinancialSectionAligned = (score: TaxonomyScore) =>
  !expandTaxonomyScore(score).notAligned;

export const isScoreAligned = (scores: TaxonomyScores) => {
  const { revenue, capex, opex } = scores;
  return (
    isFinancialSectionAligned(revenue) &&
    isFinancialSectionAligned(capex) &&
    isFinancialSectionAligned(opex)
  );
};
