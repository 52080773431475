import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  HStack,
  ModalHeaderProps,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { isChromatic } from 'utils/isChromatic';
import { gtmTrack } from 'utils/gtm';

export default function DefaultDrawer({
  isOpen,
  onClose,
  children,
  header,
  footer,
  isClosable = true,
  hasOverlay = true,
  placement = 'right',
  name,
  headerProps,
  ...props
}: {
  header?: JSX.Element | string;
  footer?: JSX.Element | string;
  isClosable?: boolean | 'no-button';
  hasOverlay?: boolean;
  name?: string;
  headerProps?: ModalHeaderProps;
} & DrawerProps) {
  useEffect(() => {
    if (name && isOpen) gtmTrack('drawer_open', { name });
  }, [name, isOpen]);

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={!!isClosable}
      closeOnOverlayClick={!!isClosable}
      placement={placement}
      autoFocus={!isChromatic()}
      {...props}
    >
      {hasOverlay && <DrawerOverlay />}
      <DrawerContent>
        <DrawerHeader maxWidth="100%" {...headerProps} justifyContent="space-between">
          <HStack> {header}</HStack>
          <HStack> {isClosable && isClosable != 'no-button' && <DrawerCloseButton />}</HStack>
        </DrawerHeader>

        <DrawerBody>{children}</DrawerBody>
        {footer && (
          <DrawerFooter boxShadow="0px 0px 32px rgba(0, 0, 0, 0.08)">{footer}</DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  );
}
