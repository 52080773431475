import { uniq, find } from 'lodash';
import { QuestionType } from './general';
import { HQuestionFieldsFragment } from './general.graphql';
import { parseExpression, Question } from './question-model';
import { getQuestionsProgress } from 'components/question/QuestionListMenu';

export function isMultipleCriteria<T extends Pick<Question, 'type'>>(q: T) {
  return q.type == QuestionType.MultipleCriteria;
}
export type MultipleCriteriaProps = {
  dependencies: { table: { [option: string]: string[] }; questionSets: string[] };
  subQuestions: {
    title: string;
    questions: Question[];
    questionSet: string;
    progress: { progress: number; notAligned: number };
  }[];
};
function sortDependencies(dependencies: Question['dependencies'], choices: Question['choices']) {
  const sortedDependencies = {
    questionSets: choices
      .filter((ch) => dependencies.questionSets.includes(ch.value))
      .map((ch) => ch.value),
    table: {},
  } as Question['dependencies'];
  choices.forEach((ch) => {
    if (dependencies.table[ch.value])
      sortedDependencies.table[ch.displayName] = sortedDependencies.questionSets.filter((qs) =>
        dependencies.table[ch.value].includes(qs)
      );
  });
  return sortedDependencies;
}
export function transformMultipleCriteriaQuestion(question: Question, questions: Question[]) {
  if (isMultipleCriteria(question)) {
    const dependencies = sortDependencies(question.dependencies, question.choices);
    const subQuestions = dependencies.questionSets.map((qsRef) => {
      const subquestions = questions.filter((q) => q.questionSetRef == qsRef);
      const progress = getQuestionsProgress(subquestions);
      subquestions.forEach((sq) => {
        sq.objective = question.objective;
        if (question.isAligned) sq.isRequired = false;
      });
      const title = find(question.choices, { value: qsRef })?.displayName || '';
      return { questions: subquestions, title, questionSet: qsRef, progress };
    });
    if (question.isAligned === false) {
      const allSubquestionsAnswered = subQuestions.every(
        (sq) => sq.progress.notAligned || sq.progress.progress == 1.0
      );
      if (!allSubquestionsAnswered) {
        question.isAligned = null;
        question.isAnswered = false;
      }
    }
    return {
      ...question,
      dependencies,
      subQuestions,
    };
  }
  return question;
}

export function removeSubquestions(questions: Question[]) {
  const toRemove = questions.flatMap((q) =>
    q.subQuestions.flatMap((sQ) => sQ.questions.map((qq) => qq.id))
  );
  return questions.filter((q) => !toRemove.includes(q.id));
}

function extractDependencies(q: HQuestionFieldsFragment) {
  if (q.type == QuestionType.MultipleCriteria) {
    const expr = parseExpression(q.isAligned || 'TRUE');
    const deps = {} as { [option: string]: string[] };
    const unknown = expr.variables();
    const vars = Object.fromEntries(unknown.map((name) => [name, ''])) as any;
    vars.allQuestionSetsAligned = (questionsets: string[], option: string) => {
      deps[option] = questionsets;
      return false;
    };
    expr.evaluate(vars);
    return deps;
  }
  return {};
}
export function getSubquestionProgress(questions: Question[]) {
  const result = { total: 0, notAligned: 0, answered: 0 };
  questions.forEach((q) => {
    q.subQuestions.forEach(({ questions: subQuestions }) => {
      // we cannot use q.subQuestions.progress because it is calculated before making
      // questions not required after criteria
      const progress = getQuestionsProgress(subQuestions);
      result.total += progress.total;
      result.answered += progress.answered;
      if (!q.isAligned) {
        result.notAligned += progress.notAligned * progress.total;
      }
    });
  });
  return result;
}
function withDependencies(q: HQuestionFieldsFragment) {
  const table = extractDependencies(q);
  return { ...q, dependencies: { table, questionSets: uniq(Object.values(table).flat()) } };
}
export function sortedWithDependencies(questions: HQuestionFieldsFragment[]) {
  return questions.map(withDependencies).sort((q1, q2) => {
    return q1.dependencies.questionSets.length - q2.dependencies.questionSets.length;
  });
}

export const filterSubquestions = (filter: (q: Question) => boolean) => (q: Question) =>
  isMultipleCriteria(q)
    ? q.subQuestions.some(({ questions }) => questions.some(filter))
    : filter(q);
