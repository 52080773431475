import { MutationTuple } from '@apollo/client';
import { useQuerySuspense } from 'hooks/useQuerySuspense';
import { mapValues, groupBy, keyBy, pick, debounce, sortBy } from 'lodash';
import { validate } from 'uuid';

import React, { useCallback, useMemo, useEffect, useState, useRef, useContext } from 'react';
import {
  HActivityReport_Constraint,
  HActivityReport_Insert_Input,
  HFinancials_Constraint,
  HFinancials_Update_Column,
  HActivityReport_Update_Column,
  HBusinessUnitAssessment,
  HBusinessUnitAssessment_Insert_Input,
  HBusinessUnitAssessment_Constraint,
  HBusinessUnitAssessment_Update_Column,
  HCompanyAssessmentAggregate_Constraint,
  HCompanyAssessmentAggregate_Update_Column,
  HCompanyAssessment_Insert_Input,
} from 'schema';

import { HQuestionFieldsFragment, HQuestionSetFieldsFragment } from './general.graphql';
import {
  HActivityReportFieldsFragment,
  HFinancialsFieldsFragment,
  HBusinessUnitAssessmentQuery,
  HBusinessUnitAssessmentFieldsFragment,
  HCompanyAssessmentQuery,
  HCompanyAssessmentFieldsFragment,
  HBusinessUnitAssessmentQueryVariables,
  useUpdateCompanyAssessmentMutation,
  HCompanyAssessmentQueryVariables,
  CompanyAssessmentDocument,
  useUpsertCompanyAssessmentMutation,
  useBusinessUnitAssessmentAnswersSubscription,
  useUpsertActivityReportsMutation,
  BusinessUnitActivitiesDocument,
  HBusinessUnitActivitiesQuery,
  HBusinessUnitActivitiesQueryVariables,
  useDeleteCompanyAssessmentMutation,
  useRemoveBusinessUnitFromCAssessmentMutation,
  BusinessUnitAssessmentDocument,
  useUpdateBusinessUnitAssessmentMutation,
} from './assessment.graphql';

import {
  ALL_QUESTIONS,
  AnswerSet,
  Question,
  resolveCondition,
  transformQuestions,
} from './question-model';
import { useCurrentCompanyId } from './localState';
import { checkReportCompletion, CompletionStatus, isActivityCompleted } from 'utils/score';
import { AppError } from 'components/Suspense';
import {
  escapeKey,
  EU_DATA_NAMESPACE,
  translateField,
  translateFields,
  useENBasedTranslation,
  TFunction,
  useEUDataTranslation,
} from 'utils/i18n';
import { setTimeoutInHook } from 'utils/setTimeoutInHook';
import { Activity, Company, omitMeta, Tuple, CompanyByIdDocument } from 'models';
import useCompany from 'hooks/useCompany';
import { useLatest } from 'react-use';
import { dateToString, stringToDate } from 'utils/dates';
import { add } from 'date-fns';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from '../utils/mixpanel';

export {
  useRemoveBusinessUnitFromCAssessmentMutation,
  BusinessUnitAssessmentDocument,
  CompanyAssessmentDocument,
};
export const COMPANY_LEVEL_GENERAL_ASSESSMENT_ID = 'company-general-questions';
export const GENERAL_BUSINESS_UNIT = {
  name: 'Company Overview',
  id: COMPANY_LEVEL_GENERAL_ASSESSMENT_ID,
  createdAt: Date.now().toString(),
  labels: [],
  contactPerson: {},
};

export enum ActivityTagEnum {
  enabling = 'ENABLING',
  transitional = 'TRANSITIONAL',
  green = 'GREEN',
}

export const DEFAULT_ACTIVITY_TAG = ActivityTagEnum.green;

export const GENERAL_ACTIVITY_REF = '0.0';
export interface Financials extends Omit<HFinancialsFieldsFragment, 'id'> {
  id?: HFinancialsFieldsFragment['id'];
}

export const SCORE_SECTIONS = [
  {
    key: 'revenue',
    isRequired: true,
    parent: null,
    tooltip: null,
  },
  {
    key: 'capex',
    isRequired: true,
    parent: null,
    tooltip: null,
  },
  {
    key: 'opex',
    isRequired: true,
    parent: null,
    tooltip: null,
  },
] as const;

export const FINANCIAL_SECTIONS = [
  ...SCORE_SECTIONS,
  {
    key: 'adaptationCapex',
    isRequired: false,
    parent: 'capex',
    tooltip: true,
  },
  {
    key: 'adaptationOpex',
    isRequired: false,
    parent: 'opex',
    tooltip: true,
  },
] as const;

export const allFinancialSections = FINANCIAL_SECTIONS.map(({ key }) => key);
export const scoreSections = SCORE_SECTIONS.map(({ key }) => key);

export interface ActivityReport
  extends Omit<HActivityReportFieldsFragment, 'financials' | 'questions'> {
  financials: Financials;
  supportedFinancials: Financials;
  questions: Question[];
  activityRef: Activity['reference'];
  name: Activity['name'];
  businessUnitId: BAssessment['businessUnit']['id']; //Backwards compatibility
  tag: ActivityTagEnum;
}
type NonNullableFields<T, F extends keyof T> = Omit<T, F> & { [K in F]-?: NonNullable<T[K]> };
function withNonNullField<T, F extends keyof T>(arr: T[], field: F) {
  return (arr.filter((obj: T) => obj[field] != null) as unknown[]) as NonNullableFields<T, F>[];
}

export interface BAssessment
  extends Pick<
    NonNullableFields<HBusinessUnitAssessmentFieldsFragment, 'businessUnit'>,
    'id' | 'businessUnit' | 'cAssessmentId' | 'deletedAt' | 'hasGeneralAssessment'
  > {
  financials: Financials;
  generalQuestions: Question[];
  activities: {
    [activityRef: string]: ActivityReport;
  };
  _questionSets?: QuestionByActivity;
}
export type BAssessmentProp = { bAssessment: BAssessment };

export interface CompanyAssessment
  extends Omit<
    HCompanyAssessmentFieldsFragment,
    'bAssesssments' | 'deletedBAssessments' | 'aggregate'
  > {
  title: string;

  financials: Financials;
  bAssessments: {
    [businessUnitId: string]: BAssessment;
  };
  deletedBAssessments: {
    [businessUnitId: string]: BAssessment;
  };
  timePeriod: string;
  contactPerson: HCompanyAssessmentFieldsFragment['aggregate']['contactPerson'];
  aggregateId: HCompanyAssessmentFieldsFragment['aggregate']['id'];
  companyId: HCompanyAssessmentFieldsFragment['aggregate']['company']['id'];
}
export type CompanyAssessmentProp = {
  cAssessment: CompanyAssessment;
};
interface QuestionByActivity {
  [activityRef: string]: HQuestionFieldsFragment[];
}
export const ActivityReportOnConflict = {
  constraint: HActivityReport_Constraint.ActivityReportBAssessmentIdActivityRef_614b7372Uniq,
  update_columns: [
    HActivityReport_Update_Column.DeletedAt,
    HActivityReport_Update_Column.CompletedAt,
  ],
};

export const createFinancials = (
  capex = 0,
  opex = 0,
  revenue = 0,
  adaptationCapex = 0,
  adaptationOpex = 0,
  isEstimate = true
) => ({
  capex,
  opex,
  revenue,
  adaptationCapex,
  adaptationOpex,
  isEstimate,
});
const qsNamespace = (reference: string) => `db/qs/${escapeKey(reference)}`;
function translateQuestion(
  q: HBusinessUnitAssessmentQuery['questionSets'][number]['questions'][number],
  t: TFunction
) {
  const prefix = `${qsNamespace(q.questionSetRef)}:${q.uniqueId}`;
  const newQ = translateFields(
    q,
    [
      'title',
      'description',
      'alignmentErrorMessage',
      'dependencyDescription',
      'extendedDescription',
      'aboutDocumentation',
    ],
    t,
    prefix
  );
  newQ.choices = q.choices.map((ch) =>
    translateField(ch, 'displayName', t, `${prefix}.choices.${ch.value}`)
  );
  return newQ as typeof q;
}
export function translateQuestionSets(
  questionSets: HBusinessUnitAssessmentQuery['questionSets'],
  t: TFunction
) {
  return questionSets.map((qs) => ({
    ...qs,
    questions: qs.questions.map((q) => translateQuestion(q, t)),
  }));
}
export function translateBusinessUnit(
  bu: NonNullable<HBusinessUnitAssessment['businessUnit']>,
  _t: TFunction
) {
  return {
    ...bu,
    // businessUnitActivities: bu.businessUnitActivities.map((bua) => ({
    //   ...bua,
    //   activity: translateFields(
    //     bua.activity,
    //     ['name', 'description'],
    //     t,
    //     `${EU_DATA_NAMESPACE}:activity.${escapeKey(bua.activity.reference)}`
    //   ),
    // })),
  };
}

export function resolveActivityTag(
  condition: string,
  answers: AnswerSet,
  allQuestions: Question[] = []
) {
  const vars = { [ALL_QUESTIONS]: allQuestions };
  return resolveCondition(condition, answers, vars);
}

function createBAssessment(
  bAssessment: NonNullableFields<HBusinessUnitAssessmentFieldsFragment, 'businessUnit'>,
  questionsByActivity: QuestionByActivity,
  t: TFunction
): BAssessment {
  const businessUnit = bAssessment.businessUnit;
  const businessUnitId = businessUnit?.id || COMPANY_LEVEL_GENERAL_ASSESSMENT_ID;
  const activityReports = sortBy(
    bAssessment.activityReports,
    (ar) => (ar.activity.reference == GENERAL_ACTIVITY_REF ? -1 : 0), // Just to be sure it is always first
    'createdAt'
  );
  const generalAnswers =
    activityReports.find((ar) => ar.activity.reference == GENERAL_ACTIVITY_REF)?.answers ?? [];

  const activities = keyBy(
    activityReports.map((ar) => {
      const financials = ar.financials ?? createFinancials();
      const { resolvedQuestions, answerSet } = transformQuestions(
        questionsByActivity[ar.activity.reference] ?? [],
        [...generalAnswers, ...ar.answers],
        ar.activity.reference
      );
      const activity = translateFields(
        ar.activity,
        ['name'],
        t,
        `${EU_DATA_NAMESPACE}:activity.${escapeKey(ar.activity.reference)}`
      );
      return {
        ...ar,
        activity,
        name: activity.name,
        activityRef: activity.reference,
        businessUnitId,
        financials,
        supportedFinancials: financials,
        // supportedFinancials: distributionKeys.reduce<Financials>((previous, s) => {
        //   const supportingFinancials =
        //     s.supportingBusinessUnit?.activityReports[0]?.financials ?? createFinancials();
        //   return {
        //     revenue: previous.revenue + (s.revenuePercentage / 100) * supportingFinancials.revenue,
        //     isEstimate: previous.isEstimate,
        //     capex: previous.capex + (s.capexPercentage / 100) * supportingFinancials.capex,
        //     opex: previous.opex + (s.opexPercentage / 100) * supportingFinancials.opex,
        //   };
        // }, financials),
        questions: resolvedQuestions,
        tag: resolveActivityTag(
          activity.tagExpression || DEFAULT_ACTIVITY_TAG,
          answerSet,
          resolvedQuestions
        ),
      };
    }),
    'activityRef'
  );
  const generalActivity = activities[GENERAL_ACTIVITY_REF];
  return {
    ...bAssessment,
    businessUnit,
    generalQuestions: generalActivity?.questions ?? [],
    financials: generalActivity?.financials ?? createFinancials(),
    activities,
    _questionSets: questionsByActivity,
  };
}
export function transformCompanyAssessment(
  cAssessment: NonNullable<HCompanyAssessmentQuery['cAssessment']>,
  questionByActivity: QuestionByActivity = {},
  t: TFunction
): CompanyAssessment {
  const { bAssesssments, deletedBAssessments, aggregate, ...rest } = cAssessment;
  const businessUnits = withNonNullField(bAssesssments, 'businessUnit');
  const businessUnitsWithDeletedAssessment = withNonNullField(deletedBAssessments, 'businessUnit');
  const rawGeneralAssessment = bAssesssments?.find((bA) => bA.businessUnit === null);
  const generalAssessment = createBAssessment(
    {
      ...rawGeneralAssessment,
      hasGeneralAssessment: true,
      businessUnit: {
        ...GENERAL_BUSINESS_UNIT,
        contactPerson: cAssessment.aggregate.contactPerson,
      },
    } as NonNullableFields<HBusinessUnitAssessmentFieldsFragment, 'businessUnit'>,
    questionByActivity,
    t
  );

  return {
    ...rest,
    title: aggregate.title,
    aggregateId: aggregate.id,
    companyId: aggregate.company.id,
    timePeriod: `${rest.startDate} - ${rest.endDate}`,
    financials:
      bAssesssments.find((bA) => bA.businessUnit == null)?.activityReports[0]?.financials ??
      createFinancials(),
    bAssessments: {
      ...mapValues(keyBy(businessUnits, 'businessUnit.id'), (bA) =>
        createBAssessment(bA, questionByActivity, t)
      ),
      [COMPANY_LEVEL_GENERAL_ASSESSMENT_ID]: generalAssessment,
    },
    contactPerson: aggregate.contactPerson,
    deletedBAssessments: mapValues(
      keyBy(businessUnitsWithDeletedAssessment, 'businessUnit.id'),
      (bA) => createBAssessment(bA, questionByActivity, t)
    ),
  };
}
export function getAllActivityReports(cAssessment: CompanyAssessment, withGeneral = true) {
  return Object.values(cAssessment.bAssessments)
    .flatMap((r) => Object.values(r.activities))
    .filter((a) => withGeneral || a.activityRef != GENERAL_ACTIVITY_REF);
}

export const getQuestionsByActivity = (
  questionSets: HQuestionSetFieldsFragment[]
): QuestionByActivity =>
  mapValues(
    groupBy(
      questionSets
        .map((qs) =>
          qs.activityQuestionSets.map((act) => ({
            activityRef: act.activityRef,
            questions: qs.questions,
          }))
        )
        .flat(),
      'activityRef'
    ),
    (qss) => qss.flatMap((qs) => qs.questions)
  );

export function useQuestionSetsTranslation(
  questionSets: HBusinessUnitAssessmentQuery['questionSets']
) {
  const { t, ...trans } = useENBasedTranslation(
    [EU_DATA_NAMESPACE, ...questionSets.map((qs) => qsNamespace(qs.reference))],
    { useSuspense: false }
  );
  return { t, ...trans };
}
export const AssessmentContext = React.createContext<CompanyAssessment | null>(null);

export function useCurrentCompanyAssessment() {
  const report = useContext(AssessmentContext);
  if (!report) throw new Error('Company report context not set!');
  return report;
}
export function useGetBAssessment(
  businessUnitId: HBusinessUnitAssessment['businessUnitId'],
  cAssessmentId: CompanyAssessment['id']
) {
  const cAssessment = useContext(AssessmentContext);
  const bAssessment =
    cAssessment?.id === cAssessmentId && cAssessment?.bAssessments[businessUnitId];

  const { bAssessments, questionSets } = useQuerySuspense<
    HBusinessUnitAssessmentQuery,
    HBusinessUnitAssessmentQueryVariables
  >(BusinessUnitAssessmentDocument, {
    variables: {
      cAssessmentId,
      businessUnitId,
    },
    skip: !!bAssessment || !validate(cAssessmentId),
  });
  const { t, ready } = useQuestionSetsTranslation(questionSets ?? []);
  return useMemo(() => {
    if (bAssessment) return bAssessment;
    const rawBAssessment = bAssessments?.[0];
    if (rawBAssessment == null || rawBAssessment.businessUnit == null)
      throw Error('No such business unit!');

    const questionsByActivity = getQuestionsByActivity(translateQuestionSets(questionSets, t));
    return createBAssessment(
      rawBAssessment as NonNullableFields<typeof rawBAssessment, 'businessUnit'>,
      questionsByActivity,
      t
    );
  }, [bAssessment, bAssessments, questionSets, t, ready]);
}

const BLOCK_REMOTE_UPDATES_TIMEOUT = 3000; // We will receive subscription updates from our own actions
export function useBusinessUnitAssessment(bAssessment: BAssessment) {
  const cAssessment = useContext(AssessmentContext);
  const [newestReport, setNewestReport] = useState<BAssessment>(bAssessment);
  const { data } = useBusinessUnitAssessmentAnswersSubscription({
    variables: {
      bAssessmentId: bAssessment.id,
    },
  });
  const holdRemoteUpdates = useRef<((r: BAssessment) => BAssessment) | boolean>(false);
  useEffect(() => {
    // report modified by local modification
    setNewestReport(bAssessment);
    if (!holdRemoteUpdates.current) holdRemoteUpdates.current = true;
    return setTimeoutInHook(() => {
      if (holdRemoteUpdates.current && holdRemoteUpdates.current != true) {
        setNewestReport(holdRemoteUpdates.current);
      }
      holdRemoteUpdates.current = false;
    }, BLOCK_REMOTE_UPDATES_TIMEOUT);
  }, [bAssessment]);
  const { t } = useENBasedTranslation([EU_DATA_NAMESPACE]);
  useEffect(() => {
    // Answers modified by someone else
    if (data && data.bAssessment) {
      const updateFunc = (curReport: BAssessment) => {
        if (curReport._questionSets) {
          if (!data.bAssessment?.businessUnit) {
            return createBAssessment(
              {
                ...data.bAssessment,
                businessUnit: {
                  ...GENERAL_BUSINESS_UNIT,
                  contactPerson: cAssessment?.contactPerson,
                },
              } as NonNullableFields<typeof data.bAssessment, 'businessUnit'>,
              curReport._questionSets,
              t
            );
          }
          return createBAssessment(
            data.bAssessment as NonNullableFields<typeof data.bAssessment, 'businessUnit'>,
            curReport._questionSets,
            t
          );
        }
        return curReport;
      };
      if (holdRemoteUpdates.current) {
        holdRemoteUpdates.current = updateFunc;
      } else {
        setNewestReport(updateFunc);
      }
    }
  }, [data?.bAssessment]);
  return newestReport;
}

function useUpdateCompanyAssessmentCompletness(companyAssessment: CompanyAssessment | null) {
  const [updateCompanyAssessment] = useUpdateCompanyAssessmentMutation();
  const updateCompletness = useMemo(
    () =>
      debounce((cAssessment: CompanyAssessment) => {
        const toUpdate = [] as HActivityReport_Insert_Input[];
        getAllActivityReports(cAssessment).forEach((a) => {
          if (a.id) {
            const completedAt =
              isActivityCompleted(a) && !a.financials.isEstimate ? a.completedAt ?? 'now()' : null;

            if (completedAt != a.completedAt)
              toUpdate.push({
                ...pick(a, 'activityRef', 'bAssessmentId'),
                completedAt,
              });
          }
        });
        const completedAt =
          checkReportCompletion(cAssessment).completionStatus == CompletionStatus.COMPLETED
            ? cAssessment.completedAt ?? 'now()'
            : null;
        if (completedAt != cAssessment.completedAt || toUpdate.length) {
          updateCompanyAssessment({
            variables: {
              id: cAssessment.id,
              fields: { completedAt },
              activityReports: toUpdate,
            },
          });
        }
      }, 1000),
    [updateCompanyAssessment]
  );
  useEffect(() => {
    if (companyAssessment) {
      updateCompletness(companyAssessment);
    }
  }, [companyAssessment, updateCompletness]);
}

export function useFindCompanyAssessment(
  cAssessmentId: CompanyAssessment['id']
): CompanyAssessment | null {
  const currentCompanyId = useCurrentCompanyId();
  const { cAssessment, questionSets } = useQuerySuspense<
    HCompanyAssessmentQuery,
    HCompanyAssessmentQueryVariables
  >(CompanyAssessmentDocument, {
    variables: {
      cAssessmentId,
    },
    skip: !validate(cAssessmentId),
  });
  const { t } = useQuestionSetsTranslation(questionSets ?? []);
  const report = useMemo(() => {
    if (cAssessment == null) return null;
    if (cAssessment.companyId !== currentCompanyId) return null;
    const questionsByActivity = getQuestionsByActivity(
      translateQuestionSets(questionSets ?? [], t)
    );
    return transformCompanyAssessment(cAssessment, questionsByActivity, t);
  }, [cAssessment, questionSets, currentCompanyId, t]);
  useUpdateCompanyAssessmentCompletness(report);
  return report;
}

export const COMPANY_REPORT_NOT_FOUND = 'CompanyAssessment not found';

export function useCompanyAssessmentSkippable(id?: string) {
  if (!id) return undefined;
  const cAssessment = useFindCompanyAssessment(id);
  return cAssessment ?? undefined;
}
export function useCompanyAssessment(id?: CompanyAssessment['id']): CompanyAssessment {
  const cAssessment = useFindCompanyAssessment(id);
  const cAssessmentFromContext = useContext(AssessmentContext);
  if (id && cAssessment) return cAssessment;
  if (!id && cAssessmentFromContext) return cAssessmentFromContext;
  throw new AppError(COMPANY_REPORT_NOT_FOUND, id);
}

function wrap<TData, TVariables>(mutation: MutationTuple<TData, TVariables>) {
  return { mutate: mutation[0], ...mutation[1], isLoading: mutation[1].loading };
}

export interface CompanyAssessmentUpdate extends Partial<Omit<CompanyAssessment, 'bAssessments'>> {
  title: string;
  timePeriod: string;
  contactPersonId?: string;
  bAssessments: Record<
    string,
    Partial<
      Pick<
        CompanyAssessment['bAssessments'][string],
        'financials' | 'deletedAt' | 'hasGeneralAssessment'
      > & {
        activities: Record<
          string,
          Pick<ActivityReport, 'financials' | 'activityRef' | 'completedAt'>
        >;
      }
    >
  >;
}
export const FinancialsOnConflict = {
  constraint: HFinancials_Constraint.FinancialsReportId_801651ecUniq,
  update_columns: [
    HFinancials_Update_Column.Capex,
    HFinancials_Update_Column.Opex,
    HFinancials_Update_Column.Revenue,
    HFinancials_Update_Column.AdaptationCapex,
    HFinancials_Update_Column.AdaptationOpex,
    HFinancials_Update_Column.IsEstimate,
  ],
};
export function useSaveCompanyAssessment() {
  const upsertCompanyAssessment = wrap(useUpsertCompanyAssessmentMutation());
  const wrapped = useCallback(
    (cAssessment: CompanyAssessmentUpdate) => {
      if (cAssessment.financials) {
        if (cAssessment.bAssessments[COMPANY_LEVEL_GENERAL_ASSESSMENT_ID]) {
          cAssessment.bAssessments[COMPANY_LEVEL_GENERAL_ASSESSMENT_ID].financials =
            cAssessment.financials;
        }
      }
      const bAssessments = Object.entries(cAssessment.bAssessments) as [
        string | null,
        CompanyAssessmentUpdate['bAssessments'][string]
      ][];

      const startDate = cAssessment.startDate ?? new Date().toISOString();
      const endDate = add(stringToDate(cAssessment.startDate), {
        years: 1,
      });

      const cAssessmentData = {
        startDate: startDate,
        endDate: dateToString(endDate),
        completedAt: cAssessment.completedAt,
        companyId: cAssessment.companyId,
      };
      const bAssessmentsInsert: HBusinessUnitAssessment_Insert_Input[] = bAssessments.map(
        ([businessUnitId, bAssessment]) => ({
          businessUnitId: businessUnitId === GENERAL_BUSINESS_UNIT.id ? null : businessUnitId,
          deletedAt: bAssessment.deletedAt,
          hasGeneralAssessment: bAssessment.hasGeneralAssessment,
          activityReports: {
            data: Object.entries(bAssessment.activities ?? {}).map(
              ([activityRef, { financials: actFinancials, completedAt }]) => ({
                activityRef,
                completedAt,
                financials: {
                  data: omitMeta(
                    activityRef === GENERAL_ACTIVITY_REF ? bAssessment.financials : actFinancials
                  ),
                  on_conflict: FinancialsOnConflict,
                },
              })
            ),
            on_conflict: ActivityReportOnConflict,
          },
        })
      );
      return upsertCompanyAssessment
        .mutate({
          variables: {
            cAssessment: {
              id: cAssessment.id || undefined,
              ...cAssessmentData,
              aggregate: {
                data: {
                  id: cAssessment.aggregateId,
                  title: cAssessment.title,
                  interval: 'yearly',
                  startDate: cAssessmentData.startDate,
                  companyId: cAssessment.companyId,
                  contactPersonId: cAssessment.contactPersonId,
                },
                on_conflict: {
                  constraint: HCompanyAssessmentAggregate_Constraint.CompanyAssessmentAggregatePkey,
                  update_columns: [
                    HCompanyAssessmentAggregate_Update_Column.Interval,
                    HCompanyAssessmentAggregate_Update_Column.Title,
                    HCompanyAssessmentAggregate_Update_Column.ContactPersonId,
                  ],
                },
              },
              bAssesssments: {
                data: bAssessmentsInsert,
                on_conflict: {
                  constraint:
                    HBusinessUnitAssessment_Constraint.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U,
                  update_columns: [HBusinessUnitAssessment_Update_Column.DeletedAt],
                },
              },
            },
          },
          refetchQueries: ['CompanyById'],
          awaitRefetchQueries: true,
        })
        .then(({ data }) => data?.cAssessment);
    },
    [upsertCompanyAssessment.mutate]
  );
  return Tuple(wrapped, {
    loading: upsertCompanyAssessment.isLoading,
  });
}

export function useAddCompanyGeneralAssessment() {
  const cAssessment = useCompanyAssessment();
  const [saveCompanyAssessment, { loading }] = useSaveCompanyAssessment();

  const addCompanyGeneralAssessment = () => {
    if (!cAssessment.bAssessments[GENERAL_BUSINESS_UNIT.id]?.activities?.[GENERAL_ACTIVITY_REF]) {
      const generalFinancials = cAssessment.financials || createFinancials();
      cAssessment.bAssessments[GENERAL_BUSINESS_UNIT.id] = {
        ...cAssessment.bAssessments[GENERAL_BUSINESS_UNIT.id],
        hasGeneralAssessment: true,
        financials: generalFinancials,
        activities: {
          [GENERAL_ACTIVITY_REF]: {
            ...cAssessment.bAssessments[GENERAL_BUSINESS_UNIT.id]?.activities?.[
              GENERAL_ACTIVITY_REF
            ],
            activityRef: GENERAL_ACTIVITY_REF,
            financials: generalFinancials,
          },
        },
      };
      return saveCompanyAssessment(cAssessment);
    }
  };

  return Tuple(addCompanyGeneralAssessment, { loading });
}

export function getDefaultReportData(
  company: Pick<Company, 'id' | 'name'>
): HCompanyAssessment_Insert_Input {
  const DEFAULT_REPORT_TITLE = `${company.name} Report 2022`;
  const generalFinancials = createFinancials();

  return {
    aggregate: {
      data: {
        companyId: company.id,
        title: DEFAULT_REPORT_TITLE,
        interval: 'yearly',
        startDate: '2022-01-01',
      },
    },
    startDate: '2022-01-01',
    endDate: '2022-12-31',
    companyId: company.id,
    bAssesssments: {
      data: [
        {
          hasGeneralAssessment: true,
          activityReports: {
            data: [
              {
                activityRef: GENERAL_ACTIVITY_REF,
                financials: {
                  data: {
                    ...generalFinancials,
                  },
                },
              },
            ],
          },
        },
      ],
    },
  };
}

// TEMPORARY: For backwards compatibility add this business unit to all exising reports
export function useGetAllCompanyAssessments() {
  const reportsRef = useLatest(
    useCompany().cAssessments.map(({ id, bAssessments, deletedBAssessments }) => ({
      id,
      bAssessments,
      deletedBAssessments,
    }))
  );
  return useCallback(
    () =>
      reportsRef.current.map(({ id, bAssessments, deletedBAssessments }) => ({
        cAssessmentId: id,
        bAssessments: bAssessments,
        deletedBAssessments: deletedBAssessments,
        activityRef: GENERAL_ACTIVITY_REF,
      })),
    []
  );
}

export function useToggleActivity(bAssessment: BAssessment) {
  const companyId = useCurrentCompanyId();
  const upsertActivityReport = wrap(useUpsertActivityReportsMutation());
  const wrapped = useCallback(
    (enabled: boolean, activityRef: ActivityReport['activityRef'] = GENERAL_ACTIVITY_REF) => {
      mixpanel.track(TRACKING_EVENTS.BUSINESS_UNITS.MANAGE_ACTIVITIES, {
        companyId,
        action: enabled ? 'add' : 'remove',
      });
      return upsertActivityReport.mutate({
        variables: {
          activityReports: [
            {
              bAssessmentId: bAssessment?.id,
              activityRef,
              deletedAt: enabled ? null : 'now()',
              createdAt: enabled ? 'now()' : undefined, // For proper ordering if it exists already
            },
          ],
        },
        refetchQueries: [CompanyAssessmentDocument, BusinessUnitAssessmentDocument],
        awaitRefetchQueries: true,
      });
    },
    [upsertActivityReport, bAssessment]
  );
  return Tuple(wrapped, {
    loading: upsertActivityReport.isLoading,
  });
}

export function useDeleteCompanyAssessment() {
  const [deleteCompanyAssessment] = useDeleteCompanyAssessmentMutation();
  return useCallback(
    (id: string) =>
      deleteCompanyAssessment({
        variables: {
          id,
          deletedAt: 'now()',
        },
        refetchQueries: [CompanyAssessmentDocument, CompanyByIdDocument],
        awaitRefetchQueries: true,
      }),
    [deleteCompanyAssessment]
  );
}

export function getActivities(bAssessment: BAssessment, general = false) {
  return Object.values(bAssessment?.activities ?? {})
    .map((a) => a.activity)
    .filter((a) => a.reference != GENERAL_ACTIVITY_REF || general);
}

export function useAllBusinessUnitActivities(companyId: Company['id']) {
  const { t } = useEUDataTranslation('activity');
  const { activities } = useQuerySuspense<
    HBusinessUnitActivitiesQuery,
    HBusinessUnitActivitiesQueryVariables
  >(BusinessUnitActivitiesDocument, { variables: { companyId } });
  return groupBy(
    activities.map(({ activity, bAssessment: { businessUnitId } }) => ({
      ...translateFields(activity, ['name'], t, escapeKey(activity.reference)),
      businessUnitId,
    })),
    'businessUnitId'
  );
}

export function useSetGeneralAssessmentOnBusinessUnit() {
  const [updateBusinessUnitAssessment] = useUpdateBusinessUnitAssessmentMutation();
  return useCallback(
    (id: BAssessment['id'], newValue: BAssessment['hasGeneralAssessment']) =>
      updateBusinessUnitAssessment({
        variables: {
          id: id,
          fields: {
            hasGeneralAssessment: newValue,
          },
        },
      }),
    [updateBusinessUnitAssessment]
  );
}
