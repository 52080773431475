import { useStyleConfig, Box, HStack, Text, useMultiStyleConfig } from '@chakra-ui/react';
import { MentionsInput, Mention, MentionsInputProps } from 'react-mentions';

import React from 'react';
import UserAvatar from 'components/topMenu/UserAvatar';
import { Company } from 'models';

interface TextareaWithInputProps extends Omit<MentionsInputProps, 'children'> {
  trigger?: string | RegExp;
  users: Company['users'];
}

const TypedMentionsInput = (MentionsInput as unknown) as React.FC<MentionsInputProps>;

export const TextareaWithMentions = ({
  value,
  onChange,
  trigger = '@',
  users,
  ...rest
}: TextareaWithInputProps) => {
  const textArea = useStyleConfig('Textarea', { size: 'md' });
  const menu = useMultiStyleConfig('Menu', { variant: 'select' });
  return (
    <Box
      width="100%"
      sx={{
        '.mentions__highlighter, .mentions__input': {
          ...textArea,
          height: '100%',
          minHeight: '40px',
        },
        '.mentions__suggestions': {
          ...menu.list,
          left: '16px',
          right: '16px',
          transform: 'translateY(10px)',
          zIndex: '3 !important',
        },
        '.mention': {
          color: 'detailsColor',
          zIndex: '2',
          position: 'relative',
          textShadow:
            '1px 1px 1px white, 1px -1px 1px white, -1px 1px 1px white, -1px -1px 1px white',
        },
        '.read-only__highlighter': { color: 'inherit !important' },
        '.read-only__highlighter__substring': {
          visibility: 'visible !important',
          color: 'inherit',
        },
        '.read-only__input': { visibility: 'hidden' },
      }}
    >
      <TypedMentionsInput value={value} onChange={onChange} className={'mentions'} {...rest}>
        <Mention
          className="mention"
          trigger={trigger}
          data={users.map((user) => ({
            display: `@${user.firstName} ${user.lastName}`,
            ...user,
          }))}
          renderSuggestion={(user, _search, highlightedDisplay, _index, focused) => {
            return (
              <HStack bg={focused ? 'hoverBg' : 'none'} p="sm" alignItems="flex-start">
                <UserAvatar user={user as Company['users'][number]} size="sm" fontSize="14px" />
                <Box>
                  <Text>{(highlightedDisplay as unknown) as React.ReactNode}</Text>
                  <Text fontSize="sm" color="secondary.dark">
                    {(user as Company['users'][number]).email}
                  </Text>
                </Box>
              </HStack>
            );
          }}
        />
      </TypedMentionsInput>
    </Box>
  );
};

export const InputWithMentions = (props: Omit<TextareaWithInputProps, 'singleLine'>) => (
  <TextareaWithMentions {...props} singleLine />
);

export const TextWithMentions = ({ value }: { value: string }) => (
  <TextareaWithMentions value={value} readOnly className="read-only" tabIndex={-1} users={[]} />
);
