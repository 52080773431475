import { useCallback } from 'react';
import {
  HAnswerAttachment_Constraint,
  HAnswerAttachment_Update_Column,
  HFile_Constraint,
  HFile_Insert_Input,
  HFile_Update_Column,
} from 'schema';
import {
  HFileFieldsFragment,
  useCompanyFilesQuery,
  useAddFileMutation,
  useRemoveFileMutation,
  CompanyFilesDocument,
  HCompanyFilesQuery,
  HCompanyFilesQueryVariables,
} from './file.graphql';
import { useCurrentCompanyId, useCurrentUserId } from './localState';
import { v4 as uuidv4 } from 'uuid';
import { storage } from 'utils/nhost';
import { uniqBy } from 'lodash';
import update from 'immutability-helper';
import { updateQuery, omitMetaOther } from './general';
import mixpanel from 'mixpanel-browser';
import { TRACKING_EVENTS } from '../utils/mixpanel';

export type CompanyFile = Omit<HFileFieldsFragment, 'tags'> & { tags: string[] };
export type CompanyFileUpdate = HFile_Insert_Input & { toUpload?: File; uploadedBy?: { id: any } };
export const AttachmentOnConflict = {
  constraint: HAnswerAttachment_Constraint.AnswerAttachmentAnswerIdFileIdEe5f7802Uniq,
  update_columns: [HAnswerAttachment_Update_Column.AnswerId],
};
export const FileOnConflict = {
  constraint: HFile_Constraint.FilePkey,
  update_columns: [
    HFile_Update_Column.Description,
    HFile_Update_Column.Title,
    HFile_Update_Column.Url,
    HFile_Update_Column.UploadedById,
    HFile_Update_Column.CreationDate,
    HFile_Update_Column.Source,
  ],
};

export function getPublicUrl(f: CompanyFile) {
  return `${f.url}?token=${f.token}`;
}
export function useCompanyFiles() {
  const companyId = useCurrentCompanyId();
  const res = useCompanyFilesQuery({ variables: { companyId } });
  return res.data?.files ?? null;
}
export const ALL_FILES = () => true;
export function getFileFilter(searchTerm: string) {
  searchTerm = searchTerm.toLocaleLowerCase().trim();
  if (searchTerm.length < 3) return ALL_FILES;
  return (f: CompanyFile) =>
    f.title.toLocaleLowerCase().includes(searchTerm) ||
    f.description.toLocaleLowerCase().includes(searchTerm) ||
    f.tags.join('$').toLocaleLowerCase().includes(searchTerm);
}
export function filterFiles(files: CompanyFile[], searchTerm: string) {
  return files.filter(getFileFilter(searchTerm));
}
export const FILE_BASE_URL = `${process.env.NEXT_PUBLIC_NHOST_BACKEND_URL}/storage/o`;

export function useUpsertFile() {
  const [saveFile] = useAddFileMutation();
  const companyId = useCurrentCompanyId();
  const userId = useCurrentUserId();
  return useCallback(
    async ({ id, url, toUpload, ...file }: CompanyFileUpdate) => {
      let toDelete = null;
      id = id ?? uuidv4();
      let upload;
      if (toUpload) {
        const newUrl = `/company/${companyId}/files/${id}/${toUpload.name}`;
        if (url && url != newUrl) toDelete = url;
        upload = storage.put(newUrl, toUpload).then(({ key, Metadata: { token } }) => ({
          ...file,
          url: `${FILE_BASE_URL}/${key}`,
          companyId,
          token,
          uploadedById: userId,
          id,
        }));
      } else
        upload = Promise.resolve({
          url,
          id,
          companyId,
          uploadedById: file.uploadedBy?.id,
          ...file,
        });
      const fileData = await upload;
      const { data } = await saveFile({
        variables: { input: omitMetaOther(fileData, 'uploadedBy', 'updatedAt') },
        update(cache, response) {
          updateQuery(
            cache,
            CompanyFilesDocument,
            { companyId } as HCompanyFilesQueryVariables,
            (companyFiles: HCompanyFilesQuery, newFile) =>
              update(companyFiles, {
                files: { $set: uniqBy([newFile, ...companyFiles.files], 'id') },
              }),
            response.data?.file
          );
        },
      });
      if (toDelete) await storage.delete(toDelete.replace(FILE_BASE_URL, ''));
      return data?.file;
    },
    [saveFile]
  );
}
export function useDeleteFile() {
  const [deleteFile] = useRemoveFileMutation();
  const companyId = useCurrentCompanyId();
  return useCallback(
    ({ id }: CompanyFile) => {
      mixpanel.track(TRACKING_EVENTS.DRIVE.DELETE, {
        companyId,
        fileId: id,
      });
      return deleteFile({
        variables: { id },
        update(cache, response) {
          updateQuery(
            cache,
            CompanyFilesDocument,
            { companyId } as HCompanyFilesQueryVariables,
            (companyFiles: HCompanyFilesQuery) =>
              update(companyFiles, {
                files: { $set: companyFiles.files.filter((f) => f.id != id) },
              }),
            response.data?.file
          );
        },
      }).then(({ data }) => data?.file);
    },
    [deleteFile]
  );
}
