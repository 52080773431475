import { IconProps, Icon, createIcon } from '@chakra-ui/icons';
import { IconType as ReactIconType } from 'react-icons';

import {
  FiLogOut,
  FiXCircle,
  FiInfo,
  FiBookOpen,
  FiGitMerge,
  FiFileText,
  FiChevronDown,
  FiTrash2,
  FiChevronUp,
} from 'react-icons/fi';
import { HiArrowRight, HiArrowLeft, HiDocumentReport, HiDocumentAdd } from 'react-icons/hi';
import { ImCheckmark } from 'react-icons/im';
import {
  FaPlus,
  FaMinus,
  FaPlusCircle,
  FaLongArrowAltRight,
  FaHandsHelping,
  FaSwatchbook,
} from 'react-icons/fa';
import { IoEllipsisVertical } from 'react-icons/io5';
import { MdBlock, MdQuestionAnswer } from 'react-icons/md';
import {
  AiOutlineCloudDownload,
  AiOutlineWarning,
  AiOutlineClose,
  AiOutlineAppstoreAdd,
} from 'react-icons/ai';
import { TiExportOutline } from 'react-icons/ti';
import { BsCloudUpload } from 'react-icons/bs';
import { GrDrag, GrScorecard } from 'react-icons/gr';
import { BiCopy, BiHelpCircle } from 'react-icons/bi';
import { RiTeamFill } from 'react-icons/ri';
import { GiLightBulb, GiSpellBook } from 'react-icons/gi';
import { CgListTree } from 'react-icons/cg';

const reactIcon = (icon: ReactIconType) => {
  const component = (props: IconProps) => (
    // @ts-ignore
    <Icon as={icon} {...props} />
  );
  component.displayName = icon.name;
  return component;
};
export const LogOutIcon = reactIcon(FiLogOut);
export const XCircleIcon = reactIcon(FiXCircle);
export const XOutlineIcon = reactIcon(AiOutlineClose);
export const InfoIcon = reactIcon(FiInfo);
export const ResourcesIcon = reactIcon(FiBookOpen);
export const DependenciesIcon = reactIcon(FiGitMerge);
export const DescriptionIcon = reactIcon(FiFileText);
export const CheckMarkIcon = reactIcon(ImCheckmark);
export const PlusIcon = reactIcon(FaPlus);
export const PlusCircleIcon = reactIcon(FaPlusCircle);
export const MinusIcon = reactIcon(FaMinus);
export const EllipsisVerticalIcon = reactIcon(IoEllipsisVertical);
export const BlockUserIcon = reactIcon(MdBlock);
export const DownloadIcon = reactIcon(AiOutlineCloudDownload);
export const WarningFeedback = reactIcon(AiOutlineWarning);
export const ChevronDownIcon = reactIcon(FiChevronDown);
export const ChevronUpIcon = reactIcon(FiChevronUp);
export const ExportIcon = reactIcon(TiExportOutline);
export const ArrowRightIcon = reactIcon(HiArrowRight);
export const ArrowLeftIcon = reactIcon(HiArrowLeft);
export const TrashIcon = reactIcon(FiTrash2);
export const DragIcon = reactIcon(GrDrag);
export const UploadIcon = reactIcon(BsCloudUpload);
export const AddIcon = reactIcon(AiOutlineAppstoreAdd);
export const LongArrowRight = reactIcon(FaLongArrowAltRight);
export const HelpIcon = reactIcon(BiHelpCircle);
export const ScreeningIcon = reactIcon(HiDocumentReport);
export const DocumentationIcon = reactIcon(HiDocumentAdd);
export const TeamMembersCollaborationIcon = reactIcon(RiTeamFill);
export const TaxonomyScoreIcon = reactIcon(GrScorecard);
export const CompanyStructureIcon = reactIcon(CgListTree);
export const DictionaryIcon = reactIcon(FaSwatchbook);
export const AboutTaxonomyIcon = reactIcon(GiSpellBook);
export const FaqIcon = reactIcon(MdQuestionAnswer);
export const GettingStartedIcon = reactIcon(FaHandsHelping);
export const LightBulbIcon = reactIcon(GiLightBulb);
export const CopyIcon = reactIcon(BiCopy);

export type { IconProps } from '@chakra-ui/icons';
export type IconType = ReturnType<typeof createIcon>;

export const QuestionMarkIcon = createIcon({
  displayName: 'QuestionMarkIcon',
  viewBox: '0 0 10 14',
  path: [
    <path
      key="1"
      d="M0.882643 3.679C0.88059 3.72744 0.888467 3.77579 0.90579 3.82107C0.923114 3.86636 0.949517 3.90762 0.983376 3.94232C1.01724 3.97703 1.05784 4.00444 1.10268 4.02287C1.14753 4.0413 1.19567 4.05036 1.24414 4.0495H2.48164C2.68864 4.0495 2.85364 3.88 2.88064 3.6745C3.01564 2.6905 3.69064 1.9735 4.89364 1.9735C5.92264 1.9735 6.86464 2.488 6.86464 3.7255C6.86464 4.678 6.30364 5.116 5.41714 5.782C4.40764 6.5155 3.60814 7.372 3.66514 8.7625L3.66964 9.088C3.67122 9.18641 3.71142 9.28026 3.78158 9.3493C3.85173 9.41833 3.94622 9.45701 4.04464 9.457H5.26114C5.3606 9.457 5.45598 9.41749 5.52631 9.34717C5.59663 9.27684 5.63614 9.18146 5.63614 9.082V8.9245C5.63614 7.8475 6.04564 7.534 7.15114 6.6955C8.06464 6.001 9.01714 5.23 9.01714 3.6115C9.01714 1.345 7.10314 0.25 5.00764 0.25C3.10714 0.25 1.02514 1.135 0.882643 3.679ZM3.21814 12.3235C3.21814 13.123 3.85564 13.714 4.73314 13.714C5.64664 13.714 6.27514 13.123 6.27514 12.3235C6.27514 11.4955 5.64514 10.9135 4.73164 10.9135C3.85564 10.9135 3.21814 11.4955 3.21814 12.3235Z"
      fill="currentColor"
    />,
  ],
});
export const QuestionMarkCircleIcon = createIcon({
  displayName: 'QuestionMarkCircleIcon',
  viewBox: '0 0 14 14',
  path: [
    <path
      key="1"
      d="M6.99999 0.142822C10.7869 0.142822 13.8571 3.21311 13.8571 6.99996C13.8571 10.7868 10.7869 13.8571 6.99999 13.8571C3.21314 13.8571 0.142853 10.7868 0.142853 6.99996C0.142853 3.21311 3.21314 0.142822 6.99999 0.142822ZM6.99999 0.999965C3.68628 0.999965 0.999995 3.68625 0.999995 6.99996C0.999995 10.3137 3.68628 13 6.99999 13C10.3137 13 13 10.3137 13 6.99996C13 3.68625 10.3137 0.999965 6.99999 0.999965ZM6.99999 9.57139C7.18944 9.57139 7.37112 9.64665 7.50507 9.7806C7.63903 9.91456 7.71428 10.0962 7.71428 10.2857C7.71428 10.4751 7.63903 10.6568 7.50507 10.7908C7.37112 10.9247 7.18944 11 6.99999 11C6.81055 11 6.62887 10.9247 6.49492 10.7908C6.36096 10.6568 6.28571 10.4751 6.28571 10.2857C6.28571 10.0962 6.36096 9.91456 6.49492 9.7806C6.62887 9.64665 6.81055 9.57139 6.99999 9.57139ZM6.99999 2.85711C8.12171 2.85711 9.14285 3.84282 9.14285 4.99996C9.14285 5.81768 8.87714 6.29939 8.25942 6.88911L8.14685 6.99425L8.03028 7.10111C7.56971 7.52282 7.42857 7.74396 7.42857 8.14282C7.42857 8.25649 7.38341 8.36549 7.30304 8.44587C7.22267 8.52624 7.11366 8.57139 6.99999 8.57139C6.88633 8.57139 6.77732 8.52624 6.69695 8.44587C6.61658 8.36549 6.57142 8.25649 6.57142 8.14282C6.57142 7.48625 6.79885 7.09025 7.33885 6.57425L7.66457 6.27254C8.12742 5.83139 8.28571 5.53825 8.28571 4.99996C8.28571 4.32339 7.65485 3.71425 6.99999 3.71425C6.34514 3.71425 5.71428 4.32339 5.71428 4.99996C5.71428 5.11363 5.66913 5.22264 5.58875 5.30301C5.50838 5.38338 5.39937 5.42854 5.28571 5.42854C5.17204 5.42854 5.06304 5.38338 4.98266 5.30301C4.90229 5.22264 4.85714 5.11363 4.85714 4.99996C4.85714 3.84282 5.87828 2.85711 6.99999 2.85711Z"
      fill="currentColor"
    />,
  ],
});

export const LocationIcon = createIcon({
  displayName: 'LocationIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="1"
      fill="currentColor"
      d="m8 15-4.218-4.975a17.4 17.4 0 0 1-.174-.225A5.444 5.444 0 0 1 2.5 6.5a5.5 5.5 0 1 1 11 0 5.442 5.442 0 0 1-1.107 3.299l-.001.001s-.15.197-.172.223L8 15ZM4.406 9.197c.001 0 .117.155.144.188L8 13.453l3.455-4.075c.022-.027.139-.182.14-.183A4.45 4.45 0 0 0 12.5 6.5a4.5 4.5 0 1 0-9 0 4.452 4.452 0 0 0 .907 2.697h-.001Z"
    />,
    <path
      key="2"
      fill="currentColor"
      d="M10.5 9h-1V5h-3v4h-1V5a1 1 0 0 1 1-1h3a1.001 1.001 0 0 1 1 1v4Z"
    />,
    <path key="3" fill="currentColor" d="M7.5 8h1v1h-1V8ZM7.5 6h1v1h-1V6Z" />,
  ],
  defaultProps: {
    w: '1em',
    h: '1em',
  },
});

export const BusinessOverviewIcon = createIcon({
  displayName: 'BusinessOverviewIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="1"
      fill="currentColor"
      d="M18 15.75h1.5v3.75H18v-3.75ZM15 12h1.5v7.5H15V12ZM8.25 19.5a3.755 3.755 0 0 1-3.75-3.75H6a2.25 2.25 0 1 0 2.25-2.25V12a3.75 3.75 0 0 1 0 7.5Z"
    />,
    <path
      key="2"
      fill="currentColor"
      d="M21 1.5H3A1.502 1.502 0 0 0 1.5 3v18A1.501 1.501 0 0 0 3 22.5h18a1.502 1.502 0 0 0 1.5-1.5V3A1.502 1.502 0 0 0 21 1.5Zm0 6.75H10.5V3H21v5.25ZM9 3v5.25H3V3h6ZM3 21V9.75h18L21.002 21H3Z"
    />,
  ],
});
export const AssessmentIcon = createIcon({
  displayName: 'AssessmentIcon',
  viewBox: '0 0 24 24',
  d:
    'M18.5 20a.5.5 0 0 1-.5.5h-5.732A6.517 6.517 0 0 1 11.19 22H18a2 2 0 0 0 2-2V9.828a2 2 0 0 0-.586-1.414l-5.83-5.828a.494.494 0 0 0-.048-.04.612.612 0 0 1-.036-.03 2.074 2.074 0 0 0-.22-.18.656.656 0 0 0-.08-.044l-.047-.024-.05-.029c-.054-.031-.11-.063-.166-.087-.2-.08-.41-.126-.624-.138a.56.56 0 0 1-.06-.007.607.607 0 0 0-.081-.007H6a2 2 0 0 0-2 2v7.498c.481-.2.985-.342 1.5-.422V4a.5.5 0 0 1 .5-.5h6V8a2 2 0 0 0 2 2h4.5v10Zm-5-15.379L17.378 8.5H14a.5.5 0 0 1-.5-.5V4.621ZM12 17.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0Zm-2.146-2.354a.5.5 0 0 0-.708 0L5.5 18.793l-1.646-1.647a.5.5 0 0 0-.708.708l2 2a.5.5 0 0 0 .708 0l4-4a.5.5 0 0 0 0-.708Z',
});
export const EditIcon = createIcon({
  displayName: 'EditIcon',
  viewBox: '0 0 24 24',
  d:
    'M19.4 7.34 16.66 4.6A2 2 0 0 0 14 4.53l-9 9a2 2 0 0 0-.57 1.21L4 18.91A1 1 0 0 0 5 20h.09l4.17-.38a2 2 0 0 0 1.21-.57l9-9a1.92 1.92 0 0 0-.07-2.71ZM9.08 17.62l-3 .28.27-3L12 9.32l2.7 2.7-5.62 5.6ZM16 10.68 13.32 8l1.95-2L18 8.73l-2 1.95Z',
});
export const CalendarIcon = createIcon({
  displayName: 'CalendarIcon',
  viewBox: '0 0 32 32',
  d:
    'M26 4H22V2H20V4H12V2H10V4H6C4.9 4 4 4.9 4 6V26C4 27.1 4.9 28 6 28H26C27.1 28 28 27.1 28 26V6C28 4.9 27.1 4 26 4ZM26 26H6V12H26V26ZM26 10H6V6H10V8H12V6H20V8H22V6H26V10Z',
});
export const DocumentSourceIcon = createIcon({
  displayName: 'DocumentSourceIcon',
  viewBox: '0 0 22 28',
  d:
    'M5.5 10A1.5 1.5 0 0 0 4 11.5v9a1.5 1.5 0 0 0 3 0v-9A1.5 1.5 0 0 0 5.5 10Zm4 3.5a1.5 1.5 0 0 1 3 0v7a1.5 1.5 0 1 1-3 0v-7Zm7 2.5a1.5 1.5 0 0 0-1.5 1.5v3a1.5 1.5 0 1 0 3 0v-3a1.5 1.5 0 0 0-1.5-1.5ZM14 0c1.306 0 2.418.835 2.83 2h1.67A3.5 3.5 0 0 1 22 5.5v19a3.5 3.5 0 0 1-3.5 3.5h-15A3.5 3.5 0 0 1 0 24.5v-19A3.5 3.5 0 0 1 3.5 2h1.67A3.001 3.001 0 0 1 8 0h6ZM8 2a1 1 0 0 0 0 2h6a1 1 0 1 0 0-2H8ZM3.5 4A1.5 1.5 0 0 0 2 5.5v19A1.5 1.5 0 0 0 3.5 26h15a1.5 1.5 0 0 0 1.5-1.5v-19A1.5 1.5 0 0 0 18.5 4h-1.67A3.001 3.001 0 0 1 14 6H8a3.001 3.001 0 0 1-2.83-2H3.5Z',
});
export const DuplicateIcon = createIcon({
  displayName: 'DuplicateIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="1"
      d="M10 4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V8.828a1 1 0 0 0-.293-.707L14.88 4.293A1 1 0 0 0 14.172 4H10ZM7 5a3 3 0 0 1 3-3h4.172a3 3 0 0 1 2.12.879l3.83 3.828A3 3 0 0 1 21 8.828V15a3 3 0 0 1-3 3h-8a3 3 0 0 1-3-3V5Z"
      fill="currentColor"
    />,
    <path
      key="2"
      d="M3 9a3 3 0 0 1 3-3h2a1 1 0 0 1 0 2H6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-2a1 1 0 0 1 2 0v2a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V9Zm11-7a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h4a1 1 0 1 1 0 2h-4a3 3 0 0 1-3-3V3a1 1 0 0 1 1-1Z"
      fill="currentColor"
    />,
  ],
});

export const PdfIcon = createIcon({
  displayName: 'PdfIcon',
  viewBox: '0 0 24 25',
  path: [
    <path key="1" d="M22.5 14V12.5H18V20H19.5V17H21.75V15.5H19.5V14H22.5Z" fill="currentColor" />,
    <path
      key="2"
      d="M14.25 20H11.25V12.5H14.25C14.8466 12.5006 15.4185 12.7378 15.8403 13.1597C16.2622 13.5815 16.4994 14.1534 16.5 14.75V17.75C16.4994 18.3466 16.2622 18.9185 15.8403 19.3403C15.4185 19.7622 14.8466 19.9994 14.25 20ZM12.75 18.5H14.25C14.4489 18.4998 14.6395 18.4207 14.7801 18.2801C14.9207 18.1395 14.9998 17.9489 15 17.75V14.75C14.9998 14.5511 14.9207 14.3605 14.7801 14.2199C14.6395 14.0793 14.4489 14.0002 14.25 14H12.75V18.5Z"
      fill="currentColor"
    />,
    <path
      key="3"
      d="M8.25 12.5H4.5V20H6V17.75H8.25C8.64764 17.7494 9.02883 17.5912 9.31 17.31C9.59118 17.0288 9.7494 16.6476 9.75 16.25V14C9.7496 13.6023 9.59144 13.221 9.31022 12.9398C9.029 12.6586 8.6477 12.5004 8.25 12.5ZM6 16.25V14H8.25L8.25075 16.25H6Z"
      fill="currentColor"
    />,
    <path
      key="4"
      d="M16.5 11V8.00001C16.5026 7.90144 16.4839 7.80347 16.4451 7.71284C16.4062 7.62221 16.3482 7.54107 16.275 7.47501L11.025 2.22501C10.959 2.15177 10.8778 2.09372 10.7872 2.05488C10.6966 2.01603 10.5986 1.99731 10.5 2.00001H3C2.60254 2.0012 2.2217 2.15961 1.94065 2.44066C1.6596 2.72171 1.50119 3.10255 1.5 3.50001V21.5C1.5 21.8978 1.65804 22.2794 1.93934 22.5607C2.22064 22.842 2.60218 23 3 23H15V21.5H3V3.50001H9V8.00001C9.00119 8.39747 9.1596 8.77831 9.44065 9.05936C9.7217 9.34041 10.1025 9.49882 10.5 9.50001H15V11H16.5ZM10.5 8.00001V3.80001L14.7 8.00001H10.5Z"
      fill="currentColor"
    />,
  ],
});
export const DeleteIcon = createIcon({
  displayName: 'DeleteIcon',
  viewBox: '0 0 24 24',
  d: 'M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12ZM8 9h8v10H8V9Zm7.5-5-1-1h-5l-1 1H5v2h14V4h-3.5Z',
});

export const UndoIcon = createIcon({
  displayName: 'UndoIcon',
  viewBox: '0 0 12 12',
  d:
    'M6.25 4A5.232 5.232 0 0 0 2.8 5.3L1 3.5V8h4.5L3.69 6.19c.695-.58 1.58-.94 2.56-.94A4.006 4.006 0 0 1 10.05 8l1.185-.39A5.257 5.257 0 0 0 6.25 4Z',
});
export const NoticeIcon = createIcon({
  displayName: 'NoticeIcon',
  viewBox: '0 0 20 20',
  d:
    'M18.625.625c-.09 0-.18.016-.272.054L4.843 6.107H1a.382.382 0 0 0-.375.39v7.007c0 .216.169.389.375.389h2.381a2.81 2.81 0 0 0 2.681 3.654 2.818 2.818 0 0 0 2.717-2.072l9.576 3.848a.757.757 0 0 0 .272.054c.396 0 .75-.332.75-.778V1.403a.768.768 0 0 0-.752-.778ZM6.062 15.866a1.123 1.123 0 0 1-1.125-1.12c0-.262.092-.513.258-.712l1.99.799a1.128 1.128 0 0 1-1.122 1.033Z',
});
export const CommentIcon = createIcon({
  displayName: 'CommentIcon',
  viewBox: '0 0 24 22',
  path: [
    <path
      key="1"
      d="M12 3.956C7.94398 3.956 4.65598 6.848 4.65598 10.412M22.8 20.96C21.2347 20.1034 20.0483 18.6901 19.476 17C20.5022 16.1694 21.3323 15.1224 21.9069 13.9337C22.4815 12.7451 22.7865 11.4442 22.8 10.124C22.8 4.904 18 0.667999 12 0.667999C5.99998 0.667999 1.19998 4.904 1.19998 10.124C1.19998 15.344 5.99998 19.592 12 19.592C13.02 19.592 14.028 19.472 15.012 19.22C16.0399 20.1734 17.3025 20.8368 18.6708 21.1425C20.0391 21.4482 21.464 21.3852 22.8 20.96Z"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
export const SwitchIcon = createIcon({
  displayName: 'SwitchIcon',
  viewBox: '0 0 18 18',
  d:
    'M5.614 7.855H2.24a.846.846 0 0 1-.083-.004c-.013-.001-.025-.004-.037-.006l-.045-.006-.041-.01-.039-.01-.039-.014-.039-.014-.035-.017-.04-.019-.032-.019-.039-.023-.033-.025-.033-.024a.852.852 0 0 1-.057-.052l-.004-.004-.004-.004a.843.843 0 0 1-.052-.058l-.024-.031-.025-.035-.024-.038-.019-.033-.019-.04-.016-.035-.015-.039c-.004-.013-.01-.025-.013-.039l-.01-.04-.01-.04-.007-.047-.005-.034a.834.834 0 0 1-.005-.084V3.636a.844.844 0 1 1 1.688 0v1.338l.946-.946a7.04 7.04 0 0 1 9.944 0 .843.843 0 0 1-1.194 1.193 5.35 5.35 0 0 0-7.557 0l-.946.947h1.338a.844.844 0 0 1 0 1.687Zm10.988 3.05-.005-.034-.007-.047-.01-.04-.01-.04-.014-.04-.014-.038-.016-.036-.02-.04-.019-.032-.023-.038-.025-.034c-.008-.011-.015-.022-.024-.032a.886.886 0 0 0-.052-.058l-.004-.004-.004-.004a.856.856 0 0 0-.057-.052l-.033-.024-.034-.025-.039-.023-.031-.02-.04-.018-.035-.017-.04-.014-.038-.014-.04-.01-.04-.01-.047-.007-.035-.005a.81.81 0 0 0-.084-.004h-3.374a.843.843 0 1 0 0 1.688h1.338l-.947.946a5.35 5.35 0 0 1-7.557 0 .844.844 0 0 0-1.193 1.193 7.04 7.04 0 0 0 9.944 0l.946-.946v1.338a.844.844 0 1 0 1.687 0v-3.375a.84.84 0 0 0-.004-.084Z',
});

export const CompanyIcon = createIcon({
  displayName: 'CompanyIcon',
  viewBox: '0 0 14 14',
  d:
    'M11.667 3.5h-1.75V2.333c0-.643-.524-1.166-1.167-1.166h-3.5c-.643 0-1.167.523-1.167 1.166V3.5h-1.75c-.643 0-1.166.523-1.166 1.167v6.416c0 .644.523 1.167 1.166 1.167h9.334c.643 0 1.166-.523 1.166-1.167V4.667c0-.644-.523-1.167-1.166-1.167ZM8.75 2.333V3.5h-3.5V2.333h3.5ZM4.667 4.667h7v1.75H2.333v-1.75h2.334Zm-2.334 6.416v-3.5h3.5V8.75h2.334V7.583h3.5v3.5H2.333Z',
});
export const PortfolioIcon = createIcon({
  displayName: 'PortfolioIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="0"
      d="M21 1.5h-9A1.501 1.501 0 0 0 10.5 3v7.5H3A1.502 1.502 0 0 0 1.5 12v10.5h21V3A1.502 1.502 0 0 0 21 1.5ZM6.75 21v-5.25h3V21h-3ZM21 21h-9.75v-6a.75.75 0 0 0-.75-.75H6a.75.75 0 0 0-.75.75v6H3v-9h9V3h9v18Z"
      fill="currentColor"
    />,
    <path
      key="1"
      d="M13.5 6H15v1.5h-1.5V6ZM18 6h1.5v1.5H18V6ZM13.5 10.5H15V12h-1.5v-1.5ZM18 10.5h1.5V12H18v-1.5ZM13.5 15H15v1.5h-1.5V15ZM18 15h1.5v1.5H18V15Z"
      fill="currentColor"
    />,
  ],
});
export const SettingsIcon = createIcon({
  displayName: 'SettingsIcon',
  viewBox: '0 0 20 20',
  d:
    'M14.383 15.712c.24-.184.474-.389.707-.622.226-.226.438-.467.615-.714l2.553.318a.49.49 0 0 0 .516-.347l.99-3.705a.488.488 0 0 0-.262-.573l-2.369-1.01a7.07 7.07 0 0 0-.48-1.811l1.541-2.05a.484.484 0 0 0-.05-.63L15.43 1.853a.474.474 0 0 0-.622-.043l-2.05 1.542a7.22 7.22 0 0 0-1.81-.481L9.934.502a.477.477 0 0 0-.573-.261l-3.698.997c-.233.063-.389.275-.346.516l.318 2.552a5.87 5.87 0 0 0-.728.601c-.22.22-.439.467-.616.715L1.74 5.303a.49.49 0 0 0-.517.347l-.99 3.705c-.07.24.057.467.262.573l2.369 1.011c.085.622.233 1.223.48 1.81l-1.54 2.05a.492.492 0 0 0 .049.63l2.715 2.716c.17.17.431.19.622.042l2.051-1.542a6.86 6.86 0 0 0 1.81.481l1.011 2.37a.477.477 0 0 0 .573.26l3.705-.99c.24-.07.382-.282.347-.515l-.304-2.539Zm-6.93-3.168a3.61 3.61 0 0 1 0-5.09 3.61 3.61 0 0 1 5.091 0 3.61 3.61 0 0 1 0 5.09 3.61 3.61 0 0 1-5.09 0Z',
});
export const FinancialsIcon = createIcon({
  displayName: 'FinancialsIcon',
  viewBox: '0 0 24 24',
  d:
    'm6 16.5-3 2.94V11h3v5.5Zm5-1.84-1.57-1.34L8 14.64V7h3v7.66ZM16 13l-3 3V3h3v10Zm2.81-.19L17 11h5v5l-1.79-1.79L13 21.36l-3.47-3.02L5.75 22H3l6.47-6.34L13 18.64',
});
export const LanguagesIcon = createIcon({
  displayName: 'LanguagesIcon',
  viewBox: '0 0 17 23',
  path: [
    <path
      key="1"
      d="M3 15.898h9.821a.71.71 0 0 0 .544-1.162l-2.573-3.088 2.573-3.089a.708.708 0 0 0-.544-1.162H3v12.75"
      stroke="currentColor"
      fill="none"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
export const CompanySettingsIcon = createIcon({
  displayName: 'CompanySettingsIcon',
  viewBox: '0 0 21 25',
  path: [
    <path
      key="1"
      d="m10.52 17.004-.396-3.176a1.86 1.86 0 0 0-1.846-1.63H6.91a1.86 1.86 0 0 0-1.844 1.63l-.398 3.176A1.24 1.24 0 0 0 5.9 18.397h3.39a1.24 1.24 0 0 0 1.23-1.393v0Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key="2"
      d="M7.595 9.72a1.86 1.86 0 1 0 0-3.72 1.86 1.86 0 0 0 0 3.72ZM2.636 11.579a1.24 1.24 0 1 0 0-2.48 1.24 1.24 0 0 0 0 2.48ZM12.554 11.579a1.24 1.24 0 1 0 0-2.48 1.24 1.24 0 0 0 0 2.48Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />,
    <path
      key="3"
      d="M2.636 13.438h-.19a1.24 1.24 0 0 0-1.223 1.036l-.206 1.24a1.24 1.24 0 0 0 1.223 1.444h2.256M12.554 13.438h.19a1.24 1.24 0 0 1 1.223 1.036l.206 1.24a1.239 1.239 0 0 1-1.223 1.444h-2.256"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
export const CollapseIcon = createIcon({
  displayName: 'CollapseIcon',
  viewBox: '0 0 24 24',
  d: 'm6 7.2 6 6 6-6 2.4 1.2-8.4 8.4-8.4-8.4L6 7.2Z',
});
export const NoteIcon = createIcon({
  displayName: 'NoteIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="1"
      d="M4 4v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8.342a2 2 0 0 0-.602-1.43l-4.44-4.342A2 2 0 0 0 13.56 2H6a2 2 0 0 0-2 2v0ZM9 13h6M9 17h3"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
    <path
      key="2"
      d="M14 2v4a2 2 0 0 0 2 2h4"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinejoin="round"
    />,
  ],
});
export const FileIcon = createIcon({
  displayName: 'FileIcon',
  viewBox: '0 0 24 24',
  path: [
    <path
      key="1"
      d="M19.5 22.5H8.25a1.502 1.502 0 0 1-1.5-1.5v-4.5h1.5V21H19.5V4.5h-6.75V3h6.75A1.501 1.501 0 0 1 21 4.5V21a1.502 1.502 0 0 1-1.5 1.5Z"
      fill="currentColor"
    />,
    <path
      key="2"
      d="M12.75 7.5H18V9h-5.25V7.5ZM12 11.25h6v1.5h-6v-1.5ZM11.25 15H18v1.5h-6.75V15ZM6.75 14.25A3.754 3.754 0 0 1 3 10.5V2.25h1.5v8.25a2.25 2.25 0 0 0 4.5 0V3.75a.75.75 0 0 0-1.5 0v7.5H6v-7.5a2.25 2.25 0 1 1 4.5 0v6.75a3.754 3.754 0 0 1-3.75 3.75Z"
      fill="currentColor"
    />,
  ],
});
export const ButtonMenuIcon = createIcon({
  displayName: 'ButtonMenu',
  viewBox: '0 0 12 12',
  d:
    'M9.848 3.516H2.152c-.231 0-.36.243-.217.41l3.848 4.462c.11.128.322.128.434 0l3.848-4.462c.143-.167.014-.41-.217-.41Z',
});
export const ChevronRightIcon = createIcon({
  viewBox: '0 0 24 24',
  d: 'M17.7 3.8 15.9 2 6 11.9l9.9 9.9 1.8-1.8L9.5 12l8.2-8.1Z',
});

export const ReminderIcon = createIcon({
  displayName: 'AddIcon',
  viewBox: '0 0 12 13',
  path: [
    <path
      key="1"
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66667 1.99999C2.31305 1.99999 1.97391 2.14047 1.72386 2.39051C1.47381 2.64056 1.33333 2.9797 1.33333 3.33332V10.3905L2.86193 8.86192C2.98695 8.73689 3.15652 8.66666 3.33333 8.66666H9.33333C9.68696 8.66666 10.0261 8.52618 10.2761 8.27613C10.5262 8.02608 10.6667 7.68695 10.6667 7.33332V3.33332C10.6667 2.9797 10.5262 2.64056 10.2761 2.39051C10.0261 2.14047 9.68696 1.99999 9.33333 1.99999H2.66667ZM0.781049 1.44771C1.28115 0.947608 1.95942 0.666656 2.66667 0.666656H9.33333C10.0406 0.666656 10.7189 0.947608 11.219 1.44771C11.719 1.9478 12 2.62608 12 3.33332V7.33332C12 8.04057 11.719 8.71884 11.219 9.21894C10.7189 9.71904 10.0406 9.99999 9.33333 9.99999H3.60948L1.13807 12.4714C0.947406 12.6621 0.660661 12.7191 0.411544 12.6159C0.162428 12.5127 0 12.2696 0 12V3.33332C0 2.62608 0.280952 1.9478 0.781049 1.44771ZM2.66663 3.99999C2.66663 3.63181 2.9651 3.33333 3.33329 3.33333H8.66663C9.03482 3.33333 9.33329 3.63181 9.33329 3.99999C9.33329 4.36818 9.03482 4.66666 8.66663 4.66666H3.33329C2.9651 4.66666 2.66663 4.36818 2.66663 3.99999ZM3.33329 6C2.9651 6 2.66663 6.29848 2.66663 6.66667C2.66663 7.03486 2.9651 7.33333 3.33329 7.33333H7.33329C7.70148 7.33333 7.99996 7.03486 7.99996 6.66667C7.99996 6.29848 7.70148 6 7.33329 6H3.33329Z"
      fill="currentColor"
      fillOpacity="0.7"
    />,
  ],
});

export {
  LockIcon as ChangePasswordIcon,
  ArrowBackIcon as BackIcon,
  CloseIcon,
  HamburgerIcon,
  QuestionOutlineIcon as QuestionIcon,
  SearchIcon,
} from '@chakra-ui/icons';

export * from './taxonomyIcons';
export * from './ComplianceIcons';
