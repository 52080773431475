import { Parser } from 'expr-eval';
import { isArray, keyBy, isEqual, sortBy, groupBy } from 'lodash';
import {
  HActivityFieldsFragment,
  HAnswerFieldsFragment,
  HQuestionFieldsFragment,
} from './general.graphql';
import { CompanyFile } from './file-model';
import { QuestionLevel, ShortUser } from './general';

import { isQuestionAligned, isQuestionApplicable } from 'utils/score';
import {
  isMultipleCriteria,
  MultipleCriteriaProps,
  removeSubquestions,
  sortedWithDependencies,
  transformMultipleCriteriaQuestion,
} from './multipleCriteriaQuestion';
import { getObjectiveIndex, OBJECTIVE_ITEMS } from 'constants/objectives';

export type Note = {
  body: string;
  updatedAt: Answer['updatedAt'];
  authors: (ShortUser & { modifiedAt: Answer['updatedAt'] })[];
};
export interface Answer
  extends Omit<HAnswerFieldsFragment, 'reportId' | 'attachments' | 'note' | 'noteChanges'> {
  activityRef: string;
  attachments: CompanyFile[];
  commentsCount: number;
  note: Note | null;
}
export interface AnswerSet {
  [questionUniqueId: string]: Answer;
}
export type ActivityRef = HActivityFieldsFragment['reference'];
function transformAnswer(a: HAnswerFieldsFragment, activityRef: ActivityRef): Answer {
  return {
    ...a,
    commentsCount: a.thread?.comments_aggregate.aggregate?.count ?? 0,
    attachments: a.attachments.map((att) => att.file),
    activityRef,
    note:
      a.note.length == 0
        ? null
        : {
            ...a.note[0],
            authors: a.noteChanges.map(({ updatedAt, author }) => ({
              modifiedAt: updatedAt,
              ...author,
            })),
          },
  };
}

const createAnswerSet = (answers: HAnswerFieldsFragment[], activityRef: ActivityRef): AnswerSet =>
  keyBy(
    answers.map((a) => transformAnswer(a, activityRef)),
    'question.uniqueId'
  );

const parser = new Parser({
  operators: {
    in: true,
  },
}) as Parser & { binaryOps: any };

parser.binaryOps['=='] = (a: any, b: any) =>
  (isArray(a) && !isArray(b) && a.includes(b)) ||
  (isArray(a) && isArray(b) && isEqual(a, b)) ||
  a === b;

// eslint-disable-next-line @typescript-eslint/dot-notation
parser.binaryOps['in'] = (a: any, b: any) =>
  (isArray(a) && isArray(b) && a.every((e) => b.includes(e))) || b.includes(a);

const KNOWN_VARIABLES = {
  this: 'this', //'answer to this question'
  YES: 'YES',
  NO: 'NO',
  NA: 'NA',
  YESNO: '[YES,NO]',
  YESNA: '[YES,NA]',
  ALL: '[YES,NO,NA]',
  TRUE: true,
  FALSE: false,
  DNSH: 'DO_NO_SIGNIFICANT_HARM',
  SC: 'SUBSTANTIAL_CONTRIBUTION',
  UNDEFINED: 'UNDEFINED',
  GREEN: 'GREEN',
  ENABLING: 'ENABLING',
  TRANSITIONAL: 'TRANSITIONAL',
};
const NOT_EDITABLE = {} as Answer;

export const ALL_QUESTIONS = 'ALL_QUESTIONS';
function getCustomFunctions(allQuestions: Question[]) {
  return {
    isAligned: (questionId: string) => {
      const question = allQuestions.find((q) => q.uniqueId === questionId);
      if (!question)
        throw Error(
          `Unknown question: ${questionId}. It might be further on the list, so it is not resolved yet`
        );
      return question.isAligned;
    },
    allQuestionSetsAligned: (questionsets: string[]) => {
      const applicable = groupBy(allQuestions.filter(isQuestionApplicable), 'questionSetRef');
      const result = questionsets.every((qs) => {
        if (!applicable[qs]) throw Error(`Unknown questionset ${qs}!`);

        return applicable[qs].every(isQuestionAligned);
      });
      return result;
    },
    listContains: (
      answers: string[],
      positiveOptions: string[],
      minOptions = 0,
      maxOptions = 0
    ) => {
      if (answers.filter((a) => !positiveOptions.includes(a)).length > 0) return false;
      return (
        answers.length >= minOptions && answers.length <= (maxOptions || positiveOptions.length)
      );
    },
  };
}
export function parseExpression(condition: string) {
  try {
    return parser.parse(condition);
  } catch (error) {
    throw new Error(`Cannot parse condition "${condition}": ${(error as Error).message}`);
  }
}
export function resolveCondition(
  condition: string,
  activityAnswers: AnswerSet,
  vars: { [key: string]: any } = {}
) {
  const expr = parseExpression(condition);
  const all = vars[ALL_QUESTIONS] as Question[];
  const values: any = { ...KNOWN_VARIABLES, ...vars, ...(all ? getCustomFunctions(all) : {}) };
  let result: undefined | false | null;
  let hasDependencies = false;
  let allDependenciesAreNotEditable = true;

  expr.variables().forEach((name) => {
    if (values[name] === undefined) {
      hasDependencies = true;
      const answer = activityAnswers[name];
      if (answer === NOT_EDITABLE) {
        // Undefined variables leads to exception when evaluating the expression
        // Replacing them with the magic keyword 'UNDEFINED' makes it possible to still
        // evaluate the expression even if some dependent questions have not been answered yet
        // Use case: When using OR in the expression, it is necessary to still evaluate even
        // if not all dependencies are ansawered i.e. the expression "if Q1 = NO or Q2 = NO"
        // then this question should be editable when Q1 is NO, even if Q2 is not editable (for some reason)
        // PROBLEM: If using negation, we may make the question editable too early. "if Q1 != NO & Q2 !== NO"
        // this would make the current question editable too early, if Q1 is answered, but Q2 is not editable
        // this question would be editable because the answer is not NO, but UNDEFINED, while the intention
        // This is decent trade-off at the moment, since we generally don't use negation.
        values[name] = KNOWN_VARIABLES.UNDEFINED;
        return;
      } else {
        values[name] = answer?.data ?? KNOWN_VARIABLES.UNDEFINED;
      }
      allDependenciesAreNotEditable = false;
    }
  });
  if (hasDependencies && allDependenciesAreNotEditable) return false;
  if (result !== undefined) return result;
  return expr.evaluate(values);
}

function transformQuestion(
  q: HQuestionFieldsFragment & {
    dependencies: Question['dependencies'];
  },
  activityAnswers: AnswerSet,
  activityRef: ActivityRef,
  allQuestions: Question[] = []
): Question {
  const vars = { [ALL_QUESTIONS]: allQuestions };
  const isVisible = resolveCondition(q.isVisible || 'TRUE', activityAnswers, vars);
  const isRequired = resolveCondition(q.isRequired || 'TRUE', activityAnswers, vars);
  const isEditable = isVisible
    ? resolveCondition(q.isEditable || 'TRUE', activityAnswers, vars)
    : null;
  const answer = activityAnswers[q.uniqueId];
  const answerValue = answer?.data ?? '';
  const isComputed = isMultipleCriteria(q);
  const isAligned =
    !isVisible || !isEditable || (answerValue === '' && !isComputed)
      ? null
      : resolveCondition(q.isAligned || 'TRUE', activityAnswers, {
          this: answerValue,
          ...vars,
        });
  const isAnswered = isEditable === false || (isEditable === true && isAligned !== null);
  const choices = q.choices.slice().sort((a, b) => a.order.localeCompare(b.order));

  const level = q.levelExpression
    ? resolveCondition(q.levelExpression, activityAnswers, { this: answerValue, ...vars })
    : q.level;

  // For activities that can do a subst. contribution to both adaptation and mitigation
  // Mitigation is the main objective, while contribution to only adaptation leads to a lower score.
  const isSecondarySubstantialContributionObjective =
    level === QuestionLevel.SubstantialContribution &&
    q.objective.key === OBJECTIVE_ITEMS.adaptation.id &&
    allQuestions.some(
      (qs) =>
        qs.objective.key === OBJECTIVE_ITEMS.mitigation.id &&
        qs.level === QuestionLevel.SubstantialContribution
    );

  if (!isEditable)
    if (isEditable === false)
      // All dependant questions will not be editable
      // eslint-disable-next-line no-param-reassign
      activityAnswers[q.uniqueId] = NOT_EDITABLE;
    else if (answer)
      // This question become not editable, so other questions should not depend on its answer while resolving their expressions
      // eslint-disable-next-line no-param-reassign
      delete activityAnswers[q.uniqueId];

  return transformMultipleCriteriaQuestion(
    {
      ...q,
      isVisible,
      isEditable,
      isRequired,
      isAligned,
      isAnswered,
      activityRef,
      level,
      answer,
      choices,
      subQuestions: [],
      isSecondarySubstantialContributionObjective,
    },
    allQuestions
  );
}
function sortQuestions<
  T extends Pick<Question, 'objective' | 'questionSetRef' | 'group' | 'orderIndex'>
>(questions: T[]) {
  return sortBy(questions, [
    (q) => getObjectiveIndex(q.objective.key),
    (q) => q.group?.toLocaleLowerCase(),
    (q) => q.orderIndex.toLocaleLowerCase(),
  ]);
}
export function transformQuestions(
  questions: HQuestionFieldsFragment[],
  answers: HAnswerFieldsFragment[],
  activityRef: ActivityRef
) {
  const answerSet = createAnswerSet(answers, activityRef);
  const sorted = sortedWithDependencies(sortQuestions(questions));
  const resolvedQuestions = [] as Question[];
  sorted.forEach((q) => {
    try {
      resolvedQuestions.push(transformQuestion(q, answerSet, activityRef, resolvedQuestions));
    } catch (e) {
      throw Error(`Error while transforming question ${q.uniqueId}: ${(e as Error).message}`);
    }
  });
  return { answerSet, resolvedQuestions: sortQuestions(removeSubquestions(resolvedQuestions)) };
}
export interface Question
  extends Omit<HQuestionFieldsFragment, 'isAligned' | 'isRequired' | 'isVisible' | 'isEditable'>,
    MultipleCriteriaProps {
  answer?: Answer;
  activityRef: ActivityRef;
  isAligned: boolean | null;
  isRequired: boolean | null;
  isVisible: boolean | null;
  isEditable: boolean | null;
  isAnswered: boolean;
  isSecondarySubstantialContributionObjective: boolean;
}
