import { Heading } from '@chakra-ui/react';
import React from 'react';
import Card, { CardProps } from './Card';

export default function CardWithHeading({
  heading,
  subheader,
  children,
  ...props
}: { heading: string; subheader?: string } & CardProps) {
  return (
    <Card {...props}>
      <Heading fontSize="xl">{heading}</Heading>
      {subheader && (
        <Heading mt="6px" fontSize="lg" fontWeight="medium" color="grayscale.600">
          {subheader}
        </Heading>
      )}
      {children}
    </Card>
  );
}
