import { useCallback, useMemo } from 'react';
import { omit, sortBy, pick, uniq } from 'lodash';
import { useApolloClient } from '@apollo/client';
import {
  HAnswer_Constraint,
  HAnswer_Update_Column,
  HCompany_Insert_Input,
  HBusinessUnit_Insert_Input,
  HCompany_Set_Input,
  HRequestQuoteInput,
  HCompanyInvitation_Insert_Input,
  HBusinessUnitAssessment_Constraint,
  HBusinessUnitAssessment_Update_Column,
  HBusinessUnitAssessment_Insert_Input,
} from 'schema';
import { ActivityReportOnConflict } from './assessment-model';

import {
  HBusinessUnitFieldsFragment,
  HCompanyFieldsFragment,
  HShortUserFragment,
  useUpsertBusinessUnitMutation,
  HBusinessUnitDistributionKeyFieldsFragment,
  useUpsertCompanyUserMutation,
  HBusinessUnitQuery,
  useDeleteBusinessUnitMutation,
  HBusinessUnitQueryVariables,
  BusinessUnitDocument,
  HCompanyByIdQuery,
  HCompanyByIdQueryVariables,
  useUpdateCompanyMutation,
  useRequestQuoteMutation,
  useUpsertCompanyInvitationMutation,
  CompanyByIdDocument,
  useGetInvitationQuery,
  HGetInvitationQuery,
  HShortCompanyFragment,
  useInvitationsSubscription,
  HCAssessmentFieldsFragment,
} from './company.graphql';

import { omitMeta, Tuple, upsertOmitMeta, User, UserRole, deepOmitTypename } from './general';
import useImperativeQuery from 'hooks/useImperativeQuery';
import { TFunction } from 'next-i18next';
import { useEUDataTranslation, useTranslation } from 'utils/i18n';
import { AttachmentOnConflict } from './file-model';
import { auth } from 'utils/nhost';
import { useCurrentUserId } from './localState';
import {
  createFinancials,
  GENERAL_ACTIVITY_REF,
  useGetAllCompanyAssessments,
  getDefaultReportData,
} from 'models';
import { BusinessUnitAssessmentDocument, CompanyAssessmentDocument } from './assessment.graphql';
import useCompany from 'hooks/useCompany';
import { useAddBusinessUnitToAssessments } from 'hooks/useCompanyStructure';

export type CompanyByIdQuery = HCompanyByIdQuery;
export type CompanyByIdQueryVariables = HCompanyByIdQueryVariables;

export {
  useBusinessUnitLazyQuery,
  CompanyByIdDocument,
  useUserInvitationsQuery,
  useGetBusinessUnitCompanyAssessmentsQuery,
} from './company.graphql';

export interface BusinessUnit extends Omit<HBusinessUnitFieldsFragment, 'sectors' | 'labels'> {
  sectors: string[];
  labels: string[];
}
export type BusinessUnitProp = { businessUnit: BusinessUnit };

export interface Company
  extends Omit<HCompanyFieldsFragment, 'businessUnits' | 'users' | 'cAssessments'> {
  cAssessments: (Pick<HCAssessmentFieldsFragment, 'id'> & {
    timePeriod: string;
    title: string;
    startDate: string;
    endDate: string;
    aggregate: {
      contactPerson?: HCAssessmentFieldsFragment['aggregate']['contactPerson'];
    };
    bAssessments: {
      id: string;
      businessUnitId: string;
    }[];
    deletedBAssessments: {
      id: string;
      businessUnitId: string;
    }[];
  })[];
  businessUnits: BusinessUnit[];
  users: (HShortUserFragment & {
    role: HCompanyFieldsFragment['users'][number]['role'];
  })[];
}
export type UserCompany = User['companies'][number]['company'];
function transformBusinessUnit(bu: HBusinessUnitFieldsFragment, _t: TFunction): BusinessUnit {
  return { ...bu };
}
export function sortBusinessUnits<T extends Pick<BusinessUnit, 'createdAt' | 'id'>>(
  businessUnits: T[]
): T[] {
  return sortBy(businessUnits, 'createdAt', 'id');
}
export function transformCompany(
  company: HCompanyFieldsFragment & { cAssessments: HCAssessmentFieldsFragment[] },
  t: TFunction
): Company {
  return {
    ...company,
    cAssessments: sortBy(company.cAssessments, 'createdAt').map((ca) => ({
      ...ca,
      title: ca.aggregate.title,
      timePeriod: `${ca.startDate} - ${ca.endDate}`,
      bAssessments: ca.bAssesssments.map((bA) => ({
        id: bA.id,
        businessUnitId: bA.businessUnitId,
      })),
      deletedBAssessments: ca.deletedBAssessments.map((bA) => ({
        id: bA.id,
        businessUnitId: bA.businessUnitId,
      })),
    })),
    settings: company.settings || {},
    users: company.users.map((cu) => ({ role: cu.role, ...cu.user })),
    businessUnits: sortBusinessUnits(company.businessUnits.map((a) => transformBusinessUnit(a, t))),
  };
}

export interface BusinessUnitDistributionKey
  extends Omit<HBusinessUnitDistributionKeyFieldsFragment, 'id'> {
  id?: HBusinessUnitDistributionKeyFieldsFragment['id'];
}
export interface BusinessUnitUpdate
  extends Omit<BusinessUnit, 'activities' | 'createdAt' | 'company' | 'contactPerson'>,
    Pick<HBusinessUnit_Insert_Input, 'contactPersonId' | 'companyId'> {
  bAssessments?: (NonNullable<HBusinessUnitQuery['businessUnit']>['bAssessments'][number] & {
    cAssessment?: HBusinessUnitAssessment_Insert_Input['cAssessment'];
  })[];
  cAssessmentIds?: string[];
}
export function useSaveBusinessUnitMutation() {
  const [upsertBusinessUnit, result] = useUpsertBusinessUnitMutation();

  const client = useApolloClient();
  const { t } = useEUDataTranslation('');
  const getReports = useGetAllCompanyAssessments();

  const wrapped = useCallback(
    ({
      id,
      // distributionKeys,
      bAssessments = [],
      cAssessmentIds = [],
      ...businessUnit
    }: BusinessUnitUpdate) => {
      const reports = getReports();

      // TEMPORARY: For backwards compatibility add this business unit to all exising reports
      if (!id && !bAssessments.length) {
        if (reports.length == 0) {
          // Create new report
          bAssessments = [
            ...bAssessments,
            {
              activityReports: [{ activityRef: GENERAL_ACTIVITY_REF, answers: [] }],
              cAssessment: {
                data: getDefaultReportData(businessUnit.companyId),
              },
              cAssessmentId: undefined,
            },
          ];
        } else
          bAssessments = [
            ...bAssessments,
            ...cAssessmentIds.map((cAssessmentId) => ({
              cAssessmentId,
              activityReports: [{ activityRef: GENERAL_ACTIVITY_REF, answers: [] }],
            })),
          ];
      }
      return upsertBusinessUnit({
        variables: {
          businessUnit: {
            ...omit(
              businessUnit,
              '__typename',
              'id',
              'createdAt',
              'contactPerson',
              'company',
              'activityReports'
            ),
            ...(id != null ? { id } : {}),
            bAssessments: {
              data: [
                ...bAssessments.map((bA) => ({
                  ...bA,
                  activityReports: {
                    data: bA.activityReports.map((ar) => ({
                      ...omitMeta(ar),
                      financials: { data: omitMeta(ar.financials ?? createFinancials()) },
                      answers: {
                        data: (ar.answers ?? []).map((ans) => ({
                          ...omitMeta(ans),
                          attachments: {
                            data: ans.attachments.map(omitMeta),
                            on_conflict: AttachmentOnConflict,
                          },
                          notes: {
                            data: ans.notes.map(omitMeta),
                          },
                        })),
                        on_conflict: {
                          constraint: HAnswer_Constraint.AnswerQuestionIdReportId_22331f7fUniq,
                          update_columns: [
                            HAnswer_Update_Column.Data,
                            HAnswer_Update_Column.Flagged,
                            HAnswer_Update_Column.AnsweredById,
                          ],
                        },
                      },
                    })),
                    on_conflict: ActivityReportOnConflict,
                  },
                })),
                ...cAssessmentIds
                  .filter((cAId) => !bAssessments.find((bA) => bA.cAssessmentId === cAId))
                  .filter((assessment) => {
                    const report = reports.find((r) => r.cAssessmentId === assessment);
                    return (
                      !report?.deletedBAssessments.find((bA) => bA.businessUnitId === id) &&
                      !report?.bAssessments.find((bA) => bA.businessUnitId === id)
                    );
                  })
                  .map((cAssessmentId) => ({
                    cAssessmentId,
                    activityReports: {
                      data: [{ activityRef: GENERAL_ACTIVITY_REF, answers: { data: [] } }],
                    },
                  })),
                ...cAssessmentIds
                  .filter((assessment) => {
                    const report = reports.find((r) => r.cAssessmentId === assessment);
                    return report?.deletedBAssessments.find((bA) => bA.businessUnitId === id);
                  })
                  .map((cA) => ({
                    deletedAt: null,
                    cAssessmentId: cA,
                  })),
              ],
              on_conflict: {
                constraint:
                  HBusinessUnitAssessment_Constraint.BusinessUnitAssessmentCAssessmentIdBusinessUnitIdN_8db61b88U,
                update_columns: [
                  HBusinessUnitAssessment_Update_Column.CAssessmentId,
                  HBusinessUnitAssessment_Update_Column.DeletedAt,
                ],
              },
            },
          },
        },
        refetchQueries: [
          CompanyAssessmentDocument,
          CompanyByIdDocument,
          BusinessUnitAssessmentDocument,
        ],
        awaitRefetchQueries: true,
      }).then(({ data }) =>
        data?.businessUnit ? transformBusinessUnit(data.businessUnit, t) : null
      );
    },
    [upsertBusinessUnit, client, t]
  );
  return Tuple(wrapped, result);
}

export function useUpsertCompanyInvitation() {
  const [upsertCompanyInvitation, result] = useUpsertCompanyInvitationMutation({
    refetchQueries: 'active',
  });

  const wrapped = useCallback(
    (invite: HCompanyInvitation_Insert_Input, previous?: Invitation) =>
      upsertCompanyInvitation({
        variables: {
          companyInvitation: {
            ...(previous
              ? pick(previous, 'id', 'status', 'inviteToChildCompanies', 'userEmail')
              : {}),
            companyId: previous?.company.id,
            ...upsertOmitMeta(invite),
          },
        },
      }),
    [upsertCompanyInvitation]
  );
  return Tuple(wrapped, result);
}

export function useAddUserToCompanyMutation() {
  const [upsertCompanyUser, result] = useUpsertCompanyUserMutation();
  const [upsertUserInvite] = useUpsertCompanyInvitation();
  const wrapped = useCallback(
    async (
      company: HCompany_Insert_Input,
      userId: string,
      skipRefreshSession?: boolean,
      invitation?: Invitation
    ) => {
      const results = await upsertCompanyUser({
        variables: {
          companyUser: {
            userId,
            role: UserRole.Admin,
            ...(company.id ? { companyId: company.id } : { company: { data: company } }),
          },
        },
      });
      if (!skipRefreshSession) await auth.refreshSession(); // Refresh companies claims in JWTtoken
      if (invitation) {
        await upsertUserInvite({ status: 'active' }, invitation);
      }
      return results;
    },
    [upsertCompanyUser]
  );
  return Tuple(wrapped, result);
}

export function useDuplicateBusinessUnit() {
  const getBusinessUnit = useImperativeQuery<HBusinessUnitQuery, HBusinessUnitQueryVariables>(
    BusinessUnitDocument
  );
  const { addBusinessUnitToAssessments } = useAddBusinessUnitToAssessments();
  const [saveBusinessUnit] = useSaveBusinessUnitMutation();
  return useCallback(
    async (businessUnitId: string) => {
      const {
        data: { businessUnit: businessUnitDataRaw },
      } = await getBusinessUnit({ id: businessUnitId });
      if (businessUnitDataRaw) {
        // Remove financials from duplicated business units
        const withoutFinancials = {
          ...businessUnitDataRaw,
          bAssessments: businessUnitDataRaw.bAssessments.map((bA) => ({
            ...bA,
            activityReports: bA.activityReports.map((ar) => ({
              ...ar,
              financials: createFinancials(),
            })),
          })),
        };
        const businessUnitData = deepOmitTypename(withoutFinancials) as BusinessUnit;
        const name = `[Copy] ${businessUnitData.name}`;
        return saveBusinessUnit({
          ...businessUnitData,
          id: undefined,
          name,
        }).then((bu) => {
          if (bu == null) throw Error('Duplicate error');
          addBusinessUnitToAssessments(
            bu,
            uniq(businessUnitDataRaw.bAssessments.map((bA) => bA.cAssessmentId))
          );
          return bu;
        });
      }
      throw Error('Business Unit not found');
    },
    [getBusinessUnit, saveBusinessUnit]
  );
}

export function useDeleteBusinessUnit() {
  const [deleteBusinessUnit] = useDeleteBusinessUnitMutation();
  return useCallback(
    (id: string, undo = false) =>
      deleteBusinessUnit({
        variables: {
          id,
          deletedAt: undo ? null : 'now()',
        },
        refetchQueries: [CompanyAssessmentDocument, BusinessUnitAssessmentDocument],
        awaitRefetchQueries: true,
      }),
    [deleteBusinessUnit]
  );
}

export type CompanyUpdate = HCompany_Set_Input;
const COMPANY_SET_FIELDS = [
  'name',
  'isPortfolioOwner',
  'industries',
  'currency',
  'logoUrl',
] as (keyof HCompany_Set_Input)[];
export function useUpdateCompany() {
  const [updateCompany] = useUpdateCompanyMutation();
  return useCallback(
    ({ id, ...company }: CompanyUpdate) =>
      updateCompany({
        variables: {
          id,
          company: pick(company, COMPANY_SET_FIELDS),
        },
      }),
    [updateCompany]
  );
}

export function useUpdateCompanySettings() {
  const [updateCompany] = useUpdateCompanyMutation();
  return useCallback(
    ({ id, settings }: { id: string; settings: any }) =>
      updateCompany({
        variables: {
          id,
          company: {
            settings,
          },
        },
      }),
    [updateCompany]
  );
}

export function useRequestQuote() {
  const [requestQuote] = useRequestQuoteMutation();
  return useCallback((data: HRequestQuoteInput) => requestQuote({ variables: { data } }), [
    requestQuote,
  ]);
}
export type Invitation = Omit<
  NonNullable<HGetInvitationQuery['invitation']>,
  'companyId' | 'companyName' | 'companyLogoUrl'
> & {
  company: Pick<HShortCompanyFragment, 'id' | 'name' | 'logoUrl'>;
};
export function useFindInvitation(invitationId?: string) {
  const { data, error, loading } = useGetInvitationQuery({
    variables: {
      data: { id: invitationId },
    },
    skip: !invitationId,
    fetchPolicy: 'cache-first',
  });
  return useMemo(
    () => ({
      error,
      loading,
      invitation: data?.invitation
        ? ({
            ...omit(data.invitation, 'companyId', 'companyName', 'companyLogoUrl'),
            company: {
              id: data.invitation.companyId,
              name: data.invitation.companyName,
              logoUrl: data.invitation.companyLogoUrl,
            },
          } as Invitation)
        : undefined,
    }),
    [data, error, loading]
  );
}

export function usePendingInvitations() {
  const userId = useCurrentUserId();
  const { data } = useInvitationsSubscription({ variables: { userId } });
  return data?.invitations;
}

export function useBusinessUnitLabels(buFilter: (bu: BusinessUnit) => boolean = () => true) {
  const company = useCompany();

  const allLabels = company.businessUnits.filter(buFilter).flatMap(({ labels }) => labels) ?? [];

  return useMemo(() => uniq(allLabels), [allLabels, buFilter]);
}

export function useCreateInitialCompany() {
  const { t } = useTranslation();
  const [addUserToCompany] = useAddUserToCompanyMutation();
  const [upsertBusinessUnit] = useUpsertBusinessUnitMutation();

  return useCallback(
    async (userId: string, name = '', isPortfolioOwner = false, currency?: string) => {
      const initialCompany: HCompany_Insert_Input = {
        name: name || t('common:welcomeModal.defaultCompany.name') || 'My Company',
        isPortfolioOwner: isPortfolioOwner || false,
        currency: currency ?? 'NOK',
      };
      const companyResponse = await addUserToCompany(initialCompany, userId);
      const company = companyResponse.data?.companyUser?.company || '';

      if (!company) throw Error('Failed to create company');

      const buResults = await upsertBusinessUnit({
        variables: {
          businessUnit: {
            companyId: company.id,
            name: t('common:welcomeModal.defaultBusinessUnit.name'),
            description: '',
            businessArea: '',
            contactPersonId: userId,
            isSupporting: false,
            sectors: [],
            labels: ['My Label'],
            id: undefined,
            bAssessments: {
              data: [
                {
                  activityReports: {
                    data: [{ activityRef: GENERAL_ACTIVITY_REF, answers: { data: [] } }],
                  },
                  cAssessment: {
                    data: getDefaultReportData(company),
                  },
                  cAssessmentId: undefined,
                },
              ],
            },
          },
        },
        refetchQueries: [
          CompanyAssessmentDocument,
          CompanyByIdDocument,
          BusinessUnitAssessmentDocument,
        ],
        awaitRefetchQueries: true,
      });
      return {
        firstBU: buResults.data?.businessUnit,
        company: companyResponse?.data?.companyUser?.company,
      };
    },
    []
  );
}
