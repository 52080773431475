import {
  Alert,
  AlertIcon,
  AlertProps,
  AlertTitle,
  CloseButton,
  Button,
  AlertDescription,
  HStack,
  VStack,
  UseToastOptions,
} from '@chakra-ui/react';

export type AlertWithActionsProps = {
  actions?: { name: string; onClick: () => void; icon?: React.ReactElement }[];
  onClose?: () => void;
} & Pick<UseToastOptions, 'title' | 'description' | 'id'> &
  Pick<AlertProps, 'variant' | 'status'>;

export function AlertWithActions({
  title,
  id,
  description,
  actions,
  onClose,
  ...props
}: AlertWithActionsProps) {
  return (
    <Alert
      alignItems="flex-start"
      id={String(id)}
      borderRadius="base"
      {...props}
      shadow="0px 7px 16px rgba(0, 0, 0, 0.16);"
    >
      <AlertIcon />
      <VStack alignItems="flex-start" flexGrow={1}>
        <AlertTitle>{title}</AlertTitle>
        {description && <AlertDescription>{description}</AlertDescription>}
        {actions && actions.length && (
          <HStack>
            {actions.map(({ name, onClick, icon }) => (
              <Button size="sm" key={name} onClick={onClick} leftIcon={icon}>
                {name}
              </Button>
            ))}
          </HStack>
        )}
      </VStack>
      <CloseButton onClick={onClose} color="primary.main" marginRight="-8px" fontSize="16px" />
    </Alert>
  );
}
