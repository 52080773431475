import React, { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QuerySuspenseContext } from 'hooks/useQuerySuspense';
import Loader, { FullScreenLoader } from './Loader';
import { useCurrentCompanyId } from 'models/localState';

export const COMPANY_ID_NOT_SET = 'companyIdMissing';
export const COMPANY_NOT_FOUND = 'companyNotFound';
export class AppError extends Error {
  _stack: Error['stack'];

  _suppressLogging: boolean;

  constructor(name: string, message?: string | undefined) {
    super(message);
    this.name = name;
    // Disable nextjs and react error handling
    this._stack = this.stack;
    this.stack = undefined;
    this._suppressLogging = true;
  }
}
export function throwAppError(name: string) {
  throw new AppError(name);
}
function disableLoggingForAppErrors(event: ErrorEvent) {
  if ((event.error as any)?._suppressLogging) event.preventDefault();
}
if (typeof window !== 'undefined') window.addEventListener('error', disableLoggingForAppErrors);

export function QuerySuspenseProvider({ children }: { children: React.ReactNode }) {
  const context = useMemo(() => ({}), []);
  return <QuerySuspenseContext.Provider value={context}>{children}</QuerySuspenseContext.Provider>;
}

function Suspense({
  children,
  height,
  newContext = false,
  debugName,
  fullScreen = false,
  fallback = fullScreen ? (
    <FullScreenLoader debugName={debugName} />
  ) : (
    <Loader height={height} debugName={debugName} />
  ),
  expectedErrors = [COMPANY_ID_NOT_SET],
  resetKeys = [],
}: {
  children: React.ReactNode;
  height?: string;
  newContext?: boolean;
  fallback?: React.ReactNode;
  expectedErrors?: string[];
  resetKeys?: any[];
  debugName?: string;
  fullScreen?: boolean;
}) {
  const companyId = useCurrentCompanyId();
  const result = (
    <React.Suspense fallback={fallback}>
      <ErrorBoundary
        resetKeys={[companyId, ...resetKeys]}
        fallbackRender={({ error }) => {
          if (expectedErrors.includes(error.name)) {
            return <>{fallback}</>;
          }
          throw error;
        }}
      >
        {children}
      </ErrorBoundary>
    </React.Suspense>
  );

  return newContext ? <QuerySuspenseProvider>{result}</QuerySuspenseProvider> : result;
}
export default React.memo(Suspense);
