import {
  Heading,
  VStack,
  Avatar,
  StackProps,
  Skeleton,
  SkeletonCircle,
  Text,
  HStack,
} from '@chakra-ui/react';
import React from 'react';
import { Container } from 'components/Container';
import { useRouter } from 'next/router';
import { LoginForm, LoginPage } from './login';
import { RegisterUserForm } from './hiddenRegisterUrlCannotBeFound';
import { useFindInvitation, Company } from 'models';
import CardWithHeading from 'components/CardWithHeading';
import { getServerSidePropsFunc } from 'utils/i18n/server';
import { useTranslation } from 'utils/i18n';
import { CompanyLogo } from 'components/topMenu/CompanySwitcher';
import { usePageTitle, withNoNavigation } from 'components/topMenu/Navigation';
import * as Sentry from '@sentry/browser';

export const CompanyAvatarWithName = ({
  company,
  hideName = false,
}: {
  company?: Pick<Company, 'name' | 'logoUrl'>;
  hideName?: boolean;
}) => (
  <HStack>
    {company?.logoUrl ? (
      <CompanyLogo url={company.logoUrl} size="32px" />
    ) : (
      <Avatar size="sm" name={company?.name} src={company?.logoUrl ?? undefined} />
    )}
    {!hideName && <Text fontSize="16px">{company?.name}</Text>}
  </HStack>
);

export const CompanyAvatar = ({
  company,
  ...rest
}: StackProps & { company?: Pick<Company, 'name' | 'logoUrl'> }) => (
  <VStack {...rest}>
    <SkeletonCircle isLoaded={!!company} size="64px">
      {company?.logoUrl ? (
        <CompanyLogo url={company.logoUrl} size="64px" />
      ) : (
        <Avatar size="lg" name={company?.name} src={company?.logoUrl ?? undefined} />
      )}
    </SkeletonCircle>
    <Skeleton isLoaded={!!company}>
      <Heading fontSize="lg">{company?.name}</Heading>
    </Skeleton>
  </VStack>
);

export default withNoNavigation(function JoinCompany() {
  const { query, push } = useRouter();
  const { invitationId } = query as {
    invitationId?: string;
  };

  const { invitation, loading, error } = useFindInvitation(invitationId);

  const isExistingUser = invitation?.userExists;
  const { t } = useTranslation();
  usePageTitle(t('common:invitations.header'));
  const handleComplete = () =>
    push({ pathname: '/', query: invitationId ? { invitationId } : null });

  if (error?.message === 'not a valid json response from webhook') {
    return (
      <Container height="100%" minHeight="100vh" background="background">
        <CardWithHeading
          alignItems="center"
          width="38rem"
          pt="8"
          mt="32"
          heading={t('common:invitations.invalidInvitation.heading')}
        >
          <Text fontSize="lg" mt="md">
            {t('common:invitations.invalidInvitation.description')}
          </Text>
        </CardWithHeading>
      </Container>
    );
  }

  if (error) {
    try {
      Sentry.captureException(error, {
        tags: {
          pathname: '/join-company',
          severity: 'SEVERE',
        },
      });
    } catch (e) {
      console.error(e);
    }
    return (
      <Container height="100%" minHeight="100vh" background="background">
        <CardWithHeading
          alignItems="center"
          width="38rem"
          pt="8"
          mt="32"
          heading={t('common:invitations.invalidInvitation.heading')}
        >
          <Text fontSize="lg" mt="md">
            {t('common:invitations.invitationError.description')}
          </Text>
        </CardWithHeading>
      </Container>
    );
  }

  return (
    <LoginPage heading={t('common:invitations.header')}>
      <VStack alignItems="stretch" width="100%">
        <CompanyAvatar company={invitation?.company} />
        <Skeleton isLoaded={!loading}>
          {isExistingUser ? (
            <LoginForm email={invitation?.userEmail} onSuccess={handleComplete} />
          ) : (
            <RegisterUserForm
              onSuccess={handleComplete}
              invitation={invitation}
              key={invitationId}
            />
          )}
        </Skeleton>
      </VStack>
    </LoginPage>
  );
});

export const getServerSideProps = getServerSidePropsFunc(['common']);
