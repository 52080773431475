import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalProps,
  ModalBodyProps,
  Box,
  ModalContentProps,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { isChromatic } from 'utils/isChromatic';
const EMPTY = () => {};
export type DefaultModalProps = {
  header?: JSX.Element | string;
  footer?: JSX.Element | string;
  hasOverlay?: boolean;
  name?: string;
  headerProps?: ModalHeaderProps;
  bodyProps?: ModalBodyProps;
  contentProps?: ModalContentProps;
  onClose?: ModalProps['onClose'];
} & Omit<ModalProps, 'onClose'>;
import { gtmTrack } from 'utils/gtm';

export default function DefaultModal({
  isOpen,
  onClose,
  children,
  header,
  footer,
  hasOverlay = true,
  name,
  headerProps,
  bodyProps,
  contentProps,
  ...props
}: DefaultModalProps) {
  useEffect(() => {
    if (name && isOpen) gtmTrack('modal_open', { name });
  }, [name, isOpen]);
  const isClosable = !!onClose;
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose ?? EMPTY}
      closeOnEsc={isClosable}
      closeOnOverlayClick={isClosable}
      autoFocus={!isChromatic()}
      {...props}
    >
      {hasOverlay && <ModalOverlay />}
      <ModalContent {...contentProps}>
        <ModalHeader {...headerProps}>
          <Box>{header}</Box>
          {isClosable && <ModalCloseButton />}
        </ModalHeader>

        <ModalBody {...bodyProps}>{children}</ModalBody>
        {footer !== null && <ModalFooter>{footer}</ModalFooter>}
      </ModalContent>
    </Modal>
  );
}
