/* 6beead267a07b90205900964a6fb82d1818b3ef4
 * This file is automatically generated by graphql-let. */

import * as Types from '../../__types__';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type HShortUserFragment = { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined };

export type HShortCompanyFragment = { id: any, name: string, isPortfolioOwner: boolean, industries: any, logoUrl?: string | null | undefined };

export type HUserFieldsFragment = { id: any, email?: string | null | undefined, lastName?: string | null | undefined, firstName?: string | null | undefined, avatar_url?: string | null | undefined, settings: any, language: string, companies: Array<{ id: any, role: Types.HUserRole_Enum, company: { id: any, name: string, isPortfolioOwner: boolean, industries: any, logoUrl?: string | null | undefined } }>, invitations: Array<{ id: any, status: string }> };

export type HQuestionFieldsFragment = { id: any, title: string, questionSetRef: string, description?: string | null | undefined, type: Types.HQuestionType_Enum, level: Types.HQuestionLevel_Enum, levelExpression?: string | null | undefined, group?: string | null | undefined, orderIndex: string, uniqueId: string, unit?: string | null | undefined, resourceLink?: string | null | undefined, documentationRequired: boolean, aboutDocumentation?: string | null | undefined, alignmentErrorMessage?: string | null | undefined, isVisible?: string | null | undefined, isEditable?: string | null | undefined, isRequired?: string | null | undefined, isAligned?: string | null | undefined, dependencyDescription?: string | null | undefined, extendedDescription?: string | null | undefined, other: any, objective: { key: string, title: string }, choices: Array<{ value: string, displayName: string, order: string }> };

export type HAnswerFieldsFragment = { id: any, questionId: any, updatedAt: any, createdAt: any, data?: any | null | undefined, flagged?: boolean | null | undefined, reportId?: any | null | undefined, answeredBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, question: { id: any, uniqueId: string }, attachments: Array<{ id: any, file: { id: any, title: string, description: string, url: string, token: string, source: string, tags: any, creationDate?: any | null | undefined, updatedAt: any, uploadedBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } }>, thread?: { id: any, comments_aggregate: { aggregate?: { count: number } | null | undefined } } | null | undefined, note: Array<{ body: string, updatedAt: any }>, noteChanges: Array<{ updatedAt: any, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }> };

export type HFileFieldsFragment = { id: any, title: string, description: string, url: string, token: string, source: string, tags: any, creationDate?: any | null | undefined, updatedAt: any, uploadedBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } };

export type HQuestionSetFieldsFragment = { reference: string, activityQuestionSets: Array<{ activityRef: string }>, questions: Array<{ id: any, title: string, questionSetRef: string, description?: string | null | undefined, type: Types.HQuestionType_Enum, level: Types.HQuestionLevel_Enum, levelExpression?: string | null | undefined, group?: string | null | undefined, orderIndex: string, uniqueId: string, unit?: string | null | undefined, resourceLink?: string | null | undefined, documentationRequired: boolean, aboutDocumentation?: string | null | undefined, alignmentErrorMessage?: string | null | undefined, isVisible?: string | null | undefined, isEditable?: string | null | undefined, isRequired?: string | null | undefined, isAligned?: string | null | undefined, dependencyDescription?: string | null | undefined, extendedDescription?: string | null | undefined, other: any, objective: { key: string, title: string }, choices: Array<{ value: string, displayName: string, order: string }> }> };

export type HObjectiveFieldsFragment = { title: string, description?: string | null | undefined, key: string };

export type HSectorFieldsFragment = { reference: string, name: string };

export type HActivityFieldsFragment = { name: string, description: string, reference: string, tagExpression?: string | null | undefined, sector: { reference: string, name: string }, naceCodes: Array<{ code: string }> };

export type HAllObjectivesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HAllObjectivesQuery = { allObjectives: Array<{ title: string, description?: string | null | undefined, key: string }> };

export type HAllSectorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HAllSectorsQuery = { sectors: Array<{ reference: string, name: string }> };

export type HAllActivitiesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type HAllActivitiesQuery = { allActivities: Array<{ name: string, description: string, reference: string, tagExpression?: string | null | undefined, sector: { reference: string, name: string }, naceCodes: Array<{ code: string }> }> };

export type HAddAnswerMutationVariables = Types.Exact<{
  answer: Types.HAnswer_Insert_Input;
  id: Types.Scalars['uuid'];
  attachmentsToDelete: Array<Types.Scalars['uuid']> | Types.Scalars['uuid'];
}>;


export type HAddAnswerMutation = { delete_AnswerAttachment?: { affected_rows: number } | null | undefined, answer?: { id: any, questionId: any, updatedAt: any, createdAt: any, data?: any | null | undefined, flagged?: boolean | null | undefined, reportId?: any | null | undefined, activityReport?: { id: any, bAssessmentId: any, completedAt?: any | null | undefined, createdAt: any, answers: Array<{ id: any }>, activity: { name: string, reference: string, tagExpression?: string | null | undefined }, financials?: { id: any, revenue: any, capex: any, opex: any, adaptationCapex: any, adaptationOpex: any, isEstimate: boolean } | null | undefined } | null | undefined, answeredBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, question: { id: any, uniqueId: string }, attachments: Array<{ id: any, file: { id: any, title: string, description: string, url: string, token: string, source: string, tags: any, creationDate?: any | null | undefined, updatedAt: any, uploadedBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } }>, thread?: { id: any, comments_aggregate: { aggregate?: { count: number } | null | undefined } } | null | undefined, note: Array<{ body: string, updatedAt: any }>, noteChanges: Array<{ updatedAt: any, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }> } | null | undefined };

export type HActivityReportWithoutAnswersFieldsFragment = { id: any, bAssessmentId: any, completedAt?: any | null | undefined, createdAt: any, activity: { name: string, reference: string, tagExpression?: string | null | undefined }, financials?: { id: any, revenue: any, capex: any, opex: any, adaptationCapex: any, adaptationOpex: any, isEstimate: boolean } | null | undefined };

export type HFinancialsFieldsFragment = { id: any, revenue: any, capex: any, opex: any, adaptationCapex: any, adaptationOpex: any, isEstimate: boolean };

export type HGetAnswerQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HGetAnswerQuery = { answer?: { id: any, questionId: any, updatedAt: any, createdAt: any, data?: any | null | undefined, flagged?: boolean | null | undefined, reportId?: any | null | undefined, answeredBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, question: { id: any, uniqueId: string }, attachments: Array<{ id: any, file: { id: any, title: string, description: string, url: string, token: string, source: string, tags: any, creationDate?: any | null | undefined, updatedAt: any, uploadedBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } }>, thread?: { id: any, comments_aggregate: { aggregate?: { count: number } | null | undefined } } | null | undefined, note: Array<{ body: string, updatedAt: any }>, noteChanges: Array<{ updatedAt: any, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }> } | null | undefined };

export type HGetUserQueryVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
}>;


export type HGetUserQuery = { user?: { id: any, email?: string | null | undefined, lastName?: string | null | undefined, firstName?: string | null | undefined, avatar_url?: string | null | undefined, settings: any, language: string, companies: Array<{ id: any, role: Types.HUserRole_Enum, company: { id: any, name: string, isPortfolioOwner: boolean, industries: any, logoUrl?: string | null | undefined } }>, invitations: Array<{ id: any, status: string }> } | null | undefined };

export type HUpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid'];
  user?: Types.Maybe<Types.HUsers_Set_Input>;
}>;


export type HUpdateUserMutation = { user?: { id: any, email?: string | null | undefined, lastName?: string | null | undefined, firstName?: string | null | undefined, avatar_url?: string | null | undefined, settings: any, language: string, companies: Array<{ id: any, role: Types.HUserRole_Enum, company: { id: any, name: string, isPortfolioOwner: boolean, industries: any, logoUrl?: string | null | undefined } }>, invitations: Array<{ id: any, status: string }> } | null | undefined };

export type HDeleteNoteMutationVariables = Types.Exact<{
  answerId: Types.Scalars['uuid'];
}>;


export type HDeleteNoteMutation = { note?: { returning: Array<{ answer: { id: any, questionId: any, updatedAt: any, createdAt: any, data?: any | null | undefined, flagged?: boolean | null | undefined, reportId?: any | null | undefined, answeredBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined }, question: { id: any, uniqueId: string }, attachments: Array<{ id: any, file: { id: any, title: string, description: string, url: string, token: string, source: string, tags: any, creationDate?: any | null | undefined, updatedAt: any, uploadedBy: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } } }>, thread?: { id: any, comments_aggregate: { aggregate?: { count: number } | null | undefined } } | null | undefined, note: Array<{ body: string, updatedAt: any }>, noteChanges: Array<{ updatedAt: any, author: { id: any, email?: string | null | undefined, avatar_url?: string | null | undefined, firstName?: string | null | undefined, lastName?: string | null | undefined } }> } }> } | null | undefined };

export const ShortCompanyFragmentDoc = gql`
    fragment ShortCompany on Company {
  id
  name
  isPortfolioOwner
  industries
  logoUrl
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on users {
  id
  email
  lastName
  firstName
  avatar_url
  settings
  language
  companies: companyUsers {
    id
    role
    company {
      ...ShortCompany
    }
  }
  invitations(where: {status: {_eq: "pending"}}) {
    id
    status
  }
}
    ${ShortCompanyFragmentDoc}`;
export const ShortUserFragmentDoc = gql`
    fragment ShortUser on users {
  id
  email
  avatar_url
  firstName
  lastName
}
    `;
export const FileFieldsFragmentDoc = gql`
    fragment FileFields on File {
  id
  title
  description
  url
  token
  source
  tags
  creationDate
  updatedAt
  uploadedBy {
    ...ShortUser
  }
}
    ${ShortUserFragmentDoc}`;
export const AnswerFieldsFragmentDoc = gql`
    fragment AnswerFields on Answer {
  id
  answeredBy {
    ...ShortUser
  }
  questionId
  question {
    id
    uniqueId
  }
  updatedAt
  createdAt
  data
  flagged
  attachments(order_by: {updatedAt: asc}) {
    id
    file {
      ...FileFields
    }
  }
  thread {
    id
    comments_aggregate {
      aggregate {
        count
      }
    }
  }
  reportId
  note: notes(limit: 1, order_by: {updatedAt: desc}) {
    body
    updatedAt
  }
  noteChanges: notes(order_by: {updatedAt: desc}) {
    updatedAt
    author {
      ...ShortUser
    }
  }
}
    ${ShortUserFragmentDoc}
${FileFieldsFragmentDoc}`;
export const QuestionFieldsFragmentDoc = gql`
    fragment QuestionFields on Question {
  id
  title
  questionSetRef
  description
  type
  level
  levelExpression
  group
  orderIndex
  uniqueId
  unit
  resourceLink
  documentationRequired
  aboutDocumentation
  alignmentErrorMessage
  objective {
    key
    title
  }
  isVisible
  isEditable
  isRequired
  isAligned
  dependencyDescription
  choices {
    value
    displayName
    order
  }
  extendedDescription
  other
}
    `;
export const QuestionSetFieldsFragmentDoc = gql`
    fragment QuestionSetFields on QuestionSet {
  reference
  activityQuestionSets {
    activityRef
  }
  questions {
    ...QuestionFields
  }
}
    ${QuestionFieldsFragmentDoc}`;
export const ObjectiveFieldsFragmentDoc = gql`
    fragment ObjectiveFields on Objective {
  title
  description
  key
}
    `;
export const SectorFieldsFragmentDoc = gql`
    fragment SectorFields on Sector {
  reference
  name
}
    `;
export const ActivityFieldsFragmentDoc = gql`
    fragment ActivityFields on Activity {
  name
  description
  reference
  sector {
    ...SectorFields
  }
  naceCodes: activityNaceCodes {
    code
  }
  tagExpression
}
    ${SectorFieldsFragmentDoc}`;
export const FinancialsFieldsFragmentDoc = gql`
    fragment FinancialsFields on Financials {
  id
  revenue
  capex
  opex
  adaptationCapex
  adaptationOpex
  isEstimate
}
    `;
export const ActivityReportWithoutAnswersFieldsFragmentDoc = gql`
    fragment ActivityReportWithoutAnswersFields on ActivityReport {
  id
  activity {
    name
    reference
    tagExpression
  }
  bAssessmentId
  completedAt
  createdAt
  financials {
    ...FinancialsFields
  }
}
    ${FinancialsFieldsFragmentDoc}`;
export const AllObjectivesDocument = gql`
    query allObjectives {
  allObjectives: Objective {
    ...ObjectiveFields
  }
}
    ${ObjectiveFieldsFragmentDoc}`;

/**
 * __useAllObjectivesQuery__
 *
 * To run a query within a React component, call `useAllObjectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllObjectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllObjectivesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllObjectivesQuery(baseOptions?: Apollo.QueryHookOptions<HAllObjectivesQuery, HAllObjectivesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HAllObjectivesQuery, HAllObjectivesQueryVariables>(AllObjectivesDocument, options);
      }
export function useAllObjectivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HAllObjectivesQuery, HAllObjectivesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HAllObjectivesQuery, HAllObjectivesQueryVariables>(AllObjectivesDocument, options);
        }
export type AllObjectivesQueryHookResult = ReturnType<typeof useAllObjectivesQuery>;
export type AllObjectivesLazyQueryHookResult = ReturnType<typeof useAllObjectivesLazyQuery>;
export type AllObjectivesQueryResult = Apollo.QueryResult<HAllObjectivesQuery, HAllObjectivesQueryVariables>;
export const AllSectorsDocument = gql`
    query allSectors {
  sectors: Sector(where: {reference: {_neq: "0"}}) {
    ...SectorFields
  }
}
    ${SectorFieldsFragmentDoc}`;

/**
 * __useAllSectorsQuery__
 *
 * To run a query within a React component, call `useAllSectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSectorsQuery(baseOptions?: Apollo.QueryHookOptions<HAllSectorsQuery, HAllSectorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HAllSectorsQuery, HAllSectorsQueryVariables>(AllSectorsDocument, options);
      }
export function useAllSectorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HAllSectorsQuery, HAllSectorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HAllSectorsQuery, HAllSectorsQueryVariables>(AllSectorsDocument, options);
        }
export type AllSectorsQueryHookResult = ReturnType<typeof useAllSectorsQuery>;
export type AllSectorsLazyQueryHookResult = ReturnType<typeof useAllSectorsLazyQuery>;
export type AllSectorsQueryResult = Apollo.QueryResult<HAllSectorsQuery, HAllSectorsQueryVariables>;
export const AllActivitiesDocument = gql`
    query allActivities {
  allActivities: Activity(limit: 150, where: {reference: {_neq: "0.0"}}) {
    ...ActivityFields
  }
}
    ${ActivityFieldsFragmentDoc}`;

/**
 * __useAllActivitiesQuery__
 *
 * To run a query within a React component, call `useAllActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllActivitiesQuery(baseOptions?: Apollo.QueryHookOptions<HAllActivitiesQuery, HAllActivitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HAllActivitiesQuery, HAllActivitiesQueryVariables>(AllActivitiesDocument, options);
      }
export function useAllActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HAllActivitiesQuery, HAllActivitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HAllActivitiesQuery, HAllActivitiesQueryVariables>(AllActivitiesDocument, options);
        }
export type AllActivitiesQueryHookResult = ReturnType<typeof useAllActivitiesQuery>;
export type AllActivitiesLazyQueryHookResult = ReturnType<typeof useAllActivitiesLazyQuery>;
export type AllActivitiesQueryResult = Apollo.QueryResult<HAllActivitiesQuery, HAllActivitiesQueryVariables>;
export const AddAnswerDocument = gql`
    mutation addAnswer($answer: Answer_insert_input!, $id: uuid!, $attachmentsToDelete: [uuid!]!) {
  delete_AnswerAttachment(
    where: {_and: [{answerId: {_eq: $id}}, {fileId: {_in: $attachmentsToDelete}}]}
  ) {
    affected_rows
  }
  answer: insert_Answer_one(
    object: $answer
    on_conflict: {constraint: Answer_questionId_reportId_22331f7f_uniq, update_columns: [data, flagged, answeredById]}
  ) {
    ...AnswerFields
    activityReport {
      ...ActivityReportWithoutAnswersFields
      answers {
        id
      }
    }
  }
}
    ${AnswerFieldsFragmentDoc}
${ActivityReportWithoutAnswersFieldsFragmentDoc}`;
export type HAddAnswerMutationFn = Apollo.MutationFunction<HAddAnswerMutation, HAddAnswerMutationVariables>;

/**
 * __useAddAnswerMutation__
 *
 * To run a mutation, you first call `useAddAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAnswerMutation, { data, loading, error }] = useAddAnswerMutation({
 *   variables: {
 *      answer: // value for 'answer'
 *      id: // value for 'id'
 *      attachmentsToDelete: // value for 'attachmentsToDelete'
 *   },
 * });
 */
export function useAddAnswerMutation(baseOptions?: Apollo.MutationHookOptions<HAddAnswerMutation, HAddAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HAddAnswerMutation, HAddAnswerMutationVariables>(AddAnswerDocument, options);
      }
export type AddAnswerMutationHookResult = ReturnType<typeof useAddAnswerMutation>;
export type AddAnswerMutationResult = Apollo.MutationResult<HAddAnswerMutation>;
export type AddAnswerMutationOptions = Apollo.BaseMutationOptions<HAddAnswerMutation, HAddAnswerMutationVariables>;
export const GetAnswerDocument = gql`
    query getAnswer($id: uuid!) {
  answer: Answer_by_pk(id: $id) {
    ...AnswerFields
  }
}
    ${AnswerFieldsFragmentDoc}`;

/**
 * __useGetAnswerQuery__
 *
 * To run a query within a React component, call `useGetAnswerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAnswerQuery(baseOptions: Apollo.QueryHookOptions<HGetAnswerQuery, HGetAnswerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HGetAnswerQuery, HGetAnswerQueryVariables>(GetAnswerDocument, options);
      }
export function useGetAnswerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HGetAnswerQuery, HGetAnswerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HGetAnswerQuery, HGetAnswerQueryVariables>(GetAnswerDocument, options);
        }
export type GetAnswerQueryHookResult = ReturnType<typeof useGetAnswerQuery>;
export type GetAnswerLazyQueryHookResult = ReturnType<typeof useGetAnswerLazyQuery>;
export type GetAnswerQueryResult = Apollo.QueryResult<HGetAnswerQuery, HGetAnswerQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: uuid!) {
  user: users_by_pk(id: $id) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<HGetUserQuery, HGetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HGetUserQuery, HGetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HGetUserQuery, HGetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HGetUserQuery, HGetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<HGetUserQuery, HGetUserQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: uuid!, $user: users_set_input) {
  user: update_users_by_pk(pk_columns: {id: $id}, _set: $user) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type HUpdateUserMutationFn = Apollo.MutationFunction<HUpdateUserMutation, HUpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<HUpdateUserMutation, HUpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HUpdateUserMutation, HUpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<HUpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<HUpdateUserMutation, HUpdateUserMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation deleteNote($answerId: uuid!) {
  note: delete_AnswerNote(where: {answer: {id: {_eq: $answerId}}}) {
    returning {
      answer {
        ...AnswerFields
      }
    }
  }
}
    ${AnswerFieldsFragmentDoc}`;
export type HDeleteNoteMutationFn = Apollo.MutationFunction<HDeleteNoteMutation, HDeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      answerId: // value for 'answerId'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<HDeleteNoteMutation, HDeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HDeleteNoteMutation, HDeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<HDeleteNoteMutation>;
export type DeleteNoteMutationOptions = Apollo.BaseMutationOptions<HDeleteNoteMutation, HDeleteNoteMutationVariables>;