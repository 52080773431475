import React from 'react';
import {
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  CloseButton,
  InputProps,
} from '@chakra-ui/react';
import { SearchIcon } from 'icons';
export const SearchInput = React.forwardRef(function SearchInput(
  {
    search,
    setSearch,
    placeholder,
    ...inputProps
  }: {
    search: string;
    setSearch: (s: string) => void;
    placeholder: string;
  } & InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  return (
    <InputGroup>
      <InputLeftElement color="secondary.dark">
        <SearchIcon />
      </InputLeftElement>
      <Input
        value={search}
        placeholder={placeholder}
        onChange={(e) => setSearch(e.target.value)}
        ref={ref}
        {...inputProps}
      />
      <InputRightElement>
        {search.length > 0 && (
          <CloseButton
            onClick={() => setSearch('')}
            color="primary.main"
            borderWidth="3px"
            borderColor="detailsColor"
            borderRadius="50%"
            size="sm"
          />
        )}
      </InputRightElement>
    </InputGroup>
  );
});
