import { getColor, mode, StyleFunctionProps, transparentize } from '@chakra-ui/theme-tools';
import { Progress, ProgressProps } from '@chakra-ui/react';

export const getTrackColor = (props: StyleFunctionProps) =>
  transparentize(`${props.colorScheme}.500`, 0.06)(props.theme);
export const multiColorFilledTrack = (props: StyleFunctionProps) => {
  const { theme, colors, value } = props;

  const breakpoints = [];
  let prevColor = '';

  Object.entries(colors).forEach(([pct, color]) => {
    const fillColor = getColor(theme, mode(`${color}.500`, `${color}.200`)(props));
    if (prevColor && prevColor !== fillColor) {
      breakpoints.push(`${prevColor} ${pct}`);
    }
    prevColor = fillColor;
    breakpoints.push(`${fillColor} ${pct}`);
  });
  if (prevColor) breakpoints.push(`${prevColor} 100%`);

  const trackColor = getTrackColor(props);

  const gradient = `
        linear-gradient(
          to right, 
          transparent 0%, transparent ${value}%, 
          ${trackColor} 0%, ${trackColor} 100%), 
        linear-gradient(
        to right,
        ${breakpoints.join(', ')}
      )`;

  // Need to override the width specified in the style
  // on progress bar
  return {
    minWidth: '100%',
    bgImage: gradient,
  };
};
const multiSegmentFilledTrack = (props: StyleFunctionProps) => {
  const { theme, values, max } = props;

  const breakpoints = [];
  let totalPct = 0;
  const trackColor = getTrackColor(props);

  Object.entries(values as { [color: string]: number }).forEach(([color, val]) => {
    const fillColor = getColor(theme, mode(`${color}.500`, `${color}.200`)(props));
    const pct = (val / max) * 100;

    breakpoints.push(`${fillColor} ${totalPct}%`);

    totalPct += pct;
    if (totalPct > max) {
      totalPct = max;
    }

    breakpoints.push(`${fillColor} ${totalPct}%`);
  });
  if (totalPct < max) {
    breakpoints.push(`${trackColor} ${totalPct}%`);
    breakpoints.push(`${trackColor} 100%`);
  }

  const gradient = `
      linear-gradient(
      to right,
      ${breakpoints.join(', ')}
    )`;

  // Need to override the width specified by style
  return {
    minWidth: '100%',
    bgImage: gradient,
    bgColor: null,
  };
};

export const MultiSegmentProgress = (
  props: ProgressProps & { values: { [color: string]: number } }
) => <Progress variant="multiSegment" borderRadius="5px" {...props} />;

export const ProgressTheme = {
  baseStyle: (props: StyleFunctionProps) => ({
    track: {
      backgroundColor: getTrackColor(props),
    },
  }),
  variants: {
    multiColor: (props: StyleFunctionProps) => ({
      filledTrack: multiColorFilledTrack(props),
    }),
    multiSegment: (props: StyleFunctionProps) => ({ filledTrack: multiSegmentFilledTrack(props) }),
  },
};
