import {
  Box,
  StylesProvider,
  useMultiStyleConfig,
  useStyles,
  CloseButton,
  Flex,
} from '@chakra-ui/react';
import { Props, GroupBase } from 'react-select';
import type { CreatableProps } from 'react-select/creatable';
import Highlighter, { HighlighterProps } from 'react-highlight-words';
import {
  Props as ChakraSelectProps,
  Select as ChakraSelect,
  CreatableSelect as ChakraCreatableSelect,
} from 'chakra-react-select';
import React from 'react';
import { useTranslation } from 'utils/i18n';

type ChakraS = Pick<
  ChakraSelectProps,
  'size' | 'colorScheme' | 'isInvalid' | 'tagVariant' | 'hasStickyGroupHeaders'
>;

const TypedHighlighter = (Highlighter as unknown) as React.FC<HighlighterProps>;

export const CustomControl = ({
  children,
  innerRef,
  innerProps,
  isDisabled,
  isFocused,
  selectProps: { size, isInvalid },
  renderContent,
}: any) => {
  const inputStyles = useMultiStyleConfig('Input', { size });

  return (
    <StylesProvider value={inputStyles}>
      <Flex
        ref={innerRef}
        sx={{
          ...inputStyles.field,
          p: 0,
        }}
        {...innerProps}
        data-focus={isFocused ? true : undefined}
        data-invalid={isInvalid ? true : undefined}
        data-disabled={isDisabled ? true : undefined}
      >
        {renderContent ? renderContent() : ''}
        {children}
      </Flex>
    </StylesProvider>
  );
};

const CustomMenu = ({ children, innerProps, selectProps: { size } }: any) => {
  const menuStyles = useMultiStyleConfig('Menu', { variant: 'select', size });
  return (
    <Box {...innerProps} marginTop="xs" position="absolute" zIndex="dropdown" width="100%">
      <StylesProvider value={menuStyles}>{children}</StylesProvider>
    </Box>
  );
};

export const CustomOption = ({
  innerRef,
  innerProps,
  isFocused,
  isDisabled,
  label,
  data,
  selectProps: { inputValue, getOptionValue },
  renderContent,
}: any) => {
  const { item } = useStyles();
  const isNew = getOptionValue(data) == null || data.isNew;
  return (
    <Box
      role="button"
      sx={{
        ...item,
        w: '100%',
        textAlign: 'start',
        bg: isFocused ? (item as any)._focus.bg : 'transparent',
        ...(isDisabled && (item as any)._disabled),
        mark: {
          background: 'none',
          fontWeight: 700,
        },
      }}
      ref={innerRef}
      {...innerProps}
      disabled={isDisabled ? true : undefined}
    >
      {inputValue && !isNew ? (
        <TypedHighlighter
          searchWords={[inputValue]}
          textToHighlight={label}
          activeStyle={{ fontWeight: 600, backgroundColor: 'inherit' }}
        />
      ) : renderContent ? (
        renderContent()
      ) : (
        label
      )}
    </Box>
  );
};
const IndicatorSeparator = () => null;
const ClearIndicator = ({ innerProps, isFocused, selectProps: { size } }: any) => {
  const { t } = useTranslation();
  return (
    isFocused && (
      <CloseButton
        {...innerProps}
        aria-label={t('common:button.clear')}
        size={size}
        mx={2}
        tabIndex={-1}
      />
    )
  );
};

const wrap = <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(
  SelectComponent: typeof ChakraSelect,
  props: CreatableProps<OptionType, IsMulti, GroupType> & ChakraS
) => {
  const { t } = useTranslation();
  return (
    <Box flexGrow={1}>
      <SelectComponent
        noOptionsMessage={() => t('common:selectNoOptions')}
        {...props}
        components={{
          Menu: CustomMenu,
          Option: CustomOption,
          IndicatorSeparator,
          ClearIndicator,
          Control: CustomControl,
          ...props.components,
        }}
      />
    </Box>
  );
};
export function Select<
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(props: Props<OptionType, IsMulti, GroupType> & ChakraS & { ref?: any }) {
  return wrap(ChakraSelect, props);
}

export function CreatableSelect<
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>(props: CreatableProps<OptionType, IsMulti, GroupType> & ChakraS) {
  return wrap(ChakraCreatableSelect, props);
}

export default Select;
